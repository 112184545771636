import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { formatTimestampForChatLog } from "../../../utils/date_time";

export default withStyles(() => ({
  container: {
    backgroundColor: "#F4F6FF",
    borderRadius: 4,
  },
  leftContent: {
    display: "flex",
    alignItems: "flex-start",
  },
  messageIcon: {
    flexShrink: 0,
    width: 32,
    height: 32,
    "& path": {
      fill: "#29302E",
    },
  },
  message: {
    margin: "4px 8px 4px 4px",
    fontWeight: 600,
    color: "#29302e",
    fontSize: 16,
  },
  timestamp: {
    color: "#716F6F",
    fontSize: 13,
    lineHeight: 1.23,
    marginTop: 9,
    whiteSpace: "nowrap",
    fontWeight: 600
  },
  hr:{
    height: 1,
    marginTop: 16,
    marginBottom: 16,
    backgroundColor: "#dddddd",
  },
  commentTitle:{
    lineHeight: 1.5,
    fontWeight: 600,
    color: "#29302e",
    fontSize: 16,
  },
  comment:{
    lineHeight: 1.5,
    color: "#29302e",
    fontSize: 16,
  }
}))(withLDConsumer()(({ classes, Icon, message, startAt, flags, comment, padding = 2, margin = 2, shouldShowTimestamp }) =>(<Box className={classes.container} p={padding} m={margin}>
    <Grid
      container
      justify="space-between"
      alignItems="flex-start"
      wrap="nowrap"
    >
      <Grid item className={classes.leftContent}>
        <Icon className={classes.messageIcon} />
        <Typography variant="body1" className={classes.message}>
          {message}
        </Typography>
      </Grid>
      <Typography className={classes.timestamp}>
        {startAt && shouldShowTimestamp
                ? flags.formatChatTimestamp
                  ? formatTimestampForChatLog(startAt)
                  : moment(startAt).format("MMM D, h:mm A").toUpperCase()
                : ""}
      </Typography>
    </Grid>    
  {flags.showTransferComment && comment && <>
    <hr className={classes.hr} />
    <Typography className={classes.commentTitle}>
      Comment
    </Typography>
    <Typography className={classes.comment}>
      {comment}
    </Typography>
  </>
  }
  </Box>
)));
