import React from 'react';
import NotificationSystem from 'react-notification-system';

var defaultWidth = 820;
var defaultShadowOpacity = 0.09;
var defaultColors = {
  success: {
    rgb: "0,0,0"
  },
}
var style = {
  Containers: {
    DefaultStyle:{
      width: defaultWidth,
      marginLeft: -(defaultWidth / 2)
    }
  },

  NotificationItem: { // Override the notification item
    DefaultStyle: { // Applied to every notification, regardless of the notification level
    	padding: '16px 20px 16px 20px',
    },
    info: {
      color: '#2e7de1',
      border: '1px solid #2e7de1',
      backgroundColor: '#eff5fd',
      WebkitBoxShadow: '0 13px 52px rgba(' + defaultColors.success.rgb + ',' + defaultShadowOpacity + ')',
      MozBoxShadow: '0 13px 52px rgba(' + defaultColors.success.rgb + ',' + defaultShadowOpacity + ')',
      boxShadow: '0 13px 52px rgba(' + defaultColors.success.rgb + ',' + defaultShadowOpacity + ')'
    },
    success: { // Applied only to the success notification item
      color: '#27ba68',
      border: '1px solid #27ba68',
      backgroundColor: '#e9f8f0',
      WebkitBoxShadow: '0 13px 52px rgba(' + defaultColors.success.rgb + ',' + defaultShadowOpacity + ')',
      MozBoxShadow: '0 13px 52px rgba(' + defaultColors.success.rgb + ',' + defaultShadowOpacity + ')',
      boxShadow: '0 13px 52px rgba(' + defaultColors.success.rgb + ',' + defaultShadowOpacity + ')'
    },
    error: {
    	color:'#ff2b55',
      border: '1px solid #ff2b55',
    	backgroundColor: '#FFEAEE',
      WebkitBoxShadow: '0 13px 52px rgba(' + defaultColors.success.rgb + ',' + defaultShadowOpacity + ')',
      MozBoxShadow: '0 13px 52px rgba(' + defaultColors.success.rgb + ',' + defaultShadowOpacity + ')',
      boxShadow: '0 13px 52px rgba(' + defaultColors.success.rgb + ',' + defaultShadowOpacity + ')'
    }
  },

  Dismiss: {
    DefaultStyle: {
      top: '50%',
      marginTop: '-8px'
    }
  }
}


class Component extends React.Component{
  // _notificationSystem = null,
	componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
  }
  static defaultProps = {
      autoDismiss: 5
  }
  // Can also take a options object as an argument.
  createToast(message, level) {
    const isError = message instanceof Error;
    if (typeof message === 'object' && !isError) {
      const options = message;
      this._notificationSystem.addNotification(Object.assign({
        position: 'tc',
        level: level,
        autoDismiss: this.props.autoDismiss
      }, options));
    } else {
      this._notificationSystem.addNotification({
        message: message,
        level: level,
        position: 'tc',
        autoDismiss: this.props.autoDismiss
      });
    }
  }
  // componentWillReceiveProps: function(nextProps){
  // 	if(nextProps.message &&
  // 		(nextProps.message != this.props.message) &&
  // 		(nextProps.message != '')){
  // 		this.createToast(nextProps.message,'success');
  // 	} else if(nextProps.error &&
  // 		(nextProps.error != this.props.error) &&
  // 		(nextProps.error != '')){
  // 		this.createToast(nextProps.error,'error');
  // 	}
  // },
  render(){
  	return (
        <NotificationSystem ref="notificationSystem" style={style} allowHTML={true}/>
  	)
  }
}

export default Component
