import React from 'react'
import PropTypes from 'prop-types'

import cx from 'classnames';
import Icon from '../../ui/forms/Icon';

class PlainTagRow extends React.Component {

  handleClickEdit = () => {
    this.props.onClickEdit(this.props.tag.id)
  }

  handleClickDelete = () => {
    this.props.onClickDelete()
  }

  render() {
    const {props} = this
    const cssClass = cx('plain-tag-row row-content', {
      disabled: props.isTableEditing,
    })
    return (
      <div className={cssClass}>
        {props.enableTagCodes && (
          <div className="column-1" onClick={this.handleClickEdit}>
            <Icon className="edit-icon" src={`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-pencil-blue.svg`} />
            {props.tag.code}
          </div>
        )}
        <div className="column-2">
          <div className="tag-description" onClick={this.handleClickEdit}>
            {!props.enableTagCodes && (
              <Icon className="edit-icon" src={`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-pencil-blue.svg`} />
            )}
            {props.tag.description}
          </div>
        </div>
        <div className="column-3" />
      </div>
    )
  }

}

PlainTagRow.propTypes = {
  tag: PropTypes.object.isRequired,
  enableTagCodes: PropTypes.bool.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
}

export default  PlainTagRow
