import _ from 'lodash'

// Returns true if the agent should see a post chat modal
const hasPostChatAction = (state) => (
  state.pype.details.end_comment_display || (
    state.pype.details.end_tags_display && !_.isEmpty(state.tags.store)
  )
)

export default {
  hasPostChatAction,
}
