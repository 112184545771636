import React from 'react';
import Toast from 'components/ui/toast/Toast';
import flash from 'utils/flash';

class FlashMessage extends React.Component{
  componentDidMount(){
    var message;
    while (message = flash.pop()) {
      this.refs.toast.createToast(message.message, message.key);
    }
  }
  render(){
    return <Toast ref="toast" />
  }
};

export default  FlashMessage;
