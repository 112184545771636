import React from 'react';
import style from './style.css';
import cx from 'classnames';
import SortIcon from './SortIcon';
import PropTypes from 'prop-types';
import _ from 'lodash';

const Column = (props) => {
  return (
    <div className={cx(props.className, style.column)} onClick={props.onClick}>
      {props.children}
      {props.sortable ? <SortIcon order={props.sortingOrder} /> : null}
    </div>
  );
};

Column.propTypes = {
  sortingOrder: PropTypes.string,
  sortable: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node
};

Column.defaultProps = {
  sortingOrder: null,
  sortable: false,
  className: '',
  onClick: _.noop
};

export default  Column;