import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import _ from 'lodash';
import Component from './Component';
import sdk from 'sdk';
const { agentsActions, skillsActions } = sdk;

const mapStateToProps = function(state,props) {
  const generalSkill = _.find(state.skills.skillsStore, {id: 'general'})
  const shouldDisableRoutingToGeneral = generalSkill ? generalSkill.assigned_agent_count < 1 : false;

  return {
    agents: state.agentsForPype.agents,
    skills: state.skills.skillsStore,
    shouldDisableRoutingToGeneral,
    isFetchingAgents: state.agentsForPype.isFetching,
    isFetchingSkills: state.skills.isFetching,
    customerName: state.pype.details.customerName
  }
}

const mapDispatchToProps = (dispatch, props) => {

  let actionCreators = bindActionCreators({
    fetchAgents: agentsActions.fetchAgents,
    deleteSkill: skillsActions.deleteSkill,
    createSkill: skillsActions.createSkill,
    fetchSkills: skillsActions.fetchSkills,
    // updateAgent: agentsActions.updateAgent
  }, dispatch)

  actionCreators = {
    ...actionCreators,
    updateSkill: (skill,agents) => {
      // const agentIds = [...skill.agents];
      // sending agents array to x_skill will always MERGE existing agents with sent ones
      skill.agents = []

      const agentActions = [];
      let assignedAgentCount = 0;

      agents.forEach((agent)=>{

        let skills = agent.skills

        if(agent.isSkillAssigned) {
          skills = _.uniq([...skills, skill.id]);
          assignedAgentCount++;
        } else {
          skills = skills.filter((_skill) => (_skill !== skill.id))
        }
        const data = {
          id: agent.id,
          skills
        }
        agentActions.push(dispatch(agentsActions.updateAgent(data, 'agent')))
      })

      return Promise.all([
        dispatch(skillsActions.updateSkill(skill, {
          assigned_agent_count: assignedAgentCount
        })),
        ...agentActions
      ])
    }
  }
  return actionCreators;
}

export default  connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);
