import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames';
import Icon from './Icon';

const IconButton = (props) => {
  const {src, name, className, width, height, onClick, ...rest} = props
  const cssClass = cx('icon-button', className)
  return (
    <button {...rest} className={cssClass} onClick={onClick}>
      <Icon src={src} name={name} width={width} height={height} />
    </button>
  )
}

IconButton.propTypes = {
  src: PropTypes.string,
  name: PropTypes.string,

  // Optional props
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  onClick: PropTypes.func,
}

IconButton.defaultProps = {
  onClick: () => {},
}

export default  IconButton
