import PropTypes from 'prop-types'
import React from 'react'
import cx from 'classnames';
import css from './StatsWrapper.css';

const Loading = (props) => (
	<div className={cx(css.loadingWrapper, {'no-border': !props.border})}>
		<div>
			<img src={`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-refresh-small.svg`} width="20" height="20" alt="loading"/>
			<span>Loading...</span>
		</div>
	</div>
)
const StatsWrapper = (props) => (
  <div className={cx(props.className, css.wrapper)}>
  	{props.isLoading ? <Loading border={props.border}/> : props.children}
  </div>
)

StatsWrapper.propTypes = {
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  border:PropTypes.bool
}
StatsWrapper.defaultProps = {
	border: true
}
export default  StatsWrapper
