import style from './style.scss';
import React from 'react'
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import SkillItem from '../SkillItem';
import SkillModal from '../SkillModal';
import activityTracker from "../../../../utils/activityTracker";

const getSortedSkills = (skillsStore) => {
  return _.chain(skillsStore)
  .sortBy('name')
  .sortBy((skill) => {
    return skill.id === "general" ? 0 : 1; // show general skill first
  }).value();
}

const getAgentsForSkills = (agents) => {
  /* agentsForSkills: skill-agents map for easy lookup.
  {
    'skillID1': ['John Doe', 'Jane Dee'],
    'skillID2': ['John Doe', 'Jane Dee']
  }
  */
 const agentsForSkills = {};

 _.sortBy(agents, 'last_name')
 .forEach((agent)=>{
   agent.skills.forEach((skill) => {
     const name = `${agent.first_name} ${agent.last_name}`;
     agentsForSkills[skill] = agentsForSkills[skill] || [];

     if(agentsForSkills[skill].indexOf(name) < 0){
       agentsForSkills[skill].push(name)
     }
   })
 });
 return agentsForSkills
}
export class SkillList extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      openSkill: null,
      editingSkill: null,
      isSkillModalVisible: false
    }
    const { customerName } = this.props

    activityTracker.logEvent(activityTracker.eventTypeNames.VIEW_PYPE_SETTING,{settingName: 'skill', customerName});
  }

  componentWillMount () {
    this.props.fetchAgents()
    this.props.fetchSkills()
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.skillsStore.length < this.props.skillsStore.length) {
      this.setState({
        openSkill: null,
        editingSkill: null,
      })
    }
  }

  handleToggle = (skill) => {
    const {state,props} = this;
    const { skillsStore } = props
    const skills = getSortedSkills(skillsStore)
    const skillObj = _.find(skills,{id: skill})
    // Show skill edit modal if no agents assigned to skill
    if(skillObj && skillObj.assigned_agent_count === 0){
      this.handleClickEdit(skillObj)
      return;
    }
    if (state.openSkill === skill) {
      this.setState({openSkill: null})
    } else {
      this.setState({
        openSkill: skill,
        editingSkill: null,
      })
    }
  }

  handleClickEdit = (skill) => {
    this.setState({
      openSkill: null,
      editingSkill: skill,
      isSkillModalVisible: true
    })
  }

  handleCloseEdit = (skill) => {
    this.setState({editingSkill: null})
  }

  handleCloseModal = () => {
    this.setState({isSkillModalVisible: false})
  }

  handleCreateSkill = () => {
    this.setState({
      editingSkill: null,
      isSkillModalVisible: true
    })
  }

  render () {
    const {props, state} = this
    const { skillsStore, agents } = props
    const skills = getSortedSkills(skillsStore)
    const agentsForSkills = getAgentsForSkills(agents)
    return (
      <div className={`SkillList ${style.SkillList}`}>
        <div className={`Content ${style.Content} ${props.flags.scrollableSkillList && style.Scrollable}`}>
          {skills.map((skill) => (
            <SkillItem
              key={skill.id}
              skill={skill}
              isOpen={skill.id === state.openSkill}
              agentsForSkill={agentsForSkills[skill.id]}
              onToggle={this.handleToggle}
              onClickEdit={this.handleClickEdit} />
          ))}
        </div>
        <div className={`CreateSkill ${style.CreateSkill}`} onClick={this.handleCreateSkill}>
          <div>
            <img className={style.Img} src={`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-add-big.svg`}/>
            <span className={style.Text}>Create a skill</span>
          </div>
        </div>
        { this.state.isSkillModalVisible ?
          <SkillModal
            skill={state.editingSkill}
            onSubmit={_.noop}
            closeModal={this.handleCloseModal}/> : null }
      </div>
    )
  }

}

SkillList.propTypes = {
  skillsStore: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetchAgents: PropTypes.func.isRequired
}

export default withLDConsumer()(SkillList);
