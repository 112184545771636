import _ from "lodash";

export const filtersApplied = (dataFilters) =>
  dataFilters.from ||
  dataFilters.to ||
  dataFilters.agent_ids ||
  dataFilters.agent_skills ||
  dataFilters.search_pattern;

export const filterHistory = (data, filters) => {
  if(filters.from && filters.to) {
    const _from = new Date(filters.from).getTime();
    const _to = new Date(filters.to).getTime();
    data.data.chat_sessions =_.filter(data.data.chat_sessions, function(item) {
        return _.inRange(
            item.history[item.history.length -1].chat_msg.timestamp, 
            _from, 
           _to
        );
    });
  }
  if(filters.agent_skills && filters.agent_skills.length > 0) {
    data.data.chat_sessions = _.filter(data.data.chat_sessions, (chat) => filters.agent_skills.includes(chat.skill));
  }
  if(filters.agent_ids && filters.agent_ids.length > 0) {
    const agents_store = window.store.getState().agentsForPype.agents;
    const agents = _.map(filters.agent_ids, (agent) => {
       const agentData = _.find(agents_store, (agent_data) => agent_data.id === agent);
       return `${agentData.first_name} ${agentData.last_name}`;
    });
    data.data.chat_sessions = _.filter(data.data.chat_sessions, (chat) => agents.includes(chat.agent_name));
  }
  return data;
};

export const formatHistoryData = (data, hasMore = false) => {
  const formattedData = { ...data };
  formattedData.data.chat_sessions = _.orderBy(
    formattedData.data.chat_sessions,
    (chat) => chat.history[chat.history.length - 1].timestamp,
    "desc"
  );
  formattedData.data.chat_sessions.forEach((chat, index) => {
    const { history } = chat;
    const last_msg = history[history.length - 1];
    const today = new Date();
    const hasMoreDays = hasMore ? index + 10 : index;
    const last_msg_timeStamp = new Date().setDate(today.getDate() - hasMoreDays);
    const formatted_last_msg = {
      chat_msg: {
        ...last_msg.chat_msg,
        timestamp: last_msg_timeStamp
      }
    };

    formattedData.data.chat_sessions[index].history[history.length - 1] = formatted_last_msg;

    const participant_history = formattedData.data.chat_sessions[index].participant_history;
    for (let i = 0; i < participant_history.length; i++) {
      if (
        participant_history[i].action === "added" &&
        participant_history[i].participant_type === "agent"
      ) {
        const history = participant_history[i];
        formattedData.data.chat_sessions[index].participant_history[i] = {
          ...history,
          time: last_msg_timeStamp
        };
        break;
      }
    }
  });
  return formattedData;
};