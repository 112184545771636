import {STOMPClient} from './STOMPClient'
import {x_list_30_day_chat_sessions} from './constants'

class SDK {
  constructor() {
    this.device_id = null
    this.user_id = null
    this.pype_id = null
  }

  // Initialize
  async init({pype_id, user_id, device_id}) {
    this.device_id = device_id
    this.user_id = user_id
    this.pype_id = pype_id

    await STOMPClient.init({
      pype_id,
      user_id,
      device_id,
    }).connect();
  }

  fetchInitSequence({
    agent_id,
    include_manager_data
  }) {
    return STOMPClient.request({
      name: 'x_init_sequence',
      data: {
        pype_id: this.pype_id,
        agent_id,
        include_manager_data,
      }
    })
  }

  // Heartbeats
  sendHeartbeat({seq_num}) {
    if(!this.device_id) {
      throw new Error('Device ID not found')
    }
    if(!this.user_id) {
      throw new Error('User ID not found')
    }
    STOMPClient.request({
      name: 'x_heartbeat',
      data: {
        device_type: 'browser',
        app_type: 'agent',
        user_id: this.user_id,
        seq_num,
        heartbeat_type: 'ping',
        device_id: this.device_id,
        source: 'client'
      },
    },{disableQueue: true})
  }

  // WIDGETS
  createWidget(data) {
    if(!this.pype_id) {
      throw new Error('Pype ID not found')
    }
    return STOMPClient.request({
      name: 'x_widget',
      data: {
        pype_id: this.pype_id,
        ...data,
      },
    })
  }

  getWidgets() {
    if(!this.pype_id) {
      throw new Error('Pype ID not found')
    }
    return STOMPClient.request({
      name: 'x_list_widgets',
      data: {
        pype_id: this.pype_id
      },
    })
  }

  updateWidget({entity_id, data}) {
    return STOMPClient.request({
      name: 'x_update',
      action: 'update',
      data: {
        entity_id,
        entity_type: 'widget',
        data,
      },
    }, {
      resolveOn: 'ack',
    })
  }

  deleteWidget({ id, stream_id}) {
    if(!this.pype_id) {
      throw new Error('Pype ID not found')
    }

    return STOMPClient.request({
      name: 'x_widget',
      action: 'delete',
      data: {
        pype_id: this.pype_id,
        id,
        stream_id,
      }
    }, {
      resolveOn: 'ack',
    })
  }

  // CURRENT AGENT
  listAgentData({agent_id, skills}) {
    return STOMPClient.request({
      name: 'x_list_agent_data',
      data: skills && skills.length
        ? {
          agent_id,
          skills
        }
        : {
          agent_id
        },
    })
  }
  setStatus({agent_id, status}) {
    if(!this.pype_id) {
      throw new Error('Pype ID not found')
    }

    const options = { resolveOn: 'ack' }

    return STOMPClient.request({
      name: 'x_presence',
      data: {
        pype_id: this.pype_id,
        agent_id,
        status,
      },
    }, options);
  }

  // Agent Chat sessions
  listChatSessionsForAgent({agent_id, more, items_per_page}) {
    if(!this.pype_id) {
      throw new Error('Pype ID not found')
    }
    return STOMPClient.request({
      name: 'x_list_chat_sessions_for_agent',
      action: 'new',
      data: {
        pype_id: this.pype_id,
        agent_id,
        status: 'active',
        more,
        items_per_page
      }
    }, {resolveOn: 'ack'})
  }

  listChatSessionsFor30Days({more, items_per_page, search_pattern, selectedFilters}) {
    if(!this.pype_id) {
      throw new Error('Pype ID not found')
    }

    let data =  {
      pype_id: this.pype_id,
      more,
      items_per_page,
    }
    if(search_pattern) {
      data.search_pattern = search_pattern
    }
    if(selectedFilters) {
      if(selectedFilters.from && selectedFilters.to) {
        data.from = selectedFilters.from;
        data.to= selectedFilters.to;
      };
      if(selectedFilters.selectedSkills && selectedFilters.selectedSkills.length) {
        data.agent_skills = [...selectedFilters.selectedSkills];
      }
      if(selectedFilters.selectedAgents && selectedFilters.selectedAgents.length) {
        data.agent_ids = [...selectedFilters.selectedAgents];
      }
    }
    return STOMPClient.request({
      name: x_list_30_day_chat_sessions,
      action: 'new',
      data
    })
  }

  listEscalatedSessionsForPype({more, items_per_page, search_pattern}) {
    if(!this.pype_id) {
      throw new Error('Pype ID not found')
    }

    let data =  {
      pype_id: this.pype_id,
      more,
      items_per_page,
    }

    if(search_pattern) {
      data.search_pattern = search_pattern
    }
    
    return STOMPClient.request({
      name: 'x_list_escalated_sessions_for_pype',
      action: 'new',
      data,
    }, {resolveOn: 'ack'})
  }

  // CONVERSATIONS
  getConversationHistory({chat_id, from}) {
    return STOMPClient.request({
      name: 'x_snapshot',
      data: {chat_id, from},
    })
  }

  endConversation({chat_id, ended_by}) {
    if(!this.pype_id) {
      throw new Error('Pype ID not found')
    }

    return STOMPClient.request({
      name: 'x_end_chat',
      data: {
        pype_id: this.pype_id,
        chat_id,
        ended_by,
      }
    }, {resolveOn: 'ack'})
  }

  classifyChat(data) {
    return STOMPClient.request({
      name: 'x_classify_chat',
      data: data
    }, {resolveOn:'ack'})
  }

  reassignChat({chat_id, user_id, to_agent,comment, skill }) {
    return STOMPClient.request({
      name: 'x_reassign_chat',
      data: {
        chat_id,
        user_id,
        to_agent,
        comment,
        skill
      }
    },{resolveOn:'ack'})
  }

  notifyTyping({chat_id, from, is_typing}) {
    return STOMPClient.request({
      name: 'x_typing',
      data: {
        chat_id,
        from,
        is_typing,
        from_side: 'agent'
      },
    },{resolveOn:'ack'})
  }

  // Conversation Messages

  createChatMessage({client_msg_id, chat_id, msg, msg_type, file_url, to_be_migrated = false, app_object=false}) {
    if(!this.user_id) {
      throw new Error('User ID not found')
    }
    
    let data = {
      client_msg_id,
      chat_id,
      from: this.user_id,
      from_side: 'agent',
      msg,
      msg_type
    }
    if(app_object) {
      data.app_object = app_object;
    }
    if (file_url) {
      data.file = file_url;
      data.file_status = 'ready';
    }
    return STOMPClient.request({
      name: to_be_migrated ? 'x_chat_msg_v2' : 'x_chat_msg',
      data
    }, {resolveOn: 'ack'})
  }

  readMessages({user_id,chat_id,msg_ids,client_timestamp,to_be_migrated}) {
    return STOMPClient.request({
      name: to_be_migrated ? 'x_read_receipt_v2' : 'x_read_receipt',
      data: {
        chat_id,
        msg_ids,
        client_timestamp,
        user_id
      }
    });
  }


  // USERS
  getUserMeta({user_id}) {
    return STOMPClient.request({
      name: 'x_public_user',
      data: {
        user_id
      },
    })
  }

  getChatSessionsForUser({pype_id, user_id, more, items_per_page}) {
    return STOMPClient.request({
      name: 'x_list_chat_sessions_with_consumer_at_pype',
      data: {
        pype_id,
        user_id,
        more,
        items_per_page,
      }
    })
  }

  // TAGS
  createTag({code, description, id, pype_id, category_id}) {
    let data = {
      code, 
      description,
      pype_id,
      category_id
    }
    if(id) {
      data.id = id
    }
    return STOMPClient.request({
      name: 'x_tag',
      action: 'new',
      data
    }, {resolveOn: 'ack'})
  }
  getTags({pype_id, category_id}) {
    return STOMPClient.request({
      name: 'x_list_tags',
      action: 'get',
      data: {
        pype_id,
        category_id,
      }
    })
  }

  deleteTag(data) {
    return STOMPClient.request({
      name: 'x_tag',
      action: 'delete',
      data
    }, {resolveOn: 'ack'})
  }

  updateTagCategory({pype_id, id, name}) {
    return STOMPClient.request({
      name: 'x_category',
      action: 'update',
      data: {
        pype_id,
        id,
        name,
      },
    }, {resolveOn: 'ack'})
  }

  // PYPE
  updatePype(data) {
    if(!this.pype_id) {
      throw new Error('Pype ID not found')
    }
    return STOMPClient.request({
      name: 'x_update',
      action: 'new',
      data: {
        entity_type: 'pype',
        entity_id: this.pype_id,
        data
      },
    }, {resolveOn: 'ack'})
  }

  updateEventBasedBroadcastsForPype({entity_id, broadcast_stream_id}) {
    return STOMPClient.request({
      name: 'x_update',
      action: 'new',
      data: {
        entity_type: 'pype',
        entity_id,
        data: {
          id: entity_id,
          broadcast_stream_id
        }
      }
    }, {
      resolveOn: 'ack',
    })
  }

  getBroadcastConfiguration() {
    return STOMPClient.request({
      name: 'x_configure_broadcast_sms',
      action: 'new',
      data: {
        iso_country_code: 'US' // NOTE hardcoded as there's no way to get this
      }
    })
  }

  createPatternRule({entity_id, masking_rules}){
    return STOMPClient.request({
      name: 'x_update',
      data: {
        entity_type: 'pype',
        entity_id,
        masking_rules
      }
    }, {
      resolveOn: 'ack',
    })
  }

  // AGENTS
  getAgents({pype_id, skills}) {
    let data = {
      pype_id
    }
    if(skills && skills.length) {
      data.skills = skills
    }
    return STOMPClient.request({
      name: 'x_list_agents',
      action: 'get',
      data
    })
  }

  updateAgent({entity_type, entity_id, data}) {
    return STOMPClient.request({
      name: 'x_update',
      action: 'update',
      data: {
        entity_type, entity_id, data
      }
    }, { resolveOn: 'ack' })
  }

  updateAgentHours({entity_id, data}) {
    return STOMPClient.request({
      name: 'x_update',
      data: {
        entity_type: 'agent_queue',
        entity_id,
        data
      }
    }, { resolveOn: 'ack' })
  }

  // Autoresponders
  getAutoResponders({pype_id}) {
    return STOMPClient.request({
      name: 'x_list_autoresponders',
      action: 'get',
      data: {
        pype_id
      }
    })
  }

  updateAutoResponders({pype_id, responders}) {
    return STOMPClient.request({
      name: 'x_autoresponder',
      action: 'new',
      data: {
        pype_id,
        responders
      }
    }, {resolveOn: 'ack'})
  }

  // Bots
  getBotMetrics({pype_id}) {
    return STOMPClient.request({
      name: 'x_list_bot_metrics',
      data: {
        pype_id
      }
    })
  }

  // Campaigns
  getCampaigns({pype_id, more, items_per_page}) {
    return STOMPClient.request({
      name: 'x_list_campaigns_for_pype',
      action: 'new',
      data: {
        pype_id, 
        more, 
        items_per_page
      }
    }, {resolveOn: 'ack'})
  }

  getCampaignStats({id}) {
    return STOMPClient.request({
      name: 'x_get_campaign_counters',
      data: {
        id
      },
    })
  }

  //Canned responses 
  createCannedResponse(data) {
    return STOMPClient.request({
      name: 'x_canned_response',
      action: 'new',
      data
    },{resolveOn:'ack'})
  }

  getCannedResponses({pype_id}) {
    return STOMPClient.request({
      name: 'x_list_canned_responses',
      action: 'get',
      data: {
        pype_id
      },
    })
  }

  updateCannedResponse(data) {
    return STOMPClient.request({
      name: 'x_canned_response',
      action: 'update',
      data
    },{resolveOn:'ack'})
  }

  deleteCannedResponse(data) {
    return STOMPClient.request({
      name: 'x_canned_response',
      action: 'delete',
      data
    },{resolveOn:'ack'})
  }

  // Skills

  createSkill(data) {
    return STOMPClient.request({
      name: 'x_skill',
      action: 'new',
      data
    },{resolveOn:'ack'})
  }

  getSkills({pype_id}) {
    return STOMPClient.request({
      name: 'x_list_skills',
      action: 'get',
      data: {
        pype_id
      },
    })
  }
  getSkill({pype_id, skill_id}) {
    return STOMPClient.request({
      name: 'x_list_skills',
      action: 'get',
      data: {
        pype_id,
        skill_id
      },
    })
  }

  deleteSkill(data) {
    return STOMPClient.request({
      name: 'x_skill',
      action: 'delete',
      data
    },{resolveOn:'ack'})
  }

  updateSkill(data) {
    return STOMPClient.request({
      name: 'x_skill',
      action: 'new',
      data
    },{resolveOn:'ack'})
  }


  //Streams
  getStreams({pype_id}){
    return STOMPClient.request({
      name: 'x_list_streams',
      action: 'new',
      data: {
        pype_id
      },
    })
  }

  getStreamStats({pype_id, stream_id}){
    return STOMPClient.request({
      name: 'x_list_streams',
      action: 'new',
      data: {
        pype_id,
        stream_id
      },
    })
  }

  getStreamLastBroadcast({pype_id, stream_id, params={}}){
    return STOMPClient.request({
      name: 'x_list_campaigns_for_stream',
      action: 'new',
      data: {
        pype_id,
        stream_id,
        ...params,
      },
    })
  }

  createStream(data){
    return STOMPClient.request({
      name: 'x_stream',
      action: 'new',
      data
    })
  }

  updateStream(data){
    return STOMPClient.request({
      name: 'x_update',
      action: 'new',
      data: {
        entity_type: 'stream',
        entity_id: data.id,
        data
      }
    }, {
      resolveOn: 'ack',
    })
  }

  updateEventBasedBroadcastsForStream({entity_id, data}) {
    return STOMPClient.request({
      name: 'x_update',
      action: 'new',
      data: {
        entity_type: 'stream',
        entity_id,
        data: {
          ...data,
          id: entity_id
        }
      }
    }, {
      resolveOn: 'ack',
    })
  }

}

const _SDK = new SDK();

export default _SDK;
