import style from './style.css'
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

class Popup extends React.Component {

  static propTypes = {
    children: PropTypes.node,
    disabled: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  handleClickMask = () => {
    this.props.onClose()
  }

  handleClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
  }

  render () {
    const {props} = this
    return (
      <div ref='el' className={style.Popup} onClick={this.handleClick}>
        <div className={style.FixedMask} onClick={this.handleClickMask} />
        <div className={style.Arrow} />
        <div className={style.BackMask} />
        <div style={{zIndex: 1}}>
          <div style={{position: 'absolute'}}>
            <div>Are you sure you want to end this chat?</div>
            {props.children}
          </div>
          <div className={style.Buttons}>
            <button disabled={props.disabled} className={classNames(style.Button,style.Cancel)} onClick={props.onClose}>Cancel</button>
            <button disabled={props.disabled} className={classNames(style.Button,style.Submit)} onClick={props.onClose}>Confirm</button>
          </div>
        </div>
      </div>
    )
  }

}

export default Popup
