import React from 'react'
import PropTypes from 'prop-types'

import {Link, withRouter} from 'react-router-dom'
import _ from 'lodash'
import ReactTooltip from 'react-tooltip'
// Local
import DetailPane from '../details/DetailPane'
import TitleBar from '../details/TitleBar'
import ContentBody from '../details/ContentBody'
import SubHeader from '../details/SubHeader'
import Footer from '../details/Footer'
import AppTooltip from '../../shared/AppTooltip'
import SaveButton from '../../ui/forms/SaveButton'
import UnsavedChanges from '../../ui/forms/UnsavedChanges'
import TetheredSelect from '../../ui/tethered-select/TetheredSelect'
import activityTracker from "../../../utils/activityTracker";
import ErrorBoundary from 'components/ui/ErrorBoundary';

const CHANNELS = {
  PYPESTREAM: 'pypestream',
  PYPESTREAM_SMS: 'pypestream_and_sms',
  CONFIGURE_SMS: 'sms',
}
const CHANNELS_OPTIONS = [
  {value: CHANNELS.PYPESTREAM, label: 'Pypestream mobile app'},
  {value: CHANNELS.PYPESTREAM_SMS, label: 'Pypestream mobile app and SMS'}
]
const SETTING_NAME = 'broadcasts';

const getActiveStreams = (streams) => {
  return _.filter(streams, (stream) => (stream.status === 'active'))
}
export class Component extends React.Component {
  constructor(props) {
    super(props);
    const {
      broadcastChannel,
      broadcastSmsLimit,
      broadcastStreamId,
      customerName,
    } = this.props;
    this.state = {
      channel: broadcastChannel || CHANNELS.PYPESTREAM,
      stream: broadcastStreamId,
      smsLimit: broadcastSmsLimit,
      isSubmitting: false,
      errors: {},
    };
    activityTracker.logEvent(activityTracker.eventTypeNames.VIEW_PYPE_SETTING, {
      settingName: SETTING_NAME,
      customerName,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isFetching !== this.props.isFetching) {
      this.setState({
        channel: nextProps.broadcastChannel || CHANNELS.PYPESTREAM,
        stream: nextProps.broadcastStreamId,
        smsLimit: nextProps.broadcastSmsLimit,
      });
    }
  }

  hasChanges = () => {
    const { props, state } = this;
    return (
      state.channel !== props.broadcastChannel ||
      state.stream !== props.broadcastStreamId ||
      state.smsLimit !== props.broadcastSmsLimit
    );
  };

  canSubmit = () => {
    if (Object.keys(_.pickBy(this.state.errors, _.identity)).length) {
      return false;
    }
    if (!this.state.channel || !this.state.stream) {
      return false;
    }
    if (this.props.isFetching || this.props.isFetchingBroadcastConfig) {
      return false;
    }

    return true;
  };

  handleChannelChange = (option) => {
    const { streamsStore } = this.props;
    const streams = getActiveStreams(streamsStore);

    ReactTooltip.hide();

    if (option.value === CHANNELS.PYPESTREAM_SMS) {
      // TODO uncomment when dynamic SMS configuration is enabled for admins.
      // this.props.getBroadcastConfiguration();
      this.setState({ stream: this.props.broadcastSMSStreamId });
    }
    if (option.value === CHANNELS.PYPESTREAM) {
      this.setState({ stream: streams.length === 1 ? streams[0].id : null });
    }
    this.setState({ channel: option.value });
  };

  handleStreamChange = (option) => {
    this.setState({ stream: option.value });
  };

  handleSMSLimitChange = (option) => {
    this.setState({ smsLimit: option.value });
  };

  handleSubmit = () => {
    this.setState({ isSubmitting: true });
    const { channel, stream, smsLimit } = this.state;

    let data = {
      broadcast_stream_id: stream,
      broadcast_channel: channel,
    };

    if (channel === CHANNELS.PYPESTREAM_SMS) {
      data.broadcast_sms_limit = smsLimit || null; // null = 'No limit'
    }

    this.props
      .onSubmit(data)
      .then(() => {
        this.setState({ isSubmitting: false });
        this.handleSuccess();
      })
      .catch((error) => {
        console.warn(error);
        this.setState({ isSubmitting: false });
        this.handleError();
      });
  };

  handleSuccess = () => {
    const { customerName } = this.props;

    window.appCreateToast({
      message: "Changes saved successfully.",
      level: "success",
    });
    activityTracker.logEvent(
      activityTracker.eventTypeNames.UPDATE_PYPE_SETTING,
      {
        settingName: SETTING_NAME,
        action: activityTracker.eventActionType.UPDATE,
        customerName,
      }
    );
  };

  handleError = () => {
    window.appCreateToast({
      message: "Error occured while updating Broadcasts",
      level: "error",
    });
  };

  getChannels = () => {
    const channels = [CHANNELS_OPTIONS[0]];
    if (this.props.isSmsEnabled) {
      channels.push(CHANNELS_OPTIONS[1]);
    }
    return channels;
  };

  getStreams = () => {
    const { props } = this;
    const { streamsStore } = props;
    const streams = getActiveStreams(streamsStore);

    if (this.state.channel === CHANNELS.PYPESTREAM_SMS) {
      // TODO sms data is configured for each EBB enabled stream in backend. Once channel is
      // saved as `pypestream` and new stream is assigned, broadcast_stream_id in
      // pype.details will point to this new stream which may not have any
      // SMS data. Therefore SMS data can be retrieved only from pre-configured EBB stream.

      // const {broadcastStreamId} = this.props
      // const streamsWithSms = props.streams.filter((stream) => (stream.id === broadcastStreamId))
      const streamsWithSms = streams.filter(
        (stream) => !!stream.sms_phone_number
      );

      return streamsWithSms.map((stream) => ({
        value: stream.id,
        label: stream.stream_name,
      }));
    }
    return streams.map((stream) => ({
      value: stream.id,
      label: stream.stream_name,
    }));
  };

  getSMSLimits = () => {
    const limits = [
      {
        value: 0,
        label: "No limit",
      },
    ];
    for (let i = 1; i <= 10; i++) {
      limits.push({
        value: i,
        label: i,
      });
    }
    return limits;
  };
  selectOptionRenderer = (option) => {
    return <div className="Select-checked">{option.label}</div>;
  };
  renderChannelOption = (option) => {
    return (
      <div className="Select-checked">
        <span className="m-r-5">{option.label}</span>
        {option.value === CHANNELS_OPTIONS[1].value ? (
          <AppTooltip tooltipKey="admin_tab.broadcast_tab.channel" />
        ) : null}
      </div>
    );
  };

  render() {
    const { props, state } = this;
    const shouldShowSMSLimit =
      props.isSmsEnabled && state.channel === CHANNELS.PYPESTREAM_SMS;
    return (
      <DetailPane data-test="broadcast-component" className="has-footer">
        <ErrorBoundary>
          <ContentBody>
            <section style={{ paddingTop: 0 }}>
              <SubHeader>Event based broadcasts (advanced)</SubHeader>
              <section>
                <p>
                  Send notifications to your customers based on specific
                  triggers in the Pypestream app or by SMS. This feature is
                  available via&nbsp;
                  <Link to="pypestream-api">Pypestream's API.</Link>
                </p>
                <p className={"label-text m-t-20 m-b-5"}>CHANNELS</p>
                <TetheredSelect
                  name="channels"
                  data-test="channels-select"
                  value={this.getChannels().filter(
                    ({ value }) => value === state.channel
                  )}
                  options={this.getChannels()}
                  searchable={false}
                  clearable={false}
                  optionRenderer={this.renderChannelOption}
                  placeholder="Select a channel"
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  onChange={this.handleChannelChange}
                />
                <p className={"label-text m-t-20 m-b-5"}>STREAM</p>
                <TetheredSelect
                  name="streams"
                  value={this.getStreams().filter(
                    ({ value }) => state.stream === value
                  )}
                  options={this.getStreams()}
                  searchable={false}
                  clearable={false}
                  placeholder="Select a Stream"
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  optionRenderer={this.selectOptionRenderer}
                  onChange={this.handleStreamChange}
                />
                {shouldShowSMSLimit ? (
                  <div>
                    <div className={"label-text m-t-20 m-b-5"}>
                      <span className="m-r-5">SMS PER USER LIMIT</span>
                      <AppTooltip tooltipKey="admin_tab.broadcast_tab.sms_limit" />
                    </div>
                    <TetheredSelect
                      data-test="sms-limit"
                      name="sms_limit"
                      value={this.getSMSLimits().filter(
                        ({ value }) => state.smsLimit === value
                      )}
                      options={this.getSMSLimits()}
                      searchable={false}
                      clearable={false}
                      placeholder="SMS Limit"
                      optionRenderer={this.selectOptionRenderer}
                      onChange={this.handleSMSLimitChange}
                    />
                  </div>
                ) : null}
              </section>
            </section>
          </ContentBody>
        </ErrorBoundary>
        <Footer>
          {this.hasChanges() && this.canSubmit() ? <UnsavedChanges /> : null}
          <SaveButton
            data-test="save-button"
            className="btn-primary"
            isLoading={this.state.isSubmitting}
            disabled={!this.canSubmit() || !this.hasChanges()}
            onClick={this.handleSubmit}
          >
            Save changes
          </SaveButton>
        </Footer>
      </DetailPane>
    );
  }
}

Component.propTypes = {
  broadcastChannel: PropTypes.string,
  broadcastSmsLimit: PropTypes.number,
  isSmsEnabled: PropTypes.bool.isRequired,
  broadcastSmsAccount: PropTypes.string,
  broadcastStreamId: PropTypes.string,
  streamsStore: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isFetchingBroadcastConfig: PropTypes.bool.isRequired,
  pypeName: PropTypes.string.isRequired
}

export default  withRouter(Component)
