import React from "react";
import {NavLink} from "react-router-dom";
import AppTooltip from 'components/shared/AppTooltip';
import {connect} from "react-redux";
import Modal from 'react-modal';
import {withRouter} from 'react-router-dom'
import {
  doesUserHasRole,
  PYPE_AGENT_ROLES
} from "bot-user-session";
import PypesListDropdown from 'components/ui/PypesListDropdown'
import {
  Bottom
} from './helpers'
import sdk from 'sdk'

import List from './List';
import ListItem from './ListItem';

const { utils, pypesActions, pypeActions } = sdk
const { getUnreadChatSessions } = utils.agentChatSessions;

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    overflow: 'visible',
    maxWidth: '400px'
  }
};

const activeStyle = {
  background: 'rgba(45,124,225,.07)',
  color: '#2e7de1'
}
class Sidebar extends React.Component{
  state = {
    newPypeId: null
  }
  
  handlePypeSelect = (pypeId) => {
    const { chatSessions, setPype, session } = this.props;

    const isAgent = doesUserHasRole(PYPE_AGENT_ROLES, pypeId);

    // current role
    if(isAgent && chatSessions.length) {
      this.setState({
        newPypeId: pypeId,
        isModalOpen: true
      })
    } else {
      // redirect based on role after pype switch
      const redirectTargetAfterSwitch = session.roles[pypeId].includes('agent') ? 'chats' : 'overview';
      setPype(pypeId);
      window.location.href = `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/${pypeId}/${redirectTargetAfterSwitch}`;
    }
  }

  confirmPypeSwitch = () => {
    const { setPype } = this.props;
    const { newPypeId } = this.state;

    if(!newPypeId) throw new Error('Pype ID not set');

    setPype(newPypeId);
    window.location.href = `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/${newPypeId}/chats`
  }

  closeModal = () => {
    this.setState({
      newPypeId: null,
      isModalOpen: false
    })
  }

  goToPath = (path) => {
    this.props.history.push(path);
  };

  render () {
    const { pypes, unreadConversationsCount, match } = this.props;
    const activePypeId = match.params.pypeId
    const { isModalOpen } = this.state;
    const hasUnreadMessages = unreadConversationsCount > 0;

    var items = this.props.items.map(function(item){
      var suffix = null;

      if(item.id == 'Messages' && hasUnreadMessages){
        var count = (unreadConversationsCount > 99) ? '99+' : unreadConversationsCount;
        suffix = (<span className="label label-danger m-l-10 msg-count">{count}</span>);
      }

      var icon = <div className={"sidebar-icon " + item.iconClass}></div>;

      var linkProps = item.path && item.path.match(/^[a-z]+:\/\//i)
        ? { target: '_blank' }
        : {};

      var content = <div><div className="title">{item.title} {suffix}</div></div>;

      const currentPath = this.props.history.location.pathname;

      const isCollapsible = item.collapsible;

      const children = item.children;

      return (
        <ListItem key={item.id} onClick={() => this.goToPath(`${match.url}${item.path}`)} {...linkProps} autoid={`${item.path.slice(1)}`} selected={'/' + currentPath.split('/')[currentPath.split('/').length - 1] === item.path.toLowerCase() ? true : false} collapsible={isCollapsible} label={item.title}>
            {children.length >= 1 ? children.map(child => 
              <ListItem key={child.id} onClick={() => this.goToPath(`${match.url}${child.path}`)} {...linkProps} autoid={`${child.path.slice(1)}`} selected={'/' + currentPath.split('/')[currentPath.split('/').length - 1] === child.path.toLowerCase() ? true : false} collapsible={child.isCollapsible} label={child.title} />
            ) : null}
        </ListItem>
      );
    },this);

    return (
      <React.Fragment>
        <List>
          {items}
        </List>
        {/* <Bottom>
          <PypesListDropdown
            pypes={pypes}
            activePypeId={activePypeId}
            showList={!isModalOpen}
            onPypeSelect={this.handlePypeSelect}
          />
          <span className="logo-wrapper">
            <img src={`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/new_p_icon_xs.svg`} width="46" height="46" />
          </span>
        </Bottom> */}

        <Modal
          isOpen={isModalOpen}
          onRequestClose={this.closeModal}
          style={customStyles}>
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" onClick={this.closeModal}>
                <img src={`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-modal-close.svg`} width="15" height="15" />
              </button>
              <h4 className="modal-title">Switching Pypes</h4>
            </div>
            <div className="modal-body">
              <p>Are you sure you want to leave? Switching Pypes will reassign
                all of your chats to another agent. </p>
            </div>
            <div className="modal-footer text-center">
              <button
                type="button"
                className="btn btn-default"
                onClick={this.closeModal}
              >Cancel</button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.confirmPypeSwitch}
              >Switch Pype</button>
            </div>
          </div>
        </Modal>
      </React.Fragment>)
  }
}

var mapStateToProps = function(state){
    return {
      unreadConversationsCount: getUnreadChatSessions(state).length,
      pypes: state.pypes.pypes,
      isFetching: state.pypes.isFetching,
      chatSessions: state.agentChatSessions.chatSessions,
      session: state.session
    };
};

const mapDispatchToProps = dispatch => ({
  fetchPypes: () => dispatch(pypesActions.fetchPypes()),
  setPype: (id) => dispatch(pypeActions.setPype(id)),
});

export default  withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar));
