import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import TimeAgo from "react-timeago";
import css from './QuickStats.css';
import sdk from 'sdk';
import cx from 'classnames';
import StatsWrapper from './StatsWrapper';
// import MultipleSelection from './DropdownIndicator'
import { components } from "react-select";
import DropdownIndicator from './DropdownIndicator';
import Select from './select';
import Checkbox from 'components/ui/forms/Checkbox';
import activityTracker from '../../../utils/activityTracker';
import featureFlags from 'utils/featureFlags'
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

const { pypeActions, streamActions, agentsActions, agentActions, botsActions, skillsActions } = sdk;


const pad = (n) => {
	return (n < 10) ? ("0" + n) : n;
}
const Option = props => {
	return (
		<div className={css.optionList}>
			<components.Option {...props}>
				<Checkbox
					value={props.isSelected}
					onClick={() => null}
				/>
				<label className={css.dropdownLabel}>{props.label}</label>
			</components.Option>
		</div>
	);
};

const ValueContainer = (props) => {
	const { getValue, children } = props;
	const [...rest] = getValue();
	let label = '';
	rest.forEach((data, index) => {
		label += `${data.label}${rest.length !== index + 1 ? ", " : ""}`;
	})
	return (
		<components.ValueContainer {...props}>
			{label}
			{children}
		</components.ValueContainer>
	);
};

const getTimeFormat = (seconds, unitShort = false) => {
	const secondsInMinute = 60;
	const secondsInHours = secondsInMinute * 60;
	const secondsInDays = secondsInHours * 24;
	const duration = moment.duration(seconds * 1000);
	let unit;
	let value;
	if (seconds === 0 || isNaN(seconds)) {
		unit = unitShort ? '' : 'minutes';
		value = '–';
	} else if (seconds < secondsInMinute) {
		unit = unitShort ? 'sec' : 'seconds';
		value = pad(duration.seconds());
	} else if (seconds < secondsInHours) {
		unit = unitShort ? 'min' : 'minutes';
		value = pad(duration.minutes()) + ":" + pad(duration.seconds());
	} else if (seconds < secondsInDays) {
		unit = unitShort ? 'h' : 'hours';
		value = pad(duration.hours()) + ":" + pad(duration.minutes());
	} else {
		unit = unitShort ? 'hours' : 'hours';
		value = '24+';
	}
	return {
		value,
		unit
	}
}

const getFormattedChatCount = (format, count, total) => {
	if (!total) return 0;
	return format === 'perc' ? parseFloat((count / total * 100).toFixed(1)) + '%' : count;
}

const numberOrDash = (number) => {
	return number ? number : '–';
}

const SummaryLabel = () => (
	<span className={ "small " + css.hint}>Metrics reflect current activity or daily summary</span>
)

export class QuickStats extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			activeChatCountFormat: 'perc',
			lastUpdatedTime: Date.now(),
			optionSelected: localStorage.getItem("skill") && JSON.parse(localStorage.getItem("skill")).length > 0 ?
				JSON.parse(localStorage.getItem("skill"))
				: {
					label: "Show All",
					value: "show all"
				}
		};
		this.refreshInterval = null
	}

	componentWillUnmount() {
		// Get time spent on overview time in seconds
		const timeSpent = ((new Date().getTime() - this.state.startTime)/1000);

		activityTracker.logEvent(activityTracker.eventTypeNames.TIME_SPENT_ON_OVERVIEW, {
			timeSpent: 
				timeSpent < 60 ?
					`${Math.floor(timeSpent)} seconds`
					: `${Math.floor(timeSpent/60)} minutes`,
		});

		clearInterval(this.refreshInterval)
	}

	componentDidMount() {
		const { skills } = this.props;
		if (featureFlags.isAgentGroupMetricsEnabled() &&
			(skills && skills.length > 0) &&
			(localStorage.getItem("skill") && JSON.parse(localStorage.getItem("skill")).length > 0)
		) {
			const selectedSkills = JSON.parse(localStorage.getItem("skill"));
			const filterData = selectedSkills.filter(o1 => skills.some(o2 => o1.value === o2.id));
			this.setState({ optionSelected: filterData });
			localStorage.setItem("skill", JSON.stringify(filterData))
		}
		let filterData = [];
		if (featureFlags.isAgentGroupMetricsEnabled() && 
			localStorage.getItem("skill") && 
			JSON.parse(localStorage.getItem("skill")).length > 0
		) {
			let data = JSON.parse(localStorage.getItem("skill"));
			filterData = data.filter(item => item.value !== "show all");
			this.props.selectedSkill(filterData);
		}
		this.setState({startTime: new Date().getTime()});
		this.handleFilterSkills(filterData);

		this.refreshInterval = setInterval(() => {
			this.handleRefresh()
		}, 30000)
	}
	toggleActiveChatCountFormat(activeChatCountFormat) {
		this.setState({
			activeChatCountFormat
		})
	}
	handleBlur = () => {
		if (localStorage.getItem("skill") && JSON.parse(localStorage.getItem("skill")).length === 0) {
			const selected = {
				label: "Show All",
				value: "show all"
			}
			this.setState({
				optionSelected: selected
			});
		}
	}
	handleRefresh = () => {
		this.setState({
			lastUpdatedTime: Date.now()
		})
		let filterData = [];
		if (localStorage.getItem("skill") && JSON.parse(localStorage.getItem("skill")).length > 0) {
			let data = JSON.parse(localStorage.getItem("skill"))
			filterData = data.filter(item => item.value !== "show all")
		}
		this.handleFilterSkills(filterData);
		this.props.fetchSkills();
		this.props.fetchStreamsIfNeeded(true);
		this.props.fetchBotsStats();
		if (!this.props.flags.pe18507) {
			activityTracker.logEvent(activityTracker.eventTypeNames.REFRESH_OVERVIEW);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if(this.state.optionSelected.length < prevState.optionSelected.length) {
			activityTracker.logEvent(activityTracker.eventTypeNames.SKILLS_FILTER_REMOVE_FILTER);
		}

		if(this.state.optionSelected.length > prevState.optionSelected.length) {
			activityTracker.logEvent(activityTracker.eventTypeNames.SKILLS_FILTER_ADD_FILTER);
		}
	}

	handleChange = selected => {
		const dataWithoutShowAll = selected.filter(item => item.value !== "show all")
		const dataWithShowAll = selected.filter(item => item.value === "show all")
		const filteredIndex = selected.findIndex((item) => item.value === "show all");
		if (selected.length && dataWithoutShowAll.length === 0) {
			this.setState({
				optionSelected: selected
			});
		} else {
			const data = (filteredIndex === 0 || filteredIndex === -1) ? dataWithoutShowAll : dataWithShowAll;
			this.setState({
				optionSelected: data
			})
		}
		const filterData = (filteredIndex === 0 || filteredIndex === -1) ? dataWithoutShowAll : [];
		localStorage.setItem("skill", JSON.stringify(filterData))
		this.props.selectedSkill(filterData)
		this.handleFilterSkills(filterData);
	}
	handleFilterSkills = (filterData) => {
		const skills = filterData.length ? filterData.map((item) => `skills[]=${item.value}`).join('&') : "";
		const agentSkills = filterData.length ? filterData.map((item) => item.value) : [];
		this.props.fetchPypeMetrics(skills)
		this.props.fetchAgent(agentSkills);
		this.props.fetchAgents(agentSkills);
	}

	render() {
		const { props } = this;
		let skills = props.skills.map(skill => {
			return { value: skill.id, label: skill.name }
		});
		skills.unshift({ value: "show all", label: "Show All" })
		const { activeChatCountFormat, lastUpdatedTime } = this.state;
		const {
			metrics,
			isFetchingPypeDetails,
			isFetchingStreams,
			isFetchingAgents,
			agentSessionsEnded,
			agentSessionsEndedHistorical,
			agentDailyResponseTime,
			agentWeeklyResponseTime,
			role,
			isPMRoutingEnabled
		} = props;
		const {
			active_agent_sessions,
			active_bot_sessions,
			active_sessions,
			current_queue_size,
			daily_average_queue_size,
			total_average_queue_size,
			total_average_queue_time,
			daily_average_response_time,
			total_average_response_time,
			current_wait_time
		} = metrics;

		if (!isPMRoutingEnabled) return null;

		const isAdmin = role === 'pype_admin'
		const isAgent = role === 'agent'

		const formattedActiveAgentSessions = getFormattedChatCount(activeChatCountFormat, active_agent_sessions, active_sessions);
		const formattedActiveBotSessions = getFormattedChatCount(activeChatCountFormat, active_bot_sessions, active_sessions);

		const currentWaitTime = getTimeFormat(current_wait_time);
		const historicalWaitTime = getTimeFormat(total_average_queue_time, true);

		const responseTime = isAgent ? getTimeFormat(agentDailyResponseTime) : getTimeFormat(daily_average_response_time);
		const historicalResponseTime = isAgent ? getTimeFormat(agentWeeklyResponseTime, true) : getTimeFormat(total_average_response_time, true);

		const isDataLoading = isFetchingPypeDetails || isFetchingStreams || isFetchingAgents;
		return (
			<div className="">
				<div className="pull-left">
					<a className="btn btn-link no-padding" onClick={()=>{
						if(this.props.flags.pe18507){
							activityTracker.logEvent(activityTracker.eventTypeNames.REFRESH_OVERVIEW);
						}
						this.handleRefresh();
					}}>
						<img src={`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-refresh.svg`} width="30" height="30" />
						{isDataLoading ?
							<span className="btn-link m-l-5 small">Updating...</span> :
							<span className="btn-link m-l-5 small">Last updated: <TimeAgo date={lastUpdatedTime} /></span>}
					</a>
				</div>
				<div className="pull-right">
					{featureFlags.isAgentGroupMetricsEnabled() && isAdmin && skills.length > 2 &&
						<span className={css.dropdownItems}>
							<h6 className={css.dropdownText}>SKILLS FILTER</h6>
							<div className={css.dropdownContent} data-cy="skill-select">
								<Select
									components={{
										DropdownIndicator,
										Option,
										ValueContainer,
										MultiValue: () => null,
										ClearIndicator: () => null,
									}}
									options={skills}
									classNamePrefix="react-select"
									placeholder=""
									onBlur={this.handleBlur}
									onChange={this.handleChange}
									isMulti
									closeMenuOnSelect={false}
									value={this.state.optionSelected}
									styles={{
										placeholder: base => ({
											...base,
										}),
									}}
								/>
							</div>
						</span>
					}
					{((isAdmin && !featureFlags.isAgentGroupMetricsEnabled()) || !isAdmin) && (
						<SummaryLabel/>
                    )}
				</div>
				<div className="clearfix"></div>
				<StatsWrapper className="m-t-10 container-fluid" isLoading={isFetchingPypeDetails}>
					<div className="row">
						{!isAgent ? (
							<div className={"col-sm-3 current-active-status-wrapper "}>
								<div className={css.box}>
									<div className={css.boxContent}>
										<h6 className="no-margin">CURRENT ACTIVE CHATS</h6>
										<h1 className="bold m-t-10">{numberOrDash(active_sessions)}</h1>
										<span className="pull-left">chats</span>
										{isAdmin ?
											<div className={css.filters + " pull-right"}>
												<a className={cx("btn btn-link no-padding", { "disabled": activeChatCountFormat === 'num' })} onClick={this.toggleActiveChatCountFormat.bind(this, 'num')}>
													<span>#</span>
												</a>
												<a className={"btn btn-link no-padding m-l-5 m-r-5 disabled"}>
													<span>-</span>
												</a>
												<a className={cx("btn btn-link no-padding", { "disabled": activeChatCountFormat === 'perc' })} onClick={this.toggleActiveChatCountFormat.bind(this, 'perc')}>
													<span>%</span>
												</a>
											</div> : null}
										<div className="clearfix"></div>
									</div>
									<div className={cx({ 'no-padding': !isAdmin }, css.boxFooter)}>
										{isAdmin ?
											<div className="row">
												<div className={"col-sm-6 " + css.separator}>
													<h6 className="no-margin">AGENTS</h6>
													<h4 className="no-margin">{numberOrDash(formattedActiveAgentSessions)}</h4>
												</div>
												<div style={{paddingLeft: "5px"}} className="col-sm-6">
													<h6 className="no-margin">SOLUTIONS</h6>
													<h4 className="no-margin">{numberOrDash(formattedActiveBotSessions)}</h4>
												</div>
											</div> : <img src={`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/tile-pattern.svg`} />}
									</div>
								</div>
							</div>) : null}
						<div className={"col-sm-3 current-waitlist "}>
							<div className={css.box}>
								<div className={css.boxContent}>
									<h6 className="no-margin">{isAgent ? 'CURRENT PYPE WAITLIST' : 'CURRENT WAITLIST'}</h6>
									<h1 className="bold m-t-10">{numberOrDash(current_queue_size)}</h1>
									<span className="pull-left">users</span>
									<div className="clearfix"></div>
								</div>
								<div className={css.boxFooter}>
									<h6 className="no-margin">ALL TIME AVG</h6>
									<h4 className="no-margin">{numberOrDash(total_average_queue_size)}</h4>
								</div>
							</div>
						</div>
						{isAgent ? (
							<div className={"col-sm-3 "}>
								<div className={css.box}>
									<div className={css.boxContent}>
										<h6 className="no-margin">CHATS ENDED</h6>
										<h1 className="bold m-t-10">{numberOrDash(agentSessionsEnded)}</h1>
										<span className="pull-left">chats</span>
										<div className="clearfix"></div>
									</div>
									<div className={css.boxFooter}>
										<h6 className="no-margin">ALL TIME AVG</h6>
										<h4 className="no-margin">{numberOrDash(agentSessionsEndedHistorical)}</h4>
									</div>
								</div>
							</div>) : null} {isAdmin ? (
								<div className={"col-sm-3 "}>
									<div className={cx({ 'red-box': /24\+/.test(currentWaitTime.value) }, css.box)}>
										<div className={css.boxContent}>
											<h6 className="no-margin">LONGEST WAIT TIME</h6>
											<h1 className="bold m-t-10">{currentWaitTime.value}</h1>
											<span className="pull-left">{currentWaitTime.unit}</span>
											<div className="clearfix"></div>
										</div>
										<div className={css.boxFooter}>
											<h6 className="no-margin">ALL TIME AVG</h6>
											<h4 className="no-margin">{historicalWaitTime.value + " " + historicalWaitTime.unit}</h4>
										</div>
									</div>
								</div>) : null}
						<div className={"col-sm-3 "}>
							<div className={cx({ 'red-box': /24\+/.test(responseTime.value) }, css.box)}>
								<div className={css.boxContent}>
									<h6 className="no-margin">FIRST REPLY TIME</h6>
									<h1 className={"bold m-t-10"}>{responseTime.value}</h1>
									<span className={"pull-left"}>{responseTime.unit}</span>
									<div className="clearfix"></div>
								</div>
								<div className={css.boxFooter}>
									<h6 className="no-margin">ALL TIME AVG</h6>
									<h4 className="no-margin">{historicalResponseTime.value + " " + historicalResponseTime.unit}</h4>
								</div>
							</div>
						</div>
					</div>
				</StatsWrapper>
				{featureFlags.isAgentGroupMetricsEnabled() && isAdmin && (
					<div className="pull-right content-right">
						<SummaryLabel/>
					</div>
				)}
			</div>
		);
	}
}

QuickStats.propTypes = {
	role: PropTypes.string.isRequired,
	metrics: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
	isFetchingPypeDetails: state.pype.isFetching,
	isFetchingStreams: state.streamsForPype.isFetching,
	isFetchingAgents: state.agentsForPype.isFetching,
	agentSessionsEnded: state.agent.daily_sessions_ended,
	agentSessionsEndedHistorical: state.agent.daily_sessions_ended,
	agentDailyResponseTime: state.agent.daily_response_time,
	agentWeeklyResponseTime: state.agent.weekly_response_time,
	skills: state.skills.skillsStore,
	isPMRoutingEnabled: state.pype.details.agent_routing === 'pypestream'
});


const mapDispatchToProps = (dispatch) => (
	bindActionCreators({
		fetchAgent: agentActions.fetchAgent,
		fetchAgents: agentsActions.fetchAgents,
		fetchBotsStats: botsActions.fetchBotsStats,
		fetchStreamsIfNeeded: streamActions.fetchStreamsIfNeeded,
		fetchPypeMetrics: pypeActions.fetchPypeMetrics,
		fetchSkills: skillsActions.fetchSkills,
		selectedSkill: skillsActions.selectedSkill
	}, dispatch)
)

const QuickStatsWithRedux = connect(
	mapStateToProps,
	mapDispatchToProps
)(QuickStats);



const QuickStatsWithFeatureFlag = withLDConsumer()(QuickStatsWithRedux);

export default QuickStatsWithFeatureFlag;
