import { RECEIVED_RESPONSE, RECEIVED_ACK } from './../constants/index';
import StompQueue from './../StompQueue';

const stompResponseMiddleware = store => next => action => {
  let result = next(action);

  switch (action.type) {
    case RECEIVED_RESPONSE:
      const { payload: { correlation_id, data } } = action;

      if (StompQueue.isQueued(correlation_id)) {
        // console.log(`found queued promise on ID: ${correlation_id}`);
        const savedPromise = StompQueue.dequeue(correlation_id);
        data.correlation_id = correlation_id
        savedPromise.resolve(data);
      }

      break;
    case RECEIVED_ACK:
      if (StompQueue.isQueued(action.ack.correlation_id) &&
          action.ack.status === 'error') {
        var promise = StompQueue.dequeue(action.ack.correlation_id)
        if (promise != null) {
          promise.reject(action.ack.details);
        }
      }
      break;
    default:
    break;
  }

  return result;
};

export default stompResponseMiddleware;
