import _ from 'lodash';
import { connect } from 'react-redux';
import Component from './Component';
import sdk from 'sdk';
const { skillsActions, agentsActions } = sdk;

const mapStateToProps = function(state) {
  return {
    skillsStore: state.skills.skillsStore,
    agents: state.agentsForPype.agents,
    customerName: state.pype.details.customerName
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchAgents() {
    const action = agentsActions.fetchAgents()
    return dispatch(action)
  },
  fetchSkills() {
    const action = skillsActions.fetchSkills()
    return dispatch(action)
  }
})

export default  connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);
