import style from './style.css';
import React from 'react'
import PropTypes from 'prop-types'

import classNames from 'classnames';
import activityTracker from "../../../../utils/activityTracker";
import ErrorBoundary from 'components/ui/ErrorBoundary';

class EditPanel extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      isModalOpen: false,
      name: props.api.friendly_name || '',
      isSubmitting: false,
      duplicate: false,
    }
  }

  componentDidMount () {
    this.refs.input.select()
    window.addEventListener('keydown', this.onKeyDown)
  }

  componentWillUnmount () {
    window.removeEventListener('keydown', this.onKeyDown)
  }

  onKeyDown = (event) => {
    if (event.keyCode === 27 || event.which === 27) {
      event.preventDefault()
      this.handleClose()
    }
  }

  isFormValid = () => {
    return this.state.name.trim() !== '' && !this.state.duplicate
  }

  isDuplicate = (name) => {
    for (var apiIndex = 0; apiIndex < this.props.apis.length; apiIndex++) {
      if (this.props.apis[apiIndex].friendly_name === name.trim()) {
          return this.setState({duplicate: true})
        }
      }
    return this.setState({duplicate: false});
  }

  handleInput = (event) => {
    if (this.state.isSubmitting) {
      return
    }
    this.setState({name: event.target.value}, () => this.isDuplicate(this.state.name))
  }

  submitMessage = () => {
    const { customerName } = this.props

    if (this.props.action === 'create') {
      activityTracker.logEvent(activityTracker.eventTypeNames.UPDATE_PYPE_SETTING,{settingName: 'api keys',action: activityTracker.eventActionType.CREATE, customerName});

      return {
        message: 'API key successfully created',
        level: 'success',
      }
    } else {
      activityTracker.logEvent(activityTracker.eventTypeNames.UPDATE_PYPE_SETTING,{settingName: 'api keys',action: activityTracker.eventActionType.UPDATE, customerName});

      return {
        message: 'Changes successfully saved.',
        level: 'success',
      }
    }
  }

  errorMessage = () => {
    if (this.props.action === 'create') {
      return {
        message: 'Encountered an error creating API key',
        level: 'error',
      }
    } else {
      return {
        message: 'Encountered an error updating API key.',
        level: 'error',
      }
    }
  }

  handleSubmit = (event) => {
    const { customerName } = this.props
    
    if (event) {
      event.preventDefault()
    }
    if (!this.isFormValid() || this.state.isSubmitting) {
      return
    }
    this.setState({isSubmitting: true})
    this.props.onSubmit(this.props.api, this.state.name.trim()).then(() => {
      window.appCreateToast(this.submitMessage())
      this.props.onClose(this.props.api)
      activityTracker.logEvent(activityTracker.eventTypeNames.UPDATE_PYPE_SETTING,{settingName: 'api keys',action: activityTracker.eventActionType.CREATE, customerName});
    }).catch((error) => {
      console.warn(error)
      window.appCreateToast(this.errorMessage())
      this.setState({isSubmitting: false})
    })
  }

  handleClose = () => {
    this.props.onClose(this.props.api)
  }


  closeIcon = () => {
    if (this.props.action === 'create') {
      return <img className={style.Img} src={`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/cancel.svg`} onClick={this.handleClose} />
    } else {
      return <img className={style.Img} src={`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/trash-icon.svg`} onClick={this.openWarningModal} />
    }
  }

  render () {
    const {props, state} = this
    const className = classNames(style.EditPanel, {
      [style.isSubmitting]: state.isSubmitting,
    })
    const containerHeight = classNames(style.editingContainer, {
      [style.increaseHeight]: this.state.duplicate,
    })
    return (
      <div className={containerHeight}>
        <ErrorBoundary>
          <div className={className}>
            <form onSubmit={this.handleSubmit} style={{flex: 1}}>
              <input ref='input' className={style.Input} type='text' value={state.name}
                disabled={state.isSubmitting} onChange={this.handleInput} placeholder='API key name'/>
            </form>
            <div className={style.Controls}>
              <span className={classNames(style.Check, {
                [style.isDisabled]: !this.isFormValid(),
              })} onClick={this.handleSubmit}></span>
              {this.closeIcon()}
            </div>
          </div>
          {this.state.duplicate && <span className={style.Error}>You can't have two keys with the same name.</span>}
        </ErrorBoundary>
      </div>
    )
  }

}

EditPanel.propTypes = {
  api: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default  EditPanel
