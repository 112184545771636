import {connect} from 'react-redux';
import Component from './Component';

const mapStateToProps = (state) => {
  return {
    pypeName: state.pype.details.name,
    customerName: state.pype.details.customerName
  }
};

export default  connect(mapStateToProps)(Component)
