import style from './style.css';
import React from 'react'

const Info = () => (
  <div className={style.Info}>
    <div className={style.Heading}>
      Configure your Web Conversational Interface
    </div>
    <p className={style.Text}>
      Create, edit and customize Web Conversational Interfaces for your website and
      manage your domains.
    </p>
  </div>
)

export default  Info
