import React from 'react';
import PropTypes from 'prop-types';
// import { Sidebar } from 'components/ui';
import { Wrapper, ContentWrapper, Content } from './helpers';

/** Main layout with header and sidebar */
const LayoutMain = ({ children }) => (
  <Wrapper>
    {/* <Sidebar /> */}
    <ContentWrapper>
      {/* <Header /> */}
      <Content>{children}</Content>
    </ContentWrapper>
  </Wrapper>
);

LayoutMain.defaultProps = {};

LayoutMain.propTypes = {
  /** child nodes */
  children: PropTypes.node.isRequired,
};

export default LayoutMain;
