// Basic externals
import _ from 'lodash';
import cx from 'classnames';
// React, redux
import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Popover from 'react-simple-popover';

// UI
import Spinner from 'components/ui/pype-spinner/PypeSpinner';
import TetheredSelect from 'components/ui/tethered-select/TetheredSelect';

import css from './style.css';


// NOTE popover plugin doesn't support css object.
const popoverCss = {
  width: '320px',
  boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
  borderRadius: '4px'
}

const ANY_AGENT_VALUE = 'any';
const GENERAL_SKILL = 'general';

const getOnlineAgents = (agentsStore, userId) => {
  const onlineAgents = _.chain(agentsStore)
  .filter((agent)=>((agent.status === 'online' || agent.status === 'busy') && agent.id !== userId ))
  .sortBy(['last_name', 'first_name'])
  .value();

  return onlineAgents;
}

const getOnlineAgentsAvailableForChat = (onlineAgents) => {
  // agents that are online but NOT busy
  const availabAgents = _.filter(onlineAgents,
    (agent)=>(agent.max_conv > agent.current_conv)
  )
  return availabAgents;
}

// List of skills with at least one online or busy agent
const getSkillsWithOnlineOrBusyAgents = (skillsStore, onlineAgents) => {
  const agentCountsForSkill = {}
  const busyCountForSkill = {}

  const sortedSkills = _.chain(skillsStore)
  .filter((skill) => {
    // show skills that have at least one online agent
    return _.filter(onlineAgents, (agent) => {
      const isAgentHavingSkill = agent.skills.indexOf(skill.id) > -1

      agentCountsForSkill[skill.id] = agentCountsForSkill[skill.id] || 0
      busyCountForSkill[skill.id] = busyCountForSkill[skill.id] || 0

      if(isAgentHavingSkill){
        agentCountsForSkill[skill.id] += 1;
      }
      if(agent.current_conv === agent.max_conv){
        busyCountForSkill[skill.id] += 1;
      }

      return isAgentHavingSkill
    }).length > 0
  })
  .map((skill) => {
    skill.agentCount = agentCountsForSkill[skill.id]
    skill.busyCount = busyCountForSkill[skill.id]
    return skill;
  })
  .sortBy('name')
  .sortBy((skill) => {
    return skill.id === "general" ? 0 : 1; // show general skill first
  })
  .value();

  return sortedSkills;
}

class Component extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      agent: ANY_AGENT_VALUE,
      skill: GENERAL_SKILL,
      comment: null,
      isSubmitting: false,
    }

  }
  componentWillMount() {
    this.props.fetchAgents();
    this.props.fetchSkills();
    this.hasFetchedAgents = false;
  }
  componentWillReceiveProps(nextProps){
    // TODO test this
    // if(this.props.isAllAgentsUnavailable !== nextProps.isAllAgentsUnavailable){
    //   this.props.closeModal(true)
    // }
    if(!this.hasFetchedAgents &&
      this.props.isFetchingAgents !== nextProps.isFetchingAgents){
      this.hasFetchedAgents = true;
    }
  }
  handleClose = () => {
    this.props.closeModal()
  }
  handleRetry = () => {
    this.props.fetchAgents();
  }

  sendAnalytics = () => {
    const {skill,comment,agent} = this.state;
    const {pype} = this.props;
    const skills = this.getSkills()
    const agents = this.getAvailableAgents()
    const agentObj = _.find(agents, {id: agent});
    const agentName = agentObj ? `${agentObj.first_name} ${agentObj.last_name}` : 'Any agent';

    const isCommentsAvailable = !!comment

    const pypeName = pype.name;

    const skillObj = _.find(skills, {id: skill})
    const skillName = skillObj.name

    const gaMessage = `Success, comment: ${isCommentsAvailable}, agent:${agentName}, pype:${pypeName}, Skill:${skillName}`;
  }

  handleSubmit = () => {
    const {props, state} = this;
    const { match } = props
    const {skill, agent, comment} = state;
    const self = this;
    const to_agent = agent === ANY_AGENT_VALUE ? null : agent;
    const data = {
      chat_id: props.chat_id,
      to_agent,
      skill,
      comment
    };
    const skills = this.getSkills()
    const agents = this.getAvailableAgents()
    const consumer_name = props.consumer_name;
    const skillObj = _.find(skills, {id: skill});
    const agentObj = _.find(agents, {id: to_agent});
    const agentName = agentObj ? `${agentObj.first_name} ${agentObj.last_name}` : 'Any agent';

    const message = `<strong>${consumer_name}</strong> was successfully returned to the <strong>${skillObj.name}</strong> queue`;


    const onSuccess = () => {
      window.appCreateToast({
        message,
        level: 'success'
      });
      props.closeModal();
      self.props.clearAgentChatSession(props.chat_id);
      self.props.history.push(`/${match.params.pypeId}/chats`);
      self.sendAnalytics();
    };

    const onError = (error) => {
      window.appCreateToast({
        message: `Chat reassignment to <strong>${agentName}</strong> failed. <strong>Try another agent.</strong>`,
        level: 'error'
      });

      self.setState({isSubmitting: false}, () => {
        // props.closeModal()
        self.handleRetry();
      });
    };

    this.setState({isSubmitting: true});

    return props.transferChat(data).then(onSuccess, onError);
  }

  handleAgentChange = (obj) => {
    this.setState({ agent: obj.value });
  }

  handleSkillChange = (obj) => {
    const skillId = obj.value
    this.setState({ skill: skillId });

    this.props.fetchSkills(skillId)

  }

  handleCommentChange = (e) => {
    this.setState({ comment: e.target.value });
  }

  getOnlineAgents() {
    const { agentsStore, userId} = this.props
    return getOnlineAgents(agentsStore, userId)
  }

  getAvailableAgents() {
    const onlineAgents = this.getOnlineAgents()
    
    return getOnlineAgentsAvailableForChat(onlineAgents)
  }

  getSkills() {
    const { skillsStore } = this.props
    const onlineAgents = this.getOnlineAgents()

    return getSkillsWithOnlineOrBusyAgents(skillsStore, onlineAgents)
  }

  isAllAgentsUnavailable() {
    const onlineAgents = this.getOnlineAgents()
    const sortedSkills = this.getSkills()

    return onlineAgents.length === 0 || sortedSkills.length === 0;
  }

  renderAgents() {
    let agents = _.chain(this.getAvailableAgents())
      .filter((agent) => { // filter agents for the active skill
        return _.includes(agent.skills, this.state.skill);
      }).map((agent) => {
        return {
          value: agent.id,
          label: agent.first_name + ' ' + agent.last_name,
          sessions:  agent.active_sessions
        }
      }).value();

    agents.unshift({
      value: ANY_AGENT_VALUE,
      label: 'Any agent'
    })
    return agents
  }

  renderSkills() {
    const {props, state} = this
    let skills = this.getSkills()
    // TODO List of skills with at least one online or busy agent
    return skills.map((skill) => {
      let {name,id,agentCount} = skill

      return {
        value: id,
        label: name,
        agentCount
      }
    })
  }

  renderSkillsValue (option) {
    return <div className="text-danger">{option.label}</div>
  }

  renderSkillsOptions(option) {
    const {skill} = this.state
    const {value, label, agentCount} = option
    const isGeneralSkill = (value === GENERAL_SKILL)

    const agentCountLabel = agentCount + ' agents'
    return (
        <div className={css.skillOption} ref="value">

          <div className={cx({
            'fw-500': isGeneralSkill
          }, css.skillOptionLeft)}>{label}</div>

          <div className={"pull-right text-muted " + css.skillOptionRight }>
            {agentCountLabel}
          </div>

        </div>
    );
  }

  renderAgentsOptions(option) {
    const {skill} = this.state
    const {value, sessions} = option;
    const isAnyAgent = value === ANY_AGENT_VALUE
    const sessionsCount = sessions + ' chats'
    return (
        <div className={css.skillOption} ref="value">

          <div className={cx({
            'fw-500': isAnyAgent
          },css.skillOptionLeft)}>{option.label}</div>

        <div className={"pull-right text-muted " + css.skillOptionRight }>
            {isAnyAgent ? '' : sessionsCount}
          </div>

        </div>
    );
  }

  renderLoading(){
    return (
      <div className="text-center">
        <Spinner offsetTop="30" offsetBottom="30" caption={false}/>
      </div>
    )
  }

  renderEmptyState(){
    return (
      <div>
        <div className={css.emptyStateBody}>
          <p className="text-muted">No available agents</p>
        </div>
        <div className={css.emptyStateButtons}>
          <button
          className="btn btn-default m-r-10"
          onClick={this.handleClose}
          disabled={false}>Close</button>
          <button
          type="submit"
          className="btn btn-primary"
          onClick={this.handleRetry}
          disabled={false}>Try again</button>
        </div>
      </div>
    )
  }

  renderTransferBody(){
    const {skill, agent} = this.state
    return (
      <div className={css.popoverWrapper}>
        <p className={"label-text m-b-5"}>SKILL</p>
        <div className={css.selectWrapper}>
          <TetheredSelect
            name="skill"
            value={this.renderSkills().filter(({value}) => skill === value)}
            options={this.renderSkills()}
            searchable={false}
            clearable={false}
            placeholder={"Pick a Skill"}
            optionRenderer={this.renderSkillsOptions}
            onChange={this.handleSkillChange}/>
        </div>

        <p className={"label-text m-t-20 m-b-5"}>AGENT</p>
        <div className={css.selectWrapper}>
          <TetheredSelect
            name="agent"
            value={this.renderAgents().filter(({value}) => agent === value)}
            options={this.renderAgents()}
            searchable={false}
            clearable={false}
            valueRenderer={this.renderAgentsOptions}
            optionRenderer={this.renderAgentsOptions}
            onChange={this.handleAgentChange}/>
        </div>

        <p className={"label-text m-t-20 m-b-5"}>COMMENT <span className={css.hint}>(Optional)</span></p>
        <textarea
          className="form-control"
          rows="4"
          placeholder="Summarize the issue to help other agents"
          onChange={this.handleCommentChange}
          defaultValue={this.state.comment}>
        </textarea>
        <div className="m-t-20">
          <button
          type="submit"
          className="btn btn-primary pull-right"
          onClick={this.handleSubmit}
          disabled={false}>Transfer</button>
          <button
          className="btn btn-default pull-right m-r-10"
          onClick={this.handleClose}
          disabled={false}>Cancel</button>
          <div className="clearfix"></div>
        </div>
      </div>)
  }
  render() {

    const {props} = this
    const {isFetchingAgents, isFetchingSkills} = props;
    const isLoading = isFetchingAgents || isFetchingSkills;

      return <Popover
          className={cx({"transfer-popover-empty": this.isAllAgentsUnavailable()},
          {"transfer-popover": !this.isAllAgentsUnavailable()})}
          placement='bottom'
          target={document.getElementById('btn-transfer')}
          show={this.props.show}
          style={popoverCss}
          containerStyle={{zIndex: 9}}
          onHide={this.handleClose}>
          {
            isLoading ? this.renderLoading() : this.isAllAgentsUnavailable() ? 
            this.renderEmptyState() : this.renderTransferBody()
          }
        </Popover>


  }

}

Component.propTypes = {
  chat_id: PropTypes.string.isRequired,
  consumer_name : PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  closeModal: PropTypes.func
}

export default  withRouter(Component)
