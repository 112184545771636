import React from 'react';
import _ from 'lodash';

var timeDiff = function(diff){
  var parsed = {};
  
  parsed.days = Math.floor(diff / (60 * 60 * 24));
  diff -=  parsed.days * (60 * 60 * 24);

  parsed.hr = Math.floor(diff / (60 * 60));
  diff -= parsed.hr * (60 * 60);

  parsed.min = Math.floor(diff / 60);
  diff -= parsed.min * 60;

  parsed.sec = Math.floor(diff);
  diff -= parsed.sec;
  
  return _.compact(["days", "hr", "min", "sec"].map(function(part){
    if (parsed[part])
      return parsed[part] + part
  })).slice(0,2).join(' ')
}

class TimeDiff extends React.Component{
  getInitialState(){
    return {
      time: this.props.time
    }
  }
  
  componentWillReceiveProps(props){
    this.setState({
      time: props.time
    })
  }
  
  render(){
    var diff = timeDiff(parseInt(this.state.time) || 0);
    return <span>{diff}</span>
  }
};

export default  TimeDiff;
