import style from './style.css';
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types'

import Modal from 'react-modal';
import SaveButton from 'components/ui/forms/SaveButton';
import cx from 'classnames';

// Local imports
import SkillInput from './SkillInput';
import AgentSearch from './AgentSearch';
import DeleteSkillConfirmModal from './DeleteSkillConfirmModal';
import activityTracker from "../../../../utils/activityTracker";

const getAgentsHavingSkill = (agents, skill) => {
  return _.chain(agents)
  .map((agent) => {
    agent.isSkillAssigned = false;
    if(skill && agent.skills.indexOf(skill.id) > -1){
      agent.isSkillAssigned = true
    }
    return agent;
  })
  .orderBy([agent => agent.last_name.toLowerCase()])
  .value();
}
class Component extends React.Component {

  constructor(props) {
    super(props)
    const agents = getAgentsHavingSkill(props.agents, props.skill)
    this.state = {
      skill: props.skill ? props.skill.name : '',
      shouldRouteToGeneral: props.skill ? props.skill.reroute_to_general : true,
      agents: _.cloneDeep(agents),
      error: null,
      isSubmitting: false,
      isDeleteSkillModalVisible: false,
      // to hide modal when showing 'Warning' modal and show again when
      // 'Cancel' is pressed in Warning modal
      isModalVisible: true
    }
  }

  componentWillMount() {
    this.props.fetchAgents();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isFetchingAgents != this.props.isFetchingAgents) {
      const agents = getAgentsHavingSkill(nextProps.agents, nextProps.skill)
      this.setState({ agents: _.cloneDeep(agents) })
    }
  }

  handleClose = () => {
    this.props.closeModal();
  }

  handleSkillChange = (skillName) => {
    const { props, state } = this
    const existingSkill = _.some(props.skills, (skill) => {
      return skill.name.toLowerCase() === skillName.trim().toLowerCase()
    })
    const ERROR_MSG = 'You cannot have two skills with the same name.';

    if (existingSkill) {
      if (props.skill) { // when editing skill, user shouldn't see error for existing skill
        if (skillName.toLowerCase() !== props.skill.name.toLowerCase()) {
          this.setState({ error: ERROR_MSG })
        } else {
          this.setState({ error: null })
        }
      } else { // creating new skill
        this.setState({ error: ERROR_MSG })
      }
    } else {
      this.setState({ error: null })
    }
    this.setState({ skill: skillName })
  }

  handleSkillRouteToggle = () => {
    this.setState({ shouldRouteToGeneral: !this.state.shouldRouteToGeneral })
  }

  handleSkillDelete = () => {
    const { props } = this;
    const { customerName } = props
    const { pype_id, name, id } = props.skill;
    const self = this;
    const skill = {
      pype_id,
      name,
      id
    }

    props.deleteSkill(skill).then(() => {
      self.setState({
        isDeleteSkillModalVisible: false,
        isModalVisible: false
      })
      self.handleClose();
      window.appCreateToast({
        message: `The <strong>${skill.name}</strong> skill has been deleted successfully.`,
        level: 'info',
      })
      activityTracker.logEvent(activityTracker.eventTypeNames.UPDATE_PYPE_SETTING, { settingName: 'skill', action: activityTracker.eventActionType.DELETE, customerName });
    })
  }

  handleAgentSelect = (agent) => {
    const updatedAgents = _.cloneDeep(this.state.agents);
    const agentObj = _.find(updatedAgents, { id: agent.id });

    if (agentObj) {
      agentObj.isSkillAssigned = !agentObj.isSkillAssigned;
    }

    this.setState({
      agents: updatedAgents
    })
  }

  handleAgentSelectAll = () => {
    const updatedAgents = _.cloneDeep(this.state.agents);

    updatedAgents.forEach((agent) => {
      agent.isSkillAssigned = true;
    })

    this.setState({ agents: updatedAgents })
  }

  handleAgentsRestore = (agentsRestore) => {
    this.setState({ agents: [...agentsRestore] })
  }

  handleSubmit = () => {
    const { props, state } = this;
    const { customerName } = props
    const self = this;
    const agentIds = _.chain(state.agents)
      .filter((agent) => (agent.isSkillAssigned === true))
      .map('id')
      .value();

    // Update skill
    if (props.skill) {
      const existingSkill = {
        id: props.skill.id,
        name: state.skill,
        agents: agentIds,
        reroute_to_general: state.shouldRouteToGeneral
      }

      props.updateSkill(existingSkill, state.agents).then(() => {
        self.handleClose();
        activityTracker.logEvent(activityTracker.eventTypeNames.UPDATE_PYPE_SETTING, { settingName: 'skill', action: activityTracker.eventActionType.UPDATE, customerName });
      })
    } else { // create new skill and assign agents to that
      props.createSkill({
        name: state.skill,
        agents: agentIds,
        reroute_to_general: state.shouldRouteToGeneral
      }).then(() => {
        self.handleClose();
        // platform doesn't return response for create skill
        // therefore no way to assign skill id unless we refetch
        props.fetchSkills();
        props.fetchAgents();
        activityTracker.logEvent(activityTracker.eventTypeNames.UPDATE_PYPE_SETTING, { settingName: 'skill', action: activityTracker.eventActionType.CREATE, customerName });
      })
    }
  }

  hasChanges = () => {
    const { state, props } = this;

    const isAgentAssigned = _.some(state.agents, { isSkillAssigned: true })
    const isEditMode = !!props.skill
    const agents = getAgentsHavingSkill(props.agents, props.skill)
    
    return isEditMode && (state.skill.trim().toLowerCase() !== props.skill.name.trim().toLowerCase()
      || state.shouldRouteToGeneral !== props.skill.reroute_to_general)
      || !_(agents).differenceWith(state.agents, _.isEqual).isEmpty()
      || !isEditMode && state.skill.length !== 0

  }

  toggleDeleteSkillModal = () => {
    const { state, props } = this;
    const toggleModal = state.isDeleteSkillModalVisible;

    // refetch skills to get up to date chat counts
    props.fetchSkills();
    this.setState({
      isModalVisible: toggleModal,
      isDeleteSkillModalVisible: !toggleModal
    })
  }

  render() {
    const { props, state } = this;
    const isEditMode = !!props.skill;
    const { isSubmitting, shouldRouteToGeneral, skill, error } = state;
    const shouldDisableCTA = state.skill.length === 0 || !this.hasChanges() || !!state.error;
    const isGeneralSkill = props.skill && props.skill.id === 'general';
    const agents = getAgentsHavingSkill(props.agents, props.skill)

    return (
      <div>
        <Modal ariaHideApp={false} ariaHideApp={false} contentLabel='Modal' className={`skills-modal ${style.SkillsModal}`} isOpen={state.isModalVisible}
          onRequestClose={this.handleClose} shouldCloseOnOverlayClick={false}>
          <div className={`modal-content ${style.ModalContent}`}>
            <div className={`modal-header ${style.ModalHeader}`}>
              {
                isEditMode ? (<span>Edit <strong>{props.skill.name}</strong> skill</span>) : <span>Create skill</span>
              }
              <button type='button' className='close' onClick={this.handleClose}>
                <img src={`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-modal-close.svg`} width='15' height='15' />
              </button>
            </div>
            <div className={cx({ [style.ModalBodyCompact]: agents.length === 0 },
              `modal-body ${style.ModalBody}`)}>
              <SkillInput
                className="SkillInput"
                id="SkillInput"
                isGeneralSkill={isGeneralSkill}
                skill={skill}
                error={error}
                shouldRouteToGeneral={shouldRouteToGeneral}
                onSkillChange={this.handleSkillChange}
                shouldDisableRoutingToGeneral={props.shouldDisableRoutingToGeneral}
                onSkillRouteToggle={this.handleSkillRouteToggle}
              />
              <AgentSearch
                agents={state.agents}
                onAgentSelect={this.handleAgentSelect}
                onAgentSelectAll={this.handleAgentSelectAll}
                onAgentsRestore={this.handleAgentsRestore}
              />
            </div>
            <div className={`modal-footer ${style.ModalFooter}`}>
              {
                (isEditMode && !isGeneralSkill) ?
                  (<button
                    className={`btn btn-link ${style.deleteSkillButton}`}
                    onClick={this.toggleDeleteSkillModal}>
                    Delete skill
                  </button>) : null
              }
              <SaveButton
                className={`btn-primary ${style.SaveButton}`}
                disabled={shouldDisableCTA}
                isLoading={isSubmitting}
                onClick={this.handleSubmit}>
                {isEditMode ? 'Save changes' : 'Create skill'}
              </SaveButton>
            </div>
          </div>
        </Modal>
        {state.isDeleteSkillModalVisible ?
          <DeleteSkillConfirmModal
            skill={props.skill}
            isLoading={props.isFetchingSkills}
            onClose={this.toggleDeleteSkillModal}
            onDeleteSkill={this.handleSkillDelete}
          /> : null
        }
      </div>
    )
  }

}

Component.propTypes = {
  skill: PropTypes.object,
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

Component.defaultProps = {
}

export default Component
