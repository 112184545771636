import React from 'react'
import PropTypes from 'prop-types'

const Icon = (props) => {
  const dir = '/img/'

  // Check if an extension is supplied, else default to .svg
  const [base, ext] = props.name.split('.')

  const path = props.src ? props.src : `${dir}${base}.${ext ? ext : 'svg'}`

  return (
    <img
      src={path}
      className={props.className}
      width={props.width}
      height={props.height}
      onClick={props.onClick} />
  )
}

Icon.propTypes = {
  src: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  onClick: PropTypes.func.isRequired,
}

Icon.defaultProps = {
  name: '',
  ext: 'svg',
  width: 15,
  height: 15,
  onClick: () => {},
}

export default  Icon
