import React from 'react';
import style from './style.css';
import PropTypes from 'prop-types';

const Head = (props) => {
  return (
    <div className={style.head}>
      {props.children}
    </div>
  );
};

Head.propTypes = {
  children: PropTypes.node
};

export default  Head;