export const getLabelForEnv = (env = "") => {
    const _env = env.toLowerCase(); 
    const configs = window.config.PS_ENV_CONFIGS 
    if(configs[_env] && configs[_env]['styles']) {
        return configs[_env]['styles']
    }
    // default style for undefined env 
    return {
        fill: '#F5E7FD',
        border: '#9013fe',
        text: '#9013fe',
        label: _env.charAt(0).toUpperCase() + _env.slice(1)
    }

}