import { 
  BOT_USER_SESSION_LOGOUT,
} from "bot-user-session";
import {
  agentConstants,
  ENDED_AGENT_CHAT_SESSION,
  RECEIVE_AGENT_CHAT_SESSIONS,
  TOGGLE_USERINFO_PANE,
  REMOVE_CHAT_SESSION,
  initSeqConsts
} from '../constants'

const getInitialState = () => ({
  isFetching: false,
  hasInitialFetched: false,
  status: 'online',
  isBrowserStatusOffline: false,
  isOffHours: false,
  isSessionUserInfoIsOpen: sessionStorage.getItem("userInfoIsOpen") !== null ? sessionStorage.getItem("userInfoIsOpen") : true,
});

export default (state=getInitialState(), action) => {
  switch (action.type) {
    case BOT_USER_SESSION_LOGOUT: {
      return getInitialState();
    }
    case agentConstants.REQUEST:
    case initSeqConsts.FETCHING:
      return {
        ...state,
        isFetching: true
      }
    case agentConstants.RECEIVE:
    case initSeqConsts.FETCHED: {
      // dont update agent's status from 'list_agent_data' data
      // if offline at start, rely on 'presence' notice instead
      const data = action.type === initSeqConsts.FETCHED ? action.data.agent_data : action.data;
      if (!data) {
        return state;
      }
      const { status, ...rest } = data
      if (status === 'offline') {
        return {
          ...state,
          ...rest,
          hasInitialFetched: true,
        }
      }
      return {
        ...state,
        ...rest,
        status,
        hasInitialFetched: true,
      }
    }
    case agentConstants.INC_CHAT_COUNT:
      return {
        ...state,
        current_conv: Math.min(state.current_conv + 1, state.max_conv),
      }
    case agentConstants.DEC_CHAT_COUNT:
      return {
        ...state,
        current_conv: Math.max(0, state.current_conv - 1),
      }
    case agentConstants.RESET_CHAT_COUNT:
      return {
        ...state,
        current_conv: 0,
      }
    case agentConstants.SET_STATUS:
      return {
        ...state,
        status: action.status,
      }
    case agentConstants.SET_BROWSER_STATUS:
      return {
        ...state,
        isBrowserStatusOffline: action.isBrowserStatusOffline,
      }
    case agentConstants.ENABLE_OFF_HOURS:
      return {
        ...state,
        isOffHours: true
      }
    case agentConstants.DISABLE_OFF_HOURS:
      return {
        ...state,
        isOffHours: false
      }
    case RECEIVE_AGENT_CHAT_SESSIONS:
      return {
        ...state,
        current_conv: Math.max(state.current_conv, action.nChats),
      }
    case TOGGLE_USERINFO_PANE:
      return {
        ...state,
        isSessionUserInfoIsOpen: action.isSessionUserInfoIsOpen,
      }
    default:
      return state
  }
}
