import React from "react";
import { Wrapper } from "./helpers";
import { Link } from "react-router-dom";

/** Show a simple message for unauthorized routes */
const Unauthorized = () => (
  <Wrapper>
    <img
      src={`${
        window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE
      }/img/Pypestream_logo_horiz_black.svg`}
      width="240"
      height="38"
    />
    <h1>Unauthorized Access</h1>
    <h5>
      You do not have the necessary permissions to view this screen. <br />
      Please contact your administrator for assistance.
    </h5>
    <Link to="/">Go Back</Link>
  </Wrapper>
);

export default Unauthorized;
