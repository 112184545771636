import * as streamActions from './streams';
import * as conversationsActions from './conversations';
import * as _conversationHistoryActions from './conversationHistory';
import * as campaignsActions from './campaigns';
import * as pypeActions from './pype';
import * as usersActions from './users';
import * as responseActions from './response';
import * as agentsActions from './agents';
import * as autoresponderActions from './autoresponder';
import * as agentChatSessionsActions from './agentChatSessions';
import * as chatSessionsWithConsumerActions from './chatSessionsWithConsumer';
import * as tagActions from './tags';
import * as agentActions from './agent';
import * as botsActions from './bots';
import * as widgetsActions from './widgets';
import * as apiActions from './api';
import * as transfersActions from './transfers';
import * as skillsActions from './skills';
import * as cannedResponsesActions from './cannedResponses';
import * as pypesActions from './pypes';
import * as sessionActions from './session';
import * as heartbeatActions from './heartbeats';
import featureFlags from 'utils/featureFlags'
import { initSeqConsts } from '../constants'
import SDK from '../SDK'

const conversationHistoryActions = _conversationHistoryActions

const requestInitSeq = () => ({
  type: initSeqConsts.FETCHING,
})

const responseInitSeq = (data, userId) => ({
  type: initSeqConsts.FETCHED,
  data,
  receivedAt: Date.now(),
  userId
})

const fetchInitSequence = (isPypeAdmin) => (dispatch, getState) => {
  
  dispatch(requestInitSeq())

  const agent_id = getState().botUserSession.user.user_id
  const userId = getState().botUserSession.user.user_id
  
  return SDK.fetchInitSequence({
    agent_id,
    include_manager_data: isPypeAdmin
  }).then(res => {
    dispatch(agentActions.checkOffHours())
    dispatch(responseInitSeq(res, userId));
  })
}

export {
  streamActions,
  conversationsActions,
  conversationHistoryActions,
  campaignsActions,
  pypeActions,
  usersActions,
  responseActions,
  agentsActions,
  autoresponderActions,
  agentChatSessionsActions,
  chatSessionsWithConsumerActions,
  tagActions,
  agentActions,
  botsActions,
  widgetsActions,
  apiActions,
  transfersActions,
  skillsActions,
  cannedResponsesActions,
  pypesActions,
  sessionActions,
  heartbeatActions,
  fetchInitSequence
}
