import amplitude from 'amplitude-js';

const { config } = window;

function init() {
    if (!config.PS_AMPLITUDE_API_KEY) {
        console.error('PS_AMPLITUDE_API_KEY was not defined');
    }
    amplitude.getInstance().init(config.PS_AMPLITUDE_API_KEY, undefined, {
        trackingOptions: {
            city: false,
            dma: false,
            ip_address: false
        }
    });
}


/**
 * Log an event with eventType and eventProperties
 * @public
 * @param {string} eventType - name of event
 * @param {object} eventProperties - (optional) an object with string keys and values for the event properties.
 * @example logEvent('Clicked Homepage Button', {'finished_flow': false, 'clicks': 15});
 */
function logEvent(eventType, eventProperties = {}) {
    const Appcues = window.Appcues;
    amplitude.getInstance().logEvent(eventType, eventProperties, (code) => {
        if (code !== 200) {
            console.error(`Error in sending event:  ${eventType}, code: ${code}`);
        }
    });
    Appcues &&
    Appcues.track(eventType, eventProperties, (code) => {
      if (code !== 200) {
        console.error(`Error in sending event:  ${eventType}, code: ${code}`);
      }
    });
}


const eventTypeNames = {
    RECEIVE_ESCALATED_CONVERSATION: 'receive escalated conversation',
    SEND_MESSAGE: 'send message',
    END_CONVERSATION: 'end conversation',
    CLASSIFY_CONVERSATION: 'classify conversation',
    START_CONVERSATION_INTERFACE_CREATION: 'start conversation interface creation',
    CREATE_CONVERSATION_INTERFACE: 'create conversation interface',
    VIEW_PYPE_SETTING: 'view pype setting',
    UPDATE_PYPE_SETTING: 'update pype setting',
    VIEW_BROADCAST: 'view broadcast',
    VIEW_AGENT_QUEUE: 'conversations: agent queue: view agent queue',
    VIEW_HISTORY: 'conversations: history: view history',
    APPLY_HISTORY_FILTERS: 'conversations: history: filter conversation history',
    SEARCH_CONVERSATION_HISTORY: 'conversations: history: search conversation history',
    OPEN_MESSAGE: 'conversations: my queue: open conversation',
    SKILLS_FILTER_ADD_FILTER: 'conversations: overview: skill filter: add skill option',
    SKILLS_FILTER_REMOVE_FILTER: 'conversations: overview: skill filter: remove skill option',
    REFRESH_OVERVIEW: 'conversations: overview: click refresh button',
    TIME_SPENT_ON_OVERVIEW: 'conversations: overview: time spent',
    VIEW_OVERVIEW_TAB: 'conversations: overview: view page',
    SEND_CANNED_RESPONSE: 'conversations: agent desktop: add canned response to chat',
};

const eventActionType = {
    CREATE:'create',
    UPDATE:'update',
    DELETE:'delete'
};

export default
{
    init,
    logEvent,
    eventTypeNames,
    eventActionType,
}
