import {
  REQUEST_AUTORESPONDERS, RECEIVE_AUTORESPONDERS,
  UPDATE_AUTORESPONDERS, UPDATED_AUTORESPONDERS,
} from "./../constants"
import SDK from '../SDK'

// get autoresponders of a pype
function requestAutoresponders() {
  return {
    type: REQUEST_AUTORESPONDERS
  }
}

export function receiveAutoresponders(req_id, autoresponses) {
  return {
    req_id,
    type: RECEIVE_AUTORESPONDERS,
    autoresponses
  }
}

export function fetchAutoresponders() {
  return (dispatch, getState) => {
    SDK.getAutoResponders({
      pype_id: getState().pype.details.id,
    })

    return dispatch => {
      dispatch(requestAutoresponders())
    }
  }
}

export function updateAutoresponders(data) {
  return (dispatch, getState) => {
    dispatch({type: UPDATE_AUTORESPONDERS})
    const onDone = () => dispatch(updatedAutoresponders())

    data.forEach((a) => delete a.stream_id)
    
    return SDK.updateAutoResponders({
      pype_id: getState().pype.details.id,
      responders: data
    }).then(onDone, () => {
      onDone()
      throw new Error('STOMPClient response returned an error.')
    })

    
  }
}

export function updatedAutoresponders() {
  return {
    type: UPDATED_AUTORESPONDERS
  }
}
