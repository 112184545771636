import style from './style.css';
import React from 'react'
import PropTypes from 'prop-types'

import classNames from 'classnames';
import DeleteWarningModal from '../DeleteWarningModal';

class EditPanel extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      isModalOpen: false,
      name: props.widget.widget_name || '',
      isSubmitting: false,
    }
  }

  componentDidMount () {
    this.refs.input.select()
    window.addEventListener('keydown', this.onKeyDown)
  }

  componentWillUnmount () {
    window.removeEventListener('keydown', this.onKeyDown)
  }

  onKeyDown = (event) => {
    if (event.keyCode === 27 || event.which === 27) {
      event.preventDefault()
      this.handleClose()
    }
  }

  isFormValid = () => {
    return this.state.name.trim() !== ''
  }

  handleInput = (event) => {
    if (this.state.isSubmitting) {
      return
    }
    this.setState({name: event.target.value})
  }

  handleSubmit = (event) => {
    if (event) {
      event.preventDefault()
    }
    if (!this.isFormValid() || this.state.isSubmitting) {
      return
    }
    this.setState({isSubmitting: true})
    this.props.onSubmit(this.props.widget, this.state.name.trim()).then(() => {
      window.appCreateToast({
        message: 'Changes successfully saved.',
        level: 'success',
      })
      this.props.onClose(this.props.widget)
    }).catch((error) => {
      console.warn(error)
      window.appCreateToast({
        message: 'Encountered an error updating Web Conversational Interface.',
        level: 'error',
      })
      this.setState({isSubmitting: false})
    })
  }

  openWarningModal = () => {
    this.setState({isModalOpen: true})
  }

  handleDelete = () => {
    if (this.state.isSubmitting) {
      return
    }
    this.setState({isSubmitting: true})
    return this.props.onDelete(this.props.widget).then(() => {
      window.appCreateToast({
        message: 'Deleted Web Conversational Interface successfully.',
        level: 'success',
      })
      this.props.onClose(this.props.widget)
    }).catch((error) => {
      console.warn(error)
      window.appCreateToast({
        message: 'Encountered an error deleting Web Conversational Interface.',
        level: 'error',
      })
      this.setState({isSubmitting: false})
    })
  }

  handleClose = () => {
    if (this.state.isSubmitting) {
      return
    }
    this.props.onClose(this.props.widget)
  }

  closeModal = () => {
    this.setState({isModalOpen: false})
  }

  render () {
    const {props, state} = this
    const className = classNames(style.EditPanel, {
      [style.isSubmitting]: state.isSubmitting,
    })
    return (
      <div className={className}>
        {state.isModalOpen && (
          <DeleteWarningModal
            widgetName={props.widget.widget_name}
            isSubmitting={state.isSubmitting}
            onSubmit={this.handleDelete}
            closeModal={this.closeModal} />
        )}
        <form onSubmit={this.handleSubmit} style={{flex: 1}}>
          <input ref='input' className={style.Input} type='text' value={state.name}
            disabled={state.isSubmitting} onChange={this.handleInput} />
        </form>
        <div className={style.Controls}>
          <img className={classNames(style.Img, {
            [style.isDisabled]: !this.isFormValid(),
          })} src={`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-check.svg`} onClick={this.handleSubmit} />
          <img className={style.Img} src={`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-delete-big.svg`} onClick={this.openWarningModal} />
          <img className={style.Img} src={`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-x.svg`} onClick={this.handleClose} />
        </div>
      </div>
    )
  }

}

EditPanel.propTypes = {
  widget: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default  EditPanel
