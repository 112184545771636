import {includes, replaceSpecialChars, now, isChrome, isFirefox, shouldAlwaysLogPlainly} from '../lib/util'
import {error} from '../lib/error'

import {
  HEADER_ONLY_FRAMETYPES,
  FRAME_TYPE_MAP,
} from '../constants'

class StompLogger {

  constructor() {
    this.lastLog = null
    this.pendingRequests = {}
  }

  shouldSkipLogging(str) {
    /*
      Ignore periodic PING messages
    */
    return str === '>>> PING'
  }

  shouldLogPlainly(str) {
    /*
      Log string as it is if:
      - Local storage setting forces plain logging
      - Not on chrome
      - Not on firefox
      - String only contains 1 line
    */
    return shouldAlwaysLogPlainly() ||
      !isChrome() ||
      str.split('\n').length === 1
  }

  getFrameType(str) {
    return FRAME_TYPE_MAP[str]
  }

  messageContainsOnlyHeaders(frameType) {
    return includes(HEADER_ONLY_FRAMETYPES, frameType)
  }

  _innerLog(fn) {

    const time = now()
    if (this.lastLog === null)
      this.lastLog = time

    const dt = time - this.lastLog

    if (dt > 1000) {
      console.log(`%c🕐 Last log occurred ${(dt / 1000).toFixed(2)} seconds ago`, 'color: #bbf')
    }

    this.lastLog = time
    fn(console.log.bind(console))

  }

  log(str) {

    /*
      Public method. Logs a websocket response string neatly, with
      prettification, and collapsing groups.
    */

    if (typeof str === 'undefined' || str === null)
      return

    const normalStr = replaceSpecialChars(str)

    if (this.shouldSkipLogging(normalStr))
      return

    if (this.shouldLogPlainly(normalStr)) {
      this._innerLog((log) => log(`%c${normalStr}`, 'color: #bbb'))
      return
    }

    const frameType = this.getFrameType(normalStr.split('\n')[0])

    if (frameType === undefined) {
      this._innerLog((log) => log(normalStr))
      return
    }

    if (this.messageContainsOnlyHeaders(normalStr)) {
      this.logMessageWithOnlyHeaders(normalStr, frameType)
      return
    }

    if (frameType === 'send' || frameType === 'message') {
      this.logMessageWithData(normalStr, frameType)
      return
    }

  }

  logMessageWithOnlyHeaders(str, frameType) {
    this._innerLog((log) => {
      console.groupCollapsed(frameType)
      log(str)
      console.groupEnd()
    })
  }

  logMessageWithData(str, frameType) {

    const [headers, json] = str.split('\n\n')

    let parsed
    let error
    try {
      parsed = JSON.parse(json)
    } catch (error) {
      error('JSON.parse error. ' + error)
    }

    let requestType
    if (frameType === 'send') {
      requestType = 'SEND ' + parsed.request_type
      this.pendingRequests[parsed.correlation_id] = {
        request_type: parsed.request_type,
        time: now(),
      }
    }
    else if (frameType === 'message') {
      if (parsed.type === 'ack') {
        requestType = 'ACK'
        const requestInfo = this.pendingRequests[parsed.correlation_id]
        if (requestInfo !== undefined) {
          let dt = Date.now() - requestInfo.time
          if (dt > 1000) {
            dt = (dt / 1000).toFixed(2) + 's'
          } else {
            dt += 'ms'
          }
          requestType += ` ${requestInfo.request_type} [${dt}]`
          delete this.pendingRequests[parsed.correlation_id]
        }
        if (parsed.status === 'error') {
          error = {status: parsed.status}
          requestType += ' [Error]'
        }
      } else {
        requestType = 'MESSAGE ' + parsed.detail
      }
    }

    let formattedJsonStr
    try {
      formattedJsonStr = JSON.stringify(parsed, null, 2)
    } catch (error) {
      error('JSON.stringify error. ' + error)
    }

    // Actual console logging

    this._innerLog((log) => {

      const colorCss = 'color: ' + (error ? '#f00' : '#00f')
      console.groupCollapsed('%c' + requestType, colorCss)

      // Log headers
      console.groupCollapsed('headers')
      log(headers)
      console.groupEnd()

      // Log data/json
      // console.groupCollapsed('data')
      log(formattedJsonStr)
      // console.groupEnd()

      console.groupEnd()

    })


  }

}

export default StompLogger
