import { skillsConstants as types } from '../constants'
import SDK from '../SDK'

const requestSkills = () => ({
  type: types.REQUEST_SKILLS,
})

const receiveSkills = (data) => ({
  type: types.RECEIVE_SKILLS,
  data,
})

const fetchSkills = () => (dispatch, getState) => {
  dispatch(requestSkills())
  return SDK.getSkills({
    pype_id: getState().pype.details.id,
  }).then((data) => {
    return dispatch(receiveSkills(data))
  })
}

const requestSkill = () => ({
  type: types.REQUEST_SKILL,
})

const receiveSkill = (data) => ({
  type: types.RECEIVE_SKILL,
  data,
})

const fetchSkill = (skill_id) => (dispatch, getState) => {
  dispatch(requestSkill())
  SDK.getSkill({
      skill_id: skill_id,
      pype_id: getState().pype.details.id,
  }).then((data) => {
    return dispatch(receiveSkill(data))
  })
}

const deleteSkillAction = () => ({
  type: types.DELETE_SKILL
})

const deletedSkillAction = (data) => ({
  type: types.DELETED_SKILL,
  data
})

const deleteSkill = (data) => (dispatch) => {
  dispatch(deleteSkillAction())
  return SDK.deleteSkill(data).then(() => {
    return dispatch(deletedSkillAction(data))
  })
}

const updateSkillAction = () => ({
  type: types.UPDATE_SKILL
})

const updatedSkillAction = (data) => ({
  type: types.UPDATED_SKILL,
  data
})

const updateSkill = (data, derivedData = {}) => (dispatch, getState) => {
  dispatch(updateSkillAction())
  const promise = (data.id !== 'general') ? 
    SDK.updateSkill({
      ...data,
      pype_id: getState().pype.details.id
    }) : Promise.resolve();

  promise.then(() => {
    return dispatch(updatedSkillAction({
      ...data,
      ...derivedData // update any derived data locally. ex: assigned_agent_count
    }))
  })
  
}

const createSkillAction = () => ({
  type: types.CREATE_SKILL
})

const createdSkillAction = (data) => ({
  type: types.CREATED_SKILL,
  data
})

const createSkill = (data) => (dispatch, getState) => {
  const skill = {
    ...data,
    pype_id: getState().pype.details.id
  }
  dispatch(createSkillAction())
  return SDK.createSkill(skill).then(() => {
    return dispatch(createdSkillAction({
      ...skill,
      id: 'new-skill' // TODO fix until platform returns 'response'. now only ack
    }))
  })
}

export function selectedSkill(data) {
  return {
    type: types.SELECTED_SKILL,
    skill:data
  }
}

export {
  fetchSkill,
  fetchSkills,
  deleteSkill,
  updateSkill,
  createSkill,
}
