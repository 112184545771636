import PropTypes from 'prop-types';
import React from 'react'
import cx from 'classnames';
import Spinner from 'components/ui/pype-spinner/PypeSpinner';

const SaveButton = (props) => {
  const cssClass = cx('save-btn btn', props.className, {
    'is-loading': props.isLoading,
    'disabled': props.disabled,
  })
  const disabled = props.disabled || props.isLoading
  return (
    <button className={cssClass} type={props.type} disabled={disabled} onClick={props.onClick}>
      <div className="btn-content">{props.children}</div>
      {props.isLoading ? <Spinner caption={false} /> : null}
    </button>
  )

}

SaveButton.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
}

SaveButton.defaultProps = {
  className: '',
  type: 'button',
  disabled: false,
}

export default  SaveButton
