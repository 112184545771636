import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import assign from 'object-assign';
import _ from 'lodash';
import { connect } from 'react-redux';
import sdk from 'sdk';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

// TODO replace
// import InfiniteScroll from 'react-infinite-scroll';(React);

// Local imports
import FilesUtils from 'utils/files';
import Toast from 'components/ui/toast/Toast';
import Spinner from 'components/ui/pype-spinner/PypeSpinner';
import { ReactComponent as AgentIcon } from 'img/icons/agent.svg';
import { ReactComponent as MessageIcon } from 'img/icons/message.svg';
import { ReactComponent as TransferIcon } from 'img/icons/transfer.svg';
import { ReactComponent as CloseIcon } from 'img/icons/close-rounded.svg';
import constructHistory from 'utils/transfer_history_constructor';
import Message from '../Message';
import MessageTyping from '../MessageTyping';
import SubHeaderBar from '../SubHeaderBar';
import PostChatModal from '../PostChatModal';
import TransferChatPopover from '../transfer-chat';
import userInfo from '../user-info';
import EmptyHistory from './EmptyHistory';
import ChatInput from './ChatInput';
import EndedChatBanner from './EndedChatBanner';
import ErrorBoundary from 'components/ui/ErrorBoundary';
import ChatWithScrollArrow from './ChatWithScrollArrow';
import { OpenPanelButton } from './helper';
import CONSTANTS from '../../../core/constant';
import { TabTypes } from '../ConversationsList';
import './style.css';
import { withOptimizely } from '@optimizely/react-sdk';
import activityTracker from '../../../utils/activityTracker';
import SystemMessage from './SystemMessage';
import EndConversationComment from '../EndConversationComment';
import moment from 'moment';

const { agentActions, agentsActions, conversationsActions } = sdk;
var MESSAGE_MAXCHARS = 1000;

const isQueueMessage = ({ assignee }) => {
  const regex = /queue$/;
  if (assignee) return regex.test(assignee.toLowerCase());
  return false;
};

const getSkill = (msg, participant) => {
  if (msg[participant]) {
  const regex = /queue$/;
  if (regex.test(msg[participant].toLowerCase())) {
    return msg[participant].split('queue')[0].trim();
  }
  if(msg.skill_name) {
    return msg.skill_name;
  }}
};

const capitalizeCase = (phrase) => {
  if (phrase) {return phrase
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');}
  return ""
};

export class Component extends React.Component {
  static propTypes = {
    chat: PropTypes.object,
    isAnon: PropTypes.bool.isRequired,
  };
  _isMounted = false;

  constructor(props) {
    super(props);
    const { optimizely } = this.props;
    const isNewAgentViewFeatureEnabled =
      optimizely && optimizely.isFeatureEnabled('new_agent_view');
    this.messageScrollRef = React.createRef();
    this.bottomDivRef = React.createRef();
    this.topDivRef = React.createRef();
    this.newMessageTimer = null;
    this.state = {
      submitting: false,
      error: null,
      lastChat: null,
      chatboxText: '',
      endChatPopoverIsOpen: false,
      postChatModalIsOpen: false,
      minimumWindowBreakPoint: 1200,
      // If the component has a modal open, or WILL have a modal open
      // after end chat modal closes. This is used to determine if the
      // route should set to /chats after a stream unsub event,
      // otherwise the postchat modal would never appear.
      inModalState: false,
      isTransferPopoverOpen: false,
      isNewAgentViewFeatureEnabled,
      messageScrollPosition: 0,
      scrollDirection: null,
      showArrow: false,
      scrollToEnd: null,
      scrollToNewMessage: false,
      newMessageTimeExceeded: false,
      mouseDirection: null,
      overflow: false,
      isCommentFormVisible: false,
      isTransferFormOpen: false,
      showClassificationInHistory: false,
      noAgentsAvailable: false,
    };
  }

  getConversationHistory = (chatId, consumerId, chat, isAnon) => {
    this.props.fetchConversationHistory(chatId, consumerId);

    if (chat != null) {
      this.props.fetchChatSessionsWithConsumer(consumerId, true);
      this.props.fetchStreamsIfNeeded();
    }
  };

  sendReadReceiptsForRange = (fromSeqId, toSeqId) => {
    const chatId = this.props.match.params.id;
    const session = _.find(this.props.unreadConversations, { id: chatId });
    const { userId } = this.props;

    if (!session) return; // no unreads. no need to to send receipts

    const userReadCount =
      session && _.find(session.user_read_counts, { user_id: userId });
    let minRange = fromSeqId;
    let maxRange = toSeqId;
    const ranges = [];

    // userReadCounts are included in range.
    // they are ignored when generating unread msgIds
    if (userReadCount.min === 0 && userReadCount.max === 0) {
      ranges.push([minRange, maxRange]);
    } else if (userReadCount.min <= minRange && userReadCount.max <= maxRange) {
      minRange = userReadCount.max;
      ranges.push([minRange, maxRange]);
    } else if (userReadCount.max <= minRange) {
      minRange = userReadCount.max;
      ranges.push([minRange, maxRange]);
    } else if (
      userReadCount.min >= minRange &&
      (userReadCount.min >= maxRange || userReadCount.min <= maxRange)
    ) {
      maxRange = userReadCount.min;
      ranges.push([minRange, maxRange]);
    } else if (userReadCount.min >= minRange && userReadCount.max <= maxRange) {
      ranges.push([minRange, userReadCount.min]);
      ranges.push([userReadCount.max, maxRange]);
    }

    const unreadMsgIds = [];

    ranges.forEach((range) => {
      const min = range[0];
      const max = range[1];

      for (let i = min; i <= max; i++) {
        if (i === userReadCount.min || i === userReadCount.max) continue;
        unreadMsgIds.push(chatId + '.' + i);
      }
    });

    if (!unreadMsgIds.length) return;

    const sendAppointmentConfirmation = () => {
      if (this.state.lastEmbedSent === 'timePicker') {
        this.setState(
          {
            chatboxText: 'Your appointment has been scheduled',
          },
          this.saveDraft
        );
        this.setState({ lastEmbedSent: '', embed: false, sendEmbed: '' });
        this.handleSubmit();
      } else if (this.state.lastEmbedSent === 'applePay') {
        this.setState(
          {
            chatboxText: 'Payment Successful',
          },
          this.saveDraft
        );
        this.setState({ lastEmbedSent: '', embed: false, sendEmbed: '' });
        this.handleSubmit();
      }
    };

    this.props.setLastReadMessage({
      chatId,
      data: unreadMsgIds[unreadMsgIds.length - 1],
    });
    this.props
      .readMessages({
        chat_id: chatId,
        msg_ids: unreadMsgIds,
        client_timestamp: Date.now(),
        to_be_migrated: this.props.to_be_migrated,
      })
      .then(function (res) {
        sendAppointmentConfirmation();
      })
      .catch(function (err) {});
  };

  findVisibleRange() {
    const history = this.props.conversationHistory;
    const lastMsg = history[history.length - 1];
    const minSeqNum = 1;
    const maxSeqNum =
      lastMsg && lastMsg.chat_msg ? lastMsg.chat_msg.seq_num : 1;

    return {
      min: minSeqNum,
      max: maxSeqNum,
    };
  }

  findRangeForReadReceipts = () => {
    const range = this.findVisibleRange();

    this.sendReadReceiptsForRange(range.min, range.max);
  };

  componentDidMount() {
    this._isMounted = true;
    const { match, chat, isAnon } = this.props;
    const {
      params: { id, consumerId },
    } = match;

    this.getConversationHistory(id, consumerId, chat, isAnon);

    this.restoreDraft(this.props.match.params.id);

    this.debouncedInput = _.debounce(
      function (event) {
        this.props.toggleAgentTypingStatus(false);
      }.bind(this),
      1000
    );

    document.addEventListener('visibilitychange', () => {
      if (
        !document.hidden &&
        this.props.isSessionEnded &&
        this.props.chatType === TabTypes.active
      )
        setTimeout(this.scrollToBottom(), 200);
    });
    this.props.fetchCannedResponse();

    sessionStorage.setItem(
      'userInfoIsOpen',
      JSON.stringify(window.innerWidth >= this.state.minimumWindowBreakPoint)
    );
    this.props.toggleUserInfo(
      window.innerWidth >= this.state.minimumWindowBreakPoint
    );
  }

  componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener('visibilitychange', () => {});
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.chatType === TabTypes.active){
      if(Math.abs(this.props.nMessages && this.state.messageScrollPosition) < CONSTANTS.CLOSE_TO_CHAT_END){  
        this.props.setCloseToChatEndId(this.props.chat_id);
      } else{
         this.props.setCloseToChatEndId(null);
       }
    }
    if (
      prevProps.chat_id === this.props.chat_id &&
      (prevProps.nMessages < this.props.nMessages ||
        prevProps.isConsumerTyping !== this.props.isConsumerTyping)
    ) {
      if (prevProps.nMessages < this.props.nMessages) {
        if (
          Math.abs(this.state.messageScrollPosition) >=
          CONSTANTS.CLOSE_TO_CHAT_END
        ) {
          this.setState({
            scrollToNewMessage: true,
            scrollDirection: 'Down',
          });

          this.startNewMessageTimer();
        }
        if (
          this.props.conversationHistory[prevProps.conversationHistory.length]
            .chat_msg.from_side === 'agent'
        ) {
          const scrollHeight = this.messageScrollRef.current.scrollHeight;
          const height = this.messageScrollRef.current.clientHeight;
          if (scrollHeight > height) this.setState({ overflow: true });
          const maxScrollTop = scrollHeight - height;
          this.messageScrollRef.current.scrollTop =
            maxScrollTop > 0 ? maxScrollTop : 0;
        } else {
          this.scrollToWhere();
        }        
      } else {
        this.scrollToWhere();
      }
    } else if (
      this.props.chatType === TabTypes.active &&
      !this.props.isFetchingHistory &&
      this.props.nMessages.length
    ) {
      this.findRangeForReadReceipts();
    } else if (prevState.userInfoIsOpen && !this.state.userInfoIsOpen) {
      const panes = document.querySelector(
        '.panes .pane.pane-detail > .full-height'
      );
      panes.style.width = '95.8rem';
    }

    if (prevProps.chat_id !== this.props.chat_id) {
      if(this.state.isTransferFormOpen) {
        this.setState({isTransferFormOpen: false});
      }
    }

    if (
      this.props.match.params.consumerId !==
        prevProps.match.params.consumerId &&
      window.innerWidth < this.state.minimumWindowBreakPoint
    ) {
      sessionStorage.setItem('userInfoIsOpen', JSON.stringify(false));
      this.props.toggleUserInfo(false);
    }

    if (prevProps.isFetchingHistory && !this.props.isFetchingHistory) {
      setTimeout(() => {
        if (this.messageScrollRef && this.messageScrollRef.current) {
          const scrollHeight = this.messageScrollRef.current.scrollHeight;
          const height = this.messageScrollRef.current.clientHeight;
          if (this.props.flags.pe18974) {
            this.messageScrollRef.current.scrollTop = scrollHeight;
          }
          this.setState({ overflow: scrollHeight > height });
        }
      }, 50);
      this.scrollToBottom();
    }
    if(this.props.isSessionEnded !== prevProps.isSessionEnded && this.props.chatType === TabTypes.active && this.props.flags.newClassifyChatForm){
      this.scrollToBottom();
    }    
  }

  toggleIsTyping = (e) => {
    if (!this.props.isAgentTyping) {
      this.props.toggleAgentTypingStatus(true);
    }
    this.debouncedInput(e);
  };

  componentWillReceiveProps(nextProps) {
    const { match } = nextProps;
    // Don't navigate out of history if we're in the post chat modal
    if (this.didChatEnd() && !this.state.inModalState) {
      const url = new URL(window.location.href)
      if (!url.searchParams.get('src_notification') && this.props.flags.pe18541) {
        this.props.history.push(`/${match.params.pypeId}/chats`);
      } else {
        this.props.history.push(`/${match.params.pypeId}/chats`);
      }
      return;
    }
    if (
      nextProps.match.params.id !== this.props.match.params.id ||
      nextProps.chatType !== this.props.chatType
    ) {
      const { match, chat, isAnon } = nextProps;
      const {
        params: { id, consumerId },
      } = match;

      this.getConversationHistory(id, consumerId, chat, isAnon);

      if(nextProps.chatType !== this.props.chatType) {
        if(this.props.flags.newClassifyChatForm) {
          this.props.history.push(`/${match.params.pypeId}/chats`);
        }
        if(this.props.flags.newMessageIndicator) {
          this.props.setCloseToChatEndId(null);
        }
      }

      this.setState({
        submitting: false,
        error: null,
        messageScrollPosition: 0,
        scrollDirection: null,
        showArrow: false,
        scrollToEnd: null,
        scrollToNewMessage: false,
        endChatPopoverIsOpen: false,
      });
      this.restoreDraft(nextProps.match.params.id);
    } else if (nextProps.createdChat.id !== this.props.createdChat.id) {
      this.setState({ submitting: false });
    } else if (
      nextProps.failedChatId === this.props.match.params.id &&
      nextProps.msgRequestTimeout
    ) {
      this.setState({
        submitting: false,
        error: 'The message could not be sent. Try again.',
      });
    }
  }

  didChatEnd = () => {
    return !_.some(
      this.props.chatSessions,
      (chat) => chat.id === this.props.match.params.id
    );
  };

  /*
    Restore saved message drafts from local storage.
    @id The id of the conversation.
  */
  restoreDraft = (id) => {
    this.setState({
      chatboxText: window.sessionStorage.getItem('msg_draft_' + id) || '',
    });
  };

  clearDraft = (id) => {
    window.sessionStorage.removeItem('msg_draft_' + this.props.match.params.id);
  };

  // Save message drafts to local storage.
  saveDraft = () => {
    if (this.state.chatboxText.trim() === '') {
      window.sessionStorage.removeItem(
        'msg_draft_' + this.props.match.params.id
      );
    } else {
      window.sessionStorage.setItem(
        'msg_draft_' + this.props.match.params.id,
        this.state.chatboxText
      );
    }
  };

  // resetMessages: function() {
  //   chatroomActions.resetChatroom();
  //   if (this.refs.infinitescroll) {
  //     this.refs.infinitescroll.pageLoaded = 0;
  //   }
  // },
  scrollToBottom = () => {
    const { chatType } = this.props;

    if (this.bottomDivRef && this.bottomDivRef.current) {
      this.bottomDivRef.current.scrollIntoView({
        behavior: 'auto',
        block: 'end',
      });
      if (chatType === TabTypes.active) {
        this.findRangeForReadReceipts();
      }

      this.stopNewMessageTimer();

      this.setState({
        scrollToNewMessage: false,
        newMessageTimeExceeded: false,
      });
    } else {
      setTimeout(() => {
        this.scrollToBottom();
      }, 100);
    }
  };
  scrollToTop = () => {
    if (this.topDivRef && this.topDivRef.current) {
      this.topDivRef.current.scrollIntoView({ behavior: 'auto', block: 'end' });
    }
  };

  addArrow = () => {
    this.setState({ showArrow: true });
  };

  removeArrow = () => {
    this.setState({ showArrow: false });
    if (
      this.state.scrollToNewMessage &&
      this.state.scrollDirection === 'Down'
    ) {
      this.setState({ scrollToNewMessage: false });
    }
  };

  startNewMessageTimer = () => {
    if (!this.newMessageTimer) {
      this.newMessageTimer = setTimeout(() => {
        this.setState({ newMessageTimeExceeded: true });
      }, CONSTANTS.REPLY_TIME_EXCEEDED);
    }
  };

  stopNewMessageTimer = () => {
    clearTimeout(this.newMessageTimer);
    this.newMessageTimer = null;
    this.setState({ newMessageTimeExceeded: false });
  };

  scrollToWhere = () => {
    const scrollHeight = this.messageScrollRef.current.scrollHeight;
    const clientHeight = this.messageScrollRef.current.clientHeight;

    if (scrollHeight === clientHeight) {
      this.setState({
        showArrow: null,
        scrollDirection: null,
        scrollToNewMessage: null,
      });
    } else {
      if (
        Math.abs(this.state.messageScrollPosition) < CONSTANTS.CLOSE_TO_CHAT_END
      ) {
        this.scrollToBottom();
      } else {
        this.addArrow();
      }
    }
  };

  onMessageScroll = () => {
    const scrollTop = this.messageScrollRef.current.scrollTop;
    const scrollHeight = this.messageScrollRef.current.scrollHeight;
    const clientHeight = this.messageScrollRef.current.clientHeight;
    const height = scrollHeight - clientHeight;
    const messageScrollPosition = scrollTop - height;
    const scrolled = scrollTop / height;
    let scrollDirection;
    let scrollToEnd;

    if (messageScrollPosition > this.state.messageScrollPosition) {
      scrollDirection = 'Down';
      scrollToEnd = this.scrollToBottom;
    } else if (messageScrollPosition < this.state.messageScrollPosition) {
      scrollDirection = 'Up';
      scrollToEnd = this.props.flags.removeUpChevron ? this.scrollToBottom : this.scrollToTop;
    }

    this.setState({
      messageScrollPosition,
      scrollDirection,
      scrollToEnd,
    });

    if (
      ( !this.props.flags.removeUpChevron && scrolled <= 0 ) ||
      Math.abs(messageScrollPosition) < CONSTANTS.CLOSE_TO_CHAT_END
    ) {
      this.removeArrow();
      if (
        Math.abs(messageScrollPosition) < CONSTANTS.CLOSE_TO_CHAT_END &&
        scrollDirection === 'Down' &&
        this.state.mouseDirection !== 'Down'
      ) {
        this.scrollToBottom();
      }
    } else {
      this.addArrow();
    }
  };

  topPosition = (domElt) => {
    if (!domElt) {
      return 0;
    }
    return domElt.offsetTop + this.topPosition(domElt.offsetParent);
  };

  appendMessages(page) {
    // if (this.refs.scrollEl) {
    //   this.rememberedHeight = ReactDOM.findDOMNode(this.refs.scrollEl).scrollHeight;
    // }
    // chatroomActions.loadChatroomAppend("chatroom", this.props.match.params.id, null, null, page);
  }

  handleSubmit = (event, attachment) => {
    event && event.preventDefault();
    var chatroomId = this.props.match.params.id;
    var message = this.state.chatboxText.trim();
    var to_be_migrated = this.props.to_be_migrated;
    const embed = this.state.embed;

    if (message.length > MESSAGE_MAXCHARS) {
      this.refs['toast'].createToast(
        'There are over ' + MESSAGE_MAXCHARS + ' characters in string',
        'error'
      );
      return false;
    }

    if (message === '' && !Boolean(this.state.attachment)) {
      this.refs['toast'].createToast('Cannot send a blank message.', 'error');
      return;
    }

    this.setState({
      chatboxText: '',
      submitting: true,
      error: null,
      sendEmbed: '',
      lastEmbedSent: this.state.sendEmbed,
    });

    var messageType = this.state.attachment
      ? FilesUtils.getFileTypeForPlatformMessage(this.state.attachment)
      : embed
      ? 'embed'
      : 'text';
    var self = this;

    var userId = this.props.match.params.consumerId;
    var consumerNames = this.props.consumer_name.trim().split(' ');
    var user = {
      first_name: consumerNames[0],
      last_name: consumerNames[1] || '',
      id: userId,
    };

    const utoa = (s) => btoa(unescape(encodeURIComponent(s)));
    const encodeValue = (value) => utoa(value);

    var action = this.state.attachment
      ? this.props.sendMessageWithAttachment({
          text: message,
          chatId: chatroomId,
          file: self.state.attachment,
          user: self.props.match.params.consumerId,
          params: {
            attachmentType: messageType,
          },
          to_be_migrated: to_be_migrated,
        })
      : embed
      ? this.props.createChat({
          chat_id: chatroomId,
          msg: message,
          msg_type: 'embed',
          file_url: null,
          to_be_migrated,
          user,
          message,
          app_object: encodeValue(JSON.stringify(embed)),
        })
      : this.props.createChat({
          chat_id: chatroomId,
          msg: message,
          msg_type: messageType,
          file_url: null,
          to_be_migrated,
          user,
        });

    action
      .then(
        function (res) {
          this.setState({
            submitting: false,
            embed: false,
          });
        }.bind(this)
      )
      .catch((err) => {
        this.refs['toast'].createToast(err, 'error');
      });

    this.clearDraft();
    this.removeAttachment();
    this.setState({ embed: false });
  };

  isChatBoxEmpty = () => {
    return this.state.chatboxText.trim() === '';
  };

  handleKeyDown = (e) => {
    var ENTER = 13;
    if (e.keyCode == ENTER) {
      this.handleSubmit(e);
    }
  };

  handleMouseEvent = (e) => {
    if (e.type === 'mousedown') {
      this.setState({ mouseDirection: 'Down' });
    } else {
      this.setState({ mouseDirection: 'Up' });
    }
  };

  handleFileDrop = (file) => {
    if (FilesUtils.fileExtensionAndSizeAllowed(file[0])) {
      this.setState({
        attachment: file[0],
      });
    }
  };

  removeAttachment = () => {
    this.setState({
      attachment: null,
    });
  };

  renderChatLog = () => {
    // TODO: need API to check if conversation is active
    var user_avatar = `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/comp-icon.png`; // TODO: need placeholder image
    var user_name = 'Loading...';
    var chatId = this.props.match.params.id;
    var to_be_migrated = this.props.to_be_migrated;

    const { isConsumerTyping, chat, skills, flags, user_id } = this.props;
    const skill = chat ? skills.find((obj) => obj.id === chat.skill) : null;
    const skillName = skill ? skill.name : 'General';
    const skillId = skill ? skill.id : "general";
    const summaries =
      chat && Array.isArray(chat.participant_history)
        ? constructHistory(chat.participant_history)
        : [];
    // combine chat messages and transfer history
    let conversationHistory = chat ? this.props.conversationHistory : [];
    if (this.props.flags.pe18974) {
      if (chat && chat.history && this.props.conversationHistory.length === 0) {
        conversationHistory = chat.history;
      }
    } else {
      if (chat && this.props.conversationHistory.length === 0) {
        conversationHistory = chat.history;
      }
    }
    let lastTimestamp
    const messages = _([...conversationHistory, ...summaries])
      .sortBy((item) => {
        return item.chat_msg
          ? item.chat_msg.timestamp
          : item.assignor
          ? item.timestamp
          : item.time;
      })
      .filter((item, index, messages) => {
        const nextItem = messages[index + 1] || {};
        // dont show multiple consecutive 'This chat has been transferred' labels.
        // show only one instead
        if (item.action && nextItem.action) {
          return false;
        }
        if (
          item.assignee &&
          nextItem.assignee &&
          isQueueMessage(item) &&
          !isQueueMessage(nextItem) &&
          nextItem.timestamp - item.timestamp < 2000
        ) {
          return false;
        }
        if (
          this.props.flags.pe29359 &&
          item.chat_msg &&
          item.chat_msg.msg_type === "analytics_update"
        ) {
          return false;
        }
        return true;
      })
      .map(function (message, index) {

        let shouldShowTimestamp = true;
        if (flags.formatChatTimestamp && message.chat_msg) {
          const currentTimestamp = moment(moment.utc(message.chat_msg.timestamp).toDate())
          if (!lastTimestamp) {
            lastTimestamp = currentTimestamp;
          } else {
            const diff = parseInt(moment.duration(currentTimestamp.diff(lastTimestamp)).asMinutes())
            if (diff < 3 && diff >= 0) {
              shouldShowTimestamp = false;
            } else {
              lastTimestamp = currentTimestamp;
            }
          }
        }

        if (!message.chat_msg) {
          // if its a transfer show label
          let displayMessage = `Conversation escalated to ${isQueueMessage(message) || message.assignee === "endedByQueue" ? 
              `Queue (${message.assignee === "endedByQueue"? "General" : getSkill(message, 'assignee')})` : 
              `${capitalizeCase(message.assignee)} (${getSkill(message, 'assignor')})`}`;

          if (flags.pe18389 && message.reason === 'transfer') {
            displayMessage =`Conversation transfered to ${capitalizeCase(message.assignee)} (${getSkill(message, 'assignor')})`
          }

          return (
            <React.Fragment key={index}>
              <SystemMessage
                data-test="system-message"
                message={displayMessage}
                Icon={
                  isQueueMessage(message) || message.assignee === 'endedByQueue'
                    ? TransferIcon
                    : AgentIcon
                }
                startAt={message.timestamp}
                comment={message.comment}
                shouldShowTimestamp={shouldShowTimestamp}
              />
              {
                message.assignee === "endedByQueue" && 
                  <SystemMessage 
                    message={`Conversation ended by Queue (${message.assignee === "endedByQueue"? "General" : getSkill(message, 'assignee')})`}
                    Icon = {CloseIcon}
                    startAt={message.timestamp}
                    shouldShowTimestamp={shouldShowTimestamp}
                  /> 
              }
            </React.Fragment>
          );
        }

        return (
          <Message
            {...assign(
              {},
              {
                message: message,
                userName: user_name,
                userAvatar: user_avatar,
                key: index,
                chatId: chatId,
                to_be_migrated: to_be_migrated,
                shouldShowTimestamp
              }
            )}
          />
        );
      })
      .value();

    // Add a message of Automation started
    if (chat) {
      messages.unshift(
        <SystemMessage
          data-test="automation-started"
          message='Automation started'
          Icon={MessageIcon}
          startAt={chat.start_ts}
          key='start'
          shouldShowTimestamp={true}
        />
      );
    }

    if (isConsumerTyping) {
      messages.push(<MessageTyping data-test="message-typing" key={'typing'} />);
    }

    const isTransferFormOpen = this.props.flags.pe18383
        ? this.state.isTransferFormOpen && this.props.chatType === TabTypes.active
        : this.state.isTransferFormOpen && !this.props.isSessionEnded && this.props.chatType === TabTypes.active
    const getMessages = () => (
      <>
        <div ref={this.topDivRef} />
        <div>{messages}</div>
        {((this.state.isTransferFormOpen && this.props.flags.newTransferForm && this.props.chatType === TabTypes.active) || ((this.props.isSessionEnded || (this.props.chatType === TabTypes.archived && !this.props.isFetching && this.props.chat)) && this.props.flags.newClassifyChatForm)) && (
          <EndConversationComment
            data-test="get-messages"
            session={this.props.isSessionEnded}
            chat_id={chatId}
            onClose={this.onCloseCommentForm}
            isActiveTab={this.props.chatType === TabTypes.active}
            isArchived={this.props.chatType === TabTypes.archived}
            isTransferFormOpen={isTransferFormOpen}
            closeTransferForm={this.closeTransferForm}
            skillName={skillName}
            skillId={skillId}
            consumerName={this.props.consumer_name}
            archivedChatTags = { this.props.chat && this.props.chat.end_tags ? this.props.chat.end_tags.length > 0 ? [...this.props.chat.end_tags] : [] : null}
            archivedComment= {this.props.chat && this.props.chat.end_comment ? this.props.chat.end_comment : null}
          />
        )}
        <div ref={this.bottomDivRef} />
      </>
    );

    const renderMessagesWithArrow = () => {
      return (
        <div>
          {!this.props.isSessionUserInfoIsOpen && (
            <OpenPanelButton
              data-test="open-panel-button"
              openPanel={this.openUserPanel}
              content={'Open Panel'}
              overflow={this.state.overflow}
              popoverIsOpen={
                this.state.endChatPopoverIsOpen ||
                this.state.isTransferPopoverOpen
              }
            ></OpenPanelButton>
          )}
          {this.state.scrollToNewMessage && (
            <>
              <ChatWithScrollArrow
                data-test="chat-with-scroll-arrow"
                chatType={this.props.chatType}
                scrollDirection='Down'
                onClick={this.scrollToBottom}
                newMessage={this.state.scrollToNewMessage}
                newMessageTimeExceeded={this.state.newMessageTimeExceeded}
              />
              {this.state.scrollDirection === 'Up' && this.state.showArrow && (
                <ChatWithScrollArrow
                  data-test="chat-with-scroll-arrow-up"
                  scrollDirection='Up'
                  onClick={this.scrollToTop}
                />
              )}
            </>
          )}
          {!this.state.scrollToNewMessage && this.state.showArrow && (
            <ChatWithScrollArrow
              data-test="chat-with-scroll-arrow-false"
              chatType={this.props.chatType}
              scrollDirection={this.props.flags.removeUpChevron ? "Down" : this.state.scrollDirection}
              onClick={this.state.scrollToEnd}
            />
          )}
          {getMessages()}
        </div>
      );
    };
    return (
      <div
        data-test="chat-messages"
        className='chat-messages'
        ref={this.messageScrollRef}
        onScroll={this.onMessageScroll}
        onMouseDown={this.handleMouseEvent}
        onMouseUp={this.handleMouseEvent}
      >
        {this.props.isFetching ? (
          <Spinner data-test="spinner" offsetTop={100} />
        ) : (
          renderMessagesWithArrow()
        ) }
      </div>
    );
  };

  handleChatboxChange = (event) => {
    const chatboxText = event.target.value;

    this.setState({ chatboxText }, this.saveDraft);
    this.toggleIsTyping(event);
  };

  setPresetMessage = (cannedResponse, category) => {
    const appended = `${this.state.chatboxText}${cannedResponse.text}`;
    this.setState({ chatboxText: appended }, this.saveDraft);
    this.toggleIsTyping(null);
    if (window.innerWidth < this.state.minimumWindowBreakPoint) {
      sessionStorage.setItem('userInfoIsOpen', JSON.stringify(false));
      this.props.toggleUserInfo(false);
    }
    activityTracker.logEvent(
      activityTracker.eventTypeNames.SEND_CANNED_RESPONSE,
      {
        cannedResponseText: cannedResponse.text,
        conversationId: this.props.chat_id,
        categoryName: category.name,
      }
    );
  };

  handleResetMessage = () => {
    this.setState({ chatboxText: '' }, this.saveDraft);
  };

  isActiveSession = () => {
    var chatId = this.props.chat_id;
    return !!_.find(
      this.props.chatSessions,
      (session) => session.id === chatId
    );
  };

  openEndChatPopover = () => {
    this.setState({
      endChatPopoverIsOpen: true,
      inModalState: true,
    });
  };

  openPostChatModal = () => {
    this.setState({ postChatModalIsOpen: true });
  };

  openTransferChatPopover = () => {
    this.setState({
      isTransferPopoverOpen: !this.state.isTransferPopoverOpen,
    });
  };

  openTransferForm = () => {
    this.props.fetchAgents().then(data => {
      if(this.props.agentsStore.filter(agent => agent.status !== "offline" && agent.id !== this.props.userId).length) {
        this.setState({
          isTransferFormOpen: true
        },
        this.scrollToBottom);
      } else {
        this.setState({
          noAgentsAvailable: true
        });
        if(!this.props.flags.pe18408) {
          setTimeout(() => {
            this.setState({
              noAgentsAvailable: false
            });
          }, 2500);
        }

      }
    });    
  };

  closeTransferForm = () => {
    this.setState({
      isTransferFormOpen: false
    });
  };

  closeEndChatPopover = () => {
    this.setState({
      endChatPopoverIsOpen: false,
      inModalState: false,
    });
  };

  handleSubmitEndChatPopover = () => {
    let nextState = {
      endChatPopoverIsOpen: false,
      inModalState: this.props.hasPostChatAction ? true : false,
    };

    if (this.props.hasPostChatAction) {
      // Open the post chat modal if comment or tags are enabled
      this.showCommentForm();
      if(!this.props.flags.newClassifyChatForm){
      sessionStorage.setItem('userInfoIsOpen', JSON.stringify(false));
      }
      assign(nextState, {
        postChatModalIsOpen: true,
      });
      this.setState(nextState);
    } else {
      const { match } = this.props;
      this.props.history.push(`/${match.params.pypeId}/chats`);
    }
    if(!this.props.flags.newClassifyChatForm){
      sessionStorage.setItem('userInfoIsOpen', JSON.stringify(false));
      this.props.toggleUserInfo(false);
    }
  };

  showCommentForm = () => {
    this.setState({ isCommentFormVisible: true });
    setTimeout(() => this.scrollToBottom(), 200);
  };

  onCloseCommentForm = (chatToRemove) => {
    this.setState({
      inModalState: false,
      postChatModalIsOpen: false,
      isCommentFormVisible: false,
      showClassificationInHistory:true,
    });
    const { match, removeChatSession, history, chat_id } = this.props;
    const { pypeId } = match.params;

    removeChatSession(chatToRemove ? chatToRemove : chat_id);
    history.push(`/${pypeId}/chats`);
  };

  closeTransferChatModal = (shouldRetry) => {
    const self = this;

    if (this._isMounted) {
      this.setState({
        isTransferPopoverOpen: false,
      });
    }

    if (shouldRetry) {
      setTimeout(() => {
        self.openTransferChatPopover(
          self.refs.SubHeaderBar.refs.transferChatBtn
        );
      }, 300);
    }
  };

  openUserPanel = (event) => {
    if (!this.props.flags.pe18055) {
      event.preventDefault();
    }
    sessionStorage.setItem('userInfoIsOpen', JSON.stringify(true));
    this.props.toggleUserInfo(true);
    this.forceUpdate();
  };

  handleSubmitLog = () => {
    this.showCommentForm();
    this.setState({
      postChatModalIsOpen: true,
      inModalState: false,
    });
    sessionStorage.setItem('userInfoIsOpen', JSON.stringify(false));
    this.props.toggleUserInfo(false);
  };

  handleCloseEndedSession = () => {
    const { removeChatSession, chat_id, history, match, classifyChat } =
      this.props;

    classifyChat({
      chat_id,
      user_cancel: true,
    }).then(
      () => {
        removeChatSession(chat_id);
        history.push(`/${match.params.pypeId}/chats`);
      },
      () => {
        // error
      }
    );
  };
  getCannedResponses() {
    const {
      agentFirstName,
      consumer_data,
      consumerName,
      cannedResponses,
      cannedResponseCategories,
    } = this.props;

    let consumerFirstName =
      consumer_data && (consumer_data.first_name || consumer_data.last_name)
        ? consumer_data.first_name ||
          consumer_data.last_name
            .split(' ')
            .reduce((acc, el) => {
              if (el !== 'Visitor') acc.push(el);
              return acc;
            }, [])
            .join(' ')
            .trim()
        : '';

    if (this.props.flags.pe18191) {
      if (this.props.consumerFirstName && this.props.consumerFirstName.trim()) {
        consumerFirstName = this.props.consumerFirstName.trim();
      }
    }

    const updatedCannedResponses = _(cannedResponses)
      .sortBy((cannedResponse) => cannedResponse.text.toLowerCase())
      .map((cannedResponse) => {
        const text = cannedResponse.text
          .replace(/{Agent First name}/gi, agentFirstName)
          .replace(/{Customer First name}/gi, consumerFirstName);
        const category = _.find(cannedResponseCategories, {
          id: cannedResponse.category,
        });
        return {
          ...cannedResponse,
          text,
          categoryName: category.text,
        };
      })
      .value();

    return updatedCannedResponses;
  }

  handleSendEmbed = (embedName) => {
    if (embedName === 'listPicker') {
      this.setState(
        {
          sendEmbed: 'listPicker',
          embed: {
            multiple_choice: true,
            post_url: null,
            label: "Please select a product you'd like to learn more about.",
            type: 'selection',
            id: 'Discovery.FrameworkBot',
            options: [
              {
                value: 'feature*feature_1',
                label: 'papaya enzyme smoothing lotion',
                description: '',
              },
              {
                value: 'feature*feature_2',
                label: 'rosehip hyaluronic serum',
                description: '',
              },
              {
                value: 'feature*feature_3',
                label: 'pomegrancate and turmeric soy cleanser',
                description: '',
              },
            ],
          },
          chatboxText: 'Sent ListPicker',
        },
        this.saveDraft
      );
    } else if (embedName === 'timePicker') {
      this.setState(
        {
          sendEmbed: 'timePicker',
          embed: {
            label: 'Please select a date',
            type: 'datepicker',
            id: 'Lynx.LynxBot',
            options: {},
            post_url: null,
          },
          chatboxText: 'Sent timePicker',
        },
        this.saveDraft
      );
    } else if (embedName === 'applePay') {
      this.setState({
        sendEmbed: 'applePay',
        chatboxText: 'Apple Pay Test',
      });
    }
  };

  closeUserPanel = () => {
    sessionStorage.setItem('userInfoIsOpen', JSON.stringify(false));
    this.props.toggleUserInfo(false);
  };

  render() {
    const { props, state } = this;
    const { chatboxText, error, submitting, isTransferPopoverOpen } = state;
    const {
      isFetching,
      stream,
      isAgentOffline,
      commentFormChatId,
      consumer_name,
      chat_id,
      chat,
      agentId,
      doesChatExist,
      isEmpty,
      consumer,
      isSessionEnded,
      commentRequired,
      tagsRequired,
      chatType,
      isBrowserStatusOffline,
    } = props;

    if (
      isSessionEnded &&
      chatType === TabTypes.active &&
      window.innerWidth <= this.state.minimumWindowBreakPoint
    ) {
      this.closeUserPanel();
    }

    var status = submitting ? 'Sending...' : null;

    var streamStatus = !stream ? 'inactive' : stream.status;

    const cannedResponses = this.getCannedResponses();
    const canPostMessage =
      streamStatus !== 'inactive' &&
      this.isActiveSession() &&
      !isAgentOffline &&
      !isBrowserStatusOffline;

    var messageInputPlaceholder;
    if (canPostMessage) {
      messageInputPlaceholder = 'Enter a message...';
    } else if (!canPostMessage) {
      if (!this.isActiveSession()) {
        messageInputPlaceholder = 'This chat has ended';
      } else if (streamStatus === 'inactive') {
        messageInputPlaceholder =
          'You cannot message this contact because you have disabled this stream.';
      } else if (this.props.isAgentOffline) {
        messageInputPlaceholder =
          'You cannot message this contact because you are currently marked as offline.';
      } else if (isBrowserStatusOffline) {
        messageInputPlaceholder =
          `You are offline, please check your internet connection and try again`;
      } else {
        messageInputPlaceholder =
          "You cannot message this contact at this time because they haven't turned this Stream on.";
      }
    }
    let sessionUserInfoIsOpen = JSON.parse(
      sessionStorage.getItem('userInfoIsOpen')
    );

    return (
      <div
        data-test='conversation-component'
        className='pane pane-detail conversation-history'
      >
        {this.state.postChatModalIsOpen &&
        !this.props.flags.newClassifyChatForm ? (
          <PostChatModal
            data-test='post-chat-modal'
            chat_id={chat_id}
            closeModal={this.onCloseCommentForm}
          />
        ) : null}
        {isTransferPopoverOpen ? (
          <TransferChatPopover
            data-test="transfer-chat-popover"
            chat_id={chat_id}
            consumer_name={consumer_name}
            show={isTransferPopoverOpen}
            closeModal={this.closeTransferChatModal}
          />
        ) : null}
        <ErrorBoundary>
          <div className='full-height chat-wrapper full-width'>
            <div className='full-height chat-content-wrapper'>
              <Toast ref='toast' />

              {isSessionEnded &&
              chatType === TabTypes.active &&
              !props.flags.newEndChatBanner ? (
                <EndedChatBanner
                  data-test='ended-chat-banner'
                  session={isSessionEnded}
                  hasPostChatAction={this.props.hasPostChatAction}
                  canClose={!commentRequired && !tagsRequired}
                  onClose={this.handleCloseEndedSession}
                  onClassify={this.openPostChatModal}
                />
              ) : (
                !isFetching &&
                  <SubHeaderBar
                    data-test='sub-header-bar'
                    agentId={agentId}
                    chat_id={chat_id}
                    name={consumer_name || ''}
                    isActiveTab={chatType === TabTypes.active}
                    popoverIsOpen={this.state.endChatPopoverIsOpen}
                    onSubmit={this.handleSubmitEndChatPopover}
                    closePopover={this.closeEndChatPopover}
                    onClickEndChat={this.openEndChatPopover}
                    noAgentsAvailable={this.state.noAgentsAvailable}
                    onClickTransferChat={this.props.flags.newTransferForm ? this.openTransferForm : this.openTransferChatPopover}
                    commentRequired={commentRequired}
                    tagsRequired={tagsRequired}
                    isTransferFormOpen={this.props.flags.newTransferForm && this.state.isTransferFormOpen}
                    endChatBanner={isSessionEnded && chatType === TabTypes.active}
                  />
              )}
              {this.renderChatLog()}
              {!isSessionEnded &&
              chatType === TabTypes.active &&
              !isFetching ? (
                <div>
                  <ChatInput
                    data-test='chat-input'
                    canPostMessage={canPostMessage}
                    status={status}
                    error={error}
                    messageInputPlaceholder={messageInputPlaceholder}
                    onSubmit={this.handleSubmit}
                    onFileDrop={this.handleFileDrop}
                    onKeyDown={this.handleKeyDown}
                    message={isBrowserStatusOffline ? "" : chatboxText}
                    attachment={this.state.attachment}
                    onChange={this.handleChatboxChange}
                    onRemoveAttachment={this.removeAttachment}
                    willSendEmbed={this.state.sendEmbed}
                  />
                </div>
              ) : null}
            </div>
            {props.isSessionUserInfoIsOpen &&
            sessionUserInfoIsOpen &&
            consumer ? (
              <userInfo.UserInfo
                data-test="user-info"
                consumerId={consumer}
                shouldFetchInitialChats={false}
                onSubmitLog={this.handleSubmitLog}
                chatId={chat_id}
                events={chat && chat.events}
                handleSendEmbed={this.handleSendEmbed}
                currentEmbed={this.state.sendEmbed}
                onSelect={this.setPresetMessage}
                cannedResponses={cannedResponses}
                currentTab={chatType}
              />
            ) : null}
          </div>
        </ErrorBoundary>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  pypeId: state.pype.details.id,
  pype: state.pype.details,
  isSessionUserInfoIsOpen: state.agent.isSessionUserInfoIsOpen,
  commentRequired: state.pype.details.end_comment_required,
  tagsRequired: state.pype.details.end_tags_required,
  agentsStore: state.agentsForPype.agents,
  userId: state.botUserSession.user.user_id,
});

const mapDispatchToProps = (dispatch) => ({
  toggleUserInfo(isSessionUserInfoIsOpen) {
    return dispatch(agentActions.toggleUserInfo(isSessionUserInfoIsOpen));
  },
  setLastReadMessage(data) {
    return dispatch(conversationsActions.setLastReadMessage(data));
  },
  fetchAgents() {
    return dispatch(agentsActions.fetchAvailableAgents());
  },
  setCloseToChatEndId(id){
    return dispatch(conversationsActions.setCloseToChatEndId(id));
  }
});

export const ComponentWithFeatureFlags = withLDConsumer()(Component);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withOptimizely(ComponentWithFeatureFlags))
);