import React from 'react'
import PropTypes from 'prop-types';
import SubNavItem from './SubNavItem';
import _ from 'lodash';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

const style = {
  ribbon: {
    marginRight: '-20px'
  }
}

const NAV_ITEMS = [{
  link: 'chat-routing',
  icon: `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-chat-routing.svg`,
  iconActive: `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-chat-routing-active.svg`,
  title: 'Chat routing',
  salesforceOrder: 1
},
  {
    link: 'agents',
    icon: `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-headset.svg`,
    iconActive: `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-headset-active.svg`,
    title: 'Agents',
    salesforceOrder: 2
  },
  {
    link: 'autoresponders',
    icon: `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-respond.svg`,
    iconActive: `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-respond-active.svg`,
    title: 'Autoresponses',
    salesforceOrder: 3
  },
  // {
  //   link: 'broadcasts',
  //   icon: `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-broadcasts.svg`,
  //   iconActive: `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-broadcasts-active.svg`,
  //   title: 'Broadcasts',
  //   salesforceOrder: 4
  // },
  {
    link: 'crm-data',
    icon: `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-puzzle.svg`,
    iconActive: `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-puzzle-active.svg`,
    title: 'CRM data integration',
    isPypeManagerFeature: true,
    salesforceOrder: 11
  },
  {
    link: 'chat-logs',
    icon: `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-bubble.svg`,
    iconActive: `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-bubble-active.svg`,
    title: 'End chat options',
    salesforceOrder: 5
  },
  {
    link: 'canned-responses',
    icon: `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-canned-response.svg`,
    iconActive: `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-canned-response-active.svg`,
    title: 'Quick replies',
    salesforceOrder: 6
  },
  {
    link: "pypestream-api",
    icon: `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/ico-pypestreamapi.svg`,
    iconActive: `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/ico-pypestreamapi-active.svg`,
    title: "Pypestream API",
    salesforceOrder: 7
  },
  {
    link: 'skills',
    icon: `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-skills.svg`,
    iconActive: `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-skills-active.svg`,
    title: 'Skills',
    isPypeManagerFeature: true,
    salesforceOrder: 10
  },
  {
    link: 'manage-tags',
    icon: `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-tag.svg`,
    iconActive: `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-tag-active.svg`,
    title: 'Tag management',
    isPypeManagerFeature: true,
    salesforceOrder: 12
  },
  {
    link: 'user-privacy',
    icon: `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/privacy-icon-inactive.svg`,
    iconActive: `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/privacy-icon.svg`,
    title: 'User privacy',
    salesforceOrder: 8
  },
  {
    link: 'webchat',
    icon: `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-webchat.svg`,
    iconActive: `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-webchat-active.svg`,
    title: 'Web Conversational Interface',
    salesforceOrder: 9
  }];

// With react-router-4 scroll-restoration is no longer provided out-of-the-box 
// https://reacttraining.com/react-router/web/guides/scroll-restoration
// therefore a workaround is implemented to auto-scroll to last clicked element
// https://stackoverflow.com/questions/51906708/react-router-v4-keep-scrolling-position-when-switching-components
var clickedId = 0
class SubNavList extends React.Component{

  handleClick = (id) => {
    clickedId = id
  }
  componentDidMount() {
    const item = document.querySelector(
      ".restore-" + clickedId
    );
    if (item && window.innerHeight < item.getBoundingClientRect().bottom) {
      item.scrollIntoView();
    }
  }

  render() {
    const { props } = this 
    const isPypeManagerEnabled = props.isPypeManagerEnabled;
    const NEW_NAV_ITEMS = props.flags && props.flags.brandInfo && props.flags.brandInfo.showAPI ? NAV_ITEMS : NAV_ITEMS.filter(item =>item.link !== "pypestream-api" );
    const navItems = isPypeManagerEnabled ? NEW_NAV_ITEMS : _.sortBy(NEW_NAV_ITEMS, (item) => (item.salesforceOrder));
    const ADMIN_SKILLS_PATH = 'skills';
    const ADMIN_CANNED_RESP_PATH = 'canned-responses';
    
    const navList = _.map(navItems, (item, i) => {
      const { link, icon, iconActive, title, isPypeManagerFeature } = item;
      const isDisabled = !isPypeManagerEnabled && isPypeManagerFeature;
      let extension = null;

      if(link === ADMIN_SKILLS_PATH &&
        window.location.pathname !== ADMIN_SKILLS_PATH && props.isSkillHavingNoAgents) {
        extension = <img src={`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/something-wrong-big.svg`} width="30" height="30"/>
      } else if(link === ADMIN_CANNED_RESP_PATH) {
        extension = <img src={`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-new-ribbon.svg`} width="59" height="26" style={style.ribbon}/>
      }

      return (
        <SubNavItem
          key={i}
          id={i}
          onLinkClick={this.handleClick}
          link={link}
          icon={icon}
          iconActive={iconActive}
          isDisabled={isDisabled}
          extension={extension}>
          {title}
        </SubNavItem>);
    });

    return (
    <div className='admin-nav-list list list-unstyled'>
      {navList}
    </div>);
  }
};

SubNavList.propTypes = {
  isPypeManagerEnabled: PropTypes.bool
};

export default (withLDConsumer()(SubNavList));
