import React from 'react';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';
import { Route, withRouter } from "react-router-dom";

import CampaignsList from './CampaignsList';
import CampaignBlank from './CampaignBlank';
import sdk from 'sdk'
import CampaignDetail from './CampaignDetail';
import ErrorBoundary from 'components/ui/ErrorBoundary';

const {streamActions, campaignsActions }  = sdk


class CampaignsComponent extends React.Component{
  state = {
    isModalOpen: false
  }

  componentDidMount() {
    this.props.fetchStreams();
    this.props.fetchCampaigns();
  }

  render () {
    var {
      campaigns,
      streams,
      pype,
      match
    } = this.props;

    return (
      <div className="content-inner">
        <div className="panes">
          <div className="pane pane-list">
            <div className="pane-header">
            </div>
            <ErrorBoundary>
              <CampaignsList campaigns={campaigns}
                           streams={streams}
                           pype={pype}
                           isFetching={this.props.isFetching}
                           />
            </ErrorBoundary>
          </div>
          <div className="pane pane-detail">
            <Route
              exact
              path={`${match.path}`}
              component={CampaignBlank}
            />
            <Route
              exact
              path={`${match.path}/view/:id`}
              component={CampaignDetail}
            />
          </div>
        </div>
      </div>
    );
  }
};


var mapStateToProps = function (state) {
  return {
    campaigns: state.campaignsForPype.campaigns,
    streams: state.streamsForPype.streams,
    pype: state.pype.details,
    isFetching: state.campaignsForPype.isFetching
  };
};

var mapDispatchToProps = function(dispatch) {
  return bindActionCreators({
    fetchCampaigns: campaignsActions.fetchCampaigns,
    receiveCampaigns: campaignsActions.receiveCampaigns,
    fetchStreams: streamActions.fetchStreamsIfNeeded
  }, dispatch);
};

export default  withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  // Campaign items in <CampaignsList> vanishes at times without this
  // option. <CampaignsList> should be the component mapping campaigns
  // in mapStateToProps, not this one (<Campaigns>)
  {pure: false}
)(CampaignsComponent));
