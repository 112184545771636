import React from 'react'
import PropTypes from 'prop-types'

const CloseButton = (props) => (
  <button type="button" className="close" onClick={props.onClick}>
    <img src={`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-modal-close.svg`} width="15" height="15" />
  </button>
)

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default  CloseButton
