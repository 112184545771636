import upload from '../TUSClient';
import {
  UPDATE_PYPE,
  UPDATED_PYPE,
  UPDATE_UNREAD_COUNT,
  UPDATE_AGENT_HOURS,
  UPDATED_AGENT_HOURS,
  REQUEST_BROADCAST_CONFIG,
  RECEIVE_BROADCAST_CONFIG,
  REQUEST_PATTERN_RULES,
  RECEIVE_PATTERN_RULES,
  REQUEST_PYPE_METRICS,
  RECEIVE_PYPE_METRICS,
  SET_PYPE,
  RECEIVE_CUSTOMER
} from "./../constants"
import RESTClient from '../RESTClient';
import SDK from '../SDK'

// Updating pype details
export function updatePype(data) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {

      dispatch({
        type: UPDATE_PYPE
      });
      
      // if no pype ID provided get actual from state
      if (!data.id) {
        data.id = getState().pype.details.id;
      }

      var logoFile = data.logoFile;
      delete data.logoFile;

      if (logoFile) {
        upload(logoFile, {
          context: 'company',
          context_id: data.id,
          filename: logoFile.name
        }, function () { }).then(
          (url) => {
            SDK.updatePype({
              ...data,
              logo: url
            }).then(() => {
              resolve()
            })
          },
          (error) => {
            // TODO: catch errors.
            SDK.updatePype(data).then(() => {
              resolve()
            })
          }
        )
      }
      else {
        SDK.updatePype(data).then(() => {
          resolve()
        })
      }
    })
  }
}

export function updatedPype(req_id, pype) {
  return (dispatch, getState) => {
    dispatch({
      req_id,
      type: UPDATED_PYPE,
      pype
    })
  }
}

export function updateUnreadCount(count) {
  return {
    type: UPDATE_UNREAD_COUNT,
    unreadConversationsCount: count
  }
}

// Update agent scheduling
function _updateAgentHours() {
  return {
    type: UPDATE_AGENT_HOURS
  }
}


export function updateAgentHours(agentHours) {
  // TODO: x_update response doesn't return updated queue data
  return (dispatch, getState) => {
    dispatch(_updateAgentHours())
    return SDK.updateAgentHours({
        entity_id: getState().pype.details.id,
        data: agentHours
    }).then((data) => {
    })
  }
}

export function updatedAgentHours(agentHours) {
  return {
    type: UPDATED_AGENT_HOURS,
    agentHours
  }
}

// TODO merge updatePype() into this
const updateEventBasedBroadcasts = (pypeData) => (dispatch, getState) => {
  /*
    receiveBroadcastConfiguration sets "phone_number", "phone_id", "sms_token",
     "sms_id"
  */
  const {
    broadcast_stream_id,
    broadcast_channel,
    broadcast_sms_limit
  } = pypeData

  let data = {
    broadcast_channel
  }
  if (broadcast_channel === 'pypestream_and_sms') {
    data.broadcast_sms_limit = broadcast_sms_limit
  }
  return Promise.all([
    SDK.updateEventBasedBroadcastsForPype({
      entity_id: getState().pype.details.id,
      broadcast_stream_id
    }),
    SDK.updateEventBasedBroadcastsForStream({
      entity_id: broadcast_stream_id,
      data
    })
  ]).then(() => {
    dispatch({ type: UPDATE_PYPE })
  })
}

const requestBroadcastConfig = () => ({
  type: REQUEST_BROADCAST_CONFIG
})

// TODO dispatch from .then() once platform returns ACK for x_configure_broadcast_sms
const fetchBroadcastConfiguration = () => (dispatch) => {
  dispatch(requestBroadcastConfig())
  return SDK.getBroadcastConfiguration().then((data) => {
  })
}

const requestPatternRules = () => ({
  type: REQUEST_PATTERN_RULES
})

const createPatternRule = (masking_rules) => (dispatch, getState) => {
  return SDK.createPatternRule({
    entity_id: getState().pype.details.id, 
    masking_rules
  }).then(() => {
    dispatch({ type: UPDATE_PYPE })
  })
}

const receivePatternRule = (data) => ({
  type: RECEIVE_PATTERN_RULES,
  data,
})

const receiveBroadcastConfiguration = (data) => ({
  type: RECEIVE_BROADCAST_CONFIG,
  data,
})

/*
  Pype metrics
*/
const requestPypeMetrics = () => ({
  type: REQUEST_PYPE_METRICS
})

const receivePypeMetrics = (data) => ({
  type: RECEIVE_PYPE_METRICS,
  data
})

const fetchPypeMetrics = (skills = "") => {
  return (dispatch, getState) => {

    return new Promise(function (resolve, reject) {
      const { env, customer_id, id } = getState().pype.details

      dispatch(requestPypeMetrics())

      RESTClient.getPypeMetrics(env, customer_id, id, skills).then(({ body }) => {
        dispatch(receivePypeMetrics(body))
        resolve()
      }).catch((error) => {
        reject(error);
      })

    });


  }
}

const setPype = (pypeId) => {
  return (dispatch, getState) => {
    const pype = getState().pypes.pypes.find((pype) => (pype.id === pypeId));

    dispatch({
      type: SET_PYPE,
      pype,
    })
  }
}

const receiveCustomer = (data) => ({
  type: RECEIVE_CUSTOMER,
  data
})


const fetchCustomer = (customer_id) => {
  return (dispatch, getState) => {
    // dispatch(requestPypes())
    return new Promise(function (resolve, reject) {
      RESTClient.getCustomer(
        customer_id
      ).then(({ body }) => {
        dispatch(receiveCustomer(body))
        resolve();
      }).catch((error) => {
        reject(error);
      });

    })
  }
}

export {
  updateEventBasedBroadcasts,
  fetchBroadcastConfiguration,
  receiveBroadcastConfiguration,
  createPatternRule,
  fetchPypeMetrics,
  setPype,
  fetchCustomer
}
