import React from 'react';
import styled from "styled-components";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from '@material-ui/styles';
import { ReactComponent as HeadsetMicIcon } from "img/icons/agent.svg";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { ReactComponent as WarningIcon } from "img/icons/icon-warning.svg";
import { OutlinedButton } from 'ui-elements';
import Constant from "core/constant";
import { renderToolTip } from '../../ui/tooltip';

let convoName = null;

export const ConvoCard = styled(Card)`
  width: 96%;
  height: 128px;
  margin: 8px 70px 8px 9px;
  padding: 0 15px 0 0;
  border-left: 4px solid transparent;
  font-family: 'Open Sans';
  &:hover {
    background: #f9f9f9;
    & ${convoName} {
      color: #0a3ab4;
    }
  }
  &.selected {
    background: #f9f9f9;
    border-left-style: solid;
    border-left-width: 4px;
    border-left-color: #0a3ab4;
  }
  &.ended {
    border-bottom: solid 1px #bdbdbd;
  }
  }
`;

export const ConvoTitleBox = styled(Box)`
  margin-top: 16px;
  margin-left: 16px;
  margin-bottom: 0px;
  height: 24px;
  display: flex;
  justify-content: space-between;
`;

convoName = styled(Box)`
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  font-size: 16px;
  color: #29302e;
  &.selected {
    color: #0a3ab4;
  }
  ${ConvoCard}:hover & {
      color: #0a3ab4;
  }
  &.isClickDisabled {
    opacity: 0.5;
  }
  &.ended {
    color: #7a6769;
  }
  &.truncate {
    width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
export const ConvoName = convoName;

export const ConvoTimeBox = styled(Box)`
  display: flex;
  justify-content: space-between;
`;

export const ConvoTimer = styled(Box)`
  white-space: nowrap; 
  height: 16px;
  margin: 4px 2.5px 4px 0;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  color: #228094;
  &.agent-has-replied {
    color: #7a6769;
  }
  &.overtime  {
    color: #e45f45;
  }
`;

export const MessageIndicator = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background: #228094;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  &.agent-has-replied {
    background: #f9f9f9;
    color: #8e8e8e;
  }
  &.overtime {
    background: #e45f4f;
  }
  &.closed {
    background: transparent;
    color: #0a3ab4;
    &:hover {
      background: #cbdffb;
    }
  }
`;

export const ConvoSubheading = styled.div`
  height: 20px;
  margin-top: -6px;
  margin-left: 16px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.43;
  letter-spacing: normal;
  color: #7a6769;
  &.isClickDisabled {
    opacity: 0.5;
  }
`;
export const ConvoBox = styled.div`
  position: relative;
  height: 48px;
  margin: 8px 1px 16px 16px;
`;

export const ConvoMessage = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #29302e;
  align-items: 'center';
  flex-wrap: 'wrap';

  &.unread {
    font-weight: 600;
  }
  &.convoEnded {
    color: #7a6769;
  }
  &.isClickDisabled {
    opacity: 0.5;
  }
`;

export const ConvoAgent = styled.div`
  display: flex;
`;
export const StyledHeadSetIcon = styled(HeadsetMicIcon)`
  width: 20px;
  height: 20px;
  & path {
    fill: #8e8e8e;
  }
`;

export const StyledAccessTimeIcon = styled(AccessTimeIcon)`
  width: 18px !important;
  height: 18px !important;
`;

export const StyledCheckCircleOutlineIcon = styled(CheckCircleOutlineIcon)`
  width: 18px !important;
  height: 18px !important;
`;

export const ArchiveDate = styled.div`
  white-space: nowrap;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: right;
  color: #7a6769;
`;

export const EndChatOption = styled.div`
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: right;
  color: #7a6769;
  &.isClickDisabled {
    opacity: 0.5;
  }
`;

export const ClassifyButton = styled(props => <OutlinedButton {...props} />)`
  font-size: 12px;
  margin: 0 8px 0 0;
  padding: 3px 6px 5px;
`;

export const WarningMessage = styled(Box)`
  width: 100%;
  float: right;
  margin: 10px 0px 10px -18px;
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #29302e;
`;

export const WarningIconBox = styled(Box)`
  margin-top: 15px;
  margin-right: 12px;
`;

export const UnreadMessage = styled(Box)`
  font-size: 12px;
`;

export const WarningTooltip = withStyles({
  tooltip: {
    width: '364px',
    height: '104px',
    borderRadius: '4px',
    backgroundColor: '#ffffff',
    boxShadow: '0 0 6px 0 rgba(0, 0, 0, 0.3)',
    borderRadius: '4px',
    fontSize: '14px',
    fontFamily: "Open Sans",
    lineHeight: '1.29',
    letterSpacing: 'normal',
    padding: '8px 12px',
  },
})(Tooltip);

export const renderWarningToolTip = (title, node, left) => {
  const toolTipProps = {
    node: node,
    title: title,
    width: '364px',
    height: '104px',
    borderRadius: '4px',
    backgroundColor: '#ffffff',
    boxShadow: '0 0 6px 0 rgba(0, 0, 0, 0.3)',
    left: `${left}px`,
    top: '-58px',
    placement: 'right'
  }
  return renderToolTip(toolTipProps);
};

export const disabledTitle = (
  <Grid container wrap="nowrap">
    <Grid item>
      <WarningIconBox>
        <WarningIcon />
      </WarningIconBox>
    </Grid>
    <Grid item>
      <WarningMessage>
        {Constant.TOOLTIPS.convo_card_disabled_warning}
      </WarningMessage>
    </Grid>
  </Grid>
);

const HOUR = 1000 * 60 * 60;
const MINUTE = 1000 * 60;
const SECOND = 1000;

// Calculate timedifference between two dates.
export const timeDifference = (date1, date2) => {
  let difference = date2.getTime() - date1.getTime();

  const hoursDifference = Math.floor(difference / HOUR);
  difference -= hoursDifference * HOUR;

  const minutesDifference = Math.floor(difference / MINUTE);
  difference -= minutesDifference * MINUTE;

  const secondsDifference = Math.floor(difference / SECOND);

  return hoursDifference
    ? `${hoursDifference}h${
        minutesDifference > 0 ? `${minutesDifference}m` : ""
      }`
    : minutesDifference > 9
      ? `${minutesDifference}m`
      : `${minutesDifference}:${
          secondsDifference < 10 ? "0" + secondsDifference : secondsDifference
        }`
};