import {
  RESET_CHAT_SESSIONS_WITH_CONSUMER,
  REQUEST_CHAT_SESSIONS_WITH_CONSUMER,
  RECEIVE_CHAT_SESSIONS_WITH_CONSUMER,
} from '../constants'

import SDK from '../SDK'

const resetChatSessionsWithConsumer = () => ({
  type: RESET_CHAT_SESSIONS_WITH_CONSUMER,
})

export const requestChatSessionsWithConsumer = (consumerId) => ({
  type: REQUEST_CHAT_SESSIONS_WITH_CONSUMER,
  consumerId,
})

export const receiveChatSessionsWithConsumer = (data) => ({
  type: RECEIVE_CHAT_SESSIONS_WITH_CONSUMER,
  receivedAt: Date.now(),
  data,  // Includes meta data, paging info, and chat sessions
})

export const fetchChatSessionsWithConsumer = (consumerId, reset=false) => (dispatch, getState) => {

  /*
    If @reset is true, clear the stored chat sessions and reset paging data.
  */

  if (reset) {
    dispatch(resetChatSessionsWithConsumer())
  }

  if (!getState().chatSessionsWithConsumer.hasMore) {
    return Promise.resolve()
  }

  dispatch(requestChatSessionsWithConsumer(consumerId))

  return SDK.getChatSessionsForUser({
    pype_id: getState().pype.details.id,
    user_id: consumerId,
    more: getState().chatSessionsWithConsumer.more,
    items_per_page: getState().chatSessionsWithConsumer.itemsPerPage,
  }).then((data) => {

    // // <TESTING:TEMP> Mock data
    // import mock from '../mockdata';
    // data = mock(reset).data

    dispatch(receiveChatSessionsWithConsumer(data))
  })

}
