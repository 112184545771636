import _ from 'lodash';

import React from 'react'
import PropTypes from 'prop-types'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import cx from 'classnames';
import {Column, Cell} from 'fixed-data-table-2';

import css from './AgentsTabPanel.css';


import sdk from 'sdk';
import StatsWrapper from './StatsWrapper';
import {numberWithCommas} from 'utils/format_number';
import {truncate} from 'utils/string';
import {formatTimestamp} from 'utils/format_time';
import TableResponsive from 'components/ui/TableResponsive';

const {agentsActions, skillsActions} = sdk;


const ROW_HEIGHT = 46;
const HEADER_HEIGHT = 25;

const getSkillsForAgents = (skills, agents) => {
  const agentsForSkills = {};

  agents.forEach((agent)=>{
    agent.skills.forEach((skill) => {
      agentsForSkills[skill] = agentsForSkills[skill] || [];

      if(!_.some(agentsForSkills[skill], {id: agent.id})){
        agentsForSkills[skill].push(agent)
      }
    })
  });

  const skillsWithAgents = _.chain(skills)
  .sortBy('name')
  .sortBy((skill) => {
    return skill.id === "general" ? 0 : 1; // show general skill first
  })
  .map((skill) => {
    let defaultStats = {
      max_conv: 0,
      active_agents: 0
    }

    if(!agentsForSkills[skill.id]){
      return {
        ...skill,
        ...defaultStats
      }
    }

    const stats = agentsForSkills[skill.id].reduce((stats, agent) => {
      return {
        max_conv: stats.max_conv + (agent.status === 'online' ? agent.max_conv : 0), // count only agents that are active
        active_agents: stats.active_agents + (agent.status === 'online' ? 1 : 0)
      }
    },defaultStats)

    return {
      ...skill,
      ...stats
    }
  })
  .sortBy((skill) => {
    return skill.active_agents > 0 ? 0 : 1;
  })
  .value();

  return skillsWithAgents
}

const getActiveAgents = (agents) => {
  return _.filter(agents, (agent) => (agent.status === 'online'))
}

class SkillsTabPanel extends React.Component{
  componentWillMount() {
    this.props.fetchAgents();
    this.props.fetchSkills();
  }
	render(){
    const {props} = this;
    const {skillsStore,isFetching, agentsStore} = props;
    const skills = getSkillsForAgents(skillsStore, agentsStore);
    const totalChats = _.sumBy(skills, 'active_agent_chat_count') + _.sumBy(skills, 'active_bot_chat_count');
    const totalChatlimit = _.sumBy(skills, 'max_conv');
    const totalResponseCount = _.sumBy(skills, 'daily_response_count');
    const totalReplyTime = _.sumBy(skills, 'daily_response_time');
    const totalChatsEnded =   _.sumBy(skills, 'daily_session_count');
    const totalActiveAgents = getActiveAgents(agentsStore).length;
    const tableHeightWithScroll = skills.length < 10 ? skills.length * ROW_HEIGHT + 75 : ROW_HEIGHT*10 + 75;

    // NOTE: ResponsiveFixedDataTable requires that we specify height in wrapper as well.
    // Else it gets 1px height.
		return (
			<StatsWrapper isLoading={isFetching} border={false}>
        <div className={css.skillsInfo}>Metric reports may be delayed.</div>
        <div className={"m-t-10"} style={{height:tableHeightWithScroll}}>
          <TableResponsive
            rowHeight={ROW_HEIGHT}
            rowsCount={skills.length}
            height={tableHeightWithScroll}
            headerHeight={HEADER_HEIGHT}
            footerHeight={ROW_HEIGHT}>
            {<Column
              header={<Cell>Name</Cell>}
              cell={({rowIndex, ...props}) => {
                const skill = skills[rowIndex];
                const {max_conv,name,active_agents} = skill;
                const skillName = truncate(name,25);

                return <Cell {...props}>
                  <span className={cx({'fade': active_agents === 0})}>{skillName}</span>
                </Cell>
              }}
              footer={<Cell>ALL SKILLS</Cell>}
              width={150}
              flexGrow={1}
            />}
            {<Column
              header={<Cell>
                <span className="pull-left">Active Chats</span>
                <span className={css.chatLimitHeader}>Chat limit</span>
              </Cell>}
              cell={
                ({rowIndex, ...props}) => {
                  const row = skills[rowIndex];
                  const {max_conv, active_agent_chat_count, active_bot_chat_count, active_agents} = row;
                  const totalActiveChatCount = active_agent_chat_count + active_bot_chat_count;
                  const maxConv = active_agents === 0 ? '-' : numberWithCommas(max_conv);
                  const barWidth = max_conv ? totalActiveChatCount/max_conv * 300 : 0;
                  return <Cell {...props}>
                    <div className={css.chatCountBarBg}>
                      <span className={"leftCount"}>{numberWithCommas(totalActiveChatCount)} chats</span>
                      <span className={"rightCount"}>{maxConv}</span>
                      <div className={"chatCountBar"} style={{width:barWidth}}></div>
                    </div>
                  </Cell>
                }
              }
              flexGrow={1}
              width={320}
              footer={()=> {
                const barWidth = !totalChatlimit ? 0 : totalChats/totalChatlimit * 300;
                const chatLimitTotal = !totalChatlimit ? '-' : numberWithCommas(totalChatlimit);

                return <Cell>
                  <div className={css.chatCountBarBg + " total"}>
                    <span className={"leftCount"}>{numberWithCommas(totalChats)} chats</span>
                    <span className={"rightCount"}>{chatLimitTotal}</span>
                    <div className={"chatCountBar"} style={{width:barWidth}}>
                      <span className={"leftCount"}>{numberWithCommas(totalChats)} chats</span>
                    </div>
                  </div>
                </Cell>
                }
              }/>}
            {<Column
              align="right"
              header={<Cell>ACTIVE AGENTS</Cell>}
              cell={
                ({rowIndex, ...props}) => {
                  const row = skills[rowIndex];
                  const {active_agents} = row;
                  return <Cell {...props}>{numberWithCommas(active_agents)}</Cell>
                }
              }
              footer={(props)=>(<Cell {...props}>{numberWithCommas(totalActiveAgents)}</Cell>)}
              flexGrow={1}
              width={135}
            />}
            {<Column
              align="right"
              header={<Cell className={css.cellNoLeftPadding}>Reply Time</Cell>}
              cell={
                ({rowIndex, ...props}) => {
                  const row = skills[rowIndex];
                  const {daily_response_time, daily_response_count} = row;
                  const replyTime = formatTimestamp(!daily_response_count ? 0 : daily_response_time/daily_response_count);
                  return <Cell {...props}>
                    <span className={replyTime.css}>{replyTime.value}</span>
                  </Cell>
                }
              }
              footer={(props) => {
                  const _totalReplyTime = formatTimestamp(!totalResponseCount ? 0 : totalReplyTime/totalResponseCount);
                  return <Cell {...props}>
                    <span className={_totalReplyTime.css}>{_totalReplyTime.value}</span>
                  </Cell>
                }
              }
              flexGrow={1}
              width={110}
            />}
            <Column
              align="right"
              header={<Cell className={css.cellNoLeftPadding}>Chats ended</Cell>}
              cell={
                ({rowIndex, ...props}) => {
                  const row = skills[rowIndex];
                  const {daily_session_count} = row;
                  return <Cell {...props}>{numberWithCommas(daily_session_count)}</Cell>
                }
              }
              footer={(props)=>(<Cell {...props}>{numberWithCommas(totalChatsEnded)}</Cell>)}
              flexGrow={1}
              width={110}
            />
          </TableResponsive>

        </div>
      </StatsWrapper>
			);
	}
}

SkillsTabPanel.propTypes = {
  skillsStore: PropTypes.array.isRequired
}

const mapStateToProps = (state) => {

  return {
    isFetching: state.agentsForPype.isFetching || state.skills.isFetching,
    skillsStore: state.skills.skillsStore,
    agentsStore: state.agentsForPype.agents,
  }
}

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({
    fetchAgents: agentsActions.fetchAgents,
    fetchSkills: skillsActions.fetchSkills
  }, dispatch)
)

export default  connect(
  mapStateToProps,
  mapDispatchToProps
)(SkillsTabPanel);
