import React from 'react'
import PropTypes from 'prop-types'

import LabelCheckbox from 'components/ui/forms/LabelCheckbox';
import style from './style.css';

const Component = (props) => {
  const {skill} = props;
  const isGeneralSkill = skill.id === 'general';
  const skillName = isGeneralSkill ? 'General' : skill.name;

  const Checkbox = (<LabelCheckbox
    name={skill.id}
    value={skill.isSkillAssigned || false}
    onClick={props.onToggle.bind(this, skill)} />)

  return (
    <div className={style.row}>
      <p className={"no-margin"}>{skillName}</p>
      { Checkbox }
    </div>
  )
}

Component.propType = {
  onToggle:PropTypes.func.isRequired,
  skill:PropTypes.object.isRequired,
  value: PropTypes.bool
}

Component.defaultProps = {
}

export default Component
