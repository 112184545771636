import {connect} from 'react-redux'
import assign  from 'object-assign';
import _ from 'lodash';
import Component from './Component'
import sdk from 'sdk';
const {agentsActions, skillsActions} = sdk;

const mapStateToProps = (state, ownProps) => {
  return {
    skillsStore: state.skills.skillsStore,
    isFetchingSkills: state.skills.isFetching,
    customerName: state.pype.details.customerName
  }
}

const mapDispatchToProps = (dispatch, props) => ({

  onSubmit(data) {
    data = assign({}, data, {id: props.agent.id})
    return Promise.all([
      // One request to update user property (eg, first_name, email, etc)
      dispatch(agentsActions.updateAgent(data, 'user')),

      // A separate request required as per the API to update agent-specific props (eg, max_conv)
      dispatch(agentsActions.updateAgent(data, 'agent')),
    ])

  }

})

export default  connect(
  mapStateToProps,
  mapDispatchToProps
)(Component)
