import React from 'react'
import PropTypes from 'prop-types'

const MessageWrapper = (props) => (
  <div className={props.className}>
    {props.children}
  </div>
)

MessageWrapper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

MessageWrapper.defaultProps = {
  className: '',
}

export default  MessageWrapper
