import { withLDConsumer } from "launchdarkly-react-client-sdk";
import React, { Component } from "react";
import { connect } from "react-redux";
import sdk from "sdk";
const { pypesActions, sessionActions } = sdk;

const withAuth = (WrappedComponent, options = {}) => {

  // eslint-disable-next-line
  const HOC = class extends Component {
    _isMounted = false;

    state = {
      isSessionSet: false,
    }

    componentDidMount() {
      this._isMounted = true;
      this.setSession()

    }

    componentWillUnmount() {
      this._isMounted = false;
    }

    async setSession() {
      let rolesInJwt = [];
      const { fetchPypes, updateSession, user, session, updateDeviceIdIfNeeded, flags } = this.props
      const { roles } = user;

      if(!Array.isArray(roles)){
        rolesInJwt = [roles]
      } else {
        rolesInJwt = roles;
      }

      const pypeRoleMap = {}

      rolesInJwt.forEach(roleInJwt => {
        // roleInJwt can contain customer_id (ex: bot managers) or pype_id (agents, pype admins)
        if(roleInJwt.pype_id) {
          pypeRoleMap[roleInJwt.pype_id] = pypeRoleMap[roleInJwt.pype_id] || [];
          // there can be multiple roles for a user in a pype. ex: super_admin + pype_admin
          pypeRoleMap[roleInJwt.pype_id].push(roleInJwt.role_definition.name);
        }
      })

      if(!Object.keys(pypeRoleMap).length) {
        // if non-wpm user redirect them to root url where role-based redirect will happen
        window.location.href = '/'
        return;
      }

      // non WPM user
      if(!(Object.keys(pypeRoleMap).includes('pype_admin') || Object.keys(pypeRoleMap).includes('agent'))) {
        // TODO redirect to /unauthorized
      }
      updateSession({
        roles: pypeRoleMap
      })

      if (!flags.pe18795) {
        await fetchPypes();
      }

      await updateDeviceIdIfNeeded();

      if (this._isMounted) {
        this.setState({ isSessionSet: true })
      }

    }
    render() {
      const { isSessionSet } = this.state;

      return isSessionSet ? <WrappedComponent {...this.props} /> : null;
    }
  };

  const mapStateToProps = state => ({
      isAuthenticated: state.botUserSession.isAuthenticated,
      user: state.botUserSession.user,
      session: state.session
  });

  const mapDispatchToProps = dispatch => ({
    fetchPypes: () => dispatch(pypesActions.fetchPypes()),
    updateSession: (data) => dispatch(sessionActions.updateSession(data)),
    updateDeviceIdIfNeeded: () => dispatch(sessionActions.updateDeviceIdIfNeeded()),
  });

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(withLDConsumer()(HOC));
};

export default withAuth;
