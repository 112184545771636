import styled from 'styled-components';

const VerticalListItemLabel = styled.span`
    display: flex;
    justify-content: space-between;
    padding-left: ${props => `${16 * props.order}px`};
    color: ${props => props.isParent || props.selected ? '#0a3ab4' : '#7a6769'};
    svg {
        fill: ${props => props.isParent || props.selected ? '#0a3ab4' : '#7a6769'};
        transition: all 0.2s linear;
        transform: ${props => props.expandedIcon ? 'rotate(0deg)' : 'rotate(-90deg)'};
    }
`;

export { VerticalListItemLabel };