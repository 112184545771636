import moment from 'moment';

export const formatTimestamp = (seconds) => {
	const secondsInADay = 24 * 60 * 60;
	let value,css;
	if(seconds < secondsInADay){
		value = moment.utc(seconds*1000).format('HH:mm:ss')
	} else {
		value = '24+ hours';
		css = 'text-danger bold';
	}
	return {
		value,
		css
	}
}

// export default {
// 	formatTimestamp
// }