import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames';

const LabelInput = (props) => {
  const {children, className, label, error, hint, focus, ...rest} = props
  const cssClass = cx('label-input', className, {
    'has-error': typeof error === 'string' && error !== '',
  })
  return (
    <label className={cssClass}>
      {(children || label) ? <div className="label-text">{children || label}</div> : null}
      <input autoFocus={focus} {...rest} />
      {error ? <p className="small text-danger m-b-0">{error}</p> : null}
      {hint ? <p className="hint">{hint}</p> : null }
    </label>
  )
}

LabelInput.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  hint: PropTypes.string,
  focus: PropTypes.bool
}

LabelInput.defaultProps = {
  className: '',
  error: '',
}

export default  LabelInput
