// External
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux';
import cx from 'classnames';
import _ from 'lodash'

// Local
import Icon from '../../ui/forms/Icon';
import SubHeader from '../details/SubHeader';
import TagTable from './TagTable';
import NewTagRow from './NewTagRow';

class TagConfig extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      tagEditing: null,
      isAddingNewTag: false,
    }
  }

  handleClickAdd = () => {
    this.setState({
      isAddingNewTag: true,
      tagEditing: null,
    })
  }

  handleCloseAddNewTag = () => {
    this.setState({isAddingNewTag: false})
  }

  handleEditRow = (id) => {
    this.setState({
      tagEditing: id,
      isAddingNewTag: false,
    })
  }

  handleFinishEditRow = (data) => {
    this.setState({tagEditing: null})
  }

  handleCloseRowEdit = () => {
    this.setState({tagEditing: null})
  }

  handleSubmitAddNewTag = () => {
    this.setState({isAddingNewTag: false})
  }

  render() {

    const {props, state} = this
    const { tagsStore, enableTags, enableTagCodes } = props
    const tags = _.values(tagsStore)
    const isAddButtonDisabled = state.isAddingNewTag

    return (
      <div className={cx('tag-config', {'feature-disabled': !enableTags})}>
        <SubHeader>Manage tags</SubHeader>
        <p className="tag-instructional">Below you can add and edit tag names, descriptions and codes.</p>
        {tags.length > 0 ? (
          <TagTable
            tagEditing={state.tagEditing}
            enableTagCodes={enableTagCodes}
            isAddingNewTag={state.isAddingNewTag}
            onEditRow={this.handleEditRow}
            onFinishEditRow={this.handleFinishEditRow}
            onCloseRowEdit={this.handleCloseRowEdit} />
        ) : null}
        {state.isAddingNewTag ? (
          <div className="tag-row">
            <NewTagRow
              className="bottom-row add-tag-row"
              enableTagCodes={enableTagCodes}
              onClose={this.handleCloseAddNewTag}
              onSubmit={this.handleSubmitAddNewTag} />
          </div>
        ) : null}
        <div className="controls">
          <a className="add-tag-btn" disabled={isAddButtonDisabled} onClick={this.handleClickAdd}>
            <Icon src={`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-add.svg`} />Create a tag
          </a>
        </div>
      </div>
    )
  }
}

TagConfig.propTypes = {
  tagsStore: PropTypes.array.isRequired,
  enableTags: PropTypes.bool.isRequired,
  enableTagCodes: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => ({
  tagsStore: state.tags.store,
})

export default  connect(mapStateToProps)(TagConfig)
