import * as agent from './agent'
import agentChatSessions from './agentChatSessions'
import history from './history'
import pype from './pype'
import tags from './tags'
import users from './users'
import * as skills from './skills'

export default {
  agent,
	agentChatSessions,
	history,
  pype,
  tags,
	users,
  skills
}
