import _ from 'lodash'

const sortedUsers = (users) => {
  const activeUsers = users.filter(u => u.status === 'active');
  const invitedUsers = users.filter(u => u.status !== 'active');

  // <NOTE> Migrated users have their full name in the last name
  // field. This should not matter here, but the developer should be
  // aware of it.
  const normalize = (user) => (
     user.first_name.trim().toLowerCase() + user.last_name.trim().toLowerCase()
  )

  const isSpecialChar = (s) => /[^a-zA-Z0-9]/.test(s)

  // The order goes as such:
  // 1. Special characters
  // 2. Numbers
  // 3. Letters (case-insensitive)
  const compareChar = (a, b) => {
    const isSpecialA = isSpecialChar(a)
    const isSpecialB = isSpecialChar(b)
    if (isSpecialA && !isSpecialB) {
      return -1
    } else if (!isSpecialA && isSpecialB) {
      return 1
    }
    if (a > b) {
      return 1
    } else if (a < b) {
      return -1
    }
    return 0
  }

  const sortedActiveUsers = [...activeUsers].sort((a, b) => {
    a = normalize(a)
    b = normalize(b)
    if (a === b) {
      return 0
    }
    for (let i=0; i < a.length; i++) {
      if (b[i] === undefined) {
        return 1
      }
      const result = compareChar(a[i], b[i])
      if (result !== 0) {
        return result
      }
    }
    return -1
  })

  const sortedInvitedUsers = _.sortBy(invitedUsers, (user) => [user.email && user.email.toLowerCase(), user.phone])

  return [
    ...sortedActiveUsers,
    ...sortedInvitedUsers,
  ]
};

// TODO: obsolete function. having here for backward compatibility  
// Gets the date the user joined the pype
const getJoinedDate = (userId) => (usersState) => {
  const user = _.find(usersState.list, (user) => user.id === userId)
    || _.find(usersState.store, (user) => user.id === userId)
  if (user == null) {
    throw new Error(`Error: Could not find user in state`)
  }
  if (_.isEmpty(user.streamSubs)) {
    throw new Error('Error: getJoinedDate(): Could not find `user.streamSubs`')
  }
  return _.first(user.streamSubs).pype_join_date
}

export default {
  sortedUsers,
  getJoinedDate,
}
