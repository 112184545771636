import style from './style.css';
import React from 'react'
import PropTypes from 'prop-types'
import ChatTimestamp from '../ChatTimestamp';
import Messages from '../Messages';

class ChatContent extends React.Component {

  render () {
    const {props} = this
    return (
      <div className={style.ChatContent}>
        <Messages />
      </div>
    )
  }

}

export default  ChatContent
