import SDK from '../SDK'
import RESTClient from '../RESTClient'
import {
  REQUEST_USER_META,
  RECEIVE_USER_META
} from "./../constants"


const requestUserMeta = () => ({
  type: REQUEST_USER_META,
})

const receiveUserMeta = (data) => ({
  type: RECEIVE_USER_META,
  data,
})

export const fetchUserMeta = (user_id) => (dispatch) =>{
  dispatch(requestUserMeta())
  SDK.getUserMeta({
    user_id
  }).then((data) => {
    return dispatch(receiveUserMeta(data))
  })
}


export const fetchCRMData = (url) => (dispatch) => {
  return new Promise((resolve, reject) => {
    RESTClient.getCRMData(url)
    .then((response) => {
      return response.body;
    }).then((data) => {
      resolve(data);
    }).catch((err) => {
      reject(err);
    });
  });
}