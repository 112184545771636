export default function createReducer(initialState, actionHandlers) {
  return (state = initialState, action) => {
    const reduceFn = actionHandlers[action.type];
    const defaultReduceFn = actionHandlers['default'];
    const alwaysReduceFn = actionHandlers['always'];

    let result;

    if (reduceFn) {
      result = reduceFn(state, action);
    } else if (defaultReduceFn) {
      result = defaultReduceFn(state, action);
    } else {
      result = state;
    }

    if (alwaysReduceFn) {
      result = alwaysReduceFn(result, action);
    }

    return result;
  };
}
