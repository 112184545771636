import React from 'react';
import style from './style.css';
import _ from 'lodash';
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom';

import AgentsListSection from '../AgentsListSection';

const NoAgents = () => {
  return (
    <div className={style.zeroState}>
      <h4>No agents yet</h4>
      <p><Link to="agents">Click here</Link> to manage your agents</p>
    </div>
  )
}

class Component extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      isUndoEnabled: false,
      tempAgents: []
    }
  }

  handleAgentSelect = (agent) => {
    this.props.onAgentSelect(agent)
    this.setState({isUndoEnabled: false})
  }

  handleSelectAll = () => {
    this.setState({
      tempAgents: _.cloneDeep(this.props.agents),
      isUndoEnabled: true
    })
    this.props.onAgentSelectAll()
  }

  handleAgentsRestore = () => {
    const tempAgents = this.state.tempAgents;
    this.props.onAgentsRestore(tempAgents);
    this.setState({
      tempAgents: [],
      isUndoEnabled: false
    })
  }

  handleDeselectAll = () => {
    const agents = [...this.props.agents];
    agents.forEach((agent) =>{
      agent.isSkillAssigned = false
    })
    this.props.onAgentsRestore(agents);
    this.setState({isUndoEnabled: false})
  }

  render () {
    const {props, state} = this
    const self = this;

    const unselectedAgents = _.filter(props.agents, {isSkillAssigned: false});
    const selectedAgents = _.filter(props.agents, {isSkillAssigned: true});
    const totalCount = props.agents.length
    const selectedCount = selectedAgents.length;


    if(totalCount === 0){
      return <NoAgents id="AgentsListEmpty"/>
    }
    return (
      <div className={style.wrapper}>
        <div className={style.selectControls}>
          { state.isUndoEnabled ?
            (<div className="inline">
              <button id="AgentsListUndo" onClick={this.handleAgentsRestore}
                className={`btn btn-link no-padding fs-13`}>
                <strong>Undo</strong>
              </button>
              <span className={`btn-link ${style.middot}`}>&middot;</span>
             </div>) : null
           }
           {selectedCount === totalCount ?
             (<button id="AgentsListDeselectAll" onClick={this.handleDeselectAll}
                className={`btn btn-link no-padding fs-13`}>
                <strong>Deselect all</strong>
              </button>) :
              (<button id="AgentsListSelectAll" onClick={this.handleSelectAll}
                className={`btn btn-link pull-right no-padding fs-13`}>
                <strong>Select all</strong>
              </button>)
            }
        </div>
        {
          selectedCount > 0 ?
          <div>
            <AgentsListSection
              id="AgentsListSectionSelected"
              title={`Selected Agents (${selectedCount} of ${totalCount})`}
              onToggle={this.handleAgentSelect}
              list={selectedAgents}
            />
            {unselectedAgents.length > 0 ?
              <AgentsListSection
                id="AgentsListSectionUnselected"
                title={`Unselected agents`}
                onToggle={this.handleAgentSelect}
                list={unselectedAgents}
              /> : null}
          </div> :
          <AgentsListSection
            title={`List of Agents (${totalCount})`}
            onToggle={this.handleAgentSelect}
            list={unselectedAgents}
          />
        }
      </div>
    )
  }

}

Component.propTypes = {
  // search: PropTypes.string,
  agents: PropTypes.array,
  onAgentSelect: PropTypes.func.isRequired,
  onAgentSelectAll: PropTypes.func.isRequired,
  // TODO use onAgentsRestore for select All, deselect all and undo
  onAgentsRestore: PropTypes.func.isRequired
}

Component.defaultProps = {
  agents: []
}

export default  Component
