import {
  REQUEST_STREAMS, RECEIVE_STREAMS,
  CREATED_STREAM, EDIT_STREAM,
  RECEIVE_STREAM_LAST_BROADCAST, UPDATE_STREAM_LAST_BROADCAST, initSeqConsts,
} from "../constants"
import _ from 'lodash'
import { BOT_USER_SESSION_LOGOUT } from "bot-user-session";

const getInitialState = () => ({
  isFetching: false,
  didInvalidate: false,
  streams: [],
  subscribers: [],
  streamsSubscribers: [],
  streamsLastBroadcast: {},
  editingStreamID: ''
});

export default function streamsForPype(state = getInitialState(), action) {
  switch (action.type) {
    case BOT_USER_SESSION_LOGOUT: {
      return getInitialState();
    }
    case REQUEST_STREAMS:
    case initSeqConsts.FETCHING:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      })
    case RECEIVE_STREAMS:
      let streamsObj = action.streams;
      let stats = streamsObj.stream_stats;
      let streams = streamsObj.streams;
      let streamsWithStats = _.chain(streams)
       .sortBy((stream) => (stream.stream_name.toLowerCase()))
       .map((stream) => (Object.assign({}, {stats: _.find(stats, {stream_id: stream.id})}, stream)))
       .value();

      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        streams: streamsWithStats,
        lastUpdated: action.receivedAt
      })
    case EDIT_STREAM:
      return Object.assign({}, state, {
        editingStreamID: action.payload.streamID
      })
    case CREATED_STREAM: {
      let createdStream = action.stream;
      let existing = _.find(state.streams, {id: createdStream.id});
      if(!existing){ // newly created stream
        createdStream.stats = {
          daily_subscription_count:0,
          daily_unsubscription_count:0,
          daily_msg_count: 0,
          daily_avg_resp_time: 0,
          subscription_count: 0,
          msg_count: 0,
          avg_resp_time: 0
        }
        return Object.assign({}, state, {
          streams: [...state.streams, createdStream],
          editingStreamID: '',
          isFetching: false
        })
      }
      let streams = state.streams;
      var updatedStream = Object.assign(existing, action.stream);
      var index = _.indexOf(streams, _.find(streams, {id: updatedStream.id}));
      streams.splice(index, 1, updatedStream);
      return Object.assign({}, state, {
        streams,
        editingStreamID: '',
        isFetching: false
      })
    }
    case RECEIVE_STREAM_LAST_BROADCAST:
      var streamsLastBroadcast = Object.assign({}, state.streamsLastBroadcast);

      if (action.campaigns && action.campaigns.length) {
        var campaign = action.campaigns[0];
        streamsLastBroadcast[campaign.stream_id] = campaign
      }
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        streamsLastBroadcast: streamsLastBroadcast,
        lastUpdated: action.receivedAt
      })
    case UPDATE_STREAM_LAST_BROADCAST:
      var streamsLastBroadcast = Object.assign({}, state.streamsLastBroadcast);
      var campaign = action.campaign;
      streamsLastBroadcast[campaign.stream_id] = campaign
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        streamsLastBroadcast: streamsLastBroadcast,
        lastUpdated: action.receivedAt
      })
    default:
      return state
  }
}
