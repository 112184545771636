import React from 'react'

class AgentBusy extends React.Component {

  render () {
    return (
      <div className="agent-status-card agent-busy-notification">
        <div className="inner-content">
          <h3>You are currently marked as online (busy).</h3>
          <p>
            You will not receive a new chat until you end at least one
            chat in your queue.
          </p>
        </div>
      </div>
    )
  }

}

export default  AgentBusy
