import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import MessageMediaBlock from './MessageMediaBlock';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { formatTimestampForChatLog } from '../../utils/date_time';

const Message = (props) => {
  let chatFeature = props.flags.newMessageBubble;
  let message = props.message.chat_msg;
  let id = message.id;
  let ownMessage =
    message.from_side !== 'consumer' &&
    message.from_side !== 'anonymous_consumer';
  let utc = message.timestamp;
  let createdAtLocalTime = moment.utc(utc).toDate();
  createdAtLocalTime = props.flags.formatChatTimestamp
    ? formatTimestampForChatLog(createdAtLocalTime)
    : moment(createdAtLocalTime).format("MMMM Do YYYY, h:mm:ssa");

  // Case to remove empty message if it's a persistent menu botMessage
  const isBotMessage =
    !_.isEmpty(message.app_object) && message.from_side === 'bot';

  let messageClass = ownMessage ? 'sent' : 'received';
  messageClass = (chatFeature && isBotMessage) ? messageClass + ' bot' : messageClass;
  
  if (
    isBotMessage &&
    JSON.parse(atob(message.app_object)).type === 'persistent_menu'
  ) {
    return (
      <div
        className='conversation-history-message'
        data-id={id}
        style={{ opacity: 0, height: 0 }}
      ></div>
    );
  }

  return (
    <div className='conversation-history-message' data-id={id}>
      {props.shouldShowTimestamp && <div className='timestamp small fade text-center'>
        {createdAtLocalTime}
      </div>}
      <div
        key={id}
        className={
          chatFeature
            ? 'chat-message-feature ' + messageClass
            : 'chat-message ' + messageClass
        }
      >
        <div className='body'>
          <div className='light no-margin'>
            <MessageMediaBlock key={'mediablock' + id} message={message} />
          </div>
        </div>
      </div>
      <div className='clearfix'></div>
    </div>
  );
};

const MessageWithFeatureFlags = withLDConsumer()(Message);

export default MessageWithFeatureFlags;
