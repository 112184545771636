import React from 'react';
import style from './style.css';
import PropTypes from 'prop-types';

const Row = (props) => {
  return (
    <div className={style.row}>
      {props.children}
    </div>
  );
};

Row.propTypes = {
  children: PropTypes.node
};

export default  Row;