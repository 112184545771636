import style from './style.css';
import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import ApiItem from '../ApiItem';
import CreateApi from '../CreateApi';
import EditPanel from '../EditPanel';

const getSortedAPIs = (apis) => {
  return _.sortBy(_.values(apis), 'date_created')
}
class ApiList extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      openApi: null,
      editingApi: null,
      openCreate: false,
    }
  }

  componentWillMount() {
    const { fetchApi } = this.props;
    fetchApi()
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.apisStore.length < this.props.apisStore.length) {
      this.setState({
        openApi: null,
        editingApi: null,
      })
    }
  }

  handleToggle = (api) => {
    let action = '';
    if (this.state.openApi === api) {
      this.setState({openApi: null})
     } else {
      this.setState({
        openApi: api,
        editingApi: null,
      })
    }
  }

  handleClickEdit = (api) => {
    this.setState({
      openApi: null,
      editingApi: api.key,
    })
  }

  handleCloseEdit = (api) => {
    this.setState({editingApi: null}, () => {
      this.setState({openCreate: false})
    })
  }

  createNewApi = () => {
    this.setState({editingApi: true}, () => {
      this.setState({openCreate: true})
    })
  }


  render () {
    const {props, state} = this
    const { apisStore } = props
    const apis = getSortedAPIs(apisStore)
    return (
      <div className={style.WidgetList}>
        <div className={style.Content}>
          {apis.map((api) => (
            <ApiItem
              key={api.key}
              apis={apis}
              api={api}
              keepOpen={apis.length === 1}
              isOpen={api.key === state.openApi}
              isEditing={api.key === state.editingApi}
              isEditMode={state.editingApi != null}
              shouldMask={state.editingApi != null && api.key !== state.editingApi}
              onToggle={this.handleToggle}
              onClickEdit={this.handleClickEdit}
              onCloseEdit={this.handleCloseEdit} />
          ))}
          {this.state.openCreate && <EditPanel api={{}} onClose={this.handleCloseEdit} action="create" apis={apis}/>}
        </div>
        <CreateApi className={style.CreateWidget} shouldMask={state.editingApi != null} activate={this.createNewApi}/>
      </div>
    )
  }

}

ApiList.propTypes = {
  apisStore: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default  ApiList
