import _ from 'lodash'
import { BOT_USER_SESSION_LOGOUT } from "bot-user-session";
import { cannedResponsesConstants as types } from '../constants'

const getInitialState = () => ({
  isFetching: false,
  cannedResponses: [],
  categories: [],
  cannedResponseDrawers: {}
});

const cannedResponses = (state=getInitialState(), action) => {
  switch (action.type) {
  case BOT_USER_SESSION_LOGOUT: {
    return getInitialState();
  }
  case types.REQUEST_CANNED_RESPONSES:
    return {
      ...state,
      isFetching: true,
    }
  case types.RECEIVE_CANNED_RESPONSES:
    return {
      ...state,
      isFetching: false,
      cannedResponses: action.data.canned_responses,
      categories: action.data.categories
    }
  case types.REQUEST_CANNED_RESPONSE:
    return {
      ...state,
      isFetching: true,
    }
  case types.DELETED_CANNED_RESPONSE:
    return {
      ...state,
      cannedResponses: _.filter(state.cannedResponses, (cannedResponse) => (cannedResponse.id !== action.data.id))
    }
  case types.UPDATED_CANNED_RESPONSE:
    return {
      ...state,
      cannedResponses: _.map(state.cannedResponses, (cannedResponse) => {
        if(cannedResponse.id === action.data.id){
          return {
            ...cannedResponse,
            ...action.data
          }
        } else {
          return cannedResponse;
        }
      })
    }
  case types.CREATED_CANNED_RESPONSE:
    return {
      ...state,
      cannedResponses: [...state.cannedResponses, action.data]
    }
  case types.GET_CANNED_RESPONSE_DRAWER: {
    const quick_replies = localStorage.getItem('quick_replies');
    const agent_id = action.agent_id;
    const category_drawer = {};

    state.categories.forEach(element => {
      category_drawer[element.id] = true;
    });

    const _obj = {}
    let quickReplyDrawerState;

    if (!quick_replies) {
      _obj[agent_id] = category_drawer;
      if(agent_id) localStorage.setItem('quick_replies', JSON.stringify(_obj));
      quickReplyDrawerState = category_drawer;
    }
    else{
      let quick_replies_drawer = JSON.parse(quick_replies);
      
      if(!quick_replies_drawer[agent_id]) {
        quick_replies_drawer[agent_id] = category_drawer;
        if(agent_id) localStorage.setItem('quick_replies', JSON.stringify(quick_replies_drawer));
      }
      quickReplyDrawerState = quick_replies_drawer[agent_id];
    }
    return {
      ...state,
      cannedResponseDrawers: quickReplyDrawerState,
    }
  }
  case types.SET_CANNED_RESPONSE_DRAWER:{
    let quickReplies = localStorage.getItem('quick_replies');
    const quick_replies_drawer = JSON.parse(quickReplies);
    quick_replies_drawer[action.agent_id][action.category_id] = !quick_replies_drawer[action.agent_id][action.category_id];
    localStorage.setItem('quick_replies', JSON.stringify(quick_replies_drawer));
    return {
      ...state,
      cannedResponseDrawers: quick_replies_drawer[action.agent_id]
    }
  }
  default:
    return state
  }
}

export default cannedResponses
