import style from './style.css';
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames';
import _ from 'lodash';

import DeleteWarningModal from '../DeleteWarningModal';
import NewRuleModal from '../NewRuleModal';
import AppTooltip from 'components/shared/AppTooltip';
import IconButton from '../../../ui/forms/IconButton';
import activityTracker from "../../../../utils/activityTracker";

class RuleItem extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      activeModal: null, // 1-3 or null
      isModalOpen: false,
      isSubmitting: false,
      isRuleModalOpen: false
    }
  }

  // Edit feature not for this release
  handleClickEdit = (event) => {
    event.stopPropagation()
    this.setState({isRuleModalOpen: true})
  }

  openWarningModal = () => {
    this.setState({isModalOpen: true})
  }

  closeModal = () => {
    this.setState({isModalOpen: false})
  }

  closeNewRuleModal = () => {
    this.setState({isRuleModalOpen: false})
  }

  deleteSuccess = () => {
    const {props} = this;
    const { customerName } = props
    const {name} = props.rule;
    const label = `Name: ${name}, Pype: ${props.pypeName}`
    window.appCreateToast({
      message: 'Rule deleted successfully.',
      level: 'success',
    })
    activityTracker.logEvent(activityTracker.eventTypeNames.UPDATE_PYPE_SETTING, {settingName: 'user privacy',action: activityTracker.eventActionType.DELETE, customerName});
    this.closeModal()
  }

  handleError = () => {
    window.appCreateToast({
      message: 'Encountered an error deleting rule.',
      level: 'error',
    })
    this.closeModal()
  }

  handleDelete = () => {
    if (this.state.isSubmitting) {
      return
    }
    this.setState({isSubmitting: true})
    const rules = this.props.rules.concat()
    rules.splice(this.props.index, 1)
    return this.props.onDelete(rules).then(() => {
      this.setState({isSubmitting: false})
      this.deleteSuccess()
    }).catch((error) => {
        console.warn(error)
        this.setState({isSubmitting: false})
        this.handleError()
      })
  }

  inActiveRule = () => {
    if (this.props.rule.regex_patterns.length < 1 || this.props.rule.regex_patterns[0] === '') {
      return true
    }
    return false
  }

  render () {
    const {props, state} = this
    const {rule} = props
    const className = classNames(style.WidgetItem, props.className, {
      [style.isEditing]: props.isEditing,
      [style.isEditMode]: props.isEditMode,
      [style.shouldMask]: props.shouldMask,
    })
    const ruleName = rule.name
    return (
      <div className={className}>
        {state.isModalOpen && (
          <DeleteWarningModal
            isSubmitting={state.isSubmitting}
            onSubmit={this.handleDelete}
            ruleName={ruleName}
            closeModal={this.closeModal} />
        )}
        <div className={style.Panel} onClick={this.onClickPanel}>
          <div style={{display: 'flex'}}>
            {state.isRuleModalOpen &&
              <NewRuleModal new={false} onSubmit={this.handleSubmit} rule={props.rule} rules={props.rules} closeModal={this.closeNewRuleModal} index={props.index} />}
            {props.ruleType === 'defined' ?
              <img className={style.Edit} src={`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/edit-big.svg`} onClick={this.handleClickEdit} /> :
              <AppTooltip message="You cannot edit this rule." place="top" onClick={_.noop}>
                <img className={style.editInactive} src={`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/edit-big.svg`} />
              </AppTooltip>
            }
            <span style={{lineHeight: '30px'}}>
              <strong>{ruleName}</strong>
            </span>
          </div>
          {props.ruleType === 'defined' &&
            <div className={style.iconsContainer}>
            {this.inActiveRule() &&
              <span className={style.Caution}>
                <AppTooltip message="This rule is inactive due to missing rule patterns." place="top" onClick={_.noop}>
                    <IconButton src={`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/something-wrong-big.svg`} width={30} height={30} className="Caution"/>
                </AppTooltip>
              </span>
            }
            <span data-cy="delete-privacy-rule" className={style.Trash} onClick={this.openWarningModal}></span>
            </div>
          }
        </div>
      </div>
    )
  }
}

RuleItem.propTypes = {
  className: PropTypes.string,
  rule: PropTypes.object.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  shouldMask: PropTypes.bool.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  onCloseEdit: PropTypes.func.isRequired,
  index: PropTypes.number,
  pypeName: PropTypes.string.isRequired,
}

RuleItem.defaultProps = {
  className: '',
}

export default  RuleItem
