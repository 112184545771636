import {connect} from 'react-redux';
import Component from './Component';
import sdk from 'sdk';
const {widgetsActions} = sdk;

const mapDispatchToProps = (dispatch) => ({
  onSubmit (widget, name) {
    const data = {
      ...widget,
      widget_name: name,
    }
    return dispatch(widgetsActions.updateWidget(data))
  },
  onDelete (widget) {
    return dispatch(widgetsActions.deleteWidget(widget))
  },
})

export default  connect(null, mapDispatchToProps)(Component)
