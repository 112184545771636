/*
  Use global ui state
*/

import style from './style.css';
import React from 'react'
import PropTypes from 'prop-types'

import classNames from 'classnames';
import StreamsModal from '../StreamsModal';
import DesignModal from '../DesignModal';
import ScriptModal from '../ScriptModal';
import activityTracker from "../../../../utils/activityTracker";

class CreateWidget extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      step: null,  // 1-2 or null
      isScriptModalOpen: false,
      widget: null,
    }
  }

  handleClick = () => {
    const { customerName } = this.props
    
    if (this.props.shouldMask) {
      return
    }
    this.setState({step: 1})
    activityTracker.logEvent(activityTracker.eventTypeNames.START_CONVERSATION_INTERFACE_CREATION, { customerName });
  }

  closeModal = () => {
    this.setState({
      step: null,
      isScriptModalOpen: false,
    })
  }

  handleBack = () => {
    this.setState({step: this.state.step - 1})
  }

  handleSubmit1 = () => {
    this.setState({step: 2})
  }

  handleSubmit2 = () => {
    return this.props.onSubmit().then((data) => {
      this.setState({step: null})
      window.appCreateToast({
        level: 'success',
        message: 'Web Conversational Interface created successfully.',
      })
      // setTimeout(() => {
        this.setState({
          isScriptModalOpen: true,
          widget: data,
        })
      // }, 500)
    }).catch((error) => {
      console.warn(error)
      this.setState({step: null})
      window.appCreateToast({
        level: 'error',
        message: 'Error creating Web Conversational Interface.',
      })
    })
  }

  render () {
    const {props, state} = this
    const className = classNames(style.CreateWidget, {
      [style.shouldMask]: props.shouldMask,
    })
    return (
      <div className={className}>
        {(state.step === 1) &&
          <StreamsModal isNew onSubmit={this.handleSubmit1} closeModal={this.closeModal} />}
        {(state.step === 2) &&
          <DesignModal isNew onBack={this.handleBack} onSubmit={this.handleSubmit2} closeModal={this.closeModal} />}
        {state.isScriptModalOpen &&
          <ScriptModal isNew widget={state.widget} closeModal={this.closeModal} />}
        <button className={style.Button} onClick={this.handleClick}>
          <img className={style.Img} src={`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-add-big.svg`} />
          <span className={style.Text}>Create a new Web Conversational Interface</span>
        </button>
      </div>
    )
  }

}

CreateWidget.propTypes = {
  shouldMask: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default  CreateWidget
