import React from 'react'
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { isPermitted, PermissionAction, PermissionResource } from 'bot-user-session';

import css from './StatsTabs.css';

import AgentsTabPanel from './AgentsTabPanel';
import SkillsTabPanel from './SkillsTabPanel';
import StreamsTabPanel from './StreamsTabPanel';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

const TabActions = withLDConsumer()((props) => {
  const { activeTab, onClickShowStreams, isPMRoutingEnabled, customerId, pypeId, flags } = props;
  if (activeTab === 0 && isPMRoutingEnabled) {
    return (<a href={`${window.config.PS_CONSOLE_URL}/users`} className="btn btn-link no-padding m-l-5 m-t-5" target="_blank" rel="noopener noreferrer">
      <img src={`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-earphones.svg`} width="20" height="20" />
      <span className="m-l-10">Add or edit agents</span>
    </a>)
  } else if (activeTab === 1 && isPMRoutingEnabled && (isPermitted(PermissionResource.PYPE, PermissionAction.CREATE_SKILL, pypeId) || isPermitted(PermissionResource.PYPE, PermissionAction.UPDATE_SKILL, pypeId))) {
    return (<Link className="btn btn-link no-padding m-l-5 m-t-5" to="admin/skills">
      <img src={`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-skills-active.svg`} width="20" height="20" />
      <span className="m-l-10">Add or edit skills</span>
    </Link>)
  } else if (activeTab === 2 && (isPermitted(PermissionResource.STREAM, PermissionAction.CREATE, pypeId) || isPermitted(PermissionResource.STREAM, PermissionAction.UPDATE, pypeId))) {
    return !flags.removeManageStream && (<a className="btn btn-link no-padding m-l-5 m-t-5" onClick={onClickShowStreams}>
      <img src={`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-list.svg`} width="20" height="20" />
      <span className="m-l-10">Add or edit Streams</span>
    </a>);
  } else {
    return null;
  }
});

class StatsTabs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 0
    };
  }
  handleSelect(index, last) {
    this.setState({ activeTab: index });
  }
  render() {
    const { props } = this;
    const { state } = this;
    const {
      onClickShowStreams,
      isFetchingStreams,
      isFetchingAgents,
      isPMRoutingEnabled,
      customerId,
      pypeId,
      selectedSkill
    } = props;
    const { activeTab } = state;
    const shouldShowAgents = isPMRoutingEnabled; // TODO v3 no matching perm
    const shouldShowSkills = isPMRoutingEnabled && isPermitted(PermissionResource.PYPE, PermissionAction.READ_SKILL, pypeId);
    const shouldShowStreams = isPermitted(PermissionResource.STREAM, PermissionAction.READ, pypeId)
    return (
      <div className="relative m-t-20">
        <div className={css.tabAction + " p-10 pull-right"}>
          {
            (isFetchingStreams || isFetchingAgents) ? null :
              <TabActions
                onClickShowStreams={onClickShowStreams}
                activeTab={activeTab}
                isPMRoutingEnabled={isPMRoutingEnabled}
                customerId={customerId}
                pypeId={pypeId}
              />
          }
        </div>
        <Tabs
          className={css.tabs}
          selectedIndex={activeTab}
          onSelect={this.handleSelect.bind(this)}>
          <TabList>
            {
              shouldShowAgents ? <Tab>Agents</Tab> : null
            }
            {
              shouldShowSkills && selectedSkill.length === 0 ? <Tab>Skills</Tab> : null
            }
            {
              shouldShowStreams && selectedSkill.length === 0 ? <Tab>{this.props.flags.pe17180 ? 'Channels' : 'Streams'}</Tab> : null
            }
          </TabList>
          {shouldShowAgents ?
            <TabPanel>
              <AgentsTabPanel />
            </TabPanel>
            : null}
          {shouldShowSkills && selectedSkill.length === 0 ?
            <TabPanel>
              <SkillsTabPanel />
            </TabPanel>
            : null
          }
          {shouldShowStreams && selectedSkill.length === 0 ?
            <TabPanel>
              <StreamsTabPanel shouldSowReplyTimes={isPMRoutingEnabled} />
            </TabPanel> : null}
        </Tabs>
      </div>)
  }
}

StatsTabs.propTypes = {
}

const mapStateToProps = (state) => {
  return {
    isFetchingStreams: state.streamsForPype.isFetching,
    isFetchingAgents: state.agentsForPype.isFetching,
    isPMRoutingEnabled: state.pype.details.agent_routing === 'pypestream',
    customerId: state.pype && state.pype.details ? state.pype.details.customer_id : null,
    pypeId: state.pype && state.pype.details ? state.pype.details.id : null,
    selectedSkill: state.skills.selectedSkill
  }
}

export default connect(
  mapStateToProps
)(withLDConsumer()(StatsTabs));
