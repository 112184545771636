import React from 'react';
import styled from 'styled-components';
import { StyledList, StyledListItem } from './helpers';
import { Icon, IconNames } from '../../icons';

const DisplayNames = {
    List: 'List',
    ListItem: 'ListItem'
}

class List extends React.Component {
    static displayName = DisplayNames.List;
    constructor(props) {
        super(props);
        this.state = {
            selectedIndex: null,
            selectedParentIndex: null,
            expandedItems: {},
        }
    }

    render() {
        const { children, ...props } = this.props;
        return (
            <StyledList {...props}>{this.renderItems(children)}</StyledList>
        );
    }

    // Handles selcting list item (parent or child) on clicking as well as additional onClick callback
    handleClick = (indexVal, child, parentIndex, collapsible=false, orderIndex) => {
        this.setState(prevState => {
            const {expandedItems} = prevState;
            if (collapsible) {
                let presentItem = expandedItems[indexVal]
                if (presentItem) {
                    if (presentItem.expanded) {
                        this.collapseChildren(child.props.children, expandedItems, orderIndex);
                    }
                    presentItem.expanded = !presentItem.expanded;
                }
                else {
                    expandedItems[indexVal] = { expanded: true };
                }
                return {
                    selectedIndex: indexVal,
                    expandedItems,
                    selectedParentIndex: parentIndex,
                }
            }
            return {
                selectedIndex: indexVal,
                expandedItems,
                selectedParentIndex: parentIndex
            }
        }, () => {
            child.props.onClick && child.props.onClick();
        })
    }

    // Handles collapsing children recursively when their ancestor is collapsed
    collapseChildren = (children, expandedItems, orderIndex) => {
        if (children) {
            React.Children.forEach(children, (child, index) => {
                if (React.isValidElement(child) && this.isReactFragment(child)) {
                    const childOrderIndex = index + orderIndex + 5;
                    this.collapseChildren(child.props.children, expandedItems, childOrderIndex);
                }
                if (React.isValidElement(child) && !this.isReactFragment(child) && child.type.displayName === DisplayNames.ListItem) {
                    if (child.props.collapsible) {
                        const childOrderIndex = index + orderIndex + 10;
                        this.collapseChildren(child.props.children, expandedItems, childOrderIndex);
                    }
                    const indexVal = orderIndex ? `${orderIndex}-${index}` : index;
                    if (expandedItems[indexVal]) {
                        expandedItems[indexVal].expanded = false;
                    } 
                }
            });
        }
    }

    // Checks is a child is Fragment
    isReactFragment = (child) => {
        if (child.type) {
            return child.type === React.Fragment;
        }
        return child === React.Fragment;
    }

    // Renders all items recursively. Shows errors when any other child than <ListItem> is rendered to parent (List or ListItem)
    renderItems = (children, items = [], orderIndex = 0, collapsibleChildren = false, parentIndex= null, childOrder) => {
        if (!children || !React.Children.count(children)) {
            return items;
        }        

        React.Children.forEach(children, (child, index) => {
            if (React.isValidElement(child) && this.isReactFragment(child)) {
                const childOrderIndex = index + orderIndex + 5;
                this.renderItems(child.props.children, items, childOrderIndex);
            }
            else if (React.isValidElement(child) && !this.isReactFragment(child) && child.type.displayName === DisplayNames.ListItem) {
                const { selectedIndex, expandedItems, selectedParentIndex } = this.state;
                const { order, selected, collapsible, ...props } = child.props;
                const indexVal = `${orderIndex}-${index}`;
                const isSelected = selectedIndex ? indexVal === selectedIndex : selected;
                const orderValue = collapsibleChildren ? childOrder : (order || 1);
                const expanded = collapsibleChildren ? expandedItems[parentIndex] && expandedItems[parentIndex].expanded : true;
                const expandedIcon = collapsible && expandedItems[indexVal] && expandedItems[indexVal].expanded;
                const isParent = collapsible && selectedParentIndex && selectedParentIndex === indexVal;
                const isChild = collapsibleChildren;
                
                items.push(
                    <StyledListItem
                        className={indexVal}
                        expanded={expanded}
                        key={indexVal}
                        selected={isSelected}
                        isParent={isParent}
                        isChild={isChild}
                        onClick={() => this.handleClick(indexVal, child, parentIndex, collapsible, index + orderIndex + 10)}
                    >
                        {
                            React.cloneElement(child, {
                                selected: isSelected,
                                itemIndex: indexVal,
                                collapsible: collapsible,
                                expandedIcon: expandedIcon,
                                order: orderValue,
                                isParent: isParent,
                                isChild: isChild,
                                ...props,
                            })
                        }
                    </StyledListItem>
                );
                if (child.props.collapsible) {
                    const childOrderIndex = index + orderIndex + 10;
                    this.renderItems(child.props.children, items, childOrderIndex, true, indexVal, orderValue + 1)
                }
            }
            else {
                console.error('Incorrect Child element detected under <List> component. You need to use only ListItem as child element.')
            }
        });
        return items;
    }
}

export default List;