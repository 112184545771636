import style from './style.css';
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames';
import types from './types';
import ProfileIcon from '../ProfileIcon';

class Message extends React.Component {

  render () {
    const {props, context} = this
    const isRight = props.from === 'consumer'
    const isLeft = !isRight

    let contentStyle = {}
    if (context.design) {
      if (props.from !== 'consumer') {
        contentStyle = {
          background: context.design.agentBubbleColor,
          color: context.design.agentBubbleTextColor,
        }
      } else {
        contentStyle = {
          background: context.design.userBubbleColor,
          color: context.design.userBubbleTextColor,
        }
      }
    }

    const reprChildren = (children) => {
      if (isRight) {
        return children
      }
      return typeof children === 'string'
        ? children.replace(/\\n/g, String.fromCharCode(13))
        : children
    }
    const beforeBubble = () => {
      if (isLeft) {
        return <div
          className={classNames(style.Bubble, style.BeforeLeftBubble)}
          style={{ borderRightColor: context.design.agentBubbleColor }}
        />
      } else {
        return <div
          className={classNames(style.Bubble, style.BeforeRightBubble)}
          style={{ borderLeftColor: context.design.userBubbleColor }}
        />
      }
    }
    const afterBubble = () => {
      if (isLeft) {
        return <div className={classNames(style.Bubble, style.AfterLeftBubble)} />
      } else {
        return <div className={classNames(style.Bubble, style.AfterRightBubble)} />
      }
    }

    return (
      <div
        className={style.MessageStyle}
        style={{
          paddingLeft: isLeft ? '65px' : '20px',
          justifyContent: isLeft ? 'flex-start' : 'flex-end',
        }}
      >
        {isLeft && props.isLastInGroup &&
          (context.pypeLogo ? (
            <img
              src={context.pypeLogo}
              className={style.ProfileIconStyle}
            />
          ) : (
            <div
              className={style.ProfileIconStyle}
              style={{ background: '#e5e5e5' }} />
          ))
        }
        <div
          className={style.Content}
          style={contentStyle}
        >
          {beforeBubble()}
          <div>{reprChildren(props.children)}</div>
          {afterBubble()}
        </div>
      </div>
    )
  }

}

Message.contextTypes = {
  design: PropTypes.object,
  pypeLogo: PropTypes.string,
}

Message.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  from: types.From,
  isLastInGroup: PropTypes.bool,
  style: PropTypes.object,
}

Message.defaultProps = {
  className: '',
  contentClassName: '',
  from: 'consumer',
  isLastInGroup: false,
  style: {},
}


export default  Message
