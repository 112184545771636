import React from 'react'
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import cx from 'classnames';
import assign from 'object-assign';
import _ from 'lodash';

// UI components
import SaveButton from 'components/ui/forms/SaveButton';
import LabelTextTags from 'components/ui/forms/LabelTextTags';
import LabelInput from 'components/ui/forms/LabelInput';
import TetheredSelect from 'components/ui/tethered-select/TetheredSelect';
import ConfirmModal from 'components/ui/modals/ConfirmModal';
import ErrorBoundary from 'components/ui/ErrorBoundary';

// Local imports
import SubHeader from '../../details/SubHeader';
import activityTracker from "../../../../utils/activityTracker";

const MESSAGE_MAX_LENGTH = 1000;
const NAME_MAX_LENGTH = 70;
const NAME_REGEX_PATTERN = /^[\d\w\s,@#$%&*!+ ()"'-]+$/i;
const MESSAGE_REGEX_PATTERN = /^[\d\w\s,@#$%&*!+ ()"'-{}áéíóúüñ¿¡]+$/i;

const DYNAMIC_TEXT_TAGS = ['Agent First Name', 'Customer First Name'];
   
const getCategories = (categories) => {
  return _.sortBy(categories, (category) => (category.text.toLowerCase()))
}
class CreateCannedResponse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      name: '',
      message: '',
      category: null,
      isSubmitting: false,
      isDeleteModalVisible: false,
      isSaveModalVisible: false,
      errors: {}
    };
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleMessageChange = this.handleMessageChange.bind(this);
    this.isNameValid = this.isNameValid.bind(this);
    this.isMessageValid = this.isMessageValid.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleDeleteModalDismiss = this.handleDeleteModalDismiss.bind(this);
    this.handleDeleteModalConfirm = this.handleDeleteModalConfirm.bind(this);
    this.handleSaveModalDismiss = this.handleSaveModalDismiss.bind(this);
    this.handleSaveModalConfirm = this.handleSaveModalConfirm.bind(this);
  }
  componentDidMount() {
    const { id, name, message, category, isEditingMode } = this.props;

    if (isEditingMode) {
      this.setState({
        id,
        name,
        message,
        category
      });
    } else if (!this.props.cannedResponses.length) {
      this.props.fetchCannedResponse();
    }
  }
  componentWillReceiveProps(nextProps) {
    const { id, name, nameError, isEditingMode } = nextProps;

    if (isEditingMode) {
      this.setState({
        id,
        name,
        errors: assign(this.state.errors, { name: nameError })
      });
      if (nextProps.editingCannedResponse !== this.props.editingCannedResponse) {
        this.setState({ errors: {} });
      }
    }
  }
  isNameValid = () => {
    let isValid = true;
    let error = '';
    const { name, errors } = this.state;

    if (_.some(this.props.cannedResponses, (resp) =>
        (this.props.id !== resp.id && resp.name === this.state.name)
      )) {
      isValid = false;
      error = 'Your quick reply name must be unique.';
    } else if (!name || !name.length) {
      isValid = false;
      error = 'Your quick reply must have a name';
    } else if (name.length > NAME_MAX_LENGTH) {
      isValid = false;
    }

    this.setState({
      errors: assign(errors, { name: error })
    });

    return isValid;
  }
  isMessageValid = () => {
    let isValid = true;
    let error = '';
    const { message, errors } = this.state;

    if (!message || !message.length) {
      isValid = false;
      error = 'Your quick reply must contain a message.';
    }

    this.setState({
      errors: assign(errors, { message: error })
    });

    return isValid;
  }
  handleNameChange = (evt) => {
    const name = evt.target.value;

    if (name === '' || (name.length <= NAME_MAX_LENGTH && NAME_REGEX_PATTERN.test(name))) {
      this.setState({ name });
    }
  }
  handleMessageChange = (message) => {
    if (!message.length || MESSAGE_REGEX_PATTERN.test(message)) {
      this.setState({ message });
    }
  }
  handleCancel = () => {
    window.history.back();
  }
  // Creation
  handleCreate = () => {
    if (!this.isFormValid()) return;

    this.setState({ isSubmitting: true });

    const { name, category, message } = this.state;
    const { customerName } = this.props
    const self = this;

    this.props.createCannedResponse({
      name,
      text: message,
      category
    }).then(()=>{
      // to get id for newly created canned response
      self.props.fetchCannedResponse();
      self.setState({ isSubmitting: false });
      window.appCreateToast({
        message: 'Quick reply created successfully.',
        level: 'success'
      });
      activityTracker.logEvent(activityTracker.eventTypeNames.UPDATE_PYPE_SETTING,{settingName: 'canned responses',action: activityTracker.eventActionType.CREATE, customerName});
      this.props.history.push('manage');
    }).catch((error)=>{
      self.setState({ isSubmitting: false });
      window.appCreateToast({
        message: 'Request could not be completed.',
        level: 'error'
      });
    });
  }
  // Delete
  handleDeleteModalDismiss = () => {
    this.setState({ isDeleteModalVisible: false });
  }
  handleDeleteModalConfirm = () => {
    this.setState({ isSubmitting: true });

    const { id, name, category, message } = this.state;
    const { customerName } = this.props;
    const self = this;

    this.props.deleteCannedResponse({
      id,
      name,
      text: message,
      category
    }).then(()=>{
      self.setState({ isSubmitting: false });
      window.appCreateToast({
        message: 'Quick reply deleted successfully.',
        level: 'success'
      });
      activityTracker.logEvent(activityTracker.eventTypeNames.UPDATE_PYPE_SETTING,{settingName: 'canned responses',action: activityTracker.eventActionType.DELETE, customerName});
      self.setState({ isDeleteModalVisible: false });
    }).catch((error)=>{
      self.setState({ isSubmitting: false });
      window.appCreateToast({
        message: 'Request could not be completed.',
        level: 'error'
      });
      self.setState({ isDeleteModalVisible: false });
    });
  }
  handleDelete = () => {
    this.setState({ isDeleteModalVisible: true });
  }
  // Update
  handleSave = () => {
    this.setState({ isSaveModalVisible: true });
  }
  handleSaveModalDismiss = () => {
    this.setState({ isSaveModalVisible: false });
    this.props.onDismiss();
  }
  handleSaveModalConfirm = () => {
    if (!this.isFormValid()) return;

    this.setState({ isSubmitting: true });

    const { id, name, category, message } = this.state;
    const { customerName } = this.props;
    const self = this;

    this.props.updateCannedResponse({
      id,
      name,
      text: message,
      category
    }).then(()=>{
      window.appCreateToast({
        message: 'Quick reply updated successfully. ',
        level: 'success'
      });
      activityTracker.logEvent(activityTracker.eventTypeNames.UPDATE_PYPE_SETTING,{settingName: 'canned responses',action: activityTracker.eventActionType.UPDATE, customerName});
    }).catch((error)=>{
      window.appCreateToast({
        message: 'Request could not be completed.',
        level: 'error'
      });
    }).finally(() => {
      self.setState({ isSubmitting: false });
      self.setState({ isSaveModalVisible: false });
      this.props.onDismiss();
    });
  }
  isFormValid = () => {
    return this.isNameValid() && this.isMessageValid();
  }
  getCategories = () => {
    const { categoriesStore } = this.props
    
    let categories = getCategories(categoriesStore).map((category)=>
      ({
        label: category.text,
        value: category.id
      })
    );

    return categories;
  }
  renderCategoryOption = (option, i) => {
    return (<div className="Select-checked">
      <span>{option.label}</span>
    </div>);
  }
  handleCategoryChange = (option) => {
    const category = option.value;

    this.setState({ category });
  }
  canSubmit = () => {
    const { state, props } = this;
    const { errors, name, message, category } = state;

    if (Object.keys(_.pickBy(errors, _.identity)).length) {
      return false;
    }
    if (!name || !message || !category) {
      return false;
    }
    if (props.isEditingMode && !(props.originalName !== name || props.message !== message || props.category !== category)) {
      return false;
    }

    return true;
  }
  render() {
    const { props, state } = this;

    const {
      name,
      message,
      category,
      errors,
      isSubmitting,
      isDeleteModalVisible,
      isSaveModalVisible
    } = state;

    const {
      isEditingMode,
      className
    } = props;

    return (
      <section className={className} style={{paddingTop: 0}}>
        <ErrorBoundary>
        {!isEditingMode ? <SubHeader>New quick reply</SubHeader> : null}
        <section>
          {!isEditingMode ?
          (<div className={cx('m-t-20', { 'has-error': errors.name })}>
            <LabelInput label="Name" type="text"
                value={name}
                className="full-width"
                placeholder="e.g. Greeting"
                onChange={this.handleNameChange}
                onBlur={this.isNameValid}
                error={errors.name}
                data-cy="quick-reply-name"
                />
          </div>) : null}
          <div className={cx('m-t-10', { 'has-error': errors.message })}>
            <LabelTextTags label="Message"
              value={message}
              maxLength={MESSAGE_MAX_LENGTH}
              tags={DYNAMIC_TEXT_TAGS}
              placeholder="e.g. How can I help you?"
              onChange={this.handleMessageChange}
              onBlur={this.isMessageValid}
              error={errors.message}
              breakTags={isEditingMode}
            />
          </div>
          <div className="m-t-20 m-b-5">
            <p className={'label-text pull-left no-margin'}>Category</p>
            <div className="clearfix"></div>
          </div>
          <TetheredSelect
            name='category'
            value={this.getCategories().filter(({value}) => category === value)}
            options={this.getCategories()}
            searchable={false}
            clearable={false}
            optionRenderer={this.renderCategoryOption}
            menuPortalTarget={document.body}
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            placeholder='Select a category'
            onChange={this.handleCategoryChange} />
        </section>
          {isEditingMode ?
            (<div className="text-center">
              <button className="btn btn-default m-r-5"
                onClick={this.handleDelete}>
                Delete
              </button>
              <SaveButton
                className="btn-primary"
                onClick={this.handleSave}
                disabled={!this.canSubmit()}
                isLoading={isSubmitting}>
                Save
              </SaveButton>
            </div>) :
            (<div className="text-center">
              <button className="btn btn-default m-r-5"
                onClick={this.handleCancel}>
                Cancel
              </button>
              <SaveButton
                className="btn-primary"
                onClick={this.handleCreate}
                disabled={!this.canSubmit()}
                isLoading={isSubmitting}>
                Create
              </SaveButton>
            </div>)}
            {isDeleteModalVisible ?
              <ConfirmModal
                title="Delete quick reply"
                message="Are you sure you want to delete this quick reply?"
                onClose={this.handleDeleteModalDismiss}
                onConfirm={this.handleDeleteModalConfirm}
                isConfirming={isSubmitting}
                isDestructive={true}
                dismissAction={'Cancel'}
                confirmAction={'Delete'}
              /> : null
            }
            {isSaveModalVisible ?
              <ConfirmModal
                title="Save changes"
                message="Do you want to save your changes? If you press cancel, all changes will be lost."
                onClose={this.handleSaveModalDismiss}
                onConfirm={this.handleSaveModalConfirm}
                isConfirming={isSubmitting}
                dismissAction={'Cancel'}
                confirmAction={'Save'}
              /> : null
            }
        </ErrorBoundary>
      </section>
    );
  }
}
CreateCannedResponse.defaultProps = {
  isEditingMode: false,
  name: null,
  nameError: null,
  originalName: null,
  message: null,
  category: null
};
CreateCannedResponse.propTypes = {
  id: PropTypes.string,
  isEditingMode: PropTypes.bool,
  name: PropTypes.string,
  nameError: PropTypes.string,
  originalName: PropTypes.string,
  message: PropTypes.string,
  category: PropTypes.string,
  editingCannedResponse: PropTypes.string,
  cannedResponses: PropTypes.array.isRequired,
  fetchCannedResponse: PropTypes.func.isRequired,
  createCannedResponse: PropTypes.func.isRequired,
  deleteCannedResponse: PropTypes.func.isRequired,
  updateCannedResponse: PropTypes.func.isRequired,
  onDismiss: PropTypes.func
};
export default  withRouter(CreateCannedResponse);
