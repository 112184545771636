import Constant from 'core/constant';
import sdk from 'sdk';
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux';

const {agentActions, utils} = sdk;


class Component extends React.Component {

  constructor(props) {
    super(props)

    this.inactiveTimeout = null;
    this.offHoursTimer = null;
  }

  startInactivityTimer(){
    const self = this;
    clearTimeout(this.inactiveTimeout);
      this.inactiveTimeout = setTimeout(()=>{
        if(self.props.agentHasSessions) {
          self.props.onClickStatus('offline')
        }
      }, Constant.AGENT_INACTIVE_TIMEOUT)
  }

  resetInactivityTimer(){
    if (!this.inactiveTimeout) {
      return;
    }
    clearTimeout(this.inactiveTimeout);
    this.inactiveTimeout = null;
  }

  startOffhourInterval(){
    if (this.offHoursTimer) {
      return;
    }

    const self = this;
    // start off-hours timer
    this.offHoursTimer = setInterval(() => {

      self.props.isSchedulingEnabled && self.props.checkOffHours()
    }, 1000);
  }

  stopOffHoursInterval() {
    if (!this.offHoursTimer) {
      return;
    }

    clearInterval(this.offHoursTimer)
    this.offHoursTimer = null
  }

  componentDidMount(){
    this._shouldShowToast = false;

    // show toast only if agent has been online > 5s.
    // Prevent showing when he logs in at off-hours
    setTimeout(() => {
      this._shouldShowToast = true
    },5000);


    this.startInactivityTimer();
    this.startOffhourInterval();
  }

  componentWillUnmount() {
    this.stopOffHoursInterval();
    this.resetInactivityTimer()
  }

  componentWillReceiveProps(nextProps){
    const {isOffHours, status, createdChat, isChatEndedByAgent, isMaxed, isAuthenticated, userId, agentHasSessions, agentChatSessionsFetching} = nextProps;
    const tooltip = this.refs.tooltip;

    const isNewMessageByAgent = createdChat.from === userId && this.props.createdChat.id != createdChat.id
    const isNewMessageByConsumer = createdChat.from !== userId && this.props.createdChat.id != createdChat.id

    if(isOffHours !== this.props.isOffHours) {

      if(isOffHours && status !== 'offline'){
        if(this._shouldShowToast){
           window.appCreateToast({
            message: '<strong>Your hours of operation have ended.</strong> Make sure to end your active chats before logging out.',
            level: 'info',
            autoDismiss: 0
          })
        }

        this.props.onClickStatus('away')
      } else if(status !== 'online') {
        window.appCreateToast({
            message: '<strong>Your hours of operations have started.</strong> Make sure to change your status back to online.',
            level: 'info',
            autoDismiss: 0
          })
        if(tooltip) tooltip.destroy();
      }
    }

    if(isNewMessageByAgent){
      this.resetInactivityTimer();
    }

    if(isNewMessageByConsumer || isChatEndedByAgent){
      this.startInactivityTimer();
    }

    if(!isAuthenticated &&
      isAuthenticated !== this.props.isAuthenticated) {
        this.stopOffHoursInterval();
    }
  }

  render() {
    const {props, state} = this

    return (
      <div></div>
    )
  }

}

Component.propTypes = {
  hasInitialFetched: PropTypes.bool.isRequired,
  status: PropTypes.oneOf(['online', 'busy', 'away', 'offline']),
  onClickStatus: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  const recentlyEndedChat = state.agentChatSessions.recentlyEndedChat;
  const userId = state.botUserSession.user.user_id
  return {
    isMaxed: utils.agent.isMaxed(state.agent),
    hasInitialFetched: state.agent.hasInitialFetched,
    status: utils.agent.getStatus(state.agent),
    isOffHours: state.agent.isOffHours,
    createdChat: state.conversationHistory.createdChat,
    agentHasSessions: state.agentChatSessions.chatSessions.length > 0,
    agentChatSessionsFetching: state.agentChatSessions.isFetching,
    isChatEndedByAgent: recentlyEndedChat && recentlyEndedChat.ended_by === userId,
    isAuthenticated: state.botUserSession.isAuthenticated,
    isSchedulingEnabled: state.pype.queues.schedule_enabled,
    userId,
    device_id: state.session.device_id
  }
}

const mapDispatchToProps = (dispatch) => ({
  onClickStatus(status) {
    return dispatch(agentActions.setStatus(status))
  },
  checkOffHours(){
    return dispatch(agentActions.checkOffHours())
  }
})

export default  connect(
  mapStateToProps,
  mapDispatchToProps
)(Component)
