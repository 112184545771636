import React from 'react';
import PropTypes from 'prop-types'

import Modal from 'react-modal';
import CloseButton from 'components/ui/modals/CloseButton';
import SaveButton from 'components/ui/forms/SaveButton';
import style from './style.css';
import Spinner from 'components/ui/pype-spinner/PypeSpinner';

const Component = (props) => {
  const {skill, isLoading} = props;

  // To avoid null error. skill will be null (inside deleteSkill)
  // during this component's lifecycle.
  if(!skill){
    return null;
  }
  const {active_bot_chat_count, active_agent_chat_count, active_queue_chat_count, name} = skill
  const chatsCount = active_bot_chat_count + active_agent_chat_count + active_queue_chat_count;
  const skillName = name;
  const message = chatsCount > 0 ? 
    <span>There {chatsCount>1?'are':'is'} {chatsCount} chat{chatsCount>1?'s':null} in this skill's queue.
      {chatsCount>1?'These':'This'} chat{chatsCount>1?'s':null} will be routed to the general queue. Are you sure you want to delete <strong>{skillName}</strong>?
    </span> 
    : <span>Are you sure you want to delete this skill?</span>

  return (
    <Modal ariaHideApp={false} isOpen={true} className="confirm-skill-delete-modal" onRequestClose={props.onClose}>
      <div className={`modal-content ${style.ModalContent}`}>
        <div className="modal-header">
          <CloseButton onClick={props.onClose} />
          <h4 className="modal-title text-center">Warning</h4>
        </div>
        <div className="modal-body p-20">
          {
            isLoading ? <Spinner caption={false}/> : <p>{message}</p>
          }
        </div>
        <div className={`modal-footer ${style.ModalFooter}`}>
          <div>
            <button className="btn btn-default m-r-20" onClick={props.onClose}>Cancel</button>
            <SaveButton className="btn-danger" isLoading={false} disabled={isLoading} onClick={props.onDeleteSkill}>
              Delete
            </SaveButton>
          </div>
        </div>
      </div>
    </Modal>
  )
}

Component.propType = {
  skill: PropTypes.object,
  onDeleteSkill:PropTypes.func.isRequired,
  onClose:PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
}

export default Component
