import _ from 'lodash'

import upload from '../TUSClient';
import { guid } from './../common/utils/common-utils';
import { updateStreamLastBroadcast } from './streams';
import {
  CREATE_CAMPAIGN_RES,
  REQUEST_CAMPAIGNS,
  RECEIVE_CAMPAIGNS,

  REQUEST_CAMPAIGN_STATS,
  RECEIVE_CAMPAIGN_STATS,

  RESET_CAMPAIGNS
} from "./../constants"

import SDK from '../SDK'

function requestCampaigns(moreToken) {
  return {
    type: REQUEST_CAMPAIGNS,
    moreToken
  }
}

const resetCampaigns = () => ({
  type:  RESET_CAMPAIGNS,
})

export function receiveCampaigns(req_id, json) {
  let metrics = json.metrics;
  let campaigns = json.campaigns;
  let more = json.more;
  campaigns.map(function(campaign){
    campaign.metrics = _.find(metrics, {campaign_id: campaign.id}).data;
  })
  return {
    req_id,
    type: RECEIVE_CAMPAIGNS,
    campaigns: campaigns,
    more,
    receivedAt: Date.now()
  }
}

export const fetchCampaigns = (reset=false) => (dispatch, getState) => {
  if (reset) {
    dispatch(resetCampaigns())
  }

  if (!getState().campaignsForPype.hasMore) {
    return Promise.resolve()
  }

  dispatch(requestCampaigns())

  const state = getState()

  return SDK.getCampaigns({
    pype_id: getState().pype.details.id,
    more: state.campaignsForPype.more,
    items_per_page: state.campaignsForPype.itemsPerPage,
  })

}


export function createdCampaign(createdCampaign) {
  return (dispatch) => {
    dispatch({
      type: CREATE_CAMPAIGN_RES,
      createdCampaign
    })
    dispatch(updateStreamLastBroadcast(createdCampaign));
  }
}

function requestCampaignStats() {
  return {
    type: REQUEST_CAMPAIGN_STATS
  }
}

function receiveCampaignStats(data) {
  return {
    type: RECEIVE_CAMPAIGN_STATS,
    data
  }
}

export const fetchCampaignStats = (id,reset) => (dispatch) =>{
  if(reset) dispatch(requestCampaignStats())
  
  SDK.getCampaignStats({
    id
  }).then((data) => {
    return dispatch(receiveCampaignStats(data))
  })
}
