import _ from 'lodash';
import { createSelector } from 'reselect'

const getChatSessions = state => state.agentChatSessions.chatSessions
const getUser = state => state.botUserSession.user

const getUnreadChatSessions = createSelector(
  getChatSessions,
  getUser,
  (chatSessions, _user) => {
    return _.filter(chatSessions, (session) => {
      if(session.status === 'ended') return false;
  
      var currentUser = _.find(session.user_read_counts, (user) => user.user_id === _user.user_id);
      if(!currentUser) return true;

      const minSeqNum = 1;
      const maxSeqNum = session.seq_num

      return session.consumer_msg_count != 0 && (currentUser.max != maxSeqNum || currentUser.min != minSeqNum);
    });
  }
)


const isEscalatedChat = (state, chatId) => _.find(state.agentChatSessions.escalated_chatSessions, c => c.id === chatId);

const isAgentChat = (state, chatId) => _.find(state.agentChatSessions.chatSessions, c => c.id === chatId);

export default {
  getUnreadChatSessions,
  isEscalatedChat,
  isAgentChat
}