import React from 'react'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'ui-elements';

// Local imports
import style from './style.css';

const EmptyState = () => (
  <div className={style.wrapper}>
    <div className={style.inner}>
      {/* <FontAwesomeIcon icon="bolt" size="3x" /> */}
      <h4 className="fs-17 m-t-0">Create a quick reply</h4>
      <p>Quick Replies are pre-defined replies that agents can use
      while chatting with customers to quickly respond
      to incoming messages</p>
      <Link className="m-t-20" to="create">
        <Button>Create a quick reply <i className="fa fa-plus"/>
        </Button>
      </Link>
    </div>
  </div>
);

export default  EmptyState;
