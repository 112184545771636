// External imports
import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FeatureUnavailableBanner from './FeatureUnavailableBanner';
import css from './style.css';

class PypeManagerFeature extends React.Component {
	render() {
		const { isPypeManagerEnabled, children, className, childrenClassName } = this.props;
		const style = !isPypeManagerEnabled ? css.disabled : null;
		return (
			<div className={`pm-feature-section ${className}`}>
				{!isPypeManagerEnabled ? <FeatureUnavailableBanner /> : null}
				<div className={`${css.wrapper} ${style} ${childrenClassName}`}>{children}</div>
			</div>)
	}

}


PypeManagerFeature.propTypes = {
	isPypeManagerEnabled: PropTypes.bool.isRequired
}

PypeManagerFeature.defaultProps = {
	isPypeManagerEnabled: true
}

// react-redux bindings
const mapStateToProps = (state) => {
	return {
		isPypeManagerEnabled: state.pype.details.agent_routing === 'pypestream'
	}
};


export default connect(
	mapStateToProps,
	null
)(PypeManagerFeature);