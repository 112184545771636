/*
  This is not tied to any specific top-level reducer (eg,
  conversations, agentChatSessions), but to any state that has an
  array history of messages.
*/

import _ from 'lodash'

const getFirstMessage = (history) => {
  if (_.isEmpty(history)) {
    return undefined
  }
  let current = null
  history.forEach(({chat_msg}) => {
    if (current === null || chat_msg.seq_num < current.seq_num) {
      current = chat_msg
    }
  })
  if (current.seq_num !== 1) {
    console.warn('Could not find a chat message where seq_num=1. Is the data valid?')
  }
  return current
}

const getRecentMessage = (history) => {
  if (_.isEmpty(history)) {
    return undefined
  }
  if (history.length === 1) {
    return _.head(history).chat_msg
  }
  return history.reduce((a, b) => (
    (a.chat_msg.seq_num > b.chat_msg.seq_num) ? a : b
  )).chat_msg
}

export default {
  getFirstMessage,
  getRecentMessage,
}
