import styled from 'styled-components';

const StyledList = styled.ul`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    list-style-type: none;
    padding-left: 0 !important;
    margin-top: 0;
    margin-bottom: 1rem;
    border-radius: 1px;
    font-family: 'Open Sans', sans-serif;
`;

const StyledListItem = styled.li`
    font-size: 16px;
    font-weight: 600;
    background-color: ${props => (props.selected && !props.isChild) || (props.isParent) ? '#f9f9f9' : 'transparent'};
    padding: 12px 12px 12px 10px;
    border-left: ${props => props.isParent || (props.selected && !props.isChild) ? '4px solid #0a3ab4' : '4px solid #ddd'};
    cursor: pointer;
    border-radius: 1px;
    // width: 248px;
    min-height: 48px;
    display: ${props => props.expanded ? 'block' : 'none'};
    &:hover {
        background-color: #f9f9f9;
    }
`;

export { StyledList, StyledListItem };