import _ from "lodash";
import * as Config from "./Config";
import { Stomp } from "../lib/stompjs";
import { store } from "./store";
import ResponseQueue from "./ResponseQueue";
import StompQueue from "./StompQueue";
import { StompLogger } from "../lib/stomp-logger";
import { guid } from "./common/utils/common-utils";
import { BOT_USER_SESSION_LOGOUT, doesUserHasRole,
  PYPE_AGENT_ROLES, PYPE_MANAGER_ROLES } from "bot-user-session";
import featureFlags from "utils/featureFlags";
import isReachable from "is-reachable";
import { x_list_30_day_chat_sessions } from "./constants";
import SDK from "./SDK";

import {
  streamActions,
  conversationsActions,
  conversationHistoryActions,
  campaignsActions,
  pypeActions,
  responseActions,
  agentsActions,
  agentActions,
  autoresponderActions,
  agentChatSessionsActions,
  tagActions,
  transfersActions,
  heartbeatActions,
} from "./actions";

const AMQP_EXCHANGE = "in.request.exch";
const AMQP_ROUTING_KEY = "in.request";
const RETRY_INTERVAL = 1000;
const messageHandlers = [];

let requestQueue = [];
let requestId = 0;
let prevReceivedStatus = "online";
let prevEndedNotClassifiedChatSessions = [];

let USER_ID, DEVICE_ID, REPLY_TO, PYPE_ID;
// these browsers don't fully support navigator.onLine, so we need to use a polling backup
// https://github.com/chrisbolin/react-detect-offline/blob/master/src/index.js#L7
const IS_UNSUPPORTED_USER_AGENTS_PATTERN =
  /Windows.*Chrome|Windows.*Firefox|Linux.*Chrome/.test(navigator.userAgent);

function onSubscribe(d) {
  // Fix to solve platform issue of sending "undefined" string in body
  var payload = JSON.parse(d.body.replace(/\"undefined\"/gi, "null"));

  messageHandlers.forEach((handler) => {
    handler(payload, store);
  });

  switch (payload.type) {
    case "ack":
      store.dispatch(responseActions.receivedAck(payload));
      break;
    case "response":
      switch (payload.detail) {
        case "list_streams":
          store.dispatch(
            streamActions.receiveStreams(payload.correlation_id, payload.data)
          );
          break;
        case "list_campaigns_for_pype":
          store.dispatch(
            campaignsActions.receiveCampaigns(
              payload.correlation_id,
              payload.data
            )
          );
          break;
        case "list_chat_sessions":
          store.dispatch(
            conversationsActions.receiveConversations(
              payload.correlation_id,
              payload.data
            )
          );
          break;
        case "list_chat_sessions_for_agent":
          store.dispatch(
            agentChatSessionsActions.receiveAgentChatSessions(
              payload.correlation_id,
              payload.data
            )
          );
          break;
        case "list_escalated_sessions_for_pype":
          if (featureFlags.isBeta()) {
            store.dispatch(
              agentChatSessionsActions.receiveEscalatedChatSessions(
                payload.correlation_id,
                payload.data
              )
            );
          }
          break;
        case 'init_sequence':
          store.dispatch(streamActions.receiveStreams(payload.correlation_id, payload.data.streams));

          const isAgent = doesUserHasRole(PYPE_AGENT_ROLES, PYPE_ID);
          const isPypeAdmin = doesUserHasRole(PYPE_MANAGER_ROLES, PYPE_ID);

          if (isAgent) {
            store.dispatch(agentChatSessionsActions.receiveAgentChatSessions(payload.correlation_id, payload.data.chat_sessions_for_agent));
          }
          
          if (isPypeAdmin) {
            store.dispatch(agentsActions.receiveAgents(payload.correlation_id, payload.data.agents));
          }
          break;
        case 'list_escalated_sessions_for_pype':
          if(featureFlags.isBeta()){
            store.dispatch(agentChatSessionsActions.receiveEscalatedChatSessions(payload.correlation_id, payload.data));
          }
          break;
        case "list_campaigns_for_stream":
          store.dispatch(
            streamActions.receiveStreamLastBroadcast(
              payload.correlation_id,
              payload.data
            )
          );
          break;
        case "list_agents":
          store.dispatch(
            agentsActions.receiveAgents(payload.correlation_id, payload.data)
          );
          break;
        case "list_autoresponders":
          store.dispatch(
            autoresponderActions.receiveAutoresponders(
              payload.correlation_id,
              payload.data
            )
          );
          break;
        default:
          break;
      }
      store.dispatch(responseActions.handleResponse(payload));
      break;

    case "notice":
      switch (payload.detail) {
        case "stream":
          store.dispatch(
            streamActions.createdOrUpdatedStream(
              payload.correlation_id,
              payload.data
            )
          );
          break;
        case "chat_msg":
          store.dispatch(
            conversationHistoryActions.createdChat(
              payload.correlation_id,
              payload.data
            )
          );
          break;
        case "chat":
          const { data, source_action } = payload;

          const { status, id, agent_is_assigned, participant_changed } = data;

          const newChatAction = async () => {
            store.dispatch(conversationHistoryActions.createdChatSession(data));

            // PYPE-4944: Bot Transferred session.
            await store.dispatch(
              agentChatSessionsActions.fetchTransferredSession(id, data)
            );

            // agent transferred chat
            if (
              participant_changed &&
              participant_changed === "agent_changed"
            ) {
              store.dispatch(transfersActions.updateTransferNotification(data));
            }
          };

          const updateChatAction = async () => {
            if (featureFlags.isBeta()) {
              const { events } = data;
              store.dispatch(agentChatSessionsActions.fetchEvents(events, id));
            }

            if (!agent_is_assigned || status === "ended") {
              // Chat was successfully ended
              if (participant_changed || participant_changed === null) {
                prevEndedNotClassifiedChatSessions.push(id);
                store.dispatch(
                  conversationsActions.endedNotClassifiedChatSessions(id)
                );
              } else {
                prevEndedNotClassifiedChatSessions =
                  prevEndedNotClassifiedChatSessions.filter(
                    (chatSession) => chatSession !== id
                  );
              }
              store.dispatch(
                agentChatSessionsActions.endedChatSession(id, data)
              );
            }

            // TODO: PYPE-7235. Currently no other better
            // way to handle this scenario until platform fix provided
            const { participant_history } = data;
            const recentParticipant = participant_history[0];

            const isConsumerReinitiatedChat =
              status === "active" &&
              recentParticipant.action === "added" &&
              recentParticipant.participant_type === "agent";

            if (isConsumerReinitiatedChat) {
              await store.dispatch(
                agentChatSessionsActions.fetchTransferredSession(id, data)
              );
            }
          };

          const resolve =
            source_action === "new"
              ? newChatAction
              : source_action === "update"
              ? updateChatAction
              : null;
          store.dispatch(
            agentChatSessionsActions.manageChatSessions(id, resolve)
          );

          break;
        case "read_receipt":
          store.dispatch(
            conversationHistoryActions.receiveReadMessages(
              payload.correlation_id,
              payload.data
            )
          );
          break;
        case "campaign":
          store.dispatch(campaignsActions.createdCampaign(payload.data));
          break;
        case "pype":
          store.dispatch(
            pypeActions.updatedPype(payload.correlation_id, payload.data)
          );
          store.dispatch(tagActions.savedConfig(payload.data));
          break;
        case "py_user": // if x_update is used for py_user
          if (payload.data.role === "agent") {
            store.dispatch(
              agentsActions.createdOrUpdatedAgent(
                payload.correlation_id,
                payload.data
              )
            );
          }
          break;
        case "create_agent":
        case "agent":
          store.dispatch(
            agentsActions.createdOrUpdatedAgent(
              payload.correlation_id,
              payload.data
            )
          );
          break;
        case "presence":
          if (
            store.getState().agent &&
            store.getState().agent.hasInitialFetched
          ) {
            store.dispatch(agentActions.receiveUpdatedStatus(payload.data));
          }
          break;
        case "public_agent_queue":
          store.dispatch(pypeActions.updatedAgentHours(payload.data));
          break;
        case "typing":
          store.dispatch(
            conversationHistoryActions.receivedConsumerTypingStatus(
              payload.data
            )
          );
          break;
        case "broadcast_sms_config":
          store.dispatch(
            pypeActions.receiveBroadcastConfiguration(payload.data)
          );
          break;
        case "heartbeat_reply":
          const updateChats = (data) => {
            if (
              store.getState().agentChatSessions &&
              store.getState().agentChatSessions.chatSessions &&
              data.current_sessions
            ) {
              const chatIdsToRemove = store
                .getState()
                .agentChatSessions.chatSessions.filter(
                  (chat) => !data.current_sessions.includes(chat.id)
                )
                .map((chat) => chat.id);
              chatIdsToRemove.forEach((id) => {
                if (!prevEndedNotClassifiedChatSessions.includes(id)) {
                  store.dispatch(
                    agentChatSessionsActions.removeChatSession(id)
                  );
                }
              });

              const currentChats = store
                .getState()
                .agentChatSessions.chatSessions.map((chat) => chat.id);

              data.current_sessions.forEach(async (id) => {
                if (!currentChats.includes(id)) {
                  const chat = await SDK.getConversationHistory({
                    chat_id: id,
                    from: "agent_id",
                  });
                  store.dispatch(
                    agentChatSessionsActions.receiveAgentChatSessions("", {
                      chat_sessions: [chat],
                    })
                  );
                }
              });
            }
          };
          let currentStatus = store.getState().agent.status;
          if (
            payload.data.status &&
            currentStatus !== payload.data.status &&
            prevReceivedStatus === payload.data.status
          ) {
            // Conflict b/w heartbeat status & current status
            store.dispatch(agentActions.receiveUpdatedStatus(payload.data));
          }

          updateChats(payload.data);
          prevReceivedStatus = payload.data.status;

          store.dispatch(heartbeatActions.receiveHeartbeat(payload.data));
          break;
        default:
          break;
      }

      store.dispatch(responseActions.handleResponse(payload));
      break;
    default:
      break;
  }
}

export const STOMPClient = {
  init({ pype_id, user_id, device_id }) {
    USER_ID = user_id;
    PYPE_ID = pype_id;
    DEVICE_ID = device_id;
    REPLY_TO = `${USER_ID}_${DEVICE_ID}`;

    this.client = null;
    this.reconnect = this.reconnect.bind(this);
    // wait until internet comes back online and reconnect
    window.addEventListener("online", this.reconnect);

    return this;
  },

  connect() {
    return new Promise((resolve, reject) => {
      const connection_url =
        window.Cypress || window.STOMP_TESTING
          ? "ws://localhost:8080"
          : Config.getSTOMPURL();

      this.client = Stomp.over(new WebSocket(connection_url));

      const logger = new StompLogger();
      this.client.debug = (...str) => {
        logger.log(...str);
      };
      const authToken = this.getAccessToken();

      this.client.connect(
        USER_ID,
        authToken,
        this._onConnect.bind(this, resolve),
        this._onError.bind(this, reject),
        "/"
      );
    });
  },

  disconnect() {
    if (this.client) {
      this.client.disconnect();
    }
    window.removeEventListener("online", this.reconnect);
  },

  reconnect() {
    if (this.client.connected) {
      console.warn("WebSocket already connected...");
      return;
    }
    this.connect();
  },

  _onConnect(callback) {
    let AMQPSub = Config.getAMQPSubscription(USER_ID);
    let AMQPACKSub = Config.getAMQPACKSubscription(REPLY_TO);

    let id = `${REPLY_TO}`;
    let ackid = `${REPLY_TO}_ack`;

    this.client.subscribe(AMQPSub, onSubscribe, {
      id: id,
      persistent: false,
      ack: "auto",
      "auto-delete": true,
      "x-queue-name": `stomp-user-${REPLY_TO}`,
    });
    this.client.subscribe(AMQPACKSub, onSubscribe, {
      id: ackid,
      persistent: false,
      ack: "auto",
      "auto-delete": true,
      "x-queue-name": `stomp-device-${REPLY_TO}`,
    });
    callback();
  },

  _onError(callback, error) {
    if (
      typeof error === "string" &&
      !error.indexOf(`Whoops! Lost connection to ${Config.getSTOMPURL()}`)
    ) {
      console.error("WebSocket connection lost!");

      if (!window.navigator.onLine) {
        console.error(
          "Network unavailable. Will try to reconnect once back online..."
        );
      } else {
        console.error("WebSocket reconnecting...");
        this.reconnect();
      }
    } else {
    }
    callback();
  },

  _request(name, action = "new", data, request) {
    let correlation_id = guid();
    let self = this;
    const authToken = this.getAccessToken();

    const _executeReq = async (client, _data) => {
      let payload = {
        user_id: USER_ID,
        correlation_id,
        reply_to: REPLY_TO,
        version: 1,
        type: "request",
        auth_token: authToken,
        request_type: name,
        request_action: action,
        data: _data,
      };

      /*
      On Firefox/Chrome for Windows WS requests get buffered when the 
      browser is offline and they are sent altogether once the connection
      is restored (ex: heartbeats). This leads to incorrect agent status
      Therefore avoid sending WS requests when offline
      */
      if (IS_UNSUPPORTED_USER_AGENTS_PATTERN) {
        const _isOnline = await isReachable("/");

        if (!_isOnline) {
          return;
        }
      }
      client.send(
        `/exchange/${AMQP_EXCHANGE}/${AMQP_ROUTING_KEY}`,
        { "content-type": "text/plain", durable: true },
        JSON.stringify(payload)
      );
    };

    if (this.client.connected && window.navigator.onLine) {
      if (request) {
        if (request.queue == "response") {
          var props = {
            name,
            action,
            data,
          };
          var options = { resolveOn: "ack" };
          requestId++;
          if (request.action) {
            correlation_id = request.action;
          }
          ResponseQueue.queue(correlation_id, request.resolve, request.reject, {
            props,
            options,
            requestId,
          });
        } else if (request.queue == "stomp") {
          // do nothing, it's already queued by request()
        }
      } else {
        var props = {
          name,
          action,
          data,
        };
        var options = {};
        requestId++;
        StompQueue.queue(
          correlation_id,
          () => {},
          () => {},
          { props, options, requestId }
        );
      }

      _executeReq(this.client, data);
    }
    return correlation_id;
  },

  request(props, options = {}) {
    /*
      Proposing this as the new standard to replace _request for
      forward development.

      Always returns a promise. The intention for this is to act very
      much like fetch() or jquery's $.ajax.

      Instead of writing adhoc functions like @getConversations or
      @getAgentChatSessions for every new endpoint, you should use
      STOMPClient.request directly within your redux actions.

      Notice also that it takes a hash argument instead of individual
      arguments.

      Example usage:

        STOMPClient.request({
            name: 'x_list_chats',
            data: {
              pype_id: pypeId
            }
          }).then((data) => (
            dispatch(receiveData(data))
          ))
    */

    if (_.isNil(props.name)) throw new Error("You must provide a @name");

    const defaultProps = {
      name: undefined, // The name of the request
      action: "new",
      data: {}, // The data to pass along to the request
    };
    props = {
      ...defaultProps,
      ...props,
    };

    let correlation_id = this._request(props.name, props.action, props.data, {
      queue: "stomp",
    });

    // TODO this is temp fix until platform changes are done
    // https://pypestream.atlassian.net/browse/PE-13962
    if (props.name === x_list_30_day_chat_sessions) {
      store.dispatch(
        agentChatSessionsActions.saveLastArchivedChatsCorrelationId(
          correlation_id
        )
      );
    }

    requestId++;

    if (options.action) {
      correlation_id = options.action;
    }

    if (options.disableQueue) {
      return;
    }
    if (options.resolveOn === "ack") {
      return new Promise((resolve, reject) => {
        ResponseQueue.queue(correlation_id, resolve, reject, {
          props,
          options,
          requestId,
        });
      });
    } else {
      return new Promise((resolve, reject) => {
        StompQueue.queue(correlation_id, resolve, reject, {
          props,
          options,
          requestId,
        });
      });
    }
  },

  getAccessToken() {
    return sessionStorage.getItem("global_accessToken");
  },
};

// Used to bind handlers for responses that don't have correlation ids
// in the response notices.
export let onMessage = (handler) => messageHandlers.push(handler);
export let offMessage = (handler) => _.pull(messageHandlers, handler);

store.subscribe(function () {
  const { lastAction } = store.getState();
  if (lastAction.type === BOT_USER_SESSION_LOGOUT) {
    STOMPClient.disconnect();
  }
});
