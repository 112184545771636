import style from './style.css';
import React from 'react'

import PropTypes from 'prop-types'

const hexToRGBA = (hex, opacity) => {
  let c;
  if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if(c.length== 3){
      c= [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = `0x${c.join('')}`;
    return `rgba(${[(c>>16)&255, (c>>8)&255, c&255].join(',')},${opacity})`;
  }
  throw new Error('Bad Hex');
}

class SmartButton extends React.Component {

  render () {
    const {props, context: { design: { userBubbleColor } }} = this
    let defaultButtonColor = '#fff';

    try {
      defaultButtonColor = hexToRGBA(userBubbleColor, 0.07)
    } catch(e) {
      // ignoring error handler
    }

    return (
      <button
        className={style.ButtonStyle}
        style={{
          background: defaultButtonColor,
          color: userBubbleColor,
        }}
      >
        {props.children}
      </button>
    )
  }

}

SmartButton.contextTypes = {
  design: PropTypes.object,
}

SmartButton.propTypes = {
  children: PropTypes.node,
}


export default  SmartButton
