import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Component from './Component';
import sdk from 'sdk'

const  {cannedResponsesActions}  = sdk;

const mapStateToProps = (state) => ({
  cannedResponses: state.cannedResponses.cannedResponses,
  categoriesStore: state.cannedResponses.categories,
  customerName: state.pype.details.customerName
});

const mapDispatchToProps = (dispatch) => {
  const actionCreators = bindActionCreators({
    fetchCannedResponse: cannedResponsesActions.fetchCannedResponses,
    createCannedResponse: cannedResponsesActions.createCannedResponse,
    updateCannedResponse: cannedResponsesActions.updateCannedResponse,
    deleteCannedResponse: cannedResponsesActions.deleteCannedResponse
  }, dispatch);

  return actionCreators;
};

export default  connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);