import {connect} from 'react-redux'
import Component from './Component'
import sdk from 'sdk';
const {agentsActions, pypeActions} = sdk;


const mapStateToProps = (state) => {
  

  return {
    pypeName: state.pype.details.name,
    hasAgents: !!state.agentsForPype.agents.length,
    routingSoftware: state.pype.details.agent_routing,
    waitTime: parseInt(state.pype.details.reroute_limit),
    isPypeUpdating: state.pype.isUpdating,
    isPypeDataLoading: state.pype.isFetching,
    customerName: state.pype.details.customerName
  }
};


const mapDispatchToProps = (dispatch) => ({
  fetchAgents () {
    return dispatch(agentsActions.fetchAgents())
  },
  updatePype (data) {
    return dispatch(pypeActions.updatePype(data))
  }

})

export default  connect(
  mapStateToProps,
  mapDispatchToProps
)(Component)
