import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux';
import cx from 'classnames';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import sdk from 'sdk';

const {conversationsActions, utils} = sdk;

export const EndChatPopover = (props) => (
    <div className="endchat-popover">
      <div className="arrow"></div>
      <div className="endchat-body">
        <p className="endchat-consumer-name">Would you like to end this chat with {props.name ? <strong>{props.name}</strong> : 'this user'}?</p>
      </div>
      <div className="endchat-footer">
        <button className="btn btn-default m-r-5" onClick={props.closePopover}>Cancel</button>
        <button className="btn btn-primary m-l-5" onClick={props.onSubmit}>End chat</button>
      </div>
    </div>
)

EndChatPopover.propTypes = {
  chat_id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  closePopover: PropTypes.func.isRequired,
}

const mapDispatchToProps = (dispatch, props) => ({

  onSubmit() {
    const onSuccess = () => {
      let curr_state
      dispatch((d, getState) => (curr_state = getState()))
      if (utils.pype.hasPostChatAction(curr_state)) {
        dispatch(conversationsActions.recentlyEndedChatForClassification(props.chat_id))
      }
      window.appCreateToast({
        message: `<span>Chat with <strong>${props.name}</strong> has successfully ended.</span>`,
        level: 'success',
      })
      props.onSubmit();
    }

    const onError = () => {
      window.appCreateToast({
        // <todo> Review this text
        message: `<span>Chat with <strong>${props.name}</strong> could not be ended.</span>`,
        level: 'error',
      })
    }

    const action = conversationsActions.endConversation(props.chat_id)
    return dispatch(action).then(onSuccess, onError).then(() => {
      let state
      dispatch((d, getState) => (state = getState()))
      // Platform requires client to always send a classify chat
      // request even when comments and tags are disabled.
      if (!utils.pype.hasPostChatAction(state)) {
        conversationsActions.classifyChat({
          chat_id: props.chat_id,
          user_cancel: true,
        })
      }
    })
  }

})

export default  connect(
  null,
  mapDispatchToProps
)(withLDConsumer()(EndChatPopover))
