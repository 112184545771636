import React from "react";
import {components} from 'react-select';

const DropdownIndicator = props => (
  components.DropdownIndicator && (
    <components.DropdownIndicator {...props}>
    </components.DropdownIndicator>
  )
);

export default DropdownIndicator
