export const REQUEST_STREAMS = 'REQUEST_STREAMS'
export const RECEIVE_STREAMS = 'RECEIVE_STREAMS'
export const REQUEST_STREAM_LAST_BROADCAST = 'REQUEST_STREAM_LAST_BROADCAST'
export const RECEIVE_STREAM_LAST_BROADCAST = 'RECEIVE_STREAM_LAST_BROADCAST'
export const UPDATE_STREAM_LAST_BROADCAST = 'UPDATE_STREAM_LAST_BROADCAST'

export const REQUEST_CONVERSATIONS = 'REQUEST_CONVERSATIONS'
export const RECEIVE_CONVERSATIONS = 'RECEIVE_CONVERSATIONS'
export const REQUEST_CONVERSATION_HISTORY = 'REQUEST_CONVERSATION_HISTORY'
export const RECEIVE_CONVERSATION_HISTORY = 'RECEIVE_CONVERSATION_HISTORY'
export const FETCH_CONVERSATION_HISTORY_ERROR = 'FETCH_CONVERSATION_HISTORY_ERROR'
export const REQUEST_CONVERSATION_MIGRATION_STATS = 'REQUEST_CONVERSATION_MIGRATION_STATS'
export const RECEIVE_CONVERSATION_MIGRATION_STATS = 'RECEIVE_CONVERSATION_MIGRATION_STATS'
export const END_CONVERSATION = 'END_CONVERSATION'
export const RESET_AGENT_CHAT_SESSIONS = 'RESET_AGENT_CHAT_SESSIONS'
export const RESET_ARCHIVED_CHAT_SESSIONS = 'RESET_ARCHIVED_CHAT_SESSIONS'
export const RESET_ESCALATED_CHAT_SESSIONS = 'RESET_ESCALATED_CHAT_SESSIONS'
export const REQUEST_AGENT_CHAT_SESSIONS = 'REQUEST_AGENT_CHAT_SESSIONS'
export const RECEIVE_AGENT_CHAT_SESSIONS = 'RECEIVE_AGENT_CHAT_SESSIONS'
export const REQUEST_ARCHIVED_CHAT_SESSIONS = 'REQUEST_ARCHIVED_CHAT_SESSIONS'
export const REQUEST_ESCALATED_CHAT_SESSIONS = 'REQUEST_ESCALATED_CHAT_SESSIONS'
export const RECEIVE_ARCHIVED_CHAT_SESSIONS = 'RECEIVE_ARCHIVED_CHAT_SESSIONS'
export const RECEIVE_ESCALATED_CHAT_SESSIONS = 'RECEIVE_ESCALATED_CHAT_SESSIONS'
export const END_AGENT_CHAT_SESSION = 'END_AGENT_CHAT_SESSION'
export const ENDED_AGENT_CHAT_SESSION = 'ENDED_AGENT_CHAT_SESSION'
export const ADD_AGENT_CHAT_SESSION = 'ADD_AGENT_CHAT_SESSION'
export const CREATE_CHAT = 'CREATE_CHAT'
export const CREATE_CHAT_RES = 'CREATE_CHAT_RES'
export const CREATED_CHAT_SESSION = 'CREATED_CHAT_SESSION'
export const APPEND_MESSAGE_TO_CONVERSATION = 'APPEND_MESSAGE_TO_CONVERSATION';
export const SORT_CONVERSATIONS = 'SORT_CONVERSATIONS'
export const CLASSIFY_CHAT = 'CLASSIFY_CHAT'
export const REQUEST_CONSUMER_DETAILS = 'REQUEST_CONSUMER_DETAILS'
export const RECEIVE_CONSUMER_DETAILS = 'RECEIVE_CONSUMER_DETAILS'
export const UPDATE_READ_COUNT = 'UPDATE_READ_COUNT'
export const CHANGE_HISTORY_FILTERS_PANEL_VIEW = 'CHANGE_HISTORY_FILTERS_PANEL_VIEW'
export const SET_SELECTED_HISTORY_FILTERS = 'SET_SELECTED_HISTORY_FILTERS'
export const RECENTLY_ENDED_CHAT_FOR_CLASSIFICATION = 'RECENTLY_ENDED_CHAT_FOR_CLASSIFICATION'
export const ENDED_NOT_CLASSIFIED_CHAT_SESSION = 'ENDED_NOT_CLASSIFIED_CHAT_SESSION'
export const ENDED_AND_CLASSIFIED_CHAT_SESSION = 'ENDED_AND_CLASSIFIED_CHAT_SESSION'
export const REMOVE_ENDED_NOT_CLASSIFIED_CHAT_SESSION = 'REMOVE_ENDED_NOT_CLASSIFIED_CHAT_SESSION'
export const SET_MESSAGE_TIMER = 'SET_MESSAGE_TIMER'
export const LAST_READ_MESSAGE = 'LAST_READ_MESSAGE'
export const SET_CLOSE_TO_CHAT_END_ID = 'SET_CLOSE_TO_CHAT_END_ID'

export const RECEIVE_MESSAGE = 'RECEIVE_MESSAGE'
export const RECEIVE_CHAT = 'RECEIVE_CHAT'

// Chat sessions with a consumer across all agents
export const RESET_CHAT_SESSIONS_WITH_CONSUMER = 'RESET_CHAT_SESSIONS_WITH_CONSUMER'
export const REQUEST_CHAT_SESSIONS_WITH_CONSUMER = 'REQUEST_CHAT_SESSIONS_WITH_CONSUMER'
export const RECEIVE_CHAT_SESSIONS_WITH_CONSUMER = 'RECEIVE_CHAT_SESSIONS_WITH_CONSUMER'

export const SEND_MSG_READ_RECEIPT = 'SEND_MSG_READ_RECEIPT'
export const SEND_MSG_READ_RECEIPT_RES = 'SEND_MSG_READ_RECEIPT_RES'


export const CREATE_STREAM = 'CREATE_STREAM'
export const CREATED_STREAM = 'CREATED_STREAM'
export const EDIT_STREAM = 'EDIT_STREAM'
export const UPDATE_STREAM = 'UPDATE_STREAM'


export const REQUEST_CAMPAIGNS = 'REQUEST_CAMPAIGNS'
export const RECEIVE_CAMPAIGNS = 'RECEIVE_CAMPAIGNS'
export const CREATE_CAMPAIGN_RES = 'CREATE_CAMPAIGN_RES'
export const RESET_CAMPAIGNS = 'RESET_CAMPAIGNS'

export const REQUEST_CAMPAIGN_STATS = 'REQUEST_CAMPAIGN_STATS'
export const RECEIVE_CAMPAIGN_STATS = 'RECEIVE_CAMPAIGN_STATS'

// pype
export const UPDATE_PYPE = 'UPDATE_PYPE'
export const UPDATED_PYPE = 'UPDATED_PYPE'
export const REMOVE_UNREAD_MSG = 'REMOVE_UNREAD_MSG'
export const ADD_UNREAD_MSG = 'ADD_UNREAD_MSG'
export const UPDATE_UNREAD_COUNT = 'UPDATE_UNREAD_COUNT'
export const UPDATE_AGENT_HOURS = 'UPDATE_AGENT_HOURS';
export const UPDATED_AGENT_HOURS = 'UPDATED_AGENT_HOURS';
export const REQUEST_BROADCAST_CONFIG = 'REQUEST_BROADCAST_CONFIG';
export const RECEIVE_BROADCAST_CONFIG = 'RECEIVE_BROADCAST_CONFIG';
export const REQUEST_PATTERN_RULES = 'REQUEST_PATTERN_RULES';
export const RECEIVE_PATTERN_RULES = 'RECEIVE_PATTERN_RULES';
export const REQUEST_PYPE_METRICS = 'REQUEST_PYPE_METRICS';
export const RECEIVE_PYPE_METRICS = 'RECEIVE_PYPE_METRICS';
export const SET_PYPE = 'SET_PYPE';
export const RECEIVE_CUSTOMER = 'RECEIVE_CUSTOMER';

export const REQUEST_PYPES = 'REQUEST_PYPES';
export const RECEIVE_PYPES = 'RECEIVE_PYPES';
export const SET_ACTIVE_PYPE = 'SET_ACTIVE_PYPE';


// user
export const REQUEST_USER_META = 'REQUEST_USER_META'
export const RECEIVE_USER_META = 'RECEIVE_USER_META'

export const FILE_UPLOAD_STARTED = 'FILE_UPLOAD_STARTED';
export const FILE_UPLOAD_SUCCEEDED = 'FILE_UPLOAD_SUCCEEDED';
export const FILE_UPLOAD_FAILED = 'FILE_UPLOAD_FAILED';

export const RECEIVED_ACK = 'RECEIVED_ACK';
export const RECEIVED_RESPONSE = 'RECEIVED_RESPONSE';

export const TOGGLE_USERINFO_PANE = 'TOGGLE_USERINFO_PANE';

export const CONVERSATIONS_FILE_UPLOAD_STARTED = 'CONVERSATIONS_FILE_UPLOAD_STARTED';
export const CONVERSATIONS_FILE_UPLOAD_SUCCEEDED = 'CONVERSATIONS_FILE_UPLOAD_SUCCEEDED';
export const CONVERSATIONS_FILE_UPLOAD_FAILED = 'CONVERSATIONS_FILE_UPLOAD_FAILED';
export const CONVERSATIONS_FILE_UPLOAD_PROGRESS_CHANGED = 'CONVERSATIONS_FILE_UPLOAD_PROGRESS_CHANGED';
export const CONVERSATIONS_FILE_REMOVED = 'CONVERSATIONS_FILE_REMOVED';

export const MIXED_MESSAGE_SEND_STARTED = 'MIXED_MESSAGE_SEND_STARTED';
export const MIXED_MESSAGE_FILE_UPLOAD_PROGRESS_CHANGED = 'MIXED_MESSAGE_FILE_UPLOAD_PROGRESS_CHANGED';
export const MIXED_MESSAGE_FILE_UPLOAD_SUCCEEDED = 'MIXED_MESSAGE_FILE_UPLOAD_SUCCEEDED';
export const MIXED_MESSAGE_FILE_UPLOAD_FAILED = 'MIXED_MESSAGE_FILE_UPLOAD_FAILED';
export const MIXED_MESSAGE_TEXT_SENDING_STARTED = 'MIXED_MESSAGE_TEXT_SENDING_STARTED';
export const MIXED_MESSAGE_TEXT_SENDING_SUCCEDED = 'MIXED_MESSAGE_TEXT_SENDING_SUCCEDED';
export const MIXED_MESSAGE_TEXT_SENDING_FAILED = 'MIXED_MESSAGE_TEXT_SENDING_FAILED';
export const MIXED_MESSAGE_TEXT_SENDING_TIMEOUT = 'MIXED_MESSAGE_TEXT_SENDING_TIMEOUT';

export const AGENT_TYPING_STATUS_CHANGED = 'AGENT_TYPING_STATUS_CHANGED';
export const CONSUMER_TYPING_STATUS_CHANGED = 'CONSUMER_TYPING_STATUS_CHANGED';
export const CONSUMER_TYPING_STATUS_UPDATE = 'CONSUMER_TYPING_STATUS_UPDATE';

export const REQUEST_SKILLS = 'REQUEST_SKILLS';
export const RECEIVE_SKILLS = 'RECEIVE_SKILLS';

export const UPDATE_EVENTS = 'UPDATE_EVENTS';

export const LAST_RECIPIENT_UPDATED = 'LAST_RECIPIENT_UPDATED';

export const REQUEST_AGENTS = 'REQUEST_AGENTS';
export const RECEIVE_AGENTS_ACK = 'RECEIVE_AGENTS_ACK';
export const RECEIVE_AGENTS = 'RECEIVE_AGENTS';
export const REMOVE_AGENT = 'REMOVE_AGENT';
export const REMOVED_AGENT = 'REMOVED_AGENT';
export const CREATE_AGENT = 'CREATE_AGENT';
export const CREATED_AGENT = 'CREATED_AGENT';
export const UPDATE_AGENT = 'UPDATE_AGENT';
export const UPDATED_AGENT = 'UPDATED_AGENT';
export const UPDATE_AGENT_STATUS = 'UPDATE_AGENT_STATUS';
export const CACHE_CONSUMER_DATA = 'CACHE_CONSUMER_DATA';
export const REMOVE_CHAT_SESSION = 'REMOVE_CHAT_SESSION';

export const REQUEST_BOT_STATS = 'REQUEST_BOT_STATS';
export const RECEIVE_BOT_STATS = 'RECEIVE_BOT_STATS';

export const REQUEST_AUTORESPONDERS = 'REQUEST_AUTORESPONDERS';
export const RECEIVE_AUTORESPONDERS = 'RECEIVE_AUTORESPONDERS';
export const UPDATE_AUTORESPONDERS = 'UPDATE_AUTORESPONDERS';
export const UPDATED_AUTORESPONDERS = 'UPDATED_AUTORESPONDERS';

// Tags
export const tagConstants = {
  REQUEST_TAGS: 'tags/REQUEST',
  RECEIVE_TAGS: 'tags/RECEIVE',
  CREATE_TAG: 'tags/CREATE',
  DESTROY_TAG: 'tags/DESTROY',
  UPDATE_TAG: 'tags/UPDATE',
  SAVE_CONFIG: 'tags/SAVE_CONFIG',
  SAVED_CONFIG: 'tags/SAVED_CONFIG',
  SAVED_CATEGORY: 'tags/SAVED_CATEGORY',
}


// Agent
export const agentConstants = {
  REQUEST: 'agent/REQUEST',
  RECEIVE: 'agent/RECEIVE',
  INC_CHAT_COUNT: 'agent/INC_CHAT_COUNT',
  DEC_CHAT_COUNT: 'agent/DEC_CHAT_COUNT',
  SET_STATUS: 'agent/SET_STATUS',
  ENABLE_OFF_HOURS: 'agent/ENABLE_OFF_HOURS',
  DISABLE_OFF_HOURS: 'agent/DISABLE_OFF_HOURS',
  RESET_CHAT_COUNT: 'agent/RESET_CHAT_COUNT',
  SET_BROWSER_STATUS: 'agent/SET_BROWSER_STATUS',
}

// Webchat widget
export const widgetsConstants = {
  REQUEST: 'widgets/REQUEST',
  RECEIVE: 'widgets/RECEIVE',
  CREATE_WIDGET: 'widgets/CREATE_WIDGET',
  UPDATE_WIDGET: 'widgets/UPDATE_WIDGET',
  REMOVE_WIDGET: 'widgets/REMOVE_WIDGET',
}

// Event based api
export const apiConstants = {
  REQUEST: 'apis/REQUEST',
  RECEIVE: 'apis/RECEIVE',
  CREATE_API: 'apis/CREATE_API',
  UPDATE_API: 'apis/UPDATE_API',
  REMOVE_API: 'apis/REMOVE_API',
}

//Transfers
export const transfersConstants = {
  UPDATE_NOTIFICATION: 'transfers/UPDATE_NOTIFICATION',
}


//Skills
export const skillsConstants = {
  REQUEST_SKILLS: 'skills/REQUEST',
  RECEIVE_SKILLS: 'skills/RECEIVE',
  REQUEST_SKILL: 'skill/REQUEST',
  RECEIVE_SKILL: 'skill/RECEIVE',
  DELETE_SKILL: 'skill/DELETE',
  DELETED_SKILL: 'skill/DELETED',
  UPDATE_SKILL: 'skill/UPDATE',
  UPDATED_SKILL: 'skill/UPDATED',
  CREATE_SKILL: 'skill/CREATE',
  CREATED_SKILL: 'skill/CREATED',
  SELECTED_SKILL: 'SELECTED_SKILL'
}

// Canned Responses
export const cannedResponsesConstants = {
  REQUEST_CANNED_RESPONSES: 'cannedResponses/REQUEST_CANNED_RESPONSES',
  RECEIVE_CANNED_RESPONSES: 'cannedResponses/RECEIVE_CANNED_RESPONSES',
  DELETE_CANNED_RESPONSE: 'cannedResponses/DELETE_CANNED_RESPONSE',
  DELETED_CANNED_RESPONSE: 'cannedResponses/DELETED_CANNED_RESPONSE',
  UPDATE_CANNED_RESPONSE: 'cannedResponses/UPDATE_CANNED_RESPONSE',
  UPDATED_CANNED_RESPONSE: 'cannedResponses/UPDATED_CANNED_RESPONSE',
  CREATE_CANNED_RESPONSE: 'cannedResponses/CREATE_CANNED_RESPONSE',
  CREATED_CANNED_RESPONSE: 'cannedResponses/CREATED_CANNED_RESPONSE',
  SET_CANNED_RESPONSE_DRAWER: 'cannnedResponses/SET_CANNED_RESPONSE_DRAWER',
  GET_CANNED_RESPONSE_DRAWER: 'cannnedResponses/GET_CANNED_RESPONSE_DRAWER',
}

export const initSeqConsts = {
  FETCHING: 'init_seq_fetching',
  FETCHED: 'init_seq_fetched'
}

export const GET_CONSUMER_DETAILS = 'GET_CONSUMER_DETAILS'

export const sessionConstants = {
  UPDATE_SESSION: 'session/UPDATE_SESSION',
}

export const heartbeatConstants = {
  HEARTBEAT_SENT: 'heartbeats/HEARTBEAT_SENT',
  HEARTBEAT_RECEIVED: 'heartbeats/HEARTBEAT_RECEIVED',
}

export const x_list_30_day_chat_sessions = 'x_list_30_day_chat_sessions'
export const SET_LATEST_ARCHIVED_CHATS_REQ_ID = 'SET_LATEST_ARCHIVED_CHATS_REQ_ID'
export const UPDATE_ARCHIVED_CHATS_SEARCHING = 'UPDATE_ARCHIVED_CHATS_SEARCHING'
export const UPDATE_CHAT_SESSION_ACTION_LIST = 'UPDATE_CHAT_SESSION_ACTION_LIST'