import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import _ from 'lodash'
import Component from './Component'

// Pypestream
import sdk from 'sdk';
const {agentsActions, conversationHistoryActions,agentChatSessionsActions} = sdk;

const mapStateToProps = (state) => {
  return {
    agentsStore: state.agentsForPype.agents,
    skillsStore: state.conversationHistory.skills,
    userId: state.botUserSession.user.user_id,
    pype: state.pype.details,
    agentsForSkill: state.conversationHistory.agentsForSkill,
    isFetchingAgents: state.agentsForPype.isFetching,
    isFetchingSkills: state.conversationHistory.isFetchingSkills,
  }

}

const mapDispatchToProps = (dispatch, props) => (

  bindActionCreators({
    fetchAgents: agentsActions.fetchAgents,
    transferChat: conversationHistoryActions.transferChat,
    fetchSkills: conversationHistoryActions.fetchSkills,
    clearAgentChatSession: agentChatSessionsActions.removeChatSession
  }, dispatch)

)

export default  connect(
  mapStateToProps,
  mapDispatchToProps
)(Component)
