import React from 'react'
import {Link} from 'react-router-dom';
import cx from 'classnames';

import css from './style.css';

const FeatureUnavailableBanner = (props) => (
  <div className={cx("text-center", css.bannerWrapper)}>
    <h3 className="no-margin">Features below not available</h3>
    <div className="fs-17 m-t-20">
    	<span>This feature is only available if you choose Pype Manager for routing.</span>
    	<br/>
    	<Link to="chat-routing">Manage your routing options here</Link>
    </div>
  </div>
)

export default  FeatureUnavailableBanner
