import { BOT_USER_SESSION_LOGOUT } from "bot-user-session";

import {
  UPDATED_PYPE,
  UPDATE_PYPE,
  UPDATE_UNREAD_COUNT,
  UPDATED_AGENT_HOURS,
  REQUEST_BROADCAST_CONFIG,
  RECEIVE_BROADCAST_CONFIG,
  REQUEST_PATTERN_RULES,
  RECEIVE_PATTERN_RULES,
  REQUEST_PYPE_METRICS,
  RECEIVE_PYPE_METRICS,
  SET_PYPE,
  RECEIVE_CUSTOMER
} from "../constants"

const getInitialState = () => ({
  hasFetched: false,
  isFetching: false,
  didInvalidate: false,
  details: {},
  metrics: {},
  queues: {},
  unreadConversationsCount:0,
  isUpdating: false,
  isFetchingCRMData: false,
  isFetchingBroadcastConfig: false,
  isFetchingRulePattern: false,
  isFetchingMetrics: false
});

export default function pype(state = getInitialState(), action) {
  switch (action.type) {
    case BOT_USER_SESSION_LOGOUT: {
      return getInitialState();
    }
    case SET_PYPE: {
      return {
        ...state,
        details: action.pype,
        hasFetched: true
      }
    }
    case UPDATE_PYPE:
      return Object.assign({}, state, {
        isUpdating: true
      })
    case UPDATED_PYPE:
      return Object.assign({}, state, {
        details: {...state.details, ...action.pype},
        isUpdating: false,
        tagCategories: !action.pype.tags ? [] : action.pype.tags,
      });
    case UPDATE_UNREAD_COUNT:
      return Object.assign({}, state, {
        unreadConversationsCount: action.unreadConversationsCount
      });
    case UPDATED_AGENT_HOURS:
      return {
        ...state,
        queues: {
          ...state.queues,
          ...action.agentHours
        }
      }
    case REQUEST_BROADCAST_CONFIG:
      return Object.assign({}, state, {
        isFetchingBroadcastConfig: true
      });
    case RECEIVE_BROADCAST_CONFIG:
      return {
        ...state,
        isFetchingBroadcastConfig: false,
        details: {
          ...state.details,
          ...action.data
        }
      }
      case REQUEST_PATTERN_RULES:
        return Object.assign({}, state, {
          isFetchingRulePattern: true
        });
      case RECEIVE_PATTERN_RULES:
        return {
          ...state,
          isFetchingRulePattern: false,
          details: {
            ...state.details,
            ...action.data
          }
        }
      case REQUEST_PYPE_METRICS:
        return {
          ...state,
          isFetchingMetrics: true
        }
      case RECEIVE_PYPE_METRICS:
        return {
          ...state,
          metrics: action.data.metrics,
          queues: action.data.queues[0],
          isFetchingMetrics: false
        }
      case RECEIVE_CUSTOMER: 
        return {
          ...state,
          details: {
            ...state.details,
            customerName: action.data.name
          },
        }
    default:
      return state
  }
}
