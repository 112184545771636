
import * as Config from './Config';
import querystring from 'querystring';
// TODO test this
// require('es6-promise').polyfill();

import superagentPromisePlugin from 'superagent-promise-plugin';
var request = superagentPromisePlugin.patch(require('superagent'));

const getToken = () => (sessionStorage.getItem('global_accessToken'))

const CONTENT_JSON = 'json'
const CONTENT_X_WWW_FORM_URLENCODED = 'x-www-form-urlencoded'
const RESTClient = {
  _request(method = 'GET', url, payload, options = {}) {
    const contentType = options.contentType || CONTENT_JSON
    const authTokenAttachMethod = options.authTokenAttachMethod || 'header'

    const headers = {
      'Accept': 'application/json',
      'Content-Type': `application/${contentType}`
    }
    if (authTokenAttachMethod === 'header') {
      headers['Authorization'] = `Bearer ${getToken()}`
    } else if (authTokenAttachMethod === 'noauth') {
      // do nothing
    } else {
      payload.authorization = getToken()
    }

    if (method === 'POST') {
      if (contentType == CONTENT_X_WWW_FORM_URLENCODED) {
        payload = querystring.stringify(payload)
      }
      return request.post(url)
        .send(payload)
        .set(headers)

    } else {
      let urlParams = querystring.stringify(payload);
      let urlFull = urlParams ? `${url}?${urlParams}` : url;
      return request.get(urlFull)
        .set(headers)
    }

  },

  _requestEventBased(method = 'GET', url, payload, contentType = CONTENT_JSON) {
    if (method === 'POST') {
      if (contentType == CONTENT_X_WWW_FORM_URLENCODED) {
        payload = querystring.stringify(payload)
      }
      return request.post(url)
        .send(payload)
        .set('Accept', 'application/json')
        .set('Authorization', `Bearer ${getToken()}`)
        .set('Content-Type', `application/${contentType}`)
    } else if (method === 'DELETE') {
      return request.delete(url)
        .set('Accept', 'application/json')
        .set('Authorization', `Bearer ${getToken()}`)
        .set('Content-Type', `application/${contentType}`)
    } else {
      let urlParams = querystring.stringify(payload);
      let urlFull = `${url}?${urlParams}`;
      return request.get(urlFull)
        .set('Accept', 'application/json')
        .set('Authorization', `Bearer ${getToken()}`)
        .set('Content-Type', `application/${contentType}`)
    }
  },
  apiGetKeys(pypeId) {
    return this._requestEventBased('GET', Config.getApiRESTURL(`pypes/${pypeId}/keys`));
  },
  apiCreateKeys(payload, pypeId) {
    return this._requestEventBased('POST', Config.getApiRESTURL(`pypes/${pypeId}/keys`), payload);
  },
  apiDeleteKey(payload) {
    return this._requestEventBased('DELETE', Config.getApiRESTURL(`keys/${payload}`));
  },
  getCRMData(url) {
    return this._request('GET', url, null, {
      authTokenAttachMethod: 'noauth'
    });
  },
  getUser(env, userId) {
    return this._request('GET', `${window.config.PS_PM_API_BASE_URL}/${env}/v5/users/${userId}`);
  },
  getPypeDetails(env, userId) {
    return this._request('GET', `${window.config.PS_PM_API_BASE_URL}/${env}/v5/users/${userId}/pypes`);
  },
  getPypeMetrics(env, customer_id, pype_id, skills) {
    return this._request('GET', `${window.config.PS_PM_API_BASE_URL}/${env}/v5/customers/${customer_id}/pypes/${pype_id}/metrics${skills.length ? `?${skills}` : ``}`);
  },
  getCustomer(customer_id) {
    return this._request('GET', `${window.config.PS_PM_API_BASE_URL}/ges/v5/customers/${customer_id}`);
  },
  registerAgent(data) {
    return this._request('POST', Config.getRESTURL(`business/register`), data, {
      contentType: CONTENT_X_WWW_FORM_URLENCODED,
      authTokenAttachMethod: 'body'
    });
  }

}

export default RESTClient;
