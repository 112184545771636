import {connect} from 'react-redux';
import sdk from 'sdk';
import Component from './Component';

const {widgetsActions} = sdk;

const mapStateToProps = (state) => ({
  streamsStore: state.streamsForPype.streams,
})

const mapDispatchToProps = (dispatch, {isNew, widget, onSubmit}) => ({
  onSubmit (stream) {
    if (isNew) {
      onSubmit()
      return Promise.resolve()
    } else {
      return dispatch(widgetsActions.updateWidget({
        id: widget.id,
        stream_id: stream,
      }))
    }
  }
})

export default  connect(
  mapStateToProps,
  mapDispatchToProps
)(Component)
