/*
  Use global ui state
*/

import style from './style.css';
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames';


class CreateApi extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      isRuleModalOpen: false,
    }
  }

  handleClick  = () => {
    this.props.onClick()
    // this.props.activate()
  }

  handleSubmit1  = () => {
    console.log('Submit')
  }

  render () {
    const {props} = this
    const className = classNames(style.CreateApi, {
      [style.shouldMask]: props.shouldMask,
      [style.Inactive]: props.inactive
    })
    return (
      <div className={className}>
        <button className={style.Button} onClick={this.handleClick}>
          <img className={style.Img} src={`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-add-big.svg`} />
          <span className={style.Text}>Add a rule pattern</span>
        </button>
      </div>
    )
  }

}

CreateApi.propTypes = {
  shouldMask: PropTypes.bool.isRequired,
}

export default  CreateApi
