import { agentConstants, TOGGLE_USERINFO_PANE } from '../constants'
import { isOffHours } from '../utils/agent';
import { updateAgentStatus } from './agents';
import { resetAgentChatSessions } from './agentChatSessions';
import RESTClient from '../RESTClient';
import SDK from '../SDK'

const request = () => ({
  type: agentConstants.REQUEST,
})

const receiveAgent = (data) => ({
  type: agentConstants.RECEIVE,
  data,
})

const enableOffHours = () => ({
  type: agentConstants.ENABLE_OFF_HOURS
})

const disableOffHours = () => ({
  type: agentConstants.DISABLE_OFF_HOURS
})

export const fetchAgent = (skills) => (dispatch, getState) => {
  dispatch(request())
  return SDK.listAgentData({
    agent_id: getState().botUserSession.user.user_id,
    skills
  }).then((data) => {
    dispatch(checkOffHours())
    dispatch(receiveAgent(data))
  })
}

export const receiveUpdatedStatus = (data) => (dispatch, getState) => {
  dispatch(updateAgentStatus(data));
  // if status is offline clear all active sessions
  if (data.status === 'offline') {
    dispatch(resetAgentChatSessions());
    dispatch(resetChatCount());
  }
  return dispatch({
    type: agentConstants.SET_STATUS,
    status: data.status
  })
}

export const setStatus = (status) => (dispatch, getState) => {
  return SDK.setStatus({
    agent_id: getState().botUserSession.user.user_id,
    status,
  })
}

export const setBrowserStatusOffline = (isBrowserStatusOffline) => (dispatch, getState) => {
  return dispatch({
    type: agentConstants.SET_BROWSER_STATUS,
    isBrowserStatusOffline
  })
}

export const incChatCount = () => ({
  type: agentConstants.INC_CHAT_COUNT,
})

export const decChatCount = () => ({
  type: agentConstants.DEC_CHAT_COUNT,
})

export const resetChatCount = () => ({
  type: agentConstants.RESET_CHAT_COUNT,
})

export const checkOffHours = () => (dispatch, getState) => {
  const { schedule_enabled } = getState().pype.queues;
  const { isOffHours: isAgentOffHours } = getState().agent;

  if (isAgentOffHours === isOffHours(getState())
    || !schedule_enabled && !isAgentOffHours) return; // no state change needed

  if (!schedule_enabled) return dispatch(disableOffHours());

  return isOffHours(getState()) ? dispatch(enableOffHours()) : dispatch(disableOffHours());
}

export const registerAgent = ({ pype_id, id, role, first_name, last_name, email }) => {
  return new Promise(function (resolve, reject) {
    RESTClient.registerAgent({
      pype_id,
      id,
      // TODO v3 platform still uses old role name. 
      role,
      first_name,
      last_name,
      email
      // TODO skills
    }).then((data) => {
      resolve();
    });
  });

}

export const toggleUserInfo = (isSessionUserInfoIsOpen) => (
  dispatch,
  getState
) => {
  return dispatch({
    type: TOGGLE_USERINFO_PANE,
    isSessionUserInfoIsOpen,
  });
};
