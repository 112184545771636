import React from 'react';
import FileDropzone from 'react-dropzone';
import Textarea from 'react-textarea-autosize';
import cx from 'classnames';
import PropTypes from 'prop-types';

// Local imports
import style from './style.css';
import FilesUtils from 'utils/files';
import assets from 'utils/assets';

import ApplePay from '../../../../img/apple-pay-brands.svg';
import { Tooltip } from '@material-ui/core';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

const AttachmentPreviewIcon = (props) => {
  const { file } = props;
  const iconType = FilesUtils.fileTypeForExtension(
    file.name.split('.').slice(-1)[0]
  );
  const iconColor = 'w';

  return iconType ? (
    <img
      alt=''
      src={assets.path(
        `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-${iconType}-type-${iconColor}.png`
      )}
    />
  ) : (
    <img alt='' width='30px' height='20px' src={file.preview} />
  );
};

const AttachmentBar = (props) => {
  const { onRemoveAttachment, attachment } = props;

  return (
    <div className={cx('attachment-bar', { visible: attachment })}>
      {/*<div style={{width: uploadProgress + '%'}} className={cx('progress', { 'visible': uploadProgress > 0 && uploadProgress < 100 })} /> */}
      <div className='attacmhent-preview-small'>
        {attachment ? <AttachmentPreviewIcon file={attachment} /> : null}
        {/*<img alt="" width="30px" height="20px" src={preview}/>*/}
        <span
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {attachment && attachment.name}
        </span>
      </div>
      <span
        data-test='close-button'
        className='close-button'
        onClick={onRemoveAttachment}
      />
    </div>
  );
};

const ChatInput = (props) => {
  const {
    canPostMessage,
    status,
    error,
    messageInputPlaceholder,
    onSubmit,
    onFileDrop,
    onKeyDown,
    message,
    onChange,
    onRemoveAttachment,
    attachment,
    willSendEmbed,
  } = props;
  const isChatBoxEmpty = message.trim() === '';

  return (
    <div className='chat-input relative'>
      <AttachmentBar
        data-test='attatchment-bar'
        onRemoveAttachment={onRemoveAttachment}
        attachment={attachment}
      />

      <form
        className={cx('col-xs-12 p-10 relative', {
          'form-disabled': !canPostMessage,
        })}
        onSubmit={onSubmit}
      >
        <span
          className='error text-danger'
          dangerouslySetInnerHTML={{ __html: error }}
        />
        <span className='status'>{status}</span>
        <div className='flex-row'>
          {canPostMessage ? props.flags.newTooltip ? (

            <Tooltip disableFocusListener={props.flags.pe18399} title="Attach" placement="top">
              <div className="flex-center p-r-10">
                <FileDropzone data-test='file-dropzone' data-cy="drag-and-drop" className={cx("btn btn-attach p-0", style.btnAttach)} onDrop={onFileDrop} multiple={false}>
                </FileDropzone>
              </div>
            </Tooltip>) : (
            <div className="flex-center p-r-10">
              <FileDropzone data-cy="drag-and-drop" className={cx("btn btn-attach p-0", style.btnAttach)} onDrop={onFileDrop} multiple={false}>
              </FileDropzone>
            </div>
          ) : null}

          {willSendEmbed === 'listPicker' ? (
            <div data-test='embed-listpicker' className='embedPreview'>
              <div className='embedPreview--listpicker' />
              <div className='embedPreview--listpicker' />
              <div className='embedPreview--listpicker' />
              <div className='embedPreview--listpicker' />
            </div>
          ) : willSendEmbed === 'timePicker' ? (
            <div data-test='embed-timepicker' className='embedPreview'>
              <div className='embedPreview--timepicker'>4:30</div>
              <div className='embedPreview--timepicker'>PM</div>
            </div>
          ) : willSendEmbed === 'applePay' ? (
            <div className='embedPreview'>
              <div
                data-test='embed-applepay'
                className='embedPreview--applePay'
              >
                <img src={ApplePay} alt='Apple Pay' />
              </div>
            </div>
          ) : null}

          <Textarea
            disabled={!canPostMessage}
            data-test='text-input-component'
            name='chatbox'
            placeholder={messageInputPlaceholder}
            onKeyDown={onKeyDown}
            className={
              'form-control no-border no-resize p-0' +
              (canPostMessage ? '' : ' input-sm')
            }
            type='text'
            minRows={1}
            maxRows={12}
            value={message}
            onChange={onChange}
          />

          {canPostMessage ? (
            <div data-test='can-post-message' className='flex-center p-l-10'>
              <button
                className='btn btn-link btn-sm btn-send-chat p-0'
                type='submit'
                disabled={isChatBoxEmpty && !Boolean(attachment)}
              >
                <span className='semi-bold'>Send</span>
              </button>
            </div>
          ) : null}
        </div>
      </form>
    </div>
  );
};

ChatInput.propTypes = {
  canPostMessage: PropTypes.bool.isRequired,
  status: PropTypes.string,
  error: PropTypes.string,
  messageInputPlaceholder: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onFileDrop: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  message: PropTypes.string,
  attachment: PropTypes.object,
  onChange: PropTypes.func,
  onRemoveAttachment: PropTypes.func,
  isCannedResponsesEnabled: PropTypes.bool,
  willSendEmbed: PropTypes.string,
};

export const ComponentWithFeatureFlags = withLDConsumer()(ChatInput);
export default ComponentWithFeatureFlags;
