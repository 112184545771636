import style from './style.css';
import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash';
import classNames from 'classnames';
import Select, { components }  from 'react-select';

import constants from './constants';
import RadioButton from 'components/ui/forms/RadioButton';
import ChatCircle from '../ChatCircle';
import ChatAnchor from '../ChatAnchor';
import ColorPicker from 'components/ui/color-picker/ColorPicker';

const {POSITION_OPTIONS, TEXT_COLOR_OPTIONS} = constants;

const Option = ({
  innerProps, 
  data: { value }
}) => {
  const className = classNames(style.ColorBox, {
    [style.shouldShowOutline]: value === '#ffffff',
  })
  return (
    <div className={style.ColorOption} {...innerProps}>
      <div className={className} style={{background: value}}>
      </div>
    </div>
  )
};

const ValueContainer = ({ children, ...props }) => {
  const { getValue } = props;
  const { value } = getValue()[0]
  const className = classNames(style.ColorBox, {
    [style.shouldShowOutline]: value === '#ffffff',
  })
  return <components.ValueContainer {...props}>
     <div className={style.ColorOption}>
      <div className={className} style={{background: value}}>
      </div>
    </div>
  </components.ValueContainer>
};

class DesignConfig extends React.Component {

  renderPositionOption (option) {
    return (
      <div className={style.PositionOption}>
        <div className={style.PositionBox}>
          <div className={`${style.PositionBoxInner} ${style[option.value]}`} />
        </div>
        {option.label}
      </div>
    )
  }

  bindToDesignProp (prop) {

    return (param) => {
      if (param.hasOwnProperty('target')) {
        const value = param.target.value
        // editing color value
        if(_.indexOf(['userBubbleColor', 'agentBubbleColor', 'widgetStyleColor'], prop) > -1){
          if(!/^#[0-9A-F]{0,6}$/i.test(value)) return;
        }
        this.props.onDesignChange(prop, param.target.value)
      } else if (param.hasOwnProperty('value')) {
        this.props.onDesignChange(prop, param.value)
      } else { // Color picker
        this.props.onDesignChange(prop, param.color)
      }
    }
  }

  handleClickStyleOption1 = () => {
    this.props.onDesignChange('styleSelection', 1)
  }

  handleClickStyleOption2 = () => {
    this.props.onDesignChange('styleSelection', 2)
  }

  handleHeightChange = (event) => {
    this.props.onDesignChange('widgetHeight', parseInt(event.target.value))
  }

  handleWidthChange = (event) => {
    this.props.onDesignChange('widgetWidth', parseInt(event.target.value))
  }

  handleRatioToggle = () => {
    this.props.onDesignChange('shouldLockRatio', !this.props.design.shouldLockRatio)
  }

  handleUpload = (event) => {
    const file = this.refs.upload.files[0]
    this.props.onUpload(file)
  }

  render () {
    const {props} = this
    const {design} = props
    return (
      <div className={style.DesignConfig}>
        <section>
          <div className={style.Heading}>1. Style</div>
          <div className={style.StyleOptions}>
            <div className={classNames(style.StyleOption, style.StyleOption1, {
              [style.StyleOptionIsSelected]: design.styleSelection === 1,
            })} onClick={this.handleClickStyleOption1}>
              <ChatCircle style={{marginBottom: '20px'}} design={design} />
              <div className={style.RadioOption}>
                <RadioButton className={style.RadioButton} checked={design.styleSelection === 1} /> Floating
              </div>
            </div>
            <div className={classNames(style.StyleOption, style.StyleOption2, {
              [style.StyleOptionIsSelected]: design.styleSelection === 2,
            })} onClick={this.handleClickStyleOption2}>
              <ChatAnchor style={{marginBottom: '26px'}} design={design} position={design.widgetPosition} />
              <div className={style.RadioOption}>
                <RadioButton className={style.RadioButton} checked={design.styleSelection === 2} /> Anchored
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className={style.Heading}>
            2. Size and placement
            <span className={style.ResetButton} onClick={props.onResetPosition}>Reset</span>
          </div>
          <div>
            <div className={style.InputHeading}>Web Conversational Interface position</div>
            <Select
              className={style.Dropdown}
              value={POSITION_OPTIONS.filter(({value}) => value === design.widgetPosition)}
              options={POSITION_OPTIONS}
              placeholder='Select Web Conversational Interface position'
              optionRenderer={this.renderPositionOption}
              clearable={false}
              searchable={false}
              onChange={this.bindToDesignProp('widgetPosition')} />
          </div>
          <div className={style.Ratio}>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <div className={style.InputWrapper}>
                <div className={style.InputHeading}>Web Conversational Interface height</div>
                <div className={style.TextInputWrapper}>
                  <input className={style.TextInput} type='number' value={design.widgetHeight}
                    onChange={this.handleHeightChange}
                    onBlur={props.onBlurWidgetHeight} />
                  {/* <div className={style.TextInputLabel}>px</div> */}
                </div>
              </div>
              <span className={style.LockRatio} onClick={this.handleRatioToggle}>
                {design.shouldLockRatio ? (
                  <img src={`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-link-closed.svg`} />
                ) : (
                  <img src={`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-link-open.svg`} />
                )}
              </span>
              <div className={style.InputWrapper}>
                <div className={style.InputHeading}>
                Web Conversational Interface width  {/* <span className={style.Unit}>(pixels)</span> */}
                </div>
                <div className={style.TextInputWrapper}>
                  <input className={style.TextInput} type='number' value={design.widgetWidth}
                    onChange={this.handleWidthChange}
                    onBlur={props.onBlurWidgetWidth} />
                  {/* <div className={style.TextInputLabel}>px</div> */}
                </div>
              </div>
            </div>
            <div className={style.QuietInfo}>
              Height and width will not be reflected in the sample to the right
            </div>
            {props.errors.widgetHeight && (
              <p className={style.error}>{props.errors.widgetHeight}</p>)}
            {props.errors.widgetWidth && (
              <p className={style.error}>{props.errors.widgetWidth}</p>)}
          </div>
        </section>
        <section>
          <div className={style.Heading}>
            3. Colors
            <span className={style.ResetButton} onClick={props.onResetColors}>Reset</span>
          </div>
          <div className={style.ColorRow}>
            <div className={style.ColorPickerSection}>
              <div className={style.InputHeading}>Web Conversational Interface style</div>
              <div className={style.ColorPicker}>
                <ColorPicker
                  color={design.widgetStyleColor}
                  onChange={this.bindToDesignProp('widgetStyleColor')}/>
                <input className={style.ColorTextInput} type='text' value={design.widgetStyleColor}
                  onChange={this.bindToDesignProp('widgetStyleColor')}
                  onBlur={props.onBlurWidgetStyleColor}/>
              </div>
            </div>
            <div className={style.ColorPickerSection} style={{marginLeft: 42}}>
              <div className={style.InputHeading}>Text color</div>
              <div className={`${style.ColorPicker} ${style.TextColorPicker}`}>
                <Select
                  className={style.Dropdown}
                  components={{ Option, ValueContainer }}
                  value={TEXT_COLOR_OPTIONS.filter(({value}) => value === design.widgetStyleTextColor)}
                  options={TEXT_COLOR_OPTIONS}
                  clearable={false}
                  searchable={false}
                  onChange={this.bindToDesignProp('widgetStyleTextColor')} />
              </div>
            </div>
          </div>
          <div className={style.ColorRow}>
            <div className={style.ColorPickerSection}>
              <div className={style.InputHeading}>Agent bubble</div>
              <div className={style.ColorPicker}>
                <ColorPicker
                  color={design.agentBubbleColor}
                  onChange={this.bindToDesignProp('agentBubbleColor')}/>
                <input className={style.ColorTextInput} type='text' value={design.agentBubbleColor}
                  onChange={this.bindToDesignProp('agentBubbleColor')}
                  onBlur={props.onBlurAgentBubbleColor} />
              </div>
            </div>
            <div className={style.ColorPickerSection} style={{marginLeft: 42}}>
              <div className={style.InputHeading}>Text color</div>
              <div className={`${style.ColorPicker} ${style.TextColorPicker}`}>
                <Select
                  className={style.Dropdown}
                  value={TEXT_COLOR_OPTIONS.filter(({value}) => value === design.agentBubbleTextColor)}
                  options={TEXT_COLOR_OPTIONS}
                  components={{ Option, ValueContainer }}
                  clearable={false}
                  searchable={false}
                  onChange={this.bindToDesignProp('agentBubbleTextColor')} />
              </div>
            </div>
          </div>
          <div className={style.ColorRow}>
            <div className={style.ColorPickerSection}>
              <div className={style.InputHeading}>User bubble</div>
              <div className={style.ColorPicker}>
                <ColorPicker
                  color={design.userBubbleColor}
                  onChange={this.bindToDesignProp('userBubbleColor')}/>
                <input className={style.ColorTextInput} type='text' value={design.userBubbleColor}
                  onChange={this.bindToDesignProp('userBubbleColor')}
                  onBlur={props.onBlurUserBubbleColor}/>
              </div>
            </div>
            <div className={style.ColorPickerSection} style={{marginLeft: 42}}>
              <div className={style.InputHeading}>Text color</div>
              <div className={`${style.ColorPicker} ${style.TextColorPicker}`}>
                <Select
                  className={style.Dropdown}
                  value={TEXT_COLOR_OPTIONS.filter(({value}) => value === design.userBubbleTextColor)}
                  options={TEXT_COLOR_OPTIONS}
                  components={{ Option, ValueContainer }}
                  clearable={false}
                  searchable={false}
                  onChange={this.bindToDesignProp('userBubbleTextColor')} />
              </div>
            </div>
          </div>
        </section>
        <section style={{display: 'none'}}>
          <div className={style.Heading}>4. Cover image</div>
          <div className={style.Info}>
            Upload a cover image (e.g. company logo) in the following
            format: 500px by 167px minimum, PNG, JPG, GIF or TIFF, 4MB
            max.
          </div>
          <div>
            <input ref='upload' className={style.Upload} type='file' onChange={this.handleUpload} />
            {/* <img src={design.bannerUrl} /> */}
          </div>
        </section>
      </div>
    )
  }

}

DesignConfig.propTypes = {
  design: PropTypes.shape({
    styleSelection: PropTypes.oneOf([1, 2]).isRequired,
    widgetPosition: PropTypes.oneOf([
      'top-left', 'top-right', 'bottom-left', 'bottom-right',
    ]).isRequired,
    shouldLockRatio: PropTypes.bool.isRequired,
    widgetWidth: PropTypes.number.isRequired,
    widgetHeight: PropTypes.number.isRequired,
    widgetStyleColor: PropTypes.string.isRequired,
    widgetStyleTextColor: PropTypes.string.isRequired,
    agentBubbleColor: PropTypes.string.isRequired,
    agentBubbleTextColor: PropTypes.string.isRequired,
    userBubbleColor: PropTypes.string.isRequired,
    userBubbleTextColor: PropTypes.string.isRequired,
    bannerUrl: PropTypes.string.isRequired,
  }).isRequired,
  errors: PropTypes.object.isRequired,
  onDesignChange: PropTypes.func.isRequired,
  onResetColors: PropTypes.func.isRequired,
  onResetPosition: PropTypes.func.isRequired,
  onBlurWidgetWidth: PropTypes.func.isRequired,
  onBlurWidgetHeight: PropTypes.func.isRequired,
  onBlurWidgetStyleColor: PropTypes.func.isRequired,
  onBlurAgentBubbleColor: PropTypes.func.isRequired,
  onBlurUserBubbleColor: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
}

export default  DesignConfig
