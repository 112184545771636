import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames';

const Checkbox = (props) => {
  const {value, onClick, name} = props
  const cssClass = cx('ui-checkbox', {value})
  return <div className={cssClass} >
    <input
      type="checkbox"
      value={value}
      onClick={props.disabled ? undefined : onClick}
      name={name}
      className="hidden"/>
  </div>
}

Checkbox.propTypes = {
  value: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  name: PropTypes.string
}

Checkbox.defaultProps = {
  disabled: false
}


export default  Checkbox
