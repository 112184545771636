import style from './style.css';
import React from 'react';
import PropTypes from 'prop-types';


import AgentsList from '../AgentsList';
import AgentSearchResults from '../AgentSearchResults';

const SearchPlaceholder = () => {
  return (<div className={style.placeholder}>
    <img src={`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-search-placeholder.svg`} width='12' height='12' />
    <span>Search</span>
  </div>)
}

class Component extends React.Component {
  constructor (props){
    super(props);
    this.state = {
      search: ''
    }
    this.handleSearchInputChange = this.handleSearchInputChange.bind(this)
    this.handleClearSearch = this.handleClearSearch.bind(this)
  }

  handleClearSearch = () => {
    this.setState({search: ''})
  }

  handleSearchInputChange = (e) => {
    this.setState({search: e.target.value})
  }

  handleAgentSelect = (agent) => {
    this.props.onAgentSelect(agent)
  }

  handleAgentSelectAll = () => {
    this.props.onAgentSelectAll()
  }

  handleAgentsRestore = (agents) => {
    this.props.onAgentsRestore(agents)
  }

  render () {
    const {props, state} = this;

    const isSearchMode = state.search.length !== 0

    return (
      <div className={style.searchWrapper}>
        <div className={`${style.wrapper}`}>
          <label className="m-r-20">Agents</label>
          {props.agents.length > 0 ?
            <div className={style.searchInput}>
              { !isSearchMode ?
                <SearchPlaceholder/> : null
              }
              <input id="agent-search-input" type="text" value={state.search} onChange={this.handleSearchInputChange}/>
              {isSearchMode ?
                <button type='button' className={style.clearSearch} onClick={this.handleClearSearch}>
                  <img src={`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-clear-search.svg`} width='24' height='24' />
                </button> : null}
            </div> : null}
        </div>
        {isSearchMode ?
          <AgentSearchResults
            search={state.search}
            agents={props.agents}
            onAgentSelect={this.handleAgentSelect}
            /> :
          <AgentsList
            agents={props.agents}
            onAgentSelect={this.handleAgentSelect}
            onAgentSelectAll={this.handleAgentSelectAll}
            onAgentsRestore={this.handleAgentsRestore}
          />
        }
      </div>
    )
  }

}

Component.propTypes = {
  agents: PropTypes.array.isRequired,
  onAgentSelect: PropTypes.func.isRequired,
  onAgentSelectAll: PropTypes.func.isRequired
}

Component.defaultProps = {
  agents: [],
  search: ''
}

export default  Component
