import _ from 'lodash'
import {tagConstants} from '../constants'
import SDK from '../SDK'

const request = () => ({
  type: tagConstants.REQUEST_TAGS,
})

const receiveTags = (data) => ({
  type: tagConstants.RECEIVE_TAGS,
  data,
})

const createTag = (data) => (dispatch, getState) => {
  dispatch(request())

  data = {
    ...data,
    pype_id: getState().pype.details.id,
    category_id: getState().tags.category.id,
  }

  return SDK.createTag(data).then(() => {
    // Unfortunately have to do a fetch to trigger an update because
    // platform does not return a tag w/ newly generated id currently
    // but this should change.
    dispatch(request())
    return new Promise((resolve, reject) => {
      // Platform does not return the new tag sometimes unless there's
      // a timeout here.
      setTimeout(() => {
        dispatch(fetchTags()).then(resolve, reject)
      }, 500)
    })
  })
}

const updateTag = (data) => (dispatch, getState) => {
  dispatch(request())
  const tagData = {
    ...getState().tags.store[data.id],
    ...data,
  }

  data = {
    ...tagData,
    pype_id: getState().pype.details.id,
    category_id: getState().tags.category.id,
  }

  return SDK.createTag(data).then(() => {
    dispatch({
      type: tagConstants.UPDATE_TAG,
      data,
    })
  })

}

// Public actions

export const fetchTags = () => (dispatch, getState) => {
  const category_id = getState().tags.category.id
  
  if(!category_id) {
    throw new Error('category_id missing in fetchTags.')
  }
  dispatch(request())

  return SDK.getTags({
    pype_id: getState().pype.details.id,
    category_id,
  }).then((data) => (
    dispatch(receiveTags(data))
  ))
}

export const createOrUpdateTag = (tag) => (dispatch, getState) => {
  if (_.isNil(tag.id)) {
    return dispatch(createTag(tag))
  } else {
    return dispatch(updateTag(tag))
  }
}

export const destroyTag = (id) => (dispatch, getState) => {
  const tag = getState().tags.store[id]
  dispatch(request())

  const data = {
    ...tag,
    category_id: getState().tags.category.id,
  }

  return SDK.deleteTag(data).then(() => {
    dispatch({
      type: tagConstants.DESTROY_TAG,
      id: tag.id,
    })
  })

}

export const saveConfig = (config) => (dispatch, getState) => {
  dispatch(request())
  // x_update doesn't give a correlation id in message, >:(
  return Promise.all([
    SDK.updatePype(config),

    SDK.updateTagCategory({
      pype_id: getState().pype.details.id,
      id: getState().tags.category.id,
      name: config.category_name,
    }).then((category) => {
      // <WARNING> x_category does not respond w/ notice so we assume
      // it comes back with the same name we provided
      dispatch(savedCategory({
        name: config.category_name,
      }))
    }),
  ])
}

const savedCategory = (data) => ({
  type: tagConstants.SAVED_CATEGORY,
  data,
})

export const savedConfig = (data) => ({
  type: tagConstants.SAVED_CONFIG,
  config: {
    categoryName: data.category_name,
    tagsEnabled: data.end_tags_display,
    tagsRequired: data.end_tags_required,
    enableMultipleTags: data.tags_multiple,
    enableTagCodes: data.tags_shortcuts,
  },
})
