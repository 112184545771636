import { BOT_USER_SESSION_LOGOUT } from "bot-user-session";
import {
  REQUEST_BOT_STATS, RECEIVE_BOT_STATS
} from "../constants"

const getInitialState = () => ({
  isFetching: false,
  bots: []
});

export default function botsForPype(state = getInitialState(), action) {
  switch (action.type) {
    case BOT_USER_SESSION_LOGOUT: {
      return getInitialState();
    }
    case REQUEST_BOT_STATS:
      return Object.assign({}, state, {
        isFetching: true,
        bots:[]
      })
    case RECEIVE_BOT_STATS:
      return Object.assign({}, state, {
        isFetching: false,
        bots: action.bots
      })
    default:
      return state
  }
}
