import _ from 'lodash'
import {connect} from 'react-redux';
import Component from './Component';
import sdk from 'sdk';
const {apiActions} = sdk;

const mapDispatchToProps = (dispatch) => ({
  onDelete (api) {
    return dispatch(apiActions.deleteApi(api))
  }
})

const mapStateToProps = (state) => {
  return {
    pypeName: state.pype.details.name,
    customerName: state.pype.details.customerName
  }
};

export default  connect(mapStateToProps, mapDispatchToProps)(Component)
