import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames';
import UserProfile from './UserProfile';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

class UserInfoTab extends React.Component {

  constructor(props) {
    super(props)
    this.toggle = this.toggle.bind(this)
    this.state = {isProfileOpen: false}
  }

  toggle(event) {
    event.preventDefault()
    this.setState({isProfileOpen: !this.state.isProfileOpen}, () => {
      this.props.onToggle(this.state.isProfileOpen)
    })
  }

  render() {
    const {props, state} = this
    return (
      <div
        data-test="user-info-tab-component"
        className={cx("user-info-header", {
          "is-profile-open": state.isProfileOpen,
        })}
      >
        <div
          className={cx("user-info-wrapper", {
            "old-user-info-wrapper": !props.flags.newUserInfo,
          })}
        >
          <UserProfile
            data-test="user-profile"
            consumer={props.consumer}
            onSubmitLog={props.onSubmitLog}
            chatId={props.chatId}
          />
        </div>
      </div>
    );
  }
}

UserInfoTab.propTypes = {
  consumer: PropTypes.object.isRequired,
  onSubmitLog: PropTypes.func.isRequired
}

const UserInfoTabWithFeatureFlags = withLDConsumer()(UserInfoTab);

export default  UserInfoTabWithFeatureFlags;
