import React from 'react'
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
// Local imports
import style from './style.css';

const SearchCannedResponse = (props) => (
    <input type="text"
      className={cx(style.input, props.className)}
      placeholder={ props.placeholder }
      onChange={ props.onChange }
      />
);

SearchCannedResponse.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func
};
SearchCannedResponse.defaultProps = {
  className: '',
  placeholder: '',
  onChange: _.noop
};
export default  SearchCannedResponse;
