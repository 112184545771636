import {connect} from 'react-redux';
import sdk from 'sdk';
import Component from './Component';
const {widgetsActions} = sdk;

const mapStateToProps = (state) => ({
  pypeName: state.pype.details.name,
  pypeLogo: state.pype.details.logo,
})

const mapDispatchToProps = (dispatch, {isNew, widget, onSubmit}) => ({
  onSubmit (design, bannerFile) {
    // console.log(bannerFile)
    if (isNew) {
      return onSubmit()
    } else {
      return dispatch(widgetsActions.updateWidget({
        id: widget.id,
        stream_id: widget.stream_id,
        style: JSON.stringify(design),
      }, bannerFile))
    }
  }
})

export default  connect(
  mapStateToProps,
  mapDispatchToProps
)(Component)
