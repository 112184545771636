import { cannedResponsesConstants as types } from '../constants'
import SDK from '../SDK'

// Fetch
const requestCannedResponses = () => ({
  type: types.REQUEST_CANNED_RESPONSES,
})

const receiveCannedResponses = (data) => ({
  type: types.RECEIVE_CANNED_RESPONSES,
  data,
})

const fetchCannedResponses = () => (dispatch, getState) => {
  dispatch(requestCannedResponses())
  return SDK.getCannedResponses({
    pype_id: getState().pype.details.id,
  }).then((data) => {
    return dispatch(receiveCannedResponses(data))
  })
}

// DELETE
const deleteCannedResponseAction = () => ({
  type: types.DELETE_CANNED_RESPONSE
})

const deletedCannedResponseAction = (data) => ({
  type: types.DELETED_CANNED_RESPONSE,
  data
})

const deleteCannedResponse = (data) => (dispatch, getState) => {
  dispatch(deleteCannedResponseAction())
  return SDK.deleteCannedResponse({
    ...data,
    pype_id: getState().pype.details.id
  }).then(() => {
    return dispatch(deletedCannedResponseAction(data))
  })
}

// UPDATE
const updateCannedResponseAction = () => ({
  type: types.UPDATE_CANNED_RESPONSE
})

const updatedCannedResponseAction = (data) => ({
  type: types.UPDATED_CANNED_RESPONSE,
  data
})

const updateCannedResponse = (data) => (dispatch, getState) => {
  dispatch(updateCannedResponseAction())
  
  return SDK.updateCannedResponse({
    ...data,
    pype_id: getState().pype.details.id
  }).then(() => {
    return dispatch(updatedCannedResponseAction(data))
  })
}

// CREATE 
const createCannedResponseAction = () => ({
  type: types.CREATE_CANNED_RESPONSE
})

const createdCannedResponse = (data) => ({
  type: types.CREATED_CANNED_RESPONSE,
  data
})

const createCannedResponse = (data) => (dispatch, getState) => {
  const cannedResponse = {
    ...data,
    pype_id: getState().pype.details.id
  }
  dispatch(createCannedResponseAction())

  return SDK.createCannedResponse(cannedResponse)
  .then(() => {
    return dispatch(createdCannedResponse({
      ...cannedResponse
    }))
  })
}

export const setCannedResponseDrawer = (category_id) => (dispatch, getState) => {
  const agent_id = getState().agent.id;
  return dispatch({
    type: types.SET_CANNED_RESPONSE_DRAWER,
    category_id,
    agent_id,
  });
}

export const getCannedResponseDrawer = () => (dispatch, getState) => {
  const agent_id = getState().agent.id;
  return dispatch({
    type: types.GET_CANNED_RESPONSE_DRAWER,
    agent_id,
  });
}

export {
  fetchCannedResponses,
  createCannedResponse,
  updateCannedResponse,
  deleteCannedResponse
}
