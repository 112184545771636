import React from "react";
import { connect } from "react-redux";
import Fingerprint2 from "fingerprintjs2";
import { Route, Redirect, withRouter } from "react-router-dom";
import {
  doesUserHasRole,
  PYPE_AGENT_ROLES, PYPE_MANAGER_ROLES
} from "bot-user-session";
import { PypestreamLoader } from "ui-elements";
import featureFlags from 'utils/featureFlags'

import { guid } from "sdk/common/utils/common-utils";
import Overview from "components/overview/Overview";
import Campaigns from "components/campaigns/Campaigns";
import Conversations from "components/conversations/Conversations";
import admin from "components/admin";
import heartbeat_worker_script from './heartbeat_worker';
import Content from "./content";
import Notifications from "./notifications";
import FlashMessage from "components/ui/flash-message/FlashMessage";
import AppTooltipHost from 'components/shared/AppTooltipHost';
import Toast from 'components/ui/toast/Toast';
import * as Config from "sdk/Config";
import Constant from 'core/constant';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import sdk from 'sdk'
const {
  SDK,
  agentsActions,
  pypesActions,
  pypeActions,
  sessionActions,
  agentActions,
  streamActions,
  tagActions,
  skillsActions,
  widgetsActions,
  apiActions,
  agentChatSessionsActions,
  utils,
  heartbeatActions,
  RESTClient,
  fetchInitSequence
} = sdk

const DashboardRoute = ({
  component: Component,
  isAuthenticated,
  isAllowed = false,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {

      if (isAuthenticated && !isAllowed) {
        return <Redirect to="/unauthorized" />;
      }

      return (
          <Component {...props} />
      );
    }}
  />
);

export class Main extends React.Component {

  constructor(props) {
    super(props)
    this.heartbeatInterval = null
    this.seq_num = 0;
    this.heartbeatWorker = null;
    this.handleInactiveTab = this.handleInactiveTab.bind(this);
    this.setBrowserStatusOffline = this.setBrowserStatusOffline.bind(this);
    this.setBrowserStatusOnline = this.setBrowserStatusOnline.bind(this);
  }

  state = {
    isInitialized: false
  }

  componentDidMount() {
    window.appCreateToast = (props) => {
      if (this.refs.toast) {
        this.refs.toast.createToast(props)
      } else {
        console.error('@this.refs.toast does not exist.')
      }
    }

    // Add walkme snippet
    const script = document.createElement('script')
    script.type = 'text/javascript'
    if (process.env.NODE_ENV === 'development') {
      script.innerHTML = `(function() {var walkme = document.createElement('script'); walkme.type = 'text/javascript'; walkme.async = true; walkme.src = 'https://cdn.walkme.com/users/c51162e2a5bd44eabcf119e715662e85/test/walkme_c51162e2a5bd44eabcf119e715662e85_https.js'; var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(walkme, s); window._walkmeConfig = {smartLoad:true}; })();`
    } else if (process.env.NODE_ENV === 'production') {
      script.innerHTML = `(function() {var walkme = document.createElement('script'); walkme.type = 'text/javascript'; walkme.async = true; walkme.src = 'https://cdn.walkme.com/users/c51162e2a5bd44eabcf119e715662e85/walkme_c51162e2a5bd44eabcf119e715662e85_https.js'; var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(walkme, s); window._walkmeConfig = {smartLoad:true}; })();`
    }
    document.body.appendChild(script)

    this.init().then(() => {
      if (!this.props.flags.heartbeatThroughWebworker) {
        document.addEventListener('visibilitychange', this.handleInactiveTab);
      }
      window.addEventListener('online',  this.setBrowserStatusOnline);
      window.addEventListener('offline',  this.setBrowserStatusOffline);
    });
  }

  componentDidUpdate(prevProps) {
    // NOTE: in order to have access to this information, you will need
    // to wrap this component in the `withRouter` HOC
    const { location: { pathname } } = this.props;
    const previousLocation = prevProps.location.pathname;

    if (pathname !== previousLocation) {
      window.Appcues.page();
    }
  }

  componentWillUnmount() {
    this.stopHeartbeat();
    if(this.props.flags.inactiveTabHeartbeatFix) {
      document.removeEventListener('visibilitychange', this.handleInactiveTab);
    }
    if(this.props.flags.isOfflinePlaceholderText) {
      window.removeEventListener('online', this.setBrowserStatusOnline);
      window.removeEventListener('offline', this.setBrowserStatusOffline);
    }
    if (this.heartbeatWorker) {
      this.heartbeatWorker.terminate();
    }
  }

  setBrowserStatusOnline(){
    this.props.setBrowserStatusOffline(false)
  }

  setBrowserStatusOffline(){
    this.props.setBrowserStatusOffline(true)
  }

  handleInactiveTab() {
    this.stopHeartbeat()
    if (document.hidden) {
      this.startWebWorkerHeartbeat()
    } else {
      this.startOldWayHeartbeat()
    }
  }

  // New way of starting heartbeat timer
  startWebWorkerHeartbeat = () => {
    if (this.heartbeatWorker) {
      return
    }
    const isAgent = doesUserHasRole(PYPE_AGENT_ROLES, this.props.pype.id);
    const { status, isAuthenticated } = this.props;
    if (isAgent && status !== "offline" && isAuthenticated) {
      this.handleSendHeartbeat()
      this.heartbeatWorker = new Worker(heartbeat_worker_script);
      this.heartbeatWorker.onmessage = () => {
        if (!this.props.isAgentDisconnected || !this.props.flags.disableStopHeartbeatOnDisconnect) {
          this.handleSendHeartbeat()
        }
      };
    } else {
      if (this.heartbeatWorker) {
        if(!this.props.isAgentDisconnected) {
          this.startHeartbeat();
        } else {
          this.stopHeartbeat();
        }
      }
    }
  }

  stopWebWorkerHeartBeat = () => {
    if(this.heartbeatWorker) {
      this.heartbeatWorker.terminate()
      this.heartbeatWorker = null
    }
  }

  handleSendHeartbeat = () => {
    this.props.sendHeartbeat(this.seq_num);
    this.seq_num++;
  }

  async init() {
    const {pypeId} = this.props.match.params
    const { fetchPypes, setPype, fetchCustomer } = this.props

    await this.setUserSession()
    await fetchPypes()
    const clientConfig = {
      key: this.props.user.user_id,
      ...this.props.user.optimizelyUserAttributes
    };
    await this.props.ldClient.identify({
      ...clientConfig,
      "custom": {
        ...clientConfig.custom,
        "domain": window.location.href,
      }
    });

    await window.Appcues.identify(
      this.props.user.user_id,
      { ...this.props.user.optimizelyUserAttributes }
    );

    const selectedPype = this.props.pypes.find((pype) => (pype.id === pypeId))
    // non-pype id in url ':pypeId' param
    if(!selectedPype) {
      this.props.history.push(`/not-found`)
      return;
    }

    setPype(pypeId)
    // TODO both agents and admins should fetchCustomer but currently agents
    // are not authorized to call GetCustomer api. temp fix bypasses the error but
    // agents won't contain customerName in amplitude
    if(!doesUserHasRole(PYPE_AGENT_ROLES, pypeId)){
      await fetchCustomer(selectedPype.customer_id)
    }
    await this.connect()

    setTimeout(() => {
      this.afterConnect()
      this.setState({ isInitialized: true })
    },500)
  }

  async setUserSession() {
    let rolesInJwt = [];
    const { updateSession, user, updateDeviceIdIfNeeded } = this.props
    const { roles } = user;

    if(!Array.isArray(roles)){
      rolesInJwt = [roles]
    } else {
      rolesInJwt = roles;
    }

    const pypeRoleMap = {}

    rolesInJwt.forEach(roleInJwt => {
      // roleInJwt can contain customer_id (ex: bot managers) or pype_id (agents, pype admins)
      if(roleInJwt.pype_id) {
        pypeRoleMap[roleInJwt.pype_id] = pypeRoleMap[roleInJwt.pype_id] || [];
        // there can be multiple roles for a user in a pype. ex: super_admin + pype_admin
        pypeRoleMap[roleInJwt.pype_id].push(roleInJwt.role_definition.name);
      }
    })

    if(!Object.keys(pypeRoleMap).length) {
      // if non-wpm user redirect them to root url where role-based redirect will happen
      window.location.href = '/'
      return;
    }

    await updateSession({
      roles: pypeRoleMap,
    })

    await updateDeviceIdIfNeeded();
  }

  startHeartbeat(){
    if (this.props.flags.heartbeatThroughWebworker) {
      this.startWebWorkerHeartbeat()
    } else {
      this.startOldWayHeartbeat()
    }
  }
  
  // Old way of starting heartbeat timer
  startOldWayHeartbeat(){
    if(this.heartbeatInterval)
      return;

    const self = this;
    const { isAuthenticated } = this.props;

    const heartbeatReq = () => {
        if(!isAuthenticated) {
          self.stopHeartbeat();
          return;
        }
        this.handleSendHeartbeat()
    };

    heartbeatReq();
    this.heartbeatInterval = setInterval(heartbeatReq, Constant.AGENT_HEARTBEAT_INTERVAL);
  }

  stopHeartbeat(){
    this.stopWebWorkerHeartBeat()
    if(this.heartbeatInterval) {
      clearInterval(this.heartbeatInterval);
      this.heartbeatInterval = null;
    }
  }

  async connect() {
    return new Promise(async (resolve, reject) => {
      const { pype, user, session } = this.props
      const { user_id, email} = user
      const { env } = pype
      const { device_id, roles } = session
      // Declaring first_name and last_name as empty vars
      let first_name = '';
      let last_name = '';
      await Config.setEnvironment(env)

      await SDK.init({
        pype_id: pype.id,
        user_id,
        device_id
      })

      let role;

      if(roles[pype.id].includes('pype_admin')){
        role = 'enterprise-admin' // platform still uses old role name to represent pype_admin
      }
      // PE-5585 - concerns with allowing both admin+agent user
      // if user has both agent and pype_admin defined for same pype,
      // override 'admin' with 'agent' role
      if(roles[pype.id].includes('agent')){
        role = 'agent'
      }

      // Using getUser API to fetch user details and populate first_name and last_name variables for registering agent
      await RESTClient.getUser(env, user_id).then(({ body }) => {
        first_name = body.first_name;
        last_name = body.last_name;
        resolve()
      }).catch((error) => {
        reject(error);
      })

      await agentActions.registerAgent({
        pype_id: pype.id,
        id: user_id,
        role, //values: agent" or "enterprise-admin"
        first_name,
        last_name,
        email
      })

      resolve()
    })
  }

  afterConnect() {
    if (this.props.flags.singleReqInitSeq) {
      this.newOneRequestInitSequence();
    } else {
      this.initSequenceOld();
    }
  }

  initSequenceOld() {
    const {
      fetchStreamsIfNeeded,
      fetchTags,
      fetchPypeMetrics,
      fetchSkills,
      fetchWidgets,
      fetchApi,
      fetchAgent,
      fetchAgentChatSessions,
      fetchAgents,
      pype
     } = this.props

    const isPypeAdmin = doesUserHasRole(PYPE_MANAGER_ROLES, pype.id);
    const isAgent = doesUserHasRole(PYPE_AGENT_ROLES, pype.id);

    if(isAgent) {
      // make sure fetchAgent is the first request sent for agent
      fetchAgent()
      // Needed for unread counts to work.
      fetchAgentChatSessions(true)

      this.startHeartbeat()
    }

    fetchStreamsIfNeeded()
    fetchTags()
    fetchPypeMetrics()
    fetchSkills()

    if(isPypeAdmin) {
      fetchWidgets()
      fetchApi()
      fetchAgents()
    }
  }

  async newOneRequestInitSequence() {
    const { fetchInitSequence, fetchApi, fetchPypeMetrics, pype } = this.props;

    const isAgent = doesUserHasRole(PYPE_AGENT_ROLES, pype.id);
    const isPypeAdmin = doesUserHasRole(PYPE_MANAGER_ROLES, pype.id);

    await fetchInitSequence(isPypeAdmin);
    fetchPypeMetrics()
    if(isPypeAdmin) {
      fetchApi()
    }

    if (isAgent) {
      this.startHeartbeat();
    }
  }

  componentWillReceiveProps(nextProps) {
    const { status, match, isAgentDisconnected, flags: { heartbeatThroughWebworker } } = nextProps
    const { pypeId } = match.params

    if(pypeId !== this.props.match.params.pypeId) {
      const isValidPypeId = nextProps.pypes.find((pype) => (pype.id === pypeId))
      // non-pype id in url ':pypeId' param
      if(!isValidPypeId) {
        this.props.history.push(`/not-found`)
        return;
      }
    }

    if (this.props.flags.heartbeatThroughWebworker !== heartbeatThroughWebworker) {
      this.stopHeartbeat()
      if (heartbeatThroughWebworker) {
        this.startWebWorkerHeartbeat()
      } else {
        this.startOldWayHeartbeat()
      }
    }

    // stop sending hearbeats if agent is offline
    if(status === 'offline' || isAgentDisconnected){
      this.stopHeartbeat();
    }

    // restart heartbeats when agent switches from offline to online
    if(!isAgentDisconnected && status !== this.props.status && this.props.status === 'offline') {
      this.startHeartbeat();
    }

    if (nextProps.transferNotificationDetail.timestamp !== this.props.transferNotificationDetail.timestamp) {
      const {assignor, consumerName} = nextProps.transferNotificationDetail;
      window.appCreateToast({
        message: `<span><strong>${assignor}</strong> assigned a chat to you with <strong>${consumerName}</strong></span>`,
        level: 'info',
      })
    }
  }

  renderContent() {
    const { match, pype, isAuthenticated } = this.props
    const { isInitialized } = this.state
    const isPypeAdmin = doesUserHasRole(PYPE_MANAGER_ROLES, pype.id);
    const isAgent = doesUserHasRole(PYPE_AGENT_ROLES, pype.id);
    const isPypeSet = !!pype.id

    return (
      <div className="full-height">
        <Notifications />
        {isInitialized ?
          <Content>
            {
              !isPypeSet && <Redirect to="/start"/>
            }
            <DashboardRoute
              exact
              path={`${match.path}/overview`}
              component={Overview}
              isAuthenticated={isAuthenticated}
              isAllowed={isPypeAdmin || isAgent}
            />

            {/* <DashboardRoute
              path={`${match.path}/broadcasts`}
              component={Campaigns}
              isAuthenticated={isAuthenticated}
              isAllowed={isPypeAdmin}
            /> */}

            <DashboardRoute
              path={`${match.path}/chats`}
              component={Conversations}
              isAuthenticated={isAuthenticated}
              isAllowed={isAgent || isPypeAdmin}
            />

            <DashboardRoute
              path={`${match.path}/admin`}
              component={admin.Admin}
              isAuthenticated={isAuthenticated}
              isAllowed={isPypeAdmin}
            />
          </Content> : this.props.flags && this.props.flags.brandInfo && <div className="pypestream-loader"><PypestreamLoader logo={this.props.flags.brandInfo.loadingIcon} loaderHeight={this.props.flags.brandInfo.loaderHeight} loaderWidth={this.props.flags.brandInfo.loaderWidth}/></div>}
        <Toast ref="toast" allowHTML={true} />
        <AppTooltipHost></AppTooltipHost>
      </div>
    );
  }

  render() {
    return (
      <div className="wrapper">
        {this.renderContent()}
        <FlashMessage />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  setPype: (id) => dispatch(pypeActions.setPype(id)),
  setBrowserStatusOffline: (status) => dispatch(agentActions.setBrowserStatusOffline(status)),
  fetchCustomer: (env, customerId) => dispatch(pypeActions.fetchCustomer(env, customerId)),
  fetchPypes: () => dispatch(pypesActions.fetchPypes()),
  updateSession: (data) => dispatch(sessionActions.updateSession(data)),
  fetchStreamsIfNeeded: () => dispatch(streamActions.fetchStreamsIfNeeded()),
  fetchTags: () => dispatch(tagActions.fetchTags()),
  fetchPypeMetrics: () => dispatch(pypeActions.fetchPypeMetrics()),
  fetchSkills: () => dispatch(skillsActions.fetchSkills()),
  fetchWidgets: () => dispatch(widgetsActions.fetchWidgets()),
  fetchApi: () => dispatch(apiActions.fetchApi()),
  fetchAgent: () => dispatch(agentActions.fetchAgent()),
  fetchAgentChatSessions: (flag) => dispatch(agentChatSessionsActions.fetchAgentChatSessions(flag)),
  updateDeviceIdIfNeeded: () => dispatch(sessionActions.updateDeviceIdIfNeeded()),
  sendHeartbeat: (data) => dispatch(heartbeatActions.sendHeartbeat(data)),
  fetchAgents: () => dispatch(agentsActions.fetchAgents()),
  fetchInitSequence: (isPypeAdmin) => dispatch(fetchInitSequence(isPypeAdmin)),
});

const mapStateToProps = state => ({
    isAuthenticated: state.botUserSession.isAuthenticated,
    hasPypeFetched: state.pype.hasFetched,
    user: state.botUserSession.user,
    pype: state.pype.details,
    session: state.session,
    pypes: state.pypes.pypes,
    status: utils.agent.getStatus(state.agent),
    isAgentDisconnected: state.heartbeats.isAgentDisconnected,
    transferNotificationDetail: state.transfers.transferNotification,
});

const MainWithFeatureFlags = withLDConsumer()(Main)

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(MainWithFeatureFlags));
