import style from './style.css'
import React, {Component, PropTypes} from 'react'
import classNames from 'classnames'
import Popup from './Popup'

class EndChatButton extends React.Component {

  state = {
    isOpen: false,
  }

  handleClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    this.setState({isOpen: !this.state.isOpen})
  }

  handleClose = () => {
    this.setState({isOpen: false})
  }

  render () {
    const {props, state} = this
    const className = classNames(style.EndChatButton, props.className)
    return (
      <div className={className}>
        <button className={classNames(style.Button,style.ToggleButton)} onClick={this.handleClick}>End chat</button>
        {state.isOpen && (
          <Popup
            onClose={this.handleClose}>
          </Popup>
        )}
      </div>
    )
  }

}

export default  EndChatButton
