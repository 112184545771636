import { store } from './store';

const responses = new Map();

const ResponseQueue = {
	queue(id, resolve, reject, payload){
		responses.set(id, {resolve, reject, payload});
	},
	dequeue(id){
		let res = responses.get(id);
		responses.delete(id);
		return res;
	},
	isQueued(id){
		return responses.has(id);
	},
	isAckResponse(id){
		let res = responses.get(id);
		return !!res.ack_response;
	},
	getQueuedRequests(){
		return responses;
	}


}

store.subscribe(function(){
  var { lastAction } = store.getState();

	const {ack} = lastAction
	if(ack){
		const { correlation_id, details, status } = ack;
	  if(details === "expired_session"){
			return;
		}
	}

  let lType = lastAction.type;
  let reqAction = (lType.indexOf('_RES') > -1) ? lType.slice(0, lType.lastIndexOf('_RES')) : lType;

  if(lastAction.type == "RECEIVED_ACK" &&
   	ResponseQueue.isQueued(lastAction.ack.correlation_id)) {

	  let isAckResponse = ResponseQueue.isAckResponse(lastAction.ack.correlation_id);

  	// ack_response : return ack as response if a response is not returned from STOMP
  	if(lastAction.ack.details != "ok" || isAckResponse){
  		let response = ResponseQueue.dequeue(lastAction.ack.correlation_id);
  		isAckResponse ? response.resolve(lastAction.ack) : response.reject(lastAction.ack);
  	}

		//if the response is okay, then dequeue the associated action for the ack
		if(lastAction.ack.details === "ok") {
			let response = ResponseQueue.dequeue(lastAction.ack.correlation_id);
	  	response.resolve(lastAction);
		}
  }
  if(lastAction.type != "RECEIVED_ACK" && ResponseQueue.isQueued(reqAction)){
  	let response = ResponseQueue.dequeue(reqAction);
  	response.resolve(lastAction);
  }
})


export default ResponseQueue;
