import { BOT_USER_SESSION_LOGOUT } from "bot-user-session";
import {
  REQUEST_AUTORESPONDERS, RECEIVE_AUTORESPONDERS,
  UPDATE_AUTORESPONDERS, UPDATED_AUTORESPONDERS,
} from "../constants"

const getInitialState = () => ({
  isFetching: false,
  didInvalidate: false,
  autoresponses: []
});

export default function list(state = getInitialState(), action) {
  switch (action.type) {
    case BOT_USER_SESSION_LOGOUT: {
      return getInitialState();
    }
    case REQUEST_AUTORESPONDERS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      })
    case RECEIVE_AUTORESPONDERS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        autoresponses: action.autoresponses
      })
    case UPDATE_AUTORESPONDERS:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case UPDATED_AUTORESPONDERS:
      return Object.assign({}, state, {
        isFetching: false,
      })
    default:
      return state
  }
}
