import style from './style.css';
import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import WidgetItem from '../WidgetItem';
import CreateWidget from '../CreateWidget';

class WidgetList extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      openWidget: null,
      editingWidget: null,
    }
  }

  componentWillMount () {
    this.props.fetchWidgets();
  }
  componentWillReceiveProps (nextProps) {
    if (nextProps.widgetsStore.length < this.props.widgetsStore.length) {
      this.setState({
        openWidget: null,
        editingWidget: null,
      })
    }
  }

  handleToggle = (widget) => {
    if (this.state.openWidget === widget) {
      this.setState({openWidget: null})
    } else {
      this.setState({
        openWidget: widget,
        editingWidget: null,
      })
    }
  }

  handleClickEdit = (widget) => {
    this.setState({
      openWidget: null,
      editingWidget: widget.id,
    })
  }

  handleCloseEdit = (widget) => {
    this.setState({editingWidget: null})
  }

  render () {
    const {props, state} = this
    const { widgetsStore } = props
    const widgets = _.sortBy(_.values(widgetsStore), 'creation_date')

    return (
      <div className={style.WidgetList}>
        <div className={style.Content}>
          {widgets.map((widget) => (
            <WidgetItem
              key={widget.id}
              widget={widget}
              keepOpen={widgets.length === 1}
              isOpen={widget.id === state.openWidget}
              isEditing={widget.id === state.editingWidget}
              isEditMode={state.editingWidget != null}
              shouldMask={state.editingWidget != null && widget.id !== state.editingWidget}
              onToggle={this.handleToggle}
              onClickEdit={this.handleClickEdit}
              onCloseEdit={this.handleCloseEdit} />
          ))}
        </div>
        <CreateWidget className={style.CreateWidget} shouldMask={state.editingWidget != null} />
      </div>
    )
  }

}

WidgetList.propTypes = {
  widgetsStore: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default  WidgetList
