import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { 
  Pype,
  PypeLogo,
  PypeName,
  PypeEnv
 } from './helpers';
import { getLabelForEnv } from 'utils/environments';

const PypesListItem = ({logo, name, env, showArrow, ...rest}) => {
  const label = getLabelForEnv(env)

  return (
    <Pype {...rest}>
      <div className="left">
        <PypeLogo src={logo}></PypeLogo>
      </div>
      <div className="center">
        <PypeName>{name}</PypeName>
        <PypeEnv
          textColor={label.text}
          borderColor={label.border}
          fillColor={label.fill}>{label.label}</PypeEnv>
      </div>
      {showArrow ? 
      <div className="right">
        <FontAwesomeIcon icon="angle-right" color="#2e7de1" />
      </div> : null}
    </Pype>)
}

PypesListItem.defaultProps = {
  showArrow: true
};

PypesListItem.propTypes = {
  logo: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  env: PropTypes.string.isRequired,
  showArrow: PropTypes.bool
};

export default PypesListItem;
