import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { TextField, Button } from "ui-elements";
import { Box, Divider, Grid, Typography, Chip } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import DoneIcon from '@material-ui/icons/Done';
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import PropTypes from "prop-types";
import sdk from "sdk";

import { ReactComponent as CloseIcon } from "img/icons/close-rounded.svg";
import { ReactComponent as TransferIcon } from "img/icons/transfer.svg";
import SearchIcon from '@material-ui/icons/Search';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import chatsUtils from "utils/chats";
import SystemMessage from "./conversation-history/SystemMessage";
import { withLDConsumer } from "launchdarkly-react-client-sdk";

// Pypestream
const { tagActions, conversationsActions, agentsActions, conversationHistoryActions, agentChatSessionsActions, skillsActions } = sdk;
const { getChatEndedUser, chatEndedUsers } = chatsUtils;

const getMessage = (session, skillName, flagHideEndedBy) => {
  if (!session || flagHideEndedBy) {
    return "Conversation ended";
  }
  const { agent_name, consumer_name, end_comment, ended_by, participants } = session;

  const user = getChatEndedUser(end_comment, ended_by, participants.agent);
  let endedBy;
  if (user === chatEndedUsers.OTHER) {
    endedBy = "connection error";
  } else if (user === chatEndedUsers.AGENT) {
    endedBy = `${agent_name} (${skillName})`;
  } else {
    endedBy = consumer_name;
  }
  return `Conversation ended by ${endedBy}`;
};

const getTagsSortedByCode = (tagsStore = []) => {
  return _.sortBy(_.values(tagsStore), "code");
};

const renderOptions = (tagsStore = [], tagShortcutsEnabled = false) => {
  return getTagsSortedByCode(tagsStore).map((tag) => {
    const label =
      tagShortcutsEnabled && !_.isNil(tag.code) ? tag.code + " - " : "";
    const newOptions = {
      value: tag.id,
      label: label + tag.description,
    };
    return newOptions;
  });
};

const useStyles = makeStyles({
  instigateAction: {
    color: '#29302e',
    fontWeight: 600
  },
  tagChip: {
    color: '#716f6f',
    backgroundColor: "#ffffff",
    marginRight: 8,
    marginBottom: 8,
    fontSize: 14,
    fontWeight: 600,
  },
  activeChips: {
    textTransform: 'capitalize',
    backgroundColor: '#cbdffb',
    lineHeight: 1.43,
    color: '#0a3ab4',
    fontSize: 14,
    fontWeight: 600,
    marginLeft: 8,
    marginBottom: 8,
  },
  inputField: {
    textTransform: 'none',
  },
  checkIcon: {
    color: "#0a3ab4"
  },
  notSelected: {
    color: "transparent"
  },
  option: {
    backgroundColor: "#ffffff !important",
    "&:hover": {
      backgroundColor: "#f9f9f9 !important"
    }
  },
  root: {
    "& .MuiInputBase-root": {
      height: 'auto !important',
    },
    '& .MuiFormLabel-root':{
      fontSize: '1.2rem !important'
    }
  },
  submitButton: {
    marginRight: 0,
  },
  downArrow: {
    width: 24,
    height: 25,
    color: '#bbb'
  },
  status: {
    width: 40,
    height: 16,
    padding: '1 8 3',
    borderRadius: 8,
    fontFamily: 'Open Sans',
    fontSize: 10,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.2,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#ffffff',
    backgroundColor: '#e45f4f'
  },
  statusAway: {
    color: '#29302e',
    backgroundColor: '#f1b617'
  }
});

const ANY_AGENT_VALUE = 'any';
const CONNECTION_ERROR_COMMENT = 'Ended as anonymous webchat user stopped heartbeating';

const EndConversationComment = ({
  afterChatClassification,
  allowMultipleTags,
  chat_id,
  commentEnabled,
  commentRequired,
  endedChatForClassification,
  clearAgentChatSession,
  fetchTags,
  fetchSkills,
  fetchAgents,
  isFetching,
  onClose,
  onSubmit,
  session,
  tagsEnabled,
  tagsRequired,
  tagShortcutsEnabled,
  tagsStore,
  skillName,
  isActiveTab,
  skillId,
  isTransferFormOpen,
  closeTransferForm,
  skillsStore,
  agentsStore,
  consumerName,
  userId,
  transferChat,
  history,
  match,
  isArchived,
  archivedChatTags,
  archivedComment,
  flags,
}) => {

  const styles = useStyles();
  const chatIdForClassification = endedChatForClassification || chat_id;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [comment, setComment] = useState("");
  const [tagList, setTagList] = useState([]);
  const [tagOptions, setTagOptions] = useState(renderOptions(tagsStore, tagShortcutsEnabled));
  const [agentPopupOpen, setAgentPopupOpen] = useState(false);
  const [selectedSkill, setSelectedSkill] = useState(
    flags.pe18410 ?
      skillsStore[0] :
      skillsStore.find(obj => {
        return obj.id === skillId
      })
  );
  const [selectedAgent, setSelectedAgent] = useState({
    value: ANY_AGENT_VALUE,
    label: 'Any agent',
  });
  const [agentsList, setAgentsList] = useState(agentsStore);
  const [skillList, setSkillList] = useState([]);

  const setAgentList = () => {
    let agentsForSkill = agentsStore.filter(agent => agent.status !== "offline" && selectedSkill && agent.skills.includes(selectedSkill.id) && agent.id !== userId).map((agent) => {
      return {
        value: agent.id,
        label: agent.first_name + ' ' + agent.last_name,
        sessions: agent.active_sessions,
        status: agent.status
      }
    });
    if (agentsForSkill) {
      agentsForSkill.unshift({
        value: ANY_AGENT_VALUE,
        label: 'Any agent',
      });
      setAgentsList(agentsForSkill);
    }
  }

  // Fetch tags
  useEffect(() => {
    if (session) {
      fetchTags();
    }
    if (isTransferFormOpen) {
      fetchAgents();
      fetchSkills();
    }
  }, []);

  useEffect(() => {
    setTagOptions(renderOptions(tagsStore, tagShortcutsEnabled));
  }, [tagsStore, tagList]);

  useEffect(() => {
    setAgentList();
  }, [agentsStore]);

  useEffect(() => {
    setAgentList();
  }, [selectedSkill])

  const onCommentSkip = () => {
    onSubmit({
      chat_id,
      user_cancel: true,
    });
    afterChatClassification();
    onClose(chatIdForClassification);
  };

  const onCommentSubmit = () => {
    let data = { chat_id: chatIdForClassification };

    if (commentEnabled && !!comment.length) data.end_comment = comment;
    if (tagsEnabled) data.end_tags = Array.isArray(tagList) ? tagList.map(tag => tag.value) : [tagList.value];
    setIsSubmitting(true);

    const onSuccess = () => {
      window.appCreateToast({
        message: "Chat status log created successfully.",
        level: "success",
      });
      afterChatClassification();
      onClose(chatIdForClassification);
    };

    const onError = (error) => {
      console.error(error);
      window.appCreateToast({
        message: "Chat status log could not be created.",
        level: "error",
      });
      afterChatClassification();
      setIsSubmitting(false);
      onClose(chatIdForClassification);
    };

    return onSubmit(data).then(onSuccess, onError);
  };

  const onTransferSubmit = () => {
    const to_agent = selectedAgent.value === ANY_AGENT_VALUE ? null : selectedAgent.value;
    const data = {
      chat_id: chat_id,
      to_agent,
      skill: selectedSkill.id,
      comment: comment ? comment : null
    };
    const message = `<strong>${consumerName}</strong> was successfully returned to the <strong>${skillName}</strong> queue`;

    const onSuccess = () => {
      window.appCreateToast({
        message,
        level: 'success'
      });
      clearAgentChatSession(chat_id);
      history.push(`/${match.params.pypeId}/chats`);
    };

    const onError = (error) => {
      window.appCreateToast({
        message: `Chat reassignment to <strong>${selectedAgent.value}</strong>  failed. <strong>Try another agent.</strong>`,
        level: 'error'
      });
    };

    return transferChat(data).then(onSuccess, onError);
  }

  const isFormValid = () => {
    if(flags.pe18162){
      if (commentEnabled && !comment) {
        return false;
      }
    } else {
      if (commentEnabled && commentRequired && !comment) {
        return false;
      }
    }

    if (
      tagsEnabled &&
      tagList.length === 0 &&
      (tagsRequired || !commentEnabled)
    ) {
      return false;
    }

    return true;
  };

  const notDisabledSkills = skillsStore.filter(option => agentsStore.find(agent => agent.status !== "offline" && agent.status !== "away" && agent.skills.includes(option.id) && agent.id !== userId))
  const disabledSkills = skillsStore.filter(option => !agentsStore.find(agent => agent.status !== "offline" && agent.status !== "away" && agent.skills.includes(option.id) && agent.id !== userId))
  let skillOptions = notDisabledSkills;
  if(disabledSkills.length){
    skillOptions = [...skillOptions, { isDivider: true }, ...disabledSkills]
  }
  const transferFormLayoutProps = {
    xs: 12,
    sm: 6,
    m: 8,
    ...(flags.pe18411 ? {
      sm: 12,
      md: 6,
      m: undefined,
      xs: undefined,
    } : {})
  }

  return (
    <Box m={2} p={2} style={{ backgroundColor: "#f4f6ff" }} >
      <SystemMessage
        message={isTransferFormOpen ? 'Conversation transfer' : getMessage(session, skillName, flags.hideEndedBy)}
        Icon={isTransferFormOpen ? TransferIcon : CloseIcon}
        startAt={session && !isTransferFormOpen ? isActiveTab ? session.agent_assign_ts : session.end_ts : isTransferFormOpen && isActiveTab ? Date.now() : null}
        padding={0}
        margin={0}
      />
      {(((isActiveTab && session && (commentEnabled || tagsEnabled)) || (isArchived && !session && ((archivedChatTags && archivedChatTags.length > 0) || (archivedComment && archivedComment !== CONNECTION_ERROR_COMMENT))) || (session && session.ended_by !== "system" && session.end_tags.length > 0 && session.end_comment !== CONNECTION_ERROR_COMMENT)) || (isTransferFormOpen)) &&
        <React.Fragment>
          <Box my={2}>
            <Divider style={{ background: "#DDD" }} />
          </Box>
          {isActiveTab ?
            <React.Fragment>
              <Grid
                container
                direction={
                  commentRequired && !tagsRequired && !isTransferFormOpen ? "column-reverse" : "column"
                }
              >
                {tagsEnabled && !isTransferFormOpen && (
                  <Grid item>
                    <Box py={2}>
                      <Box mb={-1}>
                        <Typography variant="h5" className={styles.instigateAction}>
                          Classify {tagsRequired ? "" : " (Optional)"}
                        </Typography>
                      </Box>
                      <Autocomplete
                        multiple={allowMultipleTags}
                        popupIcon={""}
                        disableClearable
                        tagSizeSmall
                        classes={{
                          option: styles.option,
                          root: styles.root
                        }}
                        onChange={(e, data) =>
                          setTagList(data)
                        }
                        options={tagOptions}
                        getOptionLabel={option => option.label}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              className={styles.activeChips}
                              label={option.label}
                            />
                          ))
                        }
                        getOptionSelected={(option, value) => option.value === value.value}
                        renderOption={(option, { selected }) => (
                          <React.Fragment>
                            <Box style={{ display: "flex" }}>
                              <Grid
                                container
                                direction="row"
                                alignItems="center"
                                spacing={1}
                              >
                                <Grid item>
                                  <Grid container alignItems="center">
                                    &nbsp;
                                    <DoneIcon className={selected ? styles.checkIcon : styles.notSelected} />
                                  </Grid>
                                </Grid>
                                <Grid item>
                                  {option.label}
                                </Grid>
                              </Grid>
                            </Box>
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            placeholder={tagList.length === 0 ? "Search" : ""}
                            {...params}
                            margin="normal" variant="outlined" />
                        )}
                      />
                    </Box>
                  </Grid>
                )}

                {isTransferFormOpen && (
                  <Grid container direction="row" justify="space-between" spacing={2}>
                    <Grid item {...transferFormLayoutProps}>
                      <Autocomplete
                          classes={{
                            option: styles.option,
                            root: styles.root
                          }}
                        value={selectedSkill}
                        popupIcon={<KeyboardArrowDownIcon className={styles.downArrow} />}
                        options={skillOptions}
                        renderOption={(option) => {
                          if (!flags.pe18388) {
                            return option.name;
                          }
                          if(option.isDivider){
                            return <Divider style={{ background: "#ddd", width:'100%' }} />;
                          }
                          return option.name;
                        }}
                        getOptionLabel={option => option.name ? option.name : ''}
                        getOptionSelected={(option, value) => option.id === value.id}
                        getOptionDisabled={option => !agentsStore.find(agent => agent.status !== "offline" && agent.status !== "away" && agent.skills.includes(option.id) && agent.id !== userId)}
                        onChange={(e, newSkill) => { setSelectedSkill(newSkill) }}
                        disableClearable

                        renderInput={(params) => (
                          <TextField {...params} label="Skills" margin="normal" variant="outlined" />
                        )}
                      />
                    </Grid>
                    <Grid item {...transferFormLayoutProps}>
                      <Autocomplete
                        classes={{
                          option: styles.option,
                          root: styles.root
                        }}
                        value={selectedAgent}
                        options={agentsList}
                        popupIcon={<KeyboardArrowDownIcon className={styles.downArrow} />}
                        onOpen={() => setAgentPopupOpen(true)}
                        onClose={() => setAgentPopupOpen(false)}
                        getOptionLabel={option => option.label}
                        getOptionSelected={(option, value) => option.value === value.value}
                        noOptionsText={"Queue"}
                        getOptionDisabled={option => option.status !== "online" && option.value !== ANY_AGENT_VALUE}
                        onChange={(e, newAgent) => { setSelectedAgent(newAgent) }}
                        disableClearable
                        renderOption={(option, { selected }) => {
                          let optionHelperLabel = '';
                          if(option.value === ANY_AGENT_VALUE){
                            const agentCount = agentsList.filter(agent => agent.status === "online").length
                            if(agentCount === 0) {
                              optionHelperLabel = "Queue 0 available";
                            } else{
                              optionHelperLabel = `${agentCount} available`;
                            }

                          }
                          return (
                              <React.Fragment>
                                <Box style={{ display: "flex" }}>
                                  <Grid
                                      container
                                      direction="row"
                                      alignItems="center"
                                      spacing={1}
                                  >
                                    <Grid item>
                                      {option.label} <span style={{color:'#716f6f'}}>{optionHelperLabel}</span>
                                    </Grid>
                                    {option.status && option.status !== "online" &&
                                        <Grid item>
                                          <Box className={`${styles.status} ${option.status === "away" && styles.statusAway}`}>{option.status}</Box>
                                        </Grid>
                                    }
                                  </Grid>
                                </Box>
                              </React.Fragment>
                          )
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="Agents" margin="normal" variant="outlined" />
                        )}
                      />
                    </Grid>

                  </Grid>
                )}

                {(commentEnabled || isTransferFormOpen) && (
                  <Grid item>
                    <Box py={2}>
                      <Box mb={1}>
                        <Typography variant="h5" className={styles.instigateAction}>
                          Comment {commentRequired && !isTransferFormOpen ? "" : " (Optional)"}
                        </Typography>
                      </Box>
                      <TextField
                        inputProps={{ style: { padding: "9.5px 4px 9.5px 6px" } }}
                        variant="outlined"
                        placeholder="Leave your comment here..."
                        multiline
                        rowsMax={5}
                        onChange={(e) =>
                          setComment(e.target.value ? e.target.value.trim() : "")
                        }
                      />
                    </Box>
                  </Grid>
                )}
              </Grid>
              <Box mt={1}>
                <Grid container justify="flex-end" spacing={flags.pe18388 ? 0 : 1}>
                  {(isTransferFormOpen || ((!commentEnabled || !commentRequired) &&
                    (!tagsEnabled || !tagsRequired))) && (
                      <Grid item>
                        <Button hollow color="primary" onClick={isTransferFormOpen ? closeTransferForm : onCommentSkip}>
                          {isTransferFormOpen ? 'Cancel Transfer' : 'Skip'}
                        </Button>
                      </Grid>
                    )}
                  <Grid item>
                    <Button className={styles.submitButton}
                      variant="contained"
                      color="primary"
                      onClick={isTransferFormOpen ? onTransferSubmit : onCommentSubmit}
                      disabled={!isTransferFormOpen && (isSubmitting || !isFormValid())}
                    >
                      {isTransferFormOpen ? 'Transfer' : 'Submit'}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </React.Fragment>
            :
            (((archivedChatTags && archivedChatTags.length > 0) || archivedComment)) &&
            <React.Fragment>
              <Grid
                container
                direction="column"
              >
                {(archivedChatTags && archivedChatTags.length > 0) &&
                  (
                    <Grid item style={{ marginBottom: -8, paddingBottom: 0 }}>
                      <Box py={0}>
                        <Box mb={1}>
                          <Typography variant="h5" className={styles.instigateAction}>
                            Classification
                          </Typography>
                        </Box>
                        {archivedChatTags && (archivedChatTags.map((endTag, index) =>
                          <Chip
                            key={index}
                            className={styles.tagChip}
                            label={endTag.split("::")[0] ? endTag.split("::").join(" - ") : endTag.split("::")[1]}
                          />)
                        )}
                      </Box>
                    </Grid>
                  )}
                {(archivedComment && archivedComment !== CONNECTION_ERROR_COMMENT) && (
                  <Grid item>
                    <Box py={1}>
                      <Box mb={1}>
                        <Typography variant="h5" className={styles.instigateAction}>
                          Comment
                        </Typography>
                      </Box>
                      <Typography>
                        {archivedComment ? archivedComment : null}
                      </Typography>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </React.Fragment>
          }
        </React.Fragment>}
    </Box>
  );
}

EndConversationComment.propTypes = {
  chat_id: PropTypes.string.isRequired,

  // All the tags created by the admin
  tagsStore: PropTypes.array.isRequired,

  // Comment config
  commentEnabled: PropTypes.bool.isRequired,
  commentRequired: PropTypes.bool.isRequired,

  // Tags config
  tagsEnabled: PropTypes.bool.isRequired,
  tagsRequired: PropTypes.bool.isRequired,
  allowMultipleTags: PropTypes.bool.isRequired,
  tagShortcutsEnabled: PropTypes.bool.isRequired,

  // Callbacks
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

EndConversationComment.defaultProps = {
  tagsStore: [],
  // Comment config
  commentEnabled: false,
  commentRequired: false,

  // Tags config
  tagsEnabled: false,
  tagsRequired: false,
  allowMultipleTags: false,
  tagShortcutsEnabled: false,
};

const mapStateToProps = (state) => {
  const { details } = state.pype;

  return {
    isFetching: state.tags.isFetching,
    tagsStore: state.tags.store,
    commentEnabled: details.end_comment_display,
    commentRequired: details.end_comment_required,
    tagsEnabled: details.end_tags_display,
    tagsRequired: details.end_tags_required,
    allowMultipleTags: details.tags_multiple,
    tagShortcutsEnabled: details.tags_shortcuts,
    endedChatForClassification:
      state.allConversations.endedChatForClassification,
    skillsStore: state.skills.skillsStore,
    agentsStore: state.agentsForPype.agents,
    userId: state.botUserSession.user.user_id
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  fetchTags() {
    dispatch(tagActions.fetchTags());
  },

  onSubmit(data) {
    return dispatch(conversationsActions.classifyChat(data));
  },

  afterChatClassification() {
    return dispatch(
      conversationsActions.recentlyEndedChatForClassification(null)
    );
  },

  fetchAgents() {
    return dispatch(
      agentsActions.fetchAgents()
    );
  },

  fetchSkills() {
    return dispatch(
      skillsActions.fetchSkills()
    );
  },

  transferChat(data) {
    return dispatch(
      conversationHistoryActions.transferChat(data)
    );
  },
  clearAgentChatSession(chat_id) {
    return dispatch(
      agentChatSessionsActions.removeChatSession(chat_id)
    );
  }
});
export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withLDConsumer()(EndConversationComment)));
