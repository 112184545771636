import React from 'react';
import PropTypes from 'prop-types'

import style from './style.css';
import AgentsListItem from '../AgentsListItem';

const Component = (props) => {
  return (
    <section>
      {props.title ? (
        <div className={`${style.header}`}>
          <p className={"label-text text-uppercase m-b-5"}>{props.title}</p>
        </div>
      ) : null}
      <div className={style.agentsList}>
        {
          props.list.map((agent) => {
            return (
              <AgentsListItem
              key={agent.id}
              agent={agent}
              highlight={props.highlight}
              fadeUnselected={props.fadeUnselected}
              onToggle={props.onToggle}/>
            )
          })
        }
      </div>
    </section>
  )
}

Component.propType = {
  title: PropTypes.string,
  onToggle:PropTypes.func.isRequired,
  list:PropTypes.array,
  highlight: PropTypes.string,
  fadeUnselected: PropTypes.bool
}

Component.defaultProps = {
}

export default Component
