import moment from 'moment';

const constructHistory = (participantHistory) => {
  let transferList = [];
  if (participantHistory.length > 3) {
    for(let i = participantHistory.length - 3; i > 0; i--) {
      const currentHistory = participantHistory[i];
      const nextHistory = participantHistory[i + 1];
      const prevHistory = participantHistory[i - 1];

      if(!(i === 0 && currentHistory.action === 'deleted')) {
        if (currentHistory.action === 'deleted') {
          switch(currentHistory.delete_reason) {
            case 'agent_to_agent_transfer':
              const transfer = {};
              transfer.assignor = nextHistory.participant_type === 'bot' ? nextHistory.user_id : nextHistory.agent_name;
              transfer.assignee = prevHistory.participant_type === 'bot' ? prevHistory.user_id : prevHistory.agent_name;
              transfer.reason = prevHistory.participant_type === 'agent' ? 'transfer' : 'escalation';
              transfer.time = moment(prevHistory.time).format('h:mm a');
              transfer.timestamp = prevHistory.time;
              transfer.skill_name = currentHistory.skill === 'general' ? "General" : currentHistory.skill_name;
              transfer.comment = currentHistory.comment ? currentHistory.comment : '';
              transferList.push(transfer);
              break;
            case 'bot_over':
            case 'agent_went_offline':
            case 'agent_added_skill':
              const transferOne = {};
              const transferTwo = {};
              transferOne.assignor = nextHistory.participant_type === 'bot' ? nextHistory.user_id : nextHistory.agent_name;
              transferOne.assignee = currentHistory.skill_name ? currentHistory.skill === 'general' ? 'General queue' : `${currentHistory.skill_name} queue` : 'General queue';
              transferOne.time = moment(currentHistory.time).format('h:mm a');
              transferOne.timestamp = currentHistory.time;
              transferOne.comment = currentHistory.comment ? currentHistory.comment : '';
              transferList.push(transferOne);
              transferTwo.assignor = currentHistory.skill_name ? currentHistory.skill === 'general' ? 'General queue' : `${currentHistory.skill_name} queue` : 'General queue';
              let queuedHistory = prevHistory.action === "queued" ? participantHistory[i - 2] : prevHistory;
              if(queuedHistory)  {
                transferTwo.assignee = queuedHistory.participant_type === 'bot' ? queuedHistory.user_id : queuedHistory.agent_name ;
              } else {
                transferTwo.assignee = "endedByQueue";
                queuedHistory = prevHistory;
              }
              transferTwo.time = moment(queuedHistory.time).format('h:mm a');
              transferTwo.timestamp = queuedHistory.time;
              transferTwo.comment = currentHistory.comment ? currentHistory.comment : '';
              transferTwo.reason = currentHistory.delete_reason === 'bot_over' && prevHistory.participant_type === 'agent' ? 'escalation' : 'transfer';
              transferList.push(transferTwo);
              break;
            default:

          }
        }
      }
    }
  }
  return transferList;
}
export default constructHistory
