import { combineReducers } from 'redux'
import { botUserSessionReducer } from 'bot-user-session';
import streamsForPype from './streams'
import allConversations from './conversations'
import conversationHistory from './conversationHistory'
import campaignsForPype from './campaigns'
import pype from './pype'
import users from './users'
import response from './response'
import agentsForPype from './agents'
import autoresponder from './autoresponder'
import agentChatSessions from './agentChatSessions'
import chatSessionsWithConsumer from './chatSessionsWithConsumer'
import tags from './tags'
import agent from './agent'
import botsForPype from './bots'
import widgets from './widgets'
import apis from './api'
import transfers from './transfers'
import skills from './skills'
import cannedResponses from './cannedResponses'
import pypes from './pypes'
import session from './session'
import heartbeats from './heartbeats'
import featureFlags from 'utils/featureFlags'

function lastAction(state = null, action) {
  return action;
}

const rootReducer = combineReducers({
  streamsForPype,
  allConversations,
  conversationHistory,
  campaignsForPype,
  pype,
  users,
  lastAction,
  response,
  agentsForPype,
  autoresponder,
  agentChatSessions: agentChatSessions,
  chatSessionsWithConsumer,
  tags,
  agent,
  botsForPype,
  widgets,
  apis,
  transfers,
  skills,
  cannedResponses,
  botUserSession: botUserSessionReducer,
  pypes,
  session,
  heartbeats
});

export default rootReducer
