import React from 'react';
import Spinner from 'components/ui/pype-spinner/PypeSpinner';
import CampaignListItem from './CampaignListItem';

// With react-router-4 scroll-restoration is no longer provided out-of-the-box 
// https://reacttraining.com/react-router/web/guides/scroll-restoration
// therefore a workaround is implemented to auto-scroll to last clicked element
// https://stackoverflow.com/questions/51906708/react-router-v4-keep-scrolling-position-when-switching-components
var clickedId = 0
class CampaignList extends React.Component{
  componentWillReceiveProps (nextProps) {
    this.setState({
      isLoading: nextProps.isFetching
    })
  }

  componentDidMount() {
    const item = document.querySelector(
      ".restore-" + clickedId
    );
    if (item && window.innerHeight < item.getBoundingClientRect().bottom) {
      item.scrollIntoView();
    }
  }

  handleClick = (id) => {
    clickedId = id
  }

  renderZeroState() {
    const {pype} = this.props;

    if(this.props.isFetching) {
      return <Spinner offsetTop={100}/>
    }
    return (
      <div className="empty-list-content">
        <h4>No broadcasts</h4>
        <p>Share updates and more about {pype.name} to connected users on Pypestream.</p>
      </div>
    );
  }

  renderList() {
    return <div
        ref="infinitescroll"
        className="infinite-scroll"
      >
        {this.props.campaigns.map((campaign) => (
          <CampaignListItem onLinkClick={this.handleClick} campaign={campaign} key={campaign.id} streams={this.props.streams}/>
        ))}
      </div>
  }

  render() {
    const {campaigns} = this.props;
    return (
      <div className="full-height">
        <div className="list recent-campaigns-list list-unstyled">
          { 
            !campaigns.length ? this.renderZeroState() : this.renderList()
          }
        </div>
      </div>
    );
  }
};

export default  CampaignList;
