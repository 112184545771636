import React from 'react';
import cx from 'classnames'
import ReactTooltip from 'react-tooltip';
import css from './AppTooltip.css';
import assets from 'utils/assets';
import Constant from 'core/constant';

var showDelay = 1000;

class AppTooltip extends React.Component{
  
  static defaultProps = {
      inverted: false,
      effect: 'solid',
      delayHide: 0,
      display: 'inline-block',
      host: 'appTooltipHost',
      delayShow: 0,
      onClick() {},
      className: '',
      style: {},
  }
  componentDidMount(){
    ReactTooltip.rebuild()
  }
  componentDidUpdate(){
    ReactTooltip.rebuild()
  }
  // NOTE: force destroy tooltip if the target component gets destroyed
  // https://github.com/wwayne/react-tooltip/issues/31
  destroy(){
    ReactTooltip.hide();
  }
  render () {
    var defaultTooltip = <img
      src={assets.path(`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/information-icon${this.props.inverted ? "-inverted" : ""}.svg`)}
      className={css.informationIcon} />
    var style = Object.assign(
      {},
      {display: this.props.display},
      this.props.style,
    )
    return (
      <div className={cx("app-tooltip", this.props.className)} style={style} onClick={this.props.onClick}>
        <div
          data-tip={this.props.message || Constant.TOOLTIPS[this.props.tooltipKey]}
          data-html
          data-effect={this.props.effect}
          data-place={this.props.place}
          data-delay-hide={this.props.delayHide}
          data-delay-show={this.props.showWithDelay ? showDelay : this.props.delayShow}
          className={css.hoverContent}
          data-for={this.props.host}
          style={this.props.style}
          data-class={this.props.dclass}>
          {this.props.children || defaultTooltip}
        </div>
      </div>
    );
  }
};

export default  AppTooltip;
