import style from './style.css';
import React from 'react'
import PropTypes from 'prop-types'

import RuleItem from '../RuleItem';
import AddRule from '../AddRule';


class RuleList extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      openRule: null,
      editingRule: null,
      openCreate: false,
    }
  }

  handleClickEdit = (rule) => {
    this.setState({
      openRule: null,
      editingRule: rule.name,
    })
  }
  handleClickEditInactive = () => {
    return false
  }

  handleCloseEdit = (rule) => {
    this.setState({editingRule: null}, () => {
      this.setState({openCreate: false})
    })
  }

  render () {
    const {props, state} = this
    const system_rules = props.rules.system_masking_rules
    const defined_rules = props.rules.masking_rules
    return (
      <div className={style.WidgetList}>
        <div className={style.Content}>
          {system_rules && system_rules.map((rule, index) => (
            <RuleItem
              key={index}
              rules={system_rules}
              rule={rule}
              isEditing={rule.name === state.editingRule}
              isEditMode={state.editingRule != null}
              shouldMask={state.editingRule != null && rule.name !== state.editingRule}
              onClickEdit={this.handleClickEditInactive}
              onCloseEdit={this.handleCloseEdit}
              ruleType={'system'} />
          ))}
          {defined_rules && defined_rules.map((rule, index) => (
            <RuleItem
              key={index}
              index={index}
              rules={defined_rules}
              rule={rule}
              isEditing={rule.name === state.editingRule}
              isEditMode={state.editingRule != null}
              shouldMask={state.editingRule != null && rule.name !== state.editingRule}
              onClickEdit={this.handleClickEdit}
              onCloseEdit={this.handleCloseEdit}
              ruleType={'defined'} />
          ))}
        </div>
        {defined_rules &&  <AddRule className={style.CreateWidget} rules={defined_rules} shouldMask={state.editingRule != null}/>}
      </div>
    )
  }
}

RuleList.propTypes = {
  rules: PropTypes.object.isRequired,
}

export default  RuleList
