import { heartbeatConstants } from "../constants";
import SDK from '../SDK'

const MAX_MISSED_HEARTBEATS_BEFORE_DISCONNECT = 6;

const hasMissedMaxHeartbeats = (lastSeqNumSent, lastSeqNumReceived) =>
  lastSeqNumSent - lastSeqNumReceived > MAX_MISSED_HEARTBEATS_BEFORE_DISCONNECT;

export const sendHeartbeat = (seqNum) => (dispatch, getState) => {
  const { lastSeqNumReceived, isAgentDisconnected } = getState().heartbeats;
  const data = {
    type: heartbeatConstants.HEARTBEAT_SENT,
    seqNum,
  };
  // Mark agent as isAgentDisconnected: true only once and never set
  // them back to isAgentDisconnected: false when they reconnect to
  // force a refresh
  if (!isAgentDisconnected) {
    if (hasMissedMaxHeartbeats(seqNum, lastSeqNumReceived)) {
      data.isAgentDisconnected = true;
    }
  } else {
    data.isAgentDisconnected = true;
  }
  SDK.sendHeartbeat({
    seq_num: seqNum
  })
  dispatch(data);
};

export const receiveHeartbeat = ({ seq_num }) => ({
  type: heartbeatConstants.HEARTBEAT_RECEIVED,
  seqNum: seq_num,
});
