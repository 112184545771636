import React from 'react';
import { makeStyles, Box, Grid, Typography } from '@material-ui/core';
import { ReactComponent as AwayIcon } from "img/icon-away.svg";
import { ReactComponent as OfflineIcon } from "img/icon-offline.svg";
import { ReactComponent as BusyIcon } from "img/icon-busy.svg";

const useStyles = makeStyles({
    heading: {
        fontFamily: 'Open Sans',
        fontSize: 16,
        fontWeight: 600,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 'normal',
        color: '#29302e'
    },
    message: {
        fontSize: 14,
        fontWeight: 'normal',
        fontStyle: 'italic',
        lineHeight: 1.43,
        color: '#716f6f'
    },
    boxMessage: {
        marginTop: -25,
        marginLeft: 48,
        marginBottom: 8
    },
    isClickDisabled: {
        opacity: 0.5
    },
    messageIcon: {
        flexShrink: 0,
        width: 15,
        height: 15,
    },
    statusBox: {
        marginTop: 16
    },
    iconBox: {
        marginLeft: 21
    }
})

const AgentStatus = ({ status }) => {
    const styles = useStyles();
    const statusMessage = `You will not receive any new conversations ${status === 'busy' ? 'until you end at least one chat in the queue' : 'in the queue until your status is changed to online'}.`;
    return (
        <Grid container direction="column" alignItems="flex-start" className={styles.statusBox}>
            <Grid item className={styles.iconBox}
            >
                {status === "busy" ? <BusyIcon className={styles.messageIcon} /> : status === "away" ? <AwayIcon className={styles.messageIcon} /> : <OfflineIcon className={styles.messageIcon} />}
            </Grid>
            <Grid item className={styles.boxMessage}>
                <Typography className={styles.heading}>
                    You’re currently marked as {status}
                </Typography>
                <Typography className={`${styles.message} ${styles.heading}`}>
                    {statusMessage}
                </Typography>
            </Grid>
        </Grid>

    );
}

export default AgentStatus;