export default  {
  MAX_UPLOAD_FILE_SIZE: 100, // megabytes,
  MAX_ACTIVE_STREAMS_ALLOWED: 12,
  GA_TRACKING_CODE: 'UA-64241053-1',
  WHITELIST_FILE_EXTENSIONS: [
    'png', 'jpg', 'jpeg', 'gif',
    'mp3', 'mp4',
    'pdf',
    'doc', 'docx',
    'xls', 'xlsx',
    'ppt', 'pptx',
  ],
  ICON_TYPES: {
    'pdf': 'pdf',
    'mp3': 'audio',
    'mp4, mov': 'video',
    'doc, dot, docx, dotx, docm, dotm': 'word',
    'xls, xlt, xla, xlsx, xltx, xlsm, xltm, xlam, xlsb': 'excel',
    'ppt, pot, pps, ppa, pptx, potx, ppsx, ppam, pptm, potm, ppsm': 'powerpoint'
  },
  TOOLTIPS: {
    'broadcasts_tab.opened': 'The percentage of connected users who opened this broadcast',
    'broadcasts_tab.send_new_broadcast': 'Please connect with Pypestream mobile app users to send broadcasts.',
    'edit_pype.pype_information': 'A brief introduction to your organization',
    'grow_tab.connect_badge': 'Add your Pype badge to your website, social media pages or emails',
    'grow_tab.invite_multiply_people': 'Easily invite your email list to connect with your Pype.\n        \u003ca href=\'http://support.pypestream.com/hc/en-us/articles/215774888\' target=\'_blank\' style=\'color:white;text-decoration:underline;\'\u003eLearn more\u003c/a\u003e',
    'grow_tab.link_to_pype': 'Share this custom link to take users right to your Pype!',
    'messages_tab.send_new_message': 'Please connect with users in order to send a message',
    'messages_tab.chat_limit': 'You have reached your chat limit. End an active chat to start a new one.',
    'messages_tab.agent_offline': 'You are offline. Change your status to start a new chat.',
    'messages_tab.user_active_chat': 'This user is already chatting with an agent.',
    'users_tab.user_engaged': 'This user is in an active chat.',
    'overview_tab.broadcast_tab': 'One-to-many communication feature sent to \u003cbr /\u003e\n        all connected users within a selected Stream',
    'overview_tab.grow_tab': 'Invite users to your Pype, share your Pype on \u003cbr /\u003e\n        social media and add your badge to your website',
    'overview_tab.message_count': 'Number of individual messages \u003cbr /\u003e\n        sent per Stream',
    'overview_tab.on_by_default_for_new_users': 'Choose if a new connected user automatically \u003cbr /\u003e\n        connects with your Stream or has to opt-in manually',
    'overview_tab.pype_io.copy_link': 'Share this custom link to take users right to your Pype!',
    'overview_tab.set_up_streams_for_your_pype': 'Topic-related channels through which you \u003cbr /\u003e\n        can communicate with your connected users',
    'overview_tab.stream_title': 'Topic-related channels through which you \u003cbr /\u003e\n        can communicate with your connected users',
    'admin_tab.shortcut_code': '<TODO> Shortcut code tooltiop',
    'admin_tab.enable_tag_codes': 'When tag codes are enabled, the chat logs will not include chat descriptions.',
    'sign_up.email': 'Please use an email address associate with your business',
    'sign_up.name_of_pype': 'Often the name of your organization.\u003cbr /\u003e\n        It’s how your Pype appears to users',
    'sign_up.phone': 'This will not be publicly visible or accessible to Pypestream users',
    'sign_up.public_pype_url': 'This custom link will take users right to your Pype!',
    'agent.email': 'Agent email addresses cannot be edited. \u003cbr /\u003e\n Remove the agent and send a new invitation \u003cbr /\u003e\n to change',
    'webchat.domains': '<TODO> Help text here',
    'agent.status': 'You cannot change your status after hours of operation.',
    'admin_tab.broadcast_tab.channel': 'SMS will be sent to users not connected to your Pype in the Pypestream mobile app.',
    'admin_tab.broadcast_tab.sms_limit': 'Select the total number of SMS messages users can receive to avoid spamming.',
    'convo_card_disabled_warning': 'You need to classify the open conversation before you can view any other messages.',

  },
  BANNER_MESSAGE: {
    'comment_required': 'You must leave a comment.',
    'classification_required': 'You must classify conversation.',
    'comment_and_classification_required': 'You must classify and comment.',
    'no_requirements': 'Conversation will move to history.'
  },
  SERVER_ERROR: 'The request returned an error. Please try again later.',
  TIMEZONES: [
    { value: '-12.0', zone: '(GMT -12:00) Eniwetok, Kwajalein' },
    { value: '-11.0', zone: '(GMT -11:00) Midway Island, Samoa' },
    { value: '-10.0', zone: '(GMT -10:00) Hawaii' },
    { value: '-9.0', zone: '(GMT -9:00) Alaska' },
    { value: '-8.0', zone: '(GMT -8:00) Pacific Time (US & Canada)' },
    { value: '-7.0', zone: '(GMT -7:00) Mountain Time (US & Canada)' },
    { value: '-6.0', zone: '(GMT -6:00) Central Time (US & Canada), Mexico City' },
    { value: '-5.0', zone: '(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima' },
    { value: '-4.0', zone: '(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz' },
    { value: '-3.5', zone: '(GMT -3:30) Newfoundland' },
    { value: '-3.0', zone: '(GMT -3:00) Brazil, Buenos Aires, Georgetown' },
    { value: '-2.0', zone: '(GMT -2:00) Mid-Atlantic' },
    { value: '-1.0', zone: '(GMT -1:00 hour) Azores, Cape Verde Islands' },
    { value: '0.0', zone: '(GMT) Western Europe Time, London, Lisbon, Casablanca' },
    { value: '1.0', zone: '(GMT +1:00 hour) Brussels, Copenhagen, Madrid, Paris' },
    { value: '2.0', zone: '(GMT +2:00) Kaliningrad, South Africa' },
    { value: '3.0', zone: '(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg' },
    { value: '3.5', zone: '(GMT +3:30) Tehran' },
    { value: '4.0', zone: '(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi' },
    { value: '4.5', zone: '(GMT +4:30) Kabul' },
    { value: '5.0', zone: '(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent' },
    { value: '5.5', zone: '(GMT +5:30) Bombay, Calcutta, Madras, New Delhi' },
    { value: '5.75', zone: '(GMT +5:45) Kathmandu' },
    { value: '6.0', zone: '(GMT +6:00) Almaty, Dhaka, Colombo' },
    { value: '7.0', zone: '(GMT +7:00) Bangkok, Hanoi, Jakarta' },
    { value: '8.0', zone: '(GMT +8:00) Beijing, Perth, Singapore, Hong Kong' },
    { value: '9.0', zone: '(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk' },
    { value: '9.5', zone: '(GMT +9:30) Adelaide, Darwin' },
    { value: '10.0', zone: '(GMT +10:00) Eastern Australia, Guam, Vladivostok' },
    { value: '11.0', zone: '(GMT +11:00) Magadan, Solomon Islands, New Caledonia' },
    { value: '12.0', zone: '(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka' }
  ],
  AGENT_INACTIVE_TIMEOUT: 30 * 60 * 1000,
  AGENT_HEARTBEAT_INTERVAL: 5 * 1000,
  REPLY_TIME_EXCEEDED: 30 * 1000,
  CLOSE_TO_CHAT_END: 50,
  EMPTY_STATES_MESSAGE: {
    active: "Once a conversation is assigned to you it will appear here.",
    escalated: "Once a conversation is assigned to an agent it will appear here.",
    archived: "Once a conversation from the last 30 days has ended it will appear here."
  },
  AUTO_RESPONDER_STRINGS: {
    busy: {
      header: "Chat routing autoresponse",
      description: "Chat routing automatically assigns incoming chats to the next available agent. This feature can be used when two or more agents are online.",
      checkboxLabel: "Use autoresponse when all agents are busy",
      messageLabel: "BUSY AUTORESPONSE MESSAGE"
    },
    offline: {
      header: "Offline autoresponse",
      description: "An offline autoresponse is a message sent to incoming chats when all agents are offline.",
      checkboxLabel: "Use autoresponse when all agents are offline",
      messageLabel: "OFFLINE AUTORESPONDER MESSAGE"
    },
    position: {
      header: "Position autoresponse",
      description: "An offline autoresponse is a pre-recorded message sent to incoming chats when all agents are Offline. Below is the default autoresponse message.",
      checkboxLabel: "Use autoresponse when all agents are offline",
      messageLabel: "OFFLINE AUTORESPONDER MESSAGE"
    }
  }
};
