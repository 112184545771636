import style from './style.css';
import React from 'react'
import { connect } from 'react-redux';
import DetailPane from '../../details/DetailPane';
import ContentBody from '../../details/ContentBody';
import TitleBar from '../../details/TitleBar';
import Banner from '../Banner';
import WidgetList from '../WidgetList';
import Info from './Info';
import activityTracker from "../../../../utils/activityTracker";
import ErrorBoundary from 'components/ui/ErrorBoundary';

class Webchat extends React.Component {
    componentDidMount() {
      const { customerName } = this.props

      activityTracker.logEvent(activityTracker.eventTypeNames.VIEW_PYPE_SETTING, {settingName: 'chat widget', customerName});
    }

    render() {
        return (
            <DetailPane className={`webchat ${style.Webchat} has-footer`}>
                <ContentBody>
                    <Banner/>
                    <Info/>
                    <ErrorBoundary>
                      <WidgetList/>
                    </ErrorBoundary>
                </ContentBody>
            </DetailPane>
        )
    }

}

const mapStateToProps = (state) => {
  return {
    customerName: state.pype.details.customerName
  }
};

export default connect(mapStateToProps, null)(Webchat)