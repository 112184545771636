import React from 'react'
import PropTypes from 'prop-types'

import LabelCheckbox from 'components/ui/forms/LabelCheckbox';
import style from './style.css'
import cx from 'classnames';

const Component = (props) => {
  const {agent,highlight,fadeUnselected} = props;
  let name = `${agent.first_name} ${agent.last_name}`;

  if(highlight){
    const reg = new RegExp(highlight,'gi');
    name = name.replace(reg, (str) => '<strong>'+str+'</strong>');
  }

  return (
    <div className={style.row}>
      <p className={cx({[style.unselected]: fadeUnselected && !agent.isSkillAssigned},"no-margin")} dangerouslySetInnerHTML={{__html: name}}/>
      <LabelCheckbox
        name={agent.id}
        value={agent.isSkillAssigned || false}
        onClick={props.onToggle.bind(this, agent)} />
    </div>
  )
}

Component.propType = {
  onToggle:PropTypes.func.isRequired,
  agent:PropTypes.object.isRequired,
  value: PropTypes.bool,
  highlight: PropTypes.string,
  fadeUnselected: PropTypes.bool
}

Component.defaultProps = {
  fadeUnselected: false
}

export default Component
