/*
  Global ui state for easier access to sibling state dependencies. Use
  this sparingly.
*/

const globalUi = {
  webchat: {
    stream: null,
    design: {
      styleSelection: 1,
      widgetPosition: '',
      shouldLockRatio: false,
      widgetHeight: 600,
      widgetWidth: 500,
      widgetStyleColor: '',
      widgetStyleTextColor: '',
      agentBubbleColor: '',
      agentBubbleTextColor: '',
      userBubbleColor: '',
      userBubbleTextColor: '',
    },
    bannerFileObject: null,
  },
}

window.globalUi = globalUi

export default  globalUi
