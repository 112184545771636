import React, { useState, useEffect } from 'react';
import { Route, withRouter, Redirect, Switch } from "react-router-dom";
import { connect } from "react-redux";
import cx from "classnames";

import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import ConversationsList, { TabTypes } from './ConversationsList';
import ConversationHistory from "components/conversations/conversation-history";
import ErrorBoundary from 'components/ui/ErrorBoundary';
import AgentDisconnected from './AgentDisconnected'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Button } from 'ui-elements';
import { makeStyles } from '@material-ui/core';
import { WarningTooltip, disabledTitle } from './Conversation/helpler';
import Constant from "core/constant";
import EmptyMailbox from '../../img/i.svg';
import _ from 'lodash';
import sdk from 'sdk';

const { agentChatSessionsActions, conversationsActions } = sdk;

const EmptyState = (props) => {
  const { chatSessions, isHistoryFilterPanelOpen, escalatedChats, archivedChats, activeTab } = props
  const activeChats = _.orderBy(chatSessions, (chat => chat.start_ts), 'desc')

  return <div className="pane pane-detail">
    <div className="full-height full-width">
      <div className="pane-header" style={{ backgroundColor: "#f9f9f9", borderBottom: "none" }} />
      <div className="empty-pane" style={{ backgroundColor: "#f9f9f9" }}>
        <img src={EmptyMailbox} alt="" />
        <p className="empty-pane-text">
          {isHistoryFilterPanelOpen
            ? "Apply Filters to See History"
            : (activeTab === "active" && activeChats.length >= 1) ||
              (activeTab === "escalated" && escalatedChats.length >= 1 && props.flags.selectConversationEmptyStates) ||
              (activeTab === "archived" && archivedChats.length >= 1 && props.flags.selectConversationEmptyStates)
              ? "Select a Conversation"
              : ""}
        </p>
      </div>
    </div>
  </div>;
}

const useStyles = makeStyles({
  pypeName: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    height: '24px',
    maxWidth: '263px',
    overflow: 'hidden'
  },
  isClickDisabled: {
    opacity: 0.5
  }
})

const Conversations = (props) => {

  const [activeTab, setActiveTab] = useState(TabTypes.active);
  const [position, setPosition] = useState({ x: undefined, y: undefined });
  const { match, isAgentDisconnected, pype, pypes, isHistoryFilterPanelOpen, pypeId } = props;
  
  const styles = useStyles();

  const handleOverviewButtonClick = (e) => {
    e.preventDefault();
    if(props.isClickDisabled && props.flags.isClickDisabledAfterChatEnded && activeTab === "active") {
      return;
    }
    if(props.flags.newMessageIndicator) {
      props.setCloseToChatEndId();
    }
    props.history.replace(`/${pypeId}/overview`);
  };

  const handleTabChange = (activeTab) => {
    setActiveTab(activeTab)
  }

  const handleMouseMove = (e) => {
    if (props.isClickDisabled && props.flags.isClickDisabledAfterChatEnded && activeTab === "active") {
      setPosition({ x: e.pageX, y: e.pageY });
    }
  };

  useEffect(() => {
    const wrapper = document.querySelector('.scrollable');
    wrapper.style.marginTop = '-2.1rem';
    if (props.flags.pe18874) {
      // Refetch agentChatSessions on mount
      // Ticket: https://pypestream.atlassian.net/browse/PE-18874
      props.fetchAgentChatSessions(true);
    }
    return () => {
      wrapper.style.marginTop = '0';
    }
  }, []);

  const topPane = (
    <div className={cx('pane-header white', { [`${styles.isClickDisabled}`]: props.flags.isClickDisabledAfterChatEnded && props.isClickDisabled && activeTab === "active" })} onMouseMove={handleMouseMove}>
      <Button startIcon={<ArrowBackIosIcon />} hollow onClick={handleOverviewButtonClick} >
        <ArrowBackIosIcon />
        <text>Overview</text>
      </Button>
      <div>
        <p className={styles.pypeName}>{pype.details.name}</p>
        {pypes.length > 1 && <small>{pype.details.env}</small>}
      </div>
    </div>
  );

  return (
    <div className="conversations-panel content-inner">
      <div className="panes">
        {isAgentDisconnected && <AgentDisconnected />}
        <div className={cx('pane', 'pane-list', { 'history-filters-is-open': isHistoryFilterPanelOpen })} style={{ marginRight: '0' }}>
          {props.flags.isClickDisabledAfterChatEnded && props.isClickDisabled && activeTab === "active" ? 
            <WarningTooltip
              title={disabledTitle}
              aria-label={Constant.TOOLTIPS.convo_card_disabled_warning}
              placement="top-start"
              PopperProps={{
                anchorEl: {
                  clientHeight: 0,
                  clientWidth: 0,
                  getBoundingClientRect: () => ({
                    top: position.y,
                    left: position.x,
                    right: position.x,
                    bottom: position.y,
                    width: 0,
                    height: 0,
                 })
                }
              }}
            >
              {topPane}
            </WarningTooltip> : 
              topPane
            }
          <ErrorBoundary>
            <ConversationsList onTabChange={handleTabChange} />
          </ErrorBoundary>
        </div>
        <Switch>
          {!props.flags.pe18009 ? 
            <Route
              exact
              path={`${match.path}`}
              component={() => EmptyState({ ...props, activeTab })}
            /> :
            <Route
              exact
              path={`${match.path}`}
              render={routeProps => {
                return <EmptyState {...routeProps} {...props} activeTab={activeTab} />
              }}
            />
          }
          <Route
            exact
            path={`${match.path}/view/:id/:consumerId`}
            render={(props) => {
              return (
                <ErrorBoundary subTitle="Please wait a moment and then try selecting the conversation again or reloading the page." className="pane pane-detail error-boundary">
                  <ConversationHistory
                    chatType={activeTab}
                    {...props}
                  />
                </ErrorBoundary>
              )
            }}
          />
          <Route exact path={`*`}>
            <Redirect to={`/not-found`} />
          </Route>
        </Switch>
      </div>
    </div>
  );
}

const mapStateToProps = (state, props) => {
  const { details } = state.pype;
  const found = state.agentChatSessions.chatSessions.some(el => el.id === state.conversationHistory.id);
  const isClickDisabled =
    ( 
      (details.end_comment_required || details.end_tags_required) &&
      state.allConversations.endedNotClassifiedChatSessions.includes(
        state.conversationHistory.id
      ) && found);
  return {
    isAgentDisconnected: state.heartbeats.isAgentDisconnected,
    pype: state.pype,
    pypes: state.pypes.pypes,
    pypeId: state.pype.details.id,
    chatSessions: state.agentChatSessions.chatSessions,
    escalatedChats: state.agentChatSessions.escalated_chatSessions,
    archivedChats: state.agentChatSessions.archived_chatSessions,
    isHistoryFilterPanelOpen: state.conversationHistory.isHistoryFilterPanelOpen,
    isClickDisabled,
  }
};

const mapDispatchToProps = (dispatch) => ({
  setCloseToChatEndId: () => {
    return dispatch(conversationsActions.setCloseToChatEndId(null));
  },
  fetchAgentChatSessions: (flag) => dispatch(agentChatSessionsActions.fetchAgentChatSessions(flag)),
});

const ConversationsWithFeatureFlags = withLDConsumer()(Conversations);

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ConversationsWithFeatureFlags));
