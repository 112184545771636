import { store } from "../sdk/store";
import x_list_chat_sessions_for_agent_data from "./mock_message/x_list_chat_sessions_for_agent.json";
import x_heartbeat_data from "./mock_message/x_heartbeat.json";
import x_presence_data from "./mock_message/x_presence.json";
import x_chat_data from "./mock_message/x_chat.json";
import x_chat_live_data from "./mock_message/x_chat_live.json";
import x_snapshot_data from "./mock_message/x_snapshot.json";
import x_list_agent_data from "./mock_message/x_list_agent_data.json";
import x_list_streams_data from "./mock_message/x_list_streams.json";
import x_list_tags_data from "./mock_message/x_list_tags.json";
import x_list_skills_data from "./mock_message/x_list_skills.json";
import x_list_chat_sessions_with_consumer_at_pype_data from "./mock_message/x_list_chat_sessions_with_consumer_at_pype.json";
import x_list_canned_responses_data from "./mock_message/x_list_canned_responses.json";
import x_chat_msg_data from "./mock_message/x_chat_msg.json";
import x_list_time_data from "./mock_message/x_list_time.json";
import x_list_autoresponders_data from "./mock_message/x_list_autoresponders.json";
import x_end_chat_data from "./mock_message/x_end_chat.json";
import x_public_user_data from "./mock_message/x_public_user.json";
import x_classify_chat_data from "./mock_message/x_classify_chat.json";
import x_list_agents_data from "./mock_message/x_list_agents.json";
import x_reassign_chat_data from "./mock_message/x_reassign_chat.json";
import x_list_escalated_session_for_pype_data from "./mock_message/x_list_escalated_session_for_pype.json";
import x_update_data from "./mock_message/x_update.json";
import x_typing_data from "./mock_message/x_typing.json";
import x_update_privacy_data from "./mock_message/x_update_privacy.json";
import x_list_bot_metrics_data from "./mock_message/x_list_bot_metrics.json";
import x_list_30_day_chat_sessions_data from "./mock_message/x_list_30_day_chat_sessions.json";
import x_list_30_day_chat_sessions_data_1 from "./mock_message/x_list_30_day_chat_sessions_1.json";
import x_init_sequence_data from './mock_message/x_init_sequence.json';
import new_canned_response_data from "./mock_message/new_canned_response.json";
import { filterHistory, formatHistoryData, filtersApplied } from "./history";

let tagFlag = "normal";
let quickReplyFlag = false;
let skillFlag = "normal";

export const createMessages = (data, msg_no = 0) => {
  const type = data.split('"request_type":')[1].split('"')[1];
  const timeStamp = Date.now();
  let msg_queue = [];
  let current_sessions = [];
  let dataString = null;
  const chat_id = "8ada64ec-c611-4830-9aff-522a9b1a821c";
  const anon_id = "60d7c8b2-f48f-4ca1-8b7d-d99d51865e84";
  const correlation_id = data.split('correlation_id":"')[1].split('"')[0];

  switch (type) {

    case "x_init_sequence":
      x_init_sequence_data["correlation_id"] = correlation_id;
      dataString = JSON.stringify(x_init_sequence_data);
      msg_queue.push(`${createMsgHeader(msg_no, dataString.length)}\x0A\x0A${dataString}`);
      msg_queue.push(createAck(msg_no, correlation_id, timeStamp));
      return msg_queue;

    case "x_list_chat_sessions_for_agent":
      x_list_chat_sessions_for_agent_data["timestamp"] = timeStamp;
      x_list_chat_sessions_for_agent_data["correlation_id"] = correlation_id;
      dataString = JSON.stringify(x_list_chat_sessions_for_agent_data);
      msg_queue.push(
        `${createMsgHeader(msg_no, dataString.length)}\x0A\x0A${dataString}`
      );
      msg_queue.push(createAck(msg_no, correlation_id, timeStamp));
      return msg_queue;

    case "x_heartbeat":
      let seq_no = data.split('seq_num":')[1].split(",")[0];
      if (Number(seq_no) > 0) {
        current_sessions.push(chat_id);
      }
      x_heartbeat_data["data"]["seq_num"] = Number(seq_no);
      x_heartbeat_data["data"]["current_sessions"] = current_sessions;
      dataString = JSON.stringify(x_heartbeat_data);
      msg_queue.push(
        `${createMsgHeader(msg_no, dataString.length)}\x0A\x0A${dataString}`
      );
      if (Number(seq_no) === 0) {
        x_chat_data["data"]["start_ts"] = timeStamp;
        const chat_string = JSON.stringify(x_chat_data);
        msg_queue.push(
          `${createMsgHeader(msg_no, chat_string.length)}\x0A\x0A${chat_string}`
        );
        x_chat_live_data["data"]["start_ts"] = timeStamp;
        const chat_string_live = JSON.stringify(x_chat_live_data);
        msg_queue.push(
          `${createMsgHeader(
            msg_no + 1,
            chat_string_live.length
          )}\x0A\x0A${chat_string_live}`
        );
      }
      return msg_queue;

    case "x_snapshot":
      x_snapshot_data["data"]["user_read_counts"][1]["user_id"] = anon_id;
      x_snapshot_data["data"]["agent_assign_ts"] = timeStamp - 10;
      x_snapshot_data["data"]["start_ts"] = timeStamp;
      x_snapshot_data["data"]["history"][0]["chat_msg"]["timestamp"] =
        timeStamp;
      x_snapshot_data["data"]["originator_user_id"] = anon_id;
      x_snapshot_data["data"]["participants"]["anon_consumer"] = anon_id;
      x_snapshot_data["data"]["consumer"] = anon_id;
      x_snapshot_data["correlation_id"] = correlation_id;

      dataString = JSON.stringify(x_snapshot_data);
      msg_queue.push(
        `${createMsgHeader(msg_no, dataString.length)}\x0A\x0A${dataString}`
      );
      msg_queue.push(createAck(msg_no, correlation_id, timeStamp));
      return msg_queue;

    case "x_typing":
      dataString = JSON.stringify(x_typing_data);
      msg_queue.push(
        `${createMsgHeader(msg_no, dataString.length)}\x0A\x0A${dataString}`
      );
      msg_queue.push(createAck(msg_no, correlation_id, timeStamp));
      return msg_queue;

    case "x_list_agent_data":
      x_list_agent_data["data"]["last_updated"] = timeStamp;
      x_list_agent_data["correlation_id"] = correlation_id;
      dataString = JSON.stringify(x_list_agent_data);
      msg_queue.push(
        `${createMsgHeader(msg_no, dataString.length)}\x0A\x0A${dataString}`
      );
      msg_queue.push(createAck(msg_no, correlation_id, timeStamp));
      return msg_queue;

    case "x_list_streams":
      x_list_streams_data["data"]["timestamp"] = timeStamp;
      x_list_streams_data["correlation_id"] = correlation_id;
      dataString = JSON.stringify(x_list_streams_data);
      msg_queue.push(
        `${createMsgHeader(msg_no, dataString.length)}\x0A\x0A${dataString}`
      );
      msg_queue.push(createAck(msg_no, correlation_id, timeStamp));
      return msg_queue;

    case "x_list_tags":
      x_list_tags_data["correlation_id"] = correlation_id;
      if (tagFlag === "new") {
        x_list_tags_data["data"].unshift({
          code: "05",
          description: "testTag",
          enable: null,
          category_id: "7efea22b-1c5f-4b4b-b16a-95fe19c51794",
          pype_id:
            "da454940-d7b6-4f47-abe0-8731bee07b36_b4fd16aa-156b-46b4-b97b-12c865d5c858",
          id: "we-can-use-test-id",
        });
      }
      dataString = JSON.stringify(x_list_tags_data);
      msg_queue.push(
        `${createMsgHeader(msg_no, dataString.length)}\x0A\x0A${dataString}`
      );
      msg_queue.push(createAck(msg_no, correlation_id, timeStamp));
      return msg_queue;

    case "x_skill":
      msg_queue.push(createAck(msg_no, correlation_id, timeStamp));
      skillFlag = data.split('"request_action":')[1].split('"')[1];
      return msg_queue;

    case "x_list_skills":
      x_list_skills_data["correlation_id"] = correlation_id;
      if (skillFlag === "new") {
        x_list_skills_data["data"]["list"].push({
          daily_session_count: 0,
          session_count: 0,
          weekly_response_count: 0,
          weekly_response_time: 0,
          daily_response_count: 0,
          daily_response_time: 0,
          response_count: 0,
          response_time: 0,
          assigned_agent_count: 1,
          active_queue_chat_count: 0,
          active_bot_chat_count: 0,
          active_agent_chat_count: 0,
          reroute_to_general: true,
          pype_id:
            "da454940-d7b6-4f47-abe0-8731bee07b36_b4fd16aa-156b-46b4-b97b-12c865d5c858",
          name: "test",
          id: "new-skill",
        });
      }
      dataString = JSON.stringify(x_list_skills_data);
      msg_queue.push(
        `${createMsgHeader(msg_no, dataString.length)}\x0A\x0A${dataString}`
      );
      msg_queue.push(createAck(msg_no, correlation_id, timeStamp));
      skillFlag = "normal";
      return msg_queue;

    case "x_list_chat_sessions_with_consumer_at_pype":
      x_list_chat_sessions_with_consumer_at_pype_data["data"]["timestamp"] =
        timeStamp;
      x_list_chat_sessions_with_consumer_at_pype_data["correlation_id"] =
        correlation_id;
      dataString = JSON.stringify(
        x_list_chat_sessions_with_consumer_at_pype_data
      );
      msg_queue.push(
        `${createMsgHeader(msg_no, dataString.length)}\x0A\x0A${dataString}`
      );
      msg_queue.push(createAck(msg_no, correlation_id, timeStamp));
      return msg_queue;

    case "x_list_canned_responses":
      if (quickReplyFlag) {
        new_canned_response_data["correlation_id"] = correlation_id;
        dataString = JSON.stringify(new_canned_response_data);
      } else {
        x_list_canned_responses_data["correlation_id"] = correlation_id;
        dataString = JSON.stringify(x_list_canned_responses_data);
      }
      msg_queue.push(
        `${createMsgHeader(msg_no, dataString.length)}\x0A\x0A${dataString}`
      );
      msg_queue.push(createAck(msg_no, correlation_id, timeStamp));
      return msg_queue;

    case "x_chat_msg":
      const clientMsgID = data.split('"client_msg_id":')[1].split('"')[1];
      const msg = data.split('"msg":')[1].split('"')[1];
      const msgType = data.split('"msg_type":')[1].split('"')[1];

      x_chat_msg_data["data"]["timestamp"] = timeStamp;
      x_chat_msg_data["data"]["seq_num"]++;
      x_chat_msg_data["data"]["msg"] = msg;
      x_chat_msg_data["data"]["msg_type"] = msgType;

      if (msgType !== "text") {
        x_chat_msg_data["data"]["file"] = data
          .split('"file":')[1]
          .split('"')[1];
        x_chat_msg_data["data"]["file_status"] = data
          .split('"file_status":')[1]
          .split('"')[1];
      }

      x_chat_msg_data["data"]["client_msg_id"] = clientMsgID;
      x_chat_msg_data["data"]["id"] =
        chat_id + `.${x_chat_msg_data["data"]["seq_num"]}`;
      dataString = JSON.stringify(x_chat_msg_data);
      msg_queue.push(
        `${createMsgHeader(msg_no, dataString.length)}\x0A\x0A${dataString}`
      );
      msg_queue.push(createAck(msg_no, correlation_id, timeStamp));
      return msg_queue;

    case "x_list_escalated_sessions_for_pype":
      x_list_escalated_session_for_pype_data["correlation_id"] = correlation_id;
      x_list_escalated_session_for_pype_data["data"]["timestamp"] = timeStamp;
      x_list_escalated_session_for_pype_data["data"]["chat_sessions"][0][
        "start_ts"
      ] = timeStamp;
      x_list_escalated_session_for_pype_data["data"]["chat_sessions"][0][
        "history"
      ][0]["chat_msg"]["timestamp"] = timeStamp;
      dataString = JSON.stringify(x_list_escalated_session_for_pype_data);
      msg_queue.push(
        `${createMsgHeader(msg_no, dataString.length)}\x0A\x0A${dataString}`
      );
      msg_queue.push(createAck(msg_no, correlation_id, timeStamp));
      return msg_queue;

    case "x_presence":
      const status = data || data.split('"status":')[1].split('"')[1];
      x_presence_data["data"]["status"] = status;
      dataString = JSON.stringify(x_presence_data);
      msg_queue.push(
        `${createMsgHeader(msg_no, dataString.length)}\x0A\x0A${dataString}`
      );
      msg_queue.push(createAck(msg_no, correlation_id, timeStamp));
      return msg_queue;

    case "x_public_user":
      x_public_user_data["correlation_id"] = correlation_id;
      dataString = JSON.stringify(x_public_user_data);
      msg_queue.push(
        `${createMsgHeader(msg_no, dataString.length)}\x0A\x0A${dataString}`
      );
      msg_queue.push(createAck(msg_no, correlation_id, timeStamp));
      return msg_queue;

    case "x_end_chat":
      dataString = JSON.stringify(x_end_chat_data);
      msg_queue.push(
        `${createMsgHeader(msg_no, dataString.length)}\x0A\x0A${dataString}`
      );
      msg_queue.push(createAck(msg_no, correlation_id, timeStamp));
      return msg_queue;

    case "x_classify_chat":
      dataString = JSON.stringify(x_classify_chat_data);
      msg_queue.push(
        `${createMsgHeader(msg_no, dataString.length)}\x0A\x0A${dataString}`
      );
      msg_queue.push(createAck(msg_no, correlation_id, timeStamp));
      return msg_queue;

    case "x_list_agents":
      x_list_agents_data["correlation_id"] = correlation_id;
      if (skillFlag === "new") {
        x_list_agents_data["data"][6].skills.push(
          "9537e97f-07e1-4d57-b9fe-93e6a2f17573"
        ); // agent should be updated
      }
      dataString = JSON.stringify(x_list_agents_data);
      msg_queue.push(
        `${createMsgHeader(msg_no, dataString.length)}\x0A\x0A${dataString}`
      );
      msg_queue.push(createAck(msg_no, correlation_id, timeStamp));
      return msg_queue;

    case "x_list_30_day_chat_sessions":
      let more = data.split('"more":')[1].split(",")[0];
      let send_data = data.split("{").slice(1).join("{");
      send_data = "{" + send_data;
      send_data = send_data.split('data":')[1];
      let data_filter = send_data.split("}")[0] + "}";

      if (more === "null") {
        let x_list_30_day_chat_sessions_data_data = formatHistoryData(
          x_list_30_day_chat_sessions_data
        );
        x_list_30_day_chat_sessions_data_data["correlation_id"] =
          correlation_id;
        if (filtersApplied(JSON.parse(data_filter))) {
          x_list_30_day_chat_sessions_data_data = filterHistory(
            x_list_30_day_chat_sessions_data_data,
            JSON.parse(data_filter)
          );
          x_list_30_day_chat_sessions_data_data["data"]["more"] = "done";
        }
        dataString = JSON.stringify(x_list_30_day_chat_sessions_data_data);
        msg_queue.push(
          `${createMsgHeader(msg_no, dataString.length)}\x0A\x0A${dataString}`
        );
        msg_queue.push(createAck(msg_no, correlation_id, timeStamp));
      } else {
        let x_list_30_day_chat_sessions_data_data_1 = formatHistoryData(
          x_list_30_day_chat_sessions_data_1,
          true
        );
        x_list_30_day_chat_sessions_data_data_1["correlation_id"] =
          correlation_id;
        if (filtersApplied(JSON.parse(data_filter))) {
          x_list_30_day_chat_sessions_data_data_1 = filterHistory(
            x_list_30_day_chat_sessions_data_data_1,
            JSON.parse(data_filter)
          );
          x_list_30_day_chat_sessions_data_data_1["data"]["more"] = "done";
        }
        dataString = JSON.stringify(x_list_30_day_chat_sessions_data_data_1);
        msg_queue.push(
          `${createMsgHeader(msg_no, dataString.length)}\x0A\x0A${dataString}`
        );
        msg_queue.push(createAck(msg_no, correlation_id, timeStamp));
      }
      return msg_queue;

    case "x_reassign_chat":
      x_reassign_chat_data["correlation_id"] = correlation_id;
      dataString = JSON.stringify(x_reassign_chat_data);
      msg_queue.push(
        `${createMsgHeader(msg_no, dataString.length)}\x0A\x0A${dataString}`
      );
      msg_queue.push(createAck(msg_no, correlation_id, timeStamp));
      return msg_queue;

    case "x_list_bot_metrics":
      x_list_bot_metrics_data["correlation_id"] = correlation_id;
      dataString = JSON.stringify(x_list_bot_metrics_data);
      msg_queue.push(
        `${createMsgHeader(msg_no, dataString.length)}\x0A\x0A${dataString}`
      );
      msg_queue.push(createAck(msg_no, correlation_id, timeStamp));
      return msg_queue;

    case "x_tag":
      msg_queue.push(createAck(msg_no, correlation_id, timeStamp));
      tagFlag = data.split('"request_action":')[1].split('"')[1];
      return msg_queue;

    case "x_list_autoresponders":
      x_list_autoresponders_data["correlation_id"] = correlation_id;
      dataString = JSON.stringify(x_list_autoresponders_data);
      msg_queue.push(
        `${createMsgHeader(msg_no, dataString.length)}\x0A\x0A${dataString}`
      );
      msg_queue.push(createAck(msg_no, correlation_id, timeStamp));
      return msg_queue;

    case "x_autoresponder":
      msg_queue.push(createAck(msg_no, correlation_id, timeStamp));
      return msg_queue;

    case "x_canned_response":
      quickReplyFlag = true;
      msg_queue.push(createAck(msg_no, correlation_id, timeStamp));
      return msg_queue;

    case "x_update":
      let update_type = data.split('"masking_rules":');
      if (update_type.length > 1) {
        let masking_rules = update_type[1];
        masking_rules = masking_rules.substr(0, masking_rules.length - 3);
        masking_rules = JSON.parse(masking_rules);
        x_update_privacy_data["data"]["masking_rules"] = masking_rules;
        dataString = JSON.stringify(x_update_privacy_data);
        msg_queue.push(
          `${createMsgHeader(msg_no, dataString.length)}\x0A\x0A${dataString}`
        );
        msg_queue.push(createAck(msg_no, correlation_id, timeStamp));
        return msg_queue;
      } else if (data.split('"chat_logs_email_enabled":').length > 1) {
        const chatLogsEmailEnabled = JSON.parse(
          data.split('"chat_logs_email_enabled":')[1].split(',"')[0]
        );
        const endCommentDisplay = JSON.parse(
          data.split('"end_comment_display":')[1].split(',"')[0]
        );
        const endCommentRequired = JSON.parse(
          data.split('"end_comment_required":')[1].split("}")[0]
        );

        x_update_data["data"]["last_updated"] = timeStamp;
        x_update_data["data"]["creation_date"] = timeStamp;
        x_update_data["data"]["chat_logs_email_enabled"] = chatLogsEmailEnabled;
        x_update_data["data"]["end_comment_display"] = endCommentDisplay;
        x_update_data["data"]["end_comment_required"] = endCommentRequired;
        dataString = JSON.stringify(x_update_data);
        msg_queue.push(
          `${createMsgHeader(msg_no, dataString.length)}\x0A\x0A${dataString}`
        );
        msg_queue.push(createAck(msg_no, correlation_id, timeStamp));
        return msg_queue;
      } else {
        if (data.split("timezone").length > 1) {
          const scheduleEnabled = JSON.parse(
            data.split('"schedule_enabled":')[1].split("}")[0]
          );
          const timezone = JSON.parse(
            data.split('"timezone":')[1].split(',"')[0]
          );
          const scheduleStart = JSON.parse(
            data.split('"schedule_start":')[1].split(',"')[0]
          );
          const scheduleEnd = JSON.parse(
            data.split('"schedule_end":')[1].split(',"')[0]
          );

          x_list_time_data["data"]["schedule_enabled"] = scheduleEnabled;
          x_list_time_data["data"]["timezone"] = timezone;
          x_list_time_data["data"]["schedule_start"] = scheduleStart;
          x_list_time_data["data"]["schedule_end"] = scheduleEnd;
          dataString = JSON.stringify(x_list_time_data);
          msg_queue.push(
            `${createMsgHeader(msg_no, dataString.length)}\x0A\x0A${dataString}`
          );
          msg_queue.push(createAck(msg_no, correlation_id, timeStamp));
          return msg_queue;
        } else {
          try {
            const chatLimit = data
              .split('"chat_default_limit":')[1]
              .split("}")[0]
              .split('"')[0];
            x_update_data["data"]["chat_default_limit"] = chatLimit;
          } catch (error) {}
          dataString = JSON.stringify(x_update_data);
          msg_queue.push(
            `${createMsgHeader(msg_no, dataString.length)}\x0A\x0A${dataString}`
          );
          msg_queue.push(createAck(msg_no, correlation_id, timeStamp));
          return msg_queue;
        }
      }
    default:
      break;
  }
};

const createMsgHeader = (msg_no, content_length) => {
  const state = store.getState();
  const USER_ID = state.botUserSession.user.user_id;
  const DEVICE_ID = state.session.device_id;
  const REPLY_TO = `${USER_ID}_${DEVICE_ID}`;
  return `MESSAGE\x0Acontent-type:application/json\x0Aredelivered:false\x0Apersistent:true\x0Acontent-length:${content_length}\x0Asubscription:${REPLY_TO}_ack\x0Amessage-id:T_${REPLY_TO}_ack@@857b6607-d6f2-4e41-a9d1-238e2102870f@@${msg_no}\x0Adestination:/exchange/general.exch/${REPLY_TO}\x0Aack:T_${REPLY_TO}_ack@@857b6607-d6f2-4e41-a9d1-238e2102870f@@${msg_no}`;
};

const createAck = (msg_no, correlation_id, timeStamp) => {
  const ack_data = `{
    "data": null,
    "timestamp": ${timeStamp},
    "error_details": null,
    "details": "ok",
    "status": "ok",
    "correlation_id": "${correlation_id}",
    "type": "ack",
    "version": 1
  }`;
  return `${createMsgHeader(msg_no + 1, ack_data.length)}\x0A\x0A${ack_data}`;
};
