import '@babel/polyfill';
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { authClientWrapper } from "bot-user-session";
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { store, history } from "./sdk/store";

import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "react-tabs/style/react-tabs.css";
import  activityTracker from 'utils/activityTracker';
import ErrorBoundary from 'components/ui/ErrorBoundary';
import initialiseMockWebSocket from './__mocks__';

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleRight,
  faBolt,
  faExclamation,
  faCircle,
  faCaretUp,
  faCaretDown,
  faChevronUp,
  faChevronDown
} from "@fortawesome/free-solid-svg-icons";
library.add(faAngleRight);
library.add(faBolt);
library.add(faExclamation);
library.add(faCircle);
library.add(faCaretUp);
library.add(faCaretDown);
library.add(faChevronUp);
library.add(faChevronDown);

const OktaAuthConfig = {
  url: window.config.PS_PM_OKTA_URL,
  issuer: window.config.PS_PM_OKTA_ISSUER,
  redirectUri: window.location.origin,
  clientId: window.config.PS_PM_OKTA_CLIENT_ID,
  scope: ["openid", "email", "profile", "pypestream_access"]
};
authClientWrapper.setAuthConfiguration(OktaAuthConfig);

activityTracker.init();

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: window.config.LAUNCHDARKLY_CLIENT_ID,
    context: {
      "kind": "user",
      "key": `agentPro-${window.location.hostname}`,
      "domain": window.location.href
    },

  });
  ReactDOM.render(
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <ErrorBoundary>
            <LDProvider>
              <App />
            </LDProvider>
          </ErrorBoundary>
        </ConnectedRouter>
      </Provider>,
      document.getElementById("root")
  );
})();

if (window.Cypress) {
  window.store = store;
  initialiseMockWebSocket();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
