import React from 'react'
import PropTypes from 'prop-types';
import _  from 'lodash';
import { Link } from 'react-router-dom';

import cx from 'classnames';
import moment from 'moment-timezone';

import css from './style.css';
import TetheredSelect from 'components/ui/tethered-select/TetheredSelect';

const generateTimeIntervals = (options = {}) => {
	let times = [];
  let startTime = moment().days(1).hour(0).minute(0).second(0);
  let endTime = moment().days(2).hour(0).minute(0).second(0);

  const {disabled} = options;

  while (startTime < endTime) {
    const time = new moment(startTime)
    const secondsSinceMidnight = time.get('hour')*60*60 + time.get('minute')*60;
    let timeLabel = time.format('h:mm A');

    if(timeLabel == '12:00 AM'){
      timeLabel += ' (midnight)'
    } else if(timeLabel == '12:00 PM'){
      timeLabel += ' (noon)'
    }
    times.push({
      value: secondsSinceMidnight,
      label: timeLabel,
      disabled: disabled === secondsSinceMidnight
    });
    startTime.add('m', 30);
  }

  return times;
}

const generateTimeZones = () => {
  return _.map(moment.tz.names(), (t, i) => ({
      value: t,
      label: t
  }));
}

class Component extends React.Component {
	onFromTimeChange = (option) => {
		const toTimeRef = this.refs.toRef;
		if(option.value !== -1){
			setTimeout(() => { toTimeRef.focus() },0);
		}
		this.props.onFromTimeChange(option);
	}
  componentWillMount(){
    this.timezoneOptions = generateTimeZones();
  }
	selectOptionsRenderer (option) {
		return (<div className="Select-checked">
			{option.label}
		</div>)
	}
  render() {
    const {props} = this
    const {toTime,fromTime,timezone, onToTimeChange, onTimezoneChange, isPypeDataLoading} = props;


    let fromTimeOptions = generateTimeIntervals({disabled: toTime});

    if(!isPypeDataLoading){
      fromTimeOptions.unshift({value:-1,label:'Always open'});
    }

    const toTimeOptions = generateTimeIntervals({disabled: fromTime});


    return (<div>
    	<p>Decide when chats should be routed to your agents. After hours, users will receive the <Link to="autoresponders">offline autoresponse</Link>, if enabled.</p>
      <p className={"label-text m-t-20 m-b-5"}>HOURS OF OPERATION</p>
      <div className={css.selectRow}>
        <div className={css.selectWrapper}>

        	<TetheredSelect
        		className={css.timeSelect}
            name="from"
            styles={{ menuPortal: base => {
              const { zIndex, ...rest } = base; 
              return { ...rest, zIndex: 9999 };
            }}}
            menuPortalTarget={document.body}
            value={fromTimeOptions.filter(({value}) => value === fromTime)}
            options={fromTimeOptions}
            searchable={false}
            clearable={false}
            placeholder="Loading..."
            isDisabled={isPypeDataLoading}
						optionRenderer={this.selectOptionsRenderer}
            onChange={this.onFromTimeChange}/>
  			</div>
        <span className={cx("p-l-10 p-r-10", {"fade": fromTime === -1})}>to</span>
        <div className={css.selectWrapper}>
	        <TetheredSelect
	        	ref="toRef"
	        	className={cx(css.timeSelect, css.toTime)}
            name="to"
            styles={{ menuPortal: base => {
              const { zIndex, ...rest } = base; 
              return { ...rest, zIndex: 9999 };
            }}}
            menuPortalTarget={document.body}
	          value={fromTimeOptions.filter(({value}) => value === toTime)}
	          options={toTimeOptions}
	          searchable={false}
	          clearable={false}
	          onChange={onToTimeChange}
	          isDisabled={fromTime === -1}
						optionRenderer={this.selectOptionsRenderer}
	          placeholder={isPypeDataLoading ? "Loading..." : "Select a closing time..."}/>
	      </div>
      </div>
      	{fromTime !== -1 ?
      		<div>
      			<p className={"label-text m-t-20 m-b-5"}>TIME ZONE</p>
		      	<div className={css.selectWrapper}>
		      		<TetheredSelect
                styles={{ menuPortal: base => {
                  const { zIndex, ...rest } = base; 
                  return { ...rest, zIndex: 9999 };
                }}}
                menuPortalTarget={document.body}
			          name="timezone"
			          value={this.timezoneOptions.filter(({value}) => value === timezone)}
			          options={this.timezoneOptions}
			          searchable={false}
			          clearable={false}
								optionRenderer={this.selectOptionsRenderer}
			          onChange={onTimezoneChange}/>
		          </div>
		       </div> : null
	        }
    </div>)
  }

}

Component.propTypes = {
	fromTime: PropTypes.number,
	toTime: PropTypes.string,
	timezone: PropTypes.string,
	onFromTimeChange: PropTypes.func.isRequired,
	onToTimeChange: PropTypes.func.isRequired,
	onTimezoneChange: PropTypes.func.isRequired,
  isPypeDataLoading: PropTypes.bool.isRequired
}

export default  Component
