import React from 'react'
import PropTypes from 'prop-types';
import Time from 'react-time';
import DotDotDot from 'react-dotdotdot';
import { NavLink, withRouter } from 'react-router-dom';
import _ from 'lodash';

const activeStyle = {
  background: 'rgba(45,124,225,.07)',
  color: '#2e7de1'
}
class CampaignListItem extends React.Component{

  static propTypes = {
    campaign: PropTypes.object.isRequired,
    streams: PropTypes.array.isRequired
  }

  render() {
    var campaign = this.props.campaign;
    var id = campaign.id;
    var text = campaign.body || '';
    var time = campaign.creation_date || '';
    var stream = '';
    const isSMSBroadcast = campaign.smsCampaign;
    const {match, onLinkClick} = this.props 

    if (this.props.streams.length > 0) {
      var streamObj = _.find(this.props.streams, function(stream) {
        return stream.id === campaign.stream_id;
      });

      stream = streamObj && streamObj.stream_name;
    }
    if (isSMSBroadcast) {
      stream = 'SMS';
    }

    return (
      <div ref="el" key={id} className="campaign-list-item">
        <NavLink onClick={() => onLinkClick(id)} to={`${match.url}/view/${id}`} className={`list-item restore-${id}`} activeStyle={activeStyle}>
          <div>
            <div className="pull-left semi-bold">
              <Time value={time} format="MM-DD-YYYY" />
            </div>
            <div className="pull-right">
              <Time className="fs-13 fade-hard" value={time} format="h:mm a" />
            </div>
          </div>
          <div className="msg">
            <DotDotDot clamp="2" className="message-preview">
              {text}
            </DotDotDot>
          </div>
          <div className="tag">
            {stream}
          </div>
        </NavLink>
      </div>
    );
  }
};

export default  withRouter(CampaignListItem);
