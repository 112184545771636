import style from './style.css';
import React from 'react'
import PropTypes from 'prop-types'

import classNames from 'classnames';
import Clipboard from 'clipboard';

import EditPanel from '../EditPanel';
import DeleteWarningModal from '../DeleteWarningModal';
import activityTracker from "../../../../utils/activityTracker";

let apiClipboard = new Clipboard('.clip');

apiClipboard.on('success', function(e) {
  window.appCreateToast({
    message: 'Copied to clipboard.',
    level: 'success',
  })
  e.clearSelection();
});

class ApiItem extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      activeModal: null, // 1-3 or null
      isModalOpen: false,
      isSubmitting: false
    }
  }

  onClickPanel = () => {
    if (this.props.isEditing || this.props.shouldMask) {
      return
    }
    this.props.onToggle(this.props.api.key)
  }


  // Edit feature not for this release
  // handleClickEdit (event) {
  //   event.stopPropagation()
  //   this.props.onClickEdit(this.props.api)
  // }

  openWarningModal = () => {
    this.setState({isModalOpen: true})
  }

  closeModal = () => {
    this.setState({isModalOpen: false})
  }

  handleDelete = () => {
    const { customerName } = this.props
    
    if (this.state.isSubmitting) {
      return
    }
    this.setState({isSubmitting: true})
    return this.props.onDelete(this.props.api.key).then(() => {

      window.appCreateToast({
        message: 'Deleted API key successfully.',
        level: 'success',
      })
      activityTracker.logEvent(activityTracker.eventTypeNames.UPDATE_PYPE_SETTING,{settingName: 'api keys',action: activityTracker.eventActionType.DELETE, customerName});
    }).catch((error) => {
      console.warn(error)
      window.appCreateToast({
        message: 'Encountered an error deleting Api.',
        level: 'error',
      })
      this.setState({isSubmitting: false})
    })
  }

  getDate = (dateStamp) => {
    const date = new Date(dateStamp);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options)
  }

  render () {
    const {props, state} = this
    const {api} = props
    const className = classNames(style.WidgetItem, props.className, {
      [style.keepOpen]: props.keepOpen,
      [style.isOpen]: props.isOpen,
      [style.isEditing]: props.isEditing,
      [style.isEditMode]: props.isEditMode,
      [style.shouldMask]: props.shouldMask,
    })
    const apiName = api.friendly_name
    return (
      <div className={className}>
        {state.isModalOpen && (
          <DeleteWarningModal
            isSubmitting={state.isSubmitting}
            onSubmit={this.handleDelete}
            closeModal={this.closeModal} />
        )}
        {props.isEditing ? (
          <EditPanel api={api} onClose={props.onCloseEdit} action="edit" apis={props.apis}/>
        ): (
          <div className={style.Panel} onClick={this.onClickPanel}>
            <div style={{display: 'flex'}}>
              {/*
                Edit feature not for this release
                <img className={style.Edit} src={`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/edit-big.svg`} onClick={this.handleClickEdit} />
              */}
              <span style={{lineHeight: '30px'}}>
                <strong>{apiName}</strong>
              </span>
            </div>
            <div className={style.iconsContainer}>
              <span className={style.Trash} onClick={this.openWarningModal}></span>
              {!props.keepOpen && (
                <span className={classNames(style.caretIcon, style.Toggle, {
                    [style.ToggleActive]: props.isOpen,
                  })}>
                </span>
              )}
            </div>
          </div>
        )}
        {(props.isOpen || props.keepOpen) && !props.isEditing && (
          <div className={style.Steps}>
            <div className={`${style.ApiConfig} ${style.UnstyledList}`}>
              <span className={style.Action}>
                <div className={style.ApiDetails}>
                  <ul>
                    <li className="clip" data-clipboard-text={api.key} ><span>CLIENT ID:</span> <span>{api.key}</span></li>
                    {api.secret && (<li className="clip" data-clipboard-text={api.secret} ><span>CLIENT SECRET:</span> <span>{api.secret}</span></li>)}
                    <li><span>CREATION DATE:</span> <span>{this.getDate(api.date_created)}</span></li>
                  </ul>
                </div>
              </span>
              {/*
                Styled reset element, won't be used in this release
                <span className={style.Reset}>
                  <button className={style.Button}>RESET</button>
                </span>
              */}
              {/* <span className={style.Weak}>(required)</span> */}
            </div>
          </div>
        )}
      </div>
    )
  }

}

ApiItem.propTypes = {
  className: PropTypes.string,
  api: PropTypes.object.isRequired,
  keepOpen: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  shouldMask: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  onCloseEdit: PropTypes.func.isRequired,
}

ApiItem.defaultProps = {
  className: '',
}

export default  ApiItem
