import style from './style.css';
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames';

const ChatAnchor = (props) => {
  const className = classNames(style.ChatAnchor, props.className, style[props.position])
  const finalStyle = props.design ? {
    ...props.style,
    background: props.design.widgetStyleColor,
    color: props.design.widgetStyleTextColor,
  } : props.style
  let src = `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-chats.svg`
  if (props.design && props.design.widgetStyleTextColor === '#000000') {
    src = `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-chats-dark.svg`
  }
  return (
    <div className={className} style={finalStyle} onClick={props.onClick}>
      <img style={{marginRight: '18px'}} src={src} /> Chat with us
    </div>
  )
}

ChatAnchor.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  design: PropTypes.object,
  position: PropTypes.oneOf(['bottom-right', 'bottom-left', 'top-left', 'top-right']),
  onClick: PropTypes.func,
}

ChatAnchor.defaultProps = {
  className: '',
  style: {},
  position: 'bottom-right',
  onClick () {},
}


export default  ChatAnchor
