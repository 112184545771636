/**
 * React-select currently doesn't support specifying a container.
 * This component uses react-tether and react-dimensions to 
 * add the react-select to body so it won't be cut-off when 
 * included inside overflow:hidden parent elements
 */
import React from 'react'
import Select from 'react-select';
import ReactDOM from 'react-dom';
import TetherComponent from './TetherComponent';

export default class TetheredSelect extends Select {
    constructor(props) {
        super(props);
        this.renderOuter = this._renderOuter;
    }

    componentDidMount() {
        this.dropdownFieldNode = ReactDOM.findDOMNode(this);
    }

    _renderOuter() {
        const menu = super.renderOuter.apply(this, arguments);

        const options = {
            attachment: 'top left',
            targetAttachment: 'bottom left',
            constraints: [
                {
                    to: 'window',
                    attachment: 'together',
                }
            ]
        };

        return (
            <TetherComponent
                target={this.dropdownFieldNode}
                options={options}
                matchWidth
            >
                {/* Apply position:static to our menu so that it's parent will get the correct dimensions and we can tether the parent */}
                {React.cloneElement(menu, {style: {position: 'static'}})}
            </TetherComponent>
        )
    }
}