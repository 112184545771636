import React from 'react'
import { Link } from 'react-router-dom';
// UI components
import SaveButton from '../../../ui/forms/SaveButton';

// Local imports
import DetailPane from '../../details/DetailPane';
import TitleBar from '../../details/TitleBar';
import ContentBody from '../../details/ContentBody';
import SubHeader from '../../details/SubHeader';
import Footer from '../../details/Footer';
import SkillList from '../SkillList';
import PypeManagerFeature from '../../PypeManagerFeature';
import style from './style.css';
import ErrorBoundary from 'components/ui/ErrorBoundary';

const Skills = () => (
  <DetailPane data-test="skills-component" className="has-footer">
    <ContentBody>
      <PypeManagerFeature className={style.wrapper} childrenClassName={style.scrollable}>
        <section style={{paddingTop: 0}}>
          <SubHeader style={{marginTop: '0'}}>Manage skills</SubHeader>
          <section>
            <p>
              Skills are used to route chats to specific agents based on their area of expertise.
              Contact your solution designer to use skills in your solutions. <Link to="chat-routing">Click here</Link> to adjust chat routing settings.
            </p>
          </section>
        </section>
        <ErrorBoundary>
          <SkillList />
        </ErrorBoundary>
      </PypeManagerFeature>
    </ContentBody>
    <Footer>
      <SaveButton
        className="btn-primary"
        isLoading={false}
        disabled={false}
        onClick={() => { }}>
        Save changes
      </SaveButton>
    </Footer>
  </DetailPane>
)

export default Skills
