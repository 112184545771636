import React from 'react';
import { Wrapper, Label, ButtonsGroup } from './helpler';
import chatsUtils from 'utils/chats';
const { getChatEndedUser, chatEndedUsers } = chatsUtils;
class Component extends React.Component {
  getMessage({ end_comment, ended_by, participants }) {
    const user = getChatEndedUser(end_comment, ended_by, participants.agent);

    if (user === chatEndedUsers.OTHER) {
      return 'The user left the conversation or lost internet connectivity.';
    } else if (user === chatEndedUsers.AGENT) {
      return 'You ended this conversation.';
    } else {
      return 'The user ended this conversation.';
    }
  }

  render() {
    const { props } = this;
    const { hasPostChatAction, session, onClose, onClassify, canClose } = props;

    return (
      <Wrapper data-test='ended-chat-banner'>
        <Label data-test='get-message'>{this.getMessage(session)}</Label>
        <ButtonsGroup>
          {hasPostChatAction ? (
            <>
              <button
                className='btn btn-default'
                onClick={onClassify}
                data-test='post-chat-button'
              >
                Classify Conversation
              </button>
              {canClose && (
                <button
                  className='btn btn-link'
                  onClick={onClose}
                  data-test='can-close-button'
                >
                  Close without classification
                </button>
              )}
            </>
          ) : (
            <button
              className='btn btn-default'
              onClick={onClose}
              data-test='close-convo-button'
            >
              Close Conversation
            </button>
          )}
        </ButtonsGroup>
      </Wrapper>
    );
  }
}

export default Component;
