import upload from '../TUSClient'
import { apiConstants as types } from '../constants'
import RESTClient from '../RESTClient';

const requestApis = () => ({
  type: types.REQUEST,
})

const receiveApis = (data) => ({
  type: types.RECEIVE,
  data,
})

const fetchApi = () => (dispatch, getState) => {
  dispatch(requestApis())
  return new Promise((resolve, reject) => {
    const pypeId = getState().pype.details.id
    
    RESTClient.apiGetKeys(pypeId).then((response) => {
      return response.body;
    }).then((data) => {
      if(data.error){
        reject(data.error)
      } else {
        dispatch(receiveApis(data.keys))
        resolve(data);
      }
    }).catch((err) => {
      reject(err);
    });
  });
}

const createApi = (data) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const pypeId = getState().pype.details.id
    RESTClient.apiCreateKeys(data, pypeId).then((response) => {
      return response.body;
    }).then((data) => {
      if(data.error){
        reject(data.error)
      } else {
        dispatch({type: types.CREATE_API, data: data})
        resolve(data);
      }
    }).catch((err) => {
      reject(err);
    });
  })
}

const deleteApi = (api) => (dispatch) => {
  return new Promise((resolve, reject) => {
    RESTClient.apiDeleteKey(api).then((response) => {
      return response.body;
    }).then((data) => {
        dispatch({type: types.REMOVE_API, id: api})
        resolve(data)
    }).catch((err) => {
      reject(err);
    });
  })
}

export {
  fetchApi,
  createApi,
  deleteApi,
}
