import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import { connect } from "react-redux";

import {
    doesUserHasRole, PermissionDenied,
    Login, PYPE_AGENT_ROLES, PYPE_MANAGER_ROLES
} from "bot-user-session";

import './globalUi'
import { LayoutEmpty, Unauthorized, NotFound, Header } from "components/ui";

import Main from "components/main";
import PypeSelect from 'components/PypeSelect'
import withAuth from 'components/withAuth'
import {
  createInstance,
  OptimizelyProvider,
} from '@optimizely/react-sdk';

import { createMuiTheme, ThemeProvider, StylesProvider, createGenerateClassName } from '@material-ui/core/styles';
import { withLDConsumer } from "launchdarkly-react-client-sdk";

const primary500 = '#0a3ab4';
const grey = '#bbbbbb';
const grey100 = '#f9f9f9';
const offGrey = '#7a6769';

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Open Sans',
    fontSize: '16px',
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        '& .MuiSvgIcon-root': {
          '& g g g path': {
            fill: grey,
          }
        },
        '&.Mui-focused': {
          '& .MuiSvgIcon-root': {
            '& g g g path': {
              fill: primary500,
            }
          }
        }
      }
    },
    MuiFormControl: {
      root: {
        verticalAlign: 'middle',
      }
    },
    MuiAccordion: {
      root: {
        '&.Mui-expanded': {
          margin: '0'
        }
      }
    },
    MuiAccordionSummary: {
      root: {
        minHeight: '48px',
        '&.Mui-expanded': {
          backgroundColor: grey100,
          minHeight: '48px',
        }
      },
      expandIcon: {
        transform: 'rotate(-90deg)',
        '& span svg path': {
          fill: offGrey,
        },
        '&.Mui-expanded': {
          transform: 'rotate(-180deg)',
          '& span svg path': {
            fill: primary500,
          },
        }
      },
      content: {
        margin: 0,
        '&.Mui-expanded': {
          margin: 0,
          '& h5': {
            color: primary500,
            fontFamily: 'Open Sans',
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: 1.5,
            margin: 0,
          }
        },
        '& h5': {
          color: offGrey,
          fontFamily: 'Open Sans',
          fontSize: '16px',
          fontWeight: 600,
          lineHeight: 1.5,
          margin: 0,
        },
      },
    },
    MuiAccordionDetails: {
      root: {
        padding: '16px',
        '& .MuiSvgIcon-root': {
          marginRight: '1px',
          cursor: 'pointer',
        }
      }
    },
    MuiButton: {
      root: {
        padding: "6px 10px",
      }
    },
    MuiIconButton: {
      sizeSmall: {
        margin: 8
      }
    },
    MuiTabs: {
      indicator: {
        backgroundColor: primary500,
        height: '4px',
      },
      root: {
        backgroundColor: grey100,
      },
    },
    MuiTab: {
      wrapper: {
        fontFamily: 'Open Sans',
        fontWeight: 600,
        lineHeight: 1.5,
        fontSize: '16px',
        textDecoration: 'none',
      },
      textColorPrimary: {
        '& not(.Mui-selected)': {
          color: offGrey,
        }
      }
    },
    MuiTooltip: {
      tooltipPlacementTop: {
        margin: "8px 0 !important"
      },
      tooltip: {
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        padding: "8px 12px",
        fontSize: 14,
        fontWeight: 400,
        lineHeight: "18px",
      }
    }
  },
});

const generateClassName = createGenerateClassName({
productionPrefix: 'ap'
});

const { config } = window;

const optimizely = createInstance({
  sdkKey: config.PS_OPTIMIZELY_SDK_KEY,
});


const DashboardRoute = ({
  isAuthenticated,
  authenticationError,
  isAllowed = false,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {

      if (!isAuthenticated && authenticationError) {
        return (
          <div>
            <Header showProfile={false}/>
            <PermissionDenied />
          </div>
        );
      }

      if (!isAuthenticated && !authenticationError) {
        return (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        );
      }

      if (isAuthenticated && !isAllowed) {
        return <Redirect to="/unauthorized" />;
      }

      return (
        <div>
          <Header/>
          <Main/>
        </div>
      );
    }}
  />
);

const EmptyLayoutRoute = ({
  component: Component,
  isAuthenticated,
  authenticationError,
  isAllowed = false,
  ...rest
}) => (
  <Route
    {...rest}
    render={matchProps => {   
      if (!isAuthenticated && authenticationError) {
        return (
          <div>
            <Header showProfile={false}/>
            <PermissionDenied />
          </div>
        );
      }

      if (!isAuthenticated && !authenticationError) {
        return (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: matchProps.location }
            }}
          />
        );
      }

      if (isAuthenticated && isAllowed && !authenticationError) {
        return <Redirect to="/start" />
      }

      return (
        <LayoutEmpty>
          <Header/>
          <Component {...matchProps} />
        </LayoutEmpty>
      );
    }}
  />
);

const NoLayoutRoute = ({
  component: Component,
  isAuthenticated,
  authenticationError,
  isAllowed = false,
  ...rest
}) => (
  <Route
    {...rest}
    render={matchProps => {
      if (!isAuthenticated && authenticationError) {
        return (
          <div>
            <Header showProfile={false}/>
            <PermissionDenied />
          </div>
        );
      }

      if (!isAuthenticated && !authenticationError) {
        return (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: matchProps.location }
            }}
          />
        );
      }
      if (isAuthenticated && !isAllowed) {
          return <Redirect to="/unauthorized" />;
      }
      return (
        <div>
          <Header/>
          <Component {...matchProps} />
        </div>
      );
    }}
  />
);

class App extends React.Component {

  componentWillMount() {
    if (this.props.flags && this.props.flags.brandInfo) {
      let favIconHref = `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/favicon.ico`;
      let appleTouchIconHref = `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/apple-touch-icon.png`
      let docTitle = "Pypestream";
      if (this.props.flags.brandInfo.favicon) {
        favIconHref = this.props.flags.brandInfo.favicon;
        document.querySelector("link[rel~='icon']").href = favIconHref;
      }
      if (this.props.flags.brandInfo.appleTouchIcon) {
        appleTouchIconHref = this.props.flags.brandInfo.appleTouchIcon;
        document.querySelector("link[rel~='apple-touch-icon']").href = appleTouchIconHref;
      }
      if(this.props.flags.brandInfo.title) {
        docTitle = this.props.flags.brandInfo.title;
      }
      document.title = docTitle;
    }
  }

  render() {
    const { isAuthenticated, authenticationError, user, flags } = this.props

    const basename = window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE || "";

    const isPypeAdmin = doesUserHasRole(PYPE_MANAGER_ROLES);
    const isAgent = doesUserHasRole(PYPE_AGENT_ROLES);
    const optimizelyProperties = (user && user.optimizelyUserAttributes) || '';

    return (
      <OptimizelyProvider
          optimizely={optimizely}
          user={{
            id: user ? user.user_id : '',
            attributes: {
              optimizelyProperties,
            }
          }}
        >
        <StylesProvider generateClassName={generateClassName}>
          <ThemeProvider theme={theme}>
            <Router basename={basename} onUpdate={() => window.Appcues.page()}>
              <Switch>
                <Route exact path="/">
                  <Redirect to="/start" />
                </Route>

                <Route
                  path="/login"
                  render={props => {
                    return <Login {...props} flags={flags} brandInfo={{...flags.brandInfo}} whitelabelEnabled={true} error={authenticationError} title="Pype Manager" />
                  }}
                />
              
                  <NoLayoutRoute
                    exact
                    path="/start"
                    component={withAuth(PypeSelect)}
                    isAuthenticated={isAuthenticated}
                    authenticationError={authenticationError}
                    isAllowed={isPypeAdmin || isAgent}
                  />

                  <EmptyLayoutRoute
                    path="/unauthorized"
                    component={Unauthorized}
                    isAuthenticated={isAuthenticated}
                    authenticationError={authenticationError}
                    isAllowed={isPypeAdmin || isAgent}
                  />

                  <EmptyLayoutRoute
                    path="/not-found"
                    component={NotFound}
                    isAuthenticated={isAuthenticated}
                    authenticationError={authenticationError}
                  />

                  <DashboardRoute
                    path="/:pypeId"
                    isAuthenticated={isAuthenticated}
                    authenticationError={authenticationError}
                    isAllowed={isPypeAdmin || isAgent}
                  />
              
              </Switch>
            </Router>
          </ThemeProvider>
        </StylesProvider>
      </OptimizelyProvider>
    );
  }
};

const mapStateToProps = state => {
  return {
    isAuthenticated: state.botUserSession.isAuthenticated,
    authenticationError: state.botUserSession.error !== null,
    user: state.botUserSession.user,
  };
};

export default connect(mapStateToProps)(withLDConsumer()(App));
