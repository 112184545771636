import _ from 'lodash'

const getTagUid = (tag) => `${tag.code}::${tag.description}`

// Gets a tag given a combined 'code::description' string
const getTag = (tagUid) => (tagsStore) => {
  const tags = _.values(tagsStore)
  return _.find(tags, (tag) => getTagUid(tag) === tagUid)
}

// Parses a 'code::description' string into {code, description}
const parseTagString = (tagUid) => {
  if (tagUid.indexOf('::') !== -1) {
    const [code, description] = tagUid.split('::')
    return {
      code,
      description,
    }
  } else {
    // Support old tag strings with a space separator
    const i = tagUid.indexOf(' ')
    const code = tagUid.substr(0, i)
    const description = tagUid.substr(i + 1)
    return {
      code,
      description,
    }
  }
}

export default {
  getTagUid,
  getTag,
  parseTagString,
}
