// External
import React from 'react'
import _ from 'lodash';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import cx from 'classnames';

// Local
import TagRow from './TagRow';

const getSortedTags = (tags, sortFields) => {
  return _.sortBy(_.values(tags), sortFields)
}
class TagTable extends React.Component {
  render() {
    const { props } = this;
    const { 
      tagsStore, 
      enableTagCodes, 
      tagEditing, 
      isAddingNewTag, 
      onEditRow, 
      onFinishEditRow, 
      onCloseRowEdit 
    } = props; 

    let sortFields = ['description'];

    if (enableTagCodes) {
      sortFields.unshift('code');
    }

    const tags = getSortedTags(tagsStore, sortFields)

    return (
      <div className="tag-table">
        <div className="table-header">
          {enableTagCodes ? <div className="column-1">Tag code</div> : null}
          <div className="column-2">Name</div>
          <div className="column-3" />
        </div>
        <div className={ cx('table-body', { 'scroller': tags.length > 7 }) }>
          {tags.map((tag) => (
            <TagRow key={tag.id} tag={tag}
              enableTagCodes={enableTagCodes}
              canEdit={_.isNil(tagEditing) && !isAddingNewTag}
              isEditing={tagEditing === tag.id}
              isTableEditing={!_.isNil(tagEditing)}
              onClickEdit={onEditRow}
              onFinishEdit={onFinishEditRow}
              onClose={onCloseRowEdit} />
          ))}
        </div>
      </div>
    );
  }
}

TagTable.propTypes = {
  tags: PropTypes.array.isRequired,
  tagEditing: PropTypes.any,  // nil or an id string keying a tag
  isAddingNewTag: PropTypes.bool.isRequired,
  enableTagCodes: PropTypes.bool.isRequired,
  onEditRow: PropTypes.func.isRequired,
  onFinishEditRow: PropTypes.func.isRequired,
  onCloseRowEdit: PropTypes.func.isRequired
};

TagTable.defaultProps = {
  tags: []
};

const mapStateToProps = (state, props) => {
  return {
    tagsStore: state.tags.store
  };
};

export default  connect(
  mapStateToProps
)(TagTable);
