import { createStore, applyMiddleware, compose } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import rootReducer from '../reducers';
import stompResponseMiddleware from '../middlewares/stomp-response-middleware';

export const history = createBrowserHistory ? createBrowserHistory() : [];

const initialState = {};
const enhancers = [];

/**
 * Sends crash reports as state is updated and listeners are notified.
 */
const crashReporter = (store) => (next) => (action) => {
  try {
    return next(action);
  } catch (err) {
    console.error('Caught an exception!', err, action, store.getState());
    throw err;
  }
};

const middleware = [
  thunk,
  routerMiddleware(history),
  stompResponseMiddleware,
  crashReporter,
];

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

export const store = createStore(
  connectRouter(history)(rootReducer),
  initialState,
  composedEnhancers
);
