import { BOT_USER_SESSION_LOGOUT } from "bot-user-session";
import {transfersConstants} from '../constants'

const getInitialState = () => ({
  transferNotification: {},
});

export default function transfers(state = getInitialState(), action) {
  switch (action.type) {
    case BOT_USER_SESSION_LOGOUT: {
      return getInitialState();
    }
    case transfersConstants.UPDATE_NOTIFICATION:
      return Object.assign({}, state, {transferNotification: action.transferNotification})
    default:
      return state
  }
}
