import {connect} from 'react-redux';
import sdk from 'sdk';
import Component from './Component';

const {widgetsActions} = sdk;

const mapStateToProps = (state) => ({
  customerName: state.pype.details.customerName
})

const mapDispatchToProps = (dispatch) => ({
  onSubmit () {
    return dispatch(widgetsActions.createWidget({
      stream_id: window.globalUi.webchat.stream,
      style: JSON.stringify(window.globalUi.webchat.design),
      widget_name: 'Chat widget',
    }, window.globalUi.webchat.bannerFileObject))
    // return dispatch(widgetsActions.createWidget({
    //   stream_id: window.globalUi.webchat.stream,
    //   style: JSON.stringify(window.globalUi.webchat.design),
    // }, window.globalUi.webchat.bannerFileObject)).then((data) => {
    //   // console.log(data)
    // })
  },
})

export default  connect(
  mapStateToProps,
  mapDispatchToProps
)(Component)
