import _ from 'lodash';
import style from './style.css';
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames';

// Components
import Banner from '../Banner';
import TitleBar from '../TitleBar';
import ChatContent from '../ChatContent';
import ChatBox from '../ChatBox';

class ChatWindow extends React.Component {

  getChildContext () {
    const {props} = this
    return {
      design: props.design,
      pypeLogo: props.pypeLogo,
    }
  }
  render () {
    const {props} = this
    const {widgetPosition, bannerUrl, styleSelection} = props.design
    const className = classNames(style.ChatWindow, props.className, {
      [style.isNotVisible]: !props.isVisible,
      [style.isVisible]: props.isVisible,
      [style.nudgeTop]: widgetPosition === 'top-left' || widgetPosition === 'top-right',
    })
    return (
      <div className={className}>
        {_.isEmpty(bannerUrl) ? (
          <TitleBar onCloseClick={props.onCloseClick}>{props.title}</TitleBar>
        ) : (
          <Banner url={bannerUrl} />
        )}
        <ChatContent />
        <ChatBox />
      </div>
    )
  }

}

ChatWindow.childContextTypes = {
  design: PropTypes.object,
  pypeLogo: PropTypes.string,
}

ChatWindow.propTypes = {
  className: PropTypes.string,
  design: PropTypes.object,
  title: PropTypes.string,
  pypeLogo: PropTypes.string,
  isVisible: PropTypes.bool,
}

ChatWindow.defaultProps = {
  className: '',
  title: '',
  isVisible: true,
}

export default  ChatWindow
