import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

export const renderToolTip = (props) => {
  const ToolTip = withStyles({
    tooltip: {
      width: `${props.width || '60px'}`,
      height: `${props.height || '34px'}`,
      borderRadius: `${props.borderRadius || '4px'}`,
      backgroundColor: `${props.backgroundColor || 'rgba(0, 0, 0, 0.8)'}`,
      color: `${props.backgroundColor || '#ffffff'}`,
      boxShadow: `${props.boxShadow || '0 0 6px 0 rgba(0, 0, 0, 0.3)'}`,
      marginLeft: `${props.left || 0}`,
      marginTop: `${props.top || 0}`,
      fontSize: `${props.fontSize || '14px'}`,
      fontFamily: `${props.fontFamily || "Open Sans"}`,
      fontWeight: `${props.fontFamily || 'normal'}`,
      fontStretch: `${props.fontStretch|| 'normal'}`,
      fontStyle: `${props.fontStyle || 'normal'}`,
      lineHeight: `${props.lineHeight || '1.29'}`,
      letterSpacing: `${ props.letterSpacing || 'normal'}`,
      padding: `${props.padding || '8px 12px'}`,
    },
  })(Tooltip);

  return (
    <ToolTip title={props.title} placement={props.placement || "top"}>
      {props.node}
    </ToolTip>
  );
};
