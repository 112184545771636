import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import Linkify from 'react-linkify';

import FilesUtils from 'utils/files';
import assets from 'utils/assets';

import css from "./Message.css";

class MessageMediaBlock extends React.Component{
	render() {
		const message = this.props.message;

    const isBotMessage = !_.isEmpty(message.app_object) && message.from_side === 'bot'

    let botMessageData
    if (isBotMessage) {
      botMessageData = JSON.parse(atob(message.app_object))
    }

    const text = isBotMessage ? botMessageData.label : message.msg

	 	if(message.msg_type === 'text' || message.msg_type === 'embed' || message.msg_type === 'system') {
	 		return <Linkify properties={{target: '_blank'}}>{text}</Linkify>
    }

    const isFileReady = (message.file_status === 'ready' && message.file)

    const isOwnMessage = message.from_side !== 'consumer'
    const color2 = this.props.flipIconStyle ? 'w' : 'b';
    const color1 = this.props.flipIconStyle ? 'b' : 'w';
    const iconColor = isOwnMessage ? color1 : color2;

    if (!isFileReady) {
      return (
        <div className="message-media-block">
          <img src={`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/spiffygif_32x32.gif`} />
          <p className="file-caption">
            <Linkify properties={{target: '_blank'}}>{text}</Linkify>
          </p>
        </div>
      );
    }

    const filePath = message.file;
    const fileExtension = filePath.substr(filePath.lastIndexOf('.') + 1);
    const fileName = filePath.replace(/^.*[\\\/]/, '');
    const fileType = FilesUtils.fileTypeForExtension(fileExtension);

    if (fileType) {
      return (
        <div className="message-media-block">
          <img
            className={css.fileIcon}
            src={assets.path(`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-${fileType}-type-${iconColor}.svg`)} />
          <a href={filePath} target="_blank">{fileName}</a>
          <p className="file-caption">
            <Linkify properties={{target: '_blank'}}>{message.msg}</Linkify>
          </p>
        </div>
      );
    }
    return (
        <div className="message-media-block">
          <a href={filePath} target='_blank'>
            <img src={filePath} />
          </a>
          <p className="file-caption">
            <Linkify properties={{target: '_blank'}}>{text}</Linkify>
          </p>
        </div>
     );
	}
}

MessageMediaBlock.propTypes = {
  message: PropTypes.object.isRequired,
}

MessageMediaBlock.defaultProps = {
	flipIconStyle: false
}
export default  MessageMediaBlock
