import {
  REQUEST_AGENTS, RECEIVE_AGENTS, RECEIVE_AGENTS_ACK,
  REMOVE_AGENT, UPDATE_AGENT, CREATE_AGENT,
  CREATED_AGENT, REMOVED_AGENT,
  UPDATE_AGENT_STATUS,
  UPDATED_AGENT
} from "../constants"
import _ from 'lodash';
import { BOT_USER_SESSION_LOGOUT } from "bot-user-session";

const getInitialState = () => ({
  isFetching: false,
  didInvalidate: false,
  agents: [],
  createdAgent: {},
});

export default function agentsForPype(state = getInitialState(), action) {
  switch (action.type) {
    case BOT_USER_SESSION_LOGOUT: {
      return getInitialState();
    }
    case REQUEST_AGENTS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      })
    case RECEIVE_AGENTS_ACK:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false
      }
    case RECEIVE_AGENTS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        agents: _.sortBy(action.agents.map((agent) => {
          // TODO v3. platform should return names of agent
          if(!agent.first_name) {
            agent.first_name = ''
          }
          if(!agent.last_name) {
            agent.last_name = ''
          }
          // TODO v3. platform should return at least one skill per agent
          if(!agent.skills) {
            agent.skills = []
          }
          return agent
        }), 'creation_date')
      })
    case CREATE_AGENT:
      return {...state, isFetching: true}
    case UPDATE_AGENT:
      return {...state, isFetching: true}
    case CREATED_AGENT:
      let existing = _.find(state.agents, {id: action.agent.id});
      if(!existing){ // newly created agent
        return Object.assign({}, state, {
          agents: [...state.agents, action.agent],
          isFetching: false
        })
      }
      var updatedAgent = action.agent;
      var agents = _.map(state.agents, (agent) => {
        return agent.id === updatedAgent.id ? {
          ...agent,
          ..._.omitBy(updatedAgent, _.isNull)
        } : agent
      });

      return Object.assign({}, state, {
        agents,
        isFetching: false
      })
    case UPDATED_AGENT:
      var updatedAgent = action.agent;
      var agents = _.map(state.agents, (agent) => {
        return agent.id === updatedAgent.id ? {
          ...agent,
          ..._.omitBy(updatedAgent, _.isNull)
        } : agent
      });
      return {
        ...state,
        agents,
        isFetching: false
      }
    case REMOVE_AGENT: {
      let agents = _.filter(state.agents, (agent) => agent.id !== action.agentId)
      return Object.assign({}, state, {
        agents
      })
    }
    case REMOVED_AGENT:
      return {
        ...state,
        agents: state.agents.filter((agent) => agent.id !== action.agentId),
      }
    case UPDATE_AGENT_STATUS: {
      const {agentId,status} = action;
      const agents = _.map(state.agents, (agent) => {
        if(agent.id === agentId){
          if(status === 'offline'){
            agent.current_conv = 0;
          }
          agent.status = status;
          return agent;
        }
        return agent;
      })
      return {
        ...state, 
        agents
      }
    }
    default:
      return state
  }
}
