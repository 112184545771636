import _ from 'lodash';
import React from 'react'
import PropTypes from 'prop-types';
import cx from 'classnames';

// UI components
import LabelCheckbox from '../../ui/forms/LabelCheckbox';
import SaveButton from '../../ui/forms/SaveButton';
import ErrorBoundary from 'components/ui/ErrorBoundary';

// Local imports
import DetailPane from './DetailPane';
import TitleBar from './TitleBar';
import ContentBody from './ContentBody';
import SubHeader from './SubHeader';
import Footer from './Footer';
import constants from 'core/constant';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import sdk from 'sdk';
import activityTracker from "../../../utils/activityTracker";

const { autoresponderActions } = sdk;

const validators = {

  /*
    All methods in this namespace will return a string on error and
    undefined on validating.
  */

  autoresponse(enabled, message) {
    const noMessage = _.isNil(message) || message.trim() === ''
    if (enabled && noMessage)
      return 'Field required'
  }

}
const SETTING_NAME = 'autoresponses';
class AutoResponders extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      autoresponses: [],
      isSubmitting: false,
    };

    // Binding methods
    this.handleSubmit = this.handleSubmit.bind(this);

  }

  componentWillMount() {
    const { customerName } = this.props

    this.props.fetchAutoresponders();
    activityTracker.logEvent(activityTracker.eventTypeNames.VIEW_PYPE_SETTING,{settingName: SETTING_NAME, customerName});
  }

  /*
    Return a new array of sorted autoresponses. The current sort order
    is: [offline, busy]
  */
  sortResponses(list) {
    return _.reverse(_.sortBy(list, 'type'))
  }

  componentWillReceiveProps(props){
    this.setState({
      autoresponses: this.sortResponses(props.autoresponses),
    })
  }

  // Event handlers

  handleSubmit() {
    const { customerName } = this.props;
    
    this.setState({isSubmitting: true})
    const onSuccess = () => {
      window.appCreateToast({
        message: 'Changes saved successfully.',
        level: 'success'
      })
      activityTracker.logEvent(activityTracker.eventTypeNames.UPDATE_PYPE_SETTING,{settingName: SETTING_NAME,action: activityTracker.eventActionType.UPDATE, customerName});
    }
    const onError = (error) => {
      window.appCreateToast({
        message: 'Error while saving changes.',
        level: 'error'
      })
    }
    const onDone = () => {
      this.setState({isSubmitting: false})
    }
    this.props.updateAutoresponders(this.state.autoresponses)
      .then(onSuccess, onError)
      .then(onDone)
  }

  bindTo(prop, index) {
    /*
      @prop: ['enable', 'text']
    */
    return (event) => {
      const autoresponses = _.cloneDeep(this.state.autoresponses)
      const value = prop === 'enable' ? !autoresponses[index].enable : event.target.value
      autoresponses[index][prop] = value
      this.setState({autoresponses})
    }
  }

  isFormValid() {
    return _.every(this.state.autoresponses, (autoresponse) => (
      validators.autoresponse(autoresponse.enable, autoresponse.text) === undefined
    ))
  }

  hasChanges() {
    return !_.isEqual(
      this.sortResponses(this.props.autoresponses),
      this.sortResponses(this.state.autoresponses)
    )
  }

  render() {
    const errorMessages = this.state.autoresponses.map((autoresponse) => (
      validators.autoresponse(autoresponse.enable, autoresponse.text)
    ))
    return(
      <DetailPane className="autoresponders has-footer">
        <ErrorBoundary>
          <ContentBody>
            {this.state.autoresponses.filter((autoresponse) => !!constants.AUTO_RESPONDER_STRINGS[autoresponse.type]).map((autoresponse, i) => (
              <section key={autoresponse.type} style={{paddingTop: 0}}>
                <SubHeader>{constants.AUTO_RESPONDER_STRINGS[autoresponse.type].header}</SubHeader>
                <section>
                  <p>
                    {constants.AUTO_RESPONDER_STRINGS[autoresponse.type].description}
                  </p>
                </section>
                <section>
                  <LabelCheckbox
                    label={constants.AUTO_RESPONDER_STRINGS[autoresponse.type].checkboxLabel}
                    name="enable"
                    value={autoresponse.enable}
                    onClick={this.bindTo('enable', i)} />
                </section>
                <section className={cx({
                  'fade': !autoresponse.enable,
                  'has-error': errorMessages[i] !== undefined
                })}>
                  <p className="label-text">{constants.AUTO_RESPONDER_STRINGS[autoresponse.type].messageLabel}</p>
                  <textarea
                    className="form-control"
                    rows="3"
                    name="text"
                    maxLength={300}
                    // Server is sending back a weird character instead of a single quote in the initial text
                    value={autoresponse.text.replace('\u0019', "'")}
                    onChange={this.bindTo('text', i)}
                    disabled={!autoresponse.enable}>
                  </textarea>
                  {typeof errorMessages[i] === 'string' ?
                    <p className="small text-danger m-b-0">{errorMessages[i]}</p>
                    : null}
                </section>
              </section>
            ))}
          </ContentBody>
        </ErrorBoundary>
        <Footer>
          <SaveButton
            className="btn-primary"
            disabled={!this.isFormValid() || !this.hasChanges()}
            isLoading={this.state.isSubmitting}
            onClick={this.handleSubmit}>
            Save changes
          </SaveButton>
        </Footer>
      </DetailPane>
    )
  }
}

AutoResponders.propTypes = {
  autoresponses: PropTypes.arrayOf(PropTypes.shape({
    // The shape of an autoresponse object
    enable: PropTypes.bool.isRequired,
    text: PropTypes.string.isRequired,
  })).isRequired,
}

AutoResponders.defaultProps = {
  autoresponses: []
}

const mapStateToProps = function(state) {
  return {
    autoresponses: state.autoresponder.autoresponses,
    customerName: state.pype.details.customerName
  }
};

const mapDispatchToProps = function(dispatch) {
  return bindActionCreators({
    fetchAutoresponders: autoresponderActions.fetchAutoresponders,
    updateAutoresponders: autoresponderActions.updateAutoresponders
  }, dispatch);
};

export default  connect(
  mapStateToProps,
  mapDispatchToProps
)(AutoResponders);
