import React from 'react';
import PropTypes from 'prop-types';
import Popover from 'react-simple-popover';
import {
  PypeLogo,
  PopoverBackdrop
} from './helpers';
import Pype from 'components/ui/PypesListItem'

const containerStyle = {
  zIndex: 9999,
  padding: 0,
  margin: 0,
  transform: 'translate3d(15px, -84px, 0)'
}
const popoverCss = {
  width: '270px',
  borderRadius: '5px',
  boxShadow: '0 10px 20px 0 rgba(0, 0, 0, 0.19), 0 6px 6px 0 rgba(0, 0, 0, 0.26)',
  backgroundColor: '#ffffff',
  maxHeight: '700px',
  overflowY: 'auto',
  padding: 0
}

class PypesListDropdown extends React.Component {
  state = {
    isListVisible: false
  }
  toggleList = () => {
    if(this.props.pypes.length < 2) {
      return;
    }
    this.setState({
      isListVisible: !this.state.isListVisible
    })
  }
  componentWillReceiveProps(nextProps) {
    if(nextProps.showList !== this.props.showList) {
      this.setState({
        isListVisible: nextProps.showList
      })
    }
  }
  render() {
    const { isListVisible } = this.state;
    const { pypes, activePypeId, onPypeSelect } = this.props;
    const activePype = pypes.find(pype => (pype.id === activePypeId))
    const logo = activePype && activePype.logo ? activePype.logo : ''

    return (
      <div>
        <PypeLogo
          isClickable={pypes.length > 1}
          ref={(node) => { this.target = node }}
          onClick={this.toggleList}>
          {logo.length ? <img src={logo} alt="pype logo"/> : null}
        </PypeLogo>
        <Popover
          placement='right'
          target={this.target}
          show={isListVisible}
          containerStyle={containerStyle}
          style={popoverCss}>
            <div>
            {pypes.map(({id, logo, env, name}) => (
              <Pype 
                key={id}
                logo={logo}
                name={name}
                env={env}
                showArrow={false}
                onClick={() => onPypeSelect(id)}
              />
              ))}
            </div>
        </Popover>
        { isListVisible ? <PopoverBackdrop onClick={this.toggleList}></PopoverBackdrop> : null }
      </div>);
  }
}

PypesListDropdown.defaultProps = {
};

PypesListDropdown.propTypes = {
  pypes: PropTypes.array.isRequired,
  activePypeId: PropTypes.string.isRequired,
  onPypeSelect: PropTypes.func.isRequired,
  showList: PropTypes.bool.isRequired
};


export default PypesListDropdown;
