import {
  REQUEST_PYPES,
  RECEIVE_PYPES,
} from "./../constants"
import RESTClient from '../RESTClient';

const requestPypes = () => ({
  type: REQUEST_PYPES
})

const receivePypes = (pypes) => {
  return (dispatch, getState) => {
    dispatch({
      type: RECEIVE_PYPES,
      pypes
    });
  };
}

// Avoid crashing entire app if getPypeDetails fails for some env
const reflect = p => p.then(success => ({success, status: "fulfilled" }),
                            error => ({error, status: "rejected" }));

export const fetchPypes = () => {

  return (dispatch, getState) => {
    dispatch(requestPypes())
    return new Promise(function(resolve, reject){

        const userId = getState().botUserSession.user.user_id 
        let envs = []
        const userEnvs = getState().botUserSession.user.envs;

        userEnvs.forEach(env => {
          envs = [...envs, ...env.envs]
        })
        envs = Array.from(new Set([...envs]));

        const requests = []

        envs.forEach(env => {
          requests.push(RESTClient.getPypeDetails(env, userId))
        });

        let allPypes = []
        const { session } = getState()

        Promise.all(requests.map(reflect)).then((req)=> {

          var reqSuccess = req.filter(x => x.status === "fulfilled");
          
          reqSuccess.forEach(({success}, i) => {
            const pypesForEnv = success.body.filter(pype => {
              if(!session.roles[pype.id]) return false; 
              return session.roles[pype.id].includes('agent') 
              || session.roles[pype.id].includes('pype_admin')
            });
            pypesForEnv.forEach(pype => {
              pype.env = envs[i]
              if (pype.agent_routing == null) {
                pype.agent_routing = 'pypestream'
              }
            })
            allPypes = [...allPypes, ...pypesForEnv]
          })
          dispatch(receivePypes(allPypes.sort((a, b) => a.name.localeCompare(b.name))))
          resolve()
        })
        .catch((error) => {
          reject('Unable to get pypes');
        }) 


    });

   
  }
}