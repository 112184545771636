import {
  sessionConstants
} from "./../constants"
import Fingerprint2 from 'fingerprintjs2';
import {guid} from '../common/utils/common-utils';


const _updateSession = (data) => ({
  type: sessionConstants.UPDATE_SESSION,
  data
})

export const updateSession = (data) => (dispatch) =>{
  dispatch(_updateSession(data))
  return Promise.resolve()
}

export const updateDeviceIdIfNeeded = () => async (dispatch,getStore) =>{
  const store = getStore();
  if(!store.session.device_id) {
    const components = await Fingerprint2.getPromise();
    //the store may change while on the above call;
    const storeAgain = getStore();
    if(!storeAgain.session.device_id) {
      const values = components.map(function (component) {return component.value})
      const murmur = Fingerprint2.x64hash128(values.join(''), 31)
      const device_id = murmur + "-" + guid();
      dispatch(_updateSession({device_id}))
    }

  }
  return Promise.resolve()
}
