// External
import _ from 'lodash';
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux';

import cx from 'classnames';
import Icon from '../../ui/forms/Icon';
import LabelInput from '../../ui/forms/LabelInput';
import DeleteTagModal from './DeleteTagModal';

// Pypestream
import sdk from 'sdk';
import activityTracker from "../../../utils/activityTracker";
const {tagActions} = sdk;

export class NewTagRow extends React.Component {
  constructor(props) {
    super(props)
    this.handleDigitError = _.throttle(this.handleDigitError, 800, {trailing: false})
    this.state = {
      code: props.code || '',
      description: props.description || '',
      isSubmitting: false,
      errors: {},
      isModalOpen: false,
    }
    this.errorMessages = {
      codeDuplication: "You can't have more than one tag with the same tag code.",
      descriptionDuplication: "You can't have more than one tag with the same description.",
      codeAbsence: "Please enter a tag code.",
      descriptionAbsence: "Please enter a description.",
    }
  }

  componentDidMount() {
    this._isMounted = true
    window.addEventListener('mousedown', this.handleClickOutside, false)
  }

  componentWillUnmount() {
    this._isMounted = false
    window.removeEventListener('mousedown', this.handleClickOutside)
  }

  hasChanges = () => {
    return this.state.code !== this.props.code ||
      this.state.description !== this.props.description
  }

  handleClickOutside = (event) => {
    if (this.state.isModalOpen) {
      return
    }
    const $rowContent = event.target.closest('.row-content')
    if ($rowContent == null) {
      if (!this.hasChanges()) {
        return this.handleClose()
      }
      return this.handleSubmit()
    }
  }

  bindTo = (prop) => {
    return (event) => this.setState({
      [prop]: event.target.value
    })
  }

  handleKeyDown = (event) => {
    if (this.state.isSubmitting)
      return
    if (event.keyCode === 13 || event.charCode === 13) {
      this.handleSubmit()
    } else if (event.keyCode === 27 || event.charCode === 27) {
      this.handleClose()
    }
  }

  clearForm = () => {
    this.setState({code: '', description: ''})
  }

  validateCode = () => {
    const { props, state } = this
    const code = state.code.trim()
    const { tagsStore } = props
    let otherTags = _.values(tagsStore)

    if (props.isEditing) {
      otherTags = otherTags.filter(tag => tag.id !== props.id)
    }

    if (code !== '' && _.some(otherTags, (tag) => tag.code === code)) {
      this.setState({
        errors: Object.assign({}, state.errors, { code: this.errorMessages.codeDuplication })
      })
    } else {
      this.setState({
        errors: Object.assign({}, state.errors, { code: null })
      })
    }
  }

  validateDescription = () => {
    const { props, state } = this
    const description = state.description.trim()
    const { tagsStore } = props
    let otherTags = _.values(tagsStore)

    if (props.isEditing) {
      otherTags = otherTags.filter(tag => tag.id !== props.id)
    }

    if (_.some(otherTags, tag => tag.description === description)) {
      this.setState({
        errors: Object.assign({}, state.errors, { description: this.errorMessages.descriptionDuplication })
      })
    } else {
      this.setState({
        errors: Object.assign({}, state.errors, { description: null })
      })
    }
  }

  isFormValid = () => {
    const {props, state} = this
    const { tagsStore } = props
    let otherTags = _.values(tagsStore)
    
    if (props.isEditing)
      otherTags = otherTags.filter(tag => tag.id !== props.id)

    const code = state.code.trim()
    const description = state.description.trim()

    if (props.enableTagCodes && code === '') {
      return this.errorMessages.codeAbsence
    }

    if (description === '') {
      return this.errorMessages.descriptionAbsence
    }

    const codeAlreadyExists = code !== '' &&
      _.some(otherTags, (tag) => tag.code === code)

    const descriptionAlreadyExists = _.some(otherTags, (tag) => (
      tag.description === description
    ))

    if (props.enableTagCodes && codeAlreadyExists) {
      return this.errorMessages.codeDuplication
    }
    if (descriptionAlreadyExists) {
      return this.errorMessages.descriptionDuplication
    }
  }

  handleSubmit = () => {
    const {props, state} = this
    const { customerName } = props

    let message = this.isFormValid()

    if (message !== undefined) {
      window.appCreateToast({
        message,
        level: 'error'
      })
      return
    }

    this.setState({isSubmitting: true})

    let data = {
      code: state.code.trim(),
      description: state.description.trim(),
    }

    if(props.id) {
      data.id = props.id
    }
    const onSuccess = () => {
      const message = props.isCreating ? 'Created tag successfully.' : 'Saved tag successfully.'
      window.appCreateToast({
        message,
        level: 'success',
      })

      activityTracker.logEvent(activityTracker.eventTypeNames.UPDATE_PYPE_SETTING,{settingName: 'tags',action: (props.isCreating ? activityTracker.eventActionType.CREATE : activityTracker.eventActionType.UPDATE), customerName});
      if (props.isCreating && this._isMounted) {
        this.clearForm()
      }
    }

    const onError = (error) => {
      const message = props.isCreating ? 'Error creating tag.' : 'Error saving tag.'
      window.appCreateToast({
        message,
        level: 'error',
      })
      throw new Error(error)
    }

    const onDone = () => {
      if (props.isCreating && this._isMounted) {
        this.setState({isSubmitting: false})
        // Otherwise the component will unmount and this will be moot
        this.focusElement()
      }
    }

    props.onSubmit(data)
      .then(onSuccess, onError)
      .then(onDone)
  }

  focusElement = () => {
    if (!this._isMounted) {
      return
    }
    // Doesn't matter if tags are disabled or not as this will select
    // the first input it finds
    this.refs.el.querySelector('input').select()
  }

  handleClose = () => {
    this.props.onClose()
  }

  handleOpenModal = () => {
    this.setState({isModalOpen: true})
  }

  handleCloseModal = () => {
    this.setState({isModalOpen: false})
  }

  handleDigitError = () => {
    window.appCreateToast({
      message: 'Tag codes can only contain digits.',
      level: 'error',
    })
  }

  handleCodeChange = (event) => {
    const {value} = event.target
    if (/[^0-9]/.test(value)) {
      this.handleDigitError()
    }
    const code = value.replace(/[^0-9]/g, '')
    this.setState({code})
  }

  render() {
    const {props, state} = this
    const errors = _.filter(_.values(state.errors), (error) => !_.isEmpty(error))
    const checkIcon = this.isFormValid() === undefined ? `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-check.svg` : `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-empty-check.svg`
    return (
      <div ref="el" className={cx('new-tag-row row-content', props.className, {'is-submitting': state.isSubmitting})}>
        {state.isModalOpen && (
          <DeleteTagModal tag={props.tag} closeModal={this.handleCloseModal} />
        )}
        <div className="inner-row-content">
          {props.enableTagCodes ? (
            <div className="column-1">
              <LabelInput
                className={'code-input'}
                focus={props.enableTagCodes}
                type="text"
                value={state.code}
                maxLength={10}
                error={state.errors.code}
                disabled={state.isSubmitting}
                placeholder="1234567890"
                onKeyDown={this.handleKeyDown}
                onChange={this.handleCodeChange}
                onBlur={this.validateCode} />
            </div>
          ) : null}
          <div className="column-2">
            <LabelInput
              focus={!props.enableTagCodes}
              className="description-input"
              type="text"
              value={state.description}
              maxLength={100}
              error={state.errors.description}
              disabled={state.isSubmitting}
              placeholder="Add name here..."
              onKeyDown={this.handleKeyDown}
              onChange={this.bindTo('description')}
              onBlur={this.validateDescription}>
              <div className="controls-container">
                <Icon src={checkIcon} onClick={this.handleSubmit} />
                {props.isEditing && <Icon className="delete-tag-icon" src={`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-trash.svg`} onClick={this.handleOpenModal} />}
              </div>
            </LabelInput>
          </div>
          <div className="column-3">
            <span className="cancel-edit-tag" onClick={this.handleClose}>Cancel</span>
          </div>
        </div>
        {!_.isEmpty(errors) && (
          <div className="tag-row-error small text-danger">
            {_.head(errors)}
          </div>
        )}
      </div>
    )
  }

}

NewTagRow.propTypes = {
  tag: PropTypes.object,
  tagsStore: PropTypes.array.isRequired,
  className: PropTypes.string,
  isCreating: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  id: PropTypes.string,
  code: PropTypes.string,
  description: PropTypes.string,
  enableTagCodes: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
}

NewTagRow.defaultProps = {
  className: '',
  code: '',
  description: '',
  onClose: () => {},
}

const mapStateToProps = (state, {id}) => ({
  tag: state.tags.store[id],
  tagsStore: state.tags.store,
  isCreating: _.isNil(id),
  isEditing: !_.isNil(id),
  customerName: state.pype.details.customerName
})

const mapDispatchToProps = (dispatch, props) => ({
  onSubmit(tag) {
    // syntaxError when () => ({}) passed inline
    const noop = () => ({})
    return dispatch(tagActions.createOrUpdateTag(tag)).then(props.onSubmit || noop)
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewTagRow)
