import styled from 'styled-components';

export const Pype = styled.div`
  height: 70px;
  border-bottom: 1px solid rgba(177, 177, 177, 0.5);
  padding: 15px 20px;
  display: flex;
  flex-direction: row;
  &:hover {
    background-color: #f4f4f4;
    cursor: pointer;
  }
  .left {
    width: 40px;
  }
  .center {
    flex: 1;
    text-align: left;
    padding-left: 10px;
  }
  .right {
    width: 10px;
    display: flex;
    align-items: center;
  }
  &:last-child {
    border-bottom: none;
  }
`;

export const PypeLogo = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  overflow: hidden;
  object-fit: cover;
`;

export const PypeName = styled.div`
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.54;
  letter-spacing: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: ${props => (props.showArrow ? '237px' : '185px')};
  overflow: hidden;
`;

export const PypeEnv = styled.span`
  border-radius: 2px;
  background-color: ${props => (props.fillColor ? props.fillColor : '#fff')};
  border-width: 1px;
  border-style: solid;
  border-color: ${props => (props.borderColor ? props.borderColor : '#ccc')};
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: ${props => (props.textColor ? props.textColor : '#ccc')};
  padding: 2px 3px;
`;
