import React from "react";
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { OutlinedButton } from "ui-elements";

const useStyles = makeStyles({
  buttonStyle: {
    background: "#ffffff",
    marginRight: (props) => (props.overflow ? "8px" : "25px"),
  },
  boxStyle: {
    width: "100%",
    marginBottom: "-32px",
    display: "flex",
    position: "sticky",
    top: "12px",
    justifyContent: "flex-end",
    zIndex: (props) => (props.popoverIsOpen ? "-1" : "1"),
  },
});

export const OpenPanelButton = ({ content, openPanel, overflow, popoverIsOpen }) => {
  const { buttonStyle, boxStyle } = useStyles({ overflow, popoverIsOpen });
  return (
    <Box className={`${boxStyle}`}>
      <OutlinedButton
        className={`${buttonStyle}`}
        onClick={openPanel}
      >
        {content}
      </OutlinedButton>
    </Box>
  );
};
