import style from './style.css';
import React from 'react'
import classNames from 'classnames';
import PropTypes from 'prop-types'

const ProfileIcon = ({src, className}) => (
  <img
    src={src}
    className={classNames(style.ProfileIcon, className)}
    style={{
      display: 'inline-block',
      width: 30,
      height: 30,
      borderRadius: 100,
      background: '#e5e5e5',
      border: '1px solid #bdbdbd',
    }} />
)

ProfileIcon.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string,
}

ProfileIcon.defaultProps = {
  src: '',
  className: '',
}

export default  ProfileIcon
