import _ from 'lodash'
import {connect} from 'react-redux';
import Component from './Component';

const mapStateToProps = (state) => ({
  rules: {system_masking_rules: state.pype.details.system_masking_rules, masking_rules: state.pype.details.masking_rules}
})
export default  connect(mapStateToProps)(Component)

// export default Component
