import {connect} from 'react-redux';
import Component from './Component';
import sdk from 'sdk';
const { widgetsActions } = sdk;

const mapStateToProps = (state) => ({
  widgetsStore: state.widgets.store,
})
const mapDispatchToProps = (dispatch) => ({
  fetchWidgets () {
    return dispatch(widgetsActions.fetchWidgets())
  },
})

export default  connect(mapStateToProps, mapDispatchToProps)(Component)
