import React from 'react'

const Footer = (props) => (
  <div className="detail-footer">
    {props.children}
  </div>
)

Footer.propTypes = {}

export default  Footer
