import moment from 'moment'

export const formatTimestampForChatLog = (timestamp) => {
    moment.updateLocale('en', {
        calendar : {
            lastDay : '[Yesterday] LT',
            sameDay : '[Today] LT',
            nextDay : '[Tomorrow] LT',
            lastWeek : 'MMMM Do h:mm:a',
            nextWeek : 'MMMM Do h:mm:a',
            sameElse : 'MMMM Do h:mm:a'
        }
    })
    const date = moment(timestamp)
    return date.calendar()
}
