import React from 'react';
import css from './PypeSpinner.css';
import cx from 'classnames';

class PypeSpinner extends React.Component {
	static defaultProps = {
    offsetTop: 0,
		offsetBottom: 0,
		caption: true,
		style: {},
		loadingText: 'Loading',
  }

  render() {
    return (
			<>
				<div
					style={{marginTop:this.props.offsetTop + 'px', marginBottom:this.props.offsetBottom + 'px', ...this.props.style}}
					className={cx('spinner', css.spinner, this.props.className)}
				/>
				{this.props.caption
					&& (
						<div className={cx('caption-container', 'spinner-caption')}>
							<div className={cx('caption')}>
								{this.props.loadingText}
							</div>
						</div>
					)
				}
			</>
		);
  }
}


export default PypeSpinner;
