import _ from 'lodash'
import { BOT_USER_SESSION_LOGOUT, doesUserHasRole, PYPE_MANAGER_ROLES, PYPE_AGENT_ROLES } from "bot-user-session";
import {
  RESET_AGENT_CHAT_SESSIONS,
  REQUEST_AGENT_CHAT_SESSIONS,
  RECEIVE_AGENT_CHAT_SESSIONS,
  ENDED_AGENT_CHAT_SESSION,
  ADD_AGENT_CHAT_SESSION,
  RECEIVE_CHAT,
  RECEIVE_MESSAGE,
  CREATE_CHAT_RES,
  UPDATE_READ_COUNT,
  CACHE_CONSUMER_DATA,
  REMOVE_CHAT_SESSION,
  REQUEST_ARCHIVED_CHAT_SESSIONS,
  RECEIVE_ARCHIVED_CHAT_SESSIONS,
  RESET_ARCHIVED_CHAT_SESSIONS,
  RECEIVE_ESCALATED_CHAT_SESSIONS,
  REQUEST_ESCALATED_CHAT_SESSIONS,
  RESET_ESCALATED_CHAT_SESSIONS,
  UPDATE_EVENTS,
  SET_LATEST_ARCHIVED_CHATS_REQ_ID,
  UPDATE_ARCHIVED_CHATS_SEARCHING,
  UPDATE_CHAT_SESSION_ACTION_LIST,
  initSeqConsts,
} from '../constants'
import history from '../utils/history'
const {getRecentMessage} = history


const chatInitialState = Object.freeze({
  delivery_receipts: [],
  history: [],
  originating_request: {},
  participant_history: [],
  participants: {},
  read_receipts: [],
  recent_msg: {},
  user_read_counts: []
})

const getInitialState = () => ({
  didInvalidate: false,
  chatSessions: [],
  archived_chatSessions: [],
  escalated_chatSessions: [],
  // Workaround: making itemsPerPage to higher
  // value 100 since we need to get unread counts
  isFetching: false,
  archived_isFetching: false,
  escalated_isFetching: false,
  itemsPerPage: 100,
  archived_itemsPerPage: 10,
  escalated_itemsPerPage: 10,
  more: null,     // Pagination token
  archived_more: null,
  escalated_more: null,
  hasMore: true,  // This must be default true because we don't know if there's more or not initially.
  archived_hasMore: true,
  escalated_hasMore: true,
  pendingChats: {},
  recentlyInitiatedChat: null, // cache consumer data when initiaing a chat, used when appending to sessions since no other way to get them
  recentlyEndedChat: null,
  archived_search_size: -1,
  latestArchivedChatsReqId: null,
  searchingArchivedChatsCancelled: false,
  chatSessionActions: {},
});


const chatSession = (state={}, action) => {
  switch (action.type) {
    case RECEIVE_AGENT_CHAT_SESSIONS:
    case RECEIVE_ARCHIVED_CHAT_SESSIONS:
    case RECEIVE_ESCALATED_CHAT_SESSIONS:
      return Object.assign({}, state, {
        recent_msg: state.history.length > 0 ?
          (getRecentMessage(state.history) || {}) :
          {},
      })
    case ADD_AGENT_CHAT_SESSION:
      const history = action.data.history;
      return {
        ...chatInitialState,
        ...action.data,
        recent_msg: history.length > 0 ?
          (getRecentMessage(history) || {}) :
        {}
      };
    default:
      return state
  }
}

export default (state=getInitialState(), action) => {
  switch (action.type) {
    case BOT_USER_SESSION_LOGOUT: {
      return getInitialState();
    }
    case initSeqConsts.FETCHING:
      return {
        ...state,
        chatSessions: [], // Needed for unread counts to work.
        more: null, // Needed for unread counts to work.
        hasMore: true, // Needed for unread counts to work.
        isFetching: true
      }
    case RESET_AGENT_CHAT_SESSIONS:
      return {
        ...state,
        chatSessions: [],
        more: null,
        hasMore: true,
      }
    case RESET_ARCHIVED_CHAT_SESSIONS:
      return {
        ...state,
        archived_chatSessions: [],
        archived_more: null,
        archived_hasMore: true,
      }
    case RESET_ESCALATED_CHAT_SESSIONS:
      return {
        ...state,
        escalated_chatSessions: [],
        escalated_more: null,
        escalated_hasMore: true,
      }
    case REQUEST_AGENT_CHAT_SESSIONS:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case REQUEST_ARCHIVED_CHAT_SESSIONS:
      return Object.assign({}, state, {
        archived_isFetching: true,
      })
    case REQUEST_ESCALATED_CHAT_SESSIONS:
      return Object.assign({}, state, {
        escalated_isFetching: true,
      })
    case RECEIVE_AGENT_CHAT_SESSIONS:
      return {
        ...state,
        isFetching: false,
        lastUpdated: action.receivedAt,
        chatSessions: _.uniqBy(
          [...state.chatSessions, ...action.data.chat_sessions],
          (chat) => chat.id
          )
          .filter((chat) => {
            return chat.participants && chat.participants.agent === action.userId
          })
          .map((chat) => chatSession(chat, action)),
        more: action.data.more,
        hasMore: action.data.more !== 'done',
      }
    case RECEIVE_ARCHIVED_CHAT_SESSIONS:
      return {
        ...state,
        archived_isFetching: false,
        lastUpdated: action.receivedAt,
        archived_chatSessions: _.uniqBy(
          [...state.archived_chatSessions, ...action.data.chat_sessions],
          (chat) => chat.id
        )
        .map((chat) => chatSession(chat, action)),
        archived_more: action.data.more,
        archived_hasMore: action.data.more !== 'done',
        archived_search_size: action.data.search_size,
      }
    case RECEIVE_ESCALATED_CHAT_SESSIONS:
      return {
        ...state,
        escalated_isFetching: false,
        lastUpdated: action.receivedAt,
        escalated_chatSessions: _.uniqBy(
          [...state.escalated_chatSessions, ...action.data.chat_sessions],
          (chat) => chat.id
        )
        .map((chat) => chatSession(chat, action)),
        escalated_more: action.data.more,
        escalated_hasMore: action.data.more !== 'done',
      }
    case ENDED_AGENT_CHAT_SESSION: {
      const chatSessions = state.chatSessions.map((chat) => {
        if(chat.id !== action.id){
          return chat
        }
        // agents gets removed from a chat once survey bot is triggered, so 
        // agent won't receive status: ended. Look for agent_is_assigned: false in such case
        // to determine whether a chat is ended. 
        const isChatEndedForSurveyBot = action.chat.agent_is_assigned === false
        return {
          ...chat,
          status: isChatEndedForSurveyBot ? 'ended' : action.chat.status,
          end_comment: action.chat.end_comment,
          ended_by: action.chat.ended_by,
        }
      })
      const escalated_chatSessions = state.escalated_chatSessions.filter(chat => chat.id !== action.id);
      return Object.assign({}, state, {
        chatSessions,
        escalated_chatSessions,
        recentlyEndedChat: action.chat
      })
    }
    case ADD_AGENT_CHAT_SESSION:
      if(action.isEscalated) {
        return {
          ...state,
          escalated_chatSessions: _.uniqBy([
            chatSession(undefined, action),
            ...state.escalated_chatSessions,
          ], 'id'),
        }
      }
      return {
        ...state,
        chatSessions: _.uniqBy([
          chatSession(undefined, action),
          ...state.chatSessions,
        ], 'id'),
      }
    case RECEIVE_CHAT:
      return {
        ...state,
        pendingChats: {
          [action.data.id]: action.data,
        },
      }
    case UPDATE_EVENTS:
      const chats = [...state.chatSessions];
      const selectedChat = _.find(chats, c => c.id === action.id);
      if (selectedChat) {
        selectedChat.events = action.events;
      }
      return {
        ...state,
        chatSessions: _.uniqBy(chats, 'id'),
      }
    case CREATE_CHAT_RES: {
      const chats = [...state.chatSessions];
      const escalatedChats = [...state.escalated_chatSessions];
      const createdChat = action.createdChat;
        const currentUserId = action.userId;
        const currentUserRole = 'agent';

        let csession = _.find(chats, c => c.id === createdChat.chat_id);
        let escSession = _.find(escalatedChats, c => c.id === createdChat.chat_id);

        if(escSession) {
          escSession.recent_msg = createdChat;
          escSession.seq_num = createdChat.seq_num;

          if(createdChat.from === currentUserId){
            const userReadCount = _.find(escSession.user_read_counts, {user_id: currentUserId});
            if (userReadCount) {
              userReadCount.max += 1;
            }
            escSession.agent_msg_count += 1;
          } else {
            escSession.consumer_msg_count += 1;
          }
          return {
            ...state,
            escalated_chatSessions: _.uniqBy(escalatedChats, 'id'),
          };
        }

        // if session is not found in escalated list but still received chat_msg notice 
        // before adding to my queues, check if user is actually a participant 
        if(!action.isParticipant){
          return state;
        }

        if(!csession){
          // Edge case for dual (agent + manager) roles. Only add message to newChat if the chat is in active session.
          let fromId, fromName;
          csession = createdChat;
          csession.id = createdChat.chat_id;
          // consumer initiated chat
          if(createdChat.from_side !== currentUserRole) {
            fromId = createdChat.from;
            fromName = createdChat.metadata.from_name;
          } else if(state.recentlyInitiatedChat){
            const { consumerId, consumerName } = state.recentlyInitiatedChat;
            fromId = consumerId;
            fromName = consumerName;
          }
          csession.consumer = fromId;
          csession.consumer_name = fromName;
          csession.consumer_msg_count = 0;
          csession.agent_msg_count = 0;
          csession.user_read_counts = [{
            user_id: currentUserId,
            min:0,
            max:0
          }];
          csession.stream_name = createdChat.metadata.stream_name
          chats.unshift(csession);
        }

        csession.recent_msg = createdChat;
        csession.seq_num = createdChat.seq_num;

        if(createdChat.from === currentUserId){
          const userReadCount = _.find(csession.user_read_counts, {user_id: currentUserId});
          if (userReadCount) {
            userReadCount.max += 1;
          }
          csession.agent_msg_count += 1;
        } else {
          csession.consumer_msg_count += 1;
        }
        return {
          ...state,
          chatSessions: _.uniqBy(chats, 'id'),
        };
    }
    case RECEIVE_MESSAGE:
      const message = action.data
      let chat = {
          ...state.pendingChats[message.chat_id]
      }
      if (message.seq_num !== 1 || _.isEmpty(chat)) {
        return state
      }
      // Received a first message in a chat
      chat = {
        ...chat,
        history: [
          {chat_msg: message},
        ],
        recent_msg: message,
      }
      return {
        ...state,
        pendingChats: _.omit(state.pendingChats, message.chat_id),
        chatSessions: [
          chat,
          ...state.chatSessions,
        ],
      }
    case UPDATE_READ_COUNT:
      const { chat_id, msg_ids, user_id } = action.receipt;
      const chatSessions = [...state.chatSessions]
      const session = _.find(chatSessions, {id:chat_id});
      if(!session){
        return state;
      }
      const seqIds = _(msg_ids)
        .map(id => (parseInt(_.last(id.split('.')))))
        .sortBy()
        .value();
      // get seq id (after .)
      const min = _.first(seqIds);
      const max = _.last(seqIds)

      let userReadCount = _.find(session.user_read_counts, {user_id});

      if(!userReadCount){
        session.user_read_counts = [
          ...session.user_read_counts,
          {
            min:min,
            max:max,
            user_id: user_id
          }
        ]
      } else {
        if(max > userReadCount.max) userReadCount.max = max;
        if(min < userReadCount.min) userReadCount.min = min;
        if(userReadCount.min === 0) userReadCount.min = min;
      }

      return {
        ...state,
        chatSessions
      }
    case CACHE_CONSUMER_DATA:
      const { consumerId, consumerName } = action.data;
      return {
        ...state,
        recentlyInitiatedChat: {
          consumerId,
          consumerName
        }
      }
    case REMOVE_CHAT_SESSION:
      return Object.assign({}, state, {
        chatSessions: state.chatSessions.filter((chat) => chat.id !== action.id)
      })
    case SET_LATEST_ARCHIVED_CHATS_REQ_ID:
      return {
        ...state,
        latestArchivedChatsReqId: action.req_id
      }
    case UPDATE_ARCHIVED_CHATS_SEARCHING:
      return {
        ...state,
        searchingArchivedChatsCancelled: action.searchingArchivedChatsCancelled,
        archived_isFetching: (action.searchingArchivedChatsCancelled) ? false : state.archived_isFetching
      }
    case UPDATE_CHAT_SESSION_ACTION_LIST:
      return {
        ...state,
        chatSessionActions: action.data
      }
    default:
      return state
  }
}
