import React from 'react'
import PropTypes from 'prop-types'
import {NavLink, withRouter} from 'react-router-dom';
import cx from 'classnames';

const activeStyle = {
  background: 'rgba(45,124,225,.07)',
  color: '#2e7de1'
}
class SubNavItem extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      active: false
    }
  }

  componentWillReceiveProps() {
    this.setState({
      active: false
    });
  }

  componentWillMount() {
    this.setState({
      active: false
    });
  }

  render() {
    const {children, link, icon, iconActive, isDisabled, extension, match, id, onLinkClick} = this.props;
    const {active} = this.state;
    
    return (
      <div className="sub-nav-item">
        <NavLink onClick={() => onLinkClick(id)} to={{ pathname: `${match.url}/${link}`, state: link}} className={`list-item restore-${id}`} activeStyle={activeStyle}>
          <img className={cx({'fade':isDisabled},'m-r-10')} src={active ? iconActive : icon} height="20px" width="20px"/>
          <div className={cx({'fade':isDisabled})}>{children}</div>
          {extension ? <div className={cx({'fade':isDisabled},"list-item-extension")}>{extension}</div> : null}
        </NavLink>
      </div>
    )
  }
};

SubNavItem.propTypes = {
  link: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  iconActive: PropTypes.string.isRequired,
};

SubNavItem.contextTypes = {
  router: PropTypes.object
}

// export default  SubNavItem;
export default  withRouter(SubNavItem);
