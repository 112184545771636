import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal';
import SaveButton from '../../ui/forms/SaveButton';
import CloseButton from '../../ui/modals/CloseButton';

class ConfirmRouterSwitchModal extends React.Component {

  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
  }

  state = {
    isSubmitting: false,
  }

  handleSubmit = () => {
    this.props.onConfirm();
  }

  handleClose = () => {
    if (!this.state.isSubmitting) {
      this.props.onBack()
    }
  }

  render() {

    const {props, state} = this
    const { software } = props 
    
    return (
      <Modal data-test="modal-component" ariaHideApp={false} isOpen={true} className="confirm-router-switch-modal" onRequestClose={this.handleClose}>
        <div className="modal-content">
          <div className="modal-header">
            <CloseButton onClick={this.handleClose} />
            <h4 className="modal-title text-center">Are you sure?</h4>
          </div>
          <div className="modal-body">
            <p>
              By changing the routing software to {software}, your agents account will be disabled and current chats ended.
            </p>
          </div>
          <div className="modal-footer">
            <div>
              <button data-test="go-back-button" className="btn btn-default" onClick={this.handleClose}>Go back</button>
              <SaveButton data-test="save-button" className="btn-danger" isLoading={state.isSubmitting} onClick={this.handleSubmit}>
                Confirm
              </SaveButton>
            </div>
          </div>
        </div>
      </Modal>
    )

  }

}

export default  ConfirmRouterSwitchModal
