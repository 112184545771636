// External imports
import React from 'react'
import sdk from 'sdk';
import assign  from 'object-assign';
import Validator from 'validator';
import _ from 'lodash';
import cx from 'classnames';


// UI components
import LabelInput from 'components/ui/forms/LabelInput';
import SaveButton from 'components/ui/forms/SaveButton';

// Local imports
import DetailPane from '../details/DetailPane';
import TitleBar from '../details/TitleBar';
import ContentBody from '../details/ContentBody';
import SubHeader from '../details/SubHeader';
import Footer from '../details/Footer';
import PypeManagerFeature from '../PypeManagerFeature';
import activityTracker from "../../../utils/activityTracker";
import ErrorBoundary from 'components/ui/ErrorBoundary';

const SETTING_NAME = 'crm data';

class Component extends React.Component {

  constructor(props) {
    super(props);
    const { customerName } = this.props

    this.state = {
      CRMTabName: props.CRMTabName,
      CRMurl: props.CRMurl,
      errors: {},
      isSubmitting: false
    };

    activityTracker.logEvent(activityTracker.eventTypeNames.VIEW_PYPE_SETTING,{settingName: SETTING_NAME, customerName});
  }

  handleTabNameChange = (e) => {
    const tabName = e.target.value;
    this.setState({
      CRMTabName: tabName,
      errors: _.omit(this.state.errors, 'CRMTabName')
    });
  }

  isTabNameValid = () => {
    var isValid = false;
    var error = '';
    const {CRMTabName} = this.state;

    if(!CRMTabName) {
      error = "Tab name cannot be blank.";
    } else {
      isValid = true;
    }

    this.setState({
      errors: assign(this.state.errors, {CRMTabName: error})
    });

    return isValid;
  }

  handleUrlChange = (e) => {
    const url = e.target.value.trim();
    this.setState({
      CRMurl: url,
      errors: _.omit(this.state.errors, 'CRMurl'),
    });
  }

  isUrlValid = () => {
    var isValid = false;
    var error = '';
    const {CRMurl} = this.state;

    if(!CRMurl || !Validator.isURL(CRMurl) || CRMurl.split(':')[0] !== 'https') {
      error = "Please enter a valid URL over HTTPS.";
    } else {
      isValid = true;
    }

    this.setState({
      errors: assign(this.state.errors, {CRMurl: error})
    });

    return isValid;
  }

  isFormValid = () => {
    return this.isTabNameValid() & this.isUrlValid();
  }

  handleSave = () => {
    const { customerName } = this.props;
    
    if(!this.isFormValid()) return;

    this.setState({isSubmitting: true})

    const onSuccess = () => {
      window.appCreateToast({
        message: 'CRM data tab added successfully.',
        level: 'success'
      })
      activityTracker.logEvent(activityTracker.eventTypeNames.UPDATE_PYPE_SETTING,{settingName: SETTING_NAME,action: activityTracker.eventActionType.UPDATE, customerName});
    }

    const onError = () => {
      window.appCreateToast({
        message: 'Request could not be completed.',
        level: 'error'
      })
    }

    const onDone = () => {
      this.setState({isSubmitting: false})
    }

    // Pass everything in this.state to onSave() handler except @error

    var data = {
      id: this.props.pypeID,
      crm_enabled: true, // enabled by default
      crm_label: this.state.CRMTabName.trim(),
      crm_url: this.state.CRMurl
    };

    this.props.updatePype(data).then(onSuccess, onError).then(onDone)
  }

  canSubmit = () => {
    const { errors, CRMTabName, CRMurl } = this.state;

    if(Object.keys(_.pickBy(errors, _.identity)).length) {
      return false;
    }
    if(!CRMTabName || !CRMurl) {
      return false;
    }

    return true;
  }

  render() {

    const {state} = this

    const {
      CRMTabName,
      CRMurl,
      errors
    } = this.state;

    return (
      <DetailPane className="chat-logs-detail has-footer">

        <ErrorBoundary>
          <ContentBody>
            <PypeManagerFeature>
              <section style={{paddingTop: 0}}>
                <SubHeader>Configure CRM data</SubHeader>
                <section>
                  <p>
                    CRM data displays your customer information to your Pype Manager account from your database. Here, you can enable your CRM data displays, customize your CRM data tab label, and enter the URL to display your customer data.
                  </p>
                </section>
                <section>
                  <div className={cx({ 'has-error': errors.CRMTabName })}>
                    <LabelInput label="Tab Name" type="text"
                              value={CRMTabName}
                              onChange={this.handleTabNameChange}
                              onBlur={this.isTabNameValid}
                              error={errors.CRMTabName}
                              />
                  </div>
                </section>
                <section>
                  <div className={cx({ 'has-error': errors.CRMurl })}>
                  <LabelInput label="URL" type="text"
                              value={CRMurl}
                              placeholder="e.g. https://CRMData.com"
                              onChange={this.handleUrlChange}
                              onBlur={this.isUrlValid}
                              error = {errors.CRMurl}
                              />
                  </div>
                </section>
              </section>
            </PypeManagerFeature>
          </ContentBody>
        </ErrorBoundary>
        <Footer>
          <SaveButton className="btn-primary" isLoading={state.isSubmitting} disabled={!this.canSubmit()} onClick={this.handleSave}>
            Save changes
          </SaveButton>
        </Footer>

      </DetailPane>
    )

  }

}

export default  Component
