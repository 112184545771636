import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames';

// UI
import Spinner from '../../ui/pype-spinner/PypeSpinner';

class LoadingMask extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mounted: false,
    }
  }
  componentDidMount() {
    this._isMounted = true
    setTimeout(() => {
      if (this._isMounted)
        this.setState({mounted: true})
    }, 20)
  }
  componentWillUnmount() {
    this._isMounted = false
  }
  render() {
    const {props} = this
    const {mounted} = this.state
    return (
      <div className={cx('loading-mask', {mounted})}>
        <div className="mask" />
        {props.hasSpinner ?
          <Spinner className="detail-spinner absolute-center" caption={false} /> : null}
      </div>
    )
  }
}

LoadingMask.propTypes = {
  hasSpinner: PropTypes.bool,
}

LoadingMask.defaultProps = {
  hasSpinner: false,
}

export default  LoadingMask
