import React from 'react';
import ReactTooltip from 'react-tooltip';
import css from './AppTooltip.css';

const  AppTooltipHost = () => (
  <div>
      <ReactTooltip
        id="appTooltipHost"
        class={css.tooltip}>
      </ReactTooltip>

    </div>
);

export default  AppTooltipHost;
