const responses = new Map();
const StompQueue = {
  queue(id, resolve, reject, payload) {
    responses.set(id, { resolve, reject, payload });
  },
  dequeue(id) {
    let res = responses.get(id);
    responses.delete(id);
    return res;
  },
  isQueued(id) {
    return responses.has(id);
  },
  getQueuedRequests(){
    return responses;
  }

};

export default StompQueue;
