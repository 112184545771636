export const SPECIAL_CHARS = {
  LF: '\x0A',
  NULL: '\x00',
}

export const HEADER_ONLY_FRAMETYPES = [
  'connect',
  'connected',
  'subscribe',
  'subscribed',
]

export const FRAME_TYPE_MAP = {

  '>>> CONNECT': 'connect',
  '<<< CONNECTED':  'connected',

  '>>> SUBSCRIBE': 'subscribe',
  '<<< SUBSCRIBED': 'subscribed',

  '>>> SEND': 'send',
  '<<< MESSAGE': 'message',

}
