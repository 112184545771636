import { BOT_USER_SESSION_LOGOUT } from "bot-user-session";
import {
  REQUEST_USER_META,
  RECEIVE_USER_META
} from "../constants"

const getInitialState = () => ({
  userMeta: null,
});

export default function users(state=getInitialState(), action) {
  switch (action.type) {
    case BOT_USER_SESSION_LOGOUT: {
      return getInitialState();
    }
    case REQUEST_USER_META:
      return Object.assign({}, state, {
        userMeta: null,
      })
    case RECEIVE_USER_META:
      return Object.assign({}, state, {
        userMeta: action.data,
      })
    default:
      return state
  }
}
