import style from './style.css';
import React from 'react'
import PropTypes from 'prop-types'

class CopyButton extends React.Component {

  constructor (props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick = () => {
    this.props.onClick()
    try {
      const isSuccessful = document.execCommand('copy')
      if (isSuccessful) {
        window.appCreateToast({
          message: 'Code successfully copied.',
          level: 'success',
        })
      } else {
        window.appCreateToast({
          message: 'Could not copy code.',
          level: 'warning',
        })
      }
    } catch (error) {
      console.warn(error)
    }
  }

  render () {
    return (
      <button className={style.CopyButton} type='button' onClick={this.handleClick}>
        Copy code
      </button>
    )
  }

}

CopyButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default  CopyButton
