import React from 'react'
import {
  Header,
  constants
} from 'unified-nav';
import { 
  userLogout, 
  doesUserHasRole,
  PYPE_AGENT_ROLES
} from 'bot-user-session';
import {connect} from 'react-redux';
import sdk from 'sdk';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

const { products } = constants
const {agentActions, utils} = sdk;

class Component extends React.Component {
  getStatus() {
      const {props} = this
      let currentStatus = props.status;
  
      // during off-hours don't show 'away' if agent has been offline
      if(props.isOffHours && currentStatus !== 'offline'){
        currentStatus = 'away'
      }
      return currentStatus
  }

  render() {
    const { isAgentDisconnected, userLogout, pypeId, setStatus, isOffHours, isMaxed, isAgentStatusLoading, showProfile, flags } = this.props
    const isAgent = doesUserHasRole(PYPE_AGENT_ROLES, pypeId);

    return (
      <Header
        flags={flags}
        whiteLabelEnabled={true}
        showProfile={showProfile}
        product={products.PYPE_MANAGER}
        agentStatus={this.getStatus()}
        showStatusToggle={isAgent && !isAgentDisconnected}
        isStatusLoading={isAgentStatusLoading}
        isOffHours={isOffHours}
        isMaxed={isMaxed}
        onSignOut={() => userLogout({
          flags,
          reason: 'Manual logout'
        })}
        onStatusChange={setStatus}
      />
    )  
  }
}


const mapStateToProps = state => ({
  pypeId: state.pype.details.id,
  status: utils.agent.getStatus(state.agent),
  isOffHours: state.agent.isOffHours,
  isMaxed: utils.agent.isMaxed(state.agent),
  isAgentStatusLoading: !state.agent.hasInitialFetched,
  isAgentDisconnected: state.heartbeats.isAgentDisconnected,
});

const mapDispatchToProps = (dispatch, props) => ({
  userLogout(props) {
    dispatch(userLogout(props))
  },
  setStatus(status) {
    return dispatch(agentActions.setStatus(status))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLDConsumer()(Component))