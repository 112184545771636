import style from './style.scss';
import _ from 'lodash';
import React from 'react'
import PropTypes from 'prop-types'
import SendButton from '../SendButton';

class ChatBox extends React.Component {

  render () {
    const {props} = this

    const brandStyle = {
      background: `url(../../../img/by-pypestream.png) center center no-repeat`,
      marginBottom: '10px',
      backgroundSize: 'contain',
      width: '112px',
      height: '13px',
    }

    return (
      <div className={style.BrandedContainer}>
        <a>
          <div style={brandStyle} />
        </a>
        <div className={style.FormattedChatBox}>
          <form className={style.TextBox}>
            <textarea
              className={style.Input}
              type="text"
              placeholder="Select an option above"
              disabled
            />
          </form>
        </div>
      </div>
    )
  }

}

export default  ChatBox
