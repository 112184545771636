import React from 'react'
import PropTypes from 'prop-types';

// Local imports
import style from './style.css';

class SortableTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: null
    };
  }
  render() {
    const { props } = this;

    return (
      <div className={style.wrapper}>
        {props.children}
      </div>
    );
  }
}

SortableTable.propTypes = {
  children: PropTypes.node
};

export default  SortableTable;
