/*
  <TODO> This entire component needs a major refactor. It needs to be
  broken up into modules.
*/
import PropTypes from 'prop-types'

import React from 'react';
import {withRouter} from 'react-router-dom'
import _ from 'lodash';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import sdk from 'sdk'
import { userLogout, isPermitted, PermissionAction, PermissionResource } from 'bot-user-session';

import Spinner from 'components/ui/pype-spinner/PypeSpinner';
import EditStreamsModal from './EditStreamsModal';
import StreamInfoModal from './StreamInfoModal';
import Toast from 'components/ui/toast/Toast';
import AppTooltip from 'components/shared/AppTooltip';
import TimeDiff from 'components/ui/time-diff/TimeDiff';
import css from './Overview.css';
import Stats from './Stats';
import featureFlags from 'utils/featureFlags'
import activityTracker from '../../utils/activityTracker';
import {
  doesUserHasRole,
  PYPE_AGENT_ROLES,
  PYPE_MANAGER_ROLES
} from "bot-user-session";

const  { pypeActions, streamActions, campaignsActions } = sdk

var stateListener = {};

class Overview extends React.Component{
  static propTypes = {
    isFetching: PropTypes.bool.isRequired,
    refreshStreams: PropTypes.func.isRequired,
    fetchCampaigns: PropTypes.func.isRequired,
    fetchStreamsIfNeeded: PropTypes.func.isRequired,
    updateStream: PropTypes.func.isRequired,
    createStream: PropTypes.func.isRequired,
    editingStream: PropTypes.func.isRequired,
    streams: PropTypes.array.isRequired,
    pypeMetrics: PropTypes.object.isRequired,
    isPypeFetching: PropTypes.bool.isRequired,
    hasPypeFetched: PropTypes.bool.isRequired,
    isSendingBroadcast: PropTypes.bool.isRequired,
    editingStreamID: PropTypes.string.isRequired,
    pype: PropTypes.object.isRequired,
    fullName: PropTypes.string.isRequired
  }

  state = {
      newStreamModalIsOpen: false,
      streamInfoModalIsOpen: false,
      selectedStream: null,
      isUnreadCountLoading: true,
      isSigningOut: false
  }

  componentDidMount() {

    const { pypeId } = this.props.match.params;
    const isAgent = doesUserHasRole(PYPE_AGENT_ROLES, pypeId);
    const isAdmin = doesUserHasRole(PYPE_MANAGER_ROLES, pypeId);
    const userRole = isAdmin ? 'admin' : isAgent ? 'agent' : 'User is not an agent or admin';

    activityTracker.logEvent(activityTracker.eventTypeNames.VIEW_OVERVIEW_TAB, {
      role: userRole,
    })

    if (!this.props.isFetching) {      
      this.props.refreshStreams();
      let filterData = [];
      if (featureFlags.isAgentGroupMetricsEnabled() && 
        localStorage.getItem("skill") && 
        JSON.parse(localStorage.getItem("skill")).length > 0) {
        let data = JSON.parse(localStorage.getItem("skill"))
        filterData = data.filter(item => item.value !== "show all")
      }
      const skills = filterData.length ? filterData.map((item) => `skills[]=${item.value}`).join('&') : "";
      this.props.fetchPypeMetrics(skills);
    }
  }

  componentWillMount() {
    stateListener.set = function(newState) {
      this.setState(newState);
    }.bind(this);
  }

  openNewStreamModal = () => {
    this.setState({
      newStreamModalIsOpen: true
    });
  }

  closeModal = () => {
    this.setState({
      newStreamModalIsOpen: false,
      streamInfoModalIsOpen: false,
    });
  }

  handleSignout = (e) => {
    e.preventDefault();

    this.setState({ isSigningOut: true });

    this.props.userLogout();
    this.signoutCallBack();
  }
  signoutCallBack() {
    document.title = 'Pypestream';
  }
  handleStreamInfo = (streamObj) => {
    this.setState({
      selectedStream: streamObj
    });

    this.setState({
      streamInfoModalIsOpen: true
    });
  }
  callbackUpdateSetDefaultState = (data) => {
    // this.setState({isUpdating: false});
    if (data.error) {
      alert(data.error);
    } else {
      this.forceUpdate();
    }
  }
  handleSetDefaultState = (id, e) => {
    var enabled = e.target.checked;

    var stream = {
      id,
      auto_joinable: enabled
    };

    this.props.updateStream(stream);
  }
  renderStreamsTable() {
    var self = this;
    
    var streams = this.props.streams && _.sortBy(this.props.streams, function(stream) { 
      return stream.stream_name; 
    }).map(function(stream) {
      var name = stream.stream_name;
      var stats = stream.stats;
      var userCount = stats && stats.subscription_count || 0;
      var campaignCount = stats && stats.campaign_count || 0;
      var messageCount = stats && stats.msg_count || 0;
      var avgFrt = stats && stats.avg_resp_time || 0;
      var id = stream.id;
      var isActive = stream.status === 'active';

      const handleClick = self.handleStreamInfo.bind(self, stream);

      return (
        <tr key={id} className={isActive ? '' : 'fade'}>
          <td>

          {isPermitted(PermissionResource.STREAM, PermissionAction.UPDATE) ?
            <a className="btn btn-link no-padding" onClick={handleClick}>{name}</a>
            : <span className="no-button btn-link no-padding">{name}</span>
          }
          </td>
          <td className="semi-bold">{userCount}</td>
          <td className="">{campaignCount}</td>
          <td className="">{messageCount}</td>
          <td className="">
            { avgFrt ? <TimeDiff time={avgFrt} /> : '–' }
          </td>
        </tr>
      );
    });

    return (
      <div>
        <table className="table table-striped m-t-30 p-t-20 m-b-10">
          <thead>
            <tr>
              <th>
                <div>
                  Stream Name&nbsp;
                  <AppTooltip tooltipKey="overview_tab.stream_title" style={ { color: 'black' } }>
                  </AppTooltip>
                </div>
              </th>
              <th>Users</th>
              <th>Broadcasts</th>
              <th>
                <div>
                  Messages&nbsp;
                  <AppTooltip tooltipKey="overview_tab.message_count" style={ { color: 'black' } }>
                  </AppTooltip>
                </div>
              </th>
              <th>AVERAGE RESPONSE TIME</th>
            </tr>
          </thead>
          <tbody>
            {streams}
          </tbody>
        </table>

        {isPermitted(PermissionResource.STREAM, PermissionAction.CREATE) ?
          <button className="btn btn-link no-padding fw-500" onClick={this.openNewStreamModal}>
            Edit or add Streams
          </button> : null
        }
      </div>
    );
  }
  handleCreateStream = (streamName) => {
    var description = '-';
    var status = 'active';
    var priority = 1;
    var autoJoinable = true;

    this.props.createStream({
      stream_name: streamName,
      description,
      status,
      priority,
      auto_joinable: autoJoinable
    });
  }
  handleEditingStream = (id) => {
    this.props.editingStream(id);
  }
  handleUpdateStream = (id, obj) => {
    var stream = {
      id
    };

    _.extend(stream, obj);
    this.props.updateStream(stream);
  }
  handleClickShowStreams = () => {
    this.setState({ newStreamModalIsOpen: true });
  }
  render() {
    var pype = this.props.pype;
    var name = pype && pype.name;
    var description = pype ? pype.description : '';
    var userName = this.props.fullName || '';
    var email = this.props.email || '';

    var metrics = this.props.pypeMetrics || {};

    const cssCenter = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: '-20px',
      marginLeft: '20px'
    };

    if (this.props.isPypeFetching && !this.props.hasPypeFetched) {
      return (
        <div className="overview-front-page content-inner p-20">
          <div style={cssCenter}>
            <Spinner />
          </div>
        </div>
      );
    }

    return (
      <div className={css.wrapper + ' overview-front-page content-inner'}>
        {/* <div className="">
          <div className=""> */}
            {/* <div className="row flex-row">
              <div className="col-sm-7 flex-col">
                <div>
                  <h3 className="no-margin inline-block fw-500 p-r-20">{name}</h3>
                  {isPermitted(PermissionResource.PYPE, PermissionAction.UPDATE) ? (
                    <p className="no-margin inline-flex">
                      <a href={`${window.config.PS_CONSOLE_URL}/pypes`} className="btn btn-link no-padding">Edit Pype</a>
                    </p>
                  ) : null}
                </div>
                <p className="m-t-10 pype-description">
                  {description}
                </p>
              </div>
              <div className="col-sm-5 text-right flex-col">
                <p className="fs-17 no-margin">{userName}</p>
                <p className="fade no-margin p-t-10">{email}</p>
                <div>
                  <a href={`${window.config.PS_CONSOLE_URL}/profile`} className="btn btn-link no-padding">Edit email or password</a>
                  <span className="text-primary p-sm"><strong>&middot;</strong></span>
                  <button className="btn btn-link no-padding fw-500" onClick={this.handleSignout} disabled={this.state.isSigningOut} autoid='signOut'>Sign out</button>
                </div>

              </div>
            </div> */}
          {/* </div>
        </div> */}

        {/* TODO */}
        <Stats className={css.stats} metrics={metrics} onClickShowStreams={this.handleClickShowStreams} />

        <EditStreamsModal
          streams={this.props.streams}
          editingID={this.props.editingStreamID}
          isUpdating={this.props.isFetching}
          modalIsOpen={this.state.newStreamModalIsOpen}
          closeModal={this.closeModal}
          onCreateStream={this.handleCreateStream}
          onUpdateStream={this.handleUpdateStream}
          onEditStream={this.handleEditingStream}
          setDefaultState={this.handleSetDefaultState}
          broadcastStreamID={this.props.broadcastStreamID}
          widgets={this.props.widgets}/>

        <StreamInfoModal stream={this.state.selectedStream} modalIsOpen={this.state.streamInfoModalIsOpen} closeModal={this.closeModal}/>

        <Toast ref="toast" autoDismiss="0"/>
      </div>);
  }
};

var mapStateToProps = function(state) {
  return {
    fullName: state.botUserSession.user.name,
    email: state.botUserSession.user.email,
    isPypeFetching: state.pype.isFetching,
    hasPypeFetched: state.pype.hasFetched,
    streams: state.streamsForPype.streams,
    pype: state.pype.details,
    pypeMetrics: state.pype.metrics,
    isFetchingMetrics: state.pype.isFetchingMetrics,
    isFetching: state.streamsForPype.isFetching,
    isSendingBroadcast: state.campaignsForPype.isSendingBroadcast,
    editingStreamID: state.streamsForPype.editingStreamID,
    edited: state.streamsForPype.edited,
    broadcastStreamID: state.pype.details.broadcast_stream_id,
    widgets: state.widgets.store
  };
};

var mapDispatchToProps = function(dispatch) {
  return bindActionCreators({
    editingStream: streamActions.editingStream,
    createStream: streamActions.createStream,
    updateStream: streamActions.updateStream,
    fetchStreamsIfNeeded: streamActions.fetchStreamsIfNeeded,
    refreshStreams: streamActions.refreshStreams,
    fetchPypeMetrics: pypeActions.fetchPypeMetrics,
    fetchCampaigns: campaignsActions.fetchCampaigns,
    userLogout
  }, dispatch);
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Overview));
