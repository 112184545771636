import * as TUS from 'tus-js-client';
import * as Config from './Config';
import {store} from './store'

export default function upload(file, params, onProgress) {
  if (!TUS.isSupported) {
    throw new Error('Your browser does not support TUS upload tool');
  }

  console.log(`TUS client activated using ${Config.getFileUploadURL()} endpoint`);
  var jwt = sessionStorage.getItem('global_accessToken');

  const USER_ID = store.getState().botUserSession.user.user_id
  const AUTH_TOKEN = jwt; 
  const DEVICE_ID = store.getState().session.device_id; // ID unique to device
  const authHash = btoa(`${USER_ID}@${DEVICE_ID}:${AUTH_TOKEN}`);
  const headers = {
    authorization: `Basic ${authHash}`,
    'Content-Type': 'application/offset+octet-stream'
  };

  return new Promise((resolve, reject) => {
    // Create a new tus upload
    const upload = new TUS.Upload(file, {
      endpoint: Config.getFileUploadURL(),
      headers,
      onError: error => {
        console.log(`Failed due to: ${error}`);
        reject(error);
      },
      onProgress,
      onSuccess: (url) => {
        resolve(url, upload);
      },
      params,
      resume: false
    });

    upload.start();
  });
}
