/*
  This reducer represents the state of chat sessions for a consumer
  across all agents.
*/

import _ from 'lodash'
import { BOT_USER_SESSION_LOGOUT } from "bot-user-session";
import {
  RESET_CHAT_SESSIONS_WITH_CONSUMER,
  REQUEST_CHAT_SESSIONS_WITH_CONSUMER,
  RECEIVE_CHAT_SESSIONS_WITH_CONSUMER,
} from '../constants'
import history from '../utils/history'
const {getFirstMessage, getRecentMessage} = history


const getInitialState = () => ({
  // Meta
  didInvalidate: false,
  isFetching: false,
  isPaging: false,

  consumer: undefined,
  chatSessions: [],

  // Paging related
  itemsPerPage: 10,
  more: null,
  hasMore: true,
});

const chatSession = (state={}, action) => {
  switch (action.type) {
    case RECEIVE_CHAT_SESSIONS_WITH_CONSUMER:
      const history = _.sortBy(state.history, h => h.chat_msg.seq_num)
      return {
        ...state,
        first_msg: state.history.length > 0 ? (getFirstMessage(state.history) || {}) : {},
        recent_msg: state.history.length > 0 ? (getRecentMessage(state.history) || {}) : {},
        history,
      }
    default:
      return state
  }
}

const chatSessionsWithConsumer = (state=getInitialState(), action) => {
  switch (action.type) {
    case BOT_USER_SESSION_LOGOUT: {
      return getInitialState();
    }
    case RESET_CHAT_SESSIONS_WITH_CONSUMER:
      return getInitialState()
    case REQUEST_CHAT_SESSIONS_WITH_CONSUMER:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
        consumer: action.consumerId,
      }
    case RECEIVE_CHAT_SESSIONS_WITH_CONSUMER:
      let {chat_sessions} = action.data

      chat_sessions = chat_sessions
        .filter(c => (c.consumer === state.consumer) && !_.isEmpty(c.history))

      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        lastUpdated: action.receivedAt,

        chatSessions: _.uniqBy(
          [...state.chatSessions, ...chat_sessions],
          (chat) => chat.id
        ).map((chat) => chatSession(chat, action)),
        more: action.data.more,
        hasMore: action.data.more !== 'done',
      }
    default:
      return state
  }
}

export default chatSessionsWithConsumer
