import React from 'react'
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import cx from 'classnames';
import assign from 'object-assign';
import _ from 'lodash';
// UI components
import Spinner from 'components/ui/pype-spinner/PypeSpinner';
import LabelInput from 'components/ui/forms/LabelInput';
import ErrorBoundary from 'components/ui/ErrorBoundary';

// Local imports
import SubHeader from '../../details/SubHeader';
import SearchField from '../SearchField';
import EmptyState from '../EmptyState';
import _SortableTable from '../SortableTable';
import CreateCannedResponse from '../CreateCannedResponse';
import EmptySearchResults from './EmptySearchResults';
import style from './style.css';
import activityTracker from "../../../../utils/activityTracker";

const COLUMN_NAME_KEY = 'name';
const COLUMN_CATEGORY_KEY = 'category';
const COLUMN_SORT_ASC = 'asc';
const COLUMN_SORT_DESC = 'desc';
const NAME_MAX_LENGTH = 70;
const NAME_REGEX_PATTERN = /^[\d\w\s,@#$%&*!+ ()"'-]+$/i;
const {SortableTable, Head, Column, Body, Row} = _SortableTable;

const getCannedResponses = (cannedResponses, categories) => {
  return cannedResponses.map((item) => {
    return {
      ...item,
      category: _.find(categories, {id: item.category})
    }
  })
}

export class ManageCannedResponse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editingName: null,
      editingCannedResponse: null,
      search: null,
      errors: {},
      sorting: {
        column: null,
        order: null
      }
    };
    this.handleSearch = this.handleSearch.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.isNameValid = this.isNameValid.bind(this);
    this.handleDismissEdit = this.handleDismissEdit.bind(this);
  }
  componentDidMount() {
    const { customerName } = this.props
    const cannedResponses = this.getCannedResponses()

    if (!cannedResponses.length) {
      this.props.fetchCannedResponse();
    }
    activityTracker.logEvent(activityTracker.eventTypeNames.VIEW_PYPE_SETTING,{settingName: 'canned responses', customerName});
  }
  getCannedResponses() {
    const { cannedResponsesStore, categories } = this.props
    return getCannedResponses(cannedResponsesStore, categories)
  }
  handleSearch = (e) => {
    this.setState({ search: e.target.value });
  }
  handleColumnSort = (sortColumn) => {
    const { sorting: { order, column } } = this.state;
    const newOrder = (order === COLUMN_SORT_DESC) ? COLUMN_SORT_ASC : COLUMN_SORT_DESC;

    this.setState({
      sorting: {
        column: sortColumn,
        order: (!order || column !== sortColumn) ? COLUMN_SORT_ASC : newOrder
      }
    });
  }
  handleClickEdit = (id, e) => {
    const cannedResponses = this.getCannedResponses()

    const cannedResponse = _.find(cannedResponses, {id});
    let name = cannedResponse.name;
    let _id = id;

    if(this.state.editingCannedResponse === id) {
      name = null;
      _id = null;
    }

    this.setState({
      editingCannedResponse: _id,
      editingName: name,
      errors: {}
    });
  }
  handleDismissEdit = () => {
    this.setState({
      editingCannedResponse: null,
      editingName: null,
      errors: {}
    });
  }
  isNameValid = () => {
    let isValid = true;
    let error = '';
    const { editingName, errors, editingCannedResponse } = this.state;
    const cannedResponses = this.getCannedResponses()

    if (_.some(cannedResponses, (resp) =>
        (editingCannedResponse !== resp.id && resp.name === editingName)
      )) {
      isValid = false;
      error = 'Your quick reply name must be unique.';
    } else if (!editingName || !editingName.length) {
      isValid = false;
      error = 'Your quick reply must have a name';
    }

    this.setState({
      errors: assign(errors, { editingName: error })
    });

    return isValid;
  }
  handleNameChange = (evt) => {
    const name = evt.target.value;

    if (name === '' || (name.length <= NAME_MAX_LENGTH && NAME_REGEX_PATTERN.test(name))) {
      this.setState({ editingName: name });
    }
  }

  getRows = () => {
    const { sorting, editingCannedResponse, search, errors, editingName } = this.state;
    const cannedResponses = this.getCannedResponses()
    const searchRegex = new RegExp(search, 'gi');

    let sorted = _(cannedResponses)
      .sortBy((cannedResponse) => {
        if (sorting.column === COLUMN_NAME_KEY) {
          return cannedResponse.name.toLowerCase();
        } else if (sorting.column === COLUMN_CATEGORY_KEY) {
          return cannedResponse.category.text.toLowerCase();
        } else {
          return parseInt(cannedResponse.creation_date);
        }
      }).filter((cannedResponse) => {
        if (!search || !search.length) return true;

        return cannedResponse.name.toLowerCase().indexOf(search.toLowerCase()) > -1
          || cannedResponse.category.text.toLowerCase().indexOf(search.toLowerCase()) > -1;
      })
      .value();

    if (sorting.order === COLUMN_SORT_DESC) {
      sorted = sorted.reverse();
    }

    return sorted.map((cannedResponse, index) => {
      const isEditing = editingCannedResponse === cannedResponse.id;
      const { text, category } = cannedResponse;
      let { name } = cannedResponse;
      let categoryName = category.text;

      if (search && !isEditing) {
        name = name.replace(searchRegex, (str) => `<strong>${str}</strong>`);
        categoryName = categoryName.replace(searchRegex, (str) => `<strong>${str}</strong>`);
      }

      return (
        <Row key={index}>
          {isEditing ?
            (<div className="full-width">
              <div className={cx("col-sm-12", style.cell)}>
                <button className={cx(style.btnEdit, { 'active': isEditing })}
                  onClick={this.handleClickEdit.bind(this, cannedResponse.id)}>
                </button>
                <div className={cx(style.editName, { 'has-error': errors.name })}>
                  <LabelInput type="text"
                      value={editingName}
                      className="full-width no-margin"
                      placeholder="e.g. Greeting"
                      onChange={this.handleNameChange}
                      onBlur={this.isNameValid}
                      error={errors.editingName}
                      />
                </div>
              </div>
              <div className={style.editBackdrop}>
                <CreateCannedResponse className="full-width"
                  id={editingCannedResponse}
                  name={editingName}
                  nameError={errors.editingName}
                  originalName={cannedResponse.name}
                  message={text}
                  category={category.id}
                  onDismiss={this.handleDismissEdit}
                  isEditingMode={true}/>
              </div>
            </div>) :
            (<div className="full-width">
                <div className={cx("col-sm-8", style.nameCell, style.cell)}>
                  <button className={cx(style.btnEdit, { 'active': isEditing })}
                    onClick={this.handleClickEdit.bind(this, cannedResponse.id)}>
                  </button>
                  <span dangerouslySetInnerHTML={{ __html: name }}/>
                </div>
                <div className={cx("col-sm-4", style.categoryCell, style.cell)}>
                  <span dangerouslySetInnerHTML={{ __html: categoryName }}/>
                </div>
              </div>)
          }
        </Row>
      );
    });
  }
  render() {
    const { props, state } = this;
    const cannedResponses = this.getCannedResponses()

    if (props.isFetching) {
      return (<div className="full-height"><Spinner style={{ top: '50%' }} /></div>);
    }

    if (!cannedResponses.length) {
      return (<EmptyState/>);
    }

    const rows = this.getRows();

    return (
      <section className={style.container} style={{paddingTop: 0}}>
        <SubHeader>Manage quick replies</SubHeader>
        <p>Quick replies are pre-defined replies that agents can use while chatting with customers to quickly respond to incoming messages</p>
        <section className={style.wrapper}>
          <SearchField placeholder="Search quick replies" className={style.search} onChange={this.handleSearch}/>
          <Link className="btn btn-primary m-l-5" to="create">
            New <i className="fa fa-plus"></i>
          </Link>
        </section>
        <section className={style.table}>
          <ErrorBoundary>
            <SortableTable>
              <Head>
                <Column className="col-sm-8"
                  sortable={true}
                  sortingOrder={state.sorting.column === COLUMN_NAME_KEY ? state.sorting.order : null}
                  onClick={this.handleColumnSort.bind(this, COLUMN_NAME_KEY)}>
                  Name
                </Column>
                <Column className="col-sm-4"
                  sortable={true}
                  sortingOrder={state.sorting.column === COLUMN_CATEGORY_KEY ? state.sorting.order : null}
                  onClick={this.handleColumnSort.bind(this, COLUMN_CATEGORY_KEY)}>
                  Category
                </Column>
              </Head>
              <Body>
                {(rows && rows.length) ? rows : <EmptySearchResults/>}
              </Body>
            </SortableTable>
          </ErrorBoundary>
        </section>
      </section>
    );
  }
}
ManageCannedResponse.propTypes = {
  cannedResponsesStore: PropTypes.array,
  fetchCannedResponse: PropTypes.func.isRequired
};
export default  withRouter(ManageCannedResponse);
