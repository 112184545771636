import React from 'react';
import {withRouter} from 'react-router-dom'
import Favicon from 'react-favicon';
import {connect} from "react-redux";
import Notify from 'notifyjs';
import sdk from 'sdk'
import _ from 'lodash'
import { MAX_IDLE_TIME as GLOBAL_MAX_IDLE_TIME, LOGOUT_WARNING, GLOBAL_COUNTDOWN_LAST_RESET_TIMESTAMP } from 'bot-user-session';
import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { Button } from 'ui-elements';
import blink_worker_script from './blink_worker';
import logout_worker_script from './logout_worker';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

var { utils  } = sdk
var { getUnreadChatSessions, isAgentChat} = utils.agentChatSessions;

var notify;

class Notifications extends React.Component{

    constructor(props) {
      super(props);
      this.blinkWorker = null;
      this.logoutCountdownWorker = null;
    }
    state = {
          ignore: true,
          title: null,
          options: null,
          currentMessage: -1,
          consumerId: null,
          chatroomId: null,
          dialogIsOpen: false,
          isPageActive: true,
    }

  favicons = {
    default: this.props.flags && this.props.flags.brandInfo && this.props.flags.brandInfo.favicon ? this.props.flags.brandInfo.favicon : `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/favicon.ico`,
    alert: this.props.flags && this.props.flags.brandInfo && this.props.flags.brandInfo.alertFavicon ? this.props.flags.brandInfo.alertFavicon : `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/favicon_new_alert.svg`
  };
  appleTouchIcons = {
    default: this.props.flags && this.props.flags.brandInfo && this.props.flags.brandInfo.appleTouchIcon ? this.props.flags.brandInfo.appleTouchIcon : `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/apple-touch-icon.png`,
    alert: this.props.flags && this.props.flags.brandInfo && this.props.flags.brandInfo.alertAppleTouchIcon ? this.props.flags.brandInfo.alertAppleTouchIcon : `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/alert-apple-touch-icon.png`
  };
  brandTitle = this.props.flags && this.props.flags.brandInfo && this.props.flags.brandInfo.title ? this.props.flags.brandInfo.title : "Pypestream";

    componentWillReceiveProps(nextProps){
      var newChat = nextProps.createdChat;
      var currentChat = this.props.createdChat;
      const {isAgentChat} = nextProps;
      var fromUser = newChat.from;
      const userId = this.props.userId

      if(newChat.id != currentChat.id &&
        fromUser != userId && isAgentChat){
        this.showNotification(newChat);
      }
    }
    onShowNotification(){
         console.log('notification is shown!');
    }
     onCloseNotification(){
        console.log('notification is closed!');
    }
     onClickNotification(){
        var consumerId = this.state.consumerId;
        var chatroomId = this.state.chatroomId;
        const { match } = this.props

       if (this.props.flags.pe18541) {
         this.props.history.push(`/${match.params.pypeId}/chats/view/${chatroomId}/${consumerId}?src_notification=${Math.random()}`);
       } else {
         this.props.history.push(`/${match.params.pypeId}/chats/view/${chatroomId}/${consumerId}`);
       }
        window.self.focus();
    }
     onErrorNotification(){

    }
     onPermissionGranted(){

    }
     onPermissionDenied(){

    }
     showNotification(notification){

       // if(notification.type == 'new_message' &&
       //    notification.data.user.id != adminId) {
       //    notificationsActions.addUnreadConversation(notification.data.chat_room_id);
       //  }
       if (!Notify.needsPermission) {
        var userName = notification.consumer_name || 'Anonymous User';
        var streamName = notification.stream_name;
        var title = 'New message from '+ userName + ' (' + streamName + ')';
        var msg = notification.msg;
        var chatId = notification.chat_id;
        var consumerId = notification.consumer_id;

        this.setState({
          consumerId: consumerId,
          chatroomId: chatId
        })
        notify = new Notify(title, {
              body: msg,
              tag: chatId || 0,
              notifyShow: this.onShowNotification.bind(this),
              notifyClose: this.onCloseNotification.bind(this),
              notifyClick: this.onClickNotification.bind(this),
              notifyError: this.onErrorNotification.bind(this),
              timeout: 4,
              icon: `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/favicon.ico`
          });

          notify.show();
          this.playAlert();

        } else if (Notify.isSupported()) {
          Notify.requestPermission(this.onPermissionGranted, this.onPermissionDenied);
        } else {
          this.playAlert();
        }
    }

    defaultUnreadLogoutWarning = () => {
      const { unreadConversationsCount } = this.props;
      let title;
      let key;
      if (unreadConversationsCount > 0) {
        title = ['(', unreadConversationsCount, ') ', this.brandTitle].join('');
        key = "alert";
      } else {
        title = this.brandTitle;
        key = "default";
      }
      this.setState({ key },() => {
        if(!document.querySelector("link[rel~='apple-touch-icon']")) {
          var link = document.createElement('link');
          link.rel = 'apple-touch-icon';
          link.href = this.appleTouchIcons[this.state.key];
          document.head.appendChild(link);
        } else {
          document.querySelector("link[rel~='apple-touch-icon']").href = this.appleTouchIcons[this.state.key];
        }
      });
      return title;
    };

    getMaxIdleTime =()=>{
      const customerId = this.props.customerId;
      let logoutTimeout = GLOBAL_MAX_IDLE_TIME;
      const logoutTimeoutTimeFlag = this.props.flags.logoutTimeoutTime
      if (logoutTimeoutTimeFlag && this.props.pe18506) {
        const defaultTimeout = logoutTimeoutTimeFlag.default;
        const customerTimeout = logoutTimeoutTimeFlag[customerId];
        const timeoutInMinute = customerTimeout ? customerTimeout: defaultTimeout;

        logoutTimeout = timeoutInMinute * 60 * 1000;
      }
      return logoutTimeout;
    }
    
    countDownInterval = () => {
      const last_reset_timestamp = Number(window.localStorage.getItem(GLOBAL_COUNTDOWN_LAST_RESET_TIMESTAMP));
      let timeToLogout;

      if (!isNaN(last_reset_timestamp)) {
        const MAX_IDLE_TIME = this.getMaxIdleTime();
        const cTime = (new Date()).getTime();
        if((last_reset_timestamp + MAX_IDLE_TIME - cTime) <= LOGOUT_WARNING) {
          this.setState({
            dialogIsOpen: true,
          }, this.notifyLogout);
          timeToLogout = (last_reset_timestamp + MAX_IDLE_TIME - cTime);
        }
      }

      this.setState({
        timeToLogout,
      })
    };

    notifyLogout = () => {
      if (this.props.flags.showLogoutNotification && (document.hidden || !document.hasFocus())) {
        new Notify('AgentPro logout warning: you are about to get logout.', {
          body: 'For security reasons you will be logged out soon and will stop receiving new messages.',
          tag: 0,
          notifyShow: this.onShowNotification.bind(this),
          notifyClose: this.onCloseNotification.bind(this),
          notifyClick: this.onClickNotification.bind(this),
          notifyError: this.onErrorNotification.bind(this),
          timeout: 5,
          icon: `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/favicon.ico`
        }).show();
      }
    }

    logoutInterval = () => {
      this.countDownInterval();
      if (this.state.timeToLogout) {
        this.logoutCountdownWorker.terminate();
        this.blink();
      } else {
        document.title = this.defaultUnreadLogoutWarning();
      }
    };

    componentDidMount() {
      this.logoutCountdownWorker = new Worker(logout_worker_script);
      this.logoutCountdownWorker.onmessage = () => {
        this.logoutInterval();
      }
    };

    componentWillUnmount() {
      document.title = this.brandTitle;
      document.querySelector("link[rel~='icon']").href = this.favicons.default;
      document.querySelector("link[rel~='apple-touch-icon']").href = this.appleTouchIcons.default;
      
      if (this.blinkWorker) {
        this.blinkWorker.terminate();
      }
      if (this.logoutCountdownWorker) {
        this.logoutCountdownWorker.terminate();
      }
    };

    blink() {
      const self = this;
      this.logoutCountdownWorker = new Worker(logout_worker_script);
      this.logoutCountdownWorker.onmessage = () => {
        this.countDownInterval();
      };
      self.blinkWorker = new Worker(blink_worker_script);
      self.blinkWorker.onmessage = () => {
        let title = this.defaultUnreadLogoutWarning();
        if (document.title === title) {
          const timey = self.convertMiliseconds(self.state.timeToLogout).split(":");
          const minute = parseInt(timey[0]);
          const seconds = parseInt(timey[1]);
          document.title =
            minute > 0
              ? `Logout in ${seconds < 1 ? minute : minute + 1} minutes`
              : seconds > 1
              ? `Logout in ${seconds > 0 ? seconds - 1 : 0} seconds`
              : title;
        } else {
          document.title = title;
        }
      };
    }

    handleClose = () => {
      this.setState({ dialogIsOpen: false });
      this.blinkWorker.terminate();
      this.logoutCountdownWorker.terminate();
      this.logoutCountdownWorker = new Worker(logout_worker_script);
      this.logoutCountdownWorker.onmessage = () => {
        this.logoutInterval();
      }
    }

    convertMiliseconds(miliseconds, format) {
      var days, hours, minutes, seconds, total_hours, total_minutes, total_seconds;
      
      total_seconds = parseInt(Math.floor(miliseconds / 1000));
      total_minutes = parseInt(Math.floor(total_seconds / 60));
      total_hours = parseInt(Math.floor(total_minutes / 60));
      days = parseInt(Math.floor(total_hours / 24));
    
      seconds = parseInt(total_seconds % 60);
      minutes = parseInt(total_minutes % 60);
      hours = parseInt(total_hours % 24);
      
      switch(format) {
        case 's':
          return total_seconds;
        case 'm':
          return total_minutes;
        case 'h':
          return total_hours;
        case 'd':
          return days;
        default:
          return `${minutes}:${('0' + seconds).slice(-2)}`;
      }
    };

    showDialog = () => {
      const { dialogIsOpen, timeToLogout } = this.state;
      return <Dialog
        onClose={this.handleClose} 
        onClick={() => this.setState({ dialogIsOpen: false })}
        open={dialogIsOpen}
        hideBackdrop
        maxWidth='xs'
        PaperProps={{
          style: {
            position: 'absolute',
            top: 0,
            right: 0
          }
        }}
      >
        <DialogContent style={{ display: 'flex', padding: '16px' }}>
          <div>
            <svg style={{ marginRight: '8px' }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <g fill="none" fill-rule="evenodd">
                  <g>
                      <g transform="translate(-754 -204) translate(754 204)">
                          <circle cx="12" cy="12" r="12" fill="#FFF"/>
                          <path fill="#E45F4F" d="M10.7 3.75c.577-1 2.024-1 2.6 0l7.496 13c.578 1-.147 2.25-1.297 2.25H4.502c-1.156 0-1.875-1.253-1.3-2.25zM12 15.125c-.485 0-.876.39-.876.875 0 .484.39.875.875.875s.875-.39.875-.875c0-.484-.39-.875-.875-.875zM12.551 8h-1.11c-.105 0-.19.087-.187.194l.235 6.125c.003.103.087.181.187.181h.64c.1 0 .185-.081.188-.181l.235-6.125c.003-.107-.081-.194-.188-.194z"/>
                      </g>
                  </g>
              </g>
            </svg>
          </div>
          <div>
            <p style={{ margin: 0, fontFamily: 'Open Sans', lineHeight: 1.5, fontSize: '16px' }}>For security reasons you will be logged out soon and will stop receiving new messages.</p>
            <br />
            <p style={{ margin: 0, fontFamily: 'Open Sans', lineHeight: 1.5, fontSize: '16px' }}>{timeToLogout > 0 ? this.convertMiliseconds(timeToLogout) : '0:00'} until you are logged out</p>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button hollow style={{ marginRight: 0 }} onClick={() => this.setState({ dialogIsOpen: false })}>Stay Logged In</Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    }

    render(){
      const { key } = this.state;

      return (
        <div>
          <Favicon url={this.favicons[key]} />
          {this.showDialog()}
        </div>);
    }
    playAlert(){
      var fileFormat = "mp3";
      var mp3Test = new Audio();
      var canPlayMP3 = (typeof mp3Test.canPlayType === "function" && mp3Test.canPlayType("audio/mpeg") !== "");
      if (!canPlayMP3) {
        fileFormat = "ogg";
      }
      var sound = new Audio('https://s3-us-west-1.amazonaws.com/static.pypestream.com/audio/ps_message_alert.' + fileFormat);
      sound.play();
    }

};

var mapStateToProps = function(state){
    return {
      createdChat: state.conversationHistory.createdChat,
      isFetching: state.conversationHistory.isFetching,
      unreadConversationsCount: getUnreadChatSessions(state).length,
      userId: state.botUserSession.user.user_id,
      isAgentChat: isAgentChat(state, state.conversationHistory.createdChat.chat_id),
      customerId: state.pype.details.customer_id

    };
};

export default  withRouter(connect(mapStateToProps)(withLDConsumer()(Notifications)));
