import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { NavLink, Route } from "react-router-dom";
import _ from "lodash";
import sdk from "sdk";
import { withRouter } from "react-router-dom";
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import {
  ConvoCard,
  ConvoTitleBox,
  ConvoName,
  ConvoTimeBox,
  ConvoTimer,
  MessageIndicator,
  ConvoSubheading,
  ConvoMessage,
  ConvoAgent,
  ConvoBox,
  StyledHeadSetIcon,
  StyledAccessTimeIcon,
  StyledCheckCircleOutlineIcon,
  ArchiveDate,
  EndChatOption,
  disabledTitle,
  WarningTooltip,
  UnreadMessage,
  timeDifference,
  ClassifyButton,
} from "./helpler";

import { renderToolTip } from "components/ui/tooltip";
import useInterval from "hooks/useInterval";
import Constant from "core/constant";
import cx from 'classnames';
import activityTracker from "../../../utils/activityTracker";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";
import Grid from "@material-ui/core/Grid";

//Remove this import after feature flags are removed
import TimeAgo from "react-timeago";

const { agentChatSessionsActions, conversationsActions } = sdk;
const REPLY_TIME_EXCEEDED = `0:${(Constant.REPLY_TIME_EXCEEDED - 1) / 1000}`;
const NON_CONSUMER_SENDERS = ["agent", "bot"];

const Component = (props) => {
  const [position, setPosition] = useState({ x: undefined, y: undefined });
  const prevSenderRef = useRef();
  
  useEffect(() => {
    prevSenderRef.current = sender;
  });

  const prevSender = prevSenderRef.current;

  const onCloseClick = (e) => {
    let isClickDisabled = props.isClickDisabled && props.flags.isClickDisabledAfterChatEnded;
    if(!props.flags.newClassifyChatForm) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (!isClickDisabled) props.onClose(props.conversation.id);
  };

  const handleMouseMove = (e) => {
    if (props.isClickDisabled && props.flags.isClickDisabledAfterChatEnded) {
      setPosition({ x: e.pageX, y: e.pageY });
    }
  };

  const conversation = props.conversation;
  
  if (!conversation) {
    props.history.push(props.match.url);
    return null;
  }

  const message = conversation.recent_msg;
  const agentAddedAction = conversation.participant_history
    ? conversation.participant_history.find(
        (history) =>
          history.action === "added" && history.participant_type === "agent"
      )
    : null;

  const time = agentAddedAction && agentAddedAction.time > message.timestamp
    ? agentAddedAction.time
    : message.timestamp;
  
  const getChats = () => {
    let chats;
    if(props.messageTimeStamps[chatId]) {
      chats = [...props.chatHistory.filter(chatSession => chatSession.id === props.conversation.id)[0].history, ...props.messageTimeStamps[chatId]]
    } else {
      chats = props.chatHistory.filter(chatSession => chatSession.id === props.conversation.id)[0].history;
    }
    chats =  _.orderBy(chats, (item) => item.chat_msg.seq_num, ["desc"]);
    return chats;
  }

  // Fetch timestamp for the first time.
  const fetchTimeStamp = () => {
    let chatHistory = getChats();
    chatHistory = chatHistory.filter(chat => chat.chat_msg.timestamp > agentAddedAction.time);
    if(chatHistory.length < 1) {
      //If no history is received from platform
      if (props.flags.pe18974 && (!conversation.participant_history || conversation.participant_history.length === 0)) {
        return time;
      }
      return conversation.participant_history[0].time;
    }
    if (chatHistory.length < 2) {
      return props.flags.pe18378 ? agentAddedAction.time : time;
    }

    const current_sender = chatHistory[0].chat_msg.from_side;
    
    let diff = -1;
    for (let i = 1; i < chatHistory.length; i++) {
      if (
        current_sender !== chatHistory[i].chat_msg.from_side &&
        (current_sender === "agent" || chatHistory[i].chat_msg.from_side === "agent")
      ) {
        diff = i;
        break;
      }
    }
    if(props.flags.pe18378 && diff === -1) {
      return agentAddedAction.time;
    }
    let chatIndex = diff !== -1 ? diff - 1 : chatHistory.length - 1;
    return agentAddedAction.time > chatHistory[chatIndex].chat_msg.timestamp
    ? agentAddedAction.time
    : chatHistory[chatIndex].chat_msg.timestamp;
  };

  const fetchUnreadCount = () => {
    let chats = getChats();
    let lastReadSeqNum;
    if(props.lastReadMessages[chatId]) {
      lastReadSeqNum = Number(props.lastReadMessages[chatId].split('.')[1]);
    } else {
      const agentReadCount = conversation.user_read_counts && conversation.user_read_counts.find(chat => chat.user_id === props.userId);
      lastReadSeqNum = agentReadCount ? agentReadCount.max : 0;
    }
    const allUnreadMessages = chats.length - lastReadSeqNum;
    if(!allUnreadMessages) return 0;
    chats = chats.slice(0, allUnreadMessages);
    chats = chats.filter((chat) => {
      return (
        NON_CONSUMER_SENDERS.indexOf(chat.chat_msg.from_side) === -1 && 
        agentAddedAction && chat.chat_msg.timestamp > agentAddedAction.time
      );
    })
    return chats.length;
  };

  const hasAgentInteracted = () => {
    let chats = getChats();
    chats = chats.filter(chat => chat.chat_msg.timeStamp > agentAddedAction.time);
    if(chats.length) {
      chats = chats.filter(chat => chat.chat_msg.from_side === "agent"); 
    }
    return chats.length;
  }

  const name = conversation.consumer_name || "";
  const chatId = conversation.id;
  const stream = conversation.stream_name;
  const isUnread = props.isUnread;
  const msgId = message.id;
  const consumerId = conversation.consumer;
  const isEnded = conversation.status === "ended";
  const isActiveTab = props.activeTab;
  let agentName = conversation.agent_name || "Unknown";

  agentName = agentName
    .split(" ")
    .map((str) => str.charAt(0).toUpperCase() + str.substring(1))
    .join(" ");

  const skillObj = props.skills.filter((sk) => {
    return sk.id === conversation.skill;
  })[0];

  const skill = skillObj ? skillObj.name : "general";
  const { isNotActiveChat, isManager } = props;
  let isClickDisabled = props.isClickDisabled && props.flags.isClickDisabledAfterChatEnded;
  const isBotMessage = !_.isEmpty(message.app_object) && message.from_side === "bot";
  let botMessageData;
  
  if (isBotMessage) {
    botMessageData = JSON.parse(atob(message.app_object));
  }
  
  const text = isBotMessage ? botMessageData.label : message.msg;
  const isTransffered = conversation.user_read_counts.filter(user => props.flags.pe18544 ? !user.user_id.includes('Survey') : true).length > 2;
  const msg_from = conversation.recent_msg.from_side;
  const msg_sender = conversation.recent_msg.from;
  const has_agent_replied = msg_from === "agent" && conversation.agent_assign_ts < conversation.recent_msg.timestamp;

  const [timeStamp, setTimeStamp] = useState(isActiveTab === "archived" || !props.flags.newMessageTimer ? time : ()=>fetchTimeStamp());
  const [unreadCount, setUnreadCount] = useState(isActiveTab !== "active" || !props.flags.newMessageIndicator ? 0 : () => fetchUnreadCount());
  const [sender, setSender] = useState(msg_sender);
  const [timeString, setTimeString] = useState(timeDifference(new Date(timeStamp), new Date()));
  const [overtime, setOvertime] = useState(
    REPLY_TIME_EXCEEDED.localeCompare(timeString) === -1 && !has_agent_replied
  );
  const [agentInteracted, setAgentInteracted] = useState(isActiveTab === "archived" || !props.flags.newMessageTimer ? [] : () => hasAgentInteracted());

  // Update time shown on the timer
  const updateTimeString = () => {
    const timeDiff = timeDifference(new Date(timeStamp), new Date());
    setTimeString(timeDiff.split('-').length > 1 ? "0:00" : timeDiff);
  };

  // Update unread message count on the card
  const updateUnreadMessages = () => {
    if(!agentInteracted && has_agent_replied) {
      setAgentInteracted(true);
    }
    if (prevSender && isUnread && NON_CONSUMER_SENDERS.indexOf(msg_from) === -1 ) {
      setUnreadCount(unreadCount => unreadCount + 1);
    }
    if (!isUnread) {
      setUnreadCount(0);
    }
  };

  // Update the timer color if user is still waiting for reply from an agent for 30 secs or more.
  const updateOvertime = () => {
    if (
      !overtime &&
      prevSender !== "agent" &&
      REPLY_TIME_EXCEEDED.localeCompare(timeString) < 0
    ) {
      setOvertime(true);
    }
  };

  const resetTimer = () => {
    setTimeStamp(time);
    setTimeString('0:00');
    if (overtime) setOvertime(false);
  }

  // Update icon and timestamp when the sender is changed
  const updateSender = () => {
    setSender(msg_sender);
    if (prevSender && prevSender !== msg_sender && (agentInteracted || has_agent_replied)) {
      resetTimer();
    }
  };
  
  useInterval(props.flags.newMessageTimer ? updateTimeString : () => {}, !isEnded ? 1000 : null);
  useEffect(props.flags.newMessageTimer ? updateOvertime : () => {}, [ timeString ]);
  useEffect(props.flags.newMessageIndicator ? updateSender : () => {}, [ msg_sender, agentAddedAction ? agentAddedAction.time : null]);
  useEffect(props.flags.newMessageIndicator ? updateUnreadMessages : () => {}, [ msgId, isUnread ]);

  const { match } = props;

  const messageShorten = (text) => {
    if(!text) {
      return "";
    }
    if (text.length > 35) {
      return text.substring(0, 35) + "...";
    }
    return text;
  };

  const endChatOptions = () => {
    let { isClickDisabled } = props;
    isClickDisabled = isClickDisabled && props.flags.isClickDisabledAfterChatEnded;
    if ((props.tagsRequired || props.commentRequired) && props.flags.newEndChatButton) {
      return (
        <EndChatOption
          onClick={onCloseClick}
          className={`${isClickDisabled ? "isClickDisabled" : null}`}
        >
          {props.tagsRequired ? "CLASSIFY" : "COMMENT"}
        </EndChatOption>
      );
    } else {
      let closeButton = props.flags.newEndChatButton ? (
        <MessageIndicator
          className={`closed ${isClickDisabled ? "isClickDisabled" : null}`}
          onClick={onCloseClick}
          data-cy="close-conversation"
        >
          <ClearOutlinedIcon />
        </MessageIndicator>
      )
        :
        <>
          {props.tagsEnabled || props.commentEnabled ? (
            <ClassifyButton onClick={onCloseClick} small>
              {props.tagsEnabled ? "Classify" : "Comment"}
            </ClassifyButton>
          ) : null}{" "}
          <MessageIndicator
            className="closed"
            onClick={onCloseClick}
            data-cy="close-conversation"
          >
            <ClearOutlinedIcon />
          </MessageIndicator>
        </>;
      let closeButtonTitle = "Close";
      let closeButtonToolTip = { node: closeButton, title: closeButtonTitle };
      return (
        <>
          { props.flags.newEndChatButton ?
            renderToolTip(closeButtonToolTip)
            : closeButton
          }
        </>
      );
    }
  };

  const _conversationCard = (matchPath) => (
    <ConvoCard
      className={`${matchPath !== null ? "selected" : null} ${
        isEnded ? "ended" : null
      }`}
      elevation={isEnded ? 0 : 1}
      onMouseMove={handleMouseMove}
      data-cy="convo-card"
    >
      <ConvoTitleBox>
      <ConvoName 
            className={cx(
              'truncate',{
              'selected': matchPath !== null,
              'ended': isEnded && isActiveTab === 'active', 
              'isClickDisabled': isClickDisabled
            })}
          >
          {name}
        </ConvoName>

        <ConvoTimeBox>
          {isEnded ? (
            isActiveTab === "archived" ? (
              <ArchiveDate>
                {moment(time)
                  .format("MMM D, h:mm A")
                  .toUpperCase()}
              </ArchiveDate>
            ) : isActiveTab === "active" ? (
              endChatOptions()
            ) : props.tagsEnabled || props.commentEnabled ? (
              <EndChatOption>
                {props.tagsEnabled ? "CLASSIFY" : "COMMENT"}
              </EndChatOption>
            ) : null
          ) : (
            <>
              {
                props.flags.newMessageTimer ? 
                  <ConvoTimer
                    className={cx({
                    "agent-has-replied": has_agent_replied || isActiveTab === "archived",
                    "overtime": !has_agent_replied && overtime && isActiveTab !== "archived"
                    })}
                  >
                    {timeString}
                  </ConvoTimer>
                :
                  <ConvoTimer className={!isUnread ? "agent-has-replied" : "overtime"}>
                    <TimeAgo date={time} />
                  </ConvoTimer>
              }
              {
                props.flags.newMessageIndicator ?
                  <MessageIndicator
                    className={cx({
                      "agent-has-replied": has_agent_replied || isActiveTab === "archived",
                      "overtime": !has_agent_replied && overtime && isActiveTab !== "archived"
                    })}
                  >
                    {has_agent_replied || isActiveTab === "archived" ? (
                      <StyledCheckCircleOutlineIcon />
                        ) : (props.closeToChatEndId && (props.closeToChatEndId === props.conversation.id)) || (unreadCount <= 0) ? (
                          <StyledAccessTimeIcon />                          
                        ) : (
                        <UnreadMessage>
                          {unreadCount > 9 ? "9+" : unreadCount}
                        </UnreadMessage>
                    )}
                  </MessageIndicator> 
                :
                  <MessageIndicator className={!isUnread ? "agent-has-replied" : "overtime"}>
                    {!isUnread ? <StyledCheckCircleOutlineIcon /> : <StyledAccessTimeIcon /> }
                  </MessageIndicator>
              }     
            </>
          )}
        </ConvoTimeBox>
      </ConvoTitleBox>
      <ConvoSubheading
        className={`${isClickDisabled ? "isClickDisabled" : null}`}
      >
        {skill}&nbsp;•&nbsp;{stream}
        {isTransffered ? " • Transfer" : null}
      </ConvoSubheading>
      <ConvoBox>
        <ConvoMessage
          className={cx({
            "unread": isUnread,
            "convoEnded": isEnded || msg_from === "agent",
            "isClickDisabled": isClickDisabled
          })}
        >
          <Grid container direction="row" alignItems="center">
            <Grid item>
              {msg_from === "agent" ? (
                <Grid container alignItems="center">
                  <ArrowBackIcon className="icon" />
                  &nbsp;
                </Grid>
              ) : null}
            </Grid>
            <Grid item>{messageShorten(text)}</Grid>
          </Grid>
        </ConvoMessage>
        {isManager && isNotActiveChat && isActiveTab !== "active" && (
          <ConvoAgent>
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="flex-end"
            >
              <Grid item className={cx('agent-name', 'truncate')}>{`${agentName}`}</Grid>
              <Grid item>
                <Grid container alignItems="center">
                  &nbsp;
                  <StyledHeadSetIcon />
                </Grid>
              </Grid>
            </Grid>
          </ConvoAgent>
        )}
      </ConvoBox>
    </ConvoCard>
  );
  
  const getConversationCard = (matchPath) =>
    isClickDisabled
      ? 
      <WarningTooltip
      title={disabledTitle}
      aria-label={Constant.TOOLTIPS.convo_card_disabled_warning}
      placement="top-start"
      PopperProps={{
        anchorEl: {
          clientHeight: 0,
          clientWidth: 0,
          getBoundingClientRect: () => ({
            top: position.y,
            left: position.x,
            right: position.x,
            bottom: position.y,
            width: 0,
            height: 0,
          })
        }
      }}
    >
      {_conversationCard(matchPath)}
    </WarningTooltip>
      : _conversationCard(matchPath);

  return (
    <NavLink
      key={msgId}
      to={isClickDisabled ? "#" : `${match.url}/view/${chatId}/${consumerId}`}
      className="list-item"
      onClick={() =>
        !isClickDisabled
          ? activityTracker.logEvent(
              activityTracker.eventTypeNames.OPEN_MESSAGE
            )
          : null
      }
    >
      <Route
        path={`${match.url}/view/${chatId}/${consumerId}`}
        children={({ match }) => getConversationCard(match)}
      />
    </NavLink>
  );
};

const mapStateToProps = (state, props) => {
  const { details } = state.pype;
  const differentChatSelected =
    state.conversationHistory.id !== props.conversation.id;
  const found = state.agentChatSessions.chatSessions.some(el => el.id === state.conversationHistory.id)
  const isClickDisabled =
    props.activeTab === "active" &&
    (state.conversationHistory.id !== props.conversation.id &&
      (details.end_comment_required || details.end_tags_required) &&
      state.allConversations.endedNotClassifiedChatSessions.includes(
        state.conversationHistory.id
      ) && found);

  return {
    pypeId: details.id,
    commentEnabled: details.end_comment_display,
    tagsEnabled: details.end_tags_display,
    commentRequired: details.end_comment_required,
    tagsRequired: details.end_tags_required,
    agentsForPype: state.agentsForPype.agents,
    isClickDisabled,
    differentChatSelected,
    chatHistory: props.activeTab === "active" ? Array.isArray(state.agentChatSessions.chatSessions) ? 
      state.agentChatSessions.chatSessions : [] 
      : props.activeTab === "escalated" ? 
      Array.isArray(state.agentChatSessions.escalated_chatSessions) ?
       state.agentChatSessions.escalated_chatSessions : [] 
      : [],
    messageTimeStamps: state.allConversations.messageTimestamps,
    lastReadMessages: state.allConversations.lastReadMessages,
    closeToChatEndId: state.allConversations.closeToChatEndId,
    userId: state.botUserSession.user.user_id,
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  onSubmit(data) {
    return dispatch(conversationsActions.classifyChat(data));
  },
  afterChatClassification() {
    return dispatch(
      conversationsActions.recentlyEndedChatForClassification(null)
    );
  },
  removeChatSession(chatId) {
    return dispatch(agentChatSessionsActions.removeChatSession(chatId));
  },
});

const ComponentWithFeatureFlags = withLDConsumer()(Component);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ComponentWithFeatureFlags)
);
