import React from 'react';
import style from './style.css';
import PropTypes from 'prop-types';

const Body = (props) => {
  return (
    <div className={style.body}>
      {props.children}
    </div>
  );
};

Body.propTypes = {
  children: PropTypes.node
};

export default  Body;