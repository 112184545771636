import _ from 'lodash'
import { BOT_USER_SESSION_LOGOUT } from "bot-user-session";
import { initSeqConsts, skillsConstants as types } from '../constants'
import {sortSkills} from '../utils/skills'

const getInitialState = () => ({
  isFetching: false,
  skillsStore: [],
  skillStore: [],
  selectedSkill: [],
});

const skills = (state=getInitialState(), action) => {
  switch (action.type) {
  case BOT_USER_SESSION_LOGOUT: {
    return getInitialState();
  }
  case types.REQUEST_SKILLS:
  case initSeqConsts.FETCHING:
    return {
      ...state,
      isFetching: true,
    }
  case types.RECEIVE_SKILLS:
  case initSeqConsts.FETCHED:
    const data = action.type === initSeqConsts.FETCHED ? action.data.skills : action.data.list;
    if (!data) {
      return state;
    }
    return {
      ...state,
      isFetching: false,
      skillsStore: sortSkills(data)
    }
  case types.REQUEST_SKILL:
    return {
      ...state,
      isFetching: true,
    }
  case types.RECEIVE_SKILL:
    return {
      ...state,
      isFetching: false,
      skillStore: sortSkills(action.data.list),
    }
  case types.DELETED_SKILL:
    return {
      ...state,
      skillsStore: _.filter(state.skillsStore, (skill) => (skill.id !== action.data.id))
    }
  case types.UPDATED_SKILL:
    return {
      ...state,
      skillsStore: _.map(state.skillsStore, (skill) => {
        if(skill.id === action.data.id){
          return {
            ...skill,
            ...action.data
          }
        } else {
          return skill;
        }
      })
    }
  case types.CREATED_SKILL:
    return {
      ...state,
      skillsStore: sortSkills([...state.skillsStore, action.data])
    }
  case types.SELECTED_SKILL:
    return {
      ...state,
      selectedSkill:action.skill
    }
  default:
    return state
  }
}

export default skills
