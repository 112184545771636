import style from './style.scss';
import React from 'react'
import PropTypes from 'prop-types'
const EndChatButton =  require('../EndChatButton')

const { closeIcon, minimizeIcon, expandIcon } =  require('./svg')

const isDark = (color) => color === '#000' || color === '#000000'

class TitleBar extends React.Component {

  render () {
    const {props, context} = this
    const finalStyle = context.design ? {
      background: context.design.widgetStyleColor,
      color: context.design.widgetStyleTextColor,
    } : {}
    return (
      <div className={style.HeaderStyled}>
        <div className={style.FixedHeader} style={finalStyle}>
          <div className={style.HeaderElements}>
            <div className={style.TitleBar}>
              <div
                className={style.BarLogo}
                style={{
                  background: `url(${context.pypeLogo}) no-repeat center`,
                  backgroundSize: 'contain',
                }}
              />
              <div className={style.Title}>
                {props.children}
              </div>
            </div>

            <div className={style.Controllers}>
              <div style={{ background: `url(${expandIcon})` }} />
              {props.isCloseEnabled &&
                <div style={{ background: `url(${minimizeIcon})` }} onClick={props.onCloseClick} />
              }
              <div style={{ background: `url(${closeIcon})` }} />
            </div>
          </div>
        </div>
      </div>
    )
  }

}

TitleBar.contextTypes = {
  design: PropTypes.object,
  pypeLogo: PropTypes.string,
}

TitleBar.propTypes = {
  children: PropTypes.node,
  onCloseClick: PropTypes.func,
  isCloseEnabled: PropTypes.bool
}

TitleBar.defaultProps = {
  isCloseEnabled: true
}

export default  TitleBar
