import React from 'react';
import style from './style.css';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SortIcon = (props) => {
  return (
    <div className={cx(style.sortIcon, { 'desc': props.order === 'desc', 'asc': props.order === 'asc' })}>
      <FontAwesomeIcon icon="caret-up" />
      <FontAwesomeIcon icon="caret-down" />
    </div>
  );
};

SortIcon.propTypes = {
  order: PropTypes.string
};

SortIcon.defaultProps = {
  order: 'asc'
};

export default  SortIcon;