import _ from 'lodash';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux';
import Component from './Component'
import sdk from 'sdk';

const { cannedResponsesActions } = sdk;

const mapStateToProps = (state) => {
  return {
    cannedResponsesStore: state.cannedResponses.cannedResponses,
    categories: state.cannedResponses.categories,
    isFetching: state.cannedResponses.isFetching,
    customerName: state.pype.details.customerName
  }
  
};

const mapDispatchToProps = (dispatch) => {

  const actionCreators = bindActionCreators({
    fetchCannedResponse: cannedResponsesActions.fetchCannedResponses
  }, dispatch);

  return actionCreators;
}

export default  connect(
  mapStateToProps,
  mapDispatchToProps
)(Component)