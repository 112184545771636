import style from './style.css';
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames';

const ChatCircle = (props) => {
  const finalStyle = props.design ? {
    ...props.style,
    background: props.design.widgetStyleColor,
  } : props.style
  let src = `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-chats.svg`
  if (props.design && props.design.widgetStyleTextColor === '#000000') {
    src = `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-chats-dark.svg`
  }
  return (
    <div className={classNames(style.ChatCircle, props.className)} style={finalStyle} onClick={props.onClick}>
      <img src={src} />
    </div>
  )
}

ChatCircle.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  design: PropTypes.object,
  onClick: PropTypes.func,
}

ChatCircle.defaultProps = {
  className: '',
  style: {},
  onClick () {},
}


export default  ChatCircle
