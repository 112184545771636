import React from 'react'
import PropTypes from 'prop-types';
import cx from 'classnames';
import Textarea from 'react-textarea-autosize';

import style from './style.css';

class LabelTextTags extends React.Component {
  constructor(props) {
    super(props);
    this.onDrop = this.onDrop.bind(this);
    this.onDrag = this.onDrag.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onTagClick = this.onTagClick.bind(this);
    this.state = {
      charCount: this.props.value && this.props.value.length || 0
    };
  }
  // TODO nice to have feature
  // setColor() {
  //     document.execCommand('styleWithCSS', false, true);
  //     document.execCommand('foreColor', false, 'red');
  // }
  componentWillReceiveProps(nextProps) {
    this.setState({
      charCount: nextProps.value && nextProps.value.length || 0
    });
  }
  onTagClick = (e) => {
    const text = e.currentTarget.getAttribute('data-drop-value');
    const textarea = this.refs.textarea._ref;
    const message = textarea.value;
    const pos = textarea.selectionStart;
    const tagAdded = [
      message.substr(0, pos),
      ' {',
      text,
      '} ',
      message.substr(pos)
    ].join('');

    this.props.onChange(tagAdded);

    setTimeout(() => {
      textarea.selectionStart = textarea.selectionEnd = pos;
    }, 0);
  }
  insertText = (text, range) => {
    range.deleteContents();
    var textNode = document.createTextNode(text);
    
    range.insertNode(textNode);
    range.selectNodeContents(textNode);
    range.collapse(false);

    var selection = window.getSelection();

    selection.removeAllRanges();
    selection.addRange(range);
  }
  onDrag = (ev) => {
    var dt = ev.dataTransfer;
    var text = ev.target.getAttribute('data-drop-value');

    text = text.replace('+', '').trim();
    text = ' {' + text + '} ';
    dt.effectAllowed = 'copy';
    dt.setData('text', text);
  }
  onDrop = (e) => {
    e.preventDefault();
    const text = e.dataTransfer.getData('text')
    var range;

    if (!document.caretRangeFromPoint) { // IE11 doesn't support caretRangeFromPoint
      range = document.createRange();
      range.setStart(e.currentTarget, 0);
      range.setEnd(e.currentTarget, 0);
    } else {
      range = document.caretRangeFromPoint(e.clientX, e.clientY);
    }
    this.insertText(text, range);
    // TODO nice to have
    // this.setColor()
  }
  onBlur = (e) => {
    this.props.onBlur(e);
  }
  onChange = (e) => {
    const length = e.target.value.length;

    if (length <= this.props.maxLength) {
      this.setState({ charCount: length });
      this.props.onChange(e.target.value);
    }
  }
  onKeyDown = (e) => {
    const value = this.props.value;

    if (e.key === 'Backspace' || e.key === 'Delete') {
      const pos = e.target.selectionStart;
      const charToDelete = value.substr(pos - 1, 1);

      if (charToDelete === '}') {
        const openTagPos = value.substr(0, pos).lastIndexOf('{');

        if (openTagPos === -1) return; // don't remove all text to left if opening tag isn't found
        const tagRemoved = value.substr(0, openTagPos) + value.substr(pos);

        this.props.onChange(tagRemoved);
        const self = this;

        setTimeout(() => {
          self.refs.textarea.selectionStart = self.refs.textarea.selectionEnd = openTagPos;
        }, 0);
      }
    }
  }
  getTags = () => {
    return this.props.tags.map((tag, index) => {
      return (
        <a key={index}
          className={cx('draggable-placeholder', style.tag)}
          href="javascript:void(0)"
          draggable="true"
          data-drop-value={tag}
          onClick={this.onTagClick}
          onDragStart={this.onDrag}>
          + {tag}
        </a>
      );
    });
  }
  render() {
    const { children, className, label, error, hint, value, maxLength, breakTags } = this.props;
    const { charCount } = this.state;
    const cssClass = cx('label-input block', className, {
      'has-error': typeof error === 'string' && error !== ''
    });

    return (
      <label className={cssClass}>
        <div className="label-text">{children || label}</div>
        <div className={style.container}>
          <Textarea name="chatbox"
            ref="textarea"
            placeholder={"e.g. How can I help you?"}
            className={'form-control no-border no-resize'}
            type="text"
            minRows={1}
            maxRows={12}
            value={value}
            onBlur={this.onBlur}
            onKeyDown={this.onKeyDown}
            onChange={this.onChange} />
          <div className={style.footer}>
            <div className={style.separator}></div>
            <div className={style.innerFooter}>
              <span className={cx(style.label, { 'block': breakTags })}>Dynamic text</span>
              {this.getTags()}
            </div>

            <span className={style.limit}>{charCount}/{maxLength}</span>

          </div>
        </div>
        {error ? <p className="small text-danger m-b-0">{error}</p> : null}
        {hint ? <p className="hint">{hint}</p> : null}
      </label>
    );
  }

}

LabelTextTags.propTypes = {
  children: PropTypes.node,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  hint: PropTypes.string,
  breakTags: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  maxLength: PropTypes.number,
  tags: PropTypes.array
};

LabelTextTags.defaultProps = {
  className: '',
  error: '',
  value: '',
  breakTags: false
};

export default  LabelTextTags;
