import styled from 'styled-components';

export const PypeLogo = styled.div`
  cursor: ${props => props.isClickable ? 'pointer' : 'default'};
  width: 48px;
  height: 48px;
  border-radius: 24px;
  border: 2px solid #fff;
  overflow: hidden;
  box-shadow: 0 0 0 2px #2e7de1;
  margin: 0 auto;
  img {
    width: 100%;
    height: 100%;
  }
`;

export const PopoverBackdrop = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;