import _ from 'lodash'

import React from 'react'
import PropTypes from 'prop-types'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import cx from 'classnames';
import {Column, Cell} from 'fixed-data-table-2';

import sdk from 'sdk';
import StatsWrapper from './StatsWrapper';
import {numberWithCommas} from 'utils/format_number';
import {truncate} from 'utils/string';
import {formatTimestamp} from 'utils/format_time';
import TableResponsive from 'components/ui/TableResponsive';

import css from './StreamsTabPanel.css';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';


const {streamActions} = sdk;


const ROW_HEIGHT = 46;
const HEADER_HEIGHT = 25;

const STATS_KEYS = {
  "daily": ['daily_subscription_count', 'daily_session_count', 'daily_avg_resp_time'],
  "historical": ['subscription_count', 'session_count', 'avg_resp_time']
}

const getSortedStreams = (streams) => {
  return _.chain(streams)
  .filter((stream) => (stream.status === 'active'))
  .sortBy((stream) => (stream.stream_name.toLowerCase()))
  .value()
}
class StreamsTabPanel extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      filter: 'daily'
    };

  }
  componentWillMount() {
    this.props.fetchStreamsIfNeeded(true)
  }
  toggleStats(filter){
    this.setState({
      filter
    })
  }

  getStreams = () => {
    const { streams = [], widgets, flags } = this.props;
    if (flags.pe17180) {
      return getSortedStreams(streams.filter(stream => _.some(widgets, {stream_id: stream.id})))
    } else {
      return getSortedStreams(streams)
    }
  }

  render(){
    const {props, state} = this;
    const {isFetchingStreams, shouldSowReplyTimes} = props;
    const {filter} = state;
    const sortedStreams = this.getStreams()
    const totalChats = _.reduce(sortedStreams, (sum,stream) => (sum + stream.stats[STATS_KEYS[filter][1]]), 0);
    const totalWeightedCount = _.reduce(sortedStreams, (sum,stream) => (
      sum + stream.stats[STATS_KEYS[filter][1]] * stream.stats[STATS_KEYS[filter][2]]
      ), 0)
    const totalTimeAvg = totalChats ? totalWeightedCount / totalChats : 0;
    const tableHeightWithScroll = sortedStreams.length < 10 ? sortedStreams.length * ROW_HEIGHT + 75 : ROW_HEIGHT*10 + 75;

    // NOTE: ResponsiveFixedDataTable requires that we specify height in wrapper as well.
    // Else it gets 1px height.
		return (
			<StatsWrapper isLoading={isFetchingStreams} border={false}>
        <div className={css.tabContent}>
          <div className={css.filters}>
	          <a className={cx("btn btn-link no-padding m-t-5", {"disabled": filter === 'daily'})} onClick={this.toggleStats.bind(this,'daily')}>
	            <span className="">Daily</span>
	          </a>
            <a className={"btn btn-link no-padding m-l-5 m-t-5 disabled"}>
              <span>-</span>
            </a>
	          <a className={cx("btn btn-link no-padding m-l-5 m-t-5", {"disabled": filter === 'historical'})} onClick={this.toggleStats.bind(this,'historical')}>
	            <span className="">All Time</span>
	          </a>
          </div>
          <div style={{height:tableHeightWithScroll}}>
            <TableResponsive
              rowHeight={ROW_HEIGHT}
              rowsCount={sortedStreams.length}
              height={tableHeightWithScroll}
              headerHeight={HEADER_HEIGHT}
              footerHeight={ROW_HEIGHT}>
              <Column
                header={<Cell>Name</Cell>}
                cell={({rowIndex, ...props}) => {
                  const {stream_name} = sortedStreams[rowIndex];
                  return <Cell {...props}>
                    <span>{truncate(stream_name,25)}</span>
                  </Cell>
                }}
                footer={<Cell>ALL {this.props.flags.pe17180 ? 'CHANNELS' : 'STREAMS'}</Cell>}
                width={200}
                flexGrow={1}
              />
              <Column
                align="right"
                header={<Cell>Users</Cell>}
                cell={({rowIndex, ...props}) => {
                  const {stats} = sortedStreams[rowIndex];
                  const {daily_subscription_count,daily_unsubscription_count,subscription_count} = stats;
                  const count = (filter === 'daily') ? daily_subscription_count - daily_unsubscription_count :
                  subscription_count;
                  return <Cell {...props}>
                    <span>{numberWithCommas(count)}</span>
                  </Cell>
                }}
                footer={<Cell>-</Cell>}
                flexGrow={1}
                width={120}
              />
              {
                shouldSowReplyTimes ?
                <Column
                  align="right"
                  header={<Cell>Reply Time</Cell>}
                  cell={({rowIndex, ...props}) => {
                    const {stats} = sortedStreams[rowIndex];
                    const replyTime = stats[STATS_KEYS[filter][2]];
                    const replyTimeFormatted = formatTimestamp(replyTime);
                    return <Cell {...props}>
                      <span className={replyTimeFormatted.css}>{replyTimeFormatted.value}</span>
                    </Cell>
                  }}
                  footer={(props) => {
                    const totalTimeAvgFormatted = formatTimestamp(totalTimeAvg);
                    return <Cell {...props}><span className={totalTimeAvgFormatted.css}>{totalTimeAvgFormatted.value}</span></Cell>
                  }}
                  flexGrow={1}
                  width={150}
                /> : null
              }
              <Column
                align="right"
                header={<Cell>Chats</Cell>}
                cell={({rowIndex, ...props}) => {
                  const {stats} = sortedStreams[rowIndex];
                    return <Cell {...props}>
                      <span>{numberWithCommas(stats[STATS_KEYS[filter][1]])}</span>
                    </Cell>
                }}
                footer={<Cell>{numberWithCommas(totalChats)}</Cell>}
                flexGrow={1}
                width={120}
              />
            </TableResponsive>
          </div>
        </div>
      </StatsWrapper>
			);
	}
}

StreamsTabPanel.propTypes = {
  streams: PropTypes.array.isRequired,
  shouldSowReplyTimes: PropTypes.bool
}

StreamsTabPanel.defaultProps = {
  shouldSowReplyTimes: true
}

const mapStateToProps = (state) => ({
  isFetchingStreams: state.streamsForPype.isFetching,
  streams: state.streamsForPype.streams,
  widgets: state.widgets.store
})

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({
    fetchStreamsIfNeeded: streamActions.fetchStreamsIfNeeded,
  }, dispatch)
)

export default  connect(
  mapStateToProps,
  mapDispatchToProps
)(withLDConsumer()(StreamsTabPanel));
