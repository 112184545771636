import React from 'react';

const Component = () => (
  <div className="full-height full-width">
    <div className="pane-header"></div>
    <div className="empty-pane">
        <h3>No broadcast selected</h3>
        <p>Select a broadcast from the list on the left.</p>
    </div>
  </div>
)

export default Component;