import styled from 'styled-components';

export const Row = styled.div`
    display: flex;
    margin-bottom: 20px;
    align-items: center;
`;

export const Label = styled.div`
    width: 90px;
    font-size: 13px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #797979;
    text-transform: uppercase;
`;

export const Value = styled.div`
    flex: 1;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    max-width: 202px;
`;

export const Spacer = styled.div`
    height: 30px;
`
