const POSITION_OPTIONS = [
  {value: 'top-left', label: 'Top left'},
  {value: 'top-right', label: 'Top right'},
  {value: 'bottom-left', label: 'Bottom left'},
  {value: 'bottom-right', label: 'Bottom right'},
]

const TEXT_COLOR_OPTIONS = [
  {value: '#ffffff', label: '#ffffff'},
  {value: '#134373', label: '#134373'},
  {value: '#000000', label: '#000000'},
]

export default  {
  POSITION_OPTIONS,
  TEXT_COLOR_OPTIONS,
}
