const flags = {
    isBeta: function(){
      // enable beta features by default. user can turn off manually 
      return localStorage.getItem('PYPESTREAM_WPM_IS_BETA') !== 'false'
    },
    isAgentGroupMetricsEnabled: function() {
      return localStorage.getItem('PYPESTREAM_AGENT_GROUP_METRICS_ENABLED') !== 'false'
    }
  };
  
  export default flags;
  