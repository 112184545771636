import React from 'react'
import {connect} from 'react-redux';
import _ from 'lodash';
import { agentActions } from 'sdk/actions';
import utils from 'sdk/utils';
import AppTooltip from 'components/shared/AppTooltip';

class AgentAway extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      isOffHours: this.props.isOffHours
    }
  }

  componentWillReceiveProps(nextProps){
    const {isOffHours} = nextProps;
    if(isOffHours != this.props.isOffHours){
      this.setState({isOffHours: isOffHours})
    }
  }

  handleClick = (event) => {
    event.preventDefault()
    this.props.onSetStatus(this.props.isMaxed)
  }

  render () {
    const linkLabel = "Change status back to online";
    const {isOffHours} = this.state;
    return (
      <div className="agent-status-card agent-away-notification">
        <div className="inner-content">
          <h3>You are currently marked as away.</h3>
          <p>
            You will not receive any new chats in your queue until your
            status is changed to online.
          </p>
          {isOffHours ? 
            <AppTooltip tooltipKey="agent.status" place="bottom">
              <a onClick={_.noop}>{linkLabel}</a>
            </AppTooltip> : 
            <a onClick={this.handleClick}>
              {linkLabel}
            </a>
          }
        </div>
      </div>
    )
  }

}

const mapStateToProps = (state) => {
  return {
    isOffHours: state.agent.isOffHours,
    isMaxed: utils.agent.isMaxed(state.agent)
  }
}

const mapDispatchToProps = (dispatch) => ({
  onSetStatus(isMaxed) {
    if (isMaxed) {
      dispatch(agentActions.setStatus("busy"));
    } else {
      dispatch(agentActions.setStatus("online"));
    }
  },
});

export default  connect(
  mapStateToProps,
  mapDispatchToProps
)(AgentAway)
