import SDK from '../SDK'
import {
  REQUEST_AGENTS, RECEIVE_AGENTS, RECEIVE_AGENTS_ACK,
  UPDATE_AGENT,
  CREATED_AGENT, REMOVED_AGENT, UPDATED_AGENT,
  UPDATE_AGENT_STATUS
} from "../constants"

// get all agents of pype
function requestAgents() {
  return {
    type: REQUEST_AGENTS
  }
}

function receiveAck() {
  return {
    type: RECEIVE_AGENTS_ACK
  }
}

export function updatedAgent(agent) {
  return {
    type: UPDATED_AGENT,
    agent
  }
}


export function receiveAgents(req_id, json) {
  return {
    req_id,
    type: RECEIVE_AGENTS,
    agents: json
  }
}

export const fetchAgents = (skills) => (dispatch, getState) => {
  let data = {
    pype_id: getState().pype.details.id,
  }
  if(skills && skills.length) {
    data.skills = skills
  }
  SDK.getAgents(data)
  
  return dispatch(requestAgents())
}

export const fetchAvailableAgents = (skills) => async (dispatch, getState) => {
  let data = {
    pype_id: getState().pype.details.id,
  }
  if(skills && skills.length) {
    data.skills = skills
  }
  await SDK.getAgents(data)
  
  return dispatch(requestAgents())
}

export const createdOrUpdatedAgent = (req_id, agent) => (dispatch, getState) => {
  SDK.getAgents({
      pype_id: getState().pype.details.id
  })

  return {
    req_id,
    type: CREATED_AGENT,
    agent
  }
}


export function updateAgent(agentData, agentOrUserAPI) {
  return (dispatch) => {

    var id = agentData.id;
    //delete agentID as we don't want to update it
    //commented out for now, until PYPE-2705 is fixed
    //https://pypestream.atlassian.net/browse/PYPE-2705
    //delete agentData.id;

    var newData = {
      entity_type: 'business_user',
      entity_id: id,
      data: agentData
    };

    SDK.updateAgent(newData).then(() => {
      dispatch(receiveAck())
      dispatch(updatedAgent(agentData))
    });

    dispatch({ type: UPDATE_AGENT });
  }
}


export function removedAgent(agentId) {
  return {
    type: REMOVED_AGENT,
    agentId,
  }
}

export function updateAgentStatus(data) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_AGENT_STATUS,
      agentId: data.agent_id,
      status: data.status
    });
  }
}
