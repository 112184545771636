/*
  <TODO>
  - Sort tags in dropdown and tag list first by code, then description
*/

// Basic externals
import _ from 'lodash';
import assign  from 'object-assign';

// React, redux
import React from 'react'
import PropTypes from 'prop-types'

import cx from 'classnames';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom'

// UI
import Modal from 'react-modal';
import Select from 'react-select';
import IconButton from '../ui/forms/IconButton';
import SaveButton from '../ui/forms/SaveButton';
import Spinner from '../ui/pype-spinner/PypeSpinner';

// Pypestream
import sdk from 'sdk';
const {tagActions, conversationsActions} = sdk;

const getTagsSortedByCode = (tagsStore = []) => {
  return _.sortBy(_.values(tagsStore), 'code')
}
const validators = {

  comment(value, more) {
    if (!more.commentEnabled)
      return
    if (more.commentRequired && (_.isNil(value) || value.trim() === ''))
      return 'A comment is required.'
  },

  tags(tagList, more) {
    // We don't show an error messages for tags dropdown but the Save
    // button still depends on its validation, hence returning false
    // instead of an error string.
    if (!more.tagsEnabled)
      return
    if (tagList.length === 0 && more.tagsRequired)
      return false
    if (tagList.length === 0 && !more.commentEnabled)
      return false
  },

}

const TagItem = (props) => (
  <div className="tag-item">
    <span>{props.children}</span>
    <IconButton src={`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-modal-close.svg`} onClick={props.onClickRemove} />
  </div>
)

TagItem.propTypes = {
  onClickRemove: PropTypes.func.isRequired,
}

const TagList = (props) => (
  <div className="tag-list">
    {props.tags.map((tag) => (
      <TagItem key={tag.id} onClickRemove={() => props.onClickRemove(tag.id)}>
        {(props.tagShortcutsEnabled && !_.isNil(tag.code)) ? tag.code + ' - ' : ''} {tag.description}
      </TagItem>
    ))}
  </div>
)

TagList.propTypes = {
  tags: PropTypes.array.isRequired,
  tagShortcutsEnabled: PropTypes.bool.isRequired,
  onClickRemove: PropTypes.func.isRequired,
}

class PostChatModal extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      comment: '',
      tag: null,    // The id of selected tag in the dropdown
      tagList: [],  // The tags which have been chosen
      errors: {
        comment: undefined,
        tags: undefined,
      },
      isSubmitting: false,
      chatIdForClassification: props.endedChatForClassification ? props.endedChatForClassification : props.chat_id
    }
  }

  componentDidMount() {
    this.props.onMount()
  }

  continueWithoutLog= () => {
    // Platform requires client to always send a classify chat
    // request even when comments and tags are disabled.
    this.props.onSubmit({
      chat_id: this.state.chatIdForClassification,
      user_cancel: true,
    })
    this.props.afterChatClassification()
    this.props.closeModal(this.state.chatIdForClassification)
  }

  handleClose() {
    return
  }

  bindValidator = (prop, more={}) => {
    return () => {
      const errorMessage = validators[prop](this.state[prop], more)
      const errors = assign({}, this.state.errors)
      if (errorMessage !== undefined)
        errors[prop] = errorMessage
      else
        delete errors[prop]
      this.setState({errors})
    }
  }

  isFormValid = () => {
    const {comment, tagList} = this.state
    const more = {
      commentEnabled: this.props.commentEnabled,
      commentRequired: this.props.commentRequired,
      tagsEnabled: this.props.tagsEnabled,
      tagsRequired: this.props.tagsRequired,
      allowMultipleTags: this.props.allowMultipleTags
    }
    let isValid = validators.comment(comment, more) === undefined
      && validators.tags(tagList, more) === undefined
    return isValid
  }

  isEmpty = () => {
    const {props, state} = this
    if (props.commentEnabled) {
      if (state.comment.trim() !== '') {
        return false
      }
    }
    if (props.tagsEnabled) {
      if (!_.isEmpty(state.tagList)) {
        return false
      }
    }
    return true
  }

  canSkipLog = () => {
    const {props} = this
    let canSkip = true
    if (props.commentEnabled && props.commentRequired)
      canSkip = false
    if (props.tagsEnabled && props.tagsRequired)
      canSkip = false
    return canSkip
  }

  handleSubmit = () => {
    const {props, state} = this

    let data = {chat_id: state.chatIdForClassification}
    const comment = state.comment.trim();
    if (props.commentEnabled && !!comment.length)
      data.end_comment = comment

    if (props.tagsEnabled)
      data.end_tags = state.tagList

    const onSuccess = () => {
      window.appCreateToast({
        message: 'Chat status log created successfully.',
        level: 'success',
      })
      props.afterChatClassification()
      props.closeModal(state.chatIdForClassification)
    }

    const onError = (error) => {
      console.error(error)
      window.appCreateToast({
        message: 'Chat status log could not be created.',
        level: 'error',
      })
      props.afterChatClassification()
      this.setState({isSubmitting: false}, () => {
        props.closeModal(state.chatIdForClassification)
      })
    }

    this.setState({isSubmitting: true})

    return props.onSubmit(data).then(onSuccess, onError)
  }

  handleCommentChange = (event) => {
    const comment = event.target.value
    this.setState({comment})
  }

  handleSelectChange = (tagObject) => {
    const { tagList } = this.state;
    const alreadyInList = _.some(tagList, id => id === tagObject.value);

    this.setState({ tag: tagObject.value });

    if (!_.isNil(tagObject) && !alreadyInList) {
      if (this.props.allowMultipleTags) {
        this.setState({ tagList: tagList.concat(tagObject.value).sort() });
      } else {
        this.setState({ tagList: [tagObject.value] });
      }
    }
  }

  handleRemoveTag = (tagId) => {
    this.setState({
      tagList: _.without(this.state.tagList, tagId)
    })
  }

  renderOptions() {
    const {props, state} = this
    const tags = getTagsSortedByCode(props.tagsStore)
    let tagsLeft = tags
    // Remove tags from dropdown as they're selected
    if (props.allowMultipleTags) {
      tagsLeft = tags.filter((tag) => (
        !_.includes(state.tagList, tag.id)
      ))
    }
    return tagsLeft.map((tag) => {
      const {tagShortcutsEnabled} = props
      const label = (tagShortcutsEnabled && !_.isNil(tag.code)) ? tag.code + ' - ' : ''
      return {
        value: tag.id,
        label: label + tag.description,
      }
    })
  }

  mapIdToTag = (tagId) => {
    const tags = getTagsSortedByCode(this.props.tagsStore)
    return _.find(tags, (tag) => tag.id === tagId)
  }

  render() {

    const {props, state} = this
    const {comment, tagList} = this.state

    const formSections = []
    if (props.tagsEnabled) {
      formSections.push('tags')
    }
    if (props.commentEnabled) {
      formSections.push('comment')
    }
    if (props.commentRequired && !props.tagsRequired) {
      formSections.reverse()
    }

    if (props.isFetching) {
      return (
        <Modal ariaHideApp={false} className="post-chat-modal" isOpen={true} onRequestClose={this.handleClose} shouldCloseOnOverlayClick={false}>
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title"><b>Post chat status log</b></h4>
            </div>
            <div className="modal-body" style={{textAlign: 'center'}}>
              <Spinner caption={false} />
            </div>
          </div>
        </Modal>
      )
    }

    return (
      <Modal ariaHideApp={false} className="post-chat-modal" isOpen={true} onRequestClose={this.handleClose} shouldCloseOnOverlayClick={false}>
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title"><b>Post chat status log</b></h4>
          </div>
          <div className="modal-body">
            <section>
              <p>Please fill in the field(s) below to classify information in your chat history.</p>
            </section>
            {formSections.map((formSection) => {
              if (formSection === 'tags') {
                return (
                  <section key="tags" className="disposition-codes">
                    <h5 className="heading-light-cap inline">{props.categoryName} </h5>
                    <span className="required-or-not">
                      ({props.tagsRequired ? 'Required' : 'Optional'})
                    </span>
                    {(props.tagsEnabled && props.allowMultipleTags) ?
                      <TagList tags={tagList.map(this.mapIdToTag)} tagShortcutsEnabled={props.tagShortcutsEnabled}
                        onClickRemove={this.handleRemoveTag} /> : null}
                    <Select
                      className={cx('ui-dropdown', {'is-required': props.tagsRequired})}
                      value={this.renderOptions().filter(({value}) => state.tag === value)}
                      options={this.renderOptions()}
                      placeholder={"Select " + props.categoryName}
                      clearable={false}
                      onChange={this.handleSelectChange} />
                  </section>
                )
              } else if (formSection === 'comment') {
                return (
                  <section key="comment">
                    <h5 className="heading-light-cap inline">COMMENT </h5>
                    <span className="required-or-not">
                      ({props.commentRequired ? 'Required' : 'Optional'})
                    </span>
                    <div className={state.errors.comment !== undefined ? 'has-error' : ''}>
                      <textarea
                        className={cx('m-t-5', 'form-control', {'is-required': props.commentRequired})}
                        placeholder="Leave your comment here..."
                        value={comment}
                        onChange={this.handleCommentChange}
                        onBlur={this.bindValidator('comment')} />
                      <p className="text-danger small">{state.errors.comment}</p>
                    </div>
                  </section>
                )
              }
            })}
          </div>
          <div className="modal-footer">
            {this.canSkipLog() ?
              <button className="btn btn-link" onClick={this.continueWithoutLog}>Continue without log</button> : null}
            <SaveButton className="btn-primary" isLoading={this.state.isSubmitting} disabled={!this.isFormValid() || this.isEmpty()} onClick={this.handleSubmit}>
              Confirm
            </SaveButton>
          </div>
        </div>
      </Modal>
    )

  }

}

PostChatModal.propTypes = {

  chat_id: PropTypes.string.isRequired,

  // All the tags created by the admin
  tagsStore: PropTypes.array.isRequired,

  // Comment config
  commentEnabled: PropTypes.bool.isRequired,
  commentRequired: PropTypes.bool.isRequired,

  // Tags config
  tagsEnabled: PropTypes.bool.isRequired,
  tagsRequired: PropTypes.bool.isRequired,
  allowMultipleTags: PropTypes.bool.isRequired,
  tagShortcutsEnabled: PropTypes.bool.isRequired,

  // Callbacks
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,

}

PostChatModal.defaultProps = {
  tagsStore: [],

  // Comment config
  commentEnabled: false,
  commentRequired: false,

  // Tags config
  tagsEnabled: false,
  tagsRequired: false,
  allowMultipleTags: false,
  tagShortcutsEnabled: false,
}

const mapStateToProps = (state) => {

  const {details} = state.pype

  return {
    isFetching: state.tags.isFetching,
    tagsStore: state.tags.store,
    categoryName: state.tags.category.name,
    commentEnabled: details.end_comment_display,
    commentRequired: details.end_comment_required,
    tagsEnabled: details.end_tags_display,
    tagsRequired: details.end_tags_required,
    allowMultipleTags: details.tags_multiple,
    tagShortcutsEnabled: details.tags_shortcuts,
    endedChatForClassification: state.allConversations.endedChatForClassification
  }

}

const mapDispatchToProps = (dispatch, props) => ({

  onMount() {
    dispatch(tagActions.fetchTags())
  },

  onSubmit(data) {
    return dispatch(conversationsActions.classifyChat(data))
  },

  afterChatClassification() {
    return dispatch(conversationsActions.recentlyEndedChatForClassification(null))
  },

})

export default  withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PostChatModal))
