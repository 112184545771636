import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Local imports
import style from './style.css';

const EmptyState = () => (
  <div className={style.wrapper}>
    <div className={style.inner}>
      <FontAwesomeIcon icon="exclamation" size="3x" />
      <h4 className="m-b-5 fs-17">No results found</h4>
      <p>Please search for another name.</p>
    </div>
  </div>
);

export default  EmptyState;
