import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames';

/*
  Currently just a wrapper, but could be useful if we ever need to
  make changes that should affect all detail panes.
*/
const DetailPane = (props) => (
  <div className={cx("full-height", props.className)}>
    {props.children}
  </div>
)

DetailPane.propTypes = {
  className: PropTypes.string,
}

export default  DetailPane
