import style from './style.css';
import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal';
import SaveButton from 'components/ui/forms/SaveButton';

const DeleteWarningModal = (props) => (
  <Modal ariaHideApp={false} contentLabel='Modal' className={style.DeleteWarningModal} isOpen={true} onRequestClose={props.closeModal}>
    <div className={`modal-content ${style.ModalContent}`}>
      <div className={`modal-header ${style.ModalHeader}`}>
        Delete Rule
      </div>
      <div className={`modal-body ${style.ModalBody}`}>
        <div className={style.Info}>
          {`Are you sure you want to delete the ${props.ruleName} Rule? `}
          It will be deleted immediately and permanently and will affect current conversations taking place.
        </div>
        <div className={style.Footer}>
          <button className={style.CloseButton} onClick={props.closeModal}>
            Cancel
          </button>
          <SaveButton
            className={`btn-danger ${style.SaveButton}`}
            onClick={props.onSubmit}
            isLoading={props.isSubmitting}>
            Delete
          </SaveButton>
        </div>
      </div>
    </div>
  </Modal>
)

DeleteWarningModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default  DeleteWarningModal
