import {
  REQUEST_PYPES,
  RECEIVE_PYPES
} from "../constants"
import { BOT_USER_SESSION_LOGOUT } from "bot-user-session";

const getInitialState = () => ({
  hasFetched: false,
  isFetching: false,
  pypes: []
});

export default function pype(state = getInitialState(), action) {
  switch (action.type) {
    case BOT_USER_SESSION_LOGOUT: {
      return getInitialState();
    }
    case REQUEST_PYPES:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case RECEIVE_PYPES:
      return Object.assign({}, state, {
        hasFetched: true,
        isFetching: false,
        pypes: action.pypes
      })
    default:
      return state
  }
}
