/**
 * NOTE: Ability to input Salesforce Live Agent
 * URL has been temporarily commented out until
 * platform supports it
 */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {Link} from 'react-router-dom';

// UI components
import SaveButton from 'components/ui/forms/SaveButton';

// Local imports
import DetailPane from '../details/DetailPane';
import TitleBar from '../details/TitleBar';
import ContentBody from '../details/ContentBody';
import SubHeader from '../details/SubHeader';
import Footer from '../details/Footer';
import TetheredSelect from 'components/ui/tethered-select/TetheredSelect';
import ConfirmRouterSwitchModal from './ConfirmRouterSwitchModal';
import PypeManagerFeature from '../PypeManagerFeature';
import ErrorBoundary from 'components/ui/ErrorBoundary';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import style from './style.css';

import sdk from 'sdk';
import activityTracker from "../../../utils/activityTracker";

const DEFAULT_WAIT_TIME = 180;

const SETTING_NAME = "chat routing";
const ROUTING_SOFTWARE_LIST = [
  {value: 'pypestream', label: "Pype Manager"},
  {value: 'salesforce', label: "Salesforce Live Agent"},
  {value: 'freshchat', label: "Freshchat"},
  {value: 'nuance', label: "Nuance"},
];

const getWaitTimes = () => {
  const mins = [1,2,3,4,5,6,7,8,9,10,15,20,30];
  return mins.map((min)=> {
    // time in seconds
    return {value: min*60, label: min + ' min'}
  })
}
export class ChatRouting extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      routingSoftware: null, // Pype Manager enabled by default
      isSubmitting: false,
      endpoint: null,
      errors: {},
      isConfirmationModalOpen: false,
      waitTime: props.waitTime || DEFAULT_WAIT_TIME
    };

    // Binding methods
    this.handleSubmit = this.handleSubmit.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onBack = this.onBack.bind(this);
    this.confirmRouterSwitch = this.confirmRouterSwitch.bind(this);

  }

  componentWillMount() {
    const { customerName } = this.props

    this.props.fetchAgents()
    activityTracker.logEvent(activityTracker.eventTypeNames.VIEW_PYPE_SETTING,{settingName: SETTING_NAME, customerName});
  }

  componentDidMount(){
    this.setState({
      routingSoftware: this.props.routingSoftware
    })
  }

  componentWillReceiveProps(props){
    if(!props.isPypeUpdating){
      this.setState({
        routingSoftware: props.routingSoftware,
        waitTime: props.waitTime || DEFAULT_WAIT_TIME
      })
    }
  }

  closeModal() {
    this.setState({
      isConfirmationModalOpen: false
    })
  }

  onBack() {
    this.setState({
      isConfirmationModalOpen: false
    })
  }

  confirmRouterSwitch() {
    this.updatePype();
    this.closeModal();
  }

  updatePype(){

    const {routingSoftware, waitTime} = this.state;
    const { customerName } = this.props;

    const data = {
      agent_routing: routingSoftware,
      reroute_limit: waitTime
    };

    this.setState({isSubmitting: true});

    const onSuccess = () => {
      window.appCreateToast({
        message: "Changes saved successfully.",
        level: "success"
      });

      const softwareObj = ROUTING_SOFTWARE_LIST.find((software) => (software.value === routingSoftware))
      const software = softwareObj ? softwareObj.label : 'Pype Manager';
      const label = `Software: ${software}, Pype name: ${this.props.pypeName}, Max wait time: ${waitTime/60} min, agent_created: ${this.props.hasAgents}`;

      activityTracker.logEvent(activityTracker.eventTypeNames.UPDATE_PYPE_SETTING,{settingName: SETTING_NAME,action: activityTracker.eventActionType.UPDATE, customerName});
    }

    const onError = (error) => {
      console.log(error);
      window.appCreateToast({
        message: "Couldn't save changes.",
        level: "error"
      });
    }

    const onDone = () => {
      this.setState({isSubmitting: false});
    }

    this.props.updatePype(data).then(onSuccess, onError)
      .then(onDone)
  }

  // Event handlers

  handleSubmit() {

    if(['salesforce', 'freshchat', 'nuance'].includes(this.state.routingSoftware)){
      this.setState({isConfirmationModalOpen: true})
      return;
    }

    this.updatePype();

  }
  // TODO: nothing to validate here.
  // Will be useful when endpoint URL is available
  isFormValid() {
    return true;
  }

  hasChanges() {
    return !_.isEqual(
      this.props.routingSoftware,
      this.state.routingSoftware
    ) || !_.isEqual(
      this.props.waitTime,
      this.state.waitTime
    )
  }
  onRoutingSoftwareChange(option){
    this.setState({routingSoftware: option.value})
  }
  onWaitTimeChange(option){
    this.setState({waitTime: option.value})
  }
  selectOptionRenderer (option) {
    return (<div className="Select-checked">
			{option.label}
		</div>)
  }
  // handleEndpointURLInput(e) {
  //   var endpoint = e.target.value.trim();

  //   this.setState({
  //     endpoint,
  //     errors: _.omit(this.state.errors, 'endpoint'),
  //   });
  // }
  // isEndpointURLValid() {
  //   var isValid = false;
  //   var error = null;
  //   var { endpoint } = this.state;

  //   if (!endpoint || !Validator.isURL(endpoint)) {
  //     error = "Please enter a valid API endpoint.";
  //   } else {
  //     isValid = true;
  //   }

  //   this.setState({ errors: assign(this.state.errors, { endpoint: error }) });

  //   return isValid;
  // }
  render() {

    const {state,props} = this;
    const {routingSoftware, endpoint, waitTime} = state;
    const {isPypeDataLoading} = props;

    const isNotPypestream = routingSoftware !== 'pypestream';
    let softwareListValue = {}

    if(routingSoftware) {
      const software = ROUTING_SOFTWARE_LIST.find((software) => (software.value === routingSoftware))
      const label = software ? software.label : software

      softwareListValue = {
        value: routingSoftware,
        label
      }
    }

    let waitTimeValue = {}
    if(waitTime) {
      waitTimeValue = getWaitTimes().find((times) => (times.value === waitTime))
    }

    const activeSoftware = softwareListValue.label

    return (
      <DetailPane data-test="chat-routing" className="chat-routing has-footer">
        <ContentBody>
          <ErrorBoundary>
            {props.flags && props.flags.brandInfo && props.flags.brandInfo.showRouting &&
              <section style={{paddingTop: 0}}>
                <SubHeader style={{marginTop: '0'}}>Routing software</SubHeader>
                <section>
                  <p>
                    Select the system receiving incoming chats for agent chat management.
                    If you select a third-party software, <a href="mailto:cx@pypestream.com">contact us</a> to link up your account.
                  </p>
                  <p className={"label-text m-t-20 m-b-5"}>SOFTWARE</p>
                  <TetheredSelect
                    className={"software-list"}
                    data-test="chat-routing-software"
                    name="software"
                    value={softwareListValue}
                    options={ROUTING_SOFTWARE_LIST}
                    searchable={false}
                    clearable={false}
                    placeholder="Loading..."
                    disabled={isPypeDataLoading}
                    optionRenderer={this.selectOptionRenderer}
                    onChange={this.onRoutingSoftwareChange.bind(this)}/>

                  { isNotPypestream ?
                    <div className="alert alert-warning m-t-10" data-test="is-not-pypestream">
                      <p>
                        <strong>Note: </strong> By selecting {activeSoftware}, you will lose access to Pypestream agents, CRM data, end chat actions, tag management and reporting capabilities.
                      </p>
                    </div> : null }
                </section>
              </section>
            }
            <PypeManagerFeature>
              <section>
                <SubHeader>Skill-based routing (advanced)</SubHeader>
                <section>
                  <div>
                    <span className="fs-13">
                      Route chats to the general queue if the wait time is
                    </span>
                    <TetheredSelect
                      className={style.waitTimeDropdown}
                      name="wait-time"
                      value={waitTimeValue}
                      options={getWaitTimes()}
                      searchable={false}
                      clearable={false}
                      placeholder="Loading..."
                      disabled={isPypeDataLoading}
                      optionRenderer={this.selectOptionRenderer}
                      onChange={this.onWaitTimeChange.bind(this)}
                    />
                    <span className="fs-13">
                      {" "}
                      shorter than the skill queue.{" "}
                      <Link to="skills">Click here</Link> to manage skills.
                    </span>
                  </div>
                </section>
              </section>
            </PypeManagerFeature>
          </ErrorBoundary>
        </ContentBody>
        <Footer>
          <SaveButton
            data-test="save-button"
            className="btn-primary"
            disabled={false}
            isLoading={this.state.isSubmitting}
            disabled={!this.isFormValid() || !this.hasChanges()}
            onClick={this.handleSubmit}
          >
            Save changes
          </SaveButton>
        </Footer>
        {this.state.isConfirmationModalOpen ? (
          <ConfirmRouterSwitchModal
            data-test="confirmation-modal"
            software={activeSoftware}
            closeModal={this.closeModal}
            onBack={this.onBack}
            onConfirm={this.confirmRouterSwitch}
          />
        ) : null}
      </DetailPane>
    );
  }
}

ChatRouting.propTypes = {
  routingSoftware: PropTypes.string
}

ChatRouting.defaultProps = {
  routingSoftware: null
}

export default (withLDConsumer()(ChatRouting));
