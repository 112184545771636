import React from "react";
import Modal from 'react-modal';
import Time from "react-time";
import ErrorBoundary from 'components/ui/ErrorBoundary';

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    overflow              : 'visible'
  }
};

class Component extends React.Component{
  state = {
      name: '',
      isUpdating: false,
      stream: null
  }
  componentWillReceiveProps(nextProps){
    this.setState({
      stream : nextProps.stream || null,
      isUpdating: false
    })
  }
  closeModal = () => {
    this.props.closeModal();
  }
  handleNameChange(e){
    var name = e.target.value;
    this.setState({
      name: name
    })
  }
  handleSubmit(event) {
    event.preventDefault();
    this.setState({isUpdating: true});

    var self = this;
    var name = this.state.name;
    var id = this.state.stream.id;
    this.props.onUpdateStream(id, {
      stream_name: name
    });
  }
  callbackUpdateStatus(data) {
    this.setState({isUpdating: false});
    if(data.error) {
      alert(data.error);
    } else {
      this.state.stream.stream.is_active = data.is_active;
      this.forceUpdate();
    }
  }
  render(){
    var stream = this.state.stream;
    var userCount, msgCount,campaignCount, createdAt = "", isActive, name = null;
    if(stream){
      userCount = stream.stats && stream.stats.subscription_count || 0;
      msgCount = stream.stats && stream.stats.msg_count || 0;
      campaignCount = stream.stats && stream.stats.campaign_count || 0;
      createdAt = stream.creation_date;
      name = stream.stream_name;
      isActive = this.state.stream.status == 'active';
    }
    return (
      <Modal
        isOpen={this.props.modalIsOpen}
        onRequestClose={this.closeModal}
        style={customStyles} >

        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" onClick={this.closeModal}>
              <img src={`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-modal-close.svg`} width="15" height="15" />
            </button>
            <h4 className="modal-title fw-300">Stream info</h4>
          </div>
          <div className="modal-body">
            <ErrorBoundary>
              <h5 className="heading-light-cap">Stream name</h5>
              <span>{name}</span>
              <h5 className="heading-light-cap p-t-10">Details</h5>
              <table className="table info">
                <tbody>
                  <tr>
                    <td><label>Users</label></td>
                    <td>{userCount}</td>
                  </tr>
                  <tr>
                    <td><label>Messages</label></td>
                    <td>{msgCount}</td>
                  </tr>
                  <tr>
                    <td><label>Broadcasts</label></td>
                    <td>{campaignCount}</td>
                  </tr>
                  <tr>
                    <td><label>Created on</label></td>
                    <td><Time value={createdAt} format="MMM DD, YYYY h:mm a"/></td>
                  </tr>
                </tbody>
              </table>
            </ErrorBoundary>
          </div>
        </div>
      </Modal>)
  }
};

export default Component;