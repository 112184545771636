/*
  Top level element that contains profile data and crm data.
*/
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import cx from "classnames";
import { bindActionCreators } from "redux";
import { withLDConsumer } from "launchdarkly-react-client-sdk";

import sdk from "sdk";

import UserInfoTab from "./UserInfoTab";
import EventsTab from "./EventsTab";
import ResponsesTab from "./ResponsesTab";
import Spinner from "components/ui/pype-spinner/PypeSpinner";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";
import { Tabs, Tab, Grid, Box, IconButton, Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { makeStyles } from "@material-ui/core";

const { usersActions, agentActions } = sdk;

const Mode = {
  INFO: "USER_INFO",
  EVENTS: "EVENTS",
  RESPONSES: "RESPONSES",
};

const useStyles = makeStyles({
  closeButtonStyle: {
    width: "25px",
    height: "25px",
    color: "#0a3ab4",
    margin: "auto 8px",
    minWidth: "25px",
    "&:hover": {
      background: "#cbdffb",
    },
  },
  tabsStyle: {
    boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.1), 0 0 6px 0 rgba(0, 0, 0, 0.1)",
  },
  tabStyle: {
    minWidth: "11.2rem",
  },
});

const UserInfo = (props) => {
  const initialTab = props.flags.useInfoTabAsDefault
    ? Mode.INFO
    : Mode.RESPONSES;
  const [mode, setMode] = useState(initialTab);
  const classes = useStyles();

  useEffect(() => {
    document.body.classList.add("user-info-is-open");
    props.fetchUserMeta(props.consumerId);
    if (
      props.currentTab !== "active" ||
      (!props.isCannedResponsesFetching && props.cannedResponses.length < 1)
    ) {
      setMode(Mode.INFO);
    }
    return () => {
      document.body.classList.remove("user-info-is-open");
    };
  }, []);

  useEffect(() => {
    props.fetchUserMeta(props.consumerId);
  }, [props.consumerId]);

  useEffect(() => {
    if (props.currentTab === "active" && props.cannedResponses.length > 0)
      setMode(Mode.RESPONSES);
  }, [props.isCannedResponsesFetching]);

  useEffect(() => {
    if (mode === Mode.RESPONSES && props.currentTab === "archived")
      setMode(Mode.INFO);
  }, [props.currentTab]);

  const closeUserInfoPanel = (event) => {
    event.preventDefault();
    props.toggleUserInfo(false);
    sessionStorage.setItem("userInfoIsOpen", JSON.stringify(false));
  };

  const hideEventsTab = props.flags.hideEventsTab
    ? !props.events || !props.events.length
    : false;

  useEffect(() => {
    if (mode === Mode.EVENTS && !props.events && props.flags.hideEventsTab) {
      if (props.currentTab === "active" && props.cannedResponses.length > 0) {
        setMode(Mode.RESPONSES);
      } else {
        setMode(Mode.INFO);
      }
    }
  }, [props.events, props.flags.hideEventsTab]);

  return (
    <Grid data-test="user-info-component" className="user-info-panel">
      <Grid>
        <Tabs
          data-test="tabs-component"
          className={classes.tabsStyle}
          value={mode}
          aria-label="simple tabs example"
          onChange={(event, newValue) => setMode(newValue)}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab
            value="USER_INFO"
            label="User Info"
            className={`${classes.tabStyle} user-info-panel--tab`}
          />
          {!hideEventsTab && (
            <Tab
              data-test="events-tab"
              value="EVENTS"
              label="Events"
              className={`${classes.tabStyle} user-info-panel--tab`}
            />
          )}
          {props.cannedResponses.length > 0 && props.currentTab === "active" ? (
            <Tab
              data-test="tab-canned-responses"
              value="RESPONSES"
              label="Quick Replies"
              className={`${classes.tabStyle} user-info-panel--tab`}
              currentTab={props.currentTab}
            />
          ) : null}
          {props.flags.newTooltip ? (
            <Tooltip data-test="tooltip" title="Close" placement="bottom">
              <IconButton
                className={classes.closeButtonStyle}
                onClick={closeUserInfoPanel}
              >
                {" "}
                <ClearOutlinedIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <IconButton
              data-test="icon-button"
              className={classes.closeButtonStyle}
              onClick={closeUserInfoPanel}
            >
              {" "}
              <ClearOutlinedIcon />
            </IconButton>
          )}
        </Tabs>
      </Grid>
      <Box className={cx("scrollable-content", { hidden: mode !== Mode.INFO })}>
        {props.consumer ? (
          <UserInfoTab
            data-test="user-info-tab"
            consumer={props.consumer}
            chatId={props.chatId}
            onSubmitLog={props.onSubmitLog}
          />
        ) : (
          <Spinner data-test="spinner" />
        )}
      </Box>
      {!hideEventsTab && (
        <Box
          className={cx("scrollable-content", {
            hidden: mode !== Mode.EVENTS,
          })}
        >
          {<EventsTab events={props.events} />}
        </Box>
      )}
      <Box
        className={cx("scrollable-content", {
          hidden: mode !== Mode.RESPONSES,
        })}
      >
        {/* {<EventsTab events={props.events} />} */}
        <ResponsesTab
          data-test="responses-tab"
          onSelect={props.onSelect}
          handleSendEmbed={props.handleSendEmbed}
          currentEmbed={props.sendEmbed}
          cannedResponses={props.cannedResponses}
        />
      </Box>
    </Grid>
  );
};

UserInfo.propTypes = {
  consumerId: PropTypes.string.isRequired,
  onSubmitLog: PropTypes.func.isRequired,
  events: PropTypes.array,
};

UserInfo.defaultProps = {
  events: [],
};

const mapStateToProps = (state, props) => ({
  consumer: state.users.userMeta,
  isCannedResponsesFetching: state.cannedResponses.isFetching,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchUserMeta: usersActions.fetchUserMeta,
      toggleUserInfo: agentActions.toggleUserInfo,
    },
    dispatch
  );
};

const UserInfoWithFeatureFlags = withLDConsumer()(UserInfo);

const connectComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserInfoWithFeatureFlags);

export default withStyles()(connectComponent);
export { UserInfo };
