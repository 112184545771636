import { BOT_USER_SESSION_LOGOUT } from "bot-user-session";
import { 
  RECEIVED_ACK
} from "../constants"

const getInitialState = () => ({
  ack: {}
});

export default function response(state = getInitialState(), action) {
  switch (action.type) {
    case BOT_USER_SESSION_LOGOUT: {
      return getInitialState();
    }
    case RECEIVED_ACK:
      return Object.assign({}, state, {
        ack: action.ack
      })
    default:
      return state
  }
}