import style from './style.css';
import React from 'react'
import PropTypes from 'prop-types'

import Modal from 'react-modal';
import installScript from './installScript';
import CopyButton from './CopyButton';
import FileSaver from 'file-saver';
import { getWCWConfig } from "sdk/Config";

class ScriptModal extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      isSubmitting: false,
    }
  }

  componentWillUnmount () {
    window.URL.revokeObjectURL(this.url)
  }

  handleClose = () => {
    if (!this.state.isSubmitting) {
      this.props.closeModal()
    }
  }

  handleCopy = () => {
    const pre = this.refs.pre
    const range = document.createRange()
    range.selectNodeContents(pre)
    const selection = window.getSelection()
    selection.removeAllRanges()
    selection.addRange(range)
  }

  handleDownload = () => {
    const outputScript = installScript.replace('<APP_ID>', this.props.widget.id)
    const blob = new Blob([outputScript], {type: 'text/plain'});
    FileSaver.saveAs(blob, "widget-script.js");
  }

  render () {
    const {props, state} = this
    const widgetName = props.widget.widget_name
    const { wcwLauncherUrl, wcwEnv, wcwDomain } = getWCWConfig();

    return (
      <Modal ariaHideApp={false} contentLabel='Modal' className={`script-modal ${style.ScriptModal}`} isOpen={true}
        onRequestClose={this.handleClose} shouldCloseOnOverlayClick={false}>
        <div className={`modal-content ${style.ModalContent}`}>
          <div className={`modal-header ${style.ModalHeader}`}>
            3. Install code{props.isNew ? '' : ` (${widgetName})`}
            <button type='button' className='close' onClick={this.handleClose}>
              <img src={`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-modal-close.svg`} width='15' height='15' />
            </button>
          </div>
          <div className={`modal-body ${style.ModalBody}`}>
            <div className={style.Heading}>Install the code yourself</div>
            <p className={style.Info} style={{marginTop: 8, marginBottom: 12}}>
              A. Paste this code right before the closing <code>{'<body>'}</code> tag of
              every page where you want your chat Web Conversational Interface to appear.
            </p>
            <p className={style.Info}>
              B. Review the <code>{'// TODO'}</code> comments and edit the code.
            </p>
            <div className={style.CodeSection}>
              <pre ref='pre' className={style.Pre}>
                {installScript.replace('<APP_ID>', props.widget.id).replace('<wcwEnv>', wcwEnv).replace('<domain>', wcwDomain).replace('<launcherUrl>', wcwLauncherUrl)}
              </pre>
              <CopyButton onClick={this.handleCopy} />
            </div>
            <div className={style.Heading}>Need help?</div>
            <p className={style.Info}>
              If you’re not sure how to install the code, download the
              code file and send it to your Development Team.
              code file and send it to your Development Team.
            </p>
            <a className={style.DownloadButton} onClick={this.handleDownload} ref='download'>Download code</a>
          </div>
          <div className={`modal-footer ${style.ModalFooter}`}>
            <button className={style.CloseButton} onClick={props.closeModal}>
              Close
            </button>
          </div>
        </div>
      </Modal>
    )
  }

}

ScriptModal.propTypes = {
  widget: PropTypes.object,
  id: PropTypes.string,
  isNew: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
}

ScriptModal.defaultProps = {
  isNew: false,
  onBack () {},
}

export default  ScriptModal
