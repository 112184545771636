import _ from 'lodash'
import SDK from '../SDK'
import { createdChat } from './conversationHistory'
import {
  RESET_AGENT_CHAT_SESSIONS,
  RESET_ARCHIVED_CHAT_SESSIONS,
  RESET_ESCALATED_CHAT_SESSIONS,
  REQUEST_AGENT_CHAT_SESSIONS,
  RECEIVE_AGENT_CHAT_SESSIONS,
  REQUEST_ARCHIVED_CHAT_SESSIONS,
  RECEIVE_ARCHIVED_CHAT_SESSIONS,
  REQUEST_ESCALATED_CHAT_SESSIONS,
  RECEIVE_ESCALATED_CHAT_SESSIONS,
  END_AGENT_CHAT_SESSION,
  ENDED_AGENT_CHAT_SESSION,
  ADD_AGENT_CHAT_SESSION,
  RECEIVE_CHAT,
  RECEIVE_MESSAGE,
  UPDATE_READ_COUNT,
  CACHE_CONSUMER_DATA,
  REMOVE_CHAT_SESSION,
  UPDATE_EVENTS,
  SET_LATEST_ARCHIVED_CHATS_REQ_ID,
  UPDATE_ARCHIVED_CHATS_SEARCHING,
  UPDATE_CHAT_SESSION_ACTION_LIST
} from '../constants'
import activityTracker from "utils/activityTracker";
import chatUtils from 'utils/chats'
import { decChatCount } from './agent'
import utils from '../utils';
import * as moment from 'moment';

const {isEscalatedChat} = utils.agentChatSessions;

export const resetAgentChatSessions = () => ({
  type: RESET_AGENT_CHAT_SESSIONS,
})

export const resetArchivedChatSessions = () => ({
  type: RESET_ARCHIVED_CHAT_SESSIONS,
})

export const resetEscalatedChatSessions = () => ({
  type: RESET_ESCALATED_CHAT_SESSIONS,
})

export const requestAgentChatSessions = () => ({
  type: REQUEST_AGENT_CHAT_SESSIONS,
})

export const requestArchivedChatSessions = () => ({
  type: REQUEST_ARCHIVED_CHAT_SESSIONS,
})

export const requestEscalatedChatSessions = () => ({
  type: REQUEST_ESCALATED_CHAT_SESSIONS,
})

export const updateArchivedChatsSearching = (flag) => ({
  type: UPDATE_ARCHIVED_CHATS_SEARCHING,
  searchingArchivedChatsCancelled: flag,
})

export const receiveAgentChatSessions = (req_id, data) => (dispatch, getState) => {

  const totalChats = _.uniqBy(
    [...data.chat_sessions, ...getState().agentChatSessions.chatSessions],
    'id'
  )

  const userId = getState().botUserSession.user.user_id

  return dispatch({
    type: RECEIVE_AGENT_CHAT_SESSIONS,
    req_id,
    receivedAt: Date.now(),
    data,
    userId,
    // Used by agent reducer
    nChats: totalChats.length,
  })
}

export const receiveArchivedChatSessions = (req_id, data) => (dispatch, getState) => {

  const totalChats = _.uniqBy(
    [...data.chat_sessions, ...getState().agentChatSessions.archived_chatSessions],
    'id'
  )

  const userId = getState().botUserSession.user.user_id

  return dispatch({
    type: RECEIVE_ARCHIVED_CHAT_SESSIONS,
    req_id,
    receivedAt: Date.now(),
    data,
    userId,
    // Used by agent reducer
    nChats: totalChats.length,
  })
}

export const receiveEscalatedChatSessions = (req_id, data) => (dispatch, getState) => {

  const totalChats = _.uniqBy(
    [...data.chat_sessions, ...getState().agentChatSessions.escalated_chatSessions],
    'id'
  )

  const userId = getState().botUserSession.user.user_id

  return dispatch({
    type: RECEIVE_ESCALATED_CHAT_SESSIONS,
    req_id,
    receivedAt: Date.now(),
    data,
    userId,
    // Used by agent reducer
    nChats: totalChats.length,
  })
}

export const fetchAgentChatSessions = (reset=false) => (dispatch, getState) => {

  /*
    If @reset is true, clear the list of chat sessions and start from
    the first page before fetching.
  */

  if (reset) {
    dispatch(resetAgentChatSessions())
  }

  if (!getState().agentChatSessions.hasMore) {
    return Promise.resolve()
  }

  dispatch(requestAgentChatSessions())

  return SDK.listChatSessionsForAgent({
    agent_id: getState().botUserSession.user.user_id,
    status: 'active',
    more: getState().agentChatSessions.more,
    items_per_page: getState().agentChatSessions.itemsPerPage,
  }, {resolveOn: 'ack'})

}

export const fetchArchivedChatSessions = (reset=false, searchPattern) => (dispatch, getState) => {

  /*
    If @reset is true, clear the list of chat sessions and start from
    the first page before fetching.
  */

  if (reset) {
    dispatch(resetArchivedChatSessions())
  }

  if (!getState().agentChatSessions.archived_hasMore) {
    return Promise.resolve()
  }

  dispatch(requestArchivedChatSessions())

  let selectedFilters = getState().conversationHistory.selectedHistoryFilters;
  // Swapping from and to values if startDate > endDate
  if (selectedFilters.duration && selectedFilters.duration === 'customDateRange') {
    let { from, to } = selectedFilters;
    if (from >= to) {
      let temp = null;
      temp = from;
      from = to 
      to = temp;
    }
    from = moment.utc(from).startOf('day').toISOString();
    to = moment.utc(to).endOf('day').toISOString();
    selectedFilters = { ...selectedFilters, from, to };
  }

  return SDK.listChatSessionsFor30Days({
    more: getState().agentChatSessions.archived_more,
    items_per_page: getState().agentChatSessions.archived_itemsPerPage,
    search_pattern: searchPattern,
    selectedFilters,
  }).then((data) => {
    let state = getState();
    if (!state.searchingArchivedChatsCancelled && state.agentChatSessions.latestArchivedChatsReqId === data.correlation_id) {
      if (reset) dispatch(resetArchivedChatSessions())
      dispatch(receiveArchivedChatSessions(data.correlation_id, data))
    }
  })
}

export const fetchEscalatedChatSessions = (reset=false, searchPattern) => (dispatch, getState) => {

  /*
    If @reset is true, clear the list of chat sessions and start from
    the first page before fetching.
  */

  if (reset) {
    dispatch(resetEscalatedChatSessions())
  }

  if (!getState().agentChatSessions.escalated_hasMore) {
    return Promise.resolve()
  }

  dispatch(requestEscalatedChatSessions())

  return SDK.listEscalatedSessionsForPype({
    pype_id: getState().pype.details.id,
    more: getState().agentChatSessions.escalated_more,
    items_per_page: getState().agentChatSessions.escalated_itemsPerPage,
    search_pattern: searchPattern,
  })
}


export const fetchEvents = (events, id) => {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_EVENTS,
      events,
      id
    });
  }
}

export const endChatSession = (id) => (dispatch, getState) => {
  const chat = _.find(getState().agentChatSessions.chatSessions, (chat) => chat.id === id)
  return dispatch({
    type: END_AGENT_CHAT_SESSION,
    id,
    chat,
  })
}

export const endedChatSession = (id, chat) => (dispatch, getState) => {

  const state = getState();
  const { end_comment, ended_by, participants } = chat
  const user = chatUtils.getChatEndedUser(end_comment, ended_by, participants.agent)

  activityTracker.logEvent(activityTracker.eventTypeNames.END_CONVERSATION,{endReason: user, environment: state.pype.details.env});
  // chat notice is received twice (with status: ended) when agent clicks
  // end chat and also after post chat modal. agent's current_conv should be decreased
  // only on first notice
  const existingActiveChat = _.find(getState().agentChatSessions.chatSessions, (chat) => chat.id === id && chat.status !== 'ended')
  
  if(existingActiveChat) {
    dispatch(decChatCount())
  }

  return dispatch({
    type: ENDED_AGENT_CHAT_SESSION,
    receivedAt: Date.now(),
    id,
    chat,
    agentId: state.botUserSession.user.user_id,
  })
}

export const addChat = (data, isEscalated = false) => ({
  type: ADD_AGENT_CHAT_SESSION,
  data,
  isEscalated
})

export const receiveChat = (data) => ({
  type: RECEIVE_CHAT,
  data,
})

export const receiveMessage = (data) => ({
  type: RECEIVE_MESSAGE,
  data,
})

// Update user_read_counts if agent reads a message
export const updateReadCount = (data) => ({
  type: UPDATE_READ_COUNT,
  receipt: data
})

export const cacheConsumerData = (data) => ({
  type: CACHE_CONSUMER_DATA,
  data
})

export const fetchTransferredSession = (chat_id, session) => (dispatch, getState) => {
  return SDK.getConversationHistory({
    chat_id,
    from: session.originator_user_id
  }).then((data) => {
    const state = getState();
    const isParticipant = session.participants.agent === state.botUserSession.user.user_id;
    
    // PE-11932 admin/agent dual user receives `chat` notices of agent when chats are assigned to them on login.
    // avoid adding them to 'My Queues' but add them to 'Agent Queue'
    if(!isParticipant) {
      dispatch(addChat(data, true));
      return;
    }

    // Added below condition for not showing notification when message is sent to escalated chat.
    const escalatedChat = isEscalatedChat(state, chat_id);
    if(!escalatedChat) {
      dispatch(addChat(data));
    }

    activityTracker.logEvent(activityTracker.eventTypeNames.RECEIVE_ESCALATED_CONVERSATION,{routingSoftware: data.agent_routing,environment: state.pype.details.env});
    // Bot transferred chat.
    if(data.history && data.history.length) {
      const chat = {
        ..._.last(data.history).chat_msg,
        consumer_name: data.consumer_name,
        stream_name: data.stream_name,
        consumer_id: data.consumer
      }
      dispatch(createdChat(null, chat))
    }
  })
}


export const removeChatSession = (id) => ({
  type: REMOVE_CHAT_SESSION,
  id
})

export const saveLastArchivedChatsCorrelationId = (correlation_id) => ({
  type: SET_LATEST_ARCHIVED_CHATS_REQ_ID,
  req_id: correlation_id
})

export const updateChatSessionActions = (data) => ({
  type: UPDATE_CHAT_SESSION_ACTION_LIST,
  data
})

export const manageChatSessions = (chat_id, handler, startNext = false) => async (dispatch, getState) => {
  let chatSessionActions = getState().agentChatSessions.chatSessionActions;
  let nextChatSessionActions;
  let resolver = handler;

  if (!startNext) {
    chatSessionActions[chat_id] = chatSessionActions[chat_id] ? [...chatSessionActions[chat_id], handler] : [handler];
    dispatch(updateChatSessionActions(chatSessionActions));
  } else {
    nextChatSessionActions = getState().agentChatSessions.chatSessionActions;
    nextChatSessionActions[chat_id].shift();
    dispatch(updateChatSessionActions(nextChatSessionActions));
    resolver = nextChatSessionActions[chat_id][0]
  }

  if ((chatSessionActions[chat_id].length === 1 || startNext) && Boolean(resolver)) {
    await resolver();
    dispatch(manageChatSessions(chat_id, null, true));
  }
}