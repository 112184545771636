import style from './style.css';
import React from 'react'
import PropTypes from 'prop-types'

import classNames from 'classnames';
import StreamsModal from '../StreamsModal';
import DesignModal from '../DesignModal';
import ScriptModal from '../ScriptModal';
import EditPanel from '../EditPanel';

class WidgetItem extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      activeModal: null, // 1-3 or null
    }
  }

  onClickPanel = () => {
    if (this.props.isEditing || this.props.shouldMask) {
      return
    }
    this.props.onToggle(this.props.widget.id)
  }

  closeModal = () => {
    this.setState({activeModal: null})
  }

  handleClickEdit = (event) => {
    event.stopPropagation()
    this.props.onClickEdit(this.props.widget)
  }

  handleClick1 = () => {
    this.setState({activeModal: 1})
  }

  handleClick2 = () => {
    this.setState({activeModal: 2})
  }

  handleClick3 = () => {
    this.setState({activeModal: 3})
  }

  render () {
    const {props, state} = this
    const {widget} = props
    let design = undefined
    try {
      design = JSON.parse(widget.style)
    } catch (SyntaxError) {
      design = undefined
      console.warn('Could not parse widget.style. All Web Conversational Interface should have styles that are parseable! Do not ignore this! widget.style value below:')
      console.warn(widget.style)
    }
    const className = classNames(style.WidgetItem, props.className, {
      [style.keepOpen]: props.keepOpen,
      [style.isOpen]: props.isOpen,
      [style.isEditing]: props.isEditing,
      [style.isEditMode]: props.isEditMode,
      [style.shouldMask]: props.shouldMask,
    })
    const widgetName = widget.widget_name
    return (
      <div className={className}>
        {state.activeModal === 1 &&
          <StreamsModal widget={widget} stream={widget.stream_id} onSubmit={this.closeModal} closeModal={this.closeModal} />}
        {state.activeModal === 2 &&
          <DesignModal widget={widget} design={design} onSubmit={this.closeModal} closeModal={this.closeModal} />}
        {state.activeModal === 3
          && <ScriptModal widget={widget} id={widget.id} onSubmit={this.closeModal} closeModal={this.closeModal} />}
        {props.isEditing ? (
          <EditPanel widget={widget} onClose={props.onCloseEdit} />
        ): (
          <div className={style.Panel} onClick={this.onClickPanel}>
            <div style={{display: 'flex'}}>
              <img className={style.Edit} src={`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-edit-big.svg`} onClick={this.handleClickEdit} />
              <span style={{position: 'relative'}}>
                <strong>{widgetName}</strong>
                <div className={style.AppId}>App ID: {widget.id}</div>
              </span>
            </div>
            {!props.keepOpen && (
              <div>
                <img className={classNames(style.Toggle, {
                  [style.ToggleActive]: props.isOpen,
                })} src={`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-page-up-big.svg`} />
              </div>
            )}
          </div>
        )}
        {(props.isOpen || props.keepOpen) && !props.isEditing && (
          <div className={style.Steps}>
            <div className={`${style.Config} ${style.BulletedConfig}`} onClick={this.handleClick1}>
              <span className={style.Action}>
                1. Configure general settings
                <div style={{display: 'flex', margin: '8px 0 0'}}>
                  <ul className={style.Bullet}><li /></ul>
                  <ul className={style.ConfigDescriptor}>
                    <li>{props.streamName}</li>
                  </ul>
                </div>
              </span>
              {/* <span className={style.Weak}>(required)</span> */}
            </div>
            <div className={`${style.Config} ${style.BulletedConfig}`} onClick={this.handleClick2}>
              <span className={style.Action}>
                2. Customize appearance
                {props.hasCustomDesign && (
                  <div style={{display: 'flex', margin: '8px 0 0'}}>
                    <ul className={style.Bullet}><li /></ul>
                    <ul className={style.ConfigDescriptor}>
                      <li>Widget appearance customized</li>
                    </ul>
                  </div>
                )}
              </span>
            </div>
            <div className={style.Config} onClick={this.handleClick3}>
              <span className={style.ScriptConfigAction}>
                3. Install code {/* (configure settings first) */}
              </span>
            </div>
          </div>
        )}
      </div>
    )
  }

}

WidgetItem.propTypes = {
  className: PropTypes.string,
  widget: PropTypes.object.isRequired,
  streamName: PropTypes.string.isRequired,
  hasCustomDesign: PropTypes.bool.isRequired,
  keepOpen: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  shouldMask: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  onCloseEdit: PropTypes.func.isRequired,
}

WidgetItem.defaultProps = {
  className: '',
}

export default  WidgetItem
