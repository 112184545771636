import _ from 'lodash'
import { BOT_USER_SESSION_LOGOUT } from "bot-user-session";
import { apiConstants as types } from '../constants'

const apiInitialState = Object.freeze({
  date_created: null,
  date_updated: null,
  friendly_name: null,
  key: null
})

const api = (state=apiInitialState, action) => {
  switch (action.type) {
  case types.RECEIVE:
    return {
      ...apiInitialState,
      ...state,
    }
  case types.CREATE_API:
    return {
      ...apiInitialState,
      ...action.data,
    }
  default:
    return state
  }
}

const getInitialState = () => ({
  isFetching: false,
  store: {},
});

const apis = (state=getInitialState(), action) => {
  switch (action.type) {
  case BOT_USER_SESSION_LOGOUT: {
    return getInitialState();
  }
  case types.REQUEST:
    return {
      ...state,
      isFetching: true,
    }
  case types.RECEIVE:
    return {
      ...state,
      isFetching: false,
      store: action.data.reduce((a, b) => ({
        ...a,
        [b.key]: api(b, action),
      }), {}),
    }
  case types.CREATE_API:
    return {
      ...state,
      store: {
        ...state.store,
        [action.data.key]: api(undefined, action),
      },
    }
  case types.REMOVE_API:
    return {
      ...state,
      store: _.omit(state.store, action.id),
    }
  default:
    return state
  }
}

export default apis
