import _ from 'lodash'

export const sortSkills = (skills) => {
  return _(skills)
    .sortBy('name')
    .sortBy((skill) => {
      return skill.id === "general" ? 0 : 1; // show general skill first
    })
    .map((skill) => {
      if(skill.id === 'general'){
        skill.name = 'General';
      }
      return skill;
    })
    .value();
}