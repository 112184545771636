import _ from 'lodash'

import React from 'react'
import PropTypes from 'prop-types'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import cx from 'classnames';
import {Column, Cell} from 'fixed-data-table-2';

import sdk from 'sdk';
import StatsWrapper from './StatsWrapper';
import {numberWithCommas} from 'utils/format_number';
import {truncate} from 'utils/string';
import {formatTimestamp} from 'utils/format_time';
import TableResponsive from 'components/ui/TableResponsive';
import featureFlags from 'utils/featureFlags'

import css from './AgentsTabPanel.css';


const {agentsActions} = sdk;


const ROW_HEIGHT = 46;
const HEADER_HEIGHT = 25;

const EmptyAgents = (props) => {
  const {customerId, pypeId} = props;

  return (
  <div className={css.emptyAgentsMessage}>
    <h4>{props.message}</h4>
    <p>Invite new agents <a href={`${window.config.PS_CONSOLE_URL}/users`}>here.</a></p>
  </div>)
}

const getJoinedAgents = (agents) => {
  return _.filter(agents, (agent)=>(agent.user_status === 'active'));;
}

const getSortedAgents = (joinedAgents) => {
  const activeAgents =  _.chain(joinedAgents)
  .filter((agent) => (
    ['online','away','busy'].indexOf(agent.status) > -1 ||
    (agent.active_sessions + agent.daily_sessions_ended) > 0)
  )
  .sortBy('last_name')
  .groupBy((agent) => {
    const isBusy = agent.max_conv === agent.current_conv;
    const status = agent.status;
    return status === 'online' && isBusy ? 'busy' : status;
  }).value();
  // Agents showing order: 1) online, 2) busy 3) away 4) offline
  const {online,busy,away,offline} = activeAgents
  const sortedAgents = _.unionBy(online, busy, away, offline, _.identity);
  return sortedAgents;
}
class AgentsTabPanel extends React.Component{
  componentDidMount() {
    let filterData = [];
    if (featureFlags.isAgentGroupMetricsEnabled() &&
        localStorage.getItem("skill") && 
        JSON.parse(localStorage.getItem("skill")).length > 0
      ) {
      let data = JSON.parse(localStorage.getItem("skill"))
      filterData = data.filter(item => item.value !== "show all")
    }
    const agentSkills = filterData.length ? filterData.map((item) => item.value) : [];
    this.props.fetchAgents(agentSkills);
  }
	render(){
    const {props} = this;
    const {
      agentsStore,
      isFetchingAgents,
      customerId,
      pypeId} = props;

    const joinedAgents = getJoinedAgents(agentsStore)
    const agents = getSortedAgents(joinedAgents);
    const totalChats = _.reduce(agents, (sum,agent) => (sum + agent.current_conv), 0);
    const totalChatlimit =  _.chain(agents)
      .filter((agent) => (agent.status !== 'offline'))
      .reduce((sum,agent) => (sum + agent.max_conv), 0)
      .value();
    const totalReplyTime = _.reduce(agents, (sum,agent) => (sum + agent.daily_response_time), 0);
    const totalChatsEnded = _.reduce(agents, (sum,agent) => (sum + agent.daily_sessions_ended), 0);
    const tableHeightWithScroll = agents.length < 10 ? agents.length * ROW_HEIGHT + 75 : ROW_HEIGHT*10 + 75;

    // NOTE: ResponsiveFixedDataTable requires that we specify height in wrapper as well.
    // Else it gets 1px height.
		return (
			<StatsWrapper isLoading={isFetchingAgents} border={false}>
        {!joinedAgents.length ? 
          <EmptyAgents
            customerId={customerId}
            pypeId={pypeId}
            message={"You have no agents yet"} />
        : !agents.length ? 
          <EmptyAgents 
            customerId={customerId}
            pypeId={pypeId}
            message={"No agents have been active today"}/>
        : (<div className={"m-t-10"} style={{height:tableHeightWithScroll}}>
            <TableResponsive
              rowHeight={ROW_HEIGHT}
              rowsCount={agents.length}
              height={tableHeightWithScroll}
              headerHeight={HEADER_HEIGHT}
              footerHeight={ROW_HEIGHT}>
              <Column
                header={<Cell>Name</Cell>}
                cell={({rowIndex, ...props}) => {
                  const agent = agents[rowIndex];
                  const {last_name,status,first_name} = agent;
                  const agentName = truncate(first_name + " " + last_name,25);
                  return <Cell {...props}>
                    <span className={cx('status-indicator inline-block', status)} />
                    <span className={cx({'fade': status === 'offline'})}>{agentName}</span>
                  </Cell>
                }}
                footer={<Cell>ALL AGENTS</Cell>}
                flexGrow={1}
                width={200}
              />
              <Column
                header={<Cell>
                  <span className="pull-left">Active Chats</span>
                  <span className={css.chatLimitHeader}>Chat limit</span>
                </Cell>}
                cell={
                  ({rowIndex, ...props}) => {
                    const row = agents[rowIndex];
                    const {max_conv, current_conv, status} = row;
                    const maxConv = status === 'offline' ? '-' : numberWithCommas(max_conv);
                    const barWidth = current_conv/max_conv * 300;
                    return <Cell {...props}>
                      <div className={css.chatCountBarBg}>
                        <span className={"leftCount"}>{numberWithCommas(current_conv)} chats</span>
                        <span className={"rightCount"}>{maxConv}</span>
                        <div className={"chatCountBar"} style={{width:barWidth}}></div>
                      </div>
                    </Cell>
                  }
                }
                footer={()=> {
                  const barWidth = !totalChatlimit ? 0 : totalChats/totalChatlimit * 300;
                  const chatLimitTotal = !totalChatlimit ? '-' : numberWithCommas(totalChatlimit);
                  return <Cell>
                    <div className={css.chatCountBarBg + " total"}>
                      <span className={"leftCount"}>{numberWithCommas(totalChats)} chats</span>
                      <span className={"rightCount"}>{chatLimitTotal}</span>
                      <div className={"chatCountBar"} style={{width:barWidth}}>
                        <span className={"leftCount"}>{numberWithCommas(totalChats)} chats</span>
                      </div>
                    </div>
                  </Cell>
                }}
                flexGrow={1}
                width={320}
              />
              <Column
                align="right"
                header={<Cell>Reply Time</Cell>}
                cell={
                  ({rowIndex, ...props}) => {
                    const row = agents[rowIndex];
                    const {daily_response_time} = row;
                    const replyTime = formatTimestamp(daily_response_time);
                    return <Cell {...props}>
                      <span className={replyTime.css}>{replyTime.value}</span>
                    </Cell>
                  }
                }
                footer={(props) => {
                    const _totalReplyTime = formatTimestamp(totalReplyTime/agents.length);
                    return <Cell {...props}>
                      <span className={_totalReplyTime.css}>{_totalReplyTime.value}</span>
                    </Cell>
                  }
                }
                width={120}
                flexGrow={1}
              />
              <Column
                align="right"
                header={<Cell>Chats ended</Cell>}
                cell={
                  ({rowIndex, ...props}) => {
                    const row = agents[rowIndex];
                    const {daily_sessions_ended} = row;
                    return <Cell {...props}>{numberWithCommas(daily_sessions_ended)}</Cell>
                  }
                }
                footer={(props)=>(<Cell {...props}>{numberWithCommas(totalChatsEnded)}</Cell>)}
                flexGrow={1}
                width={130}
              />
            </TableResponsive>

        </div>)}
      </StatsWrapper>
			);
	}
}

AgentsTabPanel.propTypes = {
  agents: PropTypes.array.isRequired
}

const mapStateToProps = (state) => {
  return {
    agentsStore: state.agentsForPype.agents,
    isFetchingAgents: state.agentsForPype.isFetching,
    customerId: state.pype && state.pype.details ? state.pype.details.customer_id : null,
    pypeId:state.pype && state.pype.details ? state.pype.details.id: null,
  }
}

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({
    fetchAgents: agentsActions.fetchAgents
  }, dispatch)
)

export default  connect(
  mapStateToProps,
  mapDispatchToProps
)(AgentsTabPanel);

