import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import _ from 'lodash';

import Linkify from 'react-linkify';
import Time from 'react-time';
import AppTooltip from 'components/shared/AppTooltip';
import Spinner from 'components/ui/pype-spinner/PypeSpinner';
import sdk from 'sdk';
import activityTracker from 'utils/activityTracker'
import ErrorBoundary from 'components/ui/ErrorBoundary';

const  {campaignsActions}  = sdk


class CampaignDetail extends React.Component{
  static propTypes = {
    campaigns: PropTypes.array.isRequired,
    streams: PropTypes.array.isRequired
  }

  state = {
    streams: [],
    campaign: {},
    intervalId: null
  }

  getCampaign = (campaignId, campaigns) => {
    return _.find(campaigns, function(campaign) { return campaign.id === campaignId; }); // TODO: get campaign details by ID API
  }

  componentDidMount() {
    const { pype, match } = this.props
    const campaignId = match.params.id
    const { customerName, name } = pype

    activityTracker.logEvent(activityTracker.eventTypeNames.VIEW_BROADCAST,{customerName, pypeName: name, broadcastID: campaignId});

    this.pollCampaignStatsForId(campaignId);
  }

  componentWillUnmount() {
     clearInterval(this.state.intervalId);
  }

  pollCampaignStatsForId = (campaignId) => {
    this.props.getCampaignStats(campaignId, true);
    clearInterval(this.state.intervalId);
    const intervalId = setInterval(this.props.getCampaignStats.bind(null, campaignId), 5000);
    this.setState({intervalId: intervalId});
  }

  componentWillMount() {
    this.setState({
      campaign: this.getCampaign(this.props.match.params.id, this.props.campaigns),
      streams: this.props.streams
    });
  }

  componentWillReceiveProps(nextProps) {
    const { pype, match, campaigns, streams } = nextProps
    const { customerName, name } = pype
    const campaignId = match.params.id

    this.setState({
      campaign: this.getCampaign(campaignId, campaigns),
      streams
    });
    if(campaignId !== this.props.match.params.id){
      this.pollCampaignStatsForId(campaignId);
      activityTracker.logEvent(activityTracker.eventTypeNames.VIEW_BROADCAST,{customerName, pypeName: name, broadcastID: campaignId});
    }
  }

  formatNumbers(num) {
    if (num - Math.floor(num) !== 0) {
      return num.toFixed(2);
    } else {
      return num;
    }
  }

  renderCampaignDetails() {
    const {stats,streams} = this.props;
    const {campaign} = this.state;
    var content = campaign.body || '';
    var file = campaign.file || '';
    var fileLink = null;
    const isSMSBroadcast = campaign.smsCampaign;

    if (file) {
      var fileName = file.replace(/^.*[\\\/]/, '');

      fileLink = (
        <div className="m-t-10 fs-16" style={ { maxWidth: '500px', overflow: 'auto', wordBreak: 'break-all' } }>
          <img src={`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/paperclip.svg`} width="19" height="18"/>
          <a target="_blank" href={file} className="m-l-5">{fileName}</a>
        </div>
      );
    }

    if (streams.length > 0) {
      var streamObj = _.find(streams, function(stream) {
        return stream.id === campaign.stream_id;
      });
    }

    var streamName = '';

    if (streamObj !== undefined) {
      streamName = streamObj.stream_name;
    }

    var status = campaign.status;
    var statusLabel = (status === 'completed' ||
    status === 'error (no deliverable messages)') ? 'Completed' : 'Incomplete';
    var statsSent, statsOpened, statsOpenedPerc;
    const isStatsLoading = _.isEmpty(stats)

    statsSent = statsOpened = statsOpenedPerc = 0;
    if(!isStatsLoading){
      if (isSMSBroadcast) {
        streamName = 'SMS';
        statsSent = stats.sms_total_count;
        statsOpened = stats.sms_read_count;
      } else {
        statsSent = stats.app_total_count;
        statsOpened = stats.app_read_count;
      }
    }

    statsOpenedPerc = !!statsSent ? statsOpened / statsSent * 100 : 0;

    return (
      <div className="campaign-container full-height p-30">

        <div className="container-fluid">
          <table className="table campaign-stats-table">
            <thead>
              <tr>
                <th className="text-center"><p className="no-margin">SENT</p></th>
                <th className="text-center">
                  <div className="no-margin"><span className="m-r-5">{ isSMSBroadcast ? 'DELIVERED' : 'OPENED' }</span>
                  <AppTooltip inverted={true} tooltipKey="broadcasts_tab.opened" style={ { color: 'black' } }>
                  </AppTooltip>
                  </div></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-center">
                  {
                    isStatsLoading ? <img className="m-t-5 m-b-5" src={`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/hourglass.gif`}/> :
                    <div>
                      <p className="fs-18 no-margin">{this.formatNumbers(statsSent)}</p><p className="perc no-margin">-</p>
                    </div>
                  }
                </td>
                <td className="text-center">
                  {
                    isStatsLoading ? <img className="m-t-5 m-b-5" src={`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/hourglass.gif`}/> :
                    <div>
                      <p className="fs-18 no-margin">{this.formatNumbers(statsOpened)}</p><p className="perc no-margin">({this.formatNumbers(statsOpenedPerc)}%)</p>
                    </div>
                  }
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <h5 className="heading-light-cap m-t-30">Broadcast content</h5>
        <Linkify className="campaign-body" properties={ { target: '_blank' } }>{content}</Linkify>
        { fileLink != null ? fileLink : <span></span> }

        <h5 className="heading-light-cap m-t-30">Recipient Stream</h5>
        <div className="tag">{streamName}</div>
        <div className="clearfix"></div>

        <h5 className="heading-light-cap m-t-30">Status</h5>
        <p>{statusLabel}</p>

      </div>
    );
  }

  render() {
    if(this.props.isFetching || !this.state.campaign){
      return (
      <div className="full-height">
        <Spinner className="chat-logs-detail-spinner" />
      </div>);
    }

    var date = this.state.campaign && this.state.campaign.creation_date;

    return (
      <div className="full-height scrollable">
        <ErrorBoundary>
          <div className="pane-header text-center">
            { date ? <Time className="title" value={date} format="MM-DD-YYYY"/> : null }
          </div>
          { this.renderCampaignDetails() }
        </ErrorBoundary>
      </div>
    );
  }
};

const mapStateToProps = function(state) {
  return {
    campaigns: state.campaignsForPype.campaigns,
    streams: state.streamsForPype.streams,
    isFetching: state.campaignsForPype.isFetching,
    stats: state.campaignsForPype.stats,
    pype: state.pype.details
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCampaignStats (campaignId, reset) {
    if(/_sms_campaign/.test(campaignId)){
      campaignId = campaignId.split('_sms_campaign').shift();
    }
    return dispatch(campaignsActions.fetchCampaignStats(campaignId, reset))
  }
})

export default  connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignDetail);
