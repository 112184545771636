/*
  Use global ui state
*/

import React from 'react'
import style from './style.css';
import PropTypes from 'prop-types'

import classNames from 'classnames';
import NewRuleModal from '../NewRuleModal';


class CreateApi extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      isRuleModalOpen: false,
    }
  }

  handleClick = () => {
    this.setState({isRuleModalOpen: true})
  }

  closeModal = () => {
    this.setState({isRuleModalOpen: false})
  }


  render () {
    const {props, state} = this
    const className = classNames(style.CreateApi, {
      [style.shouldMask]: props.shouldMask,
    })
    return (
      <div className={className}>
        {state.isRuleModalOpen &&
          <NewRuleModal isNew new={true} rules={props.rules} rule={null} closeModal={this.closeModal} />}
        <button className={style.Button} onClick={this.handleClick}>
          <img className={style.Img} src={`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-add-big.svg`} />
          <span className={style.Text}>Add a rule</span>
        </button>
      </div>
    )
  }

}

CreateApi.propTypes = {
  shouldMask: PropTypes.bool.isRequired,
}

export default  CreateApi
