// External imports
import React from 'react'
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import assign from 'object-assign';
import Validator from 'validator';
import _ from 'lodash';
import cx from 'classnames';


// UI components
import Toast from '../../ui/toast/Toast';
import LabelCheckbox from '../../ui/forms/LabelCheckbox';
import SaveButton from '../../ui/forms/SaveButton';
import ErrorBoundary from 'components/ui/ErrorBoundary';

// Local imports
import DetailPane from './DetailPane';
import TitleBar from './TitleBar';
import ContentBody from './ContentBody';
import SubHeader from './SubHeader';
import Footer from './Footer';
import PypeManagerFeature from '../PypeManagerFeature';

import sdk from 'sdk';
import activityTracker from "../../../utils/activityTracker";
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
const { pypeActions } = sdk;

const SETTING_NAME = 'end chat options'
class ChatLogs extends React.Component {

  constructor(props) {
    super(props);

    // Initial state
    this.state = {
      bot: null,
      emailChatLogsEnabled: props.emailChatLogsEnabled || false,
      chatLogsEmail: props.chatLogsEmail,
      endpointChatLogsEnabled: props.endpointChatLogsEnabled || false,
      chatLogsEndpoint: props.chatLogsEndpoint,
      surveyBotsEnabled: false,
      addComment: props.addComment || false,
      addCommentRequired: props.addCommentRequired || false,
      errors: {},
      error: '',
      isUpdating: false
    };
    const { customerName } = this.props

    activityTracker.logEvent(activityTracker.eventTypeNames.VIEW_PYPE_SETTING,{settingName: SETTING_NAME, customerName});
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      emailChatLogsEnabled: nextProps.emailChatLogsEnabled,
      chatLogsEmail: nextProps.chatLogsEmail,
      endpointChatLogsEnabled: nextProps.endpointChatLogsEnabled,
      chatLogsEndpoint: nextProps.chatLogsEndpoint,
      addComment: nextProps.addComment,
      addCommentRequired: nextProps.addCommentRequired,
    })
  }

  // Event handlers

  toggle = (prop) => {
    /*
      Handles checkbox toggling events.
      @prop: The boolean prop to toggle.
    */
    if (!this.state.hasOwnProperty(prop))
      console.error(`this.state.${prop} does not exist. Did you misspell the property name?`)
    this.setState({
      [prop]: !this.state[prop]
    })
  }

  handleEmailInput = (e) => {
    var email = e.target.value.trim();

    this.setState({
      chatLogsEmail: email,
      error: '',
      errors: _.omit(this.state.errors, 'email'),
    });
  }

  isEmailValid = () => {
    var isValid = false;
    var error = null;
    var { chatLogsEmail } = this.state;

    if (!chatLogsEmail || !Validator.isEmail(chatLogsEmail)) {
      error = "Please enter a valid email.";
    } else {
      isValid = true;
    }

    this.setState({ errors: assign(this.state.errors, { email: error }) });

    return isValid;
  }

  handleEndpointInput = (e) => {
    var endpoint = e.target.value.trim();
    this.setState({
      chatLogsEndpoint: endpoint,
      errors: _.omit(this.state.errors, 'endpoint'),
    });
  }

  isEndpointValid = () => {
    var isValid = false;
    var error = null;
    var { chatLogsEndpoint } = this.state;

    if (!chatLogsEndpoint || !Validator.isURL(chatLogsEndpoint)) {
      error = "Please enter a valid HTTP address";
    } else {
      isValid = true;
    }

    this.setState({ errors: assign(this.state.errors, { endpoint: error }) });

    return isValid;
  }

  handleBotChange = (bot) => {
    this.setState({ bot });
  }

  showMessage = () => {}

  hasFormChanged = () => {
    const {props, state} = this
    return state.emailChatLogsEnabled !== props.emailChatLogsEnabled
      || state.endpointChatLogsEnabled !== props.endpointChatLogsEnabled
      || (state.emailChatLogsEnabled && (state.chatLogsEmail !== props.chatLogsEmail))
      || (state.endpointChatLogsEnabled && (state.chatLogsEndpoint !== props.chatLogsEndpoint))
      || state.addComment !== props.addComment
      || state.addCommentRequired !== props.addCommentRequired
  }

  isFormValid = () => {

    const {state} = this

    _.forEach(state.errors, (error) => {
      if (!_.isEmpty(error)) {
        return false
      }
    })

    const email = typeof state.chatLogsEmail === 'string' ? state.chatLogsEmail.trim() : ''
    const endpoint = typeof state.chatLogsEndpoint === 'string' ? state.chatLogsEndpoint.trim() : ''

    if (state.emailChatLogsEnabled && (email === '' || !Validator.isEmail(email))) {
      return false
    }

    if (state.endpointChatLogsEnabled && (endpoint === '' || !Validator.isURL(endpoint))) {
      return false
    }

    return true

  }

  handleSave = () => {
    const { customerName } = this.props;
    const data = {
      id: this.props.pypeID,
      chat_logs_email_enabled: this.state.emailChatLogsEnabled,
      chat_logs_email: this.state.chatLogsEmail,
      chat_logs_http_enabled: this.state.endpointChatLogsEnabled,
      chat_logs_end_point: this.state.chatLogsEndpoint,
      end_comment_display: this.state.addComment,
      end_comment_required: this.state.addCommentRequired,
    };

    this.setState({isUpdating: true});

    const onSuccess = () => {
      window.appCreateToast({
        message: "Changes saved successfully.",
        level: "success"
      });
      activityTracker.logEvent(activityTracker.eventTypeNames.UPDATE_PYPE_SETTING,{settingName: SETTING_NAME,action: activityTracker.eventActionType.UPDATE, customerName});
    }

    const onError = (error) => {
      console.log(error);
      window.appCreateToast({
        message: "Couldn't save changes.",
        level: "error"
      });
    }

    const onDone = () => {
      this.setState({isUpdating: false});
    }

    this.props.updatePype(data).then(onSuccess, onError)
      .then(onDone)

  }

  render() {
    const {
      emailChatLogsEnabled, chatLogsEmail,
      endpointChatLogsEnabled, chatLogsEndpoint,
      addComment, addCommentRequired,
    } = this.state;

    const emailCSSClass = cx('p-l-30', {'has-error': this.state.errors.email}, {'hidden': !emailChatLogsEnabled});
    const endpointCSSClass = cx('p-l-30', {'has-error': this.state.errors.endpoint}, {'hidden': !endpointChatLogsEnabled});

    return (
      <DetailPane className="chat-logs-detail has-footer">
        <ErrorBoundary>
          <Toast ref="toast" />

          <ContentBody>
            {this.props.flags && this.props.flags.brandInfo && this.props.flags.brandInfo.showChatLogs &&
              <section style={{paddingTop: 0}}>
                <SubHeader>Chat logs</SubHeader>
                <section>
                  <p>
                    Chat logs are sent to a specific email address and/or a HTTP end point at the end of each chat.
                  </p>
                </section>
                <section>
                  <LabelCheckbox label="Enable email" value={emailChatLogsEnabled}
                    onClick={() => this.toggle('emailChatLogsEnabled')} />
                  <div className={emailCSSClass}>
                    <input type="email"
                          name="email"
                          placeholder="e.g. example@gmail.com"
                          value={chatLogsEmail}
                          onChange={this.handleEmailInput}
                          onBlur={this.isEmailValid}
                          className="form-control m-t-10"
                          disabled={!emailChatLogsEnabled}/>
                  </div>
                  {emailChatLogsEnabled && (
                    <p className="text-danger small p-l-30">{this.state.errors.email}</p>
                  )}
                </section>
                <section>
                  <LabelCheckbox label="Enable HTTP end point" value={endpointChatLogsEnabled}
                                onClick={() => this.toggle('endpointChatLogsEnabled')} />
                  <div className={endpointCSSClass}>
                    <input type="url"
                          placeholder="e.g. http://www.endpoint.com/sample/"
                          value={chatLogsEndpoint}
                          onChange={this.handleEndpointInput}
                          onBlur={this.isEndpointValid}
                          className="form-control m-t-10"
                          disabled={!endpointChatLogsEnabled}/>
                  </div>
                  {endpointChatLogsEnabled && (
                    <p className="text-danger small p-l-30">{this.state.errors.endpoint}</p>
                  )}
                </section>
              </section>
            }

            {/*<section>
              <SubHeader>Survey bots</SubHeader>
              <section>
                <p>
                  When enabled, all chats are automatically sent to the
                  specified survey bot after the chat is ended by an
                  agent or bot. Interaction with bots won’t be stored in
                  the chat history.
                </p>
              </section>
              <section>
                <LabelCheckbox label="Activate survey bots" value={surveyBotsEnabled}
                  onClick={() => this.toggle('surveyBotsEnabled')} />
              </section>
            </section>*/}
            <PypeManagerFeature>
              <section>
                <SubHeader>Agent actions</SubHeader>
                <section>
                  <p>
                    Comments and tags are agent actions for classifying a
                    conversation at the end of a chat. Choosing
                    'Required' indicates an agent action that must be
                    completed.
                  </p>
                </section>
                <section className="checkbox-grid">
                  <LabelCheckbox label="Add a comment" value={addComment}
                    onClick={() => this.toggle('addComment')} />
                  <LabelCheckbox
                    label="Required"
                    value={addCommentRequired}
                    onClick={() => this.toggle('addCommentRequired')}
                    className={addComment ? "" : " fade"}
                    disabled={!addComment}/>
                </section>
                <p style={{marginTop: '-10px', fontSize: '15px'}}>
                  Visit <Link to="manage-tags">Tag management</Link> to create and edit tags.
                </p>
              </section>
            </PypeManagerFeature>

          </ContentBody>

          <Footer>
            <SaveButton className="btn-primary"
              isLoading={this.state.isUpdating} disabled={!this.isFormValid() || !this.hasFormChanged()}
              onClick={this.handleSave}>
              Save changes
            </SaveButton>
          </Footer>
        </ErrorBoundary>
      </DetailPane>
    );
  }
}

ChatLogs.propTypes = {
};

ChatLogs.defaultProps = {
  emailChatLogsEnabled: false,
  endpointChatLogsEnabled: false,
  addComment: false,
  addCommentRequired: false,
}

// react-redux bindings
const mapStateToProps = (state) => {
  return {
    emailChatLogsEnabled: state.pype.details.chat_logs_email_enabled,
    chatLogsEmail: state.pype.details.chat_logs_email,
    endpointChatLogsEnabled: state.pype.details.chat_logs_http_enabled,
    chatLogsEndpoint: state.pype.details.chat_logs_end_point,
    pypeID: state.pype.details.id,
    addComment: state.pype.details.end_comment_display,
    addCommentRequired: state.pype.details.end_comment_required,
    customerName: state.pype.details.customerName
  }

};

const mapDispatchToProps = function(dispatch) {
  return bindActionCreators({
    updatePype: pypeActions.updatePype,
  }, dispatch);
};

export default  connect(
  mapStateToProps,
  mapDispatchToProps
)(withLDConsumer()(ChatLogs));
