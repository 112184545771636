import React from "react";
import { Wrapper } from "./helpers";
import { Link } from "react-router-dom";
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

/** Show a simple message for unauthorized routes */
const NotFound = (props) => (
  <Wrapper>
    <img
      src={
        props.flags && props.flags.brandInfo && props.flags.brandInfo.logo ? props.flags.brandInfo.logo :
        `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/Pypestream_logo_horiz_black.svg`
      }
      width="240"
      height="38"
    />
    <h1>Error 404</h1>
    <h5>
      Sorry, looks like we’ve had a Pype burst! <br />
      The page you’re looking for has either been relocated or doesn’t exist.
    </h5>
    <Link to="/">Go back</Link>
  </Wrapper>
);

export default (withLDConsumer()(NotFound));
