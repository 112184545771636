import style from './style.scss';
import React from 'react'
import PropTypes from 'prop-types';

import classNames from 'classnames';

export class SkillItem extends React.Component {

  onClickPanel = () => {
    this.props.onToggle(this.props.skill.id);
  }

  handleClickEdit = (event) => {
    event.stopPropagation()
    this.props.onClickEdit(this.props.skill)
  }

  render () {
    const {props, state} = this
    const {skill} = props
    const className = classNames(style.SkillItem, {
      [style.isOpen]: props.isOpen,
    })
    const {assigned_agent_count, name, id} = skill;
    const isGeneral = id === 'general';

    return (
      <div className={`SkillItem ${className}`}>
        <div className={`SkillItemPanel ${style.Panel}`} onClick={this.onClickPanel}>
        <div className={`SkillItemLeftContent ${style.LeftContent}`}>
            <img className={style.Edit} src={`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/edit-big.svg`} onClick={this.handleClickEdit} />
            <span>
              <strong>{isGeneral ? name.charAt(0).toUpperCase() + name.slice(1) : name}</strong>
            </span>
          </div>
          <div id="RightContent" className={`SkillItemRightContent ${style.RightContent}`}>
            {assigned_agent_count > 0 ?
              (<div>
                <span>
                  {`${assigned_agent_count} agent${assigned_agent_count > 1 ? 's':''}`}
                </span>
                {
                  <img className={classNames(style.Toggle, {
                    [style.ToggleActive]: props.isOpen,
                  })} src={`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-page-up-big.svg`} />
                }
              </div>) :
              (<div>
                <span>No agents</span>
                <img src={`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/something-wrong-big.svg`} width="30" height="30"/>
              </div>)
            }
          </div>
        </div>
        {(props.isOpen) && (
          <div className="SkillItemSubPanel">
            <div className={`${style.Agents} ${style.PaddedAgents}`} onClick={this.handleClickEdit}>
              <div className={style.Action}>
                {props.agentsForSkill.map((agent, index) => {
                  return <span key={index}>{agent}</span>
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

SkillItem.propTypes = {
  skill: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  onClickEdit: PropTypes.func.isRequired
}

SkillItem.defaultProps = {
  agentsForSkill: []
}

export default  SkillItem
