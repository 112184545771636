import superagentPromisePlugin from 'superagent-promise-plugin';
var request = superagentPromisePlugin.patch(require('superagent'));

const envConfig = window.config.PS_ENV_CONFIGS

// value set from setEnvironment
let env = null; 

export const getAMQPSubscription = function (user) {
  return `/exchange/stomp_users.exch/out.user.${user}`;
};
export const getAMQPACKSubscription = function (replyToKey) {
  return `/exchange/general.exch/${replyToKey}`;
};
export const setEnvironment = function (_env) {
  return new Promise(function(resolve, reject){
    env = _env;
    const configUrl = `${envConfig[env].rest}config`;

    request.get(configUrl)
      .then((response) => {
        const stomp = `${response.body.chat_url}`;
        const fileUpload = `${response.body.media_url.slice(0, -1)}/fileupload`;
  
        envConfig[env] = Object.assign(
          {},
          envConfig[env],
          {
            stomp,
            fileUpload
          }
        );
        resolve();
      })
      .catch((err) => {
        console.log('Error while fetching config URLs', err);
        reject();
      });

  });


};
export const getSTOMPURL = function () {
  if(!env) throw new Error('Env not set');
  return envConfig[env].stomp;
};

export const getRESTURL = function (path) {
  if(!env) throw new Error('Env not set');
  return `${envConfig[env].rest}${path}`;
};

export const getApiRESTURL = function (path) {
  if(!env) throw new Error('Env not set');
  return `${envConfig[env].restApi}${path}`;
}

export const getFileUploadURL = function () {
  if(!env) throw new Error('Env not set');
  return envConfig[env].fileUpload;
};

export const getWCWConfig = function () {
  if(!env) throw new Error('Env not set');
  const { wcwLauncherUrl, wcwEnv, wcwDomain } = envConfig[env];

  return {
    wcwLauncherUrl,
    wcwEnv,
    wcwDomain
  }
};