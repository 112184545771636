import React from 'react';
import PropTypes from 'prop-types'

import Modal from 'react-modal';
import cx from 'classnames';
import CloseButton from 'components/ui/modals/CloseButton';
import SaveButton from 'components/ui/forms/SaveButton';
import style from './style.css';

const Component = (props) => {
  const {
    title,
    message,
    isConfirming,
    onClose,
    onConfirm,
    isDestructive,
    dismissAction,
    confirmAction
  } = props;

  return (
    <Modal ariaHideApp={false} isOpen={true} className="confirm-delete-modal" onRequestClose={onClose}>
      <div className={`modal-content ${style.ModalContent}`}>
        <div className="modal-header">
          <CloseButton onClick={onClose} />
          <h4 className="modal-title text-center">{title}</h4>
        </div>
        <div className="modal-body p-20">
          <p>{message}</p>
        </div>
        <div className={`modal-footer ${style.ModalFooter}`}>
          <div>
            <button className="btn btn-default" onClick={onClose}>{dismissAction}</button>
            <SaveButton className={ isDestructive ? 'btn-danger' : 'btn-primary' }
              isLoading={isConfirming}
              onClick={onConfirm}>
              {confirmAction}
            </SaveButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};

Component.defaultProps = {
  isDestructive: false,
  dismissAction: 'Cancel',
  confirmAction: 'Confirm'
};

Component.propType = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  dismissAction: PropTypes.string.isRequired,
  confirmAction: PropTypes.string.isRequired,
  isConfirming: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isDestructive: PropTypes.bool
};

export default Component;

