import {connect} from 'react-redux'
import _ from 'lodash'
import Component from './Component'
import sdk from 'sdk';
const {pypeActions} = sdk;

const mapStateToProps = (state) => {
  const { broadcast_stream_id } = state.pype.details;

  let broadcast_channel, broadcast_sms_limit, broadcast_sms_account, broadcastSMSStreamId;

  const streamWithBroadcast = _.find(state.streamsForPype.streams, {id:broadcast_stream_id});
  // TODO sms data is configured for one stream in backend. Once channel is
  // saved as `pypestream` and new stream is assigned, broadcast_stream_id in
  // pype.details will point to this new stream which may not have any
  // SMS data. Therefore SMS data can be retrieved only from pre-configured EBB stream.
  const streamsWithSMSBroadcast = _.filter(state.streamsForPype.streams, (stream) => (!!stream.sms_phone_number));

  if(streamWithBroadcast){
    broadcast_channel = streamWithBroadcast.broadcast_channel;
  }

  if(streamsWithSMSBroadcast.length){
    const streamWithSMSBroadcast = _.find(streamsWithSMSBroadcast, {id: broadcast_stream_id})
      || streamsWithSMSBroadcast[0];
    broadcast_sms_limit = streamWithSMSBroadcast.broadcast_sms_limit;
    broadcast_sms_account = streamWithSMSBroadcast.sms_phone_number;
    broadcastSMSStreamId = streamWithSMSBroadcast.id;
  }
  return {
    isFetchingBroadcastConfig: state.pype.isFetchingBroadcastConfig,
    broadcastChannel: broadcast_channel,
    broadcastSmsLimit: broadcast_sms_limit || 0,
    isSmsEnabled: !!broadcast_sms_account,
    broadcastSmsAccount: broadcast_sms_account,
    broadcastStreamId: broadcast_stream_id,
    broadcastSMSStreamId: broadcastSMSStreamId,
    streamsStore: state.streamsForPype.streams,
    isFetching: state.pype.isFetching || state.streamsForPype.isFetching,
    pypeName: state.pype.details.name,
    customerName: state.pype.details.customerName
  }
}

const mapDispatchToProps = (dispatch) => ({
  onSubmit (data) {
    return dispatch(pypeActions.updateEventBasedBroadcasts(data))
  },
  // TODO uncomment when dynamic SMS configuration is enabled for admins.
  // getBroadcastConfiguration () {
  //   return dispatch(pypeActions.fetchBroadcastConfiguration())
  // }
})

export default  connect(
  mapStateToProps,
  mapDispatchToProps
)(Component)
