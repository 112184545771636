var flash = {
  read: function(){
    return JSON.parse(sessionStorage.getItem('flash')) || [];
  },

  write: function(store){
    sessionStorage.setItem('flash', JSON.stringify(store));
  },

  push: function(key, message){
    var store = flash.read();
    store.push({key: key, message: message});
    flash.write(store);
  },

  pop: function(key) {
    var store = flash.read();
    var value = store.splice(0, 1)[0];
    flash.write(store);
    return value;
  },

  clear: function(){
    flash.write([]);
  }
};

export default flash;
