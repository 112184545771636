import React from 'react'
import style from './style.css';
import _ from 'lodash';
import PropTypes from 'prop-types'

import AgentsListSection from '../AgentsListSection';

const NoResults = () => {
  return (
    <div className={style.zeroState}>
      <h4>No results found</h4>
    </div>
  )
}

class Component extends React.Component {

  handleAgentSelect = (agent) => {
    this.props.onAgentSelect(agent)
  }

  render () {
    const {props} = this
    let {search} = props;
    search = search.toLowerCase().trim();

    const searchResults = _.chain(props.agents)
      .filter((agent)=>{
        const searchString = `${agent.first_name} ${agent.last_name}`;

        return searchString.toLowerCase().indexOf(search) > -1;
      }).map((agent) =>{
        agent.isSelected = _.some(props.selectedAgents, {id: agent.id})
        return agent;
      }).value()

    return (
      <div className={style.wrapper}>
        {
          searchResults.length > 0 ?
            <AgentsListSection
              onToggle={this.handleAgentSelect}
              defaultValue={false}
              list={searchResults}
              highlight={search}
              fadeUnselected={true}
            /> :
            <NoResults id="agentsListSectionEmpty"/>
        }
      </div>
    )
  }

}

Component.propTypes = {
  search: PropTypes.string,
  agents: PropTypes.array,
  onAgentSelect: PropTypes.func.isRequired,
}

Component.defaultProps = {
  agents: []
}

export default  Component
