import React from 'react'
import styled from 'styled-components';
import EmptyMailbox from '../../img/i.svg';

export const Wrapper = styled.div`
    position: absolute;
    z-index: 999;
    left: 1px;
    right: 1px;
    top: 1px;
    bottom: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F9F9F9;
    flex-direction: column;
    h4 {
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #29302e;
      font-family: 'Open Sans';
      margin-top: 19px;
    }
    p {
      width: 326px;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #7a6769;
      font-family: 'Open Sans';
      margin-bottom: 16px;
      margin-top: 0;
    }
    .btn{
      border-radius: 4px;
      border: solid 1px #093bb4;
      background: transparent;
      font-family: 'Open Sans';
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: #0a3ab4;
    }
`;


class AgentDisconnected extends React.Component {

  handleClick = (event) => {
    event.preventDefault()
    window.location.reload()
  }

  render () {
    return (
        <Wrapper>
            <img src={EmptyMailbox} alt=""/>
            <h4>You Were Disconnected </h4>
            <p>Conversations in your queue have been reassigned to other agents. Refresh the page to begin receiving new conversations.</p>
            <button className="btn" onClick={this.handleClick}>
                Refresh
            </button>
        </Wrapper>
    )
  }

}

export default AgentDisconnected