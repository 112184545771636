import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment';
import { Box, Typography, Accordion, AccordionDetails, AccordionSummary, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { Button } from 'ui-elements';
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash'
// Internal
import Spinner from 'components/ui/pype-spinner/PypeSpinner';
import { formatPhoneNumber } from 'utils/format_phone';
import sdk from 'sdk';
import cx from 'classnames';
import Icon from 'components/ui/forms/Icon';

import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';

const { usersActions } = sdk;

const SHARED_KEY_VALUE_PAIR = [
  {
    value: "last_viewed_url",
    title: "Page URL",
    format: "link",
  },
  {
    value: "referring_site",
    title: "Website URL",
    format: "link",
  },
  {
    value: "screen_name",
    title: "Screen Name",
  },
  {
    value: "user_browser",
    title: "User Browser",
  },
  {
    value: "chat_id",
    title: "Conversation Id",
  },
  {
    value: "ip_address",
    title: "IP Address",
  },
  {
    value: "browser_language",
    title: "Browser Language",
    format: "lang",
  },
  {
    value: "platform",
    title: "Platform",
  },
  {
    value: "screen_resolution",
    title: "Screen Resolution",
  },
  {
    value: "id",
    title: "User Id",
  },
  {
    value: "location",
    title: "Location",
  },
  {
    value: "token",
    title: "Token",
  },
  {
    value: "creation_date",
    title: "Joined Pype",
    format: "time",
  },
  {
    value: "last_updated",
    title: "Last Updated",
    format: "time",
  },
  {
    value: "correlation_id",
    title: "Correlation Id",
  },

  {
    value: "status",
    title: "status",
    format: "status",
  },
];

const FULL_KEY_VALUE_PAIR = [
  {
    value: "country_alpha2",
    title: "country",
  },
];

const ExpandIcon = () => {
  return (
    <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="chevron-down" className="svg-inline--fa fa-chevron-down fa-w-14" width="16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M443.5 162.6l-7.1-7.1c-4.7-4.7-12.3-4.7-17 0L224 351 28.5 155.5c-4.7-4.7-12.3-4.7-17 0l-7.1 7.1c-4.7 4.7-4.7 12.3 0 17l211 211.1c4.7 4.7 12.3 4.7 17 0l211-211.1c4.8-4.7 4.8-12.3.1-17z"></path></svg>
  );
};

const useStyles = makeStyles({
  name: {
    fontFamily: 'Open Sans',
    fontSize: '20px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.2,
    letterSpacing: 'normal',
    color: '#29302e',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  userdata: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.43,
    letterSpacing: 'normal',
    color: '#716f6f',
  },
  summary: {
    width: '380px',
    height: '48px',
    padding: "16px 25px",
    backgroundColor: '#f9f9f9',
    ended: {
      backgroundColor: '#ffffff',
    }
  },
  header: {
    paddingLeft: '24px',
    paddingTop: '14px',
    marginBottom: '16px',
  },
  item: {
    width: '100%',
    position: 'relative',
    padding: '14px 24px',
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f9f9f9',
    },
  },
  itemTitle: {
    fontFamily: 'Open Sans',
    textTransform: 'capitalize',
    fontSize: '16px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: '#29302e'
  },
  overflowItemValue: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical"
  },
  buttonPos: {
    position: 'absolute',
    right: 24,
    bottom: 14,
    backgroundColor: '#f9f9f9',
    margin: 0
  },
  itemValue: {
    fontSize: '16px',
    color: '#29302e',
    wordWrap: 'break-word',
  },
  link: {
    color: '#0a3ab4'
  },
  categoryTitle: {
    fontFamily: 'Open Sans',
    fontSize: '16px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: '#0a3ab4',
  },
  ended: {
    color: '#716f6f'
  },
  accordianStyle: {
    boxShadow: 'none',
    '&::after': {
      backgroundColor: 'transparent'
    }
  },
  accordianDetailStyle: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column'
  },

});

const defaultDrawers = { Solution: true, Webpage: true, Technical: true, Crm: true }

const getUserInfoDrawerStates = (agentId) => {
  const localUserInfoData = localStorage.getItem('user_info_drawers');
  if (localUserInfoData) {
    const drawer_states = JSON.parse(localUserInfoData);
    if (drawer_states[agentId]) {
      return drawer_states[agentId];
    } else {
      drawer_states[agentId] = defaultDrawers;
      localStorage.setItem('user_info_drawers', JSON.stringify(drawer_states));
      return drawer_states[agentId];
    }
  } else {
    const drawer_obj = { [agentId]: defaultDrawers };
    localStorage.setItem('user_info_drawers', JSON.stringify(drawer_obj));
    return defaultDrawers;
  }
}

const UserProfile = (props) => {
  const { agentId, consumer } = props;
  const classes = useStyles();
  const [consumerDetails, setConsumerDetails] = useState(consumer);
  const [solutionPassThroughKeys, setSolutionPassThroughKeys] = useState([]);
  const [solutionExtraDataKeys, setSolutionExtraDataKeys] = useState([]);
  const [tooltipOpen, setTooltipOpen] = useState(null);
  const [accordianExpanded, setAccordianExpanded] = useState(() => getUserInfoDrawerStates(agentId));
  const [crmData, setCrmData] = useState(null);
  const [otherInfo, setOtherInfo] = useState(null);
  const [isCRMDataLoading, setIsCRMDataLoading] = useState(false);
  const [currentSelectedShowMore, setCurrentSelectedShowMore] = useState('');
  const [currentHover, setCurrentHover] = useState(null);
  const [responseButtons, setResponseButtons] = useState([]);
  const userInfoRef = useRef([]);
  userInfoRef.current = [];
  const { pe23803 } = useFlags();

  const addRefs = (el) => {
    if (el && !userInfoRef.current.includes(el)) {
      userInfoRef.current.push(el);
    }
  }

  const infoShowMore = () => {
    userInfoRef.current.forEach(el => {
      if (el.scrollHeight > el.clientHeight && !responseButtons.includes(el.id)) {
        setResponseButtons([...responseButtons, el.id])
      }
    })
  }
  const [position, setPosition] = useState({ x: undefined, y: undefined });

  useEffect(() => {
    if (otherInfo && otherInfo.length > 0) {
      let passThroughKeys = [];
      passThroughKeys = _(otherInfo)
        .filter((field) => (field.visible === 'wpm'))
        .sort((obj1, obj2) => {
          if (obj1.label.toLowerCase() < obj2.label.toLowerCase()) return -1
          else return 1;
        })
        .map((field, index) => {
          const { label, attribute } = field;
          let { value } = field;
          if (typeof value === 'object' && value !== null) {
            if(Array.isArray(value)) {
              value = value.filter((i) => typeof i === "object" && !Array.isArray(i)).reduce(
                (obj, item) => Object.assign(obj,{ ...item }), {});
            }
            const keys = Object.keys(value);
            const embed_values = keys.map(key => {
              if (typeof value[key] === "object") {
                if (Array.isArray(value[key])) {
                  if (value[key].length > 0) {
                    return { title: key, value: JSON.stringify(value[key]) }
                  }
                }
                else {
                  if (Object.keys(value[key]).length > 0) {
                    return { title: key, value: JSON.stringify(value[key]) }
                  }
                }
                return { title: key, value: null }
              }
              else {
                return { title: key, value: value[key] };
              }
            });
            return embed_values
          }
          return { title: label || attribute, value: value }
        }).value();
      passThroughKeys = [].concat.apply([], passThroughKeys);
      if (passThroughKeys.length > 0) {
        let passThroughObject = passThroughKeys.filter(i=> {return !!i.value}).reduce(
          (obj, item) => Object.assign(obj, { [item.title]: item.value }), {});
        const solution_passthrough_keys = Object.keys(passThroughObject);
        passThroughObject = { ...consumer, ...passThroughObject };
        setConsumerDetails(passThroughObject);
        keyValuePair = [...keyValuePair, ...passThroughKeys];
        setSolutionPassThroughKeys(solution_passthrough_keys);
      }
    }
  }, [otherInfo]);

  useEffect(() => {
    if (extraData && extraData.length > 0) {
      const keys = Object.keys(extraData);
      let extraKeys = [];
      extraKeys = keys.sort().map((nestedKey, i) => {
        const value = extraData[nestedKey]
        return { title: nestedKey, value: typeof value === 'object' ? JSON.stringify(value) : value };
      });
      if (extraKeys.length > 0) {
        let extraDataObject = extraKeys.reduce(
          (obj, item) => Object.assign(obj, { [item.title]: item.value }), {});
        const solution_extraData_keys = Object.keys(extraDataObject);
        extraDataObject = { ...consumer, ...extraDataObject };
        setConsumerDetails(extraDataObject);
        keyValuePair = [...keyValuePair, ...extraKeys];
        setSolutionExtraDataKeys(solution_extraData_keys);
      }
    }
  },
    [extraData]);

  const userInfoCategories = [
    {
      title: "Obsolete",
      children: [
        'avatar',
        'avatar_generated',
        'first_name',
        'last_name',
        'email',
        'phone',
        'passthrough',
        'status',
        'correlation_id',
        'creation_date',
        'last_updated',
        'token',
        'extra_data'
      ]
    },
    {
      title: "Solution",
      children: [
      ]
    },
    {
      title: "Webpage",
      children: [
        'last_viewed_url',
        'referring_site',
        'screen_name'
      ]
    },
    {
      title: "Technical",
      children: [
        'user_browser',
        'chat_id',
        'ip_address',
        'browser_language',
        'platform',
        'screen_resolution',
        ...(pe23803 ? [] : ["country_alpha2"]),
        'id'
      ]
    }
  ];

  let keyValuePair = [
    ...SHARED_KEY_VALUE_PAIR,
    ...(pe23803 ? [] : FULL_KEY_VALUE_PAIR),
  ]

  useEffect(() => {

    const { crmURL } = props;
    const url = `${crmURL}/token/${consumer.token}`
    infoShowMore();
    if (crmURL) {
      setIsCRMDataLoading(true);
      props.fetchCRMData(url).then((data) => {
        if (isValidJSON(data)) {
          setCrmData(data);
        } else {
          console.error("Invalid JSON format")
        }
        setIsCRMDataLoading(false);
      }).catch((err) => {
        console.error("CRM endpoint unavailable")
        setIsCRMDataLoading(false);
      });
    }
    if (consumer.passthrough) {
      try {
        const otherInfo = JSON.parse(consumer.passthrough)

        if (isValidJSON(otherInfo)) {
          setOtherInfo(otherInfo);
        } else {
          console.error('Invalid JSON format for passthrough')
        }
      } catch (e) {
        if (props.flags.newUserInfo && typeof consumer.passthrough === "object") {
          if (isValidJSON(consumer.passthrough)) {
            setOtherInfo(consumer.passthrough);
          } else {
            console.error('Invalid JSON format for passthrough')
          }
        }
        else {
          console.error('passthrough value format invalid');
        }
      }

    }
  }, []);

  useEffect(() => {
    if (currentHover) {
      infoShowMore();
    }
  }, [currentHover]);

  const getPhone = () => {
    let phone
    const { consumer } = props;

    if (!consumer || !consumer.phone) return null;

    try {
      phone = formatPhoneNumber(consumer.phone)
    }
    catch (error) {
      console.log(error)
    }
    return phone
  }

  const isValidJSON = (json) => {
    return _.isArray(json) && _.every(json, (field) => {
      return typeof field.attribute !== 'undefined'
        && typeof field.value !== 'undefined'
        && typeof field.label !== 'undefined'
    })
  }

  const handleClick = (e, x, y) => {
    setPosition({ x: e.pageX, y: e.pageY });
    if (tooltipOpen && tooltipOpen !== y) {
      setTooltipOpen(null);
    }
    let copiedText = formatData(y, x)
    navigator.clipboard.writeText(copiedText);
    setTooltipOpen(y)
    setTimeout(() => {
      setTooltipOpen(tooltipOpen => tooltipOpen && tooltipOpen === y ? null : tooltipOpen)
    }, 1000);
  }

  const toggleShowMore = (id) => {
    if (id === currentSelectedShowMore) {
      setCurrentSelectedShowMore('');
    }
    else {
      setCurrentSelectedShowMore(id);
    }
  }

  const handleShowClick = (e, id) => {
    e.stopPropagation();
    toggleShowMore(id)
  }

  const handleAccordionChange = (category) => (e, expanded) => {
    const currentAccordianExpanded = accordianExpanded;
    currentAccordianExpanded[category] = !accordianExpanded[category];
    setAccordianExpanded({ ...currentAccordianExpanded });
    const localUserInfoData = localStorage.getItem('user_info_drawers');
    const drawer_states = JSON.parse(localUserInfoData);
    drawer_states[agentId] = currentAccordianExpanded;
    localStorage.setItem('user_info_drawers', JSON.stringify(drawer_states));
  }
  /*
  WPM expects CRM data to be in format: 
  [
    {
      attribute: <string>,
      label: <string>,
      value: <string|number|bool>
    },
    ...
  ]
  */
  const renderCRMData = (data) => {
    return data.map((field, index) => {
      // if null, undefined or empty don't display
      if (_.isNil(field.value) || field.value === '') return null;
      return <li key={index}><span>{field.label || field.attribute}</span><span /><span>{field.value}</span></li>
    })
  }

  const getSolutionData = (data) => {
    if (props.flags.newUserInfo) {
      let availableKeys = userInfoCategories;
      availableKeys = availableKeys.filter(item => item.title !== "Obsolete");
      availableKeys = availableKeys.map((element) => {
        return { ...element, children: element.children.filter((subElement) => data[subElement]) }
      });
      if (otherInfo || extraData) {
        if (otherInfo) {
          if (otherInfo.length > 0 && solutionPassThroughKeys.length > 0) {
            availableKeys.find(x => x.title === 'Solution').children = [...availableKeys.find(x => x.title === 'Solution').children, ...solutionPassThroughKeys];
          }
        }
        if (extraData) {
          const extraDataKeys = Object.keys(extraData);
          extraDataKeys.forEach(key => data[key] = typeof extraData[key] === 'object' ? JSON.stringify(extraData[key]) : extraData[key]);
          availableKeys.find(x => x.title === 'Solution').children = [...availableKeys.find(x => x.title === 'Solution').children, ...extraDataKeys];
        }
      }
      return availableKeys;
    }
  }

  const renderLinkData = (item, value) => {
    let itemFormat = keyValuePair.find(x => x.value === item);
    if (itemFormat) {
      switch (itemFormat.format) {
        case "link":
          return <a href={value} target="_blank" rel="noreferrer" ><Typography className={`${classes.itemValue} ${classes.link}`}>{value}</Typography></a>
        default:
          return <Typography className={`${classes.itemValue}`} >{value}</Typography>
      }
    }
    return <Typography className={`${classes.itemValue}`}>{value}</Typography>
  }

  const formatData = (item, value) => {
    let itemFormat = keyValuePair.find(x => x.value === item);
    if (itemFormat) {
      switch (itemFormat.format) {
        case "time":
          return moment(value).format('MM/DD/YYYY');
        case "lang":
          return value.split(',')[0];
        case "status":
          return value === "active" ? "Connected" : value;
        default:
          return value
      }
    }
    return value;
  }

  const renderNestedOtherInfo = (key, sectionTitle, data) => {
    const keys = Object.keys(data)

    return (
      <React.Fragment key={key}>
        <li><span><strong>{sectionTitle}</strong></span></li>
        {keys.map((nestedKey, i) => {
          const value = data[nestedKey]
          return <li key={`${key}-${i}`}><p className="--title">{nestedKey}</p><p className="--body">{typeof value === 'object' ? JSON.stringify(value) : value}</p></li>
        })}
      </React.Fragment>
    )
  }

  const renderOtherInfo = (data) => {
    return _(data)
      .filter((field) => (field.visible === 'wpm'))
      .sort((obj1, obj2) => {
        if (obj1.label.toLowerCase() < obj2.label.toLowerCase()) return -1
        else return 1;
      })
      .map((field, index) => {
        const { value, label, attribute } = field

        if (typeof value === 'object' && value !== null) {
          return renderNestedOtherInfo(index, label, value);
        }
        return <li key={index}><p className="--title">{label || attribute}</p><p className="--body">{value}</p></li>
      }).value()
  }

  const renderExtraData = (data) => {
    const key = 'extra_data';
    const keys = Object.keys(data);
    return keys.sort().map((nestedKey, i) => {
      const value = data[nestedKey]
      return <li key={`${key}-${i}`}><p className="--title-extra">{nestedKey}</p><p className="--body">{typeof value === 'object' ? JSON.stringify(value) : value}</p></li>
    })
  }

  consumer.chat_id = props.chatId;
  if (consumer && consumer.ip_address && consumer.ip_address.split('.').length < 2) {
    consumer.ip_address = null;
  }

  const phone = getPhone();
  const crmLabel = props.crmLabel ? props.crmLabel : 'CRM Data';
  const extraData = consumer.extra_data;
  const newUserInfoCategories = getSolutionData(consumer);
  return (
    <>
      {props.flags.newUserInfo ?
        <Box>
          <Box className={classes.header}>
            <Typography className={classes.name}>
              {consumerDetails.first_name} {consumerDetails.last_name}
            </Typography>
            {consumerDetails.email && <Typography className={classes.userdata}>{consumerDetails.email}</Typography>}
            {consumerDetails.phone && <Typography className={classes.userdata}>{phone}</Typography>}
          </Box>
          <Box>
            {newUserInfoCategories.flatMap((item) =>
              (item.children.length < 1) ? null :
                <Accordion classes={{ root: classes.accordianStyle }}
                  defaultExpanded={accordianExpanded[item.title]}
                  onChange={handleAccordionChange(item.title)}
                >
                  <AccordionSummary
                    className={classes.summary.ended}
                    expandIcon={<ExpandIcon />}
                  >
                    <Typography className={`${classes.categoryTitle} ${!accordianExpanded[item.title] && classes.ended}`}>{item.title} {`(${item.children.length})`}</Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordianDetailStyle}>
                    {item.children && item.children.map(details =>
                      <Box>
                        {consumerDetails[details] &&
                          <Tooltip
                            title={"Copied!"}
                            aria-label="copied!"
                            placement="top"
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            leaveDelay={1000}
                            open={tooltipOpen === details}
                            PopperProps={{
                              anchorEl: {
                                clientHeight: 0,
                                clientWidth: 0,
                                getBoundingClientRect: () => ({
                                  top: position.y,
                                  left: position.x,
                                  right: position.x,
                                  bottom: position.y,
                                  width: 0,
                                  height: 0,
                                })
                              }
                            }}
                          >
                            <Box className={classes.item} onClick={(e) => handleClick(e, consumerDetails[details], details)} onMouseEnter={() => setCurrentHover(details)} onMouseLeave={() => setCurrentHover(null)}>
                              <Typography className={classes.itemTitle}>{keyValuePair.find(x => x.value === details) ? keyValuePair.find(x => x.value === details).title : details}</Typography>
                              <Box id={`info-${details}`} className={currentSelectedShowMore !== details ? classes.overflowItemValue : null} ref={addRefs}>
                                {renderLinkData(details, formatData(details, consumerDetails[details]))}
                                {currentHover === details && responseButtons.includes(`info-${details}`) && <Button className={classes.buttonPos} hollow small onClick={(e) => handleShowClick(e, details)}>{currentSelectedShowMore === details ? 'Show Less' : 'Show More'}</Button>}
                              </Box>
                            </Box>
                          </Tooltip>
                        }
                      </Box>
                    )}

                  </AccordionDetails>
                </Accordion>
            )}
            {crmData && props.flags.showCrmData &&
            <Accordion classes={{ root: classes.accordianStyle }}
              defaultExpanded={accordianExpanded['Crm']}
              onChange={handleAccordionChange('Crm')}
            >
              <AccordionSummary
                className={classes.summary.ended}
                expandIcon={<ExpandIcon />}
              >
                <Typography className={`${classes.categoryTitle} ${!accordianExpanded['Crm'] && classes.ended}`}>{crmLabel} {`(${crmData.filter(d => d.value && d.value !== "null").length})`}</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordianDetailStyle}>
                {crmData.map(details =>
                  <Box>
                    {details.value && details.value !== "null" &&
                      <Tooltip
                        title={"Copied!"}
                        aria-label="copied!"
                        placement="top"
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        leaveDelay={1000}
                        open={tooltipOpen === (details.label || details.attribute)}
                        PopperProps={{
                          anchorEl: {
                            clientHeight: 0,
                            clientWidth: 0,
                            getBoundingClientRect: () => ({
                              top: position.y,
                              left: position.x,
                              right: position.x,
                              bottom: position.y,
                              width: 0,
                              height: 0,
                            })
                          }
                        }}
                      >
                        <Box className={classes.item} onClick={(e) => handleClick(e, details.value, (details.label || details.attribute))} onMouseEnter={() => setCurrentHover(details.label || details.attribute)} onMouseLeave={() => setCurrentHover(null)}>
                          <Typography className={classes.itemTitle}>{details.label || details.attribute}</Typography>
                          <Box id={`info-${details.label || details.attribute}`} className={currentSelectedShowMore !== details.label || details.attribute ? classes.overflowItemValue : null} ref={addRefs}>
                            {details.value}
                            {currentHover === details.label || details.attribute && responseButtons.includes(`info-${details.label || details.attribute}`) && <Button className={classes.buttonPos} hollow small onClick={(e) => handleShowClick(e, details.label || details.attribute)}>{currentSelectedShowMore === details.label || details.attribute ? 'Show Less' : 'Show More'}</Button>}
                          </Box>
                        </Box>
                      </Tooltip>
                    }
                  </Box>
                )}

              </AccordionDetails>
            </Accordion>}
          </Box>
        </Box>
        :
        <div className={cx('user-data', props.className)}>
          <div className="sub-header-wrapper">
            <div>
              <Icon src={consumer.avatar} className="avatar-icon" />
            </div>
            <div className="m-t-8">
              <h4 style={{ margin: 0, marginBottom: '40px' }}>
                {consumer.first_name} {consumer.last_name}
              </h4>
            </div>
          </div>

          {
            <div className="user-info-subsection">
              <ul className="">
                <li>
                  <p className={`--title ${!consumer.email ? '--title-null' : ''}`}>Email</p>
                  <p className='--body'>{consumer.email || '—'}</p>
                </li>
                <li>
                  <p className={`--title ${!consumer.id ? '--title-null' : ''}`}>User ID</p>
                  <p className="--body">{consumer.id || '—'}</p>
                </li>
                {otherInfo ? (
                  renderOtherInfo(otherInfo)
                ) : null}
                {extraData ? (
                  renderExtraData(consumer.extra_data)
                ) : null}
              </ul>
            </div>
          }

          <div className="user-info-subsection">
            <h5 style={{
              fontWeight: 600,
              fontSize: '18px',
              fontFamily: 'Open Sans',
              lineHeight: 1.33,
            }}>General Info</h5>
            <ul className="">
              {phone ?
                <li>
                  <p className="--title">Phone number</p>
                  <span className="--body">{phone}</span>
                </li> : null}
              <li>
                <p className="--title">Joined Pype</p>
                <p className="--body">{moment(consumer.creation_date).format('MM/DD/YYYY')}</p>
              </li>
              <li>
                <p className="--title">Status</p>
                <p className="--body">{consumer.status === 'active' ? 'Connected' : consumer.status}</p>
              </li>
              {consumer.ip_address ?
                <li>
                  <p className="--title">IP Address</p>
                  <p className="--body">{consumer.ip_address}</p>
                </li> : null}
              {consumer.user_browser ?
                <li>
                  <p className="--title">User Browser</p>
                  <span className="--body">{consumer.user_browser}</span>
                </li> : null}
              {consumer.last_viewed_url ?
                <li style={{ display: 'block' }}>
                  <p className="--title">Last viewed URL</p>
                  <p className="--body">
                    <a href={consumer.last_viewed_url}>{consumer.last_viewed_url}</a>
                  </p>
                </li> : null}
              {consumer.platform ?
                <li>
                  <p className="--title">Platform</p>
                  <p className="--body">{consumer.platform}</p>
                </li> : null}
              {consumer.browser_language ?
                <li>
                  <p className="--title">Browser Language</p>
                  <p className="--body">{consumer.browser_language.split(',')[0]}</p>
                </li> : null}
              {consumer.location ?
                <li>
                  <p className="--title">Location</p>
                  <p className="--body">{consumer.location}</p>
                </li> : null}
              {consumer.referring_site ?
                <li style={{ display: 'block' }}>
                  <p className="--title">Referring Site</p>
                  <p className="--body">
                    <a href={consumer.referring_site}>{consumer.referring_site}</a>
                  </p>
                </li> : null}
              {consumer.screen_resolution ?
                <li>
                  <p className="--title">Screen Resolution</p>
                  <p className="--body">{consumer.screen_resolution}</p>
                </li> : null}
            </ul>
          </div>

          {crmData ? (
            <div className="user-info-subsection">
              <span>{crmLabel}</span>
              <ul className="data-ellipsis-list">
                {renderCRMData(crmData)}
              </ul>
            </div>) : null
          }
          {isCRMDataLoading ? <Spinner caption={false} /> : null}
        </div>
      }
    </>
  )

}

UserProfile.propTypes = {
  consumer: PropTypes.object.isRequired,
}

const mapStateToProps = (state, props) => ({
  crmURL: state.pype.details.crm_url,
  crmLabel: state.pype.details.crm_label,
  agentId: state.agent.id
})

const mapDispatchToProps = (dispatch) => {
  const actionCreators = bindActionCreators({
    fetchCRMData: usersActions.fetchCRMData,
  }, dispatch);

  return actionCreators;
};

const UserProfileWithFeatureFlags = withLDConsumer()(UserProfile);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfileWithFeatureFlags)
