import { BOT_USER_SESSION_LOGOUT } from "bot-user-session";
import {
  sessionConstants
} from "../constants"

const getInitialState = () => ({
  roles: [],
});

export default function users(state=getInitialState(), action) {
  switch (action.type) {
    case BOT_USER_SESSION_LOGOUT: {
      return getInitialState();
    }
    case sessionConstants.UPDATE_SESSION:
      return {
        ...state,
        ...action.data
      }
    default:
      return state
  }
}
