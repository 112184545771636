import React from 'react'
import PropTypes from 'prop-types'

import LabelInput from 'components/ui/forms/LabelInput';

import style from './style.css'
import {
  Row,
  Value,
  Label,
  Spacer
} from './helpers';

const Component = ({
  firstName='',
  lastName='',
  email='',
  chatLimit,
  errors,
  bindValidator,
  handleChatLimitChange
}) => {
  return (
    <div className={style.wrapper}>
        <Row>
          <Label>First name</Label>
          <Value className="first-name">{firstName}</Value>
        </Row>

        <Row>
          <Label>Last name</Label>
          <Value className="last-name">{lastName}</Value>
        </Row>

        <Row>
          <Label>Email</Label>
          <Value className="email">{email}</Value>
        </Row>
        <Spacer/>
        <LabelInput type="number" className="chat-limit m-t-10" placeholder="10" value={chatLimit} error={errors ? errors.chatLimit : null}
          onChange={handleChatLimitChange} onBlur={bindValidator('chatLimit')}>
          <div>Chat limit</div>
          <div className="descript">
            The maximum number of chats allowed in an agent’s queue at one time.
          </div>
        </LabelInput>
      </div>
  )
}

Component.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  chatLimit: PropTypes.number,
  errors: PropTypes.object,
  bindValidator: PropTypes.func.isRequired,
  handleChatLimitChange: PropTypes.func.isRequired,
}

export default Component;
