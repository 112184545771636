import React from 'react'
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import Modal from 'react-modal';
import sdk from 'sdk';
import SaveButton from '../../ui/forms/SaveButton';
import activityTracker from "../../../utils/activityTracker";

const {tagActions} = sdk;

export class DeleteTagModal extends React.Component {

  constructor() {
    super()
    this.state = {
      isSubmitting: false,
    }
  }

  handleSuccess() {
    const { customerName } = this.props;
    
    window.appCreateToast({
      message: 'Tag successfully deleted.',
      level: 'success',
    })
    activityTracker.logEvent(activityTracker.eventTypeNames.UPDATE_PYPE_SETTING,{settingName: 'tags',action: activityTracker.eventActionType.DELETE, customerName});
  }

  handleError() {
    window.appCreateToast({
      message: 'Error deleting tag.',
      level: 'error',
    })
  }

  handleSubmit = () => {
    this.setState({isSubmitting: true})
    this.props.onSubmit().then(() => {
      this.handleSuccess()
    }).catch((error) => {
      console.warn(error)
      this.setState({isSubmitting: false})
      this.handleError()
    })
  }

  handleClose = () => {
    if (!this.state.isSubmitting) {
      this.props.closeModal()
    }
  }

  render() {
    const {props, state} = this
    return (
      <Modal data-test="delete-tag-modal" ariaHideApp={false} className="delete-tag-modal" isOpen={true} onRequestClose={this.handleClose}>
        <div className="modal-content">
          <div className="modal-header">
            Confirm delete tag
            <button data-test="delete-button" type="button" className="close" onClick={this.handleClose}>
              <img src={`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-modal-close.svg`} width="15" height="15" />
            </button>
          </div>
          <div className="modal-body">
            Are you sure you want to delete this tag?
          </div>
          <div className="modal-footer">
            <button className="btn btn-default" onClick={props.closeModal}>Cancel</button>
            <SaveButton data-test="save-button" className="btn-danger" disabled={state.isSubmitting} isLoading={state.isSubmitting} onClick={this.handleSubmit}>
              Delete
            </SaveButton>
          </div>
        </div>
      </Modal>
    )
  }

}

DeleteTagModal.propTypes = {
  tag: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  customerName: state.pype.details.customerName
});

const mapDispatchToProps = (dispatch, props) => ({
  onSubmit () {
    return dispatch(tagActions.destroyTag(props.tag.id))
  },
})

export default  connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteTagModal)
