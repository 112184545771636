import style from './style.css'
import React from 'react'
import PropTypes from 'prop-types'
import Message from '../Message'
import SmartButton from '../SmartButton'

const SmartButtons = (props) => (
  <div className={style.SmartButtons}>
    <Message className={style.Message} from='bot' isLastInGroup>
      {props.label}
    </Message>
    <div className={style.Buttons}>
      {props.options.map((option) => (
        <SmartButton
          key={option.value}
        >
          {option.label}
        </SmartButton>
      ))}
    </div>
  </div>
)

SmartButtons.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
}

export default  SmartButtons
