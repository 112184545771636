import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 22px;
  background: #fbf5df;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 90px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
`;

export const Label = styled.div`
  font-size: 15px;
  max-width: 305px;
`;

export const ButtonsGroup = styled.div`
  width: 180px;
  text-align: center;
  .btn-default{
    padding: 4px 12px;
  }
  .btn-link{
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #2e7de1;
    text-decoration: underline;
  }
`;