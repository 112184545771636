import {connect} from 'react-redux'
import Component from './Component'
import sdk from 'sdk';
const {pypeActions} = sdk;

const mapStateToProps = (state) => ({
    pypeID: state.pype.details.id,
    CRMTabName: state.pype.details.crm_label || '',
    CRMurl: state.pype.details.crm_url || '',
    customerName: state.pype.details.customerName
});

const mapDispatchToProps = (dispatch) => ({
  updatePype (data) {
    return dispatch(pypeActions.updatePype(data))
  }

})

export default  connect(
  mapStateToProps,
  mapDispatchToProps
)(Component)