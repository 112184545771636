import React from 'react'
import cx from 'classnames';

const SubHeader = (props) => (
  <div className={cx("sub-header", props.className)}>
    <h5 style={{marginTop: 0}}>{props.children}</h5>
    <hr />
  </div>
);

export default  SubHeader;
