import {
  REQUEST_BOT_STATS, RECEIVE_BOT_STATS
} from "../constants"

import SDK from '../SDK'

// Bots stats
function requestBotsStats() {
  return {
    type: REQUEST_BOT_STATS
  }
}

export function receiveBotsStats(json) {
  return {
    type: RECEIVE_BOT_STATS,
    bots: json.list
  }
}

export const fetchBotsStats = () => (dispatch, getState) => {
  const pype_id = getState().pype.details.id;
  
  dispatch(requestBotsStats())

  return SDK.getBotMetrics({
    pype_id
  }).then((data) => {
    dispatch(receiveBotsStats(data))
  })
}

