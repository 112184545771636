import style from './style.css';
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames';

const SendButton = (props) => (
  <button
    type='submit'
    className={classNames(style.SendButton, props.className, {
      [style.isDisabled]: props.disabled,
    })}
    disabled={props.disabled}>
    <span className={style.Text}>Send</span>
  </button>
)

SendButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
}

SendButton.defaultProps = {
  className: '',
  disabled: false,
}

export default  SendButton
