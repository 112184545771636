// External imports
import React from 'react'
// Local imports
import style from './style.css';
import DetailPane from '../../details/DetailPane';
import TitleBar from '../../details/TitleBar';
import ContentBody from '../../details/ContentBody';
import ApiList from '../ApiList';
import activityTracker from "../../../../utils/activityTracker";
import ErrorBoundary from 'components/ui/ErrorBoundary';


class PypestreamApi extends React.Component {
    componentDidMount() {
        const { customerName } = this.props

        activityTracker.logEvent(activityTracker.eventTypeNames.VIEW_PYPE_SETTING,{settingName: 'api keys', customerName});
    }

    render() {
    return (
      <DetailPane className="chat-logs-detail">
          <ContentBody>
              <div className={style.detailContainer}>
                <h5>API Keys</h5>
                  <p>
                    The Pypestream REST API allows you to register users through our SDK and send event based broadcasts.
                    <span> </span><a href="https://apidocs.pypestream.com" target="_blank" rel="noopener noreferrer" >Learn about Pypestream REST API.</a>
                  </p>
              </div>
              <ErrorBoundary>
                <ApiList />
              </ErrorBoundary>
          </ContentBody>

      </DetailPane>
    )
  }
}

export default  PypestreamApi
