import _ from 'lodash'

import {
  REQUEST_CONVERSATIONS,
  RECEIVE_CONVERSATIONS,
  APPEND_MESSAGE_TO_CONVERSATION,
  REMOVE_UNREAD_MSG,
  ADD_UNREAD_MSG,
  CLASSIFY_CHAT,
  END_CONVERSATION,
  SORT_CONVERSATIONS,
  RECENTLY_ENDED_CHAT_FOR_CLASSIFICATION,
  ENDED_NOT_CLASSIFIED_CHAT_SESSION,
  ENDED_AND_CLASSIFIED_CHAT_SESSION,
  REMOVE_ENDED_NOT_CLASSIFIED_CHAT_SESSION,
  SET_MESSAGE_TIMER,
  LAST_READ_MESSAGE,
  SET_CLOSE_TO_CHAT_END_ID,
} from "./../constants"

import {endChatSession} from './agentChatSessions'
import utils from '../utils'
import activityTracker from "../../utils/activityTracker";

import SDK from '../SDK'

// Conversations
function requestConversations(more) {
  return {
    type: REQUEST_CONVERSATIONS,
    more
  }
}

export function receiveConversations(req_id, json) {
  return (dispatch, getState) => {
    const userId = getState().botUserSession.user.user_id

    return {
      req_id,
      type: RECEIVE_CONVERSATIONS,
      conversations: json,
      receivedAt: Date.now(),
      more: json.more,
      userId
    }
  }
}

export function appendMessageToConversation(message) {
  return {
    type: APPEND_MESSAGE_TO_CONVERSATION,
    message,
    receivedAt: Date.now()
  }
}

// Remove unread message from store
export function removeUnreadMessage(data) {
  return {
    type: REMOVE_UNREAD_MSG,
    receipt: data
  }
}

// When new message arrive mark it as unread
export function addUnreadMessage(data) {
  return {
    type: ADD_UNREAD_MSG,
    message: data
  }
}

export function sortConversations() {
  return {
    type: SORT_CONVERSATIONS
  }
}

export function classifyChat(data,request) {
  return (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      const state = getState();
      const submitData = {...data}
      
      if (!_.isEmpty(data.end_tags)) {
        submitData.end_tags = data.end_tags.map((tagId) => {
          const tag = state.tags.store[tagId]
          return utils.tags.getTagUid(tag)
        })
      }

      SDK.classifyChat(submitData).then(() => {

        dispatch(endedAndClassifiedChatSessions(submitData.chat_id));
        activityTracker.logEvent(activityTracker.eventTypeNames.CLASSIFY_CONVERSATION,{type: submitData, environment: state.pype.details.env});

        dispatch({
          type: CLASSIFY_CHAT,
        })

        resolve()
      }, () => reject());

      
    })
  }
}

export const endConversation = (id) => (dispatch, getState) => {
  dispatch({type: END_CONVERSATION, id})
  dispatch(endChatSession(id))
  return SDK.endConversation({
    chat_id: id,
    ended_by: getState().botUserSession.user.user_id,
  })
}

export const recentlyEndedChatForClassification = (endedChatForClassification) => (dispatch, getState) => {
  dispatch({type: RECENTLY_ENDED_CHAT_FOR_CLASSIFICATION, endedChatForClassification})
}

export const endedNotClassifiedChatSessions = (endedNotClassifiedChat) => (dispatch, getState) => {
  dispatch({type: ENDED_NOT_CLASSIFIED_CHAT_SESSION, endedNotClassifiedChat});
}

export const endedAndClassifiedChatSessions = (endedAndClassifiedChat) => (dispatch, getState) => {
  dispatch({type: ENDED_AND_CLASSIFIED_CHAT_SESSION, endedAndClassifiedChat});
}

export const removeEndedNotClassifiedChatSessions = () => (dispatch, getState) => {
  dispatch({type: REMOVE_ENDED_NOT_CLASSIFIED_CHAT_SESSION });
}

export function setChatTimer(response) {
  return (dispatch, getState) => {
    dispatch({
      type: SET_MESSAGE_TIMER,
      payload: response
    });
  };
};

export function setLastReadMessage(response) {
  return (dispatch, getState) => {
    dispatch({
      type: LAST_READ_MESSAGE,
      payload: response
    });
  };
};

export function setCloseToChatEndId(response) {
  return (dispatch, getState) => {
    dispatch({
      type: SET_CLOSE_TO_CHAT_END_ID,
      payload: response
    })
  }
}