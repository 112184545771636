import {connect} from 'react-redux';
import Component from './Component';
import { bindActionCreators } from 'redux';
import sdk from 'sdk'
const { apiActions } = sdk

const mapDispatchToProps = (dispatch) => {
  const actionCreators = bindActionCreators({
    fetchApi: apiActions.fetchApi,
  }, dispatch);

  return actionCreators;
};

const mapStateToProps = (state) => ({
  apisStore: state.apis.store,
  pypeName: state.pype.details.name
})
export default  connect(mapStateToProps, mapDispatchToProps)(Component)
