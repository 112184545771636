import React from 'react'
import {connect} from 'react-redux';

import _ from 'lodash';
import sdk from 'sdk';
import AppTooltip from 'components/shared/AppTooltip';

const {agentActions, utils} = sdk;

class AgentAway extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      isOffHours: this.props.isOffHours
    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.isOffHours != this.props.isOffHours){
      this.setState({isOffHours: nextProps.isOffHours})
    }
  }

  handleClick = (event) => {
    event.preventDefault()
    this.props.onSetStatus()
  }

  render () {
    const linkLabel = "Change status back to online";
    const {isOffHours} = this.state;
    return (
      <div className="agent-status-card agent-offline-notification">
        <div className="inner-content">
          <h3>You are currently marked as offline.</h3>
          <p>
            You will not receive any new chats in your queue until your status is changed to online.
          </p>
          {isOffHours ? 
            <AppTooltip tooltipKey="agent.status" place="bottom">
              <a onClick={_.noop}>{linkLabel}</a>
            </AppTooltip> : 
            <a onClick={this.handleClick}>
              {linkLabel}
            </a>
          }
        </div>
      </div>
    )
  }

}

const mapStateToProps = (state) => {
  return {
    isOffHours: state.agent.isOffHours
  }
}

const mapDispatchToProps = (dispatch) => ({
  onSetStatus() {
    dispatch(agentActions.setStatus('online'))
  },
})

export default  connect(
  mapStateToProps,
  mapDispatchToProps
)(AgentAway)
