import {transfersConstants} from '../constants'

export const updateTransferNotification = (data) => (dispatch, getState) => {
  const state = getState();
  const {participant_history, consumer_name, participants} = data;
  const isParticipant = participants.agent === state.botUserSession.user.user_id;

  if(!isParticipant) return;

  const assignor = participant_history[2].participant_type === 'bot' ? participant_history[2].user_id : participant_history[2].agent_name
  const transferNotification = {
    assignor,
    consumerName: consumer_name,
    timestamp: Date.now()
  };

  dispatch({
    type: transfersConstants.UPDATE_NOTIFICATION,
    transferNotification,
  });
};
