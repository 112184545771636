// External
import React from 'react'
import PropTypes from 'prop-types'

import cx from 'classnames';

// Local
import SubHeader from '../details/SubHeader';

// UI
import AppTooltip from 'components/shared/AppTooltip';
import LabelCheckbox from '../../ui/forms/LabelCheckbox';

class OverallConfig extends React.Component {

  handleKeyDown = (event) => {
    if (event.keyCode === 13 || event.charCode === 13) {
      this.props.onSubmit()
    }
  }

  handleFocus = (event) => {
    this.refs.input.select()
  }

  render() {

    const {props, state} = this

    return (
      <div className={cx('overall-config', {'is-submitting': props.isSubmitting})}>

        <section className="header-section">
          <SubHeader>Configure setup</SubHeader>
          <section>
            <p>
              Allow your agents to add tags at the end of a
              chat. Choosing ‘Required’ indicates an agent action that
              must be completed.
            </p>
          </section>
        </section>

        <section className="options-section">
          <section className="tag-config-section">
            <LabelCheckbox value={props.enableTags} disabled={props.isSubmitting} onClick={props.onToggleEnableTags}>
              Add tag(s)
            </LabelCheckbox>
            <LabelCheckbox value={props.requireTags} disabled={!props.enableTags || props.isSubmitting} onClick={props.onToggleRequireTags}>
              Required
            </LabelCheckbox>
          </section>
          <section>
            <div className={cx('input-with-addon', {'has-error': props.categoryNameError, 'feature-disabled': !props.enableTags})}>
              <label className="label-input">
                <div className="label-text">Tag category</div>
                <div ref="inputWithAddon" className="input-with-addon-wrapper">
                  <input ref="input" type="text" value={props.categoryName} placeholder="Resolution codes" maxLength={30}
                    disabled={props.isSubmitting}
                    onKeyDown={this.handleKeyDown}
                    onChange={props.onTagCategoryChange}
                    onFocus={this.handleFocus} />
                  <div className="addon-text">{props.categoryName.length}/30</div>
                </div>
                {props.categoryNameError ?
                  <p className="small text-danger m-b-0">{props.categoryNameError}</p> : null}
              </label>
            </div>
          </section>
          <section className={cx('checkbox-section', {'feature-disabled': !props.enableTags})}>
            <LabelCheckbox value={props.enableTagCodes}
              disabled={props.isSubmitting}
              onClick={props.onToggleTagCodesEnabled}>
              Enable tag codes
              <AppTooltip tooltipKey="admin_tab.enable_tag_codes" place="top" />
            </LabelCheckbox>
            <LabelCheckbox className="toggle-multiple-tags" label="Allow agents to select multiple tags" value={props.enableMultipleTags}
              disabled={props.isSubmitting}
              onClick={props.onToggleEnableMultipleTags} />
          </section>
        </section>

      </div>
    )

  }

}

OverallConfig.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  categoryNameError: PropTypes.any,  // undefined or string
  categoryName: PropTypes.string.isRequired,
  enableTagCodes: PropTypes.bool.isRequired,
  enableMultipleTags: PropTypes.bool.isRequired,
  onToggleEnableTags: PropTypes.func.isRequired,
  onToggleRequireTags: PropTypes.func.isRequired,
  onToggleTagCodesEnabled: PropTypes.func.isRequired,
  onTagCategoryChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default  OverallConfig
