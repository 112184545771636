import _ from 'lodash';

import React from 'react'
import PropTypes from 'prop-types'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Column, Cell} from 'fixed-data-table-2';

import css from './BotsTable.css';
import sdk from 'sdk';
import StatsWrapper from './StatsWrapper';
import {numberWithCommas,getPercentage} from 'utils/format_number';
import {truncate} from 'utils/string';
import TableResponsive from 'components/ui/TableResponsive';

const {botsActions} = sdk;


const ROW_HEIGHT = 46;
const HEADER_HEIGHT = 25;

class BotsTable extends React.Component{
  componentDidMount() {
    this.props.fetchBotsStats();
  }
	render(){
    const {botsStore,isFetchingBots} = this.props;
    const bots = _.sortBy(botsStore, 'id')
    if(!bots.length && !isFetchingBots) return null;

    const totalActiveChats = _.sumBy(bots, 'active_count');
    const totalEscalationCount = _.sumBy(bots, 'daily_escalation_count');
    const totalChatCount = _.sumBy(bots, 'daily_chat_handled_count');
    const totalEscalationRate = getPercentage(totalEscalationCount, totalChatCount);
    const tableHeightWithScroll = bots.length < 10 ? bots.length * ROW_HEIGHT + 75 : ROW_HEIGHT*10 + 75;

		return (
			<StatsWrapper className="m-t-20" isLoading={isFetchingBots}>
        <div className={css.table} data-cy="bots-table">
        		<div className={css.tableHeader}>Solutions</div>
            <div style={{height:tableHeightWithScroll}}>
              <TableResponsive
                rowHeight={ROW_HEIGHT}
                rowsCount={bots.length}
                height={tableHeightWithScroll}
                headerHeight={HEADER_HEIGHT}
                footerHeight={ROW_HEIGHT}>
                <Column
                  header={<Cell>Name</Cell>}
                  cell={({rowIndex, ...props}) => {
                    return <Cell {...props}>{truncate(bots[rowIndex].id,25)}</Cell>
                  }}
                  footer={<Cell>ALL SOLUTIONS</Cell>}
                  flexGrow={1}
                  width={200}
                />
                <Column
                  header={<Cell>CURRENT ACTIVE CHATS</Cell>}
                  cell={
                    ({rowIndex, ...props}) => {
                      return <Cell {...props}>{numberWithCommas(bots[rowIndex].active_count)} chats</Cell>
                    }
                  }
                  footer={<Cell>{numberWithCommas(totalActiveChats)} chats</Cell>}
                  flexGrow={1}
                  width={190}
                />
                <Column
                  align="right"
                  header={<Cell>ESCALATION RATE</Cell>}
                  cell={
                    ({rowIndex, ...props}) => {
                      const {daily_escalation_count,daily_chat_handled_count} = bots[rowIndex];
                      const escalationRate = getPercentage(daily_escalation_count,daily_chat_handled_count);
                      return <Cell {...props}>{escalationRate}</Cell>
                    }
                  }
                  footer={<Cell>{totalEscalationRate}</Cell>}
                  width={150}
                  flexGrow={1}
                />
                <Column
                  align="right"
                  header={<Cell><span className="text-right">CHATS</span></Cell>}
                  cell={
                    ({rowIndex, ...props}) => {
                      return <Cell {...props}>{numberWithCommas(bots[rowIndex].daily_chat_handled_count)}</Cell>
                    }
                  }
                  footer={<Cell>{numberWithCommas(totalChatCount)}</Cell>}
                  flexGrow={1}
                  width={120}
                />
              </TableResponsive>
            </div>
        </div>
      </StatsWrapper>
			);
	}
}

BotsTable.propTypes = {
  botsStore: PropTypes.array.isRequired
}

const mapStateToProps = (state) => ({
  botsStore: state.botsForPype.bots,
  isFetchingBots: state.botsForPype.isFetching
})

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({
    fetchBotsStats: botsActions.fetchBotsStats
  }, dispatch)
)

export default  connect(
  mapStateToProps,
  mapDispatchToProps
)(BotsTable);

