import style from './style.css';
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames';
import SmartButtons from '../SmartButtons';
import Message from '../Message';
import MessageWrapper from './MessageWrapper';

const MOCK_DATA = [
  {
    "messageType": "text",
    "cid": "1",
    "id": "8c49e95c-2a68-4148-931c-810e98c498a7.4",
    "chat_id": "8c49e95c-2a68-4148-931c-810e98c498a7",
    "from": "user_acbb8e5a-b2d8-45c8-b84f-f34f76dd067a",
    "msg": "Hello, how can I help you?",
    "msg_type": "text",
    "from_side": "bot",
    "isPending": false,
    "seq_num": 1,
    "encrypted": false,
    "metadata": {
      "from_name": "Keeley Predovic",
      "pype_name": "SidLeeBot",
      "stream_name": "News"
    },
    "timestamp": 1481587527000,
    "campaign_id": null,
    "secure_app_object": false,
    "file_status": "uploading",
    "file": null,
    "msg_action": "new",
    "client_msg_id": null,
  },
  {
    "messageType": "text",
    "cid": "2",
    "id": "8c49e95c-2a68-4148-931c-810e98c498a7.4",
    "chat_id": "8c49e95c-2a68-4148-931c-810e98c498a7",
    "from": "user_acbb8e5a-b2d8-45c8-b84f-f34f76dd067a",
    "msg": "I want to upgrade my plan.",
    "msg_type": "text",
    "from_side": "consumer",
    "isPending": false,
    "seq_num": 2,
    "encrypted": false,
    "metadata": {
      "from_name": "Keeley Predovic",
      "pype_name": "SidLeeBot",
      "stream_name": "News"
    },
    "timestamp": 1481587527000,
    "campaign_id": null,
    "secure_app_object": false,
    "file_status": "uploading",
    "file": null,
    "msg_action": "new",
    "client_msg_id": null,
  },
  {
    "messageType": "button",
    "cid": "3",
    "id": "8c49e95c-2a68-4148-931c-810e98c498a7.5",
    "chat_id": "8c49e95c-2a68-4148-931c-810e98c498a7",
    "from": "pypebot",
    "msg": null,
    "msg_type": "embed",
    "from_side": "bot",
    "isPending": false,
    "encrypted": false,
    "metadata": {
      "from_name": "pypebot",
      "pype_name": "SidLeeBot",
      "stream_name": "News"
    },
    "timestamp": 1481587527000,
    "campaign_id": null,
    "seq_num": 3,
    "secure_app_object": false,
    "file_status": "uploading",
    "file": null,
    "msg_action": "new",
    "client_msg_id": null,
    "content": {
      "disable_input": true,
      "label": "OK, which plan are you interested in?",
      "post_url": null,
      "options": [
        {"value": "gold", "label": "Gold Plan"},
        {"value": "silver", "label": "Silver Plan"},
        {"value": "bronze", "label": "Bronze Plan"},
      ],
      "type": "button",
      "id": "Ford.CarSearchBot",
    },
  },
]

class Messages extends React.Component {

  render () {
    const messages = MOCK_DATA.map((message, n) => {
      const isLastMessage = n === MOCK_DATA.length - 1
      const child = (() => {
        if (message.messageType === 'button') {
          const {label, options} = message.content
          return <SmartButtons message={message} label={label} options={options} isLastInGroup={isLastMessage} />
        } else {
          return (
            <Message from={message.from_side} isLastInGroup={isLastMessage}>
              {message.msg}
            </Message>
          )
        }
      })()
      const className = classNames(style.MessageWrapper, style.messageType)
      return (
        <MessageWrapper key={message.cid} className={className}>
          {child}
        </MessageWrapper>
      )
    })  // end map()
    return (
      <div>
        {messages}
      </div>
    )
  }

}


export default  Messages
