import { Server } from "mock-socket";
import { createMessages } from "./wsMessages";

const initialiseMockWebSocket = () => {
  let mockServer = new Server("ws://localhost:8080");
  let msg_no = 0;
  mockServer.on("connection", (socket) => {
    socket.send(
      "CONNECTED\x0Aserver:PypestreamCustomSTOMP\x0Asession:session-57304248-76d1-43e2-969b-7720b16b33e0\x0Aheart-beat:10000,10000\x0Aversion:1.1\x0A\x0A"
    );
    socket.on("message", (data) => {
      let reqType = data.split("\n")[0];
      switch (reqType) {
        case "SEND":
          msg_no++;
          const msg_queue = createMessages(data, msg_no);
          if (msg_queue) {
            msg_queue.forEach((msg) => {
            socket.send(msg);
            });
          }
          break;

        default:
          break;
      }
    });
  });
};

export default initialiseMockWebSocket;
