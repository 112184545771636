// External imports
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
// Local imports
import style from './style.css';
import DetailPane from '../../details/DetailPane';
import TitleBar from '../../details/TitleBar';
import ContentBody from '../../details/ContentBody';
import RuleList from '../RuleList';
import activityTracker from "../../../../utils/activityTracker";
import ErrorBoundary from 'components/ui/ErrorBoundary';

class UserPrivacy extends React.Component {
    componentDidMount() {
        const { customerName } = this.props

        activityTracker.logEvent(activityTracker.eventTypeNames.VIEW_PYPE_SETTING, {settingName: 'user privacy', customerName});
    }

    render() {
        return (
            <DetailPane className="chat-logs-detail">
                <ContentBody>
                    <div className={style.detailContainer}>
                        <h5>Sensitive data masking (advanced)</h5>
                        <p>
                            Use data masking to hide customers' sensitive information to your agents, admins, customers and chat logs
                        </p>
                    </div>
                    <ErrorBoundary>
                        <RuleList/>
                    </ErrorBoundary>
                </ContentBody>
            </DetailPane>
        )
    }
}

UserPrivacy.propTypes = {
  pypeName: PropTypes.string.isRequired
}

const mapStateToProps = (state) => {
  return {
    pypeName: state.pype.details.name,
    customerName: state.pype.details.customerName
  }
};


export default  connect(mapStateToProps, null)(UserPrivacy)
