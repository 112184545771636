import _ from 'lodash'
import { BOT_USER_SESSION_LOGOUT } from "bot-user-session";
import {
  REQUEST_CAMPAIGNS,
  RECEIVE_CAMPAIGNS,
  CREATE_CAMPAIGN_RES,

  RESET_CAMPAIGNS,

  REQUEST_CAMPAIGN_STATS,
  RECEIVE_CAMPAIGN_STATS
} from "../constants";
import createReducer from '../common/utils/create-reducer';

const getInitialState = () => ({
  isFetching: false,
  didInvalidate: false,
  campaigns: [],
  createdCampaign: {},
  campaignsForTerm: [],
  recentCampaignsForStreams: [],
  isSendingBroadcast: false,
  stats:{},
  // Pagination stuff
  itemsPerPage: 50,
  more: null,
  hasMore: true,
});

const SMS_CAMPAIGN_SUFFIX = '_sms_campaign'

const actionHandlers = {

  [BOT_USER_SESSION_LOGOUT] :  (state, action) => {
    return getInitialState();
  },

  [RESET_CAMPAIGNS]: (state, action) => getInitialState(),

  [REQUEST_CAMPAIGNS]: (state, action) => {
    return {
        ...state,
      isFetching: true,
      didInvalidate: false,
    }
  },

  [RECEIVE_CAMPAIGNS]: (state, action) => {
    const campaigns = action.campaigns;
    const multiChannelCampaigns = _.chain(campaigns)
    .filter((campaign) => {
      return campaign.kind === 'broadcast' && !!campaign.alt_body
    })
    .map((campaign) => {
      return {
        ...campaign,
        id: campaign.id + SMS_CAMPAIGN_SUFFIX,
        body: campaign.alt_body,
        smsCampaign: true
      }
    })
    .concat(campaigns)
    .sortBy('creation_date')
    .reverse()
    .value();

    let recentCampaignsForStreams = _.chain(campaigns)
    .groupBy("stream_id")
    .mapValues(function(streams){
      return _.sortBy(streams,"creation_date").pop();
    })
    .value();

    return Object.assign({}, state, {
      isFetching: false,
      didInvalidate: false,
      lastUpdated: action.receivedAt,
      recentCampaignsForStreams,
      // NOTE platform returns some previously returned
      // broadcasts in paginated results
      campaigns: _.uniqBy([...state.campaigns, ...multiChannelCampaigns], 'id'),
      more: action.more,
      hasMore: action.more !== 'done',
    })
  },

  [CREATE_CAMPAIGN_RES]: (state, action) => {
    let createdCampaign = action.createdCampaign;
    let campaignToUpdate = _.find(state.campaigns, {id: createdCampaign.id});
    let smsCampaign = null;
    // SMS
    if(createdCampaign.kind === 'broadcast' && !!createdCampaign.alt_body){
      smsCampaign = Object.assign({}, createdCampaign, {
        id: createdCampaign.id + SMS_CAMPAIGN_SUFFIX,
        body: createdCampaign.alt_body,
        smsCampaign: true
      })
    }
    if(!campaignToUpdate) {
      const updatedCampaigns = [
        ...state.campaigns
      ]

      if(createdCampaign){
        updatedCampaigns.push(createdCampaign)
      }
      if(smsCampaign){
        updatedCampaigns.push(smsCampaign)
      }
      return {
        ...state,
        createdCampaign: createdCampaign,
        campaigns: _.orderBy(updatedCampaigns, 'creation_date', 'desc'),
      }
    }
    const campaigns = _.map(state.campaigns, campaign => {
      if(campaign.id === createdCampaign.id){
        return createdCampaign;
      } else if(smsCampaign && campaign.id === smsCampaign.id){
        return smsCampaign;
      }
      return campaign;
    })
    return {
      ...state,
      campaigns
    }
  },

  [REQUEST_CAMPAIGN_STATS]: (state) => {
    return Object.assign({}, state, {
      stats: {}
    });
  },

  [RECEIVE_CAMPAIGN_STATS]: (state, action) => {
    return Object.assign({}, state, {
      stats: action.data
    });
  }


};

export default createReducer(getInitialState(), actionHandlers);
