import React from 'react'
import ReactDOM from 'react-dom';
import Tether from 'tether';

class TetheredChildrenComponent extends React.Component {
    render() {
        return this.props.children;
    }

    componentDidMount() {
        this.props.position();
    }

    componentDidUpdate() {
        this.props.position();
    }
}

export default class TetherComponent extends React.Component {
    componentDidMount() {
        this.tetherContainer = document.createElement('div');
        document.body.appendChild(this.tetherContainer);

        this.renderTetheredContent();
    }

    componentDidUpdate() {
        this.renderTetheredContent();
    }

    componentWillUnmount() {
        this.destroyTetheredContent();
    }

    renderTetheredContent() {
        ReactDOM.render(
            <TetheredChildrenComponent
                target={this.props.target}
                position={this.position}
            >
                {this.props.children}
            </TetheredChildrenComponent>,
            this.tetherContainer
        );
    }

    position = () => {
        if (!this.tether) {
            this.tether = new Tether({
                ...this.props.options,
                element: this.tetherContainer,
                target: this.props.target,
            });
        }

        if (this.props.matchWidth) {
            this.tetherContainer.style.width = `${this.props.target.clientWidth}px`;
        }

        this.tether.position();
    };

    destroyTetheredContent() {
        ReactDOM.unmountComponentAtNode(this.tetherContainer);

        this.tether.destroy();

        document.body.removeChild(this.tetherContainer);
    }

    render() {
        return null;
    }
}
