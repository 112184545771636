import React from 'react'
import PropTypes from 'prop-types'
import Checkbox from './Checkbox';
import cx from 'classnames';

const LabelCheckbox = (props) => {
  const {children, value, label, onClick, className, disabled, ...rest} = props;
  const cssClass = cx('label-checkbox', {disabled});
  return (
    <label className={cssClass + ' ' + className}>
      <Checkbox value={value} onClick={disabled ? undefined : onClick} {...rest} />
      <span className="label-text">{children || label}</span>
    </label>
  )
}

LabelCheckbox.propTypes = {
  value: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool
}

LabelCheckbox.defaultProps = {
  className: "",
  disabled: false,
}

export default  LabelCheckbox
