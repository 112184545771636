import React from "react";
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import _ from 'lodash';
import Toast from 'components/ui/toast/Toast';
import css from './EditStreamsModal.css';
import ErrorBoundary from 'components/ui/ErrorBoundary';

const customStyles = {
  content : {
    position              : 'relative',
    top                   : 0,
    left                  : 0,
    right                 : 0,
    overflow              : 'visible',
    width                 : '400px'
  }
};

class Component extends React.Component{
  state = {
      streams: [],
      newStreamName: ''
  }

  componentWillReceiveProps(nextProps){
    this.setState({
      streams : nextProps.streams || null,
      isUpdating: nextProps.isFetching || null,
      editingID: nextProps.editingStreamID || null,
      edited: nextProps.edited || null
    });
  }

  closeModal = (data) => {
    this.setState({isUpdating: false});
    this.props.onEditStream('');
    this.props.closeModal();
    if(data.error){
      alert(data.error);
    }
  }

  //when a stream's name begins to be edited, change the state of the editingStreamID
  handleEditName = (id, name) => {
    this.props.onEditStream(id);
    this.setState({
      newStreamName: name
    })
  }

  handleCancel = (e) => {
    this.props.onEditStream('');
    e.stopPropagation();
  }

  //once the user has typed in the new name for the stream, save it
  handleNameChange = (e) => {
    var name = e.target.value;
    this.setState({
      newStreamName: name
    })
  }

  nameValid (name) {

    if (name === ''){
      this.refs.toast.createToast("Stream names cannot be blank.", "error");
      return false;
    }

    if (name.length > 30){
      this.refs.toast.createToast("Stream names cannot be longer than 30 characters.", "error");
      return false;
    }

    var streams = this.props.streams;
    var _name = name.toLowerCase();

    var identicalStream = streams.find(stream => stream.stream_name.toLowerCase() === _name);

    //if no identical stream found, stream is unique
    if(identicalStream === undefined) {
      return true;
    }
    //if stream id matches editing id, you haven't changed the name so stop editing
    else if(identicalStream.id === this.props.editingID) {
      this.props.onEditStream('');
      return false;
    }
    //else there's a stream that matches the new name
    this.refs.toast.createToast("Stream names must be unique.", "error");
    return false;

  }

  //submit the name change to the server
  handleNameSubmit(stream) {
    this.setState ({
      isUpdating: true
    });
    var name = this.state.newStreamName.trim();

    if(this.nameValid(name)) {
      this.props.onUpdateStream(stream.id, {
        stream_name: name
      });
    }

  }

  //create a new stream with the name inputted in the add a stream field
  handleNewStreamSubmit = (e) => {
    e.preventDefault();
    this.setState({isUpdating: true});
    var new_input = ReactDOM.findDOMNode(this.refs.newStream);
    var name = new_input.value;
    e.stopPropagation();

    if(this.nameValid(name)) {
      this.props.onCreateStream(name);
    }
  }

  //change the stream from autojoinable or not
  setDefaultState(id, e) {
    var autojoin = e.target.checked;
    this.props.setDefaultState(id, autojoin);
  }

  //handle enabling or disabling stream
  handleUpdateStreamStatus(stream, id, enabled) {
    var self = this;
    var streams = this.props.streams;

    var numActive = streams.reduce(function(n, stream) {
      return n + (stream.status === 'active');
    }, 0);

    if(numActive === 1 && enabled === "inactive") {
      this.refs.toast.createToast("You cannot disable the last Stream.", "error");
      return
    }

    if(id === this.props.broadcastStreamID){
      this.refs.toast.createToast("This Stream is used to send event-based " +
      "broadcasts. Please change the Stream in the Admin Broadcast tab and try again.",
      "error");
      return;
    }

    if(_.some(this.props.widgets, {stream_id: id})){
      this.refs.toast.createToast("This Stream is used by your Web Conversational Interface. " +
      "Please change the Stream in the Web Conversational Interface tab and try again.",
      "error");
      return;
    }

    //hack to update modal props
    this.props.onEditStream('enabling');
    this.props.onUpdateStream(id, {
      status: enabled
    });


  }

  //render out each stream in its own table row and associated controls
  renderStreamsTable() {
    var self = this;
    var streams = this.props.streams && this.props.streams.map(function(stream) {
      var name = stream.stream_name;
      var id = stream.id;
      var is_active = stream.status == 'active';

      return (
        <tr key={id} >
          <td className={is_active ? 'p-10' : 'p-10 fade'} >
            <button className={self.props.editingID != id ? "btn btn-link btn-input p-sm" : "hidden"} onClick={() => self.handleEditName(id, name)}>{name}</button>
            <input type="text" ref="editStream" name="name" className={id === self.props.editingID ?  "form-control p-sm" : "hidden"} placeholder="e.g. Promotions, Support, Billing..."
                   onChange={self.handleNameChange}
                   defaultValue={name}/>
          <span className="m-l-a">
            {self.props.isUpdating && self.state.editingID === id ?
                <span className="btn btn-link p-sm" disabled>Saving...</span>
                :
                <span>
                  {is_active ?
                      <button className={self.props.editingID != id ? "btn btn-link p-sm" : "hidden"} onClick={() => self.handleUpdateStreamStatus(stream, id, "inactive")}>Disable</button> :
                      <button className={self.props.editingID != id ? "btn btn-link p-sm" : "hidden"} onClick={() => self.handleUpdateStreamStatus(stream, id, "active")}>Enable</button>
                  }

                  {/* Uncomment the day we can delete streams
                   <span className={inputHidden ? "text-primary" : "hidden"}><strong>&middot;</strong></span>
                   <button className={inputHidden ? "btn btn-link p-sm" : "hidden"}>Delete</button> */}
                  <span className={id === self.props.editingID ? "inline-flex" : "hidden"} >
                    <button className="btn btn-link p-sm fw-300" onClick={self.handleCancel} disabled={self.props.isUpdating}>Cancel</button>
                    <span className="text-primary p-sm"><strong>&middot;</strong></span>
                    <button className="btn btn-link p-sm" onClick={() => self.handleNameSubmit(stream)} disabled={self.props.isUpdating}>Save</button>
                  </span>

                </span>
            }
          </span>
          </td>
        </tr>
      )
    });
    return (
      <div>
        <table className="table p-t-20">
          <tbody>
            <tr onClick={()=> this.handleEditName('new', '')}>
              <td className="p-10">
                <button className={this.props.editingID != 'new' ? "btn btn-link p-sm" : "hidden"}>
                  <img src={`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-add.svg`} width="16px" height="16px" className="add-button"/>
                  Add a new Stream
                </button>
                <input type="text" ref="newStream" name="name" className={this.props.editingID === 'new' ? css.newStreamInput + " form-control p-sm" : "hidden"} placeholder="e.g. Promotions, Support, Billing..."
                       onChange={this.handleNameChange}
                       />
                <span className={this.props.editingID === 'new' ? "inline-flex" : "hidden"}>
                 <button className="btn btn-link p-sm fw-300" onClick={this.handleCancel} disabled={this.props.isUpdating}>Cancel</button>
                  <span className="text-primary p-sm"><strong>&middot;</strong></span>
                <button className="btn btn-link p-sm" onClick={this.handleNewStreamSubmit} disabled={this.props.isUpdating}>Save</button>
                </span>
              </td>
            </tr>
            {streams}
          </tbody>
        </table>
      </div>
    );
  }

  render(){
    return (
      <Modal
        isOpen={this.props.modalIsOpen}
        style={customStyles} >
        <Toast ref="toast"/>
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" onClick={this.closeModal}>
              <img src={`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-modal-close.svg`} width="15" height="15" />
            </button>
            <h4 className="modal-title" >Edit Streams</h4>
          </div>
          <div className="modal-body p-0" style={{overflow: "auto"}}>
            <ErrorBoundary>
              {this.renderStreamsTable()}
            </ErrorBoundary>
          </div>
          <div className="modal-footer">

          </div>
        </div>
      </Modal>)
  }
};

export default Component
