import React, { useState, useEffect, useRef } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, TextField, InputAdornment, Typography, Grid } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import SvgIcon from '@material-ui/core/SvgIcon';
import { Button } from 'ui-elements';
import { Col } from 'reactstrap';
import sdk from 'sdk'
import { connect } from 'react-redux';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { bindActionCreators } from 'redux';

//Remove when feature flag is removed
import EllipsisText from "react-ellipsis-text";

const ExpandIcon = () => {
    return (
        <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="chevron-down" className="svg-inline--fa fa-chevron-down fa-w-14" width="16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M443.5 162.6l-7.1-7.1c-4.7-4.7-12.3-4.7-17 0L224 351 28.5 155.5c-4.7-4.7-12.3-4.7-17 0l-7.1 7.1c-4.7 4.7-4.7 12.3 0 17l211 211.1c4.7 4.7 12.3 4.7 17 0l211-211.1c4.8-4.7 4.8-12.3.1-17z"></path></svg>
    );
};

const useStyles = makeStyles(({
    reply: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        "-webkit-line-clamp": 2,
        "-webkit-box-orient": "vertical"
    },
    buttonPos: {
        position: 'absolute',
        right: 0,
        bottom: 0,
        backgroundColor: '#f9f9f9',
        margin: 0
    },
    hoveredAccordion: {
        "&:hover": {
            backgroundColor: '#f9f9f9'
        }
    },
    title: {
        color: '#0a3ab4'
    },
    accordianPointer: {
        cursor:"pointer",
    }
}));

export const ResponsesTab = (props) => {
    const [currentSelectedShowMore, setCurrentSelectedShowMore] = useState('');
    const [currentSearchString, setCurrentSearchString] = useState('');
    const [currentHover, setCurrentHover] = useState(null);
    const [responseButtons, setResponseButtons] = useState([]);

    const cannedResponseRef = useRef([]);
    cannedResponseRef.current = [];

    const addRefs = (el) => {
        if (el && !cannedResponseRef.current.includes(el)) {
            cannedResponseRef.current.push(el);
        }
    }

    const cannedShowMore = () => {
        cannedResponseRef.current.forEach(el => {
            if (el.scrollHeight > el.clientHeight && !responseButtons.includes(el.id)) {
                setResponseButtons([...responseButtons, el.id])
            }
        })
    }

    const classes = useStyles();

    useEffect(() => {
        props.getCannedResponseDrawer();
        cannedShowMore();
    }, []);

    useEffect(() => {
        cannedShowMore();
    }, [currentHover]);

    const toggleShowMore = (id) => {
        if (id === currentSelectedShowMore) {
            setCurrentSelectedShowMore('');
        }
        else {
            setCurrentSelectedShowMore(id);
        }
    }

    const handleChange = ({ target }) => {
        setCurrentSearchString(target.value);
    }

    const handleShowClick = (e, id) => {
        e.stopPropagation();
        toggleShowMore(id)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
    }
    const sortObjectsAlaphabeticallyByName = (a, b) => {
        const categoryA = a.name.toUpperCase();
        const categoryB = b.name.toUpperCase();

        let comparison = 0;
        if (categoryA > categoryB) {
            comparison = 1;
        } else if (categoryA < categoryB) {
            comparison = -1;
        }
        return comparison;
    }

    const { cannedResponses } = props;
    let allCategoriesAvailable = [];
    let sortedCannedResponses = cannedResponses;
    const filteredResponses = cannedResponses.filter(response => {
        return response.name.toLowerCase().includes(currentSearchString.toLowerCase()) || response.text.toLowerCase().includes(currentSearchString.toLowerCase());
    });

    cannedResponses
        .forEach(cannedResponse => {
            const category = props.categoriesStore.find(category => cannedResponse.category === category.id);
            const categoryExists = allCategoriesAvailable.find(cat => cat.name === category.text);
            if (!categoryExists) allCategoriesAvailable.push({ name: category.text, id: category.id });
        });

    allCategoriesAvailable = allCategoriesAvailable.sort(sortObjectsAlaphabeticallyByName);
    sortedCannedResponses = sortedCannedResponses.sort(sortObjectsAlaphabeticallyByName);

    return (
      <Grid>
        <Box className="responsesTab-search">
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <TextField
                data-test="text-field"
              id="outlined-basic"
              label="Search"
              variant="outlined"
              fullWidth
              onChange={handleChange}
              value={currentSearchString}
              name="currentSearchString"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SvgIcon style={{ width: "24px", height: "24px" }}>
                      <g fill="none" fillRule="evenodd">
                        <g>
                          <g transform="translate(-452 -348) translate(452 348)">
                            <path
                              fill="#0A3AB4"
                              d="M19.581 19.89l.31-.309c.146-.147.146-.384 0-.531l-4.032-4.031c-.072-.072-.165-.11-.265-.11h-.322C16.344 13.75 17 12.203 17 10.5 17 6.91 14.09 4 10.5 4S4 6.91 4 10.5 6.91 17 10.5 17c1.703 0 3.25-.656 4.41-1.725v.319c0 .1.04.193.109.265l4.031 4.032c.147.146.384.146.531 0zM10.5 16C7.46 16 5 13.54 5 10.5S7.46 5 10.5 5 16 7.46 16 10.5 13.54 16 10.5 16z"
                            />
                          </g>
                        </g>
                      </g>
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
            />
          </form>
        </Box>
        {props.flags.newQuickReplies ? (
          <Box
            data-test="box-new-quick-replies"
            className="responsesTab-dropdown"
          >
            {currentSearchString !== "" ? (
              <Accordion
                data-test="accordion-current-search-string"
                style={{ boxShadow: "none" }}
                defaultExpanded={true}
              >
                <AccordionSummary
                  expandIcon={<ExpandIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h5 className="responsesTab-dropdown--header">
                    {filteredResponses.length} Search Results
                  </h5>
                </AccordionSummary>
                {filteredResponses.length < 1 ? (
                  <Typography
                    style={{
                      fontSize: "16px",
                      margin: "16px",
                      fontFamily: "Open Sans",
                    }}
                  >{`There were no results for '${currentSearchString}'. Clear your search and try again.`}</Typography>
                ) : (
                  filteredResponses.map((cannedResponse, index) => {
                    const category = allCategoriesAvailable.find(
                      (_category) => _category.id === cannedResponse.category
                    );

                    return (
                      <AccordionDetails
                        className={classes.hoveredAccordion}
                        key={cannedResponse.id}
                        onClick={() => props.onSelect(cannedResponse, category)}
                        onMouseEnter={() => setCurrentHover(cannedResponse.id)}
                        onMouseLeave={() => setCurrentHover(null)}
                      >
                        <Box
                          className={`responsesTab-dropdown-content ${classes.accordianPointer}`}
                        >
                          <Col md={12} className="embedInformation">
                            <Box className="embedInformation--title">
                              <h5
                                className={
                                  currentHover === cannedResponse.id
                                    ? classes.title
                                    : null
                                }
                              >
                                {cannedResponse.name}
                              </h5>
                            </Box>
                            <Box
                              className={
                                currentSelectedShowMore !== cannedResponse.id
                                  ? classes.reply
                                  : null
                              }
                            >
                              {cannedResponse.text}
                              {currentHover === cannedResponse.id &&
                                responseButtons.includes(
                                  `res-${cannedResponse.id}`
                                ) && (
                                  <Button
                                    className={classes.buttonPos}
                                    hollow
                                    small
                                    onClick={(e) =>
                                      handleShowClick(e, cannedResponse.id)
                                    }
                                  >
                                    {currentSelectedShowMore ===
                                    cannedResponse.id
                                      ? "Show Less"
                                      : "Show More"}
                                  </Button>
                                )}
                            </Box>
                          </Col>
                        </Box>
                      </AccordionDetails>
                    );
                  })
                )}
              </Accordion>
            ) : (
              allCategoriesAvailable.map((category) => {
                const isExpanded =
                  props.cannedResponseDrawers[category.id] === false
                    ? false
                    : true;
                return (
                  <Accordion
                    data-test="accordion-current-search-string-false"
                    style={{ boxShadow: "none" }}
                    key={category.id}
                    expanded={isExpanded}
                    onChange={() => props.setCannedResponseDrawer(category.id)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <h5 className="responsesTab-dropdown--header">
                        {category.name} (
                        {
                          props.cannedResponses.filter(
                            (canned) => canned.category === category.id
                          ).length
                        }
                        )
                      </h5>
                    </AccordionSummary>

                    {sortedCannedResponses.map(
                      (cannedResponse) =>
                        cannedResponse.category === category.id && (
                          <AccordionDetails
                            className={classes.hoveredAccordion}
                            key={cannedResponse.id}
                            onClick={() =>
                              props.onSelect(cannedResponse, category)
                            }
                            onMouseEnter={() =>
                              setCurrentHover(cannedResponse.id)
                            }
                            onMouseLeave={() => setCurrentHover(null)}
                          >
                            <Box
                              className={`responsesTab-dropdown-content ${classes.accordianPointer}`}
                            >
                              <Col md={12} className="embedInformation">
                                <Box className="embedInformation--title">
                                  <h5
                                    className={
                                      currentHover === cannedResponse.id
                                        ? classes.title
                                        : null
                                    }
                                  >
                                    {cannedResponse.name}
                                  </h5>
                                </Box>
                                <Box
                                  id={`res-${cannedResponse.id}`}
                                  className={
                                    currentSelectedShowMore !==
                                    cannedResponse.id
                                      ? classes.reply
                                      : null
                                  }
                                  ref={addRefs}
                                >
                                  {cannedResponse.text}
                                  {isExpanded &&
                                    currentHover === cannedResponse.id &&
                                    responseButtons.includes(
                                      `res-${cannedResponse.id}`
                                    ) && (
                                      <Button
                                        className={classes.buttonPos}
                                        hollow
                                        small
                                        onClick={(e) =>
                                          handleShowClick(e, cannedResponse.id)
                                        }
                                      >
                                        {currentSelectedShowMore ===
                                        cannedResponse.id
                                          ? "Show Less"
                                          : "Show More"}
                                      </Button>
                                    )}
                                </Box>
                              </Col>
                            </Box>
                          </AccordionDetails>
                        )
                    )}
                  </Accordion>
                );
              })
            )}
          </Box>
        ) : (
          <div
            data-test="accordion-quick-replies"
            className="responsesTab-dropdown"
          >
            {currentSearchString !== "" ? (
              <Accordion style={{ boxShadow: "none" }} defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<ExpandIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h5 className="responsesTab-dropdown--header">
                    {filteredResponses.length} Search Results
                  </h5>
                </AccordionSummary>
                {filteredResponses.length < 1 ? (
                  <p
                    style={{
                      fontSize: "16px",
                      margin: "16px",
                      fontFamily: "Open Sans",
                    }}
                  >{`There were no results for '${currentSearchString}'. Clear your search and try again.`}</p>
                ) : (
                  filteredResponses.map((cannedResponse) => {
                    const category = allCategoriesAvailable.find(
                      (_category) => _category.id === cannedResponse.category
                    );

                    return (
                      <AccordionDetails key={cannedResponse.id}>
                        <div className="responsesTab-dropdown-content">
                          <Col md={12} className="embedInformation">
                            <div className="embedInformation--title">
                              <h5>{cannedResponse.name}</h5>
                              <div>
                                <SvgIcon
                                  style={{ width: "12px", height: "12px" }}
                                  onClick={() =>
                                    props.onSelect(cannedResponse, category)
                                  }
                                  viewBox="0 0 12 12"
                                >
                                  <g
                                    fill="none"
                                    fillRule="evenodd"
                                    transform="translate(-6 -6)"
                                  >
                                    <circle cx="12" cy="12" r="12" />
                                    <path
                                      fill="#0A3AB4"
                                      d="M12.5 18c.138 0 .25-.112.25-.25v-5h5c.138 0 .25-.112.25-.25v-1c0-.138-.112-.25-.25-.25h-5v-5c0-.138-.112-.25-.25-.25h-1c-.138 0-.25.112-.25.25v5h-5c-.138 0-.25.112-.25.25v1c0 .138.112.25.25.25h5v5c0 .138.112.25.25.25h1z"
                                    />
                                  </g>
                                </SvgIcon>
                              </div>
                            </div>
                            {currentSelectedShowMore === cannedResponse.id ? (
                              <p>{cannedResponse.text}</p>
                            ) : (
                              <EllipsisText
                                text={cannedResponse.text}
                                length="40"
                              />
                            )}
                            <Button
                              hollow
                              small
                              onClick={() => toggleShowMore(cannedResponse.id)}
                            >
                              {currentSelectedShowMore === cannedResponse.id
                                ? "Show Less"
                                : "Show More"}
                            </Button>
                          </Col>
                        </div>
                      </AccordionDetails>
                    );
                  })
                )}
              </Accordion>
            ) : (
              allCategoriesAvailable.map((category) => {
                const isExpanded =
                  props.cannedResponseDrawers[category.id] === false
                    ? false
                    : true;
                return (
                  <Accordion
                    style={{ boxShadow: "none" }}
                    key={category.id}
                    expanded={isExpanded}
                    onChange={() => props.setCannedResponseDrawer(category.id)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <h5 className="responsesTab-dropdown--header">
                        {category.name} (
                        {
                          props.cannedResponses.filter(
                            (canned) => canned.category === category.id
                          ).length
                        }
                        )
                      </h5>
                    </AccordionSummary>

                    {sortedCannedResponses.map(
                      (cannedResponse) =>
                        cannedResponse.category === category.id && (
                          <AccordionDetails key={cannedResponse.id}>
                            <div className="responsesTab-dropdown-content">
                              <Col md={12} className="embedInformation">
                                <div className="embedInformation--title">
                                  <h5>{cannedResponse.name}</h5>
                                  <div>
                                    <SvgIcon
                                      style={{ width: "12px", height: "12px" }}
                                      onClick={() =>
                                        props.onSelect(cannedResponse, category)
                                      }
                                      viewBox="0 0 12 12"
                                    >
                                      <g
                                        fill="none"
                                        fillRule="evenodd"
                                        transform="translate(-6 -6)"
                                      >
                                        <circle cx="12" cy="12" r="12" />
                                        <path
                                          fill="#0A3AB4"
                                          d="M12.5 18c.138 0 .25-.112.25-.25v-5h5c.138 0 .25-.112.25-.25v-1c0-.138-.112-.25-.25-.25h-5v-5c0-.138-.112-.25-.25-.25h-1c-.138 0-.25.112-.25.25v5h-5c-.138 0-.25.112-.25.25v1c0 .138.112.25.25.25h5v5c0 .138.112.25.25.25h1z"
                                        />
                                      </g>
                                    </SvgIcon>
                                  </div>
                                </div>
                                {currentSelectedShowMore ===
                                cannedResponse.id ? (
                                  <p>{cannedResponse.text}</p>
                                ) : (
                                  <EllipsisText
                                    text={cannedResponse.text}
                                    length={40}
                                  />
                                )}
                                <Button
                                  hollow
                                  small
                                  onClick={() =>
                                    toggleShowMore(cannedResponse.id)
                                  }
                                >
                                  {currentSelectedShowMore === cannedResponse.id
                                    ? "Show Less"
                                    : "Show More"}
                                </Button>
                              </Col>
                            </div>
                          </AccordionDetails>
                        )
                    )}
                  </Accordion>
                );
              })
            )}
            {/**** 
                     Apple Business Chat UI and Logic
                    ******/}
            {/* <Accordion style={{boxShadow: 'none'}}>
                        <AccordionSummary
                            expandIcon={<ExpandIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <h5 className="responsesTab-dropdown--header">Apple Business Chat</h5>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="responsesTab-dropdown-content">
                                <Row md={3} className="responsesTab-dropdown-content--embed">
                                    <Col className="embedPreview">
                                        <div className="embedPreview--timepicker">
                                            4:30
                                        </div>
                                        <div className="embedPreview--timepicker">
                                            PM
                                        </div>
                                    </Col>
                                    <Col md={9} className="embedInformation">
                                        <div className="embedInformation--title">
                                            <h5>Appointment</h5>
                                            <img src={this.props.sendEmbed === 'timePicker' ? Check : Add} onClick={() => this.props.handleSendEmbed('timePicker')} />
                                        </div>
                                        <p>Timepicker</p>
                                        <Button hollow small>Preview</Button>
                                    </Col>
                                </Row>
                                <Row md={3} className="responsesTab-dropdown-content--embed">
                                    <Col className="embedPreview">
                                        <div className="embedPreview--listpicker"/>
                                        <div className="embedPreview--listpicker"/>
                                        <div className="embedPreview--listpicker"/>
                                        <div className="embedPreview--listpicker"/>
                                    </Col>
                                    <Col md={9} className="embedInformation">
                                        <div className="embedInformation--title">
                                            <h5>Product Selection</h5>
                                            <img src={this.props.sendEmbed === 'listPicker' ? Check : Add} onClick={() => this.props.handleSendEmbed('listPicker')} />
                                        </div>
                                        <p>Listpicker</p>
                                        <Button hollow small>Preview</Button>
                                    </Col>
                                </Row>
                                <Row md={3} className="responsesTab-dropdown-content--embed">
                                    <Col className="embedPreview">
                                        <div className="embedPreview--applePay">
                                            <img src={ApplePay} alt="Apple Pay" />
                                        </div>
                                    </Col>
                                    <Col md={9} className="embedInformation">
                                        <div className="embedInformation--title">
                                            <h5>Payment Request</h5>
                                            <img src={this.props.sendEmbed === 'applePay' ? Check : Add} onClick={() => this.props.handleSendEmbed('applePay')} />
                                        </div>
                                        <p>Apple Pay</p>
                                        <Button hollow small>Preview</Button>
                                    </Col>
                                </Row>
                            </div>
                        </AccordionDetails>
                    </Accordion> */}
          </div>
        )}
      </Grid>
    );
};

const { cannedResponsesActions } = sdk;

const mapStateToProps = (state) => {
    return {
        categoriesStore: state.cannedResponses.categories,
        agent_id: state.agent.id,
        cannedResponseDrawers: state.cannedResponses.cannedResponseDrawers || null
    }
}

const mapDispatchToProps = (dispatch) => {
    const actionCreators = bindActionCreators({
        fetchCannedResponse: cannedResponsesActions.fetchCannedResponses,
        getCannedResponseDrawer: cannedResponsesActions.getCannedResponseDrawer,
        setCannedResponseDrawer: cannedResponsesActions.setCannedResponseDrawer,
      }, dispatch);
  
    return actionCreators;
};

export default connect(mapStateToProps, mapDispatchToProps)(withLDConsumer()(ResponsesTab));
