import moment from 'moment-timezone';

export const isMaxed = (agentState) => agentState.current_conv >= agentState.max_conv

export const isBusy = (agentState) => (
  (agentState.status === 'busy') || (
    agentState.status === 'online' && isMaxed(agentState)
  )
)

// NOTE: You should always use this selector instead of referring
// directly to agent.status as this represents the most up-to-date
// value
export const getStatus = (agentState) => agentState.status

/**
 * Converts a a given time to user's
 * local time set in browser
 * @param  {number}	timeInSeconds  	seconds since midnight
 * @param  {float}	timezone 	timezone in Olson format format (https://momentjs.com/timezone/docs/)
 * @return {number}									converted time in seconds since midnight  
 */
export const getLocalTime = (timeInSeconds, timezone) => {
  if(!timeInSeconds || !timezone) return null;
 
  const midnight = moment().tz(timezone);
  const mmtMidnight = midnight.clone().startOf('day');
  const sinceMidnight = mmtMidnight.add(timeInSeconds,'seconds');
  
  const localMidnight = moment(); // local timezone 
  const localMmtMidnight = localMidnight.clone().startOf('day');
  const localSeconds = sinceMidnight.diff(localMmtMidnight, 'seconds')

  return localSeconds;

}


export const isOffHours = (state) => {
  const {timezone, schedule_end, schedule_start} = state.pype.queues;

  const dt = new Date();
  const secondsSinceMidnight = dt.getSeconds() + (60 * dt.getMinutes()) + (60 * 60 * dt.getHours());
  const startTime = getLocalTime(schedule_start, timezone);
  const endTime = getLocalTime(schedule_end, timezone);
  // time from following day
  if(startTime > endTime) 
    return secondsSinceMidnight > endTime && secondsSinceMidnight < startTime;
  else 
    return secondsSinceMidnight > endTime || secondsSinceMidnight < startTime;
}