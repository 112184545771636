import assign  from 'object-assign';
import React from 'react'
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import _ from 'lodash';
import moment from 'moment-timezone';
import cx from 'classnames';
import {Column, Cell} from 'fixed-data-table-2';
// UI components
import LabelCheckbox from 'components/ui/forms/LabelCheckbox';
import SaveButton from 'components/ui/forms/SaveButton';
import UnsavedChanges from 'components/ui/forms/UnsavedChanges';
import TableResponsive from 'components/ui/TableResponsive';

// Local imports
import DetailPane from '../details/DetailPane';
import ContentBody from '../details/ContentBody';
import SubHeader from '../details/SubHeader';
import Footer from '../details/Footer';
import AgentModal from './AgentModal';
import OperationHours from './OperationHours';
import PypeManagerFeature from '../PypeManagerFeature';
import css from './style.css';
import AppTooltip from 'components/shared/AppTooltip';
import ErrorBoundary from 'components/ui/ErrorBoundary';

import sdk from 'sdk';
import activityTracker from "../../../utils/activityTracker";
const {agentsActions, pypeActions, skillsActions} = sdk;

const SETTING_NAME = "agents";

export class Agents extends React.Component {

  constructor(props) {
    super(props);
    const userTimeZone = moment.tz.guess();

    this.state = {
      agentDetailsModalIsOpen: false,
      selectedAgent: null,
      errors: {},
      error: null,
      defaultChatLimit: null,
      isUpdating: false,
      canInitiateChats: props.canInitiateChats,
      openFromTime: -1,
      openToTime:null,
      timezone: userTimeZone
    }
  }

  static propTypes = {
    agents: PropTypes.array,
    canInitiateChats: PropTypes.bool.isRequired,
  }

  static defaultProps = {
      agents: [],
  }

  componentWillMount() {
    const { customerName } = this.props

    this.props.fetchAgents()
    this.props.fetchPypeMetrics();
    this.props.fetchSkills()
    
    activityTracker.logEvent(activityTracker.eventTypeNames.VIEW_PYPE_SETTING,{settingName: SETTING_NAME, customerName});
  }

  componentWillReceiveProps(nextProps) {
    const userTimeZone = moment.tz.guess();

    if(!nextProps.isPypeUpdating){
      this.setState({
        openFromTime: nextProps.openFromTime,
        openToTime: nextProps.openToTime,
        timezone: nextProps.timezone || userTimeZone
      })
    }

    this.setState({
      defaultChatLimit: nextProps.defaultChatLimit,
      canInitiateChats: nextProps.canInitiateChats
    })
  }

  closeModal = () => {
    this.setState({
      agentDetailsModalIsOpen: false,
      selectedAgent: null
    })
  }

  onBack = () => {
    this.setState({
      agentDetailsModalIsOpen: true,
    })
  }

  handleAgentInfo = (agentObj) => {
    this.setState({
      selectedAgent: agentObj,
      agentDetailsModalIsOpen: true
    })
  }

  handleDefaultChatLimitChange = (e) => {
    const limit = e.target.value.trim()
    this.setState({
      defaultChatLimit: parseInt(limit)
    })
  }

  isDefaultChatLimitValid = () => {
    let isValid = false
    let error = null
    const {defaultChatLimit} = this.state

    if(defaultChatLimit < 1 || defaultChatLimit > 99) {
      error = "Please set a chat limit between 1 to 100"
    } else if (!defaultChatLimit) {
      error = "Please enter a valid number"
    } else if (isNaN(defaultChatLimit)) {
      error = "Please input a number"
    } else {
      isValid = true
    }

    this.setState({
      errors: assign(
        this.state.errors,
        {defaultChatLimit: error}
      )
    })

    return isValid
  }

  canSubmit = () => {
    if(Object.keys(_.pickBy(this.state.errors, _.identity)).length) {
      return false
    }

    return true
  }

  handleSubmit = () => {
    const data = {
      id: this.props.pypeID,
      chat_default_limit: this.state.defaultChatLimit,
      agents_can_initiate_chats: this.state.canInitiateChats,
    }

    const fromTime = this.state.openFromTime;

    let agentHoursData = {
      timezone: this.state.timezone,
      schedule_start: fromTime,
      schedule_end: this.state.openToTime,
      schedule_enabled:true
    }

    if(fromTime === -1){
      agentHoursData = {
        timezone: null,
        schedule_start: null,
        schedule_end: null,
        schedule_enabled: false
      }
    }

    this.setState({
      isUpdating: true
    })

    Promise.all([
      this.props.updatePype(data),
      this.props.updateAgentHours(agentHoursData)
    ])
    .then((res) => {
      window.appCreateToast({
        message: "<span>Changes saved successfully.</span>",
        level: "success"
      })
      this.setState({
        isUpdating: false
      })
      activityTracker.logEvent(activityTracker.eventTypeNames.UPDATE_PYPE_SETTING,{settingName: SETTING_NAME,action: activityTracker.eventActionType.UPDATE, customerName: this.props.customerName});
    }).catch((err) => {
      window.appCreateToast({
        message: "<span>Couldn't save changes.</span>",
        level: "error"
      })
      this.setState({
        isUpdating: false
      })
    })
  }

  hasAgentOperationChanged = () => {
    const {props, state} = this

    if(state.openFromTime !== props.openFromTime
    || state.openToTime !== props.openToTime
    || props.timezone && state.timezone !== props.timezone){
      // if "From" time selected but not "To" time
      if(state.openFromTime !== -1 && state.openToTime === null)
        return false;
      return true;
    } else {
      return false;
    }
  }

  hasChanges = () => {
    const {props, state} = this
    return state.defaultChatLimit !== props.defaultChatLimit
      || state.canInitiateChats !== props.canInitiateChats
      || this.hasAgentOperationChanged()
  }

  renderAgentsTable() {

    const {agents,skills} = this.props;
    const tableHeight = 36 + (36 * Math.min(agents.length, 10))

    return (
      <section className={css.agentsTableWrapper}>
        <TableResponsive
          rowHeight={36}
          rowsCount={agents.length}
          height={tableHeight + 12}
          headerHeight={36}
          overflowX={'hidden'}>
          <Column
            header={<Cell className={css.editCell}></Cell>}
            width={30}
            cell={props => (
              <Cell {...props}>
              <img src={`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-edit-light.svg`} width="20px" height="20px" onClick={this.handleAgentInfo.bind(this, agents[props.rowIndex])}/>
              </Cell>
            )}
          />
          <Column
            header={<Cell className="table-header">Name</Cell>}
            width={150}
            cell={props => (
              <Cell {...props} className={cx({fade: agents[props.rowIndex].user_status === 'invited'},'cell-overflow')}>
                {agents[props.rowIndex].first_name + " " + agents[props.rowIndex].last_name}
                </Cell>
            )}
          />
          <Column
            header={<Cell className="table-header">Email</Cell>}
            width={200}
            cell={props => (
              <Cell {...props} className={cx({fade: agents[props.rowIndex].user_status === 'invited'},'cell-overflow')}>
                {agents[props.rowIndex].email}
              </Cell>
            )}
          />
          <Column
            header={<Cell className="table-header text-right">Chat Limit</Cell>}
            width={70}
            cell={props => (
              <Cell {...props} className={cx({fade: agents[props.rowIndex].user_status === 'invited'},'text-right')}>
                {agents[props.rowIndex].max_conv}
              </Cell>
            )}
          />
          <Column
            header={<Cell className="table-header text-right">Skills</Cell>}
            width={60}
            cell={props => {
              const agentSkills = _(agents[props.rowIndex].skills)
              .filter((skill) => (_.find(skills,{id: skill}))) // NOTE agents have skills that are not in pype (ref: WPM-323)
              .map((skill) => {
                const skillObj = _.find(skills,{id: skill})
                const skillName = skillObj && skillObj.name;

                return skillName
              }).sortBy().sortBy((skill) => {
                return /^general$/i.test(skill) ? 0 : 1; // show general skill first
              }).value()

              return (
                <Cell {...props} className={cx({fade: agents[props.rowIndex].user_status === 'invited'},'text-right','p-r-10')}>
                  <AppTooltip message={agentSkills.join('<br/>')} place="top" onClick={_.noop}>
                    <span className={css.skillCount}>{agentSkills.length === skills.length ? 'All' : agentSkills.length}</span>
                  </AppTooltip>
                </Cell>
              )
            }
          }
          />
        </TableResponsive>
      </section>
    )
  }

  toggleInitiateChats = () => {
    this.setState({
      canInitiateChats: !this.state.canInitiateChats,
    })
  }

  setFromTime = (option) => {
    const {value} = option;
    if(value === -1)
      this.setState({openToTime: null})

    this.setState({openFromTime: value})
  }

  setToTime = (option) => {
    this.setState({openToTime: option.value})
  }

  setTimezone = (option) => {
    this.setState({timezone: option.value})
  }

  render() {
    return (
      <DetailPane className="agents-detail has-footer">
        <ErrorBoundary>
          <ContentBody>
            <section className={this.state.openFromTime !== -1 ? css.dropdownWrapperExpanded : css.dropdownWrapper} style={{paddingTop: 0}}>
              <SubHeader>Hours of operation</SubHeader>
              <section>
                <OperationHours
                  isPypeDataLoading={this.props.isPypeDataLoading}
                  fromTime={this.state.openFromTime}
                  toTime={this.state.openToTime}
                  timezone={this.state.timezone}
                  onFromTimeChange={this.setFromTime}
                  onToTimeChange={this.setToTime}
                  onTimezoneChange={this.setTimezone}/>
              </section>
            </section>
            <PypeManagerFeature>
              <section>
                <SubHeader>Agent list</SubHeader>
                <section>
                  <p>
                  Manage an agent’s skills, chat limit, and additional options below. To add or remove an agent, visit the
                    <a href={`${window.config.PS_CONSOLE_URL}/users`} target="_blank" rel="noopener noreferrer"> console.</a>
                  </p>
                  {this.renderAgentsTable()}
                </section>
              </section>
              <section>
                <SubHeader>Chat limit</SubHeader>
                <section>
                  <p>
                    This is the maximum number of chats that an agent can have in their queue at one time. This number can be changed on a per-agent basis.
                  </p>
                </section>
                <section>
                  <p className={"label-text" + (this.state.errors.defaultChatLimit ? " text-danger" : "")}>Maximum Chats</p>
                  <div className={this.state.errors.defaultChatLimit ? " has-error" : ""}>
                  <input type="number" min="0" max="99"
                        className="form-control agent-max-chats-input"
                        value={this.state.defaultChatLimit}
                        onChange={this.handleDefaultChatLimitChange}
                        onBlur={this.isDefaultChatLimitValid} />
                  </div>
                  <p className="text-danger small">{this.state.errors.defaultChatLimit}</p>
                </section>
              </section>
              <section className="initiate-chats-section">
                <SubHeader>Initiate chats</SubHeader>
                <section>
                  <p>
                    When enabled, agents are allowed to initiate chats with
                    connected users.
                  </p>
                  <LabelCheckbox value={this.state.canInitiateChats} onClick={this.toggleInitiateChats}>
                    Enable agents to initiate chats
                  </LabelCheckbox>
                </section>
              </section>
            </PypeManagerFeature>
          </ContentBody>
        </ErrorBoundary>
        <Footer>
          {this.hasChanges() && <UnsavedChanges />}
          <SaveButton
            className="btn-primary"
            isLoading={this.state.isUpdating}
            disabled={!this.canSubmit() || !this.hasChanges()}
            onClick={this.handleSubmit}>
            Save changes
          </SaveButton>
        </Footer>

        {this.state.agentDetailsModalIsOpen ?
          <ErrorBoundary><AgentModal agent={this.state.selectedAgent} defaultChatLimit={this.props.defaultChatLimit}
           closeModal={this.closeModal} /></ErrorBoundary> :
          null}

      </DetailPane>
    )
  }
}

const mapStateToProps = (state) => {
  const isSchedulingEnabled = state.pype.queues.schedule_enabled;
  const openFromTime = state.pype.queues.schedule_start;
  return {
    pypeID: state.pype.details.id,
    agents: state.agentsForPype.agents,
    defaultChatLimit: state.pype.details.chat_default_limit,
    canInitiateChats: state.pype.details.agents_can_initiate_chats,
    openFromTime: isSchedulingEnabled ? openFromTime : -1,
    openToTime: state.pype.queues.schedule_end,
    timezone: state.pype.queues.timezone,
    skills: state.skills.skillsStore,
    isSchedulingEnabled,
    isPypeUpdating: state.pype.isUpdating,
    isPypeDataLoading: state.pype.isFetching && _.isEmpty(state.pype.queues),
    customerId: state.pype.details.customer_id,
    pypeId: state.pype.details.id,
    customerName: state.pype.details.customerName
  }
}

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({
    fetchAgents: agentsActions.fetchAgents,
    fetchSkills: skillsActions.fetchSkills,
    fetchPypeMetrics: pypeActions.fetchPypeMetrics,
    updateAgentHours: pypeActions.updateAgentHours,
    updatePype: pypeActions.updatePype
  }, dispatch)
)

export default  connect(
  mapStateToProps,
  mapDispatchToProps
)(Agents)
