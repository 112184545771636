// This file defines the public interface to the @admin module

import Admin from './Admin';
import details from './details';
import Agents from './agents';
import CRMData from './crm-data';
import ChatRouting from './chat-routing';
import ManageTags from './manage-tags';
import Webchat from './webchat';
import Broadcasts from './broadcasts';
import PypestreamApi from './pypestream-api';
import UserPrivacy from './user-privacy';
import Skills from './skills';
import CannedResponses from './canned-responses';

export default  {
  Admin,
  Agents,
  CRMData,
  ChatRouting,
  details,
  ManageTags,
  Webchat,
  PypestreamApi,
  UserPrivacy,
  Broadcasts,
  Skills,
  CannedResponses
};
