import _ from 'lodash'
import { BOT_USER_SESSION_LOGOUT } from "bot-user-session";
import {tagConstants, SET_PYPE, initSeqConsts} from '../constants'

const tagInitialState = Object.freeze({
  id: null,
  enable: true,
  code: '',
  description: '',
})

const getInitialState = () => ({
  // isFetching: false,
  // category: {
  //   id: pype ? Object.keys(pype.tags_categories)[0] : undefined,
  //   name: pype ? Object.values(pype.tags_categories)[0] : '',
  // },
  // tagsEnabled: pype ? pype.end_tags_display : false,
  // tagsRequired: pype ? pype.end_tags_required : false,
  // enableTagCodes: pype ? pype.tags_shortcuts : false,
  // enableMultipleTags: pype ? pype.tags_multiple : false,
  // store: {},
  isFetching: false,

  category: {
    id: undefined,
    name: '',
  },

  tagsEnabled: false,
  tagsRequired: false,
  enableMultipleTags: false,
  enableTagCodes: false,

  store: {},
});

const tag = (state={}, action) => {
  switch (action.type) {
    case tagConstants.RECEIVE_TAGS:
      return {
        ...tagInitialState,
        ...state,
        enable: true,
      }
    case tagConstants.CREATE_TAG:
      return {
        ...tagInitialState,
        ...action.data,
        enable: true,
      }
    case tagConstants.UPDATE_TAG:
      return {
        ...state,
        ...action.data,
        enable: true,
      }
    default:
      return state
  }
}

const tags = (state=getInitialState(), action) => {
  switch (action.type) {
    case BOT_USER_SESSION_LOGOUT: {
      return getInitialState();
    }
    case SET_PYPE:
      const {
        tags_categories, 
        end_tags_display, 
        end_tags_required, 
        tags_shortcuts, 
        tags_multiple
      } = action.pype
      let {category} = state
      if (!_.isEmpty(tags_categories)) {
        category = {
          id: _.keys(tags_categories)[0],
          name: _.values(tags_categories)[0],
        }
      }
      return {
        ...state,
        category,
        tagsEnabled: end_tags_display,
        tagsRequired: end_tags_required,
        enableTagCodes: tags_shortcuts,
        enableMultipleTags: tags_multiple,
      }
    case tagConstants.REQUEST_TAGS:
    case initSeqConsts.FETCHING:
      return {
        ...state,
        isFetching: true,
      }
    case tagConstants.RECEIVE_TAGS:
    case initSeqConsts.FETCHED:
      const data = action.type === initSeqConsts.FETCHED ? action.data.tags : action.data;
      if (!data) {
        return state;
      }
      return {
        ...state,
        isFetching: false,
        store: data.reduce((a, b) => ({
          ...a,
          [b.id]: tag(b, action),
        }), {}),
      }
    case tagConstants.CREATE_TAG:
      return {
        ...state,
        isFetching: false,
        store: {
          ...state.store,
          [action.data.id]: tag(undefined, action.data),
        },
      }
    case tagConstants.DESTROY_TAG:
      return {
        ...state,
        isFetching: false,
        store: _.omit(state.store, action.id),
      }
    case tagConstants.UPDATE_TAG:
      return {
        ...state,
        isFetching: false,
        store: {
          ...state.store,
          [action.data.id]: tag(state[action.data.id], action),
        },
      }
    case tagConstants.SAVE_CONFIG:
      return {
        ...state,
        isFetching: true,
      }
    case tagConstants.SAVED_CONFIG:
      return {
        ...state,
        ...action.config,
        isFetching: false,
      }
    case tagConstants.SAVED_CATEGORY:
      return {
        ...state,
        isFetching: false,
        category: {
          ...state.category,
          name: action.data.name,
        },
      }
    default:
      return state
  }
}

export default tags
