import {connect} from 'react-redux';
import Component from './Component';

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch, {isNew, onSubmit}) => ({
  onSubmit () {
    if (isNew) {
      return onSubmit()
    } else {
      return Promise.resolve()
    }
  }
})

export default  connect(
  mapStateToProps,
  mapDispatchToProps
)(Component)
