import {connect} from 'react-redux'
import Component from './Component'
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import sdk from 'sdk';
import { TabTypes } from '../ConversationsList';

const { 
  conversationHistoryActions, 
  conversationsActions,
  chatSessionsWithConsumerActions, 
  streamActions, 
  utils, 
  cannedResponsesActions,
  agentChatSessionsActions
} = sdk
const {fetchChatSessionsWithConsumer} = chatSessionsWithConsumerActions
var { getUnreadChatSessions } = utils.agentChatSessions;

var mapStateToProps = function(state, props) {
  const addComment = state.pype.details.end_comment_display
  const addTags = state.pype.details.end_tags_display
  const hasTags = !_.isEmpty(state.tags.store)
  const history = state.conversationHistory.history
  let chatSessions = [];
  if(props.chatType === TabTypes.escalated) {
    chatSessions = state.agentChatSessions.escalated_chatSessions
  }
  else if(props.chatType === TabTypes.archived) {
    chatSessions = state.agentChatSessions.archived_chatSessions
  }
  else {
    chatSessions = state.agentChatSessions.chatSessions;
  }
  const chat = _.find(chatSessions, c => c.id === props.match.params.id)
  const agentId = state.agent.id

  return {
    agentId,
    userId: state.botUserSession.user.user_id,
    chat,
    isAnon: chat != null && chat.consumer_type === 'anonymous',
    chat_id: state.conversationHistory.id,
    isEmpty: history.length === 0,
    // TODO
    isSessionEnded: chatSessions && chatSessions.find((session) => session.id === state.conversationHistory.id && session.status === 'ended'),
    // isEnded: true,
    doesChatExist: _.find(
      chatSessions,
      ({id}) => id === state.conversationHistory.id
    ) != null,
    nMessages: state.conversationHistory.history.length,
    conversationHistory: state.conversationHistory.history,
    unreadConversations: getUnreadChatSessions(state),
    stream_name: state.conversationHistory.stream_name,
    selectedStreamId: state.conversationHistory.selectedStreamId,
    // The active conversation stream
    stream: _.find(
      state.streamsForPype.streams,
      (pype) => pype.id === state.conversationHistory.selectedStreamId
    ),

    consumer_name: state.conversationHistory.consumer_name,
    consumer: props.match.params.consumerId,
    createdChat: state.conversationHistory.createdChat,
    isFetchingHistory: state.conversationHistory.isFetching,
    isFetching: state.users.isFetchingSubscribedStreams || state.agentChatSessions.isFetching || state.conversationHistory.isFetching || state.cannedResponses.isFetching,
    uploadProgress: state.conversationHistory.uploadProgress,
    fileToUpload: state.conversationHistory.fileToUpload,
    isUploadingFile: state.conversationHistory.isUploadingFile,
    uploadedFileURL: state.conversationHistory.uploadedFileURL,
    to_be_migrated: state.conversationHistory.to_be_migrated,
    subscribedStreams: state.users.subscribedStreams,
    msgRequestTimeout: state.conversationHistory.msgRequestTimeout,
    failedChatId: state.conversationHistory.failedChatId,
    chatSessions,
    // Post chat related stuff
    addComment,
    addTags,
    hasTags,
    hasPostChatAction: addComment || (addTags && hasTags),
    commentRequired: state.pype.details.end_comment_required,
    tagsRequired: state.pype.details.end_tags_required,
    isConsumerTyping: state.conversationHistory.isConsumerTyping,
    isAgentTyping: state.conversationHistory.isAgentTyping,
    isAgentOffline: utils.agent.getStatus(state.agent) === 'offline',
    isBrowserStatusOffline: state.agent.isBrowserStatusOffline,
    // Canned responses
    cannedResponses: state.cannedResponses.cannedResponses,
    cannedResponseCategories: state.cannedResponses.categories,
    agentFirstName: state.agent.first_name,
    consumerName: state.conversationHistory.consumer_name,
    consumerFirstName: state.conversationHistory.consumer_first_name,
    consumerLastName: state.conversationHistory.consumer_last_name,
    consumer_data: state.conversationHistory.consumer_data,
    skills: state.skills.skillsStore

  };
};

var mapDispatchToProps = function(dispatch) {
  return bindActionCreators({
    uploadFile: conversationHistoryActions.uploadFile,
    fetchChatSessionsWithConsumer: fetchChatSessionsWithConsumer,
    fetchConversationHistory: conversationHistoryActions.fetchConversationHistory,
    removeAttachment: conversationHistoryActions.removeAttachment,
    fetchStreamsIfNeeded: streamActions.fetchStreamsIfNeeded,
    sendMessageWithAttachment: conversationHistoryActions.sendMessageWithAttachment,
    appendMessageToConversation: conversationsActions.appendMessageToConversation,
    toggleAgentTypingStatus: conversationHistoryActions.toggleAgentTypingStatus,
    fetchCannedResponse: cannedResponsesActions.fetchCannedResponses,
    removeChatSession: agentChatSessionsActions.removeChatSession,
    createChat: conversationHistoryActions.createChat,
    sendEmbedMessage: conversationHistoryActions.sendEmbedMessage,
    classifyChat: conversationsActions.classifyChat,
    readMessages: conversationHistoryActions.readMessages
  }, dispatch);
};

export default  connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
