import {connect} from 'react-redux';
import Component from './Component';
import sdk from 'sdk';
const {apiActions} = sdk;

const mapDispatchToProps = (dispatch) => ({
  onSubmit (api, name) {
    if (Object.keys(api).length === 0 && api.constructor === Object) {
      let data = {
        friendly_name: name,
      }
      return dispatch(apiActions.createApi(data))
    }
  }
})

const mapStateToProps = (state) => {
  return {
    pypeName: state.pype.details.name,
    customerName: state.pype.details.customerName
  }
};

export default  connect(mapStateToProps, mapDispatchToProps)(Component)
