import _ from 'lodash'
import {connect} from 'react-redux';
import Component from './Component';
import sdk from 'sdk';
const {pypeActions} = sdk;

const mapDispatchToProps = (dispatch) => ({

  onDelete (maskingRules) {
    return dispatch(pypeActions.createPatternRule(maskingRules))
  }
})

const mapStateToProps = (state) => {
  return {
    pypeName: state.pype.details.name,
    customerName: state.pype.details.customerName
  }
}

export default  connect(
  mapStateToProps,
  mapDispatchToProps
)(Component)
