import React from "react";
import styled, {keyframes} from 'styled-components';

const IconNames = {
  ArrowUp: 'arrow-up',
  ArrowRight: 'arrow-right',
  ArrowRoute: 'arrow-route',
  ChevronUp: 'chevron-up',
  ChevronDown: 'chevron-down',
  ChevronLeft: 'chevron-left',
  ChevronRight: 'chevron-right',
  ContentText: 'content-text',
  ContentGeneric: 'content-generic',
  ContentImage: 'content-image',
  ContentLink: 'content-link',
  ToggleShow: 'toggle-show',
  ToggleHide: 'toggle-hide',
  ToggleLock: 'toggle-lock',
  ToggleUnlock: 'toggle-unlock',
  ToggleFavorite: 'toggle-favorite',
  ToggleUnfavorite: 'toggle-unfavorite',
  Edit: 'edit',
  Copy: 'copy',
  OpenLink: 'open-link',
  Upload: 'upload',
  Download: 'download',
  Search: 'search',
  Loading: 'loading',
  Sort: 'sort',
  Drag: 'drag',
  Plus: 'plus',
  Close: 'close',
  Check: 'check',
  InputError: 'input-error',
  Info: 'info',
}

const rotate = keyframes`
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
`;

const SVG = styled.svg`
  animation:  ${props => props.rotateIcon ? `${rotate} 2s linear infinite;` : 'none'};
`;

const renderIconChild = (name, fill, fillrule) => {
  switch (name) {
    case IconNames.ArrowUp:
      return (
        <g fill="none" fillRule={fillrule}>
          <circle cx="12" cy="12" r="12"/>
          <path fill={fill} d="M11.88 17.653l-.116-.006c-.497-.058-.883-.481-.883-.994l.002-6.054-1.699 1.698c-.36.36-.927.389-1.32.084l-.094-.084-.353-.353c-.39-.391-.39-1.024 0-1.415L10.83 7.11l.049-.06.053-.06.354-.353.094-.083c.164-.127.358-.197.554-.208h.137c.236.014.468.11.648.291l.354.354c.038.038.072.079.103.121l3.413 3.417c.36.36.388.928.083 1.32l-.083.095-.353.354-.094.083c-.36.28-.867.28-1.226 0l-.094-.084-1.44-1.441-.002 5.797c0 .513-.386.935-.883.993l-.117.007h-.5z"/>
        </g>
      );
    case IconNames.ArrowRight:
      return (
        <g fill="none" fillRule={fillrule}>
          <circle cx="12" cy="12" r="12"/>
          <path fill={fill} d="M6.35 11.877l.007-.117c.057-.497.48-.883.993-.883l6.054.002-1.698-1.699c-.36-.36-.389-.927-.084-1.32l.084-.094.353-.353c.391-.39 1.024-.39 1.415 0l3.419 3.414.06.049.06.053.353.354.083.094c.127.164.197.358.208.554v.137c-.014.236-.11.468-.29.648h-.001l-.354.354c-.038.038-.079.072-.121.103l-3.417 3.414c-.36.36-.928.387-1.32.082l-.095-.083-.354-.353-.083-.094c-.28-.36-.28-.867 0-1.226l.084-.094 1.441-1.44-5.797-.002c-.513 0-.935-.386-.993-.883l-.007-.117v-.5z"/>
        </g>
      );
    case IconNames.ArrowRoute:
      return (
        <g fill="none" fillRule={fillrule}>
          <circle cx="12" cy="12" r="12"/>
          <path fill={fill} d="M7.5 11.798l.002-3.678c0-.552.448-1 1-1h.5l.117.006c.497.058.883.481.883.994L10 10.801l4.477.001-1.621-1.622c-.36-.36-.389-.927-.084-1.32l.083-.094.354-.353c.391-.39 1.024-.39 1.415 0l3.419 3.414.06.049.06.053.353.354.083.094c.127.164.197.358.208.554v.137c-.014.236-.11.468-.29.648h-.001l-.354.354c-.038.038-.079.072-.121.103l-3.417 3.414c-.36.36-.928.387-1.32.082l-.095-.083-.353-.353-.084-.094c-.28-.36-.28-.867 0-1.226l.084-.094 1.518-1.517L8.5 13.3c-.513 0-.935-.386-.993-.883L7.5 12.3v-.502z"/>
        </g>
      );
    case IconNames.ChevronUp:
      return (
        <g fill="none" fillRule={fillrule}>
          <circle cx="12" cy="12" r="12"/>
          <path fill={fill} d="M12.613 7.838l.094.083.354.354c.037.037.071.077.102.12l3.415 3.418c.39.39.39 1.024 0 1.414l-.354.354-.094.084c-.393.304-.96.277-1.32-.084l-2.82-2.82-2.819 2.82c-.36.36-.927.388-1.32.084l-.094-.084-.353-.354c-.39-.39-.39-1.023 0-1.414l3.413-3.417c.03-.042.065-.083.104-.121l.353-.354c.18-.18.412-.277.648-.291h.137c.197.011.39.08.554.208z"/>
        </g>
      );
    case IconNames.ChevronDown:
      return (
        <g fill="none" fillRule={fillrule}>
          <circle cx="12" cy="12" r="12" />
          <path fill={fill} d="M11.293 16.08v-.001l-.354-.354c-.038-.038-.072-.079-.103-.121l-3.414-3.417c-.39-.39-.39-1.024 0-1.414l.354-.354.094-.084c.393-.304.96-.277 1.32.084l2.819 2.82 2.82-2.82c.36-.36.927-.388 1.32-.084l.094.084.353.354c.39.39.39 1.023 0 1.414l-3.414 3.419c-.03.042-.065.082-.103.12l-.353.353-.094.083c-.164.127-.358.197-.554.208h-.137c-.236-.014-.468-.11-.648-.29z"/>
        </g>
      );
    case IconNames.ChevronRight:
      return (
        <g fill="none" fillRule={fillrule}>
          <circle cx="12" cy="12" r="12" />
          <path fill={fill} d="M15.178 13.502l-.084.094-.353.354c-.038.037-.078.071-.12.102l-3.419 3.415c-.39.39-1.024.39-1.414 0l-.354-.354-.083-.094c-.305-.393-.277-.96.083-1.32l2.82-2.82-2.82-2.819c-.36-.36-.388-.927-.083-1.32l.083-.094.354-.353c.39-.39 1.023-.39 1.414 0l3.417 3.413c.043.03.083.065.122.103l.353.354c.18.18.278.412.291.648v.137c-.011.196-.08.39-.207.554z"/>
        </g>
      );
    case IconNames.ChevronLeft:
      return (
        <g fill="none" fillRule={fillrule}>
          <circle cx="12" cy="12" r="12" />
          <path fill={fill} d="M8.936 11.283l.354-.354c.038-.038.079-.072.121-.103l3.417-3.413c.391-.39 1.024-.39 1.415 0l.353.353.084.095c.305.392.277.96-.084 1.32l-2.82 2.818 2.82 2.82c.36.36.389.927.084 1.32l-.083.094-.354.353c-.391.39-1.024.39-1.415 0L9.41 13.173c-.041-.03-.081-.065-.12-.102l-.353-.354-.083-.094c-.127-.164-.197-.358-.208-.554v-.137c.014-.236.11-.468.291-.648z"/>
        </g>
      );
    case IconNames.ContentText:
      return (
        <g fill="none" fillRule={fillrule}>
          <circle cx="12" cy="12" r="12" />
          <path fill={fill} d="M17 8.752L7 8.75c-.552 0-1-.448-1-1v-.5l.007-.117c.057-.497.48-.883.993-.883l10 .002c.552 0 1 .448 1 1v.5l-.007.117c-.057.497-.48.883-.993.883zm0 4.502l-10-.002c-.552 0-1-.448-1-1v-.5c0-.553.448-1 1-1l10 .002c.552 0 1 .448 1 1v.5l-.007.117c-.057.497-.48.883-.993.883zm-2 4.5l-8-.002c-.552 0-1-.448-1-1v-.5l.007-.117c.057-.497.48-.883.993-.883l8 .002c.552 0 1 .448 1 1v.5l-.007.117c-.057.497-.48.883-.993.883z"/>
        </g>
      );
    case IconNames.ContentGeneric:
      return (
        <g fill="none" fillRule={fillrule}>
          <circle cx="12" cy="12" r="12" />
          <path fill={fill} d="M16.749 18.003l-.02-.001-9.441-.001-.04.002h-.5l-.116-.007c-.497-.058-.883-.48-.883-.993l.002-10c0-.552.448-1 1-1h.21L7 6l10 .002h.251l.117.007c.497.058.883.481.883.994l-.002 10c0 .552-.448 1-1 1h-.5zm-1-2.501l.002-7-7.5-.001-.001 7 7.5.001z"/>
        </g>
      );
    case IconNames.ContentImage:
      return (
        <g fill="none" fillRule={fillrule}>
          <circle cx="12" cy="12" r="12" />
          <path fill={fill} d="M18 5.75c.69 0 1.25.56 1.25 1.25v10c0 .69-.56 1.25-1.25 1.25H6c-.69 0-1.25-.56-1.25-1.25V9c0-.69.56-1.25 1.25-1.25h4.75V7c0-.647.492-1.18 1.122-1.244L12 5.75zm-1.25 2.5h-3.5V9c0 .647-.492 1.18-1.122 1.244L12 10.25H7.25v5.5h9.5v-7.5zM12 11c1.105 0 2 .895 2 2s-.895 2-2 2-2-.895-2-2 .895-2 2-2z"/>
        </g>
      );
    case IconNames.ContentLink:
      return (
        <g fill="none" fillRule={fillrule}>
          <circle cx="12" cy="12" r="12" />
          <path fill={fill} d="M10.127 16.106l-.71.705c-.617.608-1.608.608-2.226 0-.292-.29-.457-.684-.457-1.096 0-.411.165-.806.457-1.095l2.608-2.586c.54-.536 1.556-1.326 2.297-.59.217.231.544.325.851.246.307-.08.547-.32.624-.629.078-.308-.02-.633-.252-.849-1.259-1.25-3.12-1.018-4.742.59L5.97 13.39c-.623.614-.972 1.452-.97 2.326-.002.874.347 1.712.97 2.326.62.616 1.46.96 2.335.959.846 0 1.692-.32 2.335-.959l.71-.704c.163-.162.256-.382.256-.612.001-.23-.09-.45-.252-.613-.338-.34-.886-.342-1.227-.006zM18.03 6.058c-1.354-1.343-3.246-1.415-4.497-.173l-.883.877c-.22.218-.306.537-.227.837.079.299.312.533.61.615.3.08.619-.003.839-.222l.883-.876c.648-.644 1.497-.377 2.053.173.294.293.457.681.457 1.095 0 .414-.163.803-.457 1.095l-2.782 2.76c-1.273 1.26-1.87.67-2.124.417-.22-.218-.54-.302-.838-.221-.299.081-.532.315-.61.615-.08.3.007.618.227.836.584.58 1.25.867 1.949.867.855 0 1.759-.43 2.619-1.284l2.782-2.759c.621-.614.97-1.452.969-2.326.002-.874-.348-1.712-.97-2.326z"/>
        </g>
      );
    case IconNames.ToggleShow:
      return (
        <g fill="none" fillRule={fillrule}>
          <circle cx="12" cy="12" r="12" />
          <path fill={fill} d="M14.5 12c0 1.103-.897 2-2 2s-2-.897-2-2 .897-2 2-2 2 .897 2 2zm5-.28S16.843 17 12.01 17c-4.488 0-7.51-5.28-7.51-5.28S7.279 7 12.01 7c4.808 0 7.49 4.72 7.49 4.72zm-4 .28c0-1.654-1.346-3-3-3s-3 1.346-3 3 1.346 3 3 3 3-1.346 3-3z"/>
        </g>
      );
    case IconNames.ToggleHide:
      return (
        <g fill="none" fillRule={fillrule}>
          <circle cx="12" cy="12" r="12" />
          <path fill={fill} d="M15.717 4.822l.866.5-8 13.856-.866-.5 1.394-2.414C6.27 14.812 4.5 11.719 4.5 11.719S7.279 7 12.01 7c.808 0 1.557.134 2.243.356l1.464-2.534zm.267 3.337c2.287 1.397 3.516 3.56 3.516 3.56S16.843 17 12.01 17c-.368 0-.726-.035-1.073-.1l1.117-1.933c.146.022.294.033.446.033 1.654 0 3-1.346 3-3 0-.706-.245-1.355-.654-1.868l1.138-1.973zM12.5 9c-1.654 0-3 1.346-3 3 0 .817.328 1.558.86 2.1l.528-.917c-.244-.331-.388-.74-.388-1.183 0-1.103.897-2 2-2 .074 0 .148.004.22.012l.53-.917C13.01 9.033 12.759 9 12.5 9zm1.786 2.1c.137.271.214.577.214.9 0 1.065-.837 1.938-1.887 1.997l1.673-2.896z"/>
        </g>
      );
    case IconNames.ToggleUnlock:
      return (
        <g fill="none" fillRule={fillrule}>
          <circle cx="12" cy="12" r="12" />
          <path fill={fill} d="M11.958 4.75c2.525 0 3.816 1.61 4.206 4.184l-1.483.225-.036-.218c-.318-1.781-1.108-2.691-2.687-2.691-1.641 0-2.43.984-2.723 2.908-.084.558-.116 1.05-.124 1.8l.003-.208h7.315c.38 0 .693.282.743.648l.007.102v6c0 .414-.336.75-.75.75H7c-.414 0-.75-.336-.75-.75v-6c0-.414.336-.75.75-.75l.614-.002c.012-.714.049-1.224.138-1.815.391-2.574 1.682-4.183 4.206-4.183zm3.28 7.75H8.19v4h7.048v-4z"/>
        </g>
      );
    case IconNames.ToggleLock:
      return (
        <g fill="none" fillRule={fillrule}>
          <circle cx="12" cy="12" r="12" />
          <path fill={fill} d="M11.814 4.75c2.525 0 3.815 1.609 4.206 4.183.09.591.126 1.101.139 1.815l.27.002c.414 0 .75.336.75.75v6c0 .414-.336.75-.75.75H7c-.414 0-.75-.336-.75-.75v-6c0-.414.336-.75.75-.75l.47-.002c.012-.714.049-1.224.138-1.815C8 6.359 9.29 4.75 11.814 4.75zm3.424 7.75H8.19v4h7.048v-4zm-3.424-6.25c-1.641 0-2.43.984-2.723 2.908l-.048.366c-.043.371-.064.745-.073 1.224l5.688.002v-.062c-.014-.604-.047-1.042-.12-1.53-.293-1.924-1.082-2.908-2.724-2.908z"/>
        </g>
      );
    case IconNames.ToggleUnfavorite:
      return (
        <g fill="none" fillRule={fillrule}>
          <circle cx="12" cy="12" r="12" />
          <path fill={fill} d="M11.823 4.831c.216-.087.459-.004.579.187l.039.076 1.807 4.49 4.676.417c.118.01.228.065.309.153.154.17.163.42.033.6l-.064.07-3.57 3.256 1.064 4.802c.028.123.005.252-.062.358-.126.197-.371.27-.58.186l-.076-.04L12 16.86l-3.978 2.527c-.106.068-.235.09-.357.063-.228-.05-.381-.256-.372-.481l.01-.086 1.065-4.802-3.57-3.255c-.088-.08-.142-.19-.153-.309-.02-.232.13-.44.347-.5l.084-.015 4.676-.418 1.807-4.489c.049-.12.144-.215.264-.263zm1.377 6.165L12 8.015l-1.2 2.98-3.269.292 2.49 2.27-.719 3.238L12 15.082l2.697 1.713-.717-3.238 2.488-2.27-3.268-.291z"/>
        </g>
      );
    case IconNames.ToggleFavorite:
      return (
        <g fill="none" fillRule={fillrule}>
          <circle cx="12" cy="12" r="12" />
          <path fill={fill} d="M12 16.859l3.978 2.527c.221.14.515.075.656-.146.067-.106.09-.235.062-.358l-1.064-4.802 3.57-3.255c.194-.177.207-.477.03-.671-.08-.088-.19-.143-.308-.153l-4.676-.418-1.807-4.489c-.098-.243-.375-.36-.618-.263-.12.048-.215.143-.264.263l-1.807 4.49L5.076 10c-.261.023-.454.254-.43.515.01.119.064.229.152.309l3.57 3.255-1.064 4.802c-.057.257.105.51.36.567.123.027.252.005.358-.063l3.808-2.42.17-.107z"/>
        </g>
      );
    case IconNames.Edit:
      return (
        <g fill="none" fillRule={fillrule}>
          <circle cx="12" cy="12" r="12" />
          <path fill={fill} d="M9.338 17.506l-2.38.828c-.521.181-1.091-.095-1.273-.616-.073-.213-.073-.444 0-.657l.827-2.38 2.826 2.825zm8.746-8.777l-7.776 7.78-2.826-2.825 7.776-7.78c.36-.362.927-.39 1.32-.084l.094.083 1.411 1.412c.39.39.39 1.024 0 1.414z"/>
        </g>
      );
    case IconNames.Copy:
      return (
        <g fill="none" fillRule={fillrule}>
          <circle cx="12" cy="12" r="12" />
          <path fill={fill} d="M18.002 8.75l.117.007c.459.053.823.417.876.876l.007.117-.002 8c0 .513-.386.935-.883.993L18 18.75h-.52l-7.729-.003c-.513 0-.935-.386-.993-.883l-.007-.117v-.5l.007-.117c.053-.459.417-.823.876-.876l.117-.007 6.75.002V9.75c.001-.513.387-.935.885-.993l.116-.007h.5zM6.251 5.25l8 .002h.251l.117.007c.459.054.823.418.876.877l.007.117-.002 8c0 .513-.386.935-.884.993l-.116.007H14l-.02-.001-7.441-.001-.04.002H6l-.116-.007c-.46-.053-.823-.418-.876-.877L5 14.253l.002-8c0-.513.386-.936.883-.993l.117-.007h.21l.039-.003zM7.5 7.751v5l5.5.001v-5l-5.5-.001z"/>
        </g>
      );
    case IconNames.OpenLink:
      return (
        <g fill="none" fillRule={fillrule}>
          <circle cx="12" cy="12" r="12" />
          <path fill={fill} d="M6 9l3 .001c.514 0 .936.387.994.884l.007.117v.5c0 .513-.386.935-.884.993l-.116.006-1.75-.001-.001 4.999 6.5.002.002-2.498c0-.513.386-.936.883-.994l.117-.006h.5l.117.006c.459.053.823.418.876.877l.007.117-.002 4c0 .512-.386.935-.884.993l-.116.007h-.5L14.728 19l-8.43-.002-.048.004h-.5l-.117-.007c-.46-.053-.823-.418-.876-.877l-.007-.116.002-8c0-.513.386-.936.883-.994l.117-.006.209-.001.04-.002zm12.134-4l.117.005c.23.022.437.123.595.276l.014.013c.158.152.266.356.296.585l.01.116h-.001l.009.5c0 .055-.003.108-.01.16l.081 4.829c.009.513-.37.942-.866 1.008l-.116.01-.5.008-.117-.004c-.46-.046-.83-.404-.891-.862l-.01-.116-.038-2.235-3.935 3.798c-.367.354-.934.372-1.321.06l-.093-.085-.347-.36-.082-.095c-.273-.364-.264-.871.022-1.225l.085-.093L14.89 7.57l-2.201.04c-.552.01-1.008-.43-1.018-.982l-.008-.5c-.01-.552.43-1.008.982-1.018l4.83-.087.079-.01.079-.005.5-.009z"/>
        </g>
      );
    case IconNames.Search:
      return (
        <g fill="none" fillRule={fillrule}>
          <circle cx="12" cy="12" r="12" />
          <path fill={fill} d="M12.357 6.5c2.761 0 5 2.239 5 5s-2.239 5-5 5c-.923 0-1.787-.25-2.529-.685l-2.705 2.703c-.39.39-1.024.39-1.414 0l-.354-.354-.083-.094c-.305-.393-.277-.96.083-1.32l2.7-2.699c-.443-.747-.698-1.62-.698-2.551 0-2.761 2.238-5 5-5zm0 2c-1.657 0-3 1.343-3 3s1.343 3 3 3 3-1.343 3-3-1.343-3-3-3z"/>
        </g>
      );
    case IconNames.Upload:
      return (
        <g fill="none" fillRule={fillrule}>
          <circle cx="12" cy="12" r="12" />
          <path fill={fill} d="M11.748 18.5l-.117-.006c-.497-.058-.883-.481-.883-.994l.002-4.482-1.579 1.578c-.36.36-.927.389-1.32.084l-.094-.083-.353-.354c-.39-.391-.39-1.024 0-1.415l3.414-3.419.049-.06.054-.06.291-.29H7c-.513-.001-.935-.387-.993-.885L6 7.998v-.5l.007-.117c.057-.497.48-.883.993-.883L17 6.5c.552 0 1 .448 1 1V8l-.007.117c-.057.497-.48.883-.993.883h-4.23l.29.29c.039.038.073.079.104.121l3.414 3.417c.36.36.387.928.083 1.32l-.084.095-.353.353-.094.084c-.36.28-.867.28-1.226 0l-.094-.084-1.56-1.56-.002 4.464c0 .513-.386.935-.884.993l-.116.007h-.5z"/>
        </g>
      );
    case IconNames.Download:
      return (
        <g fill="none" fillRule={fillrule}>
          <circle cx="12" cy="12" r="12" />
          <path fill={fill} d="M12.252 5.5l.117.006c.497.058.883.481.883.994l-.002 4.482 1.579-1.578c.36-.36.927-.389 1.32-.084l.094.083.353.354c.39.391.39 1.024 0 1.415l-3.414 3.419-.049.06-.054.06-.291.29H17c.513.001.935.387.993.885l.007.116v.5l-.007.117c-.057.497-.48.883-.993.883L7 17.5c-.552 0-1-.448-1-1V16l.007-.117c.057-.497.48-.883.993-.883h4.23l-.29-.29c-.039-.038-.073-.079-.104-.121l-3.414-3.417c-.36-.36-.387-.928-.083-1.32l.084-.095.353-.353.094-.084c.36-.28.867-.28 1.226 0l.094.084 1.56 1.56.002-4.464c0-.513.386-.935.884-.993l.116-.007h.5z"/>
        </g>
      );
    case IconNames.Loading:
      return (
        <g fill="none" fillRule={fillrule}>
          <circle cx="12" cy="12" r="12" />
          <circle cx="6.2" cy="12" r="1.5" fill={fill} fillOpacity=".1"/>
          <circle cx="12" cy="17.8" r="1.5" fill={fill} fillOpacity=".4"/>
          <circle cx="12" cy="6.2" r="1.5" fill={fill}/>
          <circle cx="16.2" cy="7.85" r="1.5" fill={fill} fillOpacity=".85"/>
          <circle cx="16.2" cy="16.15" r="1.5" fill={fill} fillOpacity=".55"/>
          <circle cx="7.85" cy="16.15" r="1.5" fill={fill} fillOpacity=".25"/>
          <circle cx="17.8" cy="12" r="1.5" fill={fill} fillOpacity=".7"/>
        </g>
      );
    case IconNames.Sort:
      return (
        <g fill="none" fillRule={fillrule}>
          <circle cx="12" cy="12" r="12" />
          <path fill={fill} d="M11.274 17.691l-.353-.354c-.038-.038-.072-.078-.103-.12l-2.352-2.356c-.39-.39-.39-1.024 0-1.414l.354-.354.094-.084c.392-.304.96-.276 1.32.084l1.757 1.76 1.757-1.76c.36-.36.927-.388 1.32-.084l.094.084.353.354c.39.39.39 1.023 0 1.414l-2.354 2.358c-.03.042-.063.081-.1.118l-.354.354c-.18.18-.412.277-.648.291h-.137c-.236-.014-.468-.11-.648-.29zM12.707 5.92v.001l.354.354c.037.037.071.078.102.12l2.352 2.356c.39.39.39 1.024 0 1.414l-.353.354-.095.083c-.392.305-.96.277-1.32-.083l-1.757-1.76-1.757 1.76c-.36.36-.927.388-1.32.083l-.093-.083-.354-.354c-.39-.39-.39-1.023 0-1.414l2.354-2.359c.03-.04.063-.08.1-.117l.354-.354c.18-.18.412-.278.648-.291h.137c.236.013.468.11.648.29z"/>
        </g>
      );
    case IconNames.Drag:
      return (
        <g fill="none" fillRule={fillrule}>
          <circle cx="12" cy="12" r="12" />
          <path fill={fill} d="M14.153 15.667h-.5c-.054 0-.107-.005-.159-.013l-4.83-.003c-.552 0-1-.448-1-1v-.5l.008-.126c.062-.493.482-.874.992-.874l3.988.002V9.164c-.001-.51.38-.93.873-.992l.126-.008h.5c.552 0 1 .448 1 1l.003 4.832c.008.051.012.104.012.157v.5l-.007.126c-.026.205-.114.391-.245.538l-.097.097c-.176.157-.409.253-.664.253z"/>
        </g>
      );
    case IconNames.Plus:
      return (
        <g fill="none" fillRule={fillrule}>
          <circle cx="12" cy="12" r="12" />
          <path fill={fill} d="M12.25 5.75c.552 0 1 .448 1 1v4h4c.552 0 1 .448 1 1v.5c0 .552-.448 1-1 1h-4.001l.001 4c0 .552-.448 1-1 1h-.5c-.552 0-1-.448-1-1l-.001-4.001-3.999.001c-.552 0-1-.448-1-1v-.5c0-.552.448-1 1-1l4-.001V6.75c0-.552.448-1 1-1h.5z"/>
        </g>
      );
    case IconNames.Close:
      return (
        <g fill="none" fillRule={fillrule}>
          <circle cx="12" cy="12" r="12" />
          <path fill={fill} d="M9.172 7.404L12 10.232l2.828-2.828c.391-.39 1.024-.39 1.415 0l.353.353c.39.39.39 1.024 0 1.415L13.768 12l2.828 2.828c.39.391.39 1.024 0 1.415l-.353.353c-.39.39-1.024.39-1.415 0L12 13.768l-2.828 2.828c-.391.39-1.024.39-1.415 0l-.353-.353c-.39-.39-.39-1.024 0-1.415L10.232 12 7.404 9.172c-.39-.391-.39-1.024 0-1.415l.353-.353c.39-.39 1.024-.39 1.415 0z"/>
        </g>
      );
    case IconNames.Check:
      return (
        <g fill="none" fillRule={fillrule}>
          <circle cx="12" cy="12" r="12" />
          <path fill={fill} d="M9.945 15.803l-.094-.084-.354-.353c-.038-.039-.073-.08-.104-.123l-2.35-2.354c-.39-.39-.39-1.023 0-1.414l.353-.354c.39-.39 1.024-.39 1.414 0l1.758 1.759 4.585-4.587c.36-.36.927-.388 1.32-.083l.094.083.353.354c.39.39.39 1.023 0 1.414l-5.18 5.183c-.03.043-.064.083-.103.122l-.353.353-.094.084c-.164.127-.358.196-.554.208h-.137c-.197-.012-.39-.081-.554-.208z"/>
        </g>
      );
    case IconNames.InputError:
      return (
        <g fill="none" fillRule={fillrule}>
          <circle cx="12" cy="12" r="12" />
          <path fill={fill} d="M12.49 4.168c.16.09.292.222.382.381l7.29 12.96c.27.482.1 1.092-.382 1.363-.15.084-.318.128-.49.128H4.71c-.552 0-1-.448-1-1 0-.172.044-.34.128-.49l7.29-12.96c.271-.482.88-.653 1.362-.382zM12 15.5c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1zm0-8.3h-.033c-.533.019-.951.466-.933 1l.19 5.55c.015.418.358.75.776.75.418 0 .761-.332.776-.75l.19-5.55v-.034c0-.533-.432-.966-.966-.966z"/>
        </g>
      );
    case IconNames.Info:
      return (
        <g fill="none" fillRule={fillrule}>
          <circle cx="12" cy="12" r="12" />
          <path fill={fill} d="M12 6.5c3.038 0 5.5 2.462 5.5 5.5s-2.462 5.5-5.5 5.5-5.5-2.462-5.5-5.5S8.962 6.5 12 6.5zm0 1.106c-2.427 0-4.394 1.967-4.394 4.394S9.573 16.394 12 16.394s4.394-1.967 4.394-4.394S14.427 7.606 12 7.606zM12.04 11c.04 0 .079.003.117.01h.076c.286 0 .517.23.517.516 0 .03-.002.057-.007.085.005.032.007.065.007.1v2.579c0 .392-.318.71-.71.71-.392 0-.71-.318-.71-.71v-2.247h-.063c-.286 0-.517-.231-.517-.517 0-.285.231-.516.517-.516h.656c.038-.007.077-.01.117-.01zm-.073-2c.433 0 .783.347.783.775 0 .428-.35.775-.783.775-.432 0-.782-.347-.782-.775 0-.428.35-.775.782-.775z"/>
        </g>
      );
    default:
      return;
  }
};

const Icon = ({ name, fill='#000', fillrule='evenodd', style={}, width='24px', viewBox='0 0 24 24', ...props }) => {
  return(
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={width}
      style={style}
      viewBox={viewBox}
      fill="none"
      {...props}
    >
      {renderIconChild( name, fill, fillrule )}
    </SVG>
  );
}

export { Icon, IconNames };
