import style from './style.css';
import moment from 'moment';
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames';

const ChatTimestamp = (props) => (
  <div className={classNames(style.ChatTimestamp, props.className)} style={props.style}>
    {moment(props.date)[props.format]()}
  </div>
)

ChatTimestamp.propTypes = {
  format: PropTypes.oneOf(['calendar', 'fromNow']),
  date: PropTypes.number.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
}

ChatTimestamp.defaultProps = {
  format: 'calendar',
  className: '',
  style: {},
}

export default  ChatTimestamp
