import style from './style.css';
import React from 'react'
import PropTypes from 'prop-types'

const Banner = (props) => (
  <div className={style.Banner}>
    <img className={style.Image} src={props.url} />
    <div className={style.CloseBox}>
      <img className={style.Close} src={`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-white-x.svg`} />
    </div>
  </div>
)

Banner.propTypes = {
  url: PropTypes.string.isRequired,
}

export default  Banner
