import React from 'react'
import { Route, withRouter, Switch, Redirect } from "react-router-dom";

// UI components
import DetailPane from '../../details/DetailPane';
import TitleBar from '../../details/TitleBar';
import ContentBody from '../../details/ContentBody';

// Local imports
import ManageCannedResponse from '../ManageCannedResponse';
import CreateCannedResponse from '../CreateCannedResponse';

const CannedResponses = (props) => {
  const { match } = props

  return (
  <DetailPane>
    <ContentBody>
      <Switch>
        <Route exact path={`${match.path}`}>
          <Redirect to={`${match.url}/manage`}/>
        </Route>
        <Route
          exact
          path={`${match.path}/create`}
          component={CreateCannedResponse}
        />
        <Route
          exact
          path={`${match.path}/manage`}
          component={ManageCannedResponse}
        />
        <Route exact path={`*`}>
          <Redirect to={`/not-found`}/>
        </Route>
      </Switch>
    </ContentBody>
  </DetailPane>);
};

export default withRouter(CannedResponses);
