import React from 'react';
import PropTypes from 'prop-types'
import {findDOMNode} from 'react-dom';
import cx from 'classnames';
import _ from 'lodash';
import style from './style.css';
import {Link, withRouter} from 'react-router-dom';
import SkillsListItem from '../SkillsListItem';


const NoSkills = (props) => {
  return (
    <div className={style.NoSkills}>
      <h4>No skills created yet</h4>
      <p><Link to="skills">Click here</Link> to manage your skills</p>
    </div>
  )
}

class Component extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isScrolled: false
    }
    this.handleScroll = this.handleScroll.bind(this)
  }
  componentDidMount () {
    this.bindScroll(true);
  }
  componentWillUnmount () {
    this.bindScroll(false);
  }
  bindScroll(isBind=true){
    const skillsList = findDOMNode(this.refs.skillsList);
    if(!skillsList) return;

    if(isBind){
      skillsList.addEventListener('scroll', this.handleScroll);
    } else {
      skillsList.removeEventListener('scroll', this.handleScroll);
    }
  }
  handleScroll (event) {
    const {scrollTop} = event.target

    this.setState({
      isScrolled: scrollTop > 5
    });
  }

  render () {
    const {props,state} = this;

    if(!props.skills.length){
      return (
        <div className={style.wrapper}>
          <NoSkills {...props}/>
        </div>)
    }

    const selectedSkillsCount = _.filter(props.skills,{isSkillAssigned: true}).length;
    const totalSkillsCount = props.skills.length;

    const toggleSelectText = _.every(props.skills, {isSkillAssigned: true}) ? 'Deselect all' : 'Select all';

    return (
      <div className={style.wrapper}>
        <div className="p-r-20 p-t-20">
          <div className={style.header}>
            <p className={"label-text m-b-5"}>SKILLS <span className="normal-caps">{`(${selectedSkillsCount} of ${totalSkillsCount})`}</span></p>
            <button onClick={props.onSelectAllSkills} className={`btn btn-link no-padding fs-13 m-b-5`}>{toggleSelectText}</button>
          </div>
          <p className="fs-13">
            Select at least one skill. <Link to="skills">Click here</Link> to manage skills.
          </p>
        </div>
        <div ref="skillsList" className={cx({scrolled:state.isScrolled},'m-t-10',style.skillsList)}>
        {
          props.skills.map((skill) => {
            return (
              <SkillsListItem
                key={skill.id}
                skill={skill}
                onToggle={props.onSkillSelect}
                value={true}
              />
            )
          })
        }
        </div>
      </div>
    )
  }
}

Component.propTypes = {
  skills: PropTypes.array,
  onSkillSelect: PropTypes.func.isRequired,
  onSelectAllSkills: PropTypes.func.isRequired
}

export default withRouter(Component);
