import React from 'react'
import { connect } from 'react-redux';
import _ from 'lodash';
import { Route, Redirect, withRouter, Switch } from "react-router-dom";

import SubNav from './SubNav';
import sdk from 'sdk';
import ChatRouting from './chat-routing';
import ChatLogs from './details/ChatLogs';
import Agents from './agents';
import AutoResponders from './details/AutoResponders';
import Broadcasts from './broadcasts';
import CRMData from './crm-data';
import PypestreamApi from './pypestream-api/PypestreamApi';
import ManageTags from './manage-tags';
import UserPrivacy from './user-privacy/UserPrivacy';
import Skills from './skills';
import CannedResponses from './canned-responses/CannedResponses';
import ErrorBoundary from '../ui/ErrorBoundary';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

const {skillsActions} = sdk;

class Admin extends React.Component {
  componentWillMount (){
    this.props.fetchSkills();
  }
  render() {
    const {props} = this
    const {match} = props 

    return (
      <div className="admin content-inner">
        <div className="">
          {/* <div className="pane pane-list">
            <div className="pane-header" />
            <SubNav isPypeManagerEnabled={props.isPypeManagerEnabled}
              isSkillHavingNoAgents={props.isSkillHavingNoAgents}/>
          </div> */}
          <div className="pane pane-detail">
            <Switch>
              <Route exact path={`${match.path}`}>
                <Redirect to={`${match.url}/chat-routing`}/>
              </Route>
              <Route
                exact
                path={`${match.path}/chat-routing`}
                render={(props) => (
                  <ErrorBoundary>
                    <ChatRouting {...props}/>
                  </ErrorBoundary>
                )}
              />
              <Route
                exact
                path={`${match.path}/chat-logs`}
                render={(props) => (
                  <ErrorBoundary>
                    <ChatLogs {...props}/>
                  </ErrorBoundary>
                )}
              />
              <Route
                exact
                path={`${match.path}/agents`}
                render={(props) => (
                  <ErrorBoundary>
                    <Agents {...props}/>
                  </ErrorBoundary>
                )}
              />
              <Route
                exact
                path={`${match.path}/autoresponders`}
                render={(props) => (
                  <ErrorBoundary>
                    <AutoResponders {...props}/>
                  </ErrorBoundary>
                )}
              />
              {/* <Route
                exact
                path={`${match.path}/broadcasts`}
                render={(props) => (
                  <ErrorBoundary>
                    <Broadcasts {...props}/>
                  </ErrorBoundary>
                )}
              /> */}
              <Route
                exact
                path={`${match.path}/crm-data`}
                render={(props) => (
                  <ErrorBoundary>
                    <CRMData {...props}/>
                  </ErrorBoundary>
                )}
              />
              {this.props.flags && this.props.flags.brandInfo && this.props.flags.brandInfo.showAPI && 
                <Route
                  exact
                  path={`${match.path}/pypestream-api`}
                  render={(props) => (
                    <ErrorBoundary>
                      <PypestreamApi {...props}/>
                    </ErrorBoundary>
                  )}
                />
              }
              <Route
                path={`${match.path}/canned-responses`}
                render={(props) => (
                  <ErrorBoundary>
                    <CannedResponses {...props}/>
                  </ErrorBoundary>
                )}
              />
              <Route
                exact
                path={`${match.path}/manage-tags`}
                render={(props) => (
                  <ErrorBoundary>
                    <ManageTags {...props}/>
                  </ErrorBoundary>
                )}
              />
              <Route
                exact
                path={`${match.path}/user-privacy`}
                render={(props) => (
                  <ErrorBoundary>
                    <UserPrivacy {...props}/>
                  </ErrorBoundary>
                )}
              />
              <Route
                exact
                path={`${match.path}/skills`}
                render={(props) => (
                  <ErrorBoundary>
                    <Skills {...props}/>
                  </ErrorBoundary>
                )}
              />
              <Route exact path={`*`}>
                <Redirect to={`/not-found`}/>
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    )
  }
}

// react-redux bindings
const mapStateToProps = (state) => {
  const isSkillHavingNoAgents = _.some(state.skills.skillsStore, (skill)=>(skill.assigned_agent_count === 0))
  return {
    isPypeManagerEnabled: state.pype.details.agent_routing === 'pypestream',
    isSkillHavingNoAgents
  }
};

const mapDispatchToProps = (dispatch) => ({
  fetchSkills() {
    return dispatch(skillsActions.fetchSkills())
  }
})

Admin.propTypes = {};

export default  withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withLDConsumer()(Admin)));
