import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames';
import moment from 'moment';
import styled from 'styled-components';

const EventWrapper = styled.div`
  padding-bottom: 20px;
  > span {
    color: #999999;
    white-space: nowrap;
  }
`;

class EventsTab extends React.Component {
  constructor(props) {
    super(props)
  }

  renderEvents = (eventArr) => {
    return eventArr.map((ev, ind) => {
      if (typeof ev !== 'object' || ev === null) {
        return (
          <li key={`event_${ind}`}>
            <span style={{color: '#db4437'}}><i>Unsupported Format</i></span>
          </li>
        );
      }
      return(
        <li key={`event_${ind}`}>
          {this.renderEventData(ev)}
        </li>
      );
    })
  }

  renderEventData = (event) => {
    const keys = Object.keys(event);
    return keys.map((k, i) => (
      <React.Fragment key={i}>
        {i !== 0 && <span className="dotted-line"></span>}
        <span>{k}</span>
        <span className="dotted-line"></span>
        {typeof event[k] !== 'string' && typeof event[k] !== 'number' ?
          <span style={{color: '#db4437'}}><i>Unsupported Format</i></span> :
          <span>{event[k]}</span>
        }
      </React.Fragment>
    ))
  }

  render() {
    const {props} = this;
    const {events} = props;
    return (
      <div
        data-test="events-tab-component"
        className={cx("user-info-header", "events-tab")}
      >
        <div className="user-info-wrapper">
          <div className="user-info-subsection">
            <ul className="events-ellipsis-list">
              {events && events.length ? (
                events.map((e, index) => {
                  let utc = moment.utc(e.timestamp).toDate();
                  utc = moment(utc).format("h:mm:ssa");
                  if (!Array.isArray(e.event)) {
                    return (
                      <EventWrapper key={"event" + index}>
                        <span data-test="event-item">{utc}</span>
                        <li>
                          <span style={{ color: "#db4437" }}>
                            <i>Unsupported Format</i>
                          </span>
                        </li>
                      </EventWrapper>
                    );
                  }
                  return (
                    <EventWrapper key={"event" + index}>
                      <span>{utc}</span>
                      {this.renderEvents(e.event)}
                    </EventWrapper>
                  );
                })
              ) : (
                <h3 data-test="no-events">No Events</h3>
              )}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

EventsTab.propTypes = {
  events: PropTypes.array,
}

export default  EventsTab;
