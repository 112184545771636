import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Button } from 'ui-elements';
import cx from "classnames";
import {
  Typography,
  Grid,
  InputLabel,
  withStyles,
  Chip,
  Checkbox,
  FormGroup,
  FormControlLabel,
  TextField,
  RadioGroup,
  Radio,
  Select,
  FormControl,
  MenuItem,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DoneIcon from '@material-ui/icons/Done';
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import * as moment from 'moment';
import sdk from 'sdk';
import Spinner from '../ui/pype-spinner/PypeSpinner';
import { withOptimizely } from '@optimizely/react-sdk';

const { conversationHistoryActions, agentsActions, skillsActions } = sdk;

const styles = () => ({
  root:{
    color: '#29302e',
    marginLeft: '24px',
    marginRight: '24px',
    position: 'absolute',
    left: '0',
    right: '0',
    top: '0',
    bottom: '0'
  },
  heading: {
    fontSize: '20px',
    lineHeight: '24px',
  },
  subHeading: {
    fontSize: '16px',
    marginBottom: '8px',
    lineHeight: '20px',
    fontWeight: 500,
  },
  clearFilterBtn: {
    float: 'right',
    marginRight: '0px',
  },
  showAllBtn: {
    padding: '4px 6px',
    marginRight: '0px',
    height: '32px',
  },
  cancelBtn: {
    padding: '4px 6px',
    marginRight: '8px',
    height: '32px',
  },
  chips: {
    marginRight: '8px',
    marginBottom: '8px',
  },
  sectionContainer: {
    marginTop: '24px',
  },
  borderNone: {
    '& input': {
      border: 'none',
    },
  },
  actionButtonContainer: {
    marginTop: '16px',
    paddingBottom: '24px',
  },
  dateRangeSelector: {
    margin: '8px 12px 8px 0px',
    width: '100%',
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      fontSize: '12px',
      lineHeight: '12px',
      transform: 'translate(11px, -5px) scale(0.55)',
    }
  },
  dateRadioBtn: {
    padding: '0px 4px 0px 7px',
  },
  dateRadioBtnLabel: {
    fontSize: '16px',
  },
  subTexts: {
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 'normal',
    marginBottom: '16px',
  },
  selectedChip: {
    lineHeight: '20px',
    fontSize: '14px',
    color: '#0a3ab4',
    fontWeight: 500,
  },
  unSelectedChip: {
    lineHeight: '20px',
    fontSize: '14px',
    color: '#7a6769',
    '&:hover': {
      color: '#0a3ab4',
    }
  },
  // surveyCheckbox: {
  //   padding: '8px',
  // },
  // surveyLabel: {
  //   fontSize: '16px',
  //   lineHeight: '20px',
  //   fontWeight: 'normal',
  // },
  datePicker: {
    '& .MuiSelect-root': {
      fontSize: '16px',
      lineHeight: '20px',
    },
  },
  datePickerLabel: {
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 'normal',
  },
  dropDownOption: {
    paddingLeft: '30px',
    fontSize: '14px',
    display: 'flex',
  },
  selectDropdown: {
    maxHeight: '40vh',
  },
  filterNotSelected: {
    color: '#7a6769',
  },
  // otherFiltersLabel: {
  //   fontSize: '16px',
  //   lineHeight: '20px',
  //   fontWeight: 500,
  // },
  selectedAgentChip: {
    marginRight: '4px',
    marginBottom: '4px',
  },
  loaderContainer: {
    margin: 'auto',
  },
  agentOption: {
    height: 'auto !important',
    boxShadow: 'none !important',
  },
  agentListContainer: {
    padding: '0px 0px 0px 0px !important'
  },
});

const createDateRangeOptions = () => {
  const lastThirtyDays = [...new Array(30)].map((i, index) => {
    const day = moment.utc().startOf('day').subtract(index, 'days');
    return {
      value: day.toISOString(),
      label: `${(index === 0) ? 'Today' : day.format('dddd')}, ${day.format('MMMM Do')}`,
    }
  });
  return lastThirtyDays;
};

const defaultState = {
  isLoading: true,
  selectedAgents: [],
  duration: '',
  selectedSkills: [],
  // isSurveyCompleted: false,
  agentInput: null,
  lastThirtyDays: createDateRangeOptions(),
};

const durationList = [
  {
    value: 'last30days',
    label: 'Last 30 days',
  },
  {
    value: 'last7days',
    label: 'Last 7 days',
  },
  {
    value: 'last24hrs',
    label: 'Last 24 hours',
  },
  {
    value: 'customDateRange',
    label: 'Custom range',
  },
];

const SelectedChip = withStyles({
  root: {
    backgroundColor: '#cbdffb',
  },
})(Chip);

export class HistoryFilters extends React.Component {
  constructor(props) {
    super(props);
    const { optimizely } = props;
    const isCustomDateFeatureEnabled = optimizely.isFeatureEnabled('custom_date_filter');
    const { lastThirtyDays } = defaultState;
    let startDate = lastThirtyDays[0].value;
    let endDate = lastThirtyDays[lastThirtyDays.length - 1].value
    const isCustomDate = (props.selectedHistoryFilters && props.selectedHistoryFilters.duration === 'customDateRange');
    if (isCustomDate) {
      startDate = moment.utc(props.selectedHistoryFilters.from).startOf('day').toISOString();
      endDate = moment.utc(props.selectedHistoryFilters.to).startOf('day').toISOString();
    }
    
    this.state = {
      ...defaultState,
      isCustomDate,
      startDate,
      endDate,
      isCustomDateFeatureEnabled,
      ...props.selectedHistoryFilters
    };
  }

  static defaultProps = {
    agents: [],
    skillList: [],
  }

  componentDidMount() {
    this.props.fetchAgents();
    this.props.fetchSkills();
  }

  componentWillReceiveProps(nextProps) {
    const { selectedHistoryFilters, isFetchingAgents, isFetchingSkills, agents } = nextProps;
    if(this.state.isLoading && !isFetchingAgents && !isFetchingSkills) {
      const selectedAgents = (
          !_.isEmpty(selectedHistoryFilters)
          && selectedHistoryFilters.selectedAgents
          && selectedHistoryFilters.selectedAgents.length
        )
        ? agents.filter((agent) => selectedHistoryFilters.selectedAgents.includes(agent.id))
        : [];
      this.setState({ selectedAgents, isLoading: false});
    }
  }

  componentWillUnmount() {
    this.props.changeHistoryFiltersView(false);
  }

  handleDurationChange = ({ target }) => {
    this.setState({
      duration: target.value,
      isCustomDate: target.value === 'customDateRange',
    });
  };

  handleSkillChips = (value) => {
    const selectedSkills = [...this.state.selectedSkills];
    const index = selectedSkills.indexOf(value);
    if (index >= 0) {
      selectedSkills.splice(index, 1);
    } else {
      selectedSkills.push(value);
    }
    this.setState({ selectedSkills });
  };

  handleReset = () => {
    const { lastThirtyDays } = defaultState;
    let startDate = lastThirtyDays[0].value;
    let endDate = lastThirtyDays[lastThirtyDays.length - 1].value
    this.setState({
      ...defaultState,
      isLoading: false,
      isCustomDate: false,
      startDate: startDate,
      endDate: endDate,
    });
  };

  handleApplyFilter = (flag = true) => {
    if (flag) {
      let selectedFilters = {};
      const {
        selectedAgents,
        selectedSkills,
        duration,
        // isSurveyCompleted, // removed survey completed checkbox for now
        startDate,
        endDate,
      } = this.state;
      if (selectedAgents.length) selectedFilters = { ...selectedFilters, selectedAgents: selectedAgents.map((agent) => agent.id) };
      if (selectedSkills.length) selectedFilters = { ...selectedFilters, selectedSkills };
      if (duration) {
        const currentDate = moment.utc().toISOString();
        let from;
        let to = currentDate;
        switch (duration) {
          case 'last24hrs': {
            from = moment.utc(currentDate).subtract(24, 'h').toISOString();
            break;
          }
          case 'last7days': {
            from = moment.utc(currentDate).startOf('day').subtract(6, 'days').toISOString();
            to = moment.utc(currentDate).endOf('day').toISOString();
            break;
          }
          case 'customDateRange': {
            from = startDate;
            to = endDate;
            break;
          }
          default: {
            from = moment.utc(currentDate).startOf('day').subtract(29, 'days').toISOString();
            to = moment.utc(currentDate).endOf('day').toISOString();
          }
        }
        selectedFilters = { ...selectedFilters, duration, from, to };
      }
      // if (isSurveyCompleted) selectedFilters = { ...selectedFilters, isSurveyCompleted };
      this.props.setSelectedHistoryFilters(selectedFilters);
    }
    this.props.hideFilterHandle(flag);
  };

  getSelectedAgentLabel = (id) => {
    const agent = this.props.agents.find((agent) => agent.id === id);
    return (agent) ? `${agent.first_name} ${agent.last_name}` : '';
  };

  renderFilters() {
    const { classes, skillList, agents } = this.props;
    const { selectedAgents, lastThirtyDays } = this.state;
    const selectedSkills = new Set(this.state.selectedSkills);

    return (
      <>
        <div className={classes.root}>
          <Grid container className={classes.sectionContainer}>
            <Grid item xs={6}>
              <Typography className={classes.heading}>Filters</Typography>
            </Grid>
            <Grid item xs={6}>
              <Button small onClick={this.handleReset} className={classes.clearFilterBtn}>Clear Filters</Button>
            </Grid>
          </Grid>
          {
            (agents.length > 0) &&
            (<Grid container className={classes.sectionContainer}>
              <Grid item xs={12}>
                <Typography className={classes.subHeading}>Agents</Typography>
                <Autocomplete
                  multiple
                  className={classes.borderNone}
                  classes={{
                    option: classes.agentOption,
                    listbox: classes.agentListContainer
                  }}
                  disablePortal
                  options={agents}
                  getOptionLabel={(agent) => `${agent.first_name} ${agent.last_name}`}
                  value={selectedAgents}
                  onChange={(event, values) => this.setState({ selectedAgents: values})}
                  popupIcon={null}
                  disableCloseOnSelect
                  noOptionsText={
                    <span className={classes.dropDownOption}>
                      {`No results match "${this.state.agentInput}"`}
                    </span>
                  }
                  renderTags={(values) =>
                    values.map((agent) => (
                      <SelectedChip
                        key={agent.id}
                        className={classes.selectedAgentChip}
                        label={
                          <Typography className={classes.selectedChip}>
                            {this.getSelectedAgentLabel(agent.id)}
                          </Typography>
                        }
                      />
                    ))
                  }
                  renderOption={(agent, { selected }) => (
                    <span className={classes.dropDownOption}>
                      {(selected)
                        && (<DoneIcon
                              style={
                                {
                                  color: '#0a3ab4',
                                  width: '18px',
                                  height: '18px',
                                  marginRight: '9px',
                                  marginLeft: '-27px',
                                }
                              }
                            />)
                        }
                      {`${agent.first_name} ${agent.last_name}`}
                    </span>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      placeholder='Search'
                      onChange={({ target }) => this.setState({ agentInput: target.value })}
                    />
                  )}
                />
              </Grid>
            </Grid>)
          }
          {
            (skillList.length > 0) &&
            (<Grid container className={classes.sectionContainer}>
              <Grid item xs={12}>
                <Typography className={classes.subHeading}>Skills</Typography>
              </Grid>
              <Grid item xs={12}>
                {skillList.map((skill) =>
                  selectedSkills.has(skill.id) ? (
                    <SelectedChip
                      key={skill.id}
                      avatar={
                        selectedSkills.has(skill.id) && (
                          <DoneIcon
                            style={
                              {
                                color: '#0a3ab4',
                                width: '18px',
                                height: '18px',
                                marginRight: '-10px'
                              }
                            }
                          />
                        )
                      }
                      clickable
                      label={
                        <Typography className={classes.selectedChip}>
                          {skill.name}
                        </Typography>
                      }
                      onClick={() => this.handleSkillChips(skill.id)}
                      className={classes.chips}
                    />
                  ) : (
                    <Chip
                      size='medium'
                      clickable
                      key={skill.id}
                      label={
                        <Typography className={classes.unSelectedChip}>
                          {skill.name}
                        </Typography>
                      }
                      onClick={() => this.handleSkillChips(skill.id)}
                      className={classes.chips}
                    />
                  )
                )}
              </Grid>
            </Grid>)
          }
          <Grid container className={classes.sectionContainer}>
            <Grid item xs={12}>
              <Typography className={classes.subHeading}>Dates</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.subTexts}>
                For privacy reasons, only 30 days of conversations are saved
              </Typography>
            </Grid>
            <Grid container>
              <RadioGroup
                row
                value={this.state.duration}
                onChange={this.handleDurationChange}
              >
                {durationList.map((duration) => {
                  if (!this.state.isCustomDateFeatureEnabled && duration.value === 'customDateRange') {
                    return null;
                  }
                  return (
                    <FormControlLabel
                      key={duration.value}
                      value={duration.value}
                      control={
                        <Radio
                          color='primary'
                          id={duration.value}
                          className={classes.dateRadioBtn}
                          icon={<RadioButtonUncheckedIcon style={{ fontSize: 18 }} />}
                          checkedIcon={<RadioButtonCheckedIcon style={{ fontSize: 18 }} />}
                        />
                      }
                      label={
                        <Typography
                          className={cx({
                            [classes.dateRadioBtnLabel]: true,
                            [classes.filterNotSelected]: duration.value !== this.state.duration
                          })}
                        >
                          {duration.label}
                        </Typography>
                      }
                    />
                  );
                })}
              </RadioGroup>
            </Grid>
            <Grid container>
              {this.state.isCustomDate && this.state.isCustomDateFeatureEnabled && (
                <div id="customDateContainer">
                  <Grid item xs={12}>
                    <FormControl
                      variant='outlined'
                      className={classes.dateRangeSelector}
                    >
                      <InputLabel>
                        <Typography className={classes.datePickerLabel}>Start Date</Typography>
                      </InputLabel>
                      <Select
                        value={this.state.startDate}
                        className={classes.datePicker}
                        fullWidth
                        MenuProps={{ classes: { paper: classes.selectDropdown } }}
                        onChange={({ target }) =>
                          this.setState({ startDate: target.value })
                        }
                        label='Start Date'
                      >
                        {lastThirtyDays.map((day) => {
                          return (
                            <MenuItem key={day.value} value={day.value} className={classes.dropDownOption}>
                              {day.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      variant='outlined'
                      className={classes.dateRangeSelector}
                    >
                      <InputLabel>
                        <Typography className={classes.datePickerLabel}>End Date</Typography>
                      </InputLabel>
                      <Select
                        value={this.state.endDate}
                        className={classes.datePicker}
                        fullWidth
                        MenuProps={{
													classes: { paper: classes.selectDropdown },
													getContentAnchorEl: null,
												}}
                        onChange={({ target }) =>
                          this.setState({ endDate: target.value })
                        }
                        label='End Date'
                      >
                        {lastThirtyDays.map((day) => {
                          return (
                            <MenuItem key={day.value} value={day.value} className={classes.dropDownOption}>
                              {day.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </div>
              )}
            </Grid>
          </Grid>
          {/* <Grid container className={classes.sectionContainer}>
            <Grid item xs={12}>
              <Typography className={classes.otherFiltersLabel}>Other Filters</Typography>
            </Grid>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    color='primary'
                    className={classes.surveyCheckbox}
                    checked={this.state.isSurveyCompleted}
                    onClick={() =>
                      this.setState({
                        isSurveyCompleted: !this.state.isSurveyCompleted,
                      })
                    }
                    icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 18 }} />}
                    checkedIcon={<CheckBoxIcon style={{ fontSize: 18 }} />}
                  />
                }
                label={
                  <Typography
                    className={cx({
                      [classes.surveyLabel]: true,
                      [classes.filterNotSelected]: !this.state.isSurveyCompleted
                    })}
                  >
                    Survey was completed
                  </Typography>
                }
              />
            </FormGroup>
          </Grid> */}
          <Grid
            container
            className={classes.actionButtonContainer}
            direction='row'
            justify='flex-end'
            alignItems='center'
          >
            <Grid item>
              <Button hollow onClick={() => this.handleApplyFilter(false)} className={classes.cancelBtn}>Cancel</Button>
            </Grid>
            <Grid item>
              <Button onClick={() => this.handleApplyFilter()} className={classes.showAllBtn}>Apply Filters</Button>
            </Grid>
          </Grid>
        </div>
      </>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      (this.state.isLoading)
      ? (
        <div className={classes.loaderContainer}>
          <Spinner offsetTop={4} />
        </div>
      )
      : this.renderFilters()
    )
  }
}

const mapStateToProps = (state) => ({
  selectedHistoryFilters: state.conversationHistory.selectedHistoryFilters,
  skillList: state.skills.skillsStore,
  agents: _.sortBy(state.agentsForPype.agents, ['first_name', 'last_name']),
  isFetchingAgents: state.agentsForPype.isFetching,
  isFetchingSkills: state.conversationHistory.isFetchingSkills,
});

const mapDispatchToProps = (dispatch) => ({
  changeHistoryFiltersView(reset = true) {
    return dispatch(conversationHistoryActions.changeHistoryFiltersView(reset));
  },
  setSelectedHistoryFilters(selectedHistoryFilters = {}) {
    return dispatch(conversationHistoryActions.setSelectedHistoryFilters(selectedHistoryFilters));
  },
  fetchAgents() {
    return dispatch(agentsActions.fetchAgents());
  },
  fetchSkills() {
    return dispatch(skillsActions.fetchSkills());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withOptimizely(HistoryFilters)));
