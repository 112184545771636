import _ from 'lodash'
import { BOT_USER_SESSION_LOGOUT } from "bot-user-session";
import { initSeqConsts, widgetsConstants as types } from '../constants'

const widgetInitialState = Object.freeze({
  id: null,
  app_id: null,
  pype_id: null,
  stream_id: null,
  style: null,
  whitelist: null,
})

const getInitialState = () => ({
  isFetching: false,
  store: {},
});

const widget = (state=widgetInitialState, action) => {
  switch (action.type) {
  case types.RECEIVE:
    return {
      ...widgetInitialState,
      ...state,
    }
  case types.CREATE_WIDGET:
    return {
      ...widgetInitialState,
      ...action.data,
    }
  case types.UPDATE_WIDGET:
    return {
      ...state,
      ...action.data,
    }
  default:
    return state
  }
}

const widgets = (state=getInitialState(), action) => {
  switch (action.type) {
  case BOT_USER_SESSION_LOGOUT: {
    return getInitialState();
  }
  case types.REQUEST:
  case initSeqConsts.FETCHING:
    return {
      ...state,
      isFetching: true,
    }
  case types.RECEIVE:
  case initSeqConsts.FETCHED:
    const data = action.type === initSeqConsts.FETCHED ? action.data.widgets : action.data;
    if (!data) {
      return state;
    }
    return {
      ...state,
      isFetching: false,
      store: data.reduce((a, b) => ({
        ...a,
        [b.id]: widget(b, action),
      }), {}),
    }
  case types.CREATE_WIDGET:
    return {
      ...state,
      store: {
        ...state.store,
        [action.data.id]: widget(undefined, action),
      },
    }
  case types.UPDATE_WIDGET:
    return {
      ...state,
      isFetching: false,
      store: {
        ...state.store,
        [action.data.id]: widget(state.store[action.data.id], action),
      },
    }
  case types.REMOVE_WIDGET:
    return {
      ...state,
      store: _.omit(state.store, action.id),
    }
  default:
    return state
  }
}

export default widgets
