import React from 'react'

const MessageTyping = (props) => (
  <div className="conversation-history-message">
    <div key={"typing-indicator"} className={"chat-message received"}>
      <div className="body">
        <div className="light no-margin"><img src={`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/typing-indicator.gif`} width="41"/></div>
      </div>
    </div>
    <div className="clearfix"></div>
  </div>
)

MessageTyping.propTypes = {}

export default  MessageTyping
