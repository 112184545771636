import { connect } from 'react-redux';
import sdk from "sdk";
import Component from './Component';
import { userLogout } from 'bot-user-session';

const { pypesActions, pypeActions } = sdk;

const mapStateToProps = state => ({
  pypes: state.pypes.pypes,
  isFetching: state.pypes.isFetching,
  hasFetched: state.pypes.hasFetched,
  isAuthenticated: state.botUserSession.isAuthenticated

});

const mapDispatchToProps = dispatch => ({
  fetchPypes: () => dispatch(pypesActions.fetchPypes()),
  setPype: (id) => dispatch(pypeActions.setPype(id)),
  userLogout:  () => dispatch(userLogout()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);
