import React, { useEffect } from 'react';
import Pype from 'components/ui/PypesListItem'
import { PypestreamLoader } from "ui-elements";
import {
  doesUserHasRole,
  PYPE_AGENT_ROLES
} from "bot-user-session";
import {
  Wrapper,
  Content,
  SelectPype,
  PypeList
} from './helpers';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

export const PypeSelect = (props) => {
  const {isFetching, pypes, setPype, flags, fetchPypes} = props;
  const hasMultiplePypes =  Array.isArray(pypes) && pypes.length > 1;

  useEffect(() => {
    if (flags.pe18795) {
      fetchPypes();
    }
  }, []);

  useEffect(() => {
    if(!isFetching && pypes.length === 1) {
      // select single pype as active pype if user has only one pype
      handlePypeSelect(pypes[0].id);
    }
  }, [isFetching, pypes]);

  const handlePypeSelect = (pypeId) => {
    setPype(pypeId);
    const isAgent = doesUserHasRole(PYPE_AGENT_ROLES, pypeId);

    if(isAgent) {
      window.location.href = `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/${pypeId}/chats`
    } else {
      window.location.href = `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/${pypeId}/overview`
    }
  }

  const renderLogo = () => {
    if (flags && flags.brandInfo) {
      const brandLogo = flags.brandInfo.logo ? flags.brandInfo.logo : `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/Pypestream_logo_horiz_black.svg`;
      return <img
        src={brandLogo}
        width="240"
        height="38"
      />
    }
  }

  return (
    <Wrapper>
      {isFetching || !hasMultiplePypes ? (
        flags && flags.brandInfo && <div className="pypestream-loader"><PypestreamLoader logo={flags.brandInfo.loadingIcon} loaderHeight={flags.brandInfo.loaderHeight} loaderWidth={flags.brandInfo.loaderWidth}/></div>
      ) : (
        <Content>
          {renderLogo()}
          <SelectPype>
            {
              // Show spinner when fetching pypes and also after fetch
              // if only single pype is found (while waiting for redirection to happen)
            }
            <div>
              <h2>Select a Pype</h2>

              <PypeList id="pypeList">
                {pypes.map(({ id, logo, env, name }) => (
                  <Pype
                    id="pype"
                    key={id}
                    logo={logo}
                    name={name}
                    env={env}
                    onClick={() => handlePypeSelect(id)}
                  />
                ))}
              </PypeList>
            </div>
          </SelectPype>
        </Content>
      )}
    </Wrapper>
  );
}

const PypeSelectWithFeatureFlag = withLDConsumer()(PypeSelect);

export default PypeSelectWithFeatureFlag;
