import _ from 'lodash'
import { BOT_USER_SESSION_LOGOUT } from "bot-user-session";
import {
  REQUEST_CONVERSATION_HISTORY,
  FETCH_CONVERSATION_HISTORY_ERROR,
  RECEIVE_CONVERSATION_HISTORY,
  RECEIVE_CONVERSATION_MIGRATION_STATS,
  CREATE_CHAT,
  CREATE_CHAT_RES,
  SEND_MSG_READ_RECEIPT_RES,
  CREATED_CHAT_SESSION,
  CHANGE_HISTORY_FILTERS_PANEL_VIEW,
  SET_SELECTED_HISTORY_FILTERS,

  CONVERSATIONS_FILE_UPLOAD_STARTED,
  CONVERSATIONS_FILE_UPLOAD_SUCCEEDED,
  CONVERSATIONS_FILE_UPLOAD_PROGRESS_CHANGED,
  CONVERSATIONS_FILE_UPLOAD_FAILED,
  CONVERSATIONS_FILE_REMOVED,

  REQUEST_CONSUMER_DETAILS,
  RECEIVE_CONSUMER_DETAILS,

  MIXED_MESSAGE_SEND_STARTED,
  MIXED_MESSAGE_FILE_UPLOAD_PROGRESS_CHANGED,
  MIXED_MESSAGE_FILE_UPLOAD_SUCCEEDED,
  MIXED_MESSAGE_FILE_UPLOAD_FAILED,
  MIXED_MESSAGE_TEXT_SENDING_STARTED,
  MIXED_MESSAGE_TEXT_SENDING_SUCCEDED,
  MIXED_MESSAGE_TEXT_SENDING_FAILED,
  MIXED_MESSAGE_TEXT_SENDING_TIMEOUT,

  AGENT_TYPING_STATUS_CHANGED,
  CONSUMER_TYPING_STATUS_CHANGED,
  CONSUMER_TYPING_STATUS_UPDATE,

  REQUEST_SKILLS,
  RECEIVE_SKILLS,
} from "./../constants"
import createreducer from '../common/utils/create-reducer';
import {sortSkills} from '../utils/skills'


const getInitialState = () => ({
  id: null,
  isFetching: false,
  didInvalidate: false,
  history: [],
  stream_name: null,
  consumer: null,
  consumer_name: null,
  consumer_data: null,
  createdChat: {},
  fileToUpload: null,
  isUploadingFile: false,
  uploadedFileURL: null,
  client_msg_id: null,
  uploadProgress: 0,
  read_receipts: [],
  selectedStreamId: null,
  isSendingMixedMessage: false,
  mixedMessageError: null,
  to_be_migrated: false,
  shouldMigrateNewConversation: false,
  msgRequestTimeout: false,
  failedChatId: null,
  selectedStreamId: null,
  consumerDetails: null,
  isAgentTyping: false,
  isConsumerTyping: false,
  skills: [],
  agentsForSkill: [],
  isFetchingSkills: false,
  events: [],
  isHistoryFilterPanelOpen: false,
  selectedHistoryFilters: {},
});


const actionHandlers = {
  [BOT_USER_SESSION_LOGOUT]: (state) => {
    return getInitialState();
  },
  
  [REQUEST_CONVERSATION_HISTORY]: (state) => {
    return Object.assign({}, state, {
      isFetching: true,
      didInvalidate: false
    });
  },

  [FETCH_CONVERSATION_HISTORY_ERROR]: (state) => {
    return Object.assign({}, state, {
      isFetching: false,
    });
  },

  [RECEIVE_CONVERSATION_HISTORY]: (state, action) => {
    let { id, history, stream_name, consumer, consumer_name, consumer_first_name, consumer_last_name, stream_id, to_be_migrated, gateway_metadata } = action.history;
    const consumer_data = gateway_metadata.consumer || null;

    history = _.sortBy(history, (h) => h.chat_msg.seq_num)
    return Object.assign({}, state, {
      isFetching: false,
      didInvalidate: false,
      history,
      stream_name,
      consumer_data,
      consumer_name,
      consumer_first_name,
      consumer_last_name,
      consumer,
      selectedStreamId: stream_id,
      to_be_migrated,
      lastUpdated: action.receivedAt,
      id,
      isConsumerTyping: false
    });
  },

  [RECEIVE_CONVERSATION_MIGRATION_STATS]: (state, action) => {
    return Object.assign({}, state, {
      shouldMigrateNewConversation: action.shouldMigrateNewConversation
    });
  },

  [CREATE_CHAT]: (state, action) => {
    return Object.assign({}, state, {
      mixedMessageError: null,
      msgRequestTimeout: false,
      failedChatId: null
    });
  },

  [CREATE_CHAT_RES]: (state, action) => {
    const {chat_id,from,msg_action,id} = action.createdChat;
    let newMessage = {chat_msg: action.createdChat};
    let data = {
      uploadedFileURL: null,
      client_msg_id: null,
      fileToUpload: null,
      createdChat: action.createdChat,
      isSendingTextMessage: false
    };

    const isExistingMessage = _.some(state.history, (chat) => {
      return chat.chat_msg.id === id
    })

    if(isExistingMessage) {
      data.history = state.history.map((chat) => {
        return chat.chat_msg.id === id ? newMessage : chat
      })
    } else if (state.history.length && chat_id == state.history[0].chat_msg.chat_id) {
      data.history = [...state.history, newMessage]
    }

    if(from === state.consumer &&
      chat_id === state.id){
      data.isConsumerTyping = false;
    }

    return Object.assign({}, state, data);
  },
  [CREATED_CHAT_SESSION]: (state, action) => {
    const to_be_migrated = action.chat_session.to_be_migrated;
    return Object.assign({}, state, {
      to_be_migrated
    });
  },

  [CONVERSATIONS_FILE_UPLOAD_STARTED]: (state, { file }) => {
    return Object.assign({}, state, {
      isUploadingFile: true,
      fileToUpload: file
    });
  },

  [CONVERSATIONS_FILE_UPLOAD_SUCCEEDED]: (state, { payload,client_msg_id }) => {
    const update = {
      isUploadingFile: false,
      fileToUpload: null,
      uploadProgress: 0,
      uploadedFileURL: payload,
      client_msg_id: client_msg_id
    };

    return Object.assign({}, state, update);
  },

  [CONVERSATIONS_FILE_UPLOAD_PROGRESS_CHANGED]: (state, { payload }) => {
    const { bytesUploaded, bytesTotal } = payload;
    const uploadProgress = (bytesUploaded / bytesTotal * 100).toFixed(2);

    return Object.assign({}, state, {
      uploadProgress
    });
  },

  [CONVERSATIONS_FILE_UPLOAD_FAILED]: (state) => {
    return Object.assign({}, state, {
      isUploadingFile: false,
      fileToUpload: null
    });
  },

  [CONVERSATIONS_FILE_REMOVED]: (state) => {
    return Object.assign({}, state, {
      fileToUpload: null,
      uploadedFileURL: null,
      client_msg_id: null,
      uploadProgress: 0
    });
  },

  [SEND_MSG_READ_RECEIPT_RES]: (state, action) => {
    return Object.assign({}, state, {
      readReceipt: action.receipts
    });
  },

  [REQUEST_CONSUMER_DETAILS]: (state) => {
    return Object.assign({}, state, {
      isFetching: true,
      didInvalidate: false
    });
  },

  [RECEIVE_CONSUMER_DETAILS]: (state, action) => {
    return Object.assign({}, state, {
      isFetching: false,
      consumerDetails: action.consumer_data
    })
  },

  [MIXED_MESSAGE_SEND_STARTED]: (state, action) => {

    if(state.history.length && action.payload.chatId !== state.history[0].chat_msg.chat_id)
      return state;

    let _message = {
      campaign_id: null,
      chat_id: action.payload.chatId,
      client_msg_id: action.payload.client_msg_id,
      file: action.payload.file,
      file_status: 'uploading',
      from: action.userId,
      from_side: 'agent',
      id: 'placeholder',
      metadata: {},
      msg: action.payload.text,
      msg_action: 'replace',
      msg_type: action.payload.params.attachmentType,
      seq_num: null,
      timestamp: new Date()
    };

    let data = {
      uploadedFileURL: null,
      client_msg_id: null,
      fileToUpload: null,
      createdChat: _message,
      isSendingMixedMessage: true,
      mixedMessageError: null,
      msgRequestTimeout: false
    };

    data.history = [...state.history, {chat_msg: _message}];

    return Object.assign({}, state, data);



    // return Object.assign({}, state, {
    //   isSendingMixedMessage: true,
    //   mixedMessageError: null
    // });
  },

  [MIXED_MESSAGE_FILE_UPLOAD_PROGRESS_CHANGED]: (state, { payload }) => {
    const { bytesUploaded, bytesTotal } = payload;
    const uploadProgress = (bytesUploaded / bytesTotal * 100).toFixed(2);

    return Object.assign({}, state, {
      uploadProgress
    });
  },

  [MIXED_MESSAGE_FILE_UPLOAD_SUCCEEDED]: (state, action) => {
    return state;
  },

  [MIXED_MESSAGE_FILE_UPLOAD_FAILED]: (state, action) => {
    return state;
  },

  [MIXED_MESSAGE_TEXT_SENDING_STARTED]: (state, action) => {
    return Object.assign({}, state, {
      isSendingMixedMessage: true
    });
  },

  [MIXED_MESSAGE_TEXT_SENDING_SUCCEDED]: (state, action) => {
    const history = state.history.filter(message => message.chat_msg.id !== 'placeholder');

    return Object.assign({}, state, {
      isSendingMixedMessage: false,
      history
    });
  },

  [MIXED_MESSAGE_TEXT_SENDING_FAILED]: (state, action) => {
    const history = state.history.filter(message => message.chat_msg.id !== 'placeholder');

    return Object.assign({}, state, {
      isSendingMixedMessage: false,
      mixedMessageError: action.payload,
      history
    });
  },

  [MIXED_MESSAGE_TEXT_SENDING_TIMEOUT]: (state, action) => {
    return Object.assign({}, state, {
      isSendingMixedMessage: false,
      mixedMessageError: 'Unable to send the message',
      msgRequestTimeout: true,
      failedChatId: action.chat_id
    });
  },

  [CONSUMER_TYPING_STATUS_CHANGED]: (state, action) => {
    const {chat_id, from, is_typing,from_side} = action.data;
    if(chat_id !== state.id || from !== state.consumer){
      return state;
    }
    return {
      ...state,
      isConsumerTyping: is_typing
    }
  },

  [AGENT_TYPING_STATUS_CHANGED]: (state, action) => {
    const {chat_id, from, is_typing,from_side} = action.data;
    if(chat_id !== state.id){
      return state;
    }
    return {
      ...state,
      isAgentTyping: is_typing
    }
  },
  [CONSUMER_TYPING_STATUS_UPDATE]: (state, action) => {
    return {
      ...state,
      isConsumerTyping: action.isTyping
    }
  },

  [REQUEST_SKILLS]: (state, action) => {
    return {
      ...state,
      isFetchingSkills: true
    }
  },
  [RECEIVE_SKILLS]: (state, action) => {
    const {skills} = action
    // action.skills will be either list of skills or agents for a specific
    // skill (if a skill id was passed when making the request)

    if(!skills.length){
      return state;
    }
    // list of agents for a skill
    if('skills' in skills[0]){
      return {
        ...state,
        skills: sortSkills(state.skills),
        isFetchingSkills: false,
        agentsForSkill: skills
      }
    } else {
      return {
        ...state,
        isFetchingSkills: false,
        skills: sortSkills(skills),
        agentsForSkill: []
      }
    }

  },
  [CHANGE_HISTORY_FILTERS_PANEL_VIEW]: (state, action) => {
    return {
      ...state,
      isHistoryFilterPanelOpen: action.isHistoryFilterPanelOpen
    }
  },
  [SET_SELECTED_HISTORY_FILTERS]: (state, action) => {
    return {
      ...state,
      selectedHistoryFilters: action.selectedHistoryFilters
    }
  },
};

export default createreducer(getInitialState(), actionHandlers);
