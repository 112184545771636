// External
import _ from 'lodash';
import React from 'react'
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Local
import DetailPane from '../details/DetailPane';
import ContentBody from '../details/ContentBody';
import Footer from '../details/Footer';
import PypeManagerFeature from '../PypeManagerFeature';

// UI
import Spinner from '../../ui/pype-spinner/PypeSpinner';
import LoadingMask from '../../ui/masks/LoadingMask';
import SaveButton from '../../ui/forms/SaveButton';
import ErrorBoundary from 'components/ui/ErrorBoundary';

// Inner module
import OverallConfig from './OverallConfig';
import TagConfig from './TagConfig';

// Pypestream
import sdk from 'sdk';
import activityTracker from "../../../utils/activityTracker";
const {tagActions} = sdk;

const validators = {
  categoryName(value) {
    if (value === '' || _.isNil(value))
      return 'You must provide a tag category name.'
  },
}

export class ManageTags extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      isSubmitting: false,
      enableTags: props.enableTags,
      requireTags: props.requireTags,
      categoryName: props.categoryName,
      enableTagCodes: props.enableTagCodes,
      enableMultipleTags: props.enableMultipleTags,
      categoryNameError: undefined,
    }
  }

  componentWillMount() {
    const { customerName } = this.props

    this.props.fetchTags();
    activityTracker.logEvent(activityTracker.eventTypeNames.VIEW_PYPE_SETTING,{settingName: 'tags', customerName});
  }

  toggleEnableTags = () => {
    this.setState({enableTags: !this.state.enableTags})
  }

  toggleRequireTags = () => {
    this.setState({requireTags: !this.state.requireTags})
  }

  handleTagCategoryChange = (event) => {
    const {value} = event.target
    this.setState({
      categoryName: value,
      categoryNameError: validators.categoryName(value),
    })
  }

  toggleTagCodesEnabled = () => {
    this.setState({enableTagCodes: !this.state.enableTagCodes})
  }

  toggleEnableMultipleTags = () => {
    this.setState({enableMultipleTags: !this.state.enableMultipleTags})
  }

  handleSubmit = () => {
    const {props, state} = this
    const { customerName } = props

    if (this.state.isSubmitting || !this.isFormValid())
      return
    this.setState({isSubmitting: true})
    props.onSubmit({
      enableTags: state.enableTags,
      requireTags: state.requireTags,
      categoryName: state.categoryName,
      enableTagCodes: state.enableTagCodes,
      enableMultipleTags: state.enableMultipleTags,
    }).then(() => {
      window.appCreateToast({
        message: 'Changes saved successfully.',
        level: 'success',
      })
      activityTracker.logEvent(activityTracker.eventTypeNames.UPDATE_PYPE_SETTING,{settingName: 'tags',action: activityTracker.eventActionType.UPDATE, customerName});
    }, () => {
      window.appCreateToast({
        message: 'Oops! Request could not be completed.',
        level: 'error',
      })
    }).then(() => {
      this.setState({isSubmitting: false})
    })
  }

  isFormValid = () => {
    const {state} = this
    return validators.categoryName(state.categoryName) === undefined
  }

  hasChanges = () => {
    const {props, state} = this
    return state.enableTags !== props.enableTags
      || state.requireTags !== props.requireTags
      || state.categoryName !== props.categoryName
      || state.enableTagCodes !== props.enableTagCodes
      || state.enableMultipleTags !== props.enableMultipleTags
  }

  render() {
    const {props, state} = this
    const isFetching = props.isPypeFetching || props.isTagsFetching
    const hasChanges = this.hasChanges()

    return (
      <DetailPane className="manage-tags has-footer">
        {isFetching ? (
          <LoadingMask data-test="loader" />
        ) : null}

        {false ? <Spinner className="detail-spinner absolute-center" /> : (
          <div>
            <ContentBody>
              <PypeManagerFeature>
                <ErrorBoundary>
                  <OverallConfig
                    isSubmitting={state.isSubmitting}
                    categoryNameError={state.categoryNameError}
                    enableTags={state.enableTags}
                    requireTags={state.requireTags}
                    categoryName={state.categoryName}
                    enableTagCodes={state.enableTagCodes}
                    enableMultipleTags={state.enableMultipleTags}
                    onToggleEnableTags={this.toggleEnableTags}
                    onToggleRequireTags={this.toggleRequireTags}
                    onTagCategoryChange={this.handleTagCategoryChange}
                    onToggleTagCodesEnabled={this.toggleTagCodesEnabled}
                    onToggleEnableMultipleTags={this.toggleEnableMultipleTags}
                    onSubmit={this.handleSubmit} />
                  </ErrorBoundary>
                <Footer>
                  {hasChanges && <span className="unsaved-changes">Unsaved changes</span>}
                  <SaveButton className="btn-primary" disabled={!this.isFormValid() || !hasChanges} isLoading={state.isSubmitting} onClick={this.handleSubmit}>
                    Save changes
                  </SaveButton>
                </Footer>
                <ErrorBoundary>
                  <TagConfig enableTags={state.enableTags} enableTagCodes={state.enableTagCodes} />
                </ErrorBoundary>
              </PypeManagerFeature>
            </ContentBody>
          </div>)}
      </DetailPane>
    )
  }

}

ManageTags.propTypes = {
  enableTags: PropTypes.bool.isRequired,
  requireTags: PropTypes.bool.isRequired,
  categoryName: PropTypes.string.isRequired,
  enableTagCodes: PropTypes.bool.isRequired,
  enableMultipleTags: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => ({
  isPypeFetching: state.pype.isFetching,
  isTagsFetching: state.tags.isFetching,
  enableTags: state.tags.tagsEnabled,
  requireTags: state.tags.tagsRequired,
  enableTagCodes: state.tags.enableTagCodes,
  categoryName: state.tags.category.name,
  enableMultipleTags: state.tags.enableMultipleTags,
  customerName: state.pype.details.customerName
})

const mapDispatchToProps = (dispatch) => ({
  fetchTags() {
    return dispatch(tagActions.fetchTags())
  },
  onSubmit(data) {
    return dispatch(tagActions.saveConfig({
      category_name: data.categoryName,
      end_tags_display: data.enableTags,
      end_tags_required: data.requireTags,
      tags_multiple: data.enableMultipleTags,
      tags_shortcuts: data.enableTagCodes,
    }))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageTags)
