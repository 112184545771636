import style from './style.css';
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames';

const RadioButton = (props) => (
  <div className={classNames(style.RadioButton, props.className, {
    [style.Checked]: props.checked,
  })} style={props.style} />
)

RadioButton.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  checked: PropTypes.bool,
}

RadioButton.defaultProps = {
  className: '',
  style: {},
  checked: false,
}

export default  RadioButton
