import {
  RECEIVED_ACK,
  RECEIVED_RESPONSE
} from "./../constants";

export function receivedAck(ack){
  return (dispatch, getState) => {
  	const { correlation_id, details, status } = ack;

    dispatch({
      type: RECEIVED_ACK,
      ack: {
      	correlation_id,
      	details,
      	status
      }
    })
  };

}

export function handleResponse(response) {
  return (dispatch, getState) => {
    dispatch({
      type: RECEIVED_RESPONSE,
      payload: response
    });
  };
}
