import React from 'react'
import PropTypes from 'prop-types'
import style from './style.css';

// UI components
import LabelInput from 'components/ui/forms/LabelInput';
import LabelCheckbox from 'components/ui/forms/LabelCheckbox';
import AppTooltip from 'components/shared/AppTooltip';

const TOOLTIP_MESSAGE = 'If turned off, chats routed to this skill will be' +
                        '<br/>ended when all agents are offline.';

const MAX_SKILL_LENGTH = 50;

const SKILL_NAME_PATTERN = /^[ A-Za-z0-9!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]*$/;

const CharLimit = (props) => {
  return (
    <div className={props.className}>{`${props.currentLength}/${MAX_SKILL_LENGTH}`}</div>
  )
}

class Component extends React.Component {

  constructor (props) {
    super(props)
    this.handleSkillChange = this.handleSkillChange.bind(this)
  }

  handleSkillChange = (e) => {
    const value = e.target.value;

    if(!SKILL_NAME_PATTERN.test(value) || value.length > MAX_SKILL_LENGTH){
      return;
    }
    this.props.onSkillChange(value)
  }

  handleRouteChange = () => {
    this.props.onSkillRouteToggle()
  }

  render () {
    const {props} = this;
    const {skill, shouldRouteToGeneral, error, shouldDisableRoutingToGeneral, isGeneralSkill} = props;
    const currentLength = skill && skill.length || 0;

    return (
      <div className={`p-20 ${style.wrapper}`}>
        <div className={style.inputWrapper}>
          <CharLimit id="SkillInputCharLimit" className={style.charLimit} currentLength={currentLength}/>
          <LabelInput className={`normal-caps ${style.labelInput}`} placeholder="Technical support" disabled={isGeneralSkill} value={skill} error={error}
            onChange={this.handleSkillChange}>
            Skill
          </LabelInput>
        </div>
        {!shouldDisableRoutingToGeneral && !isGeneralSkill ?
        <LabelCheckbox
          id="SkillInputChangeRoute"
          className="m-t-20"
          name="general"
          value={shouldRouteToGeneral}
          onClick={this.handleRouteChange}>
          <div>
            <span className="m-r-5">Route chats to the general queue if all agents are offline</span>
            <AppTooltip
              message={TOOLTIP_MESSAGE}
              style={{color: 'black'}} />
          </div>
        </LabelCheckbox> : null}

      </div>
    )
  }

}

Component.propTypes = {
  skill: PropTypes.string,
  error: PropTypes.string,
  shouldRouteToGeneral: PropTypes.bool.isRequired,
  onSkillChange: PropTypes.func.isRequired,
  onSkillRouteToggle: PropTypes.func.isRequired,
  shouldDisableRoutingToGeneral: PropTypes.bool,
  isGeneralSkill: PropTypes.bool
};

Component.defaultProps = {
  skill: '',
  error: '',
  shouldDisableRoutingToGeneral: false,
  isGeneralSkill: false
};

export default  Component;
