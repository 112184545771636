const chatEndedUsers = {
  OTHER: 'Other',
  AGENT: 'Agent',
  USER: 'User'
}

const getChatEndedUser = (end_comment, ended_by, agent) => {
  if(end_comment === 'Ended as anonymous webchat user stopped heartbeating') {
    return chatEndedUsers.OTHER
  } else if(ended_by === agent){
    return chatEndedUsers.AGENT
  } else {
    return chatEndedUsers.USER
  }
} 

export default {
  chatEndedUsers,
  getChatEndedUser
}