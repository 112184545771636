import style from './style.css';
import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal';
import SaveButton from 'components/ui/forms/SaveButton';

const DeleteWarningModal = (props) => (
  <Modal ariaHideApp={false} contentLabel='Modal' className={style.DeleteWarningModal} isOpen={true} onRequestClose={props.closeModal}>
    <div className={`modal-content ${style.ModalContent}`}>
      <div className={`modal-header ${style.ModalHeader}`}>
        Confirm delete Web Conversational Interface
      </div>
      <div className={`modal-body ${style.ModalBody}`}>
        <div className={style.Info}>
          Are you sure you want to delete <strong>{props.widgetName}</strong>?
          This Web Conversational Interface will be disabled on your website and all open chats will be ended.
        </div>
        <div className={style.Footer}>
          <button className={style.CloseButton} onClick={props.closeModal}>
            Cancel
          </button>
          <SaveButton className={`btn-danger ${style.SaveButton}`} isLoading={props.isSubmitting}
            onClick={props.onSubmit}>
            Delete
          </SaveButton>
        </div>
      </div>
    </div>
  </Modal>
)

DeleteWarningModal.propTypes = {
  widgetName: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default  DeleteWarningModal
