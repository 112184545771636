import {
  SPECIAL_CHARS,
} from '../constants'

const now = () => Date.now()

const includes = (list, item) => list.indexOf(item) !== -1

const startsWith = (str, against) => (
  str.slice(0, against.length) === against
)

const replaceSpecialChars = (str) => (
  str.replace(SPECIAL_CHARS.NULL, '')
    .replace(SPECIAL_CHARS.LF, '\n')
)

const isChrome = () => {
  /*
    Source: http://stackoverflow.com/a/13348618

    Please note, that IE11 now returns undefined again for
    window.chrome and new Opera 30 outputs true for window.chrome and
    new IE Edge outputs to true now for window.chrome and if not iOS
    Chrome check so use the below updated condition
  */

  var isChromium = window.chrome,
      winNav = window.navigator,
      vendorName = winNav.vendor,
      isOpera = winNav.userAgent.indexOf("OPR") > -1,
      isIEedge = winNav.userAgent.indexOf("Edge") > -1,
      isIOSChrome = winNav.userAgent.match("CriOS")

  if (isIOSChrome) {
    return true
  } else if (isChromium !== null && isChromium !== undefined && vendorName === "Google Inc." && isOpera == false && isIEedge == false) {
    return true
  } else {
    return false
  }

}

const isFirefox = () => (
  navigator.userAgent.toLowerCase().indexOf('firefox') > -1
)

const shouldAlwaysLogPlainly = () => {
  if (typeof window !== 'undefined')
    return window.sessionStorage.getItem('force-plain-logging') === 'true'
  else
    return false
}

export {
  now,
  includes,
  startsWith,
  replaceSpecialChars,
  isChrome,
  isFirefox,
  shouldAlwaysLogPlainly,
}
