import { heartbeatConstants as types } from '../constants'

const getInitialState = () => ({
  lastSeqNumSent: 0,
  lastSeqNumReceived: 0,
  isAgentDisconnected: false
});

const heartbeats = (state=getInitialState(), action) => {
  switch (action.type) {
  case types.HEARTBEAT_SENT:
    return {
      ...state,
      lastSeqNumSent: action.seqNum,
      isAgentDisconnected: action.isAgentDisconnected
    }
  case types.HEARTBEAT_RECEIVED:
    return {
      ...state,
      lastSeqNumReceived: action.seqNum
    }
  default:
    return state
  }
}

export default heartbeats
