import React, { Component } from 'react';
import Select from 'react-select';
import DropdownIndicator from './DropdownIndicator';
// import { NoResultsMessage } from '../../CreateAndAssignUser/PermissionMap/RoleEntityDropdown/helpers';

// const NoOptionsMessage = () => <NoResultsMessage>No results found</NoResultsMessage>;

const selectStyles = {
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    padding: '5px',
    transform: state.isFocused ? 'rotate(180deg)' : null,
  }),
  placeholder: base => ({
    ...base,
    marginLeft: 0,
    marginRight: 0,
  }),
  menu: base => ({
    ...base,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.23)',
    border: 'solid 1px #0067f3',
    marginTop: '-2px',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  }),
  groupHeading: base => ({
    ...base,
    color: '#0067f3',
    fontSize: '10px',
    fontWeight: 'bold',
    paddingTop: '4px',
    paddingBottom: '4px',
  }),
  option: (base, { isFocused }) => ({
    ...base,
    paddingTop: '4px',
    paddingBottom: '4px',
    marginTop: '4px',
    marginBottom: '4px',
    color: '#000',
    backgroundColor: isFocused ? 'rgba(153, 153, 153, 0.2)' : 'none',
  }),
  // TODO: shadow stays after selecting an option.
  // isFocused is not the correct state
  control: (base, state) => ({
    ...base,
    minHeight: '32px',
    backgroundColor: '#fff',
    borderColor: state.isFocused ? '#0067f3' : '#999',
    // borderBottomLeftRadius: state.isFocused ? 0 : '4px',
    // borderBottomRightRadius: state.isFocused ? 0 : '4px',
    boxShadow: 'none',
    // boxShadow: state.isFocused ? '0 3px 6px 0 rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.23)' : 'none',
  }),
};

class SelectComponent extends Component {
  menuIsOpen = () => this.select.state.menuIsOpen

  render() {
    const { components, ...rest } = this.props;
    return (
      <Select
        ref={(ref) => { this.select = ref; }}
        components={components || { DropdownIndicator }}
        styles={selectStyles}
        hideSelectedOptions={false}
        menuPlacement="auto"
        {...rest}
      />
    );
  }
}


export default SelectComponent;
