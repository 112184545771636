import _ from 'lodash'
import { BOT_USER_SESSION_LOGOUT } from "bot-user-session";

import {
  REQUEST_CONVERSATIONS,
  RECEIVE_CONVERSATIONS,
  APPEND_MESSAGE_TO_CONVERSATION,
  REMOVE_UNREAD_MSG,
  ADD_UNREAD_MSG,
  GET_CONSUMER_DETAILS,
  LAST_RECIPIENT_UPDATED,
  SORT_CONVERSATIONS,
  RECENTLY_ENDED_CHAT_FOR_CLASSIFICATION,
  ENDED_NOT_CLASSIFIED_CHAT_SESSION,
  ENDED_AND_CLASSIFIED_CHAT_SESSION,
  REMOVE_ENDED_NOT_CLASSIFIED_CHAT_SESSION,
  SET_MESSAGE_TIMER,
  LAST_READ_MESSAGE,
  SET_CLOSE_TO_CHAT_END_ID,
} from "./../constants"

const getInitialState = () => ({
  isFetching: false,
  didInvalidate: false,
  conversations: [],
  moreConversationsToken: null,
  unreadConversations: [],
  unreadMessages: [],
  lastReadMessages: {},
  messageTimestamps: {},
  chatSessions: [],
  endedNotClassifiedChatSessions: [],
  recipient: {},
  endedChatForClassification: null,
  closeToChatEndId:null,
});

function getConsumerMessages(chat_sessions){
  var consumerMsgs = _.chain(chat_sessions)
  .reduce(function(res, val, key){
    return _.concat(res, val.history);
  },[])
  .map('chat_msg')
  .filter(function(msg){
    return msg.from_side == 'consumer';
  }).value();
  return consumerMsgs;
}

function getUnreadMessages(chat_sessions, userId){
  var consumerMsgs = getConsumerMessages(chat_sessions);
  var readMsgIds = _.chain(chat_sessions)
  .reduce(function(res, val, key){
    return _.concat(res, val.read_receipts);
  },[])
  .map('read_receipt')
  .filter(function(receipt){
    return receipt.user_id === userId
  })
  .reduce(function(res, val, key){
    var concat = _.concat(res, val.msg_ids);
    return concat;
  },[])
  .uniq().value();

  var unreadMessages = _.chain(consumerMsgs)
  .filter(function(msg){
    return !_.includes(readMsgIds, msg.id)
  }).value()

  return unreadMessages;
}

function getUnreadMessagesIds(chat_sessions, userId){
  var unreadMessageIds = _.map(getUnreadMessages(chat_sessions, userId), function(o){
    return o.id
  })
  return unreadMessageIds;
}

function getUnreadConversationIds(chat_sessions) {
  var unreadMessages = getUnreadMessages(chat_sessions);
  var unreadChatIds = _.chain(unreadMessages)
  .map(function(msg){
    return msg.chat_id
  }).uniq().value();

  return unreadChatIds;
}


export default function allConversations(state = getInitialState(), action) {
  switch (action.type) {
    case BOT_USER_SESSION_LOGOUT: {
      return getInitialState();
    }
    case LAST_RECIPIENT_UPDATED:
      const recipient = action.payload;

      return Object.assign({}, state, { recipient });
    case APPEND_MESSAGE_TO_CONVERSATION:
      let conversation = _.find(state.conversations, c => c.id === action.message && action.message.chat_id) || {};
      const consumerInfo = state.recipient;
      const consumerId = action.message.from_side === 'consumer' ? action.message.from : state.recipient.id
      const consumerName = action.message.from_side === 'consumer' ?
        action.message.metadata.from_name :
        `${consumerInfo.first_name} ${consumerInfo.last_name}`;
      const update = {
        consumer_name: consumerName,
        recent_msg: Object.assign({}, action.message),
        consumer: consumerId
      };

      if(_.isEmpty(conversation)) {
        state.conversations.unshift(Object.assign({}, {
          id: action.message.chat_id,
          stream_name: action.message.metadata.stream_name
        }, update));
      } else {
        Object.assign(conversation, update);
      }

      var updatedConversations = _.chain(state.conversations)
        .sortBy(o => o.recent_msg.timestamp)
        .reverse()
        .value();

      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        conversations: updatedConversations,
        lastUpdated: action.receivedAt
      });
    case REQUEST_CONVERSATIONS:
      let _conversations = action.more ? state.conversations : [];
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
        conversations:_conversations,
        lastUpdated: action.receivedAt
      })
    case RECEIVE_CONVERSATIONS:
      let json = action.conversations;
      const { userId } = action
      let { chat_sessions, consumer_users } = json;
      let moreConversationsToken = action.more === 'done' ? null : action.more;

      let conversations = _(chat_sessions)
        .filter(conversation => {
          let history = conversation.history;
          let conlength = history.length;
          if(conlength === 1 && history[0].chat_msg.campaign_id){ // initial campaign msg, don't show as a chat session!
            return false;
          } else return conlength > 0
        }) // filter out empty objects (auto-created when consumer joins pype)
        .map(conversation => {
          let consumer = _.find(consumer_users, {id: conversation.consumer});
          conversation.consumer_avatar = consumer.avatar;
          conversation.recent_msg = _.last(conversation.history).chat_msg;
          return conversation;
        })
        .sortBy(o => o.recent_msg.timestamp)
        .reverse()
        .value();

      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        conversations: _.uniqBy(_.concat(state.conversations,conversations),'id'), // API returns duplicates
        moreConversationsToken,
        lastUpdated: action.receivedAt,
        unreadConversations: _.uniq(_.concat(state.unreadConversations,getUnreadConversationIds(chat_sessions))),
        unreadMessages: _.uniq(_.concat(state.unreadMessages,getUnreadMessagesIds(chat_sessions, userId)))
      })
    case REMOVE_UNREAD_MSG:
      let receipt = action.receipt;
      let readConversationId = receipt.chat_id;
      let readMsgIds = receipt.msg_ids;
      return Object.assign({}, state, {
        unreadMessages: _.difference(state.unreadMessages,readMsgIds),
        unreadConversations: _.without(state.unreadConversations,readConversationId)
      })
    case ADD_UNREAD_MSG:
      let message = action.message;
      let conversationId = message.chat_id;
      let msgId = message.id;

      return Object.assign({}, state, {
        unreadMessages: _.union(state.unreadMessages, [msgId]),
        unreadConversations: _.union(state.unreadConversations, [conversationId])
      })
    case SORT_CONVERSATIONS:
      let sortedConversations = _.orderBy(state.conversations, function(conversation) {
        return conversation.recent_msg.timestamp
      }, ['desc']);
      return Object.assign({}, state, {
        conversations: sortedConversations
      })
    case GET_CONSUMER_DETAILS:
    case RECENTLY_ENDED_CHAT_FOR_CLASSIFICATION:
      return Object.assign({}, state, {
        endedChatForClassification: action.endedChatForClassification
      })
    case ENDED_NOT_CLASSIFIED_CHAT_SESSION:
      return {...state,
        endedNotClassifiedChatSessions: [...state.endedNotClassifiedChatSessions, action.endedNotClassifiedChat]
      }
    case ENDED_AND_CLASSIFIED_CHAT_SESSION:
      return {...state,
        endedNotClassifiedChatSessions: state.endedNotClassifiedChatSessions.filter(item => item !== action.endedAndClassifiedChat)
      }
    case REMOVE_ENDED_NOT_CLASSIFIED_CHAT_SESSION:
      return {
        ...state,
        endedNotClassifiedChatSessions: []
      }
    case SET_MESSAGE_TIMER:
      let newMessageTimestamp;
      if(state.messageTimestamps[action.payload.data.chat_id]){
        newMessageTimestamp=[...state.messageTimestamps[action.payload.data.chat_id], {chat_msg: action.payload.data}];
      } else {
        newMessageTimestamp=[{chat_msg: action.payload.data}];
      }
      return {
        ...state,
        messageTimestamps: {
          ...state.messageTimestamps,
          [action.payload.data.chat_id]: newMessageTimestamp
        }
      }
    case LAST_READ_MESSAGE:
      return {
        ...state,
        lastReadMessages: {
        ...state.lastReadMessages,
        [action.payload.chatId]: action.payload.data
          }
      }
    case SET_CLOSE_TO_CHAT_END_ID:
      return {
        ...state,
        closeToChatEndId: action.payload
      }
    default:
      return state
  }
}
