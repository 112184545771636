import styled from 'styled-components';

export const Bottom = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 22px;
    margin-top: auto;
    .logo-wrapper {
        text-align: center;
        padding-top: 35px;
    }
`;
