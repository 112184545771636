import React from 'react'
import PropTypes from 'prop-types'
import RCColorPicker from 'rc-color-picker';

const validatedColor = (color) => {
  return /^#[0-9A-F]{6}$/i.test(color) ? color : '#ffffff'
}

const ColorPicker = (props) => {
  const validatedProps = {
    ...props,
    color: validatedColor(props.color)
  }
  return (
    <RCColorPicker
        color={validatedProps.color}
        onChange={props.onChange}
        placement={props.placement}
        {...validatedProps}>
        <span></span>
    </RCColorPicker>
  )
}

ColorPicker.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  placement: PropTypes.oneOf(['topLeft', 'topRight', 'bottomLeft', 'bottomRight']),
  onChange: PropTypes.func,
}

ColorPicker.defaultProps = {
  className: '',
  color: '#fff',
  placement: 'topLeft',
  onChange () {},
}


export default  ColorPicker
