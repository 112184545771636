import SDK from '../SDK'
import upload from '../TUSClient';
import { guid } from './../common/utils/common-utils';
import { appendMessageToConversation } from './conversations';
import { updateReadCount,removeChatSession } from './agentChatSessions';
import { incChatCount, decChatCount } from './agent'
import { onMessage, offMessage } from '../STOMPClient'

import _ from 'lodash'

import {
  REQUEST_CONVERSATION_HISTORY,
  FETCH_CONVERSATION_HISTORY_ERROR,
  RECEIVE_CONVERSATION_HISTORY,
  REQUEST_CONVERSATION_MIGRATION_STATS,
  RECEIVE_CONVERSATION_MIGRATION_STATS,
  CREATE_CHAT,
  CREATE_CHAT_RES,
  CREATED_CHAT_SESSION,
  CHANGE_HISTORY_FILTERS_PANEL_VIEW,
  SET_SELECTED_HISTORY_FILTERS,

  CONVERSATIONS_FILE_UPLOAD_STARTED,
  CONVERSATIONS_FILE_UPLOAD_SUCCEEDED,
  CONVERSATIONS_FILE_UPLOAD_FAILED,
  CONVERSATIONS_FILE_UPLOAD_PROGRESS_CHANGED,
  CONVERSATIONS_FILE_REMOVED,

  SEND_MSG_READ_RECEIPT,
  SEND_MSG_READ_RECEIPT_RES,

  REQUEST_CONSUMER_DETAILS,
  RECEIVE_CONSUMER_DETAILS,

  MIXED_MESSAGE_SEND_STARTED,
  MIXED_MESSAGE_FILE_UPLOAD_PROGRESS_CHANGED,
  MIXED_MESSAGE_FILE_UPLOAD_SUCCEEDED,
  MIXED_MESSAGE_FILE_UPLOAD_FAILED,
  MIXED_MESSAGE_TEXT_SENDING_STARTED,
  MIXED_MESSAGE_TEXT_SENDING_SUCCEDED,
  MIXED_MESSAGE_TEXT_SENDING_FAILED,
  MIXED_MESSAGE_TEXT_SENDING_TIMEOUT,

  LAST_RECIPIENT_UPDATED,

  AGENT_TYPING_STATUS_CHANGED,
  CONSUMER_TYPING_STATUS_CHANGED,
  CONSUMER_TYPING_STATUS_UPDATE,

  REQUEST_SKILLS,
  RECEIVE_SKILLS,
} from "./../constants"
import activityTracker from "../../utils/activityTracker";

const CREATE_MSG_TIMEOUT = 30000;
let createChatTimers = {}
let consumerTypingTimeout = null;
let agentTypingInterval = null;
// Chat history

function requestConversationHistory() {
  return {
    type: REQUEST_CONVERSATION_HISTORY
  }
}

export function receiveConversationHistory(json) {
  return (dispatch, getState) =>{
    var { lastAction } = getState();

    if(lastAction.type === REQUEST_CONVERSATION_MIGRATION_STATS){
      dispatch({
        type: RECEIVE_CONVERSATION_MIGRATION_STATS,
        shouldMigrateNewConversation: json.to_be_migrated
      })
    } else {
      dispatch({
        type: RECEIVE_CONVERSATION_HISTORY,
        history: json,
        receivedAt: Date.now()
      })
    }
  }
}

const fetchConversationHistoryError = (error) => ({
  type: FETCH_CONVERSATION_HISTORY_ERROR,
  error,
})

export const fetchConversationHistory = (chat_id, from) => (dispatch) => {
  dispatch(requestConversationHistory())
  return SDK.getConversationHistory({chat_id, from}).then((data) => {
    dispatch(receiveConversationHistory(data))
  }).catch((error) => {
    console.warn('fetchConversationHistory error:', error)
    dispatch(fetchConversationHistoryError(error))
  })
}

function requestMigrationStats() {
  return {
    type: REQUEST_CONVERSATION_MIGRATION_STATS
  }
}


export function fetchMigrationStatus({chat_id, from}) {
  SDK.getConversationHistory(chat_id, from);
  return dispatch => {
      dispatch(requestMigrationStats())
  }
}


// Send chat

export function createChat(data){
  return (dispatch,getState) => {
    return new Promise((resolve, reject) => {

      const state = getState();
      const { chat_id, msg, msg_type, file_url, to_be_migrated, user, message, app_object  } = data
      const clientMsgId = guid()

      dispatch(saveRecipient(user));

      createChatTimers[clientMsgId] = setTimeout(()=>{
        dispatch({
          type: MIXED_MESSAGE_TEXT_SENDING_TIMEOUT,
          error: true,
          payload: "Unable to send message",
          chat_id
        });
      }, CREATE_MSG_TIMEOUT);

      activityTracker.logEvent(activityTracker.eventTypeNames.SEND_MESSAGE,{environment: state.pype.details.env});

      dispatch({
        type: CREATE_CHAT
      })

      SDK.createChatMessage({
        client_msg_id: clientMsgId, 
        chat_id, 
        msg, 
        msg_type, 
        file_url,  
        to_be_migrated,
        message,
        app_object,
      }).then(() =>{
        resolve()
      })

    })
  }
}

export function saveRecipient(user) {
  return {
    type: LAST_RECIPIENT_UPDATED,
    payload: user
  };
}

export function createdChat(req_id, createdChat){
  return async (dispatch, getState) => {
    const state = getState()
    const {chatSessions} = state.agentChatSessions
    const pypeId = state.pype.details.id
    const userId = state.botUserSession.user.user_id
    const session = _.find(chatSessions, {id: createdChat.chat_id})
    let isParticipant = true; 

    if(session){
      createdChat = {
        ...createdChat,
        consumer_name: session.consumer_name,
        stream_name: session.stream_name,
        consumer_id: session.consumer
      }
    } else {
      // when a dual-role user receives a chat_msg notice for a chat that's neither in My queues nor Agent queues
      // check if the user is actually a participant of that chat
      const chatHistory = await SDK.getConversationHistory({chat_id: createdChat.chat_id, from:userId})
      isParticipant = chatHistory.participants.agent === userId
    }
    dispatch(appendMessageToConversation(createdChat))
    dispatch({
      req_id,
      type: CREATE_CHAT_RES,
      createdChat,
      userId,
      pypeId,
      isParticipant
    })
    if(createChatTimers[createdChat.client_msg_id]){
      clearTimeout(createChatTimers[createdChat.client_msg_id]);
      delete createChatTimers[createdChat.client_msg_id]
    }
  }
}

export function uploadFile(file, params) {
  return (dispatch, getState) => {
    const parameters = {
      context_id: params.context_id,
      context: 'message',
      filename: encodeURIComponent(file.name),
      client_msg_id: guid()
    };

    function onProgress(bytesUploaded, bytesTotal) {
      dispatch({
        type: CONVERSATIONS_FILE_UPLOAD_PROGRESS_CHANGED,
        payload: {
          bytesUploaded,
          bytesTotal
        }
      });
    }

    dispatch({
      type: CONVERSATIONS_FILE_UPLOAD_STARTED,
      payload: {
        file
      }
    });

    upload(file, parameters, onProgress)
      .then(
        (response) => {
          console.log('file successfully uploaded');
          dispatch({
            type: CONVERSATIONS_FILE_UPLOAD_SUCCEEDED,
            payload: response,
            client_msg_id: parameters.client_msg_id
          });

          return response;
        },
        (error) => {
          console.log('error occurred');
          dispatch({
            type: CONVERSATIONS_FILE_UPLOAD_FAILED,
            error: true,
            payload: error
          });
        }
      );
  };
}

export function removeAttachment() {
  return (dispatch, getState) => {
    dispatch({
      type: CONVERSATIONS_FILE_REMOVED
    });
  }
}

// Send read message receipts
export function readMessages(data, request){
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: SEND_MSG_READ_RECEIPT
      })


      const {chat_id,msg_ids,client_timestamp,to_be_migrated} = data
      SDK.readMessages({
        user_id: getState().botUserSession.user.user_id,
        chat_id,
        msg_ids,
        client_timestamp,
        to_be_migrated
      })

      function handler (payload) {
        if (payload.type === 'notice' && payload.detail === 'read_receipt') {
          offMessage(handler)
          resolve(payload.data)
        }
      }
      onMessage(handler)
      
    })
  }
}

// read_receipt response
export function receiveReadMessages(req_id, data) {
  return (dispatch, getState) => {
    dispatch(updateReadCount(data));
    dispatch({
      req_id,
      type: SEND_MSG_READ_RECEIPT_RES,
      receipts: data,
      receivedAt: Date.now()
    })
  }
}

// Notification of a created new chat session
export function createdChatSession(json) {
  return (dispatch, getState) => {
    const isParticipant = json.participants.agent === getState().botUserSession.user.user_id;

    if(!isParticipant) return;

    dispatch({
      type: CREATED_CHAT_SESSION,
      chat_session: json,
      receivedAt: Date.now()
    })
    dispatch(incChatCount())
    //TODO dispatch createdChat() from here once chat notice contains stream name
  }
}

/**
 * Send mixed message (text + attachment) to server
 *
 * @param {string} text - Text of a message
 * @param {string} chatId - Chat to send message to
 * @param {File} file - File in attachment
 * @param {Object} params - Additional parameters
 * @returns {function()}
 */
export function sendMessageWithAttachment(data) {
  return (dispatch, getState) => {
    const {text, chatId, file, user, params, to_be_migrated} = data;
    const client_msg_id = guid();
    const parameters = {
      context_id: chatId,
      context: 'message',
      filename: encodeURIComponent(file.name),
      client_msg_id
    };
    const userId = getState().botUserSession.user.user_id

    dispatch({
      type: MIXED_MESSAGE_SEND_STARTED,
      payload: {
        text, chatId, file, user, params, client_msg_id
      },
      userId
    });

    function onProgress(bytesUploaded, bytesTotal) {
      // this event is used for showing actual state of an upload
      // dispatch({
      //   type: MIXED_MESSAGE_FILE_UPLOAD_PROGRESS_CHANGED,
      //   payload: {
      //     bytesUploaded,
      //     bytesTotal
      //   }
      // });
    }

    return new Promise((resolve, reject) => {
      upload(file, parameters, onProgress)
        .then(
          (response) => {
            console.log('file successfully uploaded');
            dispatch({
              type: MIXED_MESSAGE_FILE_UPLOAD_SUCCEEDED,
              payload: response,
              client_msg_id: parameters.client_msg_id
            });

            return {
              file_url: response,
              client_msg_id: parameters.client_msg_id
            };
          },
          (error) => {
            console.log('error occurred while file uploading');
            dispatch({
              type: MIXED_MESSAGE_FILE_UPLOAD_FAILED,
              error: true,
              payload: error
            });
            reject(error);
          }
        )
        .then(
          (payload) => {
            console.log('start sending text');
            dispatch({
              type: MIXED_MESSAGE_TEXT_SENDING_STARTED
            });

            return dispatch(createChat({
              chat_id: chatId,
              msg: text,
              msg_type: params.attachmentType,
              file_url: payload.file_url,
              to_be_migrated,
              user
            }));
          }
        )
        .then(
          (response) => {
            console.log('text successfully sent');
            dispatch({
              type: MIXED_MESSAGE_TEXT_SENDING_SUCCEDED,
              payload: response
            });

            dispatch(saveRecipient(user));
            resolve(response);
          },
          (err) => {
            console.log('error ocurred while sending text');
            dispatch({
              type: MIXED_MESSAGE_TEXT_SENDING_FAILED,
              error: true,
              payload: err
            });
            reject(err);
          }
        );
    });
  }
}

export function receiveConsumerDetails(req_id, json) {
  return {
    req_id,
    type: RECEIVE_CONSUMER_DETAILS,
    consumer_data: json
  }
}

function agentTypingStatusChanged(data){
  return {
    type: AGENT_TYPING_STATUS_CHANGED,
    data
  }
}

function sendIsTyping(data){
  return (dispatch,getState) => {
    const {isAgentTyping} = getState().conversationHistory;
    if(!isAgentTyping){
      clearInterval(agentTypingInterval);
      return;
    }
    const { chat_id, from, is_typing } = data
    SDK.notifyTyping({
      chat_id, 
      from, 
      is_typing
    })
  }
}

export function toggleAgentTypingStatus(isTyping) {

  return (dispatch,getState) => {
    const {id} = getState().conversationHistory;

    const data = {
      chat_id: id,
      from: getState().botUserSession.user.user_id,
      is_typing: isTyping,
      from_side: 'agent'
    }

    dispatch(agentTypingStatusChanged(data));

    if(isTyping){
      dispatch(sendIsTyping(data))
      agentTypingInterval = setInterval(()=>{
        dispatch(sendIsTyping(data))
      },5000);
    } else {
      clearInterval(agentTypingInterval);
    }
  }
}

export function receivedConsumerTypingStatus(data){
  return (dispatch) => {
    if(consumerTypingTimeout) {
      clearTimeout(consumerTypingTimeout)
    }
    consumerTypingTimeout = setTimeout(()=>{
      dispatch(setConsumerTypingStatus(false));
    },30000);
    dispatch({
      type: CONSUMER_TYPING_STATUS_CHANGED,
      data
    })
  }
}

export function setConsumerTypingStatus(isTyping){
  return (dispatch) => {
    dispatch({
      type: CONSUMER_TYPING_STATUS_UPDATE,
      isTyping
    })
  }
}


export const transferChat = (data) => (dispatch, getState) => {
  const {chat_id,to_agent, comment, skill} = data
  const user_id = getState().botUserSession.user.user_id
  return SDK.reassignChat({
    chat_id,
    user_id,
    to_agent,
    comment,
    skill
  }).then(()=>{
    // clear session
    dispatch(removeChatSession(chat_id))
  })
}

function requestSkills() {
  return {
    type: REQUEST_SKILLS
  }
}

function receiveSkills(data) {
  return {
    type: RECEIVE_SKILLS,
    skills: data
  }
}

export const fetchSkills = (skill_id) => (dispatch, getState) => {
  const pype_id = getState().pype.details.id

  dispatch(requestSkills())
  let data = {
    pype_id
  };
  if(skill_id) {
    data = {
      ...data,
      skill_id
    }
    return SDK.getSkill({
      pype_id, 
      skill_id
    }).then((data)=> {
      dispatch(receiveSkills(data.list))
    })
  } else {
    return SDK.getSkills({
      pype_id
    }).then((data)=> {
      dispatch(receiveSkills(data.list))
    })
  }
}

export const changeHistoryFiltersView = (reset = false) => {
  return {
    type: CHANGE_HISTORY_FILTERS_PANEL_VIEW,
    isHistoryFilterPanelOpen: reset,
  };
}

export const setSelectedHistoryFilters = (selectedHistoryFilters = {}) => {
  return {
    type: SET_SELECTED_HISTORY_FILTERS,
    selectedHistoryFilters,
  };
}
