import React from 'react';
import PropTypes from 'prop-types';
import ChevronUp from '../../../../img/up.svg';
import ChevronDown from '../../../../img/down.svg';
import styled, { css } from 'styled-components';

const ScrollArrow = styled.button`
  background-color: #0a3ab4;
  color: #f1f1f1;
  border-style: none;
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 4px;
  z-index: 2;
  position: fixed;
  text-align: center;
  &:hover {
    background-color: #062d9a;
  }
  &:active {
    background-color: #042181;
  }
  &:after {
    ${(props) =>
      props.newMessage &&
      css`
        content: '';
        position: absolute;
        top: 1px;
        right: 1px;
        transform: translate(50%, -50%);
        width: 16px;
        height: 16px;
        background-color: ${props.newMessageTimeExceeded
          ? '#e45f4f'
          : '#32b777'};
        border-radius: 100%;
      `}
  }

  ${(props) =>
    props.scrollDirection === 'Down'
      ? props.chatType !== 'active'
        ? css`
            bottom: 32px;
          `
        : css`
            bottom: 78px;
          `
      : props.chatType !== 'active'
      ? css`
          top: 138px;
        `
      : css`
          top: 179px;
        `};
  left: ${(props) =>
    props.userInfoIsOpen ? 'calc(50%)' : 'calc(50% + 169px)'};

  > div {
    width: 24px;
    height: 24px;
  }
`;

const ChatWithScrollArrow = ({
  chatType,
  scrollDirection,
  onClick,
  newMessage,
  newMessageTimeExceeded,
}) => {
  let arrow = scrollDirection === 'Down' ? ChevronDown : ChevronUp;
  let userInfoIsOpen = JSON.parse(sessionStorage.getItem('userInfoIsOpen'));

  return (
    <ScrollArrow
      data-test='scroll-arrow'
      chatType={chatType}
      scrollDirection={scrollDirection}
      newMessage={newMessage}
      userInfoIsOpen={userInfoIsOpen}
      newMessageTimeExceeded={newMessageTimeExceeded}
      onClick={onClick}
    >
      <div>
        <img data-test='scroll-arrow-img' src={arrow} alt='' />
      </div>
    </ScrollArrow>
  );
};

ChatWithScrollArrow.defaultProps = {
  newMessage: false,
  newMessageTimeExceeded: false,
};

ChatWithScrollArrow.propTypes = {
  onClick: PropTypes.func.isRequired,
  scrollDirection: PropTypes.string.isRequired,
  newMessage: PropTypes.bool,
  newMessageTimeExceeded: PropTypes.bool,
  chatType: PropTypes.string,
};

export default ChatWithScrollArrow;
