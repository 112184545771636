import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import errorTracker from 'utils/errorTracker'
import {
  Wrapper,
  Title,
  SubTitle
} from './helpers'
class ErrorBoundary extends Component {
	constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

	static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  static defaultProps = {
    title: 'An unexpected error has happened',
    subTitle: 'Please wait a moment and then try reloading the page.'
  }
  
  componentDidMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      if (this.state.hasError) {
        this.setState({ hasError: false });
      }
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

	componentDidCatch(error, errorInfo) {
    errorTracker.logError(error, errorInfo);
	}
	
  render() {
    if (this.state.hasError) {
      return (
        <Wrapper className={this.props.className}>
          <img src={`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-warning-large.svg`}/>
          <Title>{this.props.title}</Title>
          <SubTitle>{this.props.subTitle}</SubTitle>
        </Wrapper>
      );
    }
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
