import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';
import cx from 'classnames';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'ui-elements';
import { Typography, TextField, InputAdornment, Grid } from '@material-ui/core';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import ReplayIcon from '@material-ui/icons/Replay';
import { Tabs, Tab } from '@material-ui/core';
import ConversationComponent from './Conversation/Component';
import PagedList from '../ui/paging/PagedList';
import { WarningTooltip, disabledTitle } from './Conversation/helpler';
import constants from 'core/constant';
import AgentAway from './AgentAway';
import AgentBusy from './AgentBusy';
import AgentOffline from './AgentOffline';
import AgentStatus from './AgentStatus';
import PostChatModal from './PostChatModal';
import activityTracker from '../../utils/activityTracker';
import ChatBubbleImage from '../../img/combined-shape.svg';
import { doesUserHasRole, PYPE_MANAGER_ROLES, PYPE_AGENT_ROLES } from 'bot-user-session';
import Constant from "core/constant";
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import sdk from 'sdk';
import HistoryFilters from './HistoryFilters';
const { agentChatSessionsActions, utils, conversationsActions, conversationHistoryActions } = sdk;
const { getUnreadChatSessions } = utils.agentChatSessions;

export const TabTypes = {
  active: 'active',
  escalated: 'escalated',
  archived: 'archived'
};

const ReactTabs = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const SearchBox = styled.form`
  background-color: #f7f7f7;
  position: relative;
`;

const SearchBottomLabel = styled.div`
  .search-bottom-container {
    font-size: 13px;
    color: rgba(121, 121, 121, 0.9);
    font-weight: bold;
    margin: 2px 20px;
    display: block;
    padding: 2px 25px;
    position: relative;
  }
  svg {
    position: absolute;
    left: 0;
    top: 4px;
    width: 15px;
    height: 15px;
  }
`;

const refreshRotation = keyframes`
  0% {
    transform: rotate(359deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;

const refreshIconStyles = css`
  animation: ${refreshRotation} 1s 0s infinite linear;
  pointer-events: none;
  #fillBG {
    fill: #000;
    fill-opacity: 0.4;
  }
`;

const RefreshSpinner = styled(ReplayIcon)`
  cursor: pointer;
  ${(props) => props.rotating && refreshIconStyles};
`;

const SearchInput = ({
  inputValue,
  handleInput,
  resetInput,
  onBlur,
  autoFocus,
  placeholder = 'Search'
}) => {
  return (
    <div className='conversationSearchBox'>
      <TextField
        fullWidth
        autoFocus={autoFocus}
        className='conversationSearchField'
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              {inputValue ? (
                <HighlightOffOutlinedIcon
                  className='resetIcon'
                  color='primary'
                  onClick={resetInput}
                />
              ) : (
                <SearchOutlinedIcon className='searchIcon' color='disabled' />
              )}
            </InputAdornment>
          )
        }}
        value={inputValue}
        placeholder={placeholder}
        onChange={handleInput}
        onBlur={onBlur}
        variant='outlined'
      />
    </div>
  );
};

export class ConversationsList extends React.Component {
  static propTypes = {
    agentStatus: PropTypes.oneOf(['online', 'busy', 'away', 'offline']).isRequired,
    activeChats: PropTypes.object.isRequired,
    activeChatsIsFetching: PropTypes.bool,
    activeChatsHasMore: PropTypes.bool,
    archivedChats: PropTypes.object,
    unreadChats: PropTypes.array.isRequired,
    requestMore: PropTypes.func.isRequired,
    requestMoreArchived: PropTypes.func,
    isManager: PropTypes.bool.isRequired,
    isAgent: PropTypes.bool.isRequired,
    onTabChange: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      postChatModalIsOpen: false,
      activeTab: props.isAgent ? TabTypes.active : TabTypes.escalated,
      searchInput: '',
      showStillSearchingText: false,
      showFilters: false,
      newFiltersApplied: false,
      waitingForChats: false,
      position: {x: undefined, y:undefined}
    };
  }

  componentDidMount() {
    const { isManager } = this.props;
    const { activeTab } = this.state;
    this.timer = null;
    if (isManager) {
      this.props.requestMoreEscalated(true);
      this.props.requestMoreArchived(true);
    }
    // So as to provide active tab to Conversations component for Manager role (agent queue)
    this.props.onTabChange(activeTab);
  }

  componentWillReceiveProps(nextProps) {
    const {
      archivedChats,
      archivedChatsIsFetching,
      archivedChatsHasMore,
      archivedItemsPerPage,
      archivedSearchSize,
      requestMoreArchived,
      escalatedChats,
      escalatedChatsIsFetching,
      selectedHistoryFilters,
      searchingArchivedChatsCancelled
    } = nextProps;

    const {
      activeTab,
      waitingForChats,
      searchInput,
      newFiltersApplied,
      showStillSearchingText
    } = this.state;

    if (nextProps.location && nextProps.location.search && nextProps.flags.pe18541){
      this.setState({
        activeTab: TabTypes.active
      },()=>{
        this.props.onTabChange(activeTab);
      })
    }

    if (
      activeTab === TabTypes.archived &&
      !archivedChatsIsFetching &&
      this.props.archivedChatsIsFetching
    ) {
      if (
        archivedChatsHasMore &&
        archivedChats.length < archivedItemsPerPage &&
        archivedSearchSize > -1 &&
        !searchingArchivedChatsCancelled
      ) {
        this.setState({ showStillSearchingText: true });
        return requestMoreArchived(false, searchInput || null);
      } else if (showStillSearchingText) {
        this.setState({ showStillSearchingText: false });
      }
    }

    if (newFiltersApplied && !archivedChatsIsFetching) {
      this.applyFiltersLogger(selectedHistoryFilters, archivedChats.length);
      this.setState({ newFiltersApplied: false });
    }

    if (waitingForChats && searchInput !== '') {
      if (activeTab === TabTypes.archived && !archivedChatsIsFetching) {
        this.searchInputLogger(archivedChats.length);
        this.setState({ waitingForChats: false });
      } else if (activeTab === TabTypes.escalated && !escalatedChatsIsFetching) {
        this.searchInputLogger(escalatedChats.length);
        this.setState({ waitingForChats: false });
      }
    }

    if (this.props.flags.pe17819 && activeTab === TabTypes.escalated && !escalatedChatsIsFetching && escalatedChats && escalatedChats.length === 0 && window.location.href.includes('chats/view')) {
      this.props.history.goBack();
    }
  }

  loadMoreChats = () => {
    const { activeTab, searchInput } = this.state;
    const {
      isAgent,
      escalatedChatsIsFetching,
      escalatedChatsHasMore,

      archivedChatsIsFetching,
      archivedChatsHasMore,

      activeChatsIsFetching,
      activeChatsHasMore,
      requestMoreEscalated,
      requestMoreArchived,
      requestMore
    } = this.props;

    if (activeTab === TabTypes.escalated) {
      if (!escalatedChatsIsFetching && escalatedChatsHasMore) {
        return requestMoreEscalated(false, searchInput || null);
      }
    }

    if (activeTab === TabTypes.archived) {
      if (!archivedChatsIsFetching && archivedChatsHasMore) {
        return requestMoreArchived(false, searchInput || null);
      }
    }

    if (isAgent && !activeChatsIsFetching && activeChatsHasMore) {
      return requestMore();
    }
  };

  handleClose = (chatId) => {
    const {
      removeChatSession,
      hasPostChatAction,
      activeChatId,
      history,
      match,
      flags,
      tagsRequired,
      commentRequired,
      classifyChat
    } = this.props;
    const { pypeId } = match.params;

    const { newClassifyChatForm } = flags;
    const takePostChatAction = newClassifyChatForm ? (tagsRequired || commentRequired) : hasPostChatAction;

    this.setState({
      endedChatId: chatId
    });

    if (takePostChatAction) {
      this.setState({
        postChatModalIsOpen: true
      });
    } else {
      classifyChat({
        chat_id: chatId,
        user_cancel: true
      }).then(
        () => {
          removeChatSession(chatId);

          if (activeChatId === this.state.endedChatId) {
            history.push(`/${pypeId}/chats`);
          }
        },
        () => {
          // error
        }
      );
    }
  };
  closePostChatModal() {
    this.setState({
      postChatModalIsOpen: false
    });
    const { endedChatId } = this.state;
    const { match, history, removeChatSession, activeChatId } = this.props;
    const { pypeId } = match.params;

    removeChatSession(endedChatId);

    if (activeChatId === endedChatId) {
      history.push(`/${pypeId}/chats`);
    }
  }

  stopSearching = () => {
    this.props.updateArchivedChatsSearching(true);
  };

  renderList() {
    const { props, handleClose, state } = this;
    const {
      activeChats,
      activeChatsIsFetching,
      activeChatsHasMore,

      archivedChats,
      archivedChatsIsFetching,
      archivedChatsHasMore,
      archivedSearchSize,

      escalatedChats,
      escalatedChatsIsFetching,
      escalatedChatsHasMore,

      isManager,
      skills
    } = props;
    const { activeTab, showStillSearchingText, searchInput } = state;
    const archive = activeTab === TabTypes.archived;
    const escalated = activeTab === TabTypes.escalated;
    const active = activeTab === TabTypes.active;

    const chatsList = escalated ? escalatedChats : archive ? archivedChats : activeChats;
    const isFetching = escalated
      ? escalatedChatsIsFetching
      : archive
        ? archivedChatsIsFetching
        : activeChatsIsFetching;
    const loadingText = showStillSearchingText
      ? `Searching for matching conversations, ${archivedChats.length
      } matches found out of ${archivedSearchSize} conversations`
      : searchInput !== ''
        ? 'We are searching for your results…'
        : 'Loading conversations';
    const hasMore = escalated
      ? escalatedChatsHasMore
      : archive
        ? archivedChatsHasMore
        : activeChatsHasMore;
    let sortedChats = []
    if (this.props.flags.pe17893) {
      let chatsListOrderedBy = (chat) => chat.status !== 'ended';
      sortedChats = archive
          ? _.orderBy(chatsList, [chatsListOrderedBy, (chat) => chat.recent_msg.timestamp], ['desc','desc'])
          : _.orderBy(chatsList, [ chatsListOrderedBy, (chat) => chat.start_ts], ['desc', 'desc' ]);
    } else {
      sortedChats = archive
          ? _.orderBy(chatsList, (chat) => chat.recent_msg.timestamp, 'desc')
          : _.orderBy(chatsList, (chat) => chat.start_ts, 'desc');
    }
    const chats = sortedChats.map((chat) => {
      const isUnread =
        props.flags.newMessageTimer && props.flags.newMessageIndicator ? 
          (!escalated && !archive && _.findIndex(props.unreadChats, { id: chat.id }) > -1 && chat.recent_msg.from_side !== "agent") :
          (!escalated && !archive && _.findIndex(props.unreadChats, { id: chat.id }) > -1);

      return (
        (
          (
            props.flags.hideChatWhileFetching
            && ((active && !activeChatsIsFetching) || (escalated && !escalatedChatsIsFetching) || (archive))
          ) || (!props.flags.hideChatWhileFetching)
        ) && (
          <ConversationComponent
            onClose={handleClose.bind(this)}
            conversation={chat}
            isUnread={isUnread}
            key={chat.id}
            isNotActiveChat={archive || escalated}
            isManager={isManager}
            skills={skills}
            activeTab={activeTab}
          />
        )
      );
    });

    return (
      <React.Fragment>
        {!isManager && !isFetching && chatsList.length === 0 && this.renderNoMessages()}
        <PagedList
          className={cx({
            'has-zero': chatsList.length === 0,
            'conversations-list__archived': archive || escalated,
            'conversations-list__non_archived': !(archive || escalated)
          })}
          isFetching={isFetching}
          hasMore={hasMore}
          requestMore={this.loadMoreChats}
          loadingText={loadingText}
          showCancelButton={showStillSearchingText}
          cancelButtonHandler={this.stopSearching}
        >
          {chats}
        </PagedList>
      </React.Fragment>
    );
  }

  renderNoMessages() {
    const { activeTab } = this.state;
    let message = '';
    if (activeTab === 'active') {
      message = 'Your Queue';
    } else if (activeTab === 'archived') {
      message = 'Your History';
    } else if (activeTab === 'escalated') {
      message = 'Agent Queue';
    }

    return (
      <div className='empty-list-content'>
        <img src={ChatBubbleImage} alt='' />
        <p id='title'>{message} is Empty</p>
        <p id='description'>{this.props.flags.newEmptyStateMessages ? constants.EMPTY_STATES_MESSAGE[activeTab] : "When a conversation is assigned to you it will appear here."}</p>
      </div>
    );
  }

  renderNoSearchedMessages = () => {
    const Wrapper = styled.div`
      padding: 46px 24px;
      text-align: center;
      h3 {
        margin: 0;
        font-weight: 400;
        padding-bottom: 15px;
      }
      button {
        background: none;
        border: none;
        color: #2d7ee1;
        :focus {
          outline: none;
        }
      }
    `;

    return (
      <Wrapper>
        <h3>{`No results for "${this.state.searchInput}"`}</h3>
        <button onClick={this.handleResetInput}>Clear your search</button>
      </Wrapper>
    );
  };

  handleRefresh = () => {
    if (this.props.searchingArchivedChatsCancelled) this.props.updateArchivedChatsSearching(false);
    const { activeTab, searchInput } = this.state;
    if (activeTab === TabTypes.escalated) {
      this.props.requestMoreEscalated(true, searchInput || null);
    } else if (activeTab === TabTypes.archived) {
      this.props.requestMoreArchived(true, searchInput || null);
    }
  };

  handleSelect = (e, activeTab) => {
    if (activeTab === TabTypes.escalated)
      activityTracker.logEvent(activityTracker.eventTypeNames.VIEW_AGENT_QUEUE);
    if (activeTab === TabTypes.archived)
      activityTracker.logEvent(activityTracker.eventTypeNames.VIEW_HISTORY);
    this.setState({ activeTab: activeTab, searchInput: '', showStillSearchingText: false }, () => {
      this.props.onTabChange(activeTab);
    });
    if (this.props.searchingArchivedChatsCancelled) this.props.updateArchivedChatsSearching(false);
  };

  handleMouseMove = (e) => {
    if (this.props.isClickDisabled && this.props.flags.isClickDisabledAfterChatEnded) {
      this.setState({ position: {x: e.pageX, y: e.pageY}});
    }
  };

  handleOnChange = (e) => {
    clearTimeout(this.timer);
    this.setState({ searchInput: e.target.value, showStillSearchingText: false });
    this.timer = setTimeout(this.requestOnSearch, 500);
    if (this.props.searchingArchivedChatsCancelled) this.props.updateArchivedChatsSearching(false);
  };

  handleResetInput = () => {
    clearTimeout(this.timer);
    this.setState({ searchInput: '', showStillSearchingText: false });
    this.timer = setTimeout(this.requestOnSearch, 800);
    if (this.props.searchingArchivedChatsCancelled) this.props.updateArchivedChatsSearching(false);
  };

  handleFormSubmit = (e) => {
    e.preventDefault();
  };

  searchInputLogger = (noOfResults) => {
    activityTracker.logEvent(activityTracker.eventTypeNames.SEARCH_CONVERSATION_HISTORY, {
      SearchQuery: this.state.searchInput,
      NumberOfResults: noOfResults
    });
  };

  applyFiltersLogger = (selectedFilters, noOfResults) => {
    let eventLog = [];
    Object.keys(selectedFilters).forEach((key) => {
      switch (key) {
        case 'selectedAgents':
          eventLog.push('Agent Names');
          break;
        case 'selectedSkills':
          eventLog.push('Skills');
          break;
        case 'duration':
          eventLog.push(selectedFilters.duration);
          break;
        default:
      }
    });
    activityTracker.logEvent(activityTracker.eventTypeNames.APPLY_HISTORY_FILTERS, {
      FilterName: eventLog,
      NumberOfResults: noOfResults
    });
  };

  handleOnBlur = () => {
    const {
      archivedChats,
      archivedChatsIsFetching,
      escalatedChats,
      escalatedChatsIsFetching
    } = this.props;

    const { activeTab } = this.state;

    if (this.state.searchInput !== '') {
      if (
        (activeTab === TabTypes.archived && archivedChatsIsFetching) ||
        (activeTab === TabTypes.escalated && escalatedChatsIsFetching)
      ) {
        this.setState({ waitingForChats: true });
      } else if (activeTab === TabTypes.archived && !archivedChatsIsFetching) {
        this.searchInputLogger(archivedChats.length);
      } else if (activeTab === TabTypes.escalated && !escalatedChatsIsFetching) {
        this.searchInputLogger(escalatedChats.length);
      }
    } else if (this.state.waitingForChats) {
      this.setState({ waitingForChats: false });
    }
  };

  requestOnSearch = () => {
    const searchPattern = this.state.searchInput || null;
    const { activeTab } = this.state;
    if (activeTab === TabTypes.escalated) {
      this.props.requestMoreEscalated(true, searchPattern);
    } else if (activeTab === TabTypes.archived) {
      this.props.requestMoreArchived(true, searchPattern);
    }
  };

  handleFiltersClick = () => {
    const { match, history, location } = this.props;
    const { pypeId } = match.params;
    this.props.changeHistoryFiltersView(true);
    const prevChatPath = location.pathname;
    history.push(`/${pypeId}/chats`);
    this.setState({
      showFilters: true,
      prevChatPath
    });
  };

  renderChatTabs() {
    const { activeTab, searchInput } = this.state;
    const {
      activeChats,
      activeChatsIsFetching,

      archivedChats,
      archivedChatsIsFetching,

      escalatedChats,
      escalatedChatsIsFetching,

      unreadChats,
      isAgent,
      isManager,

      selectedHistoryFiltersCount,

      isClickDisabled,
      flags
    } = this.props;

    const { isClickDisabledAfterChatEnded, newAgentStatusBanner } = flags;
    const activeHasUnreadChats = unreadChats.length !== 0;
    const showStatusBanner = newAgentStatusBanner && isAgent && activeTab === TabTypes.active;

    const roleTabs = (
      <Tabs
        value={activeTab}
        aria-label='tabs'
        onChange={this.handleSelect}
        onMouseMove={this.handleMouseMove}
        variant='fullWidth'
        textColor='primary'
        className={cx('queue-tab-manager', { 'isClickDisabled': isClickDisabled && isClickDisabledAfterChatEnded && activeTab === "active" })}
      >
      {isAgent && (
        <Tab
          value={TabTypes.active}
          label='Queue'
          id={TabTypes.active}
          className={activeHasUnreadChats && 'active-tab-manager-notifications'}
          disabled={isClickDisabled && isClickDisabledAfterChatEnded && activeTab === "active"}
        />
      )}
      {isManager && <Tab value={TabTypes.escalated} label='Agents' id={TabTypes.escalated} disabled={isClickDisabled && isClickDisabledAfterChatEnded && activeTab === "active"} />}
      {isManager && <Tab value={TabTypes.archived} label='History' id={TabTypes.archived} disabled={isClickDisabled && isClickDisabledAfterChatEnded && activeTab === "active"} />}
    </Tabs>
    );

    return (
      <ReactTabs>
        {isClickDisabled && isClickDisabledAfterChatEnded && activeTab === "active"
        ? 
        <WarningTooltip
        title={disabledTitle}
        aria-label={Constant.TOOLTIPS.convo_card_disabled_warning}
        placement="top-start"
        PopperProps={{
          anchorEl: {
            clientHeight: 0,
            clientWidth: 0,
            getBoundingClientRect: () => ({
              top: this.state.position.y,
              left: this.state.position.x,
              right: this.state.position.x,
              bottom: this.state.position.y,
              width: 0,
              height: 0,
           })
          }
        }}
      >
        {roleTabs}
      </WarningTooltip> 
         :roleTabs}
        {showStatusBanner && this.props.agentStatus !== 'online' && <AgentStatus status={this.props.agentStatus}/>}
        {activeTab === TabTypes.active &&
          (isAgent && !activeChatsIsFetching && activeChats.length === 0
            ? this.renderNoMessages()
            : this.renderList())}
        {activeTab === TabTypes.escalated && (
          <React.Fragment>
            <SearchBox onSubmit={this.handleFormSubmit}>
              <SearchInput
                handleInput={this.handleOnChange}
                inputValue={searchInput}
                resetInput={this.handleResetInput}
                onBlur={this.handleOnBlur}
                autoFocus={true}
              />
              <SearchBottomLabel
                className={cx('search-bottom-container', {
                  'hide-search-bottom-container': escalatedChatsIsFetching
                })}
              >
                <span className={cx('search-bottom-container')}>
                  <RefreshSpinner
                    rotating={escalatedChatsIsFetching}
                    onClick={this.handleRefresh}
                  />
                  <Typography className='filter-text' variant='h6'>
                    {`Showing ${escalatedChats.length} conversations`}
                  </Typography>
                </span>
              </SearchBottomLabel>
            </SearchBox>
            {!escalatedChatsIsFetching && escalatedChats.length === 0
              ? searchInput
                ? this.renderNoSearchedMessages()
                : this.renderNoMessages()
              : this.renderList()}
          </React.Fragment>
        )}
        {activeTab === TabTypes.archived && (
          <React.Fragment>
            <SearchBox onSubmit={this.handleFormSubmit}>
              <SearchInput
                handleInput={this.handleOnChange}
                inputValue={searchInput}
                resetInput={this.handleResetInput}
                onBlur={this.handleOnBlur}
                autoFocus={true}
              />
              <SearchBottomLabel
                className={cx('search-bottom-container', {
                  'hide-search-bottom-container': archivedChatsIsFetching
                })}
              >
                <span className={cx('filter-button-container', 'search-bottom-container')}>
                  <div className='refresh-icon'>
                    <RefreshSpinner
                      className='refresh-svg'
                      rotating={archivedChatsIsFetching}
                      onClick={this.handleRefresh}
                    />
                    <Typography className='filter-text' variant='h6'>
                      {`Showing ${archivedChats.length} conversations`}
                    </Typography>
                  </div>
                  {isManager && (
                    <Button
                      small
                      className='filter-button'
                      hollow={selectedHistoryFiltersCount === 0}
                      onClick={this.handleFiltersClick}
                    >
                      {selectedHistoryFiltersCount > 0
                        ? `${selectedHistoryFiltersCount} ${selectedHistoryFiltersCount > 1 ? 'Filters' : 'Filter'
                        }`
                        : 'Filters'}
                    </Button>
                  )}
                </span>
              </SearchBottomLabel>
            </SearchBox>
            {this.renderListPanelContent()}
          </React.Fragment>
        )}
      </ReactTabs>
    );
  }

  renderListPanelContent = () => {
    const { archivedChatsIsFetching, archivedChats } = this.props;
    const { searchInput, showFilters } = this.state;
    if (!archivedChatsIsFetching && archivedChats.length === 0 && !showFilters) {
      return searchInput ? this.renderNoSearchedMessages() : this.renderNoMessages();
    }

    return this.renderList();
  };

  hideFilters = (fetchConversations) => {
    if (!fetchConversations && this.state.prevChatPath)
      this.props.history.push(this.state.prevChatPath);

    this.setState({ showFilters: false, newFiltersApplied: fetchConversations }, () => {
      if (fetchConversations) this.handleRefresh();
    });
  };

  render() {
    const { props, state, closePostChatModal } = this;
    const { postChatModalIsOpen, endedChatId, activeTab } = state;
    const { isAgent, flags } = props;

    const showStatusBanner = !flags.newAgentStatusBanner && isAgent && activeTab === TabTypes.active; //Feature Flag Off New Agent Status Banner

    return (
      <div className='conversations-list full-height' ref='root'>
        {showStatusBanner && props.agentStatus === 'away' && <AgentAway />}
        {showStatusBanner && props.agentStatus === 'offline' && <AgentOffline />}
        {showStatusBanner && props.agentStatus === 'busy' && <AgentBusy />}
        {this.state.showFilters ? (
          <div className='list'>
            <HistoryFilters hideFilterHandle={this.hideFilters} />
          </div>
        ) : (
          <div className='list recent-messages-list list-unstyled'>{this.renderChatTabs()}</div>
        )}
        {postChatModalIsOpen && !props.flags.newClassifyChatForm && (
          <PostChatModal chat_id={endedChatId} closeModal={closePostChatModal.bind(this)} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { details } = state.pype;
  const found = state.agentChatSessions.chatSessions.some(el => el.id === state.conversationHistory.id);
  const isClickDisabled =
    ( 
      (details.end_comment_required || details.end_tags_required) &&
      state.allConversations.endedNotClassifiedChatSessions.includes(
        state.conversationHistory.id
      ) && found);
  const addComment = state.pype.details.end_comment_display;
  const addTags = state.pype.details.end_tags_display;
  const hasTags = !_.isEmpty(state.tags.store);
  let selectedFiltersCount = 0;
  const selectedFilters = { ...state.conversationHistory.selectedHistoryFilters };
  Object.keys(state.conversationHistory.selectedHistoryFilters).forEach((key) => {
    if (Array.isArray(selectedFilters[key])) selectedFiltersCount += selectedFilters[key].length;
    else if (key !== 'from' && key !== 'to') selectedFiltersCount++;
  });

  return {
    agentStatus: utils.agent.getStatus(state.agent),

    activeChats: state.agentChatSessions.chatSessions,
    activeChatsIsFetching: state.agentChatSessions.isFetching,
    activeChatsHasMore: state.agentChatSessions.hasMore,

    archivedChats: state.agentChatSessions.archived_chatSessions,
    archivedChatsIsFetching: state.agentChatSessions.archived_isFetching,
    archivedChatsHasMore: state.agentChatSessions.archived_hasMore,
    archivedSearchSize: state.agentChatSessions.archived_search_size,
    archivedItemsPerPage: state.agentChatSessions.archived_itemsPerPage,
    searchingArchivedChatsCancelled: state.agentChatSessions.searchingArchivedChatsCancelled,

    escalatedChats: state.agentChatSessions.escalated_chatSessions,
    escalatedChatsIsFetching: state.agentChatSessions.escalated_isFetching,
    escalatedChatsHasMore: state.agentChatSessions.escalated_hasMore,

    unreadChats: getUnreadChatSessions(state),
    hasPostChatAction: addComment || (addTags && hasTags),
    activeChatId: state.conversationHistory.id,
    isManager: doesUserHasRole(PYPE_MANAGER_ROLES, state.pype.details.id),
    isAgent: doesUserHasRole(PYPE_AGENT_ROLES, state.pype.details.id),
    skills: state.skills.skillsStore,
    selectedHistoryFilters: state.conversationHistory.selectedHistoryFilters,
    selectedHistoryFiltersCount: selectedFiltersCount,
    isClickDisabled,
    commentRequired: details.end_comment_required,
    tagsRequired: details.end_tags_required,
  };
};

const mapDispatchToProps = (dispatch) => ({
  requestMore(reset = false) {
    const action = agentChatSessionsActions.fetchAgentChatSessions(reset);
    return dispatch(action);
  },
  requestMoreArchived(reset = false, searchPattern = null) {
    const action = agentChatSessionsActions.fetchArchivedChatSessions(reset, searchPattern);
    return dispatch(action);
  },
  requestMoreEscalated(reset = false, searchPattern = null) {
    const action = agentChatSessionsActions.fetchEscalatedChatSessions(reset, searchPattern);
    return dispatch(action);
  },
  removeChatSession(id) {
    const action = agentChatSessionsActions.removeChatSession(id);
    return dispatch(action);
  },
  classifyChat(data) {
    return dispatch(conversationsActions.classifyChat(data));
  },
  updateArchivedChatsSearching(flag = false) {
    return dispatch(agentChatSessionsActions.updateArchivedChatsSearching(flag));
  },
  changeHistoryFiltersView(reset = true) {
    return dispatch(conversationHistoryActions.changeHistoryFiltersView(reset));
  }
});

const ConversationsListWithFeatureFlags = withLDConsumer()(ConversationsList);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ConversationsListWithFeatureFlags)
);
