import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #f5f5f5;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
`;
export const Content = styled.div`
  text-align: center;
`;

export const Logo = styled.div`
 
`;

export const SelectPype = styled.div`
  width: 412px;
  border-radius: 5px;
  background-color: #ffffff;
  margin-top: 24px;
  padding: 32px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  h2 {
    font-size: 28px;
    font-weight: normal;
    margin: 0;
  }
`;

export const PypeList = styled.div`
  margin-top: 26px;
  border-radius: 5px;
  border: 1px solid rgba(177, 177, 177, 0.5);
  max-height: 280px;
  overflow-y: auto;
`;
