import React from 'react'
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

import {
  doesUserHasRole,
  PYPE_AGENT_ROLES, PYPE_MANAGER_ROLES,
  isPermitted, PermissionAction, PermissionResource
} from "bot-user-session";
import _ from 'lodash';
import Sidebar from 'components/ui/Sidebar';
import Campaigns from './campaigns/Campaigns';
import StatusListener from './agent-status';
import featureFlags from 'utils/featureFlags'
import { Button } from 'ui-elements';

import { OptimizelyFeature } from '@optimizely/react-sdk';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

class Content extends React.Component{

  menuItems = {

    overview: {
      id: 'Overview',
      title: 'Overview',
      path: '/overview',
      iconClass: 'icon-overview',
      collapsible: false,
      children: [],
    },

    // broadcasts: {
    //   id: 'Broadcasts',
    //   title: 'Broadcasts',
    //   path: '/broadcasts',
    //   iconClass: 'icon-broadcasts',
    //   tooltipKey: 'overview_tab.broadcast_tab',
    //   collapsible: false,
    //   children: [],
    // },

    // 'chats' and 'messages' are being used interchangeably here but the forward term is 'chats'
    // chats: {
    //   id: 'Messages',
    //   title: 'Conversations',
    //   path: '/chats',
    //   iconClass: 'icon-messages',
    //   collapsible: false,
    //   children: [],
    // },

    admin: {
      id: 'Admin',
      title: 'Settings',
      path: '/admin',
      iconClass: 'icon-admin',
      collapsible: true,
      children: [
        {
          id: 'ChatRouting',
          title: 'Chat routing',
          path: '/admin/chat-routing',
          icon: `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-chat-routing.svg`,
          iconActive: `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-chat-routing-active.svg`,
          salesforceOrder: 1
        },
          
        {
          id: 'Agents',
          title: 'Agents',
          path: '/admin/agents',
          icon: `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-headset.svg`,
          iconActive: `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-headset-active.svg`,
          salesforceOrder: 2
        },
        {
          id: 'AutoResponders',
          title: 'Auto Responses',
          path: '/admin/autoresponders',
          icon: `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-respond.svg`,
          iconActive: `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-respond-active.svg`,
          salesforceOrder: 3
        },
        // {
        //   id: 'Broadcasts',
        //   title: 'Broadcasts',
        //   path: '/admin/broadcasts',
        //   icon: `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-broadcasts.svg`,
        //   iconActive: `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-broadcasts-active.svg`,
        //   salesforceOrder: 4
        // },
        {
          id: 'CrmData',
          title: 'CRM Data Integration',
          path: '/admin/crm-data',
          icon: `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-puzzle.svg`,
          iconActive: `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-puzzle-active.svg`,
          isPypeManagerFeature: true,
          salesforceOrder: 11
        },
        {
          id: 'ChatLogs',
          title: 'End Chat Options',
          path: '/admin/chat-logs',
          icon: `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-bubble.svg`,
          iconActive: `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-bubble-active.svg`,
          salesforceOrder: 5
        },
        {
          id: 'CannedResponses',
          title: 'Quick Replies',
          path: '/admin/canned-responses/manage',
          icon: `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-canned-response.svg`,
          iconActive: `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-canned-response-active.svg`,
          salesforceOrder: 6
        },
        {
          id: 'PypestreamApi',
          title: "Pypestream API",
          path: '/admin/pypestream-api',
          icon: `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/ico-pypestreamapi.svg`,
          iconActive: `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/ico-pypestreamapi-active.svg`,
          salesforceOrder: 7
        },
        {
          id: 'Skills',
          title: 'Skills',
          path: '/admin/skills',
          icon: `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-skills.svg`,
          iconActive: `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-skills-active.svg`,
          isPypeManagerFeature: true,
          salesforceOrder: 10
        },
        {
          id: 'ManageTags',
          title: 'Tag Management',
          path: '/admin/manage-tags',
          icon: `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-tag.svg`,
          iconActive: `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-tag-active.svg`,
          isPypeManagerFeature: true,
          salesforceOrder: 12
        },
        {
          id: 'UserPrivacy',
          title: 'User Privacy',
          path: '/admin/user-privacy',
          icon: `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/privacy-icon-inactive.svg`,
          iconActive: `${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/privacy-icon.svg`,
          salesforceOrder: 8
        },
      ]
    },
  }

  componentWillMount() {
    const { pypeId } = this.props
    const isAgent = doesUserHasRole(PYPE_AGENT_ROLES, pypeId);
    const isAdmin = doesUserHasRole(PYPE_MANAGER_ROLES, pypeId);

    if (!isAgent) {
      if(featureFlags.isBeta()){
        if (isAdmin) {
          return;
        }
        else {
          this.menuItems = _.omit(this.menuItems, ['chats']);
        }
      } else {
        this.menuItems = _.omit(this.menuItems, ['chats']);
      }
    } 
    if (!isAdmin) {
      this.menuItems = _.omit(this.menuItems, ['admin']);
    } else {
        if(this.props.flags && this.props.flags.brandInfo && !this.props.flags.brandInfo.showAPI) {
          let adminOptions = this.menuItems.admin.children;
          adminOptions = adminOptions.filter(item => item.id !== 'PypestreamApi');
          this.menuItems.admin.children = adminOptions;
        }
    }
  }

  handlePypeClick = (e) => {
    e.preventDefault();
    this.props.history.push('/start');
  }

  handleConversationClick = (e) => {
    e.preventDefault();
    this.props.history.replace(`/${this.props.pypeId}/chats`);
  }

  render() {
    const { pypeId, location, pypes } = this.props
    const isAgent = doesUserHasRole(PYPE_AGENT_ROLES, pypeId);
    const isAdmin = doesUserHasRole(PYPE_MANAGER_ROLES, pypeId);
    const isOnlyAgent = isAgent && !isAdmin;

    var pype = this.props.pype;
    var name = pype && pype.name;

    return (
      <div className="full-height">
        { isAgent ? <StatusListener/> : null }
        {location.pathname.includes('/chats') ? (
            <div className="content scrollable" style={{paddingLeft: '0'}}>
              { this.props.children ? this.props.children : <Campaigns /> }
            </div>
          ) : (
          <div className="container-fluid">
            {pypes.length > 1 ? (
              <Button hollow className="all-pypes-btn" onClick={this.handlePypeClick}>All Pypes</Button>
            ) : null}
            <div className="row top-nav-wrapper">
              <div className="top-nav-text-wrapper">
                <div className="top-nav-overview">
                  <h4>{name}</h4>
                  <small>{pype.env}</small>
                </div>
                <div>
                  <Button onClick={this.handleConversationClick}>Agent Interface</Button>
                </div>
              </div>
            </div>
            <div className="content-wrapper row">
              <div className="sidebar col-lg-2 col-sm-2">
                <Sidebar items={_.values(this.menuItems)} />
              </div>
              <div className="content scrollable col-lg-auto col-sm-auto">
                { this.props.children ? this.props.children : <Campaigns /> }
              </div>
            </div>
          </div>
        )
      }
      </div>
    );
  }
};

const mapStateToProps = state => ({
  pypeId: state.pype.details.id,
  pype: state.pype.details,
  pypes: state.pypes.pypes,
});

const ContentWithFeatureFlag = withLDConsumer()(Content);

export default withRouter(connect(
mapStateToProps
)(ContentWithFeatureFlag));
