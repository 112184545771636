import _ from 'lodash'
import {connect} from 'react-redux';
import Component from './Component';

const DEFAULT_WIDGET_STYLE = {
  // "name": "Chat widget",
  "styleSelection": 1,
  "widgetPosition": "bottom-right",
  "shouldLockRatio": true,
  "widgetHeight": 600,
  "widgetWidth": 500,
  "widgetStyleColor": "#797979",
  "widgetStyleTextColor": "#ffffff",
  "agentBubbleColor": "#e5e5e5",
  "agentBubbleTextColor": "#000000",
  "userBubbleColor": "#2E7DE1",
  "userBubbleTextColor": "#ffffff",
  "bannerUrl": ""
}

const mapStateToProps = (state, {widget}) => {
  const stream = _.find(state.streamsForPype.streams, (s) => (
    s.id === widget.stream_id
  ))
  const streamName = stream ? stream.stream_name: ''
  const hasCustomDesign = !_.isEqual(
    _.omit(JSON.parse(widget.style), 'name'),
    DEFAULT_WIDGET_STYLE
  )
  return {
    streamName,
    hasCustomDesign,
  }
}

export default  connect(mapStateToProps)(Component)
