import React from 'react'
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import moment from 'moment';
import css from './Stats.css';
import { doesUserHasRole, PYPE_MANAGER_ROLES } from 'bot-user-session';

import QuickStats from './stats/QuickStats';
import StatsTabs from './stats/StatsTabs';
import BotsTable from './stats/BotsTable';
import ErrorBoundary from 'components/ui/ErrorBoundary';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

const Footer = (props) => {
	const renderCopyright = () => {
		if (props.flags && props.flags.brandInfo && props.flags.brandInfo.showCopyright) {
			return <span>&copy; {moment().format('YYYY')} Pypestream</span>
		} else {
			return;
		}
	}

	return (
		<div>
			<div className="copyright m-t-10 p-20">
		    	{renderCopyright()}
			</div>
		</div>
	)
}
class Stats extends React.Component{
	render() {
	const {metrics,onClickShowStreams,pypeId,selectedSkill} = this.props;
	const isAdmin = doesUserHasRole(PYPE_MANAGER_ROLES, pypeId);
	const role = isAdmin ? 'pype_admin' : 'agent'
	
	return (
		<div className={css.wrapper}>
			<div className={css.wrapperInner}>
				{/* TODO v3 use pype:list_metrics:$pype_id */}
				<ErrorBoundary>
					<QuickStats role={role} metrics={metrics} />
				</ErrorBoundary>
				{/* TODO v3 use pype:list_metrics:$pype_id */}
				{isAdmin && selectedSkill.length ===0 ? <ErrorBoundary><BotsTable /></ErrorBoundary> : null}
				{isAdmin ? <ErrorBoundary><StatsTabs role={role} onClickShowStreams={onClickShowStreams} /></ErrorBoundary> : null}
				<Footer flags={this.props.flags} role={role} metrics={metrics}/>
			</div>
		</div>
		);
	}
}
Stats.propTypes = {
	metrics: PropTypes.object.isRequired,
	onClickShowStreams: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  pypeId: state.pype.details.id,
  selectedSkill: state.skills.selectedSkill
});

const StatsWithFeatureFlag = withLDConsumer()(Stats)

export default connect(
mapStateToProps
)(StatsWithFeatureFlag);

