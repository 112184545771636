import SortableTable from './SortableTable';
import Head from './Head';
import Column from './Column';
import Body from './Body';
import Row from './Row';

export default  {
  SortableTable,
  Head,
  Column,
  Body,
  Row
};
