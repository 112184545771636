/*
  A list that supports paging.
*/

import React from 'react'
import {debounce} from 'lodash';
import PropTypes from 'prop-types'
import cx from 'classnames';
import Spinner from '../../ui/pype-spinner/PypeSpinner';

class PagedList extends React.Component {
  static defaultProps = {
		loadingText: 'Loading',
  }

  constructor(props) {
    super(props);
    this.pagedList = React.createRef();

    this.state = {
      isPaging: false,
      // Technical paging params
    }
  }

  componentDidMount() {
    this.pagedList.current.addEventListener('scroll', debounce(this.onScroll), 400)
  }

  componentWillUnmount() {
    this.pagedList.current.removeEventListener('scroll', this.onScroll)
  }

  onScroll = (event) => {
    const {bufferPx, onScroll} = this.props;
    const domList = this.pagedList.current;

    if (domList) {
      // This value will be equal domList.scrollHeight when hitting the bottom
      const scrollY = domList.scrollTop + domList.clientHeight

      // Trigger a fetch when hitting the buffer scroll area
      if (domList.scrollHeight - scrollY < bufferPx) {
        this.requestMore();
      }
      onScroll(event);
    }
  }

  requestMore = () => {
    if (!this.props.hasMore)
      return;
    this.setState({ isPaging: true });
    const result = this.props.requestMore()
    if (result === undefined)  // Not a promise
      return
    return result.then(() => {
      // Do nothing here
    }, (error) => {
      console.error(error)
    })
    .then(() => {
      this.setState({ isPaging: false });
    })
  }

  render() {
    const {props, state} = this;

    return (
      <div className={cx('paged-list', props.className)} ref={this.pagedList}>
        <div className="list-content">
          {props.children}
        </div>
        {(state.isPaging || props.isFetching) ? (
          <div className={cx('loader-container')}>
            <div className={cx('loader-contents')}>
              <Spinner loadingText={this.props.loadingText} offsetTop={4} />
              {this.props.showCancelButton && (
                <div className={cx('cancel-search-button')}>
                  <button onClick={this.props.cancelButtonHandler}>Cancel Search</button>
                </div>
              )}
            </div>
          </div>
        ) : null}
      </div>
    )
  }

}

PagedList.propTypes = {
  isFetching: PropTypes.bool,
  hasMore: PropTypes.bool.isRequired,
  requestMore: PropTypes.func.isRequired,  // This needs to return a promise
  bufferPx: PropTypes.number,
  className: PropTypes.string,
  onScroll: PropTypes.func,
  showCancelButton: PropTypes.bool,
  cancelButtonHandler: PropTypes.func,
}

PagedList.defaultProps = {
  isFetching: false,
  bufferPx: 10,
  className: '',
  onScroll: () => {},
  showCancelButton: false,
  cancelButtonHandler: () => {},
}

export default  PagedList
