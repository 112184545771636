import style from './style.css';
import _ from 'lodash';
import React from 'react'
import PropTypes from 'prop-types'

import classNames from 'classnames';
import Modal from 'react-modal';
// import Select from 'react-select';
import SaveButton from 'components/ui/forms/SaveButton';
import DesignConfig from '../DesignConfig';
import ChatCircle from '../ChatCircle';
import ChatAnchor from '../ChatAnchor';
import ChatWindow from '../ChatWindow';

class DesignModal extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      isSubmitting: false,
      isChatVisible: true,
      widthToHeightRatio: props.design.widgetWidth / props.design.widgetHeight,
      design: props.design,
      bannerFileObject: null,
      errors: {
        widgetWidth: undefined,
        widgetHeight: undefined,
        bannerUrl: undefined,
      },
    }
  }

  handleClose = () => {
    if (!this.state.isSubmitting) {
      this.props.closeModal()
    }
  }

  handleSubmit = () => {
    window.globalUi.webchat.design = this.state.design
    window.globalUi.webchat.bannerFileObject = this.state.bannerFileObject
    this.setState({isSubmitting: true})
    this.props.onSubmit(this.state.design, this.state.bannerFileObject).then(() => {
      if (!this.props.isNew) {
        this.setState({isSubmitting: false})
      }
      if (!this.props.isNew) {
        this.handleSuccess()
      }
    }).catch((error) => {
      console.warn(error)
      this.setState({isSubmitting: false})
      this.handleError()
    })
  }

  handleSuccess = () => {
    window.appCreateToast({
      message: 'Changes successfully saved.',
      level: 'success',
    })
    this.props.closeModal()
  }

  handleError = () => {
    window.appCreateToast({
      message: 'Error saving Web Conversational Interface style.',
      level: 'error',
    })
    this.props.closeModal()
  }

  handleDesignChange = (prop, value) => {
    if (this.state.isSubmitting) {
      return
    }
    const {state} = this
    const {design} = state
    this.setState({
      errors: {
        ...state.errors,
        [prop]: undefined,
      },
    })
    if (prop === 'shouldLockRatio') {
      let widthToHeightRatio = (design.widgetWidth || 0) / (design.widgetHeight || 0)
      if (isNaN(widthToHeightRatio) || widthToHeightRatio === Infinity) {
        widthToHeightRatio = 1
      }
      this.setState({widthToHeightRatio})
    }
    if (prop === 'widgetWidth' && design.shouldLockRatio) {
      this.setState({
        design: {
          ...design,
          widgetWidth: parseInt(value),
          widgetHeight: Math.round(value / state.widthToHeightRatio),
        },
      })
    } else if (prop === 'widgetHeight' && design.shouldLockRatio) {
      this.setState({
        design: {
          ...design,
          widgetWidth: Math.round(value * state.widthToHeightRatio),
          widgetHeight: parseInt(value)
        },
      })
    } else {
      this.setState({
        design: {
          ...design,
          [prop]: value,
        },
      })
    }
  }

  toggleChat = () => {
    this.setState({isChatVisible: !this.state.isChatVisible})
  }

  resetColors = () => {
    if (this.state.isSubmitting) {
      return
    }
    this.setState({
      design: {
        ...this.state.design,
        widgetStyleColor: '#007BFF',
        widgetStyleTextColor: '#ffffff',
        agentBubbleColor: '#DEE8F5',
        agentBubbleTextColor: '#134373',
        userBubbleColor: '#113260',
        userBubbleTextColor: '#ffffff',
      },
      errors: {
        widgetStyleColor: undefined,
        agentBubbleColor: undefined,
        userBubbleColor: undefined
      }
    })
  }

  resetPosition = () => {
    if (this.state.isSubmitting) {
      return
    }
    this.setState({
      design: {
        ...this.state.design,
        widgetPosition: 'bottom-right',
        shouldLockRatio: true,
        widgetHeight: 660,
        widgetWidth: 380,
      },
      errors: {
        widgetWidth: undefined,
        widgetHeight: undefined,
        bannerUrl: undefined,
      }
    })
  }

  getErrors = () => {
    const errors = {}
    const {design} = this.state
    if (!design.widgetHeight || design.widgetWidth < 300 || design.widgetWidth > 600) {
      errors.widgetWidth = 'Width should be between 300 and 600 pixels.'
    }
    if (!design.widgetHeight || design.widgetHeight < 400 || design.widgetHeight > 800) {
      errors.widgetHeight = 'Height should be between 400 and 800 pixels.'
    }
    if (!/^#[0-9A-F]{6}$/i.test(design.widgetStyleColor)) {
      errors.widgetStyleColor = 'Web Conversational Interface style color is invalid.'
    }
    if (!/^#[0-9A-F]{6}$/i.test(design.agentBubbleColor)) {
      errors.agentBubbleColor = 'Agent bubble color is invalid.'
    }
    if (!/^#[0-9A-F]{6}$/i.test(design.userBubbleColor)) {
      errors.userBubbleColor = 'User bubble color is invalid.'
    }
    return errors
  }

  handleBlurWidgetWidth = () => {
    const error = this.getErrors().widgetWidth
    this.setState({errors: {...this.state.errors, widgetWidth: error}})
  }

  handleBlurWidgetHeight = () => {
    const error = this.getErrors().widgetHeight
    this.setState({errors: {...this.state.errors, widgetHeight: error}})
  }

  handleBlurWidgetStyleColor = () => {
    const error = this.getErrors().widgetStyleColor
    this.setState({errors: {...this.state.errors, widgetStyleColor: error}})
  }

  handleBlurAgentBubbleColor = () => {
    const error = this.getErrors().agentBubbleColor
    this.setState({errors: {...this.state.errors, agentBubbleColor: error}})
  }

  handleBlurUserBubbleColor = () => {
    const error = this.getErrors().userBubbleColor
    this.setState({errors: {...this.state.errors, userBubbleColor: error}})
  }

  isFormValid = () => {
    return _.isEmpty(this.getErrors())
  }

  getImageDimensions = (file) => {
    const img = new Image()
    return new Promise(function (resolve) {
      img.onload = function () {
        resolve({
          width: this.width,
          height: this.height,
        })
      }
      img.src = window.URL.createObjectURL(file)
    })
  }

  handleUpload = (file) => {
    // <TODO> Error checking
    this.getImageDimensions(file).then((rect) => {
      // console.log(rect)
      // if (rect.width !== 500 || rect.height !== 167) {}
    })
    const reader = new FileReader()
    reader.onload = (event) => {
      this.setState({
        design: {
          ...this.state.design,
          bannerUrl: event.target.result,
        },
        bannerFileObject: file,
      })
    }
    reader.readAsDataURL(file)
  }

  render () {
    const {props, state} = this
    const launcherClassName = classNames(style.Launcher, style[state.design.widgetPosition])
    return (
      <Modal ariaHideApp={false} className={`design-modal ${style.DesignModal}`} isOpen={true} onRequestClose={this.handleClose}
        contentLabel='Modal' shouldCloseOnOverlayClick={false}>
        <div className={`modal-content ${style.ModalContent}`}>
          <div className={`modal-header ${style.ModalHeader}`}>
            2. Customize appearance{!props.isNew && ` (${props.widget.widget_name})`}
            <button type='button' className='close' onClick={this.handleClose}>
              <img src={`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-modal-close.svg`} width='15' height='15' />
            </button>
          </div>
          <div className={`modal-body ${style.ModalBody}`}>
            <div className={style.Config}>
              <DesignConfig
                design={state.design}
                errors={state.errors}
                onDesignChange={this.handleDesignChange}
                onResetColors={this.resetColors}
                onResetPosition={this.resetPosition}
                onBlurWidgetWidth={this.handleBlurWidgetWidth}
                onBlurWidgetHeight={this.handleBlurWidgetHeight}
                onBlurWidgetStyleColor={this.handleBlurWidgetStyleColor}
                onBlurAgentBubbleColor={this.handleBlurAgentBubbleColor}
                onBlurUserBubbleColor={this.handleBlurUserBubbleColor}
                onUpload={this.handleUpload} />
            </div>
            <div className={style.Preview}>
              <ChatWindow
                className={style.ChatWindow}
                design={state.design}
                isVisible={state.isChatVisible}
                title={props.pypeName}
                pypeLogo={props.pypeLogo}
                onCloseClick={this.toggleChat} />
              {state.design.styleSelection === 1 ? (
                <ChatCircle
                  className={launcherClassName + ' ' + style.ChatCircleInPreview}
                  design={state.design}
                  onClick={this.toggleChat} />
              ) : !state.isChatVisible && (
                <ChatAnchor
                  className={launcherClassName + ' ' + style.ChatAnchorInPreview}
                  design={state.design}
                  position={state.design.widgetPosition}
                  onClick={this.toggleChat} />
              )}
            </div>
          </div>
          <div className={`modal-footer ${style.ModalFooter}`}>
            {props.isNew && (
              <button
                className={style.BackButton}
                disabled={state.isSubmitting}
                onClick={props.onBack}>
                Back
              </button>
            )}
            <SaveButton
              className={`btn-primary ${style.SaveButton}`}
              disabled={state.isSubmitting || !this.isFormValid()}
              isLoading={state.isSubmitting}
              onClick={this.handleSubmit}>
              {props.isNew ? 'Next' : 'Save'}
            </SaveButton>
          </div>
        </div>
      </Modal>
    )
  }

}

DesignModal.propTypes = {
  isNew: PropTypes.bool,
  widget: PropTypes.object,
  design: PropTypes.object,
  pypeName: PropTypes.string.isRequired,
  pypeLogo: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
}

DesignModal.defaultProps = {
  isNew: false,
  design: {
    name: 'Web Conversational Interface',
    styleSelection: 1,
    widgetPosition: 'bottom-right',
    shouldLockRatio: true,
    widgetHeight: 660,
    widgetWidth: 380,
    widgetStyleColor: '#007BFF',
    widgetStyleTextColor: '#ffffff',
    agentBubbleColor: '#DEE8F5',
    agentBubbleTextColor: '#134373',
    userBubbleColor: '#113260',
    userBubbleTextColor: '#ffffff',
    bannerUrl: '',
  },
  onBack () {},
}

export default  DesignModal
