import upload from '../TUSClient'
import SDK from '../SDK'
import { onMessage, offMessage } from '../STOMPClient'
import { widgetsConstants as types } from '../constants'
import activityTracker from "../../utils/activityTracker";

const requestWidgets = () => ({
  type: types.REQUEST,
})

const receiveWidgets = (data) => ({
  type: types.RECEIVE,
  data,
})

const fetchWidgets = (user_id) => (dispatch, getState) => {
  dispatch(requestWidgets())
  SDK.getWidgets()
  .then((data) => {
    return dispatch(receiveWidgets(data))
  })
}

const uploadBanner = (bannerFile) => {
  const onSuccess = (url) => {}
  const onError = () => {}
  return upload(bannerFile, {
    filename: bannerFile.name,
    context_id: 'foobar',  // <TEMP>
    context: 'widget',
  }, () => {}).then(onSuccess, onError)
}

const createWidget = (data, bannerFile) => (dispatch, getState) => {
  /*
    <TODO>
    - Test this
    - Upload file if it exists
      - Make request with file url as bannerUrl
    - Else just make the request
  */

  SDK.createWidget(data)
  const customerName = getState().pype.details.customerName
  
  // x_widget response doesn't return correlation ID therefore can't
  // use dequeue method ex: SDK.createWidget({...}).then(...)
  const request = () => {
    return new Promise((resolve) => {
      async function handler (payload) {
        if (payload.type === 'notice' && payload.detail === 'widget') {
          offMessage(handler)
          await dispatch({type: types.CREATE_WIDGET, data: payload.data})
          activityTracker.logEvent(activityTracker.eventTypeNames.CREATE_CONVERSATION_INTERFACE,{customization: JSON.parse(payload.data.style), customerName});
          resolve(payload.data)
        }
      }
      onMessage(handler)
    })
  }

  return request()
}

const updateWidget = (data) => (dispatch) => {
  return SDK.updateWidget({
    entity_id: data.id,
    data
  }).then(() => {
    dispatch({
      type: types.UPDATE_WIDGET,
      data,
    })
  })
}

const deleteWidget = (widget) => (dispatch, getState) => {
  return SDK.deleteWidget({
    id: widget.id,
    stream_id: widget.stream_id,
  }).then(() => {
    dispatch({type: types.REMOVE_WIDGET, id: widget.id})
  })
}

export {
  fetchWidgets,
  createWidget,
  updateWidget,
  deleteWidget,
}
