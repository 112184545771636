// <TODO> Disable the row when another row is being edited

// External
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux';


// Local
import PlainTagRow from './PlainTagRow';
import NewTagRow from './NewTagRow';

// Pypestream
import sdk from 'sdk';
const {tagActions} = sdk;

class TagRow extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      code: props.tag.code,
      description: props.tag.description,
    }
  }

  handleClickEdit = (id) => {
    const {props} = this
    // if (props.canEdit) {
      props.onClickEdit(id)
    // }
  }

  handleFinishEdit = (data) => {
    this.props.onFinishEdit(data)
  }

  handleClickDelete = () => {
    const {props} = this

    const onSuccess = () => {
      window.appCreateToast({
        message: 'Tag successfully deleted.',
        level: 'success',
      })
    }

    const onError = () => {
      window.appCreateToast({
        message: 'Error deleting tag.',
        level: 'error',
      })
    }

    props.onClickDelete().then(onSuccess, onError)
  }

  handleClose = () => {
    this.props.onClose()
  }

  render() {
    const {props, state} = this
    return (
      <div className={'tag-row'}>
        {props.isEditing ? (
          <NewTagRow {...props.tag}
            enableTagCodes={props.enableTagCodes}
            onSubmit={this.handleFinishEdit}
            onClose={this.handleClose} />
        ) : (
          <PlainTagRow
            tag={props.tag}
            isTableEditing={props.isTableEditing}
            enableTagCodes={props.enableTagCodes}
            onClickEdit={this.handleClickEdit}
            onClickDelete={this.handleClickDelete} />)}
      </div>
    )
  }

}

TagRow.propTypes = {
  // canEdit: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isTableEditing: PropTypes.bool.isRequired,
  tag: PropTypes.object.isRequired,
  enableTagCodes: PropTypes.bool.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  onFinishEdit: PropTypes.func.isRequired,
}

const mapDispatchToProps = (dispatch, props) => ({
  onClickDelete() {
    return dispatch(tagActions.destroyTag(props.tag.id))
  },
})

export default  connect(null, mapDispatchToProps)(TagRow)
