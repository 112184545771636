import {
  REQUEST_STREAMS,
  RECEIVE_STREAMS,
  CREATE_STREAM,
  CREATED_STREAM,
  EDIT_STREAM,
  UPDATE_STREAM,
  REQUEST_STREAM_LAST_BROADCAST,
  RECEIVE_STREAM_LAST_BROADCAST,
  UPDATE_STREAM_LAST_BROADCAST,
} from "./../constants"

import SDK from '../SDK'

function requestStreams() {
  return {
    type: REQUEST_STREAMS
  }
}

export function receiveStreams(req_id,json) {
  return {
    req_id,
    type: RECEIVE_STREAMS,
    streams: json,
    receivedAt: Date.now()
  }
}

function fetchStreams() {
  return dispatch => {
    dispatch(requestStreams())
  }
}

function shouldFetchStreams(state) {
  const streams = state.streamsForPype.streams;
  if (streams.length === 0) {
    return true
  }
  if (state.streamsForPype.isFetching) {
    return false
  }
  return streams.didInvalidate
}

export function fetchStreamsIfNeeded(refresh) {
  return (dispatch, getState) => {
    if (refresh || shouldFetchStreams(getState())) {
      SDK.getStreams({
        pype_id: getState().pype.details.id,
      });
      return dispatch(fetchStreams())
    }
  }
}

/*
  Useful for updating metrics
*/
export function refreshStreams() {
  return (dispatch, getState) => {
    SDK.getStreams({
      pype_id: getState().pype.details.id,
    });
    return dispatch(fetchStreams())
  }
}

export function editingStream(stream_id) {
  return {
    type: EDIT_STREAM,
    payload: {
      streamID: stream_id
    }
  }
}

export function createStream(streamData) {
  return (dispatch, getState) => {
    SDK.createStream({
      ...streamData,
      pype_id: getState().pype.details.id,
    })
    return {
      type: CREATE_STREAM
    };
  }
}

export function updateStream(streamData) {
  SDK.updateStream(streamData);
  return {
    type: UPDATE_STREAM
  };
}

export function createdOrUpdatedStream(req_id,stream){
  return {
    req_id,
    type: CREATED_STREAM,
    stream
  }
}


function requestLastBroadcastForStream(streamId, pypeId){
  SDK.getStreamLastBroadcast({
    stream_id: streamId,
    pype_id: pypeId,
    params: {
      more: null,
      items_per_page: 4,
    }
  });
}

export function fetchStreamLastBroadcast(stream_id){
  return (dispatch,getState) => {
    const pypeId = getState().pype.details.id
    if(!stream_id){
      const streams = getState().streamsForPype.streams;
      streams.length && streams.forEach((stream) => {
        requestLastBroadcastForStream(stream.id, pypeId);
      });
    } else {
      requestLastBroadcastForStream(stream_id, pypeId);
    }
    dispatch({
      type: REQUEST_STREAM_LAST_BROADCAST
    })
  }
}

export function receiveStreamLastBroadcast(req_id, json) {
  return {
    req_id,
    type: RECEIVE_STREAM_LAST_BROADCAST,
    campaigns: json.campaigns,
    receivedAt: Date.now()
  }
}

export function updateStreamLastBroadcast(createdCampaign){
  return (dispatch) => {
    dispatch({
      type: UPDATE_STREAM_LAST_BROADCAST,
      campaign: createdCampaign,
      receivedAt: Date.now()
    })
  }
}