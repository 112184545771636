import _ from 'lodash'
import Constant from 'core/constant';

export default {
  fileExtensionAndSizeAllowed: function(file) {
    var extension = file.name.split('.').pop();

    var notMatched = Constant.WHITELIST_FILE_EXTENSIONS.every(function(value){
      return value != extension.toLowerCase();
    });

    if (notMatched) {
      alert("Only PNG, JPG, JPEG, GIF, MP3, MP4, DOC(*), XLS(*), PPT(*) and PDF files are allowed!");
    } else if (file.size > Constant.MAX_UPLOAD_FILE_SIZE * 1024 * 1024) {
      alert("Your file size is larger than " + Constant.MAX_UPLOAD_FILE_SIZE + "MB. Please attach a file smaller than " + Constant.MAX_UPLOAD_FILE_SIZE + "MB.");
    } else {
      return true;
    }
  },

  fileTypeForExtension: function(extension) {
    var fileType;
    _.keys(Constant.ICON_TYPES).find(function(extensions){
      if (extensions.split(', ').indexOf(extension.toLowerCase()) > -1) {
        fileType = Constant.ICON_TYPES[extensions];
      }
    })
    return fileType;
  },

  getFileTypeForPlatformMessage: function(file) {
    var _type = file.type.split('/')[0];

    if (_type === 'image') {
      return 'photo';
    } else if (_type === 'video') {
      return 'video';
    } else if (_type === 'audio') {
      return 'audio';
    } else {
      return 'document';
    }
  },

  getFileNameFromURL: function(url){
    return url.substring(url.lastIndexOf('/')+1);
  }
}
