import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Popover from "react-simple-popover";
import {
  Box,
  Grid,
  IconButton,
  Tooltip,
  Typography,

} from "@material-ui/core";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import cx from 'classnames';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "ui-elements";

import EndChatPopover from "./EndChatPopover";
import TransferIcon from "img/icons/transfer.svg";
import CONSTANTS from "../../core/constant";

const useStyles = makeStyles((theme) => ({
  container: {
    borderColor: "#DDD",
  },
  grid: {
    width: 'inherit',
  },
  consumerName: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: "24px",
    color: "#29302E",
    letterSpacing: 0,
  },
  truncateName: {
    flex: 1,
    minWidth: 0,
  },
  truncateNameHeading: {
    flex: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
  },
  btnGrp: {
    flexShrink: 0
  },
  endConvBtn: {
    marginRight: 0
  },
  transferBtn: {
    padding: 0,
    margin: "0 8px",
    "&:hover": {
      background: "#CBDFFB"
    }
  },
  popover: {
    width: "244px",
    boxShadow: "none",
    borderRadius: "4px",
    transform: "translateX(-62px)",
  },
  endedChatMessage: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.43,
    letterSpacing: 'normal',
    textAlign: 'right',
    color: '#7a6769',
  }
}));

const SubHeaderBar = (props) => {
  const endChatRef = useRef(null);
  const [itemWidth, setItemWidth] = useState(null);
  const [open, setOpen] = React.useState(false);
  const { commentRequired, tagsRequired } = props;
  const headingRef = useRef();
  const message =
    commentRequired && tagsRequired
      ? CONSTANTS.BANNER_MESSAGE.comment_and_classification_required
      : commentRequired
      ? CONSTANTS.BANNER_MESSAGE.comment_required
      : tagsRequired
      ? CONSTANTS.BANNER_MESSAGE.classification_required
      : CONSTANTS.BANNER_MESSAGE.no_requirements;

  useEffect(() => {
    let buffer = props.isActiveTab ? 180 : 0;
    setItemWidth(headingRef.current.clientWidth - buffer );
   });

  const classes = useStyles();

  return (
    <Box ref={headingRef} mx={3} py={1.5} borderBottom={1} className={classes.container}>
      <Grid container justify="space-between" alignItems="center" wrap="nowrap">
        <Grid item >
        <Typography className={cx([classes.consumerName], [classes.truncateNameHeading])} variant="h6" style={{maxWidth: `${itemWidth}px`}}>
          {props.name}
        </Typography>
        </Grid>
        {props.isActiveTab && !props.endChatBanner &&(
          <React.Fragment>
            <Grid wrap="nowrap" className={classes.btnGrp}>
              {!props.isTransferFormOpen &&(
                  props.flags.pe18408 ?
                      <ClickAwayListener onClickAway={()=> setOpen(false)}>
                        <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            onClose={()=> setOpen(false)}
                            open={open}
                            disableFocusListener={!!props.noAgentsAvailable}
                            disableHoverListener={!!props.noAgentsAvailable}
                            disableTouchListener={!!props.noAgentsAvailable}
                            title={props.noAgentsAvailable ? "No agents are available" : "Transfer"} placement="top" leaveDelay={props.noAgentsAvailable ? 500 : 0}>
                          <IconButton
                              id="btn-transfer"
                              color="primary"
                              onClick={()=>{
                                setOpen(true);
                                props.onClickTransferChat()
                              }}
                              size="small"
                              className={classes.transferBtn}
                          >
                            <img src={TransferIcon} alt="Transfer Icon" />
                          </IconButton>
                        </Tooltip>
                      </ClickAwayListener>
                      :
                      <Tooltip title={props.noAgentsAvailable ? "No agents are available" :"Transfer"} placement="top" leaveDelay={props.noAgentsAvailable ? 2500 : 0}>
                        <IconButton
                            id="btn-transfer"
                            color="primary"
                            onClick={props.onClickTransferChat}
                            size="small"
                            disabled={props.noAgentsAvailable}
                            className={classes.transferBtn}
                        >
                          <img src={TransferIcon} alt="Transfer Icon" />
                        </IconButton>
                      </Tooltip>

              )
              }
              <Button
                ref={endChatRef}
                onClick={props.onClickEndChat}
                className={classes.endConvBtn}
              >
                End Conversation
              </Button>
            </Grid>

            <Popover
              className={classes.popover}
              placement="bottom"
              target={endChatRef.current}
              show={props.popoverIsOpen}
              onHide={props.closePopover}
            >
              <EndChatPopover
                chat_id={props.chat_id}
                name={props.name}
                closePopover={props.closePopover}
                onSubmit={props.onSubmit}
              />
            </Popover>
          </React.Fragment>
        )}
        {props.endChatBanner &&
          <Grid wrap="nowrap" item>
            <Typography className={classes.endedChatMessage}>
              {message}
            </Typography>
          </Grid>
        }
      </Grid>
    </Box>
  );
};

SubHeaderBar.propTypes = {
  agentId: PropTypes.string.isRequired,
  chat_id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isActiveTab: PropTypes.bool.isRequired,
  onClickEndChat: PropTypes.func.isRequired,
  onClickTransferChat: PropTypes.func.isRequired,
  closePopover: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  popoverIsOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isSessionUserInfoIsOpen: state.agent.isSessionUserInfoIsOpen,
 });

const SubHeaderBarWithFeatureFlags = withLDConsumer()(SubHeaderBar);

export default withRouter(connect(mapStateToProps)(SubHeaderBarWithFeatureFlags));
