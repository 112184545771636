import React from 'react'

import {Table} from 'fixed-data-table-2';
import Dimensions from 'react-dimensions';

// Using react-dimensions to give dynamic container width for 
// fixed-data-table that gets adjusted when resized
// https://github.com/digidem/react-dimensions/issues/37
class _Table extends React.Component {
  render() {
    const {containerWidth, children, ...rest} = this.props; 
    return <Table  width={containerWidth} {...rest}>{children}</Table>
  }
}
  
export default Dimensions()(_Table)