import style from './style.css';
import _ from 'lodash';
import React from 'react'
import PropTypes from 'prop-types'

import Modal from 'react-modal';
import Select from 'react-select';
import SaveButton from 'components/ui/forms/SaveButton';

const Weak = ({children}) => (
  <span className={style.Weak}>
    {children}
  </span>
)

const getActiveStreams = (streamsStore = []) => {
  return streamsStore.filter(s => s.status === 'active');
}
class StreamsModal extends React.Component {

  constructor (props) {
    super(props)
    const streams = getActiveStreams(props.streamsStore)
    const stream = streams.length === 1
      ? streams[0].id
      : props.stream
    this.state = {
      stream,
      isSubmitting: false,
    }
    window.globalUi.webchat.stream = stream;
  }

  handleClose = () => {
    if (!this.state.isSubmitting) {
      this.props.closeModal()
    }
  }

  handleSubmit = () => {
    this.setState({isSubmitting: true})
    this.props.onSubmit(this.state.stream).then(() => {
      if (!this.props.isNew) {
        this.setState({isSubmitting: false})
      }
      if (!this.props.isNew) {
        this.handleSuccess()
      }
    }).catch((error) => {
      console.warn(error)
      this.setState({isSubmitting: false})
      // this.handleError()
    })
  }

  handleSuccess = () => {
    window.appCreateToast({
      message: 'Stream updated successfully.',
      level: 'success',
    })
    this.props.closeModal()
  }

  handleError = () => {
    window.appCreateToast({
      message: 'Error updating stream.',
      level: 'error',
    })
    this.props.closeModal()
  }

  getOptions = () => {
    const streams = getActiveStreams(this.props.streamsStore)
    return streams.map((stream) => ({
      value: stream.id,
      label: stream.stream_name,
    }))
  }

  handleChange = (stream) => {
    this.setState({stream: stream.value})
    window.globalUi.webchat.stream = stream.value
  }

  renderOption (option) {
    return <div className={style.Option}>{option.label}</div>
  }

  canSubmit () {
    return this.state.stream != null
  }

  render () {
    const {props, state} = this
    return (
      <Modal ariaHideApp={false} contentLabel='Modal' className={`streams-modal ${style.StreamsModal}`} isOpen={true}
        onRequestClose={this.handleClose} shouldCloseOnOverlayClick={false}>
        <div className={`modal-content ${style.ModalContent}`}>
          <div className={`modal-header ${style.ModalHeader}`}>
            1. Configuring {!props.isNew && `(${props.widget.widget_name})`} settings
            <button type='button' className='close' onClick={this.handleClose}>
              <img src={`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/icon-modal-close.svg`} width='15' height='15' />
            </button>
          </div>
          <div className={`modal-body ${style.ModalBody}`}>
            <div>
              <div className={style.Heading}>Select a Stream <Weak>(required)</Weak></div>
              <p className={style.Info}>
                Select which Stream should receive incoming chats from
                your visitors.
              </p>
              <Select
                className={style.Dropdown}
                value={this.getOptions().filter(({value}) => state.stream === value)}
                options={this.getOptions()}
                placeholder='Select a Stream'
                clearable={false}
                optionRenderer={this.renderOption}
                disabled={state.isSubmitting}
                onChange={this.handleChange} />
            </div>
          </div>
          <div className={`modal-footer ${style.ModalFooter}`}>
            <SaveButton
              className={`btn-primary ${style.SaveButton}`}
              disabled={state.isSubmitting || !this.canSubmit()}
              isLoading={state.isSubmitting}
              onClick={this.handleSubmit}>
              {props.isNew ? 'Next' : 'Save'}
            </SaveButton>
          </div>
        </div>
      </Modal>
    )
  }

}

StreamsModal.propTypes = {
  isNew: PropTypes.bool,
  stream: PropTypes.string,
  streamsStore: PropTypes.arrayOf(PropTypes.object).isRequired,
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

StreamsModal.defaultProps = {
  isNew: false,
}

export default  StreamsModal
