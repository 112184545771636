import style from './style.css';
import React from 'react'

const Banner = () => (
  <div className={style.Banner}>
    <div className={`${style.Pane} ${style.LeftPane}`}>
      <div className={style.Heading}>
        Welcome to the Web Conversational Interface
      </div>
      <p className={style.Info}>
        Enable your website visitors to chat instantly with agents or
        chatbots.
      </p>
    </div>
    <div className={`${style.Pane} ${style.RightPane}`}>
      <img className={style.Img} src={`${window.config.PS_PYPE_MANAGER_FRONTEND_HOMEPAGE}/img/webchat-banner.svg`} />
    </div>
  </div>
)

export default  Banner
