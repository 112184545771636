import React from 'react';
import { VerticalListItemLabel } from './helpers';
import { Icon, IconNames } from '../../icons';

const DisplayNames = {
    List: 'List',
    ListItem: 'ListItem'
}

class ListItem extends React.Component {
    static displayName = DisplayNames.ListItem;

    render() {
        const { selected, label, collapsible, children, itemIndex, expandedIcon, order, ...props } = this.props;
        return (
            <VerticalListItemLabel data-index={itemIndex} selected={selected} expandedIcon={expandedIcon} order={order} {...props}>
                {collapsible ? label : (label || children)}
                {collapsible && <Icon fill={selected ? '#0a3ab4' : '#7a6769'} name={IconNames.ChevronDown} />}
            </VerticalListItemLabel>
        );
    }
}

export default ListItem;