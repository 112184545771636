// External
import assign  from 'object-assign';
import _ from 'lodash';
import {isEmail} from 'validator';


// React, redux
import React from 'react'
import PropTypes from 'prop-types'

// UI
import Modal from 'react-modal';
import CloseButton from 'components/ui/modals/CloseButton';
import SaveButton from 'components/ui/forms/SaveButton';

import AgentDetails from './AgentDetails';
import SkillsList from './SkillsList'
import style from './style.css';
import activityTracker from "../../../../utils/activityTracker";


/*
  Get only digits from a string.
  Example:
    pickDigits('aaaa42') => '42'
*/
const pickDigits = (s) => s.replace(/\D/g, '')

const validators = {

  /*
    All methods in this namespace will return a string on error and
    undefined on validating.
  */

  chatLimit(chatLimit) {
    if (_.isNil(chatLimit) || chatLimit === '')
      return 'Please enter a valid number.'
    if (chatLimit < 1 || chatLimit > 100)
      return 'Please set a chat limit between 1 to 100.'
  },

}

const getSortedSkills = (skillsStore, agent) => {
  const skills = _.chain(skillsStore)
  .map((skill) => {
    // Enable general skill by default only when creating new agent
    if(!agent && skill.id === 'general') {
      skill.isSkillAssigned = true;
    } else if(agent && agent.skills.indexOf(skill.id) > -1) {
      skill.isSkillAssigned = true;
    } else {
      skill.isSkillAssigned = false;
    }
    return skill;
  })
  .sortBy('name')
  .sortBy((skill) => {
    return skill.id === "general" ? 0 : 1; // show general skill first
  }).value();

  return skills
}
class Component extends React.Component {
  constructor(props){
    super(props)
    const {agent, skillsStore} = props;

    let state = {
      isSubmitting: false,
      skills: _.cloneDeep(getSortedSkills(skillsStore, agent)),
      firstName: '',
      lastName: '',
      email: '',
      chatLimit: props.defaultChatLimit || 10,
      errors: {}
    }
    if(agent){
      state = {
        ...state,
        firstName: agent.first_name,
        lastName: agent.last_name,
        email: agent.email,
        chatLimit: agent.max_conv
      }
    }
    this.state = state;
  }

  componentWillReceiveProps (nextProps) {
    if(nextProps.isFetchingSkills != this.props.isFetchingSkills){
      this.setState({skills:_.cloneDeep(getSortedSkills(nextProps.skillsStore, nextProps.agent))})
    }
  }

  getValidationErrors() {
    const props = ['chatLimit']
    const errors = {}
    props.forEach((prop) => {
      const errorMessage = validators[prop](this.state[prop])
      if (errorMessage !== undefined)
        errors[prop] = errorMessage
    })
    return errors
  }

  isFormValid = () => {
    return _.keys(this.getValidationErrors()).length === 0 &&
      _.some(this.state.skills,{isSkillAssigned: true})
  }

  hasChanges = () => {
    const {props, state} = this
    const {agent, skillsStore} = props

    if(!agent){
      return true;
    }

    const skills = getSortedSkills(skillsStore, agent)

    return state.chatLimit !== agent.max_conv ||
      !_(skills).differenceWith(state.skills, _.isEqual).isEmpty()
  }

  handleClose = () => {
    if (!this.state.isSubmitting)
      this.props.closeModal()
  }

  handleSubmit = () => {

    const {state,props} = this;
    const {agent, closeModal, customerName} = props
    const isNewAgentInvite = _.isNil(agent);
    const skills = _.filter(state.skills, {isSkillAssigned: true}).map((skill)=>(skill.id))
    const agentData = {
      first_name: state.firstName.trim(),
      last_name: state.lastName.trim(),
      email: state.email.trim(),
      max_conv: state.chatLimit,
      skills
    }
    const data = assign(
      {},
      agentData,
      !isNewAgentInvite ? {id: agent.id} : {}
    )

    const name = agentData.first_name + ' ' + agentData.last_name

    this.setState({isSubmitting: true})

    const toastSuccessMsg = isNewAgentInvite ?
      '<span>Agent invitation to  <strong>' + name + '</strong> was sent successfully.</span>' :
      '<span>Changes saved successfully.</span>';

    const toastErrorMsg = isNewAgentInvite ?
      '<span>Agent invitation to <strong>' + name + '</strong> failed.</span>' :
      '<span>Unable to save Agent details</span>';


    const onSuccess = () => {
      window.appCreateToast({
        message: toastSuccessMsg,
        level: 'success',
      })
      activityTracker.logEvent(activityTracker.eventTypeNames.UPDATE_PYPE_SETTING,{settingName: 'agents',action: activityTracker.eventActionType.UPDATE, customerName});
    }

    const onError = (error) => {
      window.appCreateToast({
        message: toastErrorMsg,
        level: 'error',
      })
      console.error(error)
    }

    const onDone = () => {
      this.setState({isSubmitting: false})
      closeModal()
    }

    this.props.onSubmit(data)
      .then(onSuccess, onError)
      .then(onDone)

  }

  handleSkillSelect = (skill) => {
    const updatedSkills = _.cloneDeep(this.state.skills);
    const skillObj = _.find(updatedSkills, {id:skill.id});

    if(skillObj){
      skillObj.isSkillAssigned = !skillObj.isSkillAssigned;
    }

    this.setState({
      skills: updatedSkills
    })
  }

  handleSelectAllSkills = () => {
    const updatedSkills = _.cloneDeep(this.state.skills);
    const shouldDeselectAll = _.every(updatedSkills, {isSkillAssigned: true})

    updatedSkills.forEach((skill)=>{
      skill.isSkillAssigned = shouldDeselectAll ? false : true;
    })

    this.setState({skills: updatedSkills})
  }

  bindTo(prop, options={}) {
    return (event) => {
      let {value} = event.target
      if (options.type === 'number')
        value = parseInt(value)
      this.setState({
        [prop]: value,
        errors: _.omit(this.state.errors, prop),
      })
    }
  }

  bindValidator = (prop) => {
    return () => {
      const errorMessage = validators[prop](this.state[prop])
      const errors = assign({}, this.state.errors)
      if (errorMessage !== undefined)
        errors[prop] = errorMessage
      else
        delete errors[prop]
      this.setState({errors})
    }
  }

  handleChatLimitChange = (event) => {
    let chatLimit
    if (event.target.value === '')
      chatLimit = ''
    else
      chatLimit = parseInt(pickDigits(event.target.value))
    this.setState({
      chatLimit,
      errors: _.omit(this.state.errors, 'chatLimit'),
    })
  }

  render() {

    const {props, state} = this
    const {agent} = props
    const isNew = _.isNil(agent)

    const formProps = {
      isNew,
      chatLimit: state.chatLimit,
      firstName: state.firstName,
      lastName: state.lastName,
      email: state.email,
      errors: state.errors,
      onSubmit: this.handleSubmit,
      bindTo:this.bindTo,
      bindValidator: this.bindValidator,
      handleChatLimitChange: this.handleChatLimitChange
    }

    return (
      <Modal ariaHideApp={false} className={style.Modal} onRequestClose={this.handleClose} isOpen={true}>
        <div className={`modal-content`}>
          <div className="modal-header">
            <CloseButton onClick={this.handleClose} />
            <div className="clearfix"></div>
          </div>
          <div className="modal-body">
            <div className={style.agentDetailsWrapper}>
              <AgentDetails {...formProps} />
            </div>
            <div className={style.skillsWrapper}>
              <SkillsList
                skills={state.skills}
                onSkillSelect={this.handleSkillSelect}
                onSelectAllSkills={this.handleSelectAllSkills}/>
            </div>
          </div>

          <div className="modal-footer">
            <div className={style.footerRight}>
              {!isNew && this.hasChanges() ?
                <div className="unsaved inline m-r-10">Unsaved changes</div> : null
              }
              <SaveButton className="btn btn-primary" type="submit" isLoading={state.isSubmitting} disabled={!this.isFormValid() || !this.hasChanges()} onClick={this.handleSubmit}>
                {isNew ? 'Send invitation' : 'Save changes'}
              </SaveButton>
            </div>
          </div>
        </div>
      </Modal>
    )

  }

}

Component.propTypes = {

  agent: PropTypes.object,

  // Modal props
  closeModal: PropTypes.func.isRequired,

  // Callbacks
  onSubmit: PropTypes.func.isRequired,

}

export default  Component;
