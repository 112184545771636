import style from './style.css';
import _ from 'lodash';
import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal';
import SaveButton from 'components/ui/forms/SaveButton';
import AddRulePattern from '../AddRulePattern';
import activityTracker from "../../../../utils/activityTracker";


class NewRuleModal extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      rule: props.rule || {},
      rules: props.rules || [],
      isSubmitting: false,
      newPatterns: props.rule && props.rule.regex_patterns && props.rule.regex_patterns.length > 0 ? props.rule.regex_patterns.map((val) => ({value: val, valid: true})) : [{value: '', valid: true}],
      canAddPattern: false
    }
  }

  handleClose = () => {
    if (!this.state.isSubmitting) {
      this.props.closeModal()
    }
  }

  handleSubmit = () => {
    if (this.props.new) {
      this.setState({isSubmitting: true})
      if (!this.state.rule.regex_patterns) {
        let emptyRule = {...this.state.rule}
        emptyRule.regex_patterns = []
        this.setState({rule: emptyRule}, () => {
          const newRules = this.state.rules.concat([this.state.rule])
          this.props.onSubmit(newRules)
            .then(() => {
              this.setState({isSubmitting: false})
              this.handleCreateSuccess()
            })
            .catch((error) => {
              console.warn(error)
              this.setState({isSubmitting: false})
              this.handleError()
            })
        })
      } else {
        const newRules = this.state.rules.concat([this.state.rule])
        this.props.onSubmit(newRules)
          .then(() => {
            this.setState({isSubmitting: false})
            this.handleCreateSuccess()
          })
          .catch((error) => {
            console.warn(error)
            this.setState({isSubmitting: false})
            this.handleError()
          })
      }
    }
    if (!this.props.new) {
      this.setState({isSubmitting: true})
      const updatedRules = Object.assign(this.state.rules.slice(), {[this.props.index]: this.state.rule})
      this.props.onSubmit(updatedRules)
        .then(() => {
          this.setState({isSubmitting: false})
          this.handleEditSuccess()
        })
        .catch((error) => {
          console.warn(error)
          this.setState({isSubmitting: false})
          this.handleError()
        })
    }
  }

  handleEditSuccess = () => {
    const {state, props} = this;
    const { customerName } = props
    const {name, description} = state.rule;
    const used = description ? 'used' : 'not used';
    const label = `Name: ${name}, Pype: ${props.pypeName}, description: ${used}, patterns: ${state.newPatterns.length}`
    window.appCreateToast({
      message: 'Rule updated successfully.',
      level: 'success',
    })
    activityTracker.logEvent(activityTracker.eventTypeNames.UPDATE_PYPE_SETTING, {settingName: 'user privacy',action: activityTracker.eventActionType.UPDATE, customerName});
    this.props.closeModal()
  }

  handleCreateSuccess = () => {
    const {state, props} = this;
    const { customerName } = props
    const {name, description} = state.rule;
    const used = description ? 'used' : 'not used';
    const label = `Name: ${name}, Pype: ${props.pypeName}, description: ${used}, patterns: ${state.newPatterns.length}`
    window.appCreateToast({
      message: 'Rule created successfully.',
      level: 'success',
    })
    activityTracker.logEvent(activityTracker.eventTypeNames.UPDATE_PYPE_SETTING, {settingName: 'user privacy',action: activityTracker.eventActionType.CREATE, customerName});
    this.props.closeModal()
  }

  handleError = () => {
    window.appCreateToast({
      message: 'Error saving rule.',
      level: 'error',
    })
    this.props.closeModal()
  }

  canSubmit = () => {
    return this.validName() && this.redundantPatternField()
  }

  isDuplicate = (name) => {
    for (var ruleIndex = 0; ruleIndex < this.props.rules.length; ruleIndex++) {
      if (this.props.rules[ruleIndex].name === name.trim()) {
          return this.setState({duplicate: true})
        }
      }
    return this.setState({duplicate: false});
  }

  handleNameInput = (event) => {
    if (this.state.isSubmitting) {
      return
    }
    let rule = Object.assign({}, this.state.rule);
    rule.name = event.target.value;
    this.setState({rule}, () => this.isDuplicate(this.state.rule.name))
  }

  handleDescriptionInput = (event) => {
    if (this.state.isSubmitting) {
      return
    }
    let rule = Object.assign({}, this.state.rule);
    rule.description = event.target.value;
    this.setState({rule})
  }

  preventInput = (event, max) => {
    if (event.keyCode != 8 && event.keyCode != 46 && event.target.value.length >= max) {
      event.preventDefault();
    }
  }

  handleNameLength = (event) => {
    this.preventInput(event, 80)
  }

  handleDescriptionLength = (event) => {
    this.preventInput(event, 250)
  }

  isRuleEmpty = (rule) => {
    if (Object.getOwnPropertyNames(rule).length == 0) {
      return true
    }
  }

  isPatternEmpty = (pattern) => {
    if (this.state.rule.pattern === false) {
      return true
    }
    return !(pattern[0] != null)
  }

  showEmptyInput = (rule) => {
    if (this.isPatternEmpty(rule)) {
      return true
    }
    // if (state.addRulePattern) {

    // }
  }

  handlePatternInput = (event) => {
    let pattern = event.target.value;
    try {
      let validator = new RegExp(event.target.value)
      const editPatterns = this.state.newPatterns.slice()
      editPatterns[Number(event.target.name)] = { value: pattern, valid: true}
      let rule = Object.assign({}, this.state.rule);
      rule.regex_patterns = editPatterns.map((patternObject) => patternObject.value);
      this.setState({rule, newPatterns: editPatterns}, () => this.canAddPattern());
    }
    catch (e) {
        const editPatterns = this.state.newPatterns.slice()
        editPatterns[Number(event.target.name)] = { value: pattern, valid: false}
        // let rule = Object.assign({}, this.state.rule);
        // rule.regex_patterns = editPatterns;
       this.setState({newPatterns: editPatterns}, () => this.canAddPattern())
    }
  }

  deletePattern = (index) => {
    const patterns = this.state.newPatterns.concat()
    patterns.splice(index, 1)
    const rule = Object.assign({}, this.state.rule);
    rule.regex_patterns = patterns.map((patternObject) => patternObject.value);
    this.setState({rule, newPatterns: patterns}, () => this.canAddPattern());
  }

  handleAddPattern = () => {
    let allPatternsValid = this.state.newPatterns.every((pattern) => pattern.value && pattern.valid );
    if(allPatternsValid) {
      this.setState({newPatterns: [...this.state.newPatterns, {value: '', valid: true}]}, () => this.canAddPattern())
    }
  }

  canAddPattern = () => {
    let allPatternsValid = this.state.newPatterns.every((pattern) => pattern.value && pattern.valid );
    if (allPatternsValid) {
      return this.setState({canAddPattern: true})
    }
    this.setState({canAddPattern: false})
  }

  componentDidMount = () => {
    this.canAddPattern()
  }

  validName = () => {
    if (this.state.duplicate || this.state.rule.name === '' || this.state.rule.name === undefined) {
      return false
    } else {
      return true
    }
  }

  redundantPatternField = () => {
    let allPatternsValid = this.state.newPatterns.every((pattern) => pattern.value && pattern.valid );
    if (this.state.newPatterns.length > 1 && allPatternsValid) {
      return true;
    } else if (this.state.newPatterns.length === 1 && this.state.newPatterns[0].valid) {
      return true
    }
    return false
  }


  render () {
    const {props, state} = this
    return (
      <Modal ariaHideApp={false} contentLabel='Modal' className={`streams-modal ${style.StreamsModal}`} isOpen={true}
        onRequestClose={this.handleClose} shouldCloseOnOverlayClick={false}>
        <div className={`modal-content ${style.ModalContent}`}>
          <div className={`modal-header ${style.ModalHeader}`}>
            {props.new ? 'Create new rule' : `Editing "${props.rule.name}" rule`}
          </div>
          <div className={`modal-body ${style.ModalBody}`}>
            <div>
              <div className={style.RuleNameSection}>
                <div className={style.RuleName}>RULE NAME</div>
                {props.new ? <input type="text" id="RuleName" className={style.RuleNameInput} onChange={this.handleNameInput} placeholder="e.g. Credit card" onKeyPress={this.handleNameLength}/> : <input type="text" id="RuleName" className={style.RuleNameInput} onChange={this.handleNameInput} value={state.rule.name} placeholder="e.g. Credit card" onKeyPress={this.handleNameLength}/>
                }
                {this.state.duplicate && <span className={style.Error}>You already created a rule with his name. Please use another name</span>}
              </div>
              <div className={style.DescriptionSection}>
                <div className={style.Description}>DESCRIPTION (Optional)</div>
                {props.new ? <textarea type="text" className={style.DescriptionInput} onChange={this.handleDescriptionInput} placeholder="Enter a description for this rule." onKeyPress={this.handleDescriptionLength}></textarea> : <textarea type="text" className={style.DescriptionInput} onChange={this.handleDescriptionInput} placeholder="Enter a description for this rule." value={state.rule.description} value={state.rule.description} onKeyPress={this.handleDescriptionLength}></textarea>
                }
              </div>
              <div className={style.PatternSection}>
                <div className={style.Pattern}>RULE PATTERNS</div>
                <span className={style.PatternInfo}>
                  Enter each pattern as a JavaScript regular expression (regex) on a separate line.
                  <a href="https://regex101.com/" target="_blank">Click here</a> to test your regex.
                 </span>
                  {state.newPatterns.map((pattern, index) => (
                    <div key={index}>
                      <div className={style.PatternRow}>
                          <textarea
                            type="text"
                            className={style.PatternInput}
                            placeholder="e.g. ^(?!219-09-9999|078-05-1120)(?!666|000|9\d{2})\d{3}"
                            value={pattern.value}
                            name={index}
                            onChange={this.handlePatternInput}
                            >
                          </textarea>
                        <div className={style.iconsContainer}>
                          <span className={style.Trash} onClick={() => this.deletePattern(index)}></span>
                        </div>
                      </div>
                    {!pattern.valid && <span className={style.Error}>Please enter a valid JavaScript regular expression.</span>}
                    </div>
                  ))}
              <AddRulePattern onClick={this.handleAddPattern} inactive={!this.state.canAddPattern} className={style.CreateWidget}/>
              </div>
            </div>
          </div>
          <div className={`modal-footer ${style.ModalFooter}`}>
              <button
                className={style.CancelButton}
                disabled={state.isSubmitting}
                onClick={this.handleClose}>
                Cancel
              </button>
            <SaveButton
              className={`btn-primary ${style.SaveButton}`}
              disabled={state.isSubmitting || !this.canSubmit()}
              isLoading={state.isSubmitting}
              onClick={this.handleSubmit}>
              {props.new ? 'Create rule' : 'Save changes'}
            </SaveButton>
          </div>
        </div>
      </Modal>
    )
  }

}

NewRuleModal.propTypes = {
  isNew: PropTypes.bool,
  rule: PropTypes.object,
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  pypeName: PropTypes.string.isRequired,
}

NewRuleModal.defaultProps = {
  isNew: false,
}

export default  NewRuleModal
